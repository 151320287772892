import React from 'react';
import { getCurrencySymbol, getLCYCurrencySymbol } from 'common/util';
import sortStringNumbers from 'helpers/sortStringNumbers';
import nameColumn from '../helpers/nameColumn';
import onlineReserveColumn from '../helpers/onlineReserveColumn';
import { getOrderCurrency } from '../../../helpers';

const createColumnsZPr = ({ currency, hasExportPrice, settingsCurrency }) => {
  const orderCurrency = getOrderCurrency('order', currency);
  const currencySymbol = getCurrencySymbol(orderCurrency);

  return [
    {
      Header: '',
      id: 'no',
      width: 25,
      sortable: false,
      Cell: row => row.viewIndex + 1,
    },
    {
      Header: 'Код',
      width: 60,
      id: 'id',
      accessor: 'id',
      sortMethod: sortStringNumbers,
    },
    {
      Header: 'Part #',
      id: 'vendorPart',
      width: 125,
      accessor: 'vendorPart',
    },
    {
      Header: 'Наименование',
      minWidth: 500,
      accessor: 'name',
      id: 'name',
      style: { textAlign: 'left' },
      Cell: nameColumn,
    },
    {
      Header: 'Мин.кол.',
      width: 75,
      accessor: 'minAmount',
      id: 'minAmount',
    },
    {
      Header: 'В упак.',
      width: 65,
      accessor: 'packaged',
      id: 'packaged',
      sortMethod: sortStringNumbers,
    },
    {
      Header: 'Кол.',
      width: 50,
      accessor: 'qty',
      id: 'qty',
      Cell: row => <strong>{row.value}</strong>,
      sortMethod: sortStringNumbers,
    },
    {
      Header: () => <span>Цена, {currencySymbol}</span>,
      width: 80,
      accessor: 'unitPrice',
      id: 'unitPrice',
      style: { textAlign: 'right' },
      Cell: row => <strong>{row.value}</strong>,
      sortMethod: sortStringNumbers,
    },
    {
      Header: () => <span>Сумма, {currencySymbol}</span>,
      width: 80,
      accessor: 'total',
      id: 'total',
      style: { textAlign: 'right' },
      sortMethod: sortStringNumbers,
    },
    {
      Header: 'Дост.',
      width: 55,
      accessor: 'availableClient',
      id: 'availableClient',
      sortMethod: sortStringNumbers,
    },
    {
      Header: () => <span>РЦена, &#8381;</span>,
      accessor: 'priceLCY',
      id: 'priceLCY',
      width: 90,
      style: { textAlign: 'right' },
      sortMethod: sortStringNumbers,
    },
   /* {
      Header: () => <span>РЦена, {getLCYCurrencySymbol(settingsCurrency)}</span>,
      accessor: 'priceClient',
      id: 'priceClient',
      width: 90,
      style: { textAlign: 'right' },
      sortMethod: sortStringNumbers,
    },*/
    {
      Header: 'ЖКол',
      accessor: 'desiredQty',
      id: 'desiredQty',
      width: 70,
      sortMethod: sortStringNumbers,
    },
    {
      Header: () => <span>ЖЦена, &#8381;</span>,
      accessor: 'desiredPrice',
      id: 'desiredPrice',
      width: 90,
      style: { textAlign: 'right' },
      sortMethod: sortStringNumbers,
    },
    {
      Header: () => <span>Цена_ЭКСПОРТ, &#8381;</span>,
      accessor: 'exportPrice',
      id: 'exportPrice',
      width: 130,
      style: { textAlign: 'right' },
      sortMethod: sortStringNumbers,
      show: !!parseInt(hasExportPrice),
    },
    /*{
      Header: () => <span>Цена_ЭКСПОРТ, {getLCYCurrencySymbol(settingsCurrency)}</span>,
      accessor: 'exportPriceClient',
      id: 'exportPriceClient',
      width: 130,
      style: { textAlign: 'right' },
      sortMethod: sortStringNumbers,
      show: !!parseInt(hasExportPrice),
    },*/
    {
      Header: 'ОР',
      title: 'Онлайн Резерв (в случае платного резерва со стоимостью отмены резерва, Р/шт*ч)',
      accessor: 'cancelReserveCostHr',
      id: 'cancelReserveCostHr',
      width: 80,
      Cell: onlineReserveColumn,
    },
    {
      Header: 'ВР',
      title: 'Время нахождения в резерве, ч.',
      width: 60,
      accessor: 'onlineReserve',
      id: 'onlineReserve',
      Cell: row => {
        if (!row) return '';

        return [2, 5].includes(parseInt(row.value, 10)) ? row.original.inReserveHours : '';
      },
    },
    {
      Header: 'Вес',
      width: 50,
      accessor: 'weight',
      id: 'weight',
      style: { textAlign: 'right' },
      Cell: row => (row && parseFloat(row.value).toFixed(2)) || null,
    },
    {
      Header: 'Объем',
      width: 70,
      accessor: 'volume',
      id: 'volume',
      style: { textAlign: 'right' },
      Cell: row => (row && parseFloat(row.value).toFixed(2)) || null,
    },
  ];
};

export default createColumnsZPr;
