import uniqBy from 'lodash/uniqBy';
import defaultSelect from '../constants/default-select';

const createOptionsForFilter = (list, filterBy) => {
  const optionsFromList = uniqBy(list, filterBy).map(item => ({
    value: item[filterBy],
    label: item[filterBy],
  }));

  return [defaultSelect, ...optionsFromList];
};

export default createOptionsForFilter;
