import { FIELD_RADIO_BUTTON } from './input-type';

const AVAIL = 'avail';

const AVAILABILITY = {
  all: {
    id: 0,
    value: 'Все товары',
  },
  inStockAndSuborder: {
    id: 1,
    value: 'На складе и под заказ',
  },
  inStock: {
    id: 2,
    value: 'На складе',
  },
};

export const DEFAULT_AVAIL_VALUE = 0;

export const availabilityFilter = {
  id: AVAIL,
  caption: 'Наличие',
  searchable: false,
  inputType: FIELD_RADIO_BUTTON,
  values: [
    {
      id: AVAILABILITY.all.id,
      value: AVAILABILITY.all.value,
      propertyId: AVAIL,
      name: AVAIL,
    },
    {
      id: AVAILABILITY.inStockAndSuborder.id,
      value: AVAILABILITY.inStockAndSuborder.value,
      propertyId: AVAIL,
      name: AVAIL,
    },
    {
      id: AVAILABILITY.inStock.id,
      value: AVAILABILITY.inStock.value,
      propertyId: AVAIL,
      name: AVAIL,
    },
  ],
};
