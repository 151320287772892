import { get } from 'lodash';
import createValue from '../helpers/createValue';
import { SHIPMENT_MSK } from '../../../../helpers';

export default {
  key: 'shipmentInfo',
  create: ({ order, settings, shipmentMethodRows }) => ({
    label: `${
      (order && order.shipment) || get(shipmentMethodRows, [0, 'Description'], SHIPMENT_MSK)
    }:`,
    value: createValue(get(order, 'shipmentDate', get(settings, 'shipmentDate', null))),
  }),
};
