export default ({
  orderNo,
  date,
  timeIntervalCode,
  carModel,
  numberType,
  stateNumber,
  notRussianStateNumber,
  representativeFIO,
}) => {
  const errors = {};
  if (!orderNo) errors.orderNo = 'Заполните поле';
  if (!date) errors.date = 'Заполните поле';
  if (!timeIntervalCode) errors.timeIntervalCode = 'Заполните поле';
  if (!carModel) errors.carModel = 'Заполните поле';
  if (!numberType) {
    if (!stateNumber) errors.stateNumber = 'Заполните поле';
    if (stateNumber && /[^0-9а-яА-Я\-ёЁ ']/.test(stateNumber))
      errors.stateNumber = 'Номер машины может состоять из русских букв и цифр';
  }
  if (numberType) {
    if (!notRussianStateNumber) errors.notRussianStateNumber = 'Заполните поле';
    if (notRussianStateNumber && notRussianStateNumber.length > 12)
      errors.notRussianStateNumber = 'Длина поля не может превышать 12 символов';
  }
  if (!representativeFIO) {
    errors.representativeFIO = 'Заполните поле';
  } else if (/[^а-яА-Я\-ёЁ ']/.test(representativeFIO)) {
    errors.representativeFIO = 'ФИО может состоять из русских букв, пробелов и апострофов';
  }
  return errors;
};
