// @ts-nocheck
/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { THEME_COLORS } from 'common/constants/styles';
import { capitalizeAndCamelCase } from 'utils/new/strings';
import { iconsList } from './data';

/**
 * Renders a <Icon /> component with SVG
 * @component
 * @example
 * const name = 'star'
 * return (
 *   <Icon name={name} />
 * )
 */
export const Icon = memo(props => {
  const { name, color } = props;
  const iconColor = THEME_COLORS[color];
  if (name) {
    const SelectedIcon = iconsList[capitalizeAndCamelCase(name)];
    return <SelectedIcon {...props} color={iconColor} />;
  }
  return null;
});

Icon.defaultProps = {
  onClick: () => {},
};

Icon.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * 'white' | 'black' | 'brand-red' | 'brand-red-hover' | 'brand-blue' | 'brand-blue-hover' | 'accent-blue' | 'light-blue'
   * | 'accent-yellow' | 'accent-yellow-hover' | 'light-yellow' | accent-green' | 'accent-green-hover'| 'light-green' | 'gray' | 'dark-gray' | 'light-gray' | 'stroke-gray'
   */
  color: PropTypes.string,
  /**
   * Icon name with one word or two words dashed
   */
  name: PropTypes.string,
  /**
   * Click event handler
   */
  onClick: PropTypes.func,
  /**
   * Svg boundaries
   */
  viewBox: PropTypes.string,
  /**
   * Icon width
   */
  width: PropTypes.string,
  /**
   * Icon height
   */
  height: PropTypes.string,
  /**
   * Icon title
   */
  title: PropTypes.string,
};
