import React, { useMemo } from 'react';
import Content from 'components/layout/content';
import { hasAccess } from 'common/util';
import EventsList from './components/EventsList';
import ActionsList from './components/ActionsList';
import NewsListMain from './components/NewsList';
import AdsList from './components/AdsList';
import { isCactus } from 'common/config';
import { useSelector } from 'react-redux';
import { getUser } from 'pages/Orders/selectors';
import { compose } from 'redux';
import withApi from 'hoc/withApi';
import withMemo from 'hoc/withMemo';

const NewsList = () => {
  const user = useSelector(getUser);

  const hasAccessActions = useMemo(() => hasAccess(user, 'ACT'), [user]);
  const hasAccessEvents = useMemo(() => hasAccess(user, 'MRK'), [user]);

  return (
    <Content className="news-list-container">
      <div className="main row justify-content-between">
        <NewsListMain />
        <AdsList />
        {hasAccessActions && <ActionsList />}
        {hasAccessEvents && !isCactus && <EventsList />}
      </div>
    </Content>
  );
};

export default compose(withApi, withMemo())(NewsList);
