import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import 'url-search-params-polyfill';
import isFunction from 'lodash/isFunction';

class Pagination extends Component {
  constructor(props) {
    super(props);

    const search = new URLSearchParams(location.search);
    const curPage = parseInt(search.get('p'));

    this.state = {
      page: curPage || 1,
    };
  }

  handleClick = page => e => {
    e.preventDefault();
    const { onChangePage } = this.props;
    this.setState(
      {
        page,
      },
      () => {
        if (isFunction(onChangePage)) {
          onChangePage(page);
          return false;
        }
        this.props.history.push({
          pathname: this.props.location.pathname,
          search: `?p=${page}`,
        });
      },
    );
  };

  buildPageLink(page, content, key) {
    return (
      <a
        href=""
        onClick={this.handleClick(page)}
        className={this.state.page === page ? 'active' : ''}
        key={key}
      >
        {content}
      </a>
    );
  }

  render() {
    const { total, perPage } = this.props;

    if (perPage >= total) return null;

    const curPage = parseInt(this.state.page);

    const first = curPage > 1 ? this.buildPageLink(1, '<<', 'first') : null;
    const prev = curPage > 1 ? this.buildPageLink(curPage - 1, '<', 'back') : null;
    const next = curPage < total ? this.buildPageLink(curPage + 1, '>', 'forward') : null;
    const last = curPage < total ? this.buildPageLink(total, '>>', 'last') : null;

    const links = [];

    const firstVisibleLink = curPage > 1 ? (curPage > 2 ? curPage - 2 : curPage - 1) : curPage;
    const lastVisibleLink =
      curPage + 3 > total
        ? curPage + 2 > total
          ? curPage + 1 > total
            ? total
            : curPage + 1
          : curPage + 2
        : curPage + 3;

    for (let i = firstVisibleLink; i <= lastVisibleLink; i++) {
      links.push(this.buildPageLink(i, i, i));
    }

    return (
      <div className="pagination">
        {first}
        {prev}
        {links}
        {next}
        {last}
      </div>
    );
  }
}

export default withRouter(Pagination);
