/**
 * Created by marat.m on 15.08.2017.
 */

/* eslint-disable no-console */
import React from 'react';
import { Link } from 'react-router-dom';
import { isEqual, omit } from 'lodash';
import localForage from 'localforage';
import { matchRoutes } from 'react-router-config';
import Centrifuge from 'centrifuge';
import SockJS from 'sockjs-client';
import alert from 'components/native/alert/function';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import { isCactus, SOCK_JS_URL, UNAUTHORIZED } from './config';
import { DEFAULT_QTY_LIMIT, STATIONARY_CODE } from './constants';

export const centrifugo = user => {
  const centrifuge = new Centrifuge(SOCK_JS_URL, {
    sockjs: SockJS,
  });

  centrifuge.setToken(user.rtmToken.toString());

  return centrifuge;
};

const dateFormat = require('dateformat');

dateFormat.i18n = {
  dayNames: [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  monthNames: [
    'Янв',
    'Фев',
    'Мар',
    'Апр',
    'Май',
    'Июн',
    'Июл',
    'Авг',
    'Сен',
    'Окт',
    'Ноя',
    'Дек',
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ],
  timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
};

export default dateFormat;

export const WEEKDAYS_SHORT = {
  ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
};
export const MONTHS = {
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  rod: [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ],
};

export const WEEKDAYS_LONG = {
  ru: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
};

export const FIRST_DAY_OF_WEEK = {
  ru: 1,
};

export const LABELS = {
  ru: { nextMonth: 'следующий месяц', previousMonth: 'предыдущий месяц' },
};

export const PAGING = [5, 10, 25, 50];

export const dateToDB = date =>
  `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`;

export const stringDateToDB = date => {
  let newDate;

  try {
    newDate = parse(date, 'dd.MM.yyyy', new Date());
  } catch (err) {
    newDate = new Date();
  }

  return format(newDate, 'yyyy-MM-dd 00:00:00');
};

export const dateToUTC = date => {
  if (!date) return false;

  const data = date.split('.');

  return `${data[2]},${data[1]},${data[0]}`;
};
export const dateUTCSplit = date => {
  const utcDate = dateToUTC(date);

  return utcDate && utcDate.split(',');
};
export const dateToRU = date => {
  let data = Date.parse(date);

  if (data) {
    data = new Date(data);

    return `${`0${data.getDate()}`.slice(-2)}.${`0${data.getMonth() + 1}`.slice(
      -2,
    )}.${data.getFullYear()}`;
  }

  return null;
};

export const compareDate = (date1, date2) => {
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);

  return (date1.getTime() < date2.getTime() && -1) || (date1.getTime() > date2.getTime() && 1) || 0;
};

export const hasAccess = (user, action) => {
  const permissions = JSON.parse(user.permissions || '[]');
  const filteredPermissions = permissions.filter(perm => perm[action] == 1);

  return !!filteredPermissions.length;
};

export const hasOrderEditAccess = (user, order) =>
  // console.log(order.completed, order.changeProtect, parseInt(order.completed) === 0,parseInt(order.changeProtect) !== 1, ['-', 'Готово', 'Error'].includes(order.status), order.status, order.initiator, user.login, parseInt(order.completed), parseInt(order.changeProtect),  hasAccess(user, 'ZPR_ALL'), hasAccess(user, 'ZPR_MY'))
  (undef(order.completed) || parseInt(order.completed) === 0) &&
  parseInt(order.changeProtect) !== 1 &&
  (hasAccess(user, 'ZPR_ALL') || (hasAccess(user, 'ZPR_MY') && order.initiator === user.login)) &&
  !(order.type === 'PZ' && !hasAccess(user, 'ZPR_PZ')) &&
  (undef(order.status) || ['-', 'Готово', 'Error', 'Ok'].includes(order.status));

export const getDisplayName = Component => Component.displayName || Component.name || 'Component';

export const autoHeight = idSelector =>
  document.getElementById(idSelector).offsetTop - document.documentElement.clientHeight;

export const circularReplacer = () => {
  const seen = new WeakSet();

  return (key, value) => {
    if ((typeof value === 'object' || typeof value === 'function') && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }

    return value;
  };
};

export const isShallowEqual = (obj1, obj2) => {
  const a = JSON.stringify(obj1, circularReplacer());
  const b = JSON.stringify(obj2, circularReplacer());

  return a === b;
};

export const isEqualNoCircular = (leftObject, rightObject) => {
  const APP_SOCKET_COMPONENT_KEYS = [
    'socket',
    'subscription',
    'subscriptionExpire',
    'commonNotify',
    'commonSubscription',
  ];
  const trimmedLeftObject = omit(leftObject, APP_SOCKET_COMPONENT_KEYS);
  const trimmedRightObject = omit(rightObject, APP_SOCKET_COMPONENT_KEYS);

  return isEqual(trimmedLeftObject, trimmedRightObject);
};

export const getParentRoute = (routes, pathname) => {
  const branches = matchRoutes(routes, pathname);

  return branches[0].route;
};

export const getMainRoute = (routes, pathname) => {
  const branches = matchRoutes(routes, pathname);

  return branches[1].route;
};

export const getCurrentRoute = (routes, pathname) => {
  const branches = matchRoutes(routes, pathname);

  return branches[0];
};

export const removeProp = (obj, prop) => {
  const rest = { ...obj };

  delete rest[prop];

  return rest;
};

export const arrayToObj = (arr, callback) => arr.reduce(callback, {});

export const isObject = obj => Object.prototype.toString.call(obj) === '[object Object]';

export const objToQS = (obj, prefix) => {
  const QS = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const k = prefix ? `${prefix}[${key}]` : key;
      const v = obj[key];

      if (isObject(v)) {
        !v.hidden && QS.push(objToQS(v, k));
      } else {
        QS.push(`${k}=${encodeURI(v)}`);
      }
    }
  }

  return QS.join('&');
};

export const QSToObj = qs =>
  [...new URLSearchParams(qs).entries()].reduce((q, [k, v]) => ({ ...q, [k]: v }), {});

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const bindHover = (id, oldClass, newClass) => {
  const elem = document.getElementById(id);

  elem
    .addEventListener('mouseover', e => {
      e.target.classList.add(newClass);
      e.target.classList.remove(oldClass);
    })
    .addEventListener('mouseout', e => {
      e.target.classList.add(oldClass);
      e.target.classList.remove(newClass);
    });
};

export const getDaysInMonth = (month, year) => {
  const date = new Date(year, month, 1);

  const days = [];

  while (date.getMonth() == month) {
    days.push(`0${new Date(date).getDate()}`.slice(-2));
    date.setDate(date.getDate() + 1);
  }

  return days;
};

export const getYearsRange = startYear => {
  const years = [];
  const currYear = new Date().getFullYear();

  for (let year = startYear; year < currYear; year++) {
    years.push(year);
  }

  return years;
};

export const hideRow = id => {
  document.getElementById(id).style.display = 'none';
};

export const blockRow = id => {
  document.getElementById(id).style.color = 'var(--color-gray)';
};

export const unblockRow = id => {
  document.getElementById(id).style.color = 'var(--color-black)';
};

export const def = x => typeof x !== 'undefined' && x !== 'undefined';
export const undef = x => typeof x === 'undefined';

export const formatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 2,
});

export const quatroFormatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 4,
});

export const sumFormatter = new Intl.NumberFormat('ru-RU', {
  minimumFractionDigits: 2,
});

export const storeLocationState = state => event => {
  if (event && (event.nativeEvent.which !== 1 || event.nativeEvent.ctrlKey)) {
    localForage
      .setItem('location.state', state)
      /* eslint-disable no-console */
      .catch(err => console.log(err));
  }
};

export const getFilename = header => {
  const pattern = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const utf8pattern = /filename\*=utf-8((['"]+?).*?\2)(.+)/;
  const matches = pattern.exec(header);
  const matchesUtf8 = utf8pattern.exec(header);

  return (
    (matchesUtf8 && decodeURIComponent(matchesUtf8[3])) ||
    (matches && matches[1].replace(/['"]/g, '')) ||
    ''
  );
};

export const download = (blob, filename) => {
  if (def(window.navigator.msSaveBlob)) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const url = window.URL || window.webkitURL;

    try {
      const downloadUrl = url.createObjectURL(blob);

      if (filename) {
        const a = document.createElement('a');

        if (undef(a.download)) {
          window.location = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;

          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      } else {
        window.location = downloadUrl;
      }

      setTimeout(() => {
        URL.revokeObjectURL(downloadUrl);
      }, 100);
    } catch (e) {
      alert('Не удалось получить файл!');
    }
  }
};

export const chunk = (array, chunkSize) =>
  array.reduce(
    (chunks, element, index) =>
      (index % chunkSize ? chunks[chunks.length - 1].push(element) : chunks.push([element])) &&
      chunks,
    [],
  );

export const getLCYCurrencySymbol = code => {
  switch (code) {
    case '':
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    default:
      return '₽';
  }
};

export const getCurrencySymbol = currency => {
  switch (currency) {
    case '':
      return '₽';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    default:
      return '₽';
  }
};

export const getDiff = (a, b) =>
  Object.assign(
    ...Array.from(new Set([...Object.keys(a), ...Object.keys(b)]), k => ({
      [k]: a[k] && Object.is(a[k]) && b[k] && Object.is(b[k]) ? getDiff(a[k], b[k]) : a[k] === b[k],
    })),
  );

export const checkDate = (dateFrom, dateTo) => {
  if (dateFrom && dateTo) {
    const today = new Date().getTime();
    const newDateFrom = Date.parse(dateFrom.split('.').reverse().join('-'));
    const newDateTo = Date.parse(dateTo.split('.').reverse().join('-'));

    return !(newDateFrom <= today && today <= newDateTo);
  }
  return null;
};

export const addEvent = (evnt, elem, func) => {
  if (elem.addEventListener) elem.addEventListener(evnt, func, false);
  else if (elem.attachEvent) {
    elem.attachEvent(`on${evnt}`, func);
  } else {
    elem[`on${evnt}`] = func;
  }
};

export const removeEvent = (evnt, elem, func) => {
  if (elem.removeEventListener) elem.removeEventListener(evnt, func, false);
  else if (elem.detachEvent) {
    elem.detachEvent(`on${evnt}`, func);
  } else {
    elem[`on${evnt}`] = null;
  }
};

export const eventPath = e => {
  if ('composedPath' in e) return e.composedPath();
  if ('path' in e) return e.path;

  const path = [];
  let currentElem = e.target;

  while (currentElem) {
    path.push(currentElem);
    currentElem = currentElem.parentElement;
  }

  if (path.indexOf(window) === -1 && path.indexOf(document) === -1) path.push(document);
  if (path.indexOf(window) === -1) path.push(window);

  return path;
};

/**
 * prepare a value for text element for trigger events manually
 *
 * @param element
 * @param value
 */
export const setNativeValue = (element, value) => {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else {
    valueSetter.call(element, value);
  }
};

export const getBrowser = () => {
  const referer = document.referer == undefined ? 'N/A' : document.referer;
  const device = /Mobi/.test(navigator.userAgent) == true ? 'Mobile' : 'Desktop';

  let browser = '';
  let os = '';

  // Opera 8.0+
  const isOpera = !!window.opr || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== 'undefined';

  // Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(!window['safari'] || window['safari'].pushNotification);

  // Internet Explorer 6-11
  const isIE = /* @cc_on!@ */ !!document.documentMode;

  // Edge 20+
  const isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1+
  const isChrome = !!window.chrome && !!window.chrome.webstore;

  if (isOpera == true) {
    browser = 'Opera';
  } else if (isFirefox == true) {
    browser = 'FireFox';
  } else if (isSafari == true) {
    browser = 'Safari';
  } else if (isIE == true) {
    browser = 'Internet Explorer';
  } else if (isEdge == true) {
    browser = 'Microsoft Edge';
  } else if (isChrome == true) {
    browser = 'Chrome';
  }

  if (device == 'Desktop') {
    if (/Windows/.test(navigator.userAgent) == true) {
      os = 'Windows';
      if (/5.1;/.test(navigator.userAgent) == true) {
        os += ' XP';
      } else if (/6.0;/.test(navigator.userAgent) == true) {
        os += ' Vista';
      } else if (/6.1;/.test(navigator.userAgent) == true) {
        os += ' 7';
      } else if (/6.2/.test(navigator.userAgent) == true) {
        os += ' 8';
      } else if (/10.0;/.test(navigator.userAgent) == true) {
        os += ' 10';
      }

      if (/64/.test(navigator.userAgent) == true) {
        os += ' 64-bit';
      } else {
        os += ' 32-bit';
      }
    } else if (/Macintosh/.test(navigator.userAgent) == true) {
      os = 'Macintosh';
      if (/OS X/.test(navigator.userAgent) == true) {
        os += ' OS X';
      }
    }
  } else if (device == 'Mobile') {
    if (/Windows/.test(navigator.userAgent) == true) {
      os = 'Windows';
      if (/Phone 8.0/.test(navigator.userAgent) == true) {
        os += ' Phone 8.0';
      } else if (/Phone 10.0/.test(navigator.userAgent) == true) {
        os += ' Phone 10.0';
      }
    } else if (/Android/.test(navigator.userAgent) == true) {
      function AndroidVersion() {
        if (/Android/.test(navigator.appVersion)) {
          return navigator.appVersion.match(/Android (\d+).(\d+)/);
        }
      }

      const ver = AndroidVersion();
      os = ver[0];
    } else if (/iPhone;/.test(navigator.userAgent) == true) {
      function iOSversion() {
        if (/iP(hone|od|ad)/.test(navigator.appVersion)) {
          const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
          return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        }
      }

      const ver = iOSversion();
      os = `iOS ${ver[0]}.${ver[1]}.${ver[2]}`;
    } else if (/iPad;/.test(navigator.userAgent) == true) {
      function iOSversion() {
        if (/iP(hone|od|ad)/.test(navigator.appVersion)) {
          const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
          return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        }
      }

      const ver = iOSversion();
      os = `iOS ${ver[0]}.${ver[1]}.${ver[2]}`;
    } else if (/BBd*/.test(navigator.userAgent) == true) {
      os = 'BlackBerry';
    }
  }

  return {
    browser: browser || 'Chrome',
    language: navigator.language,
    user_agent: navigator.userAgent,
    device,
    referer,
    os,
    online: navigator.onLine,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    screen_resolution: `${screen.width} x ${screen.height}`,
    cookie_enabled: navigator.cookieEnabled,
  };
};

export const disabledSameIds = (indexes, id, index) => indexes[id] && indexes[id] !== index;

export const mobileMask = input => {
  const raw = input ? input.replace(/\D+/g, '') : [];

  if (raw[0] == 3 || (raw[0] == 3 && (raw[1] == 7 || raw[1] == 8))) {
    return [
      '+',
      /\d/,
      /\d/,
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
  }
  return ['+', '7', '(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
};
export const smoothScrollToTop = (speed = 250) => {
  if (window && window.scrollY) {
    setTimeout(() => {
      window.scrollTo(0, window.scrollY - speed);
      smoothScrollToTop();
    }, 5);
  }
};

export const checkOverflow = el => {
  const curOverflow = el.style.overflow;

  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';

  const isOverflowing = el.clientWidth < el.scrollWidth;

  el.style.overflow = curOverflow;

  return isOverflowing;
};

export const downloadFile = res =>
  res.payload !== false &&
  res.payload.status !== UNAUTHORIZED &&
  download(res.payload.blob, res.payload.filename);

export const validateMessage = message =>
  message
    .trim()
    .replace(/(^\s*\n*)|(^\n*\s*)|(\n+$)/g, '')
    .replace(/[<>]/g, s => {
      switch (s) {
        case '<':
          return '&lt;';
        case '>':
          return '&gt;';
        default:
          return s;
      }
    });

export const formattedStringToDate = date =>
  /\d{2}.\d{2}.\d{4}/.test(date) ? new Date(date.split('.').reverse().join('-')) : date;

export const checkDateWithFormatted = (dateFrom, dateTo, handlerDate) => {
  if (!handlerDate) {
    return {
      error: true,
      message: 'Что-то пошло не так',
    };
  }

  if (dateFrom && dateTo) {
    const formattedDateFrom = formattedStringToDate(dateFrom);
    const formattedDateTo = formattedStringToDate(dateTo);
    const timeDiff = Date.parse(formattedDateTo) - Date.parse(formattedDateFrom);
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return handlerDate(diffDays, formattedDateFrom, formattedDateTo);
  }

  return {
    error: true,
    message: 'Не выбрана дата',
  };
};

export const anchorToLinkTransform = node => {
  const { type, name, attribs } = node;

  if (type === 'tag' && name === 'a') {
    const { href, type } = attribs;
    if (type === 'link') {
      return <Link to={href} />;
    }
  }
};

export const separateNumberFormatter = bonus => {
  let result = bonus;
  try {
    result = String(Math.round(bonus)).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1\u00a0');
  } catch (e) {
    /* eslint-disable no-console */
    console.log(bonus, e);
  }
  return result;
};

export const getHostName = url => url.replace(/(http(s)?:\/\/)|(\/.*)/g, '');

export const hrefReglamentClaims = isCactus
  ? '/contents/cms/img/files/Cactus_OfficeAssistant/claims_cactus/claims_reglament_cactus.doc'
  : '/contents/cms/img/files/claims_reglament.doc';

const DIFFERENCE = 2;

export const validatePrice = (newPrice, oldPrice) => {
  const minPrice = oldPrice / DIFFERENCE;
  const maxPrice = oldPrice * DIFFERENCE;

  if (newPrice < minPrice || newPrice > maxPrice) {
    return {
      success: false,
      price: Number(newPrice) > Number(oldPrice) ? maxPrice : minPrice,
    };
  }

  return {
    success: true,
    price: newPrice,
  };
};

export const checkMaxQty = argsToCheck => {
  const { value, code, salesLimitType, minPackaged, qtyLimit = DEFAULT_QTY_LIMIT } = argsToCheck;

  if (value > qtyLimit && !(+minPackaged > 1 && salesLimitType && code === STATIONARY_CODE)) {
    alert(
      `Для приобретения товара, превышающего количество ${qtyLimit} шт, необходимо обратиться к менеджеру`,
    );
    return true;
  }
  return false;
};

export function proxyArgsToMethods(objects, args) {
  const proxyArgs = Array.isArray(args) ? args : [args];

  return Object.fromEntries(
    Object.entries(objects).map(([name, func]) => [name, func(...proxyArgs)]),
  );
}

export function findLastIndex(array, predicate) {
  if (array == null) {
    throw new TypeError('Array.prototype.findIndex called on null or undefined');
  }
  if (typeof predicate !== 'function') {
    throw new TypeError('predicate must be a function');
  }

  let l = array.length >>> 0;

  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }

  return -1;
}

export function getNounWithSuffix(number, one, two, five) {
  let n = Math.abs(number) % 100;

  if (n >= 5 && n <= 20) {
    return five;
  }

  n %= 10;

  if (n === 1) {
    return one;
  }

  if (n >= 2 && n <= 4) {
    return two;
  }

  return five;
}
