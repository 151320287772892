import { createAction } from 'redux-actions';

/**
 * events state actions
 */
export const SET_EVENTS_REGION = 'SET_EVENTS_REGION';
export const setEventsRegion = createAction(SET_EVENTS_REGION);

export const SET_EVENTS_BRAND = 'SET_EVENTS_BRAND';
export const setEventsBrand = createAction(SET_EVENTS_BRAND);

export const SET_EVENTS_DATE = 'SET_EVENTS_DATE';
export const setEventsDate = createAction(SET_EVENTS_DATE);
