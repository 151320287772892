// @ts-nocheck
const PATH_MAIN_PAGE = '/';

export const getRoutesByPilot = (routes, menu) => {
  if(!routes) return []

  if(__webpack_chunkname__?.includes('main')){
    return routes
  }

  return routes.map(route => {
    if (menu.includes(route.path?.replace('/:id', '')) || route.path === PATH_MAIN_PAGE) {
      return {
        ...route,
        routes: getRoutesByPilot(route.routes, menu),
        props: {
          ...route.props,
          isNewLayout: true,
        },
      };
    }

    return {
      ...route,
      props: {
        ...route.props,
        apiVersion: 'v1'
      },
      component: route.oldComponent || route.component,
      routes: getRoutesByPilot(route.routes, menu)
    };
  });
}
