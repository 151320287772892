import React, { useCallback, useState, Fragment, useEffect } from 'react';
import CustomDayPickerInput from 'components/customDayPickerInput';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { formatDate } from 'react-day-picker/moment';
import PropTypes from 'prop-types';
import { LoadingSubmitButton } from 'components/loading-sumbit-button';
import { formattedStringToDate } from 'common/util';
import alert from 'components/native/alert/function';
import { getEffectLoading } from 'common/selectors';
import isFunction from 'lodash/isFunction';

const PeriodPicker = ({ type: filterType, model, effect, children, diffDates = 90, ...rest }) => {
  const {
    select: { financeFilters },
  } = useStore();

  const initialDates = useSelector(financeFilters.getFilters(filterType));
  const loading = useSelector(getEffectLoading(model, effect));

  const dispatch = useDispatch();
  const {
    financeFilters: { save },
  } = dispatch;

  const [date, setDate] = useState(initialDates);
  const [isRequested, setIsRequested] = useState(false);

  useEffect(() => {
    loading && setIsRequested(true);
  }, [loading]);

  const dateFrom = formatDate(date['dateFrom'], 'DD.MM.Y', 'ru');
  const dateTo = formatDate(date['dateTo'], 'DD.MM.Y', 'ru');

  const handleDayChange = type => async selectedDay => {
    setDate(state => ({ ...state, [type]: selectedDay }));

    await save({
      type: filterType,
      [type]: selectedDay,
    });
  };

  const handleChoose = useCallback(async () => {
    if (!dispatch[model][effect]) {
      console.error(`Model ${model} and effect ${effect} not found in store!`);
      return false;
    }

    if (dateFrom && dateTo) {
      const timeDiff = Math.abs(
        Date.parse(formattedStringToDate(dateTo)) - Date.parse(formattedStringToDate(dateFrom)),
      );
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (diffDays <= 0) {
        alert('Начальная дата периода должна быть меньше конечной даты.');
        return false;
      } else if (diffDays > diffDates) {
        alert(`Период не должен превышать ${diffDates} дней.`);
        return false;
      }

      const { success, message } = await dispatch[model][effect]({ dateFrom, dateTo });

      if (!success) {
        alert(Object.values(message).map(error => `${error}<br />`));
      }
    }
  }, [dateFrom, dateTo]);

  return (
    <Fragment>
      <div className="main-block payments-main-block">
        <div className="datepicker-block">
          <label>Дата C</label>
          <CustomDayPickerInput value={dateFrom} onDateInputChange={handleDayChange('dateFrom')} />
          <label>По</label>
          <CustomDayPickerInput value={dateTo} onDateInputChange={handleDayChange('dateTo')} />
        </div>
        <div className="datepicker-input">
          <LoadingSubmitButton
            label="Показать"
            fetching={loading}
            btnClass="btn btn-danger btn--rect"
            onClick={handleChoose}
            {...rest}
          />
        </div>
      </div>
      {isFunction(children) && children({ isRequested, dateTo, dateFrom })}
    </Fragment>
  );
};

PeriodPicker.propTypes = {
  type: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  effect: PropTypes.string.isRequired,
  diffDates: PropTypes.number,
};

export default PeriodPicker;
