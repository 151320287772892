import React, { useEffect, Fragment, forwardRef, useRef, useCallback, memo, useMemo } from 'react';
import cn from 'classnames';
import TableList from 'components/table/list';
import { getScrolledElements, initScrolls, syncScrollWidth } from '../helpers/eventListeners';
import { setPage, setSorted } from '../action/state';
import { empty } from 'components/empty';
import { ITEMS_TABLE_NAME } from '../constances';
import { OrderEditContext } from './order-edit/context';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getSimpleView } from 'pages/Orders/selectors';
import ReactTable from 'react-table-v6';
import ItemsLoader from 'pages/Orders/components/common/items-loader';
import useContextSelector from 'hooks/context/useContextSelector';
import useContextDispatch from 'hooks/context/useContextDispatch';
import useDeepEffect from "hooks/use-deep-effect"
import useListData from "components/table/hooks/useListData"

import styles from 'common/layout/container/container.module.scss';

const defaultFilterMethod = (filter, row) => filter.check?.(row) || row[filter.id] === filter.value;

const getTrGroupProps = (state, ri = {}) => {
  const isOrder = ri?.original?.lineNo;
  const isCatalog = !ri?.original?.lineNo;
  const isEmpty =
    Number(ri?.original?.availableClient) === 0 &&
    Number(ri?.original?.availableMSK1) === 0;

  const isEmptyInOrder = isOrder && isEmpty;
  const isEmptyInCatalog = isCatalog && isEmpty;

  return ({
    className:
      (ri?.row?.status && `rt-tr-group--${ri?.row?.status?.toLowerCase()}`) ||
      (isEmptyInOrder && 'rt-tr-group__is-empty-in-order') ||
      (isEmptyInCatalog && 'rt-tr-group__is-empty-in-catalog') ||
      (ri?.original?.lineNo && 'rt-tr-group__order-item'),
  });
}
const getTableProps = () => ({ id: 'items-list-table' });
const getHeaderTableProps = () => ({
  id: 'items-list-table-header',
  className: 'rt-table__header',
});
const getTbodyProps = () => ({ id: 'items-list-body' });
const getPaginationProps = () => ({ isManual: false });
const getTdProps = () => ({ className: 'orders__table__td' });

const ItemsTable = (props, ref) => {
  const { noBody, visibility, ...rest } = props;
  const { select } = useStore();
  const pageSize = useSelector(select.listSettings.getPageSize(ITEMS_TABLE_NAME));
  const filtered = useSelector(select.filters.getActive);
  const items = useListData();
  const simpleView = useSelector(getSimpleView);

  const { filters, listSettings } = useDispatch();

  const sorted = useContextSelector(OrderEditContext, state => state.sorted);
  const page = useContextSelector(OrderEditContext, state => state.page);
  const stateDispatch = useContextDispatch(OrderEditContext);

  const prevItemsLength = useRef(0);
  const prevSimpleView = useRef(false);

  const getTrProps = useCallback(
    (state, info) => {
      if (!visibility.image || !info?.original?.imageUrl) {
        return { className: 'orders__table__row orders__table__row-small' };
      }

      return { className: 'orders__table__row' };
    },
    [visibility],
  );

  const handleSortedChange = useCallback(newSorted => stateDispatch(setSorted(newSorted)), []); // eslint-disable-line
  const handlePageChange = useCallback(pageIndex => stateDispatch(setPage(pageIndex)), []); // eslint-disable-line

  const onPageSizeChange = useCallback(async pageSize => {
    stateDispatch(setPage(0));

    await listSettings.pageSizeChange({ size: pageSize, name: ITEMS_TABLE_NAME });
  }, []); // eslint-disable-line

  useEffect(() => {
    const tbody = document.getElementById('items-list-body');
    const elements = getScrolledElements();
    const { scroll, table, header } = elements;

    if (!scroll) {
      return;
    }

    initScrolls(elements);
    syncScrollWidth({ scroll, table, header });

    if (prevSimpleView.current !== simpleView) {
      scroll.scrollLeft = table.scrollLeft = 0;
      prevSimpleView.current = simpleView;
    }

    if (prevItemsLength.current !== items.length) {
      let allPages = 1;
      const allItems = items.length;

      if (pageSize < allItems) {
        const ceilPages = parseInt(allItems / pageSize);
        allPages = allItems % pageSize ? ceilPages + 1 : ceilPages;
      }

      allPages - 1 < page && stateDispatch(setPage(page - 1));

      tbody.scrollTop = 0;
      prevItemsLength.current = items.length;
    }

    table.scrollLeft = header.scrollLeft;
  }, [simpleView, items, pageSize]); // eslint-disable-line

  useDeepEffect(() => {
    if(ref){
      setTimeout(() => filters.disableGroups({...ref}), 0);
    }
  }, [filtered], false); // eslint-disable-line

  const TableHeader = useMemo(
    () => (
      <ReactTable
        className="items-list"
        {...rest}
        onSortedChange={handleSortedChange}
        getTableProps={getHeaderTableProps}
        TbodyComponent={empty}
        TfootComponent={empty}
        noDataText=""
        loading={false}
        loadingText={null}
      />
    ),
    [rest.columns],
  );

  const TableBody = (
    <TableList
      className="items-list"
      name={ITEMS_TABLE_NAME}
      defaultFilterMethod={defaultFilterMethod}
      filtered={filtered}
      {...rest}
      getTrGroupProps={getTrGroupProps}
      getTableProps={getTableProps}
      getTbodyProps={getTbodyProps}
      getPaginationProps={getPaginationProps}
      getTrProps={getTrProps}
      getTdProps={getTdProps}
      ref={ref}
      page={page}
      sorted={sorted}
      onPageChange={handlePageChange}
      onPageSizeChange={onPageSizeChange}
      minRows={1}
      showPagination={items.length > pageSize}
    />
  );

  return noBody ? (
    TableHeader
  ) : (
    <Fragment>
      <ItemsLoader />
      {TableBody}
      <div id="fake-scroll" className={cn(styles['fake-scroll'], styles['fake-scroll--sticky'])} >
        <div id="fake-inner-scroll" className="fake-inner-scroll" />
      </div>
    </Fragment>
  );
};

ItemsTable.displayName = 'ItemsTable';
ItemsTable.whyDidYouRender = false;

export default forwardRef(ItemsTable);
