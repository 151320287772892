import React, { useState } from 'react';
import { certsCollector } from 'pages/Certificates/constants';

const FilterSearch = ({ search }) => {
  const [value, setValue] = useState();

  const onChange = ({ target: { value } }) => {
    setValue(value);
    certsCollector.set('search', value);
  };

  return (
    <input
      type="text"
      size="40"
      onChange={onChange}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          search();
        }
      }}
      placeholder="введите наименование или код товара"
      className="form-control form-control-sm"
      value={value}
    />
  );
};

export default FilterSearch;
