import React, {
  useState,
  useLayoutEffect,
  useMemo,
  useCallback,
  forwardRef,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import { getScrolledElements, syncScrollWidth } from 'pages/Orders/helpers/eventListeners';
import ym from 'react-yandex-metrika';
import classnames from 'classnames';
import { isCactus, isDevEnv } from 'common/config';
import isFunction from 'lodash/isFunction';
import { TitleWrapper } from './withTitle';
import ColumnSwitcher from '../components/column-switcher';

const classModal = classnames('Modal', 'Modal__columns', { 'Modal--cactus': isCactus });
const classTitleWidget = classnames('title-widget', 'title-widget__dropdown', {
  'title-widget__dropdown--cactus': isCactus,
});

const TitleContent = React.memo(({ column }) => {
  const { id, title, model, headerContent } = column;

  const dispatch = useDispatch();

  const content = isFunction(headerContent) ? headerContent() : headerContent;

  const [top, setTop] = useState(-9000);
  const [left, setLeft] = useState(-9000);

  const handleMouseEnter = useCallback(e => {
    const rect = e.target.getBoundingClientRect();
    const pageTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

    setTop(rect.bottom + pageTop);
    setLeft(rect.left + rect.width / 2);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setTop(-9000);
    setLeft(-9000);
  }, []);

  return (
    <div className="column-header" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {content}
      <TitleWrapper>
        <div className="title-widget-wrapper" style={{ top, left }}>
          <div className={classTitleWidget}>
            <p dangerouslySetInnerHTML={{ __html: title }} />
            <i
              className="title-widget__link pointed"
              onClick={() => {
                dispatch[model]?.visibilityPersist({ [id]: false });
              }}
            >
              Скрыть колонку
            </i>
          </div>
        </div>
      </TitleWrapper>
    </div>
  );
});

/**
 * HOC to add toggleable visibility for table columns with storing visibility
 *
 * @param {import('react-table-v6').Column<any>|object} [firstColumn = {}] - config for switcher column
 *
 * @returns {function(React.ComponentType): React.ForwardRefExoticComponent<React.PropsWithoutRef<{}>  & React.RefAttributes<unknown>>}
 */
const withToggle =
  (firstColumn = {}) =>
  TableComponent => {
    const Toggleable = (props, ref) => {
      const { columns, model, saved, visibility, toggleable, ...tableProps } = props;

      if (toggleable === false) {
        return <TableComponent {...props} ref={ref} />;
      }

      const [cols, setCols] = useState([]);

      const { TheadComponent } = tableProps;

      const dispatch = useDispatch();

      const isEmptyHeader = typeof TheadComponent === 'function' && !TheadComponent();

      const [isOpen, setIsOpen] = useState(!saved);
      const [top, setTop] = useState(250);
      const [left, setLeft] = useState(50);

      useLayoutEffect(() => {
        const { scroll, table, header } = getScrolledElements();

        scroll && table && header && syncScrollWidth({ scroll, table, header });
      }, [visibility]);

      const columnsForSwitcher = useMemo(
        () =>
          columns.map(column =>
            column.id in visibility ? { ...column, show: visibility[column.id] } : column,
          ),
        [visibility, columns],
      );

      const handleOpen = e => {
        const targetRect = e.target.getBoundingClientRect();

        setTop(targetRect.top);
        setLeft(targetRect.left);
        setIsOpen(true);
      };

      const SwitchController = useMemo(
        () => (
          <div className="text-left">
            <span
              title="Настройка колонок"
              className="icon icon-column-settings colvis-switcher"
              onClick={handleOpen}
            />
          </div>
        ),
        [],
      );

      useEffect(() => {
        const colsWithTitle = columns.map(column => ({
          ...column,
          show: column.id in visibility ? visibility[column.id] : column.show !== false,
          headerContent: column.Header,
          Header: TitleContent,
          model,
        }));

        setCols([
          {
            Header: SwitchController,
            id: 'switcher',
            className: 'frozen',
            headerClassName: 'frozen',
            sortable: false,
            width: 40,
            ...firstColumn,
          },
          ...colsWithTitle,
        ]);
      }, [visibility, columns, model]);

      const saveModal = (values, isChanged) => {
        setIsOpen(false);

        if (isChanged) {
          !isDevEnv && ym('reachGoal', 'CUSTOM_VIEW');

          dispatch[model]?.visibilityPersist(values);
        }
      };

      return (
        <div id="column-list-switcher" className="columns-toggle-switcher">
          <div className="table-list">
            <TableComponent
              visibility={visibility}
              ref={ref}
              {...tableProps}
              columns={cols}
              model={model}
            />
          </div>
          {!isEmptyHeader && (
            <ReactModal
              isOpen={isOpen}
              onRequestClose={() => setIsOpen(false)}
              bodyOpenClassName="ReactModal__Body--open ReactModal__Body--open__columns"
              contentLabel="Modal"
              className={classModal}
              overlayClassName="Overlay Overlay__columns"
              ariaHideApp={false}
              style={{
                content: {
                  top: `${top}px`,
                  left: `${left}px`,
                },
              }}
            >
              <ColumnSwitcher
                columns={columnsForSwitcher}
                save={saveModal}
                saved={saved}
                model={model}
                initial={visibility}
              />
            </ReactModal>
          )}
        </div>
      );
    };

    Toggleable.displayName = 'Toggleable';
    // Toggleable.whyDidYouRender = true;

    return forwardRef(Toggleable);
  };

export default withToggle;
