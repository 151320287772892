// @ts-nocheck
import { availabilityFilter, DEFAULT_AVAIL_VALUE} from '../constants/availability';

export const getAvailTag = (avail = DEFAULT_AVAIL_VALUE) => ({
  ...availabilityFilter,
  parameters: availabilityFilter.values.map(item => ({
    ...item,
    checked: item.id === avail,
  })),
  operator: {
    id: 'radio',
    label: 'radio',
    value: 'radio',
  },
});
