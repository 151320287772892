export const THEME_COLORS = {
  white: '#fff',
  black: '#000',
  'brand-red': '#f15b5b',
  'brand-red-hover': '#ce4343',
  'brand-blue': '#236192',
  'brand-blue-hover': '#0d4572',
  'accent-blue': '#6eb1e7',
  'light-blue': '#edf1fa',
  'accent-yellow': '#ffa900',
  'accent-yellow-hover': '#ee9d00',
  'light-yellow': '#fff7e6',
  'accent-green': '#008000',
  'accent-green-hover': '#007000',
  'light-green': '#effaec',
  gray: '#808080',
  'dark-gray': '#333',
  'light-gray': '#f0f0f0',
  'stroke-gray': '#d4d4d4',
};
