// @ts-nocheck
import get from 'lodash/get';
import map from 'lodash/map';
import dateFnsIsAfter from 'date-fns/isAfter';
import dateFnsIsToday from 'date-fns/isToday';
import alert from 'components/native/alert/function';
import { isObject, proxyArgsToMethods } from 'common/util';
import noImage from 'common/assets/images/no-image.png';
import { convertDateString } from 'utils/new/date';
import {
  formatCurrency,
  formatNumber,
  removeCurrencySymbol,
  toPrimalNumber,
} from 'utils/new/numbers/format-number';

import {
  composeGoodIcons,
  makeContractorRows,
  makePackingRows,
  makeRepresentativeRows,
  makeShipmentAgentRows,
  makeShipmentDatesRows,
  makeShipmentMethodRows,
  makeShipmentEndpointRows,
  prepareOrderForRequest,
  changeOrder,
} from '../utils';
import * as effects from './effects';

export default {
  name: 'newOrder',
  state: {
    fetching: false,
    order: null,
    orderSettings: null,
    orderSaveStatus: '',
    orderSaveStatusText: '',
    ordersShipment: [],
    orderShipmentSubscription: [],
    orderApiStatus: '',
    orderApiStatusText: '',
    orderGoodDeleteApiStatus: '',
    orderGoodDeleteApiStatusText: '',
    goods: {
      data: [],
      fetching: false,
    },
    relatedGoods: {},
    analogGoods: {},
    orderSaveJobId: '',
    orderGoodDeleteJobId: '',
  },
  reducers: {
    fetchOrderRequest: (state, orderNo) => {
      if (!state.order) {
        state.fetching = true;
      }

      if (state.order && state.order.orderNo !== orderNo) {
        state.order = null;
        state.goods = {
          data: [],
          fetching: false,
        };
        state.relatedGoods = {};
        state.analogGoods = {};
        state.fetching = true;
      }

      return state;
    },
    'order/load': (state, payload) => {
      const data = get(payload, 'data', null);

      if (isObject(data?.order) && Array.isArray(data?.data)) {
        state.order = { ...data.order, status: data.order?.statusV2 };
        state.goods.data = data.data;
      }

      state.fetching = false;
      state.orderSaveStatus = '';
      state.orderSaveStatusText = '';

      return state;
    },
    saveOrderRequest: (state, payload) => {
      state.orderSaveStatus = 'fetching';
      state.orderSaveStatusText = payload;
    },
    pullSaveOrderError: (state, payload) => {
      state.orderSaveStatus = 'error';
      state.orderSaveStatusText = payload.textError;
    },
    pullSaveOrderSuccess: (state, payload) => {
      state.orderSaveStatus = 'done';
      state.orderSaveStatusText = payload.textSuccess;
    },
    pullSaveOrderFinish: state => {
      state.orderSaveStatus = '';
      state.orderSaveStatusText = '';
    },
    fetchOrderSettingsSuccess: (state, payload) => {
      state.orderSettings = get(payload, 'orderSettings', null);
      return state;
    },
    updateOrder: (state, payload) => {
      state.order = state.order ? changeOrder(state.order, payload) : state.order;
      return state;
    },
    setHeader: (state, payload) => ({
      ...state,
      order: { ...(state.order || {}), ...payload },
    }),
    fetchRepresentativesSuccess: (state, payload) => {
      state.orderSettings = {
        ...state.orderSettings,
        representatives: get(payload, 'representatives', null),
      };
      return state;
    },
    fetchShipmentDatesSuccess: (state, payload) => {
      state.orderSettings = {
        ...state.orderSettings,
        shipmentDates: get(payload, 'data', null),
      };
      return state;
    },
    fetchShipmentTimeSuccess: (state, payload) => {
      state.orderSettings = {
        ...state.orderSettings,
        shipmentTime: get(payload, 'data', null),
      };
      return state;
    },
    deleteOrderGoodRequest: (state, payload) => {
      state.orderGoodDeleteApiStatus = 'fetching';
      state.orderGoodDeleteApiStatusText = payload;
      return state;
    },
    pullOrderGoodError: (state, payload) => {
      state.order = get(payload, 'order', null);
      state.orderGoodDeleteApiStatus = 'error';
      state.orderGoodDeleteApiStatusText = payload.textError;
      return state;
    },
    pullOrderGoodSuccess: (state, payload) => {
      const goodId = get(payload, 'goodId', null);
      state.order = get(payload, 'order', null);
      state.goods.data = state.goods.data.filter(goodItem => goodItem.id !== goodId);
      state.orderGoodDeleteApiStatus = 'done';
      state.orderGoodDeleteApiStatusText = payload.textSuccess;
      return state;
    },
    pullOrderGoodFinish: state => {
      state.orderGoodDeleteApiStatus = '';
      state.orderGoodDeleteApiStatusText = '';
      return state;
    },
    setOrderSaveJobId: (state, payload) => {
      state.orderSaveJobId = payload.jobId;
      return state;
    },
    setOrderGoodDeleteJobId: (state, payload) => {
      state.orderGoodDeleteJobId = payload.jobId;
      return state;
    },
    clearOrder: state => ({ ...state, order: null }),
    'newOrdersNew/deleteOrder': state => ({ ...state, order: null }),
  },
  effects(dispatch) {
    const type = this.name;
    const { api } = dispatch;

    return {
      ...proxyArgsToMethods(effects, [dispatch, type]),
      async fetchOrder(orderNo) {
        try {
          this.fetchOrderRequest(orderNo);

          return await api.get({
            url: `new-order/view/${orderNo}`,
          });
        } catch (err) {
          return null;
        }
      },
      async fetchOrderHeader(orderNo) {
        return await api.get({
          url: `new-order/header/${orderNo}`,
        });
      },
      async saveOrder(order) {
        try {
          this.saveOrderRequest('Изменения сохраняются');
          const result = await api.post({
            url: `new-order/save/${order.orderNo}`,
            data: prepareOrderForRequest(order),
          });

          this.setOrderSaveJobId(result);
          return result;
        } catch (err) {
          return null;
        }
      },
      async fetchOrderSettings(uid) {
        try {
          const result = await api.get({
            url: `new-order/settings/get${uid ? `/${uid}` : ''}`,
          });
          this.fetchOrderSettingsSuccess(result);
          return result;
        } catch (err) {
          return null;
        }
      },
      expire() {
        alert('Ваш запрос обрабатывается. Обновите страницу через некоторое время.');
      },
    };
  },
  selectors: (slice, createSelector, hasProps) => ({
    getOrder() {
      return slice(order => {
        const orderInstance = get(order, ['order'], null);

        if (orderInstance) {
          return {
            ...orderInstance,
            apiStatus: orderInstance.apiStatus || '',
            apiStatusText: orderInstance.apiStatusText || '',
            blankedOrderNo: orderInstance.blankedOrderNo
              ? orderInstance.blankedOrderNo.replace(/[\n\r\s\t]+/g, '')
              : '',
            dueDate:
              dateFnsIsToday(convertDateString(orderInstance.dueDate)) ||
              dateFnsIsAfter(convertDateString(orderInstance.dueDate), new Date())
                ? orderInstance.dueDate
                : '',

            price: formatCurrency(toPrimalNumber(removeCurrencySymbol(orderInstance.price)), {
              currency: 'USD',
            }),
            priceRUB: formatCurrency(toPrimalNumber(removeCurrencySymbol(orderInstance.priceRUB)), {
              currency: 'RUB',
            }),
            status: orderInstance.status?.replace(/\-/gi, '')
              ? orderInstance.status
              : orderInstance.statusV2,
            volume: formatNumber(orderInstance.volume),
            weight: formatNumber(orderInstance.weight),
          };
        }

        return null;
      });
    },
    getOrderFetching() {
      return slice(order => get(order, ['fetching'], false));
    },
    getOrderSaveStatus() {
      return slice(order => {
        const orderSaveStatus = get(order, ['orderSaveStatus'], '');
        const orderSaveStatusText = get(order, ['orderSaveStatusText'], '');

        return {
          orderSaveStatus,
          orderSaveStatusText,
        };
      });
    },
    getGoods() {
      return slice(order => {
        const goods = get(order, ['goods', 'data'], []);

        return map(goods, good => ({
          ...good,
          anywhere:
            parseFloat(good.availableMSK1Raw) +
            parseFloat(good.availableOfficeRG) +
            parseFloat(good.transitReserveAvail),
          availableExpected: formatNumber(good.availableExpected, { fractions: 0 }),
          availableExpectedNext: formatNumber(good.availableExpectedNext, { fractions: 0 }),
          cancelReserveCostHr: formatNumber(good.cancelReserveCostHr, { fractions: 2 }),
          code: `${good.id} ${good.vendorPart}`,
          count: good.qty,
          desiredCount: good.desiredQty,
          img: good.imageUrl ? good.imageUrl : noImage,
          inventoryMSK1Raw: formatNumber(good.inventoryMSK1Raw),
          minPackaged: toPrimalNumber(good.minPackaged),
          desiredPriceRUB: formatNumber(toPrimalNumber(good.desiredPriceRUB), { fractions: 2 }),
          desiredPriceUSD: formatNumber(toPrimalNumber(good.desiredPriceUSD), {
            fractions: 2,
          }),
          priceUSD: formatNumber(toPrimalNumber(good.priceOrderUSD), { fractions: 2 }),
          priceRUB: formatNumber(toPrimalNumber(good.priceOrderRUB), {
            fractions: 2,
          }),
          priceListPriceRUB: formatNumber(toPrimalNumber(good.priceLCY), { fractions: 2 }),
          priceListPriceUSD: formatNumber(toPrimalNumber(good.priceClient), {
            fractions: 2,
          }),
          // nextPriceRUB: formatNumber(toPrimalNumber(good.desiredPriceRUB), { fractions: 2 }),
          // nextPriceUSD: formatNumber(toPrimalNumber(good.desiredPriceClient), {
          //   fractions: 2,
          // }),
          nextPriceRUB: 0,
          nextPriceUSD: 0,
          nextCount: null,
          status:
            parseFloat(good.transitReserveAvail) > 0 && good.status === 'transit' ? 'transit' : '',
          statuses: composeGoodIcons(good),
          stock: parseFloat(good.availableClient),
          // stock: good.availableClient,
          transitReserveAvail: formatNumber(good.transitReserveAvail),
          warehouseOfficeRGRaw: formatNumber(good.warehouseOfficeRGRaw),
        }));
      });
    },
    getOrderGoodDeleteStatus() {
      return slice(order => {
        const orderGoodDeleteApiStatus = get(order, ['orderGoodDeleteApiStatus'], '');
        const orderGoodDeleteApiStatusText = get(order, ['orderGoodDeleteApiStatusText'], '');

        return {
          orderGoodDeleteApiStatus,
          orderGoodDeleteApiStatusText,
        };
      });
    },
    getOrderSettings() {
      return slice(order => {
        const orderSettings = get(order, ['orderSettings'], null);

        if (orderSettings) {
          return {
            ...orderSettings,
            contractorRows: makeContractorRows(orderSettings.counterAgentRows),
            packingRows: makePackingRows(orderSettings.packingRows),
            representatives: makeRepresentativeRows(orderSettings.representatives),
            shipmentAgentRows: makeShipmentAgentRows(orderSettings.shipmentAgentRows),
            shipmentDatesRows: makeShipmentDatesRows(orderSettings.shipmentDates),
            shipmentMethodRows: makeShipmentMethodRows(orderSettings.shipmentMethodRows),
            shipmentEndpointRows: makeShipmentEndpointRows(orderSettings.shipmentEndpointRows),
            shipmentDates: orderSettings.shipmentDates,
            shipmentTime: orderSettings?.shipmentTime?.intervals[0],
          };
        }

        return null;
      });
    },
    getOrdersShipment() {
      return slice(newOrder => get(newOrder, 'ordersShipment', []));
    },
    getOrderShipmentSubscription() {
      return slice(newOrder => get(newOrder, 'orderShipmentSubscription', []));
    },
  }),
};
