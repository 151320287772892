import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import { regExpOnlyNumber, regExpNumberPhone } from 'helpers/regexpes';
import SHIPPING_AGENT from '../constants/types-shiping-agent';
import alert from '../../../components/native/alert/function';

export const highlightOptions = (options, text) => {
  const textSplited = text.split(' ').join('|');
  options.forEach(item => {
    const reg = new RegExp(textSplited, 'g');
    item.highlighted = item.name.toLowerCase().replace(reg, '<b>$&</b>');
  });
  return options;
};

export const handleWithCustomRule = maxLength => input => e => {
  const { value } = e.target;
  if (regExpOnlyNumber.test(value)) {
    const newValue = value.length > maxLength ? value.substr(0, maxLength) : value;
    input.onChange(newValue);
  }
};

export const handleWithRuleMaxLength = maxLength => input => e => {
  const { value } = e.target;
  const newValue = value.length > maxLength ? value.substr(0, maxLength) : value;
  input.onChange(newValue);
};

export const formattingDataShipingAgentForm = values => {
  if (values.typeShipingAgent === SHIPPING_AGENT.tk) {
    const { typeShipingAgent, shipingAgent } = values;
    return {
      typeShipingAgent,
      shipingAgent,
    };
  }
  const timeFrom = get(values, ['time', 'timeFrom'], '08:00');
  const timeTo = get(values, ['time', 'timeTo'], '08:00');
  const paidEntry = values && values.paidEntry ? '1' : '0';

  return {
    ...values,
    timeFrom,
    timeTo,
    paidEntry,
    files: values.otherFiles && values.otherFiles[0],
    rent: undefined,
    time: undefined,
    shipingAgent: undefined,
  };
};

export const formattingPhone = numberPhone => {
  if (!numberPhone) return null;

  const clearNumberPhone = numberPhone.replace(/\D/g, '');
  return clearNumberPhone.replace(regExpNumberPhone, '+7 ($2) $3-$4-$5');
};

export const formattingDataClaim = values => {
  const formattedProducts = {};

  values.products.forEach((product, index) => {
    const { itemId, factQty, claimType, comment, file } = product;
    if (factQty && claimType) {
      formattedProducts[`itemId[${index}]`] = itemId;
      formattedProducts[`factQty[${itemId}]`] = factQty;
      formattedProducts[`claimType[${itemId}]`] = claimType && claimType.value;
      formattedProducts[`comment[${itemId}]`] = comment;
      formattedProducts[`attachname[${itemId}]`] = file && file[0];
    }
  });

  return {
    ...values,
    ...formattedProducts,
    products: undefined,
  };
};

export const isDoubleClaimAlert = (claims = [], order) => {
  const docsNo = claims.reduce(
    (acc, { salesInvoiceNo, docNo }) => (salesInvoiceNo === order && [...acc, docNo]) || acc,
    [],
  );

  const uniqDocs = [...new Set(docsNo)];
  docsNo.length && alert(`На заказ ${order} уже существует претензия ${uniqDocs.join(', ')}`);
};

export const getMessages = (obj, res = []) =>
  Object.keys(obj).reduce((acc, prop) => {
    if (typeof obj[prop] === 'object') {
      return getMessages(obj[prop], acc);
    }

    return [...acc, obj[prop]];
  }, res);

export const getMessageByClaim = message => {
  if (!isPlainObject(message)) {
    return 'Форма не отправлена';
  }

  return getMessages(message).join('</br>');
};
