import { proxyArgsToMethods } from 'common/util';
import * as effects from './effects';

export default {
  name: 'claims',
  state: {
    list: [],
    products: [],
  },
  reducers: {
    load: (state, { data }) => ({ ...state, list: data.data }),
    loadProducts: (state, { data }) => ({ ...state, products: data?.data || [] }),
    clearProducts: state => ({ ...state, products: [] }),
    addProducts: (state, products) => ({
      ...state,
      products: [...state.products, ...products],
    }),
  },
  effects: dispatch => proxyArgsToMethods(effects, dispatch),
};
