import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect';
import { get, isEmpty, memoize, isEqual, cloneDeep } from 'lodash';

import parseJsonObjectKeys from 'utils/parseJsonObjectKeys';
import { def } from 'common/util';
import { sortByFavorite, sortByName } from 'helpers/sortCategories';
import { compose } from 'redux';
import { FOUND_WITH_AVAILABILITY } from 'common/config';
import { preorderTypes } from 'pages/Orders/constances/preorder';
import { formValueSelector } from 'redux-form';
import { getEffectLoading } from 'common/selectors';
import cleanFilterGroups from '../components/common/order-list-filter/helpers/cleanFilterGroups';
import { buildFilterStrategies } from '../components/common/order-list-filter/helpers/buildFilterStrategies';
import { SIMPLIFIED_COLUMNS } from '../components/order-edit/helpers/simplifiedColumns';
import assignFilteredFacets from '../components/common/order-list-filter/helpers/clientData/assignFilteredFacets';
import unblockFilters from '../components/common/order-list-filter/helpers/clientData/unblockFilters';
import { SERVER_BAD_ID, SERVER_ID } from '../constances/groups';
import createOrderContractorCode from '../helpers/createOrderContractorCode';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const ordersSelect = state => get(state, 'order', {});
export const getOrderItems = state => get(state, ['order', 'rows'], []) || [];

export const getOrderView = state => get(state, ['order', 'header'], {}) || {};
export const isZprOrder = createSelector(getOrderView, order => Number(order.docType) === 1);

export const isOrderPreorder = createSelector(
  getOrderView,
  order => order.docType === preorderTypes.docType && order.tender === preorderTypes.tender,
);

export const getUser = state => get(state, ['user', 'info'], {}) || {};

export const getIsOrderFromWarehouse = createSelector(
  ordersSelect,
  orders => orders.isOrderFromWarehouse,
);

export const getOrder = createSelector(getOrderView, order => order);

export const getNullOrder = createSelector(getOrder, order => (!isEmpty(order) && order) || null);

export const getOrderSettings = createSelector(
  state => get(state, ['user', 'orderSettings'], {}) || {},
  orderSettings =>
    parseJsonObjectKeys(orderSettings, [
      'counterAgentRows',
      'shipmentAgentRows',
      'shipmentEndpointRows',
      'packingRows',
      'shipmentMethodRows',
      'workDateRows',
      'preorderShipmentMethodRows',
      'shipmentDate',
    ]),
);

export const getOrderShipmentMethods = createSelector(
  state => get(state, ['user', 'orderSettings'], {}) || {},
  orderSettings => parseJsonObjectKeys(orderSettings, ['shipmentMethodRows']),
);

export const getContractorCode = createSelector(
  getUser,
  getNullOrder,
  getOrderSettings,
  (user, order, settings) =>
    createOrderContractorCode({
      user,
      order,
      counterAgentRows: settings.counterAgentRows,
      shipmentMethodRows: settings.shipmentMethodRows,
    }),
);

export const getShipmentAgentOptions = createSelector(
  getOrderSettings,
  ({ shipmentAgentRows = [] }) =>
    shipmentAgentRows.map(shipmentAgent => ({
      value: `${shipmentAgent['Code'] || ''}#${shipmentAgent['Agent Type'] || ''}`,
      label: `${shipmentAgent['Name']} | ${shipmentAgent['Address']}`,
    })),
);

export const getShipmentMethodRows = createSelector(getOrderSettings, settings =>
  get(settings, 'shipmentMethodRows', []),
);

export const getDefaultShipmentMethod = createSelector(
  getShipmentMethodRows,
  methods => methods.find(method => +method['IsDefault']) || methods[0] || {},
);

export const getIsRegional = createSelector(getOrderSettings, settings =>
  parseInt(get(settings, 'isRegional', 0)),
);

export const getOrderIsRegional = createSelector(getOrder, order =>
  parseInt(get(order, 'isRegional', 0)),
);

export const getIsRegionalShipment = createSelector(
  getIsRegional,
  getOrder,
  (userSettings, order) =>
    isEmpty(order) ? userSettings !== 1 : get(order, 'isRegional', 0) !== 1,
);

export const getShipmentMethod = createSelector(
  getNullOrder,
  getShipmentMethodRows,
  (order, shipmentMethods) =>
    (order &&
      shipmentMethods.find(shipmentMethod => shipmentMethod.Code === order.shipmentMethod)) ||
    shipmentMethods[0],
);

const getOrderShipmentDates = createSelector(ordersSelect, order =>
  get(order, 'orderShipmentDates', []),
);

export const getShipmentDates = createSelector(
  state => state.orders.shipmentDates,
  getOrderSettings,
  getOrderShipmentDates,
  (dates, settings, orderShipmentDates) =>
    (orderShipmentDates.length && orderShipmentDates) ||
    (dates.length && dates) ||
    settings.workDateRows,
);

export const getItemsColumns = state => state.itemsColumns;
export const getOrdersColumns = state => state.ordersColumns;

export const getUserItemsVisibility = createSelector(
  getItemsColumns,
  itemsColumns => itemsColumns.userVisibility,
);

export const getUserOrdersVisibility = createSelector(
  getOrdersColumns,
  ordersColumns => ordersColumns.userVisibility,
);

export const getUserItemsColumnsOrder = createSelector(
  getItemsColumns,
  itemsColumns => itemsColumns.userOrder,
);

export const getSimpleView = createSelector(
  getUser,
  getUserItemsVisibility,
  state => state.itemsColumns.simplified,
  (user, userVisibility, simplified) => get(userVisibility, [user.id, 'simplified'], simplified),
);

export const getItemsVisibility = createSelector(
  getUser,
  getUserItemsVisibility,
  state => state.itemsColumns.values,
  getSimpleView,
  (user, itemsVisibility, defaultValues, simpleView) =>
    memoize(isNotPreorder => {
      const userFieldColumns = itemsVisibility[user.id]?.values || defaultValues;

      const columns = simpleView ? SIMPLIFIED_COLUMNS : userFieldColumns;

      if (isNotPreorder) {
        const { deliveryDate, actualyDateFrom, actualyDateTo, ...rest } = columns;

        return rest;
      }

      return columns;
    }),
);

export const isItemsVisibilitySaved = createSelector(
  getUser,
  getUserItemsVisibility,
  (user, visibility) => memoize(userId => def(visibility[user.id || userId])),
);

export const getItemsColumnsOrder = createSelector(
  getUser,
  getUserItemsColumnsOrder,
  state => state.itemsColumns.order,
  (user, userOrder, defaultOrder) => userOrder[user.id] || defaultOrder,
);

export const getOrdersVisibility = createSelector(
  getUser,
  getUserOrdersVisibility,
  state => state.ordersColumns.values,
  (user, ordersVisibility, defaultValues) =>
    ordersVisibility[user.id] ? { ...defaultValues, ...ordersVisibility[user.id] } : defaultValues,
);

export const isOrdersVisibilitySaved = createSelector(
  getUser,
  getUserOrdersVisibility,
  (user, visibility) => memoize(userId => def(visibility[user.id || userId])),
);

export const getItemsFilters = state => state.itemsFilter;

export const getUserItemsFilter = createSelector(getItemsFilters, filters => filters.userFilters);

export const getDefaultItemsFilter = createSelector(getItemsFilters, filters => filters.filter);

export const getItemsBrandsFilter = createSelector(
  getUser,
  getUserItemsFilter,
  getDefaultItemsFilter,
  (user, userFilters, defaultFilters) => {
    let brands = defaultFilters.brands;

    if (userFilters[user.id]?.brands?.length) {
      brands = userFilters[user.id]?.brands;
    }

    if (!Array.isArray(brands)) {
      return [];
    }

    return brands;
  },
);

export const getItemsAvailFilter = createSelector(
  getUser,
  getUserItemsFilter,
  getDefaultItemsFilter,
  (user, userFilters, defaultFilters) =>
    Number((def(userFilters[user.id]) && userFilters[user.id].avail) || defaultFilters.avail),
);

export const getOrderCategories = state => get(state, ['catalog', 'categories'], []);
export const getFavoriteCategories = state => get(state, ['favoriteCategories', 'cats'], {});

export const hasFavoriteCategories = createSelector(getFavoriteCategories, cats =>
  cats ? Object.values(cats).flat().length > 0 : false,
);

const sortCategories = (...args) => {
  const [cats, favCats, forSale] = args;
  const categories = [...cats];

  categories.sort(sortByName).sort(sortByFavorite(Object.keys(favCats)));

  return Number(forSale) ? categories.filter(cat => cat.sale > 0) : categories;
};

export const getSortedCategories = createSelector(
  getOrderCategories,
  getFavoriteCategories,
  state => state.catalog.forSale,
  sortCategories,
);

export const getSortedSubCategories = parentId =>
  createSelector(
    state => state.catalog.subCategories[parentId] || [],
    getFavoriteCategories,
    state => state.catalog.forSale,
    sortCategories,
  );

const getFormState = state => state.form;
const getNamedFormState = formName =>
  createSelector([getFormState], (formState = {}) => formState[formName]);
const registeredFieldsSelector = formName =>
  createSelector(
    [getNamedFormState(formName)],
    (namedFormState = {}) => namedFormState.registeredFields,
  );

export const getRegisteredFields = formName =>
  createSelector([registeredFieldsSelector(formName)], (registeredFields = {}) =>
    Object.values(registeredFields)
      .filter(field => field.count)
      .map(field => field.name),
  );

export const getItems = state => get(state, ['list', 'data'], []);
export const getFilterCharacteristics = state => get(state, ['items', 'characteristics'], []);
export const getFacets = state => get(state, ['items', 'facets'], {});
export const getFilteredFacets = state => get(state, ['orders', 'filteredFacets'], {});
export const getTotalFound = state => get(state, ['orders', 'meta', 'total_found'], 0);

export const getFilterGroups = state => get(state, ['orders', 'filterGroups'], []);
export const getMemoFilters = createSelector(
  state => state.orders,
  orders => memoize(noBody => (noBody ? [] : get(orders, 'filters', []))),
);

export const getFilterTags = state => get(state, ['orders', 'filterTags'], []);

export const getActiveFilterKeys = createSelector(getFilterTags, tags => tags.map(tag => tag.code));

export const getActiveFilterGroups = createSelector(getFilterTags, tags => {
  const groups = tags.map(tag => tag.groupId);

  return [...new Set(groups)];
});

export const getCurrentFilterKey = createSelector(getFilterTags, tags => {
  const last = tags[tags.length - 1];

  return last ? last.groupId : null;
});

export const getServerFilters = createDeepEqualSelector(
  getFilterCharacteristics,
  getFacets,
  (chars, facets) => compose(cleanFilterGroups, buildFilterStrategies)(chars, facets),
);

export const getServerGroups = createSelector(getServerFilters, groups => cloneDeep(groups));

export const getCurrentFilterGroups = createSelector(
  getServerFilters,
  getServerGroups,
  getFilterGroups,
  getFilteredFacets,
  getActiveFilterGroups,
  getCurrentFilterKey,
  getActiveFilterKeys,
  (server, serverGroups, client, facets, activeGroups, activeGroup, activeKeys) => {
    const groups = client.length ? client : server;

    if (!isEmpty(facets)) {
      return assignFilteredFacets(facets, serverGroups)(activeGroup, activeGroups, activeKeys);
    }

    if (!client.length) {
      unblockFilters(serverGroups);
    }

    return groups;
  },
);

export const isNeededBackendFiltration = createSelector(
  getTotalFound,
  totalFound => totalFound > FOUND_WITH_AVAILABILITY,
);

export const hasServerGroupItems = createSelector(getOrderItems, items => {
  const data = (!!items.length && items) || [];

  return data.some(item => item.code1 === SERVER_ID || item.code2 === SERVER_BAD_ID);
});

export const getOrderSplitItems = createSelector(
  state => state.order.splitRows,
  items => (Array.isArray(items) ? items : []),
);

export const getShipmentMethods = createSelector(getOrderSettings, settings =>
  get(settings, 'shipmentMethodRows', []),
);

export const getLastApiCall = createSelector(
  state => state.api.history,
  history => history?.[history.length - 1] || {},
);

export const isLastApiCallItems = createSelector(getLastApiCall, ({ url }) =>
  /(search|group|orders\/new)/gi.test(url),
);

export const getCategories = state => state.catalog.subCategories || [];

export const getSubCategories = createSelector(getCategories, categories =>
  memoize(id => categories[id] || []),
);

export const getActiveCategory = createSelector(
  state => state.catalog.activeCategory,
  state => state.catalog.categories,
  (id, categories) => categories.find(cat => cat.id === id) || {},
);

const selector = formValueSelector('ORDER_EDIT_FORM');
export const getFormValue = fieldName => state => selector(state, fieldName);

export const isFetchingItems = createSelector(
  getEffectLoading('items', 'getByOrder'),
  getEffectLoading('items', 'importFromText'),
  getEffectLoading('items', 'importFromFile'),
  getEffectLoading('items', 'toggleFilters'),
  getEffectLoading('catalog', 'getItems'),
  getEffectLoading('demo', 'getItems'),
  getEffectLoading('items', 'handleSearch'),
  getEffectLoading('items', 'refresh'),
  getEffectLoading('newOrdersNew', 'fetchGoods'),
  getEffectLoading('newOrdersNew', 'fetchGoodsInOrders'),
  getEffectLoading('newSearchHints', 'fetchSearchMain'),
  (...args) => args.some(arg => arg),
);

export const isFetchingOrders = createSelector(
  getEffectLoading('order', 'searchByItem'),
  getEffectLoading('order', 'handleDelete'),
  getEffectLoading('order', 'handleSign'),
  getEffectLoading('order', 'handleExport'),
  (...args) => args.some(arg => arg),
);

export const getGoodsToFind = state => get(state, ['newOrdersNew', 'goodsToFind'], []);
export const getOrders = state => get(state, ['newOrdersNew', 'orders'], []);
