import React, {
  memo,
  Children,
  cloneElement,
  createContext,
  useRef,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { ReactTableDefaults, ReactTable, TableProps } from 'react-table-v6';
import { VariableSizeList as List, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const DynamicListContext = createContext({});

const elementIsGroup = element => element.type === ReactTableDefaults.TrGroupComponent;

const flattenGroups = groups =>
  groups
    .map(group => {
      const children = Children.toArray(group.props.children);
      const embeddedGroups = children.filter(elementIsGroup);
      const rows = children.filter(element => !elementIsGroup(element));

      return [cloneElement(group, { children: rows }), ...flattenGroups(embeddedGroups)];
    })
    .reduce((result, chunk) => result.concat(chunk), []);

const Row = memo(props => {
  const { row, index, style, width } = props;
  const { setSize } = useContext(DynamicListContext);
  const rowRoot = useRef();

  useEffect(() => {
    if (rowRoot.current) {
      setSize?.(index, rowRoot.current.getBoundingClientRect().height);
    }
  }, [setSize, index, width]);

  return (
    <div style={style} key={index} className="rt-tr-group rt-tr-group--virtual">
      <div ref={rowRoot}>{row}</div>
    </div>
  );
}, areEqual);

const TbodyComponent = props => {
  const { rows, children, ...rest } = props;
  const groups = flattenGroups(Children.toArray(children));
  const listRef = useRef();
  const sizeMap = useRef({});

  const setSize = useCallback((index, size) => {
    if (sizeMap.current[index] !== size) {
      sizeMap.current = { ...sizeMap.current, [index]: size };
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    }
  }, []);

  const getSize = useCallback(index => {
    return sizeMap.current[index] || 70;
  }, []);

  const calcEstimatedSize = useCallback(() => {
    const keys = Object.keys(sizeMap.current);
    const estimatedHeight = keys.reduce((p, i) => p + sizeMap.current[i], 0);

    return estimatedHeight / keys.length;
  }, []);

  return (
    <DynamicListContext.Provider value={{ setSize }}>
      <ReactTableDefaults.TbodyComponent {...rest}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              ref={listRef}
              width={width}
              height={height}
              itemData={rows}
              itemCount={rows?.length || 0}
              itemSize={getSize}
              overscanCount={4}
              estimatedItemSize={calcEstimatedSize()}
              className="rt-virtual-list"
            >
              {({ index, ...props }) => (
                <Row
                  row={groups[index]}
                  width={width}
                  {...props}
                  index={index}
                />
              )}
            </List>
          )}
        </AutoSizer>
      </ReactTableDefaults.TbodyComponent>
    </DynamicListContext.Provider>
  );
};

/**
 * @template {ReactTable<TableProps>} T
 * @param {T} Component
 * @return {function(TableProps): T}
 */
export default Component => props => <Component TbodyComponent={TbodyComponent} {...props} />;
