import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './error-message.module.scss';

/**
 * Renders a <ErrorMessage /> component
 * @component
 * @example
 *
 * return (
 *   <ErrorMessage />
 * )
 */
export const ErrorMessage = ({ className, children }) => {
  const classes = cn(styles['error-message'], className);
  return (
    <p className={classes} data-testid="error-message">
      {children}
    </p>
  );
};

ErrorMessage.defaultProps = {
  className: '',
  children: '',
};

ErrorMessage.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * children
   */
  children: PropTypes.string,
};
