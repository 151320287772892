import login from './login';
import shipmentMethod from './shipmentMethod';
import shipmentMethodDefault from './shipmentMethodDefault';
import shipmentInfoDefault from './shipmentInfoDefault';
import currentDate from './currentDate';
import shipmentInfo from './shipmentInfo';
import initiator from './initiator';
import priceRUB from './priceRUB';
import weight from './weight';
import volume from './volume';
import paymentCode from './paymentCode';
import dueDate from './dueDate';
import contractorName from './contractorName';
import orderNo from './orderNo';
import orderDate from './orderDate';
import shipmentDate from './shipmentDate';
import type from './type';
import contact from './contact';
import status from './status';
import postingDescription from './postingDescription';
import endCustomer from './endCustomer';

const headerCreators = [
  login,
  shipmentMethod,
  currentDate,
  shipmentInfo,
  shipmentInfoDefault,
  initiator,
  priceRUB,
  weight,
  volume,
  paymentCode,
  dueDate,
  contractorName,
  orderNo,
  orderDate,
  shipmentDate,
  type,
  contact,
  status,
  postingDescription,
  endCustomer,
  shipmentMethodDefault,
];

export default headerCreators;
