// @ts-nocheck

export const deleteOrderGood = (dispatch, type) => async payload => {
  const { good, orderNo } = payload;
  try {
    dispatch[type].deleteOrderGoodRequest('Изменения сохраняются');

    const result = await dispatch.api.post({
      url: `new-order/delete/row/${orderNo}`,
      data: {
        itemId: good.id,
        lineNo: good.lineNo,
      },
      successPayload: {
        id: good.id,
      },
    });

    dispatch[type].setOrderGoodDeleteJobId(result);

    return result;
  } catch (err) {
    return {};
  }
};
