import React from 'react';
import { useDesign } from 'hooks/new';
import { Textarea } from 'common/ui/textarea';
import { CustomInput } from '../../../common/form-fields/CustomInput';

const EndCustomerField = props => {
  const [isPilot] = useDesign();

  return (
    <div>
      <label>{props.label}</label>
      {isPilot ? (
        <Textarea {...props} {...props.input} />
      ) : (
        <CustomInput {...props} className="form-control" />
      )}
    </div>
  );
};

export default EndCustomerField;
