const createIquoteColumns = [
  {
    Header: 'Код',
    accessor: 'id',
    width: 90,
  },
  {
    Header: 'Наименование',
    accessor: 'name',
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
    width: 300,
  },
  {
    Header: 'Группа',
    accessor: 'group1',
  },
  {
    Header: 'Партномер',
    accessor: 'vendorPart',
    width: 130,
  },
  {
    Header: 'Желаемое Количество',
    accessor: 'qty',
  },
];

export default createIquoteColumns;
