import { CACHE_NAME } from 'common/config';
import alert from 'components/native/alert/function';

export default {
  name: 'balance',
  state: {
    customerBalance: null,
    tenderBalance: null,
    counterpartyBalance: null,
    errorMessage: '',
    searchText: null,
  },
  reducers: {
    loadBalance: (state, payload) => ({
      ...state,
      customerBalance: payload.customerBalance,
      tenderBalance: payload.tenderBalance,
      counterpartyBalance: payload.counterpartyBalance,
    }),
    setSearchText: (state, payload) => ({
      ...state,
      searchText: payload,
    }),
    setErrorMessage: (state, payload) => ({
      ...state,
      errorMessage: payload,
    }),
  },
  effects: ({ api }) => ({
    async getBalanceByDate(date) {
      try {
        const response = await api.get({
          url: '/finances/balance',
          query: `?date=${date}`,
          cache: CACHE_NAME,
        });

        const {
          customerBalance = null,
          tenderBalance = null,
          counterpartyBalance = null,
        } = response;

        this.loadBalance({customerBalance, tenderBalance, counterpartyBalance});
      } catch (error) {
        this.setErrorMessage(error.message);
      }
    },
  }),
  selectors: (slice, createSelector) => ({
    getBalance() {
      return slice(state => state);
    },
    getCustomerBalance() {
      return slice(state => state.customerBalance);
    },
    getTenderBalance() {
      return slice(state => state.tenderBalance);
    },
    getCounterpartyBalance() {
      return slice(state => state.counterpartyBalance);
    },
    getErrorMessage() {
      return slice(state => state.errorMessage);
    },
    getSearchText() {
      return slice(state => state.searchText);
    },
    getFilteredCounterparty() {
      return createSelector(
        this.getCounterpartyBalance,
        this.getSearchText,
        (counterparties, searchText) => {
          if (!searchText) {
            return counterparties;
          }
          return counterparties.filter(counterparty => {
            const lowerCaseFilterText = searchText.toLowerCase();
            return (
                counterparty.name.toLowerCase().includes(lowerCaseFilterText)
                  ) ||
                  (
                    counterparty.code.toString().toLowerCase().includes(lowerCaseFilterText) &&
                    counterparty.code.toString().toLowerCase().startsWith(lowerCaseFilterText)
                  )
          })
        }
      );
    },
  }),
};
