import React from 'react';
import BrowserWarning from 'containers/browser-warning';
import { Footer } from 'common/layout/footer';
import { isCactus } from 'common/config';
import cn from 'classnames';

import styles from './guest-layout-v2.module.scss';

export const GuestLayoutV2 = ({ children }) => (
  <div className={cn(styles['layout'], isCactus && styles['layout-cactus'])}>
    {children}
    <BrowserWarning />
    {!isCactus && <Footer />}
  </div>
);
