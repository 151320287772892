import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Gift = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M15 2.5H3C2.10345 2.5 1.5 3.26835 1.5 4.125V13.875C1.5 14.7317 2.10345 15.5 3 15.5H15C15.8965 15.5 16.5 14.7317 16.5 13.875V4.125C16.5 3.26835 15.8965 2.5 15 2.5Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M17 7H11.2222C9.97778 7 9 7.88 9 9C9 10.12 9.97778 11 11.2222 11H17"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path d="M13 8H11V10H13V8Z" fill={color} />
    </svg>
  );
};

Gift.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Gift.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
