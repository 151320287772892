const OrderColumns = [
  {
    Header: 'Код',
    accessor: 'id',
    width: 90,
  },
  {
    Header: 'Наименование',
    accessor: 'name',
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
  },
  {
    Header: 'Бренд',
    accessor: 'brand',
    width: 90,
  },
  {
    Header: 'Партномер',
    accessor: 'partNo',
    width: 90,
  },
  {
    Header: 'Кол-во',
    accessor: 'qty',
    width: 90,
  },
];

export default OrderColumns;
