import { useDispatch, useSelector, useStore } from 'react-redux';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { def } from 'common/util';

const EmployeeActions = row => {
  if (!row) return null;

  const {
    original: { isBlocked, login },
    index,
  } = row || {};

  const {
    select: {
      employees: { getIsEmployeeBlocking, getIsEmployeeDeleting },
    },
  } = useStore();

  const isRowBlocking = useSelector(getIsEmployeeBlocking(index));
  const isRowDeleting = useSelector(getIsEmployeeDeleting(index));

  const {
    employees: { remove, block },
  } = useDispatch();

  const handleRemove = useCallback(async () => {
    await remove({ login, index });
  }, [login, index]);

  const handleBlock = useCallback(async () => {
    let currentBlocked = isBlocked;

    if (def(isRowBlocking)) {
      currentBlocked = isRowBlocking === 'block';
    }

    await block({ login, index, blocked: currentBlocked });
  }, [login, index, isBlocked, isRowBlocking]);

  const blockedClass = useMemo(() => {
    let blocked = isBlocked;

    if (def(isRowBlocking)) {
      blocked = isRowBlocking === 'block';
    }

    return classNames('icon enabled', {
      'icon-block': blocked,
      'icon-unblock': !blocked,
    });
  }, [isBlocked, isRowBlocking]);

  const blockedTitle = isRowBlocking
    ? isRowBlocking === 'block'
      ? 'Разблокировать'
      : 'Заблокировать'
    : isBlocked
    ? 'Разблокировать'
    : 'Заблокировать';

  return (
    <div className="employee-actions">
      {isRowDeleting ? (
        <i className="loader loader--small" />
      ) : (
        <i className="icon delete-employee" title="Удалить" onClick={handleRemove}>
          &#10005;
        </i>
      )}
      {isRowBlocking === 'wait' ? (
        <i className="loader loader--small" />
      ) : (
        <i className={blockedClass} title={blockedTitle} onClick={handleBlock} />
      )}
      <Link
        to={{
          pathname: '/settings/setup',
          state: { list: true, login },
        }}
      >
        <i className="icon icon-settings" title="Настройки" />
      </Link>
    </div>
  );
};

export default EmployeeActions;
