import _ from 'lodash';
import { getLCYCurrencySymbol } from 'common/util';

const createPriceRUB = ({ order, user, currency }) => {
  const userLCYMigration = parseInt(user['LCY Migration']);
  const priceRUBFirst =
    (order && (userLCYMigration === 1 ? order.priceRUB : order.price)) ||
    `0.00 ${getLCYCurrencySymbol()}`;
  const currencySybmol = getLCYCurrencySymbol(_.get(currency, 'Code', ''));
  const priceRUBSecond =
    (order && (userLCYMigration === 1 ? order.price : order.priceRUB)) || `0.00 ${currencySybmol}`;

  return order?.priceRUB || `0.00 ${getLCYCurrencySymbol()}`;
};

export default createPriceRUB;
