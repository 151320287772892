import { getUser } from 'pages/Orders/selectors';
import saveSettings from 'models/effects/saveSettings';
import isEmpty from 'lodash/isEmpty';

const loadFilters = (state, payload) => {
  const { filter } = payload?.itemsFilters || {};
  const uid = payload.uid || payload.user?.id;

  if (isEmpty(filter)) {
    return state;
  }

  return {
    ...state,
    filter,
    userFilters: {
      ...state.userFilters,
      [uid]: filter,
    },
  };
};

const itemsFilter = {
  name: 'itemsFilter',
  state: {
    filter: {
      brands: [],
      avail: false,
    },
    userFilters: {},
  },
  reducers: {
    persist: (state, { userId, ...rest }) => {
      const filter = { ...state.filter, ...rest };

      return {
        ...state,
        filter,
        userFilters: {
          ...state.userFilters,
          [userId]: filter,
        },
      };
    },
    'user/loadSettings': loadFilters,
    'user/login': loadFilters,
  },
  effects: dispatch => ({
    async save(payload, state) {
      const user = getUser(state);
      this.persist({ userId: user.id, ...payload });

      await saveSettings({
        itemsFilters: {
          filter: {
            ...state.itemsFilter.filter,
            ...payload,
          },
        },
      })(dispatch);
    },
  }),
};

export default itemsFilter;
