import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Flash = ({
  className,
  color = '#ffa900',
  name,
  width = '14',
  height = '18',
  viewBox = '0 0 14 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g clipPath="url(#clip0)">
        <path d="M8 7L10 0L0 11H6L4 18L14 7H8Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Flash.defaultProps = {
  className: '',
  color: '#ffa900',
  width: '14',
  height: '18',
  viewBox: '0 0 14 18',
};

Flash.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
