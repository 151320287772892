import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Network = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M16.2727 16H1.72727C1.32727 16 1 15.64 1 15.2V12.8C1 12.36 1.32727 12 1.72727 12H16.2727C16.6727 12 17 12.36 17 12.8V15.2C17 15.64 16.6727 16 16.2727 16Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path d="M3 14H3.73913" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M5 14H5.73913" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M7 14H7.73913" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M3 16V17.0984" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M15 16V17.0984" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M12 11.7872V3" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path
        d="M10.2453 3.08691C10.0313 3.30659 9.88867 3.5995 9.88867 3.92902C9.88867 4.25854 10.0313 4.55144 10.2453 4.77112"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M13.7547 3.08691C13.9687 3.30659 14.1113 3.5995 14.1113 3.92902C14.1113 4.25854 13.9687 4.55144 13.7547 4.77112"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M9.24646 2.06177C8.7472 2.50113 8.46191 3.16017 8.46191 3.92904C8.46191 4.66131 8.7472 5.32035 9.24646 5.79632"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M14.7535 2.06177C15.2528 2.50113 15.5381 3.16017 15.5381 3.92904C15.5381 4.66131 15.2528 5.32035 14.7535 5.79632"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M8.21247 1C7.46359 1.73227 7 2.75744 7 3.89245C7 5.02746 7.46359 6.05263 8.21247 6.7849"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M15.7875 1C16.5364 1.73227 17 2.75744 17 3.89245C17 5.02746 16.5364 6.05263 15.7875 6.7849"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Network.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Network.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
