import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Tv = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect x="0.5" y="3.5" width="17" height="11" rx="1.5" stroke={color} strokeLinejoin="round" />
    <path d="M3 15V16" stroke={color} strokeLinecap="round" />
    <path d="M6 8V11" stroke={color} strokeLinecap="round" />
    <path d="M9 8L11 11L13 8" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 8H7" stroke={color} strokeLinecap="round" />
    <path d="M15 15V16" stroke={color} strokeLinecap="round" />
  </svg>
);

Tv.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Tv.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
