// @ts-nocheck
import React, { useEffect } from 'react';
import cn from 'classnames';
import { Icon } from 'common/ui/icon';
import { Spinner } from 'common/ui/spinner';
import './notifier.scss';
import { useDispatch } from 'react-redux';

export const Toast = ({ message, success, id }) => {
  const { notifier } = useDispatch();

  useEffect(() => {
    if (success === null) {
      notifier.notify(id);
    } else {
      notifier.resolve({ id, success });
    }
  }, [success, id]);

  useEffect(
    () => () => {
      notifier.dismiss(id);
    },
    [id],
  );

  return (
    <div className="notifier-toast">
      <span
        className={cn('notifier-toast__text', {
          'notifier-toast__text--done': success === true,
          'notifier-toast__text--error': success === false,
        })}
      >
        {message}
      </span>
      {success && <Icon className="notifier-toast__icon" name="done" />}
      {success === false && <Icon className="notifier-toast__icon" name="error" />}
      {success === null && <Spinner />}
    </div>
  );
};
