import React, { useState, useEffect, useCallback, useRef, forwardRef, memo } from 'react';
import { compose } from 'redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SuggestContent from './components/suggest-content';
import Modal from './components/modal';
import { setState } from '../../../action/state';
import classNames from 'classnames';
import { isCactus } from 'common/config';
import { OrderEditContext } from 'pages/Orders/components/order-edit/context';
import { eventPath } from 'common/util';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { getEffectLoading } from 'common/selectors';
import useContextDispatch from 'hooks/context/useContextDispatch';
import useContextSelector from 'hooks/context/useContextSelector';
import { demofondModeContext } from 'pages/VAD/context';
import { activeControllers } from 'common/collections';

const classModal = classNames('Modal', 'Modal__items-suggest', { 'Modal--cactus': isCactus });

const ItemsSearch = (props, ref) => {
  const { name, placeholder, className } = props;

  const {
    items: { getSuggests, handleSearch: searchItems },
  } = useDispatch();

  const fetching = useSelector(getEffectLoading('items', 'getSuggests'));

  const { pathname } = useLocation();
  const isDemo = /demof[uo]nd/.test(pathname);

  const [text, setText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [{ brands = [], groups = [], goods = [] }, setSuggests] = useState({});

  const demofondMode = useContextSelector(demofondModeContext, state => state.demofondMode);
  const stateDispatch = useContextDispatch(OrderEditContext);

  const container = useRef();

  const handleClick = e => {
    const { tagName } = e.target;

    const isContainer = eventPath(e)
      .map(elem => elem.className)
      .includes('suggest-content');

    if (isContainer) {
      if (['A', 'B', 'SPAN'].includes(tagName)) {
        setTimeout(() => setIsOpen(false), 250);
      }

      return;
    }

    setIsOpen(false);
  };

  const handleFocus = e => {
    const { value } = e.target;

    const suggestContentLength = brands.length + groups.length + goods.length || 0;

    setIsOpen(value.length > 2 && suggestContentLength > 0);
  };

  const handleSuggests = useCallback(
    debounce(val => {
      getSuggests({ val, isDemo })
        .then(data => {
          const { brands, groups, goods } = data;

          setSuggests(data);
          setIsOpen(!isEmpty(goods) || !isEmpty(groups) || !isEmpty(brands));
        })
        .catch(() => false);
    }, 400),
    [isDemo],
  );

  const handleChange = ({ target, nativeEvent }) => {
    const { value } = target;
    setText(value);

    if (value.length < 3) {
      setIsOpen(false);
      return;
    }

    if ('inputType' in nativeEvent) {
      handleSuggests(value);
    }
  };

  const handleSearch = async () => {
    handleSuggests.cancel();
    activeControllers.abort(isDemo ? 'demofund/suggest-items' : 'orders/edit/suggest-items');

    setIsOpen(false);

    stateDispatch(
      setState({
        skipFilterFlushRequest: true,
      }),
    );

    await searchItems({ text, isDemo });

    stateDispatch(
      setState({
        groups: {},
        searchText: text,
        skipFilterFlushRequest: false,
      }),
    );
  };

  const handleKeyUp = async e => {
    if (e.key !== 'Enter' || text.length < 3) {
      return;
    }

    await handleSearch();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => document.removeEventListener('mousedown', handleClick, false);
  }, [handleClick]); // eslint-disable-line

  useEffect(() => {
    setText('');
  }, [demofondMode]);

  return (
    <div className="order-search">
      <div className="row align-items-center" ref={container}>
        <input
          ref={ref}
          type="text"
          name={name || 'itemSearch'}
          placeholder={placeholder || 'Поиск товара (минимум 3 символа)'}
          className={`form-control form-control-sm ${className || ''}`}
          value={text}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          onFocus={handleFocus}
          autoComplete="off"
        />
        {fetching && <span className="Suggest-spinner" />}
        <i className="icon icon-search enabled" onClick={handleSearch} />
      </div>
      <Modal
        position={{
          compensationTop: 32,
          compensationLeft: 0,
          relativeRef: container,
        }}
        isOpen={isOpen}
        className={classModal}
      >
        <SuggestContent
          items={goods.slice(0, 10)}
          brands={brands.slice(0, 10)}
          groups={groups.slice(0, 10)}
          wholeItemsLength={goods.length}
          text={text}
          isDemofond={isDemo}
        />
      </Modal>
    </div>
  );
};

ItemsSearch.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default compose(memo, forwardRef)(ItemsSearch);
