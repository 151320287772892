import React from 'react';
import get from 'lodash/get';
import List from './common/list';
import withApi from 'hoc/withApi';

const columns = [
  {
    Header: 'Код товара',
    id: 'itemNo',
    accessor: 'itemNo',
  },
  {
    Header: 'Серийный номер',
    id: 'serialNo',
    accessor: 'itemSerial',
  },
  {
    Header: 'Наименование товара',
    headerStyle: { textAlign: 'left' },
    id: 'name',
    style: { textAlign: 'left' },
    accessor: 'itemName',
    width: 600,
  },
  {
    Header: 'Дата приема',
    id: 'deliveryDate',
    accessor: d => d.deliveryDateSort,
    Cell: row => get(row, ['original', 'deliveryDate'], null),
  },
  {
    Header: 'Документ приема',
    id: 'deliveryDocNo',
    accessor: 'deliveryDocNo',
  },
  {
    Header: 'Дата выдачи',
    id: 'receiptDate',
    accessor: d => d.receiptDateSort,
    Cell: row => get(row, ['original', 'receiptDate'], null),
  },
  {
    Header: 'Документ выдачи',
    id: 'receiptDocNo',
    accessor: 'receiptDocNo',
  },
  {
    Header: 'Дата выкупа',
    id: 'buyoutDate',
    accessor: d => d.buyoutDateSort,
    Cell: row => get(row, ['original', 'buyoutDate'], null),
  },
  {
    Header: 'Документ выкупа',
    id: 'buyoutDocNo',
    accessor: 'buyoutDocNo',
  },
];

const Documents = () => {
  return <List columns={columns} width="100%" />;
};

export default withApi(Documents);
