// @ts-nocheck
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { scrollToTop } from 'utils/new/dom';

export const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(scrollToTop, [pathname]);

  return children || null;
};
