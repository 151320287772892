import { API_URL } from 'common/config/app';

export const orderActionsApi = {
  'new-order-signature/([a-zA-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/index/sign`,
  },
  'new-order-unsignature/([a-zA-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/index/sign`,
  },
  'new-download-files/([a-zA-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/index/document`,
  },
  'new-order-shipment-method/([a-zA-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders`,
  },
  'new-order-removed/([a-zA-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/index/delete`,
  },
};
