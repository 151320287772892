import { useEffect, useState, useRef } from 'react';

export const useHover = () => {
  const [state, setState] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setState(true);
  const handleMouseOut = () => setState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, []);

  return [ref, state, setState];
};
