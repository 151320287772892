import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { DEMOFOND_ORDER_FORM } from 'pages/VAD/constants';
import validate from '../helper/demofund-order-validate';
import OrderForm from '../components/demofond/components/orderForm';

const DemofondOrderForm = reduxForm({
  form: DEMOFOND_ORDER_FORM,
  validate,
});

const DemofondOrderConnect = connect(state => ({
  initialValues: {
    contact: get(state, ['user', 'info', 'FIO'], ''),
    phone: get(state, ['user', 'info', 'MobilePhone'], ''),
    email: get(state, ['user', 'info', 'EMail'], ''),
  },
}));

export default compose(DemofondOrderConnect, DemofondOrderForm)(OrderForm);
