import { AccountedVolumeHeader } from './cells';

export const AccViewColumns = type => [
  {
    Header: 'Номер',
    accessor: 'no',
    width: 80,
  },
  {
    Header: 'Наименование',
    headerStyle: { textAlign: 'left' },
    accessor: 'name',
    style: { textAlign: 'left' },
  },
  {
    Header: AccountedVolumeHeader,
    accessor: d => parseFloat(d.volume).toFixed(3),
    id: 'volume',
    sortable: false,
    show: type === 2,
    width: 130,
  },
  {
    Header: 'Недогруз',
    accessor: 'underload',
    show: type === 2,
    width: 100,
  },
  {
    Header: 'Кол.',
    accessor: 'qty',
    sortable: false,
    width: 50,
  },
  {
    Header: 'Заказ',
    accessor: 'orderedQty',
    sortable: false,
    show: type === 2,
    width: 50,
  },
  {
    Header: 'Цена',
    headerStyle: { textAlign: 'right' },
    accessor: 'price',
    sortable: false,
    style: { textAlign: 'right' },
    width: 100,
  },
  {
    Header: 'Сумма',
    headerStyle: { textAlign: 'right' },
    accessor: 'sum',
    sortable: false,
    style: { textAlign: 'right' },
    width: 100,
  },
];
