import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Chair = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M11.5841 7H6.40952C6.02857 7 5.70476 6.75335 5.63492 6.41491L5 3.20268C5 1.98662 6.09206 1 7.4381 1H10.5619C11.9079 1 13 1.98662 13 3.20268L12.3651 6.41491C12.2889 6.75335 11.9587 7 11.5841 7Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7V9"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 7V9"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4265 11H5.5735C5.25489 11 5 10.6576 5 10.2296V9.77043C5 9.34241 5.25489 9 5.5735 9H12.4265C12.7451 9 13 9.34241 13 9.77043V10.2296C13 10.6498 12.7451 11 12.4265 11Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11V14.7188"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 15V14.6541C5 13.7421 5.95065 13 7.11883 13H10.8812C12.0493 13 13 13.7421 13 14.6541V15"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 16C10 15.4511 9.54887 15 9 15C8.45113 15 8 15.4511 8 16C8 16.5489 8.45113 17 9 17C9.55639 17 10 16.5489 10 16Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 16C14 15.4511 13.5489 15 13 15C12.4511 15 12 15.4511 12 16C12 16.5489 12.4511 17 13 17C13.5564 17 14 16.5489 14 16Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 16C6 15.4511 5.54887 15 5 15C4.45113 15 4 15.4511 4 16C4 16.5489 4.45113 17 5 17C5.54887 17 6 16.5489 6 16Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 3V4.6685"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 3V4.6685"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3V4.6685"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Chair.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Chair.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
