import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { isCactus } from 'common/config';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getTableListData } from 'common/selectors';
import prepareExportData from 'pages/Orders/helpers/prepareExportData';
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';
import { OrderContext } from 'pages/Orders/components/common/items-search/constants';
import { OrderEditContext } from 'pages/Orders/components/order-edit/context';
import useContextSelector from 'hooks/context/useContextSelector';
import useListData from "components/table/hooks/useListData"

const classModal = classNames('Modal', 'Modal__generated-files', { 'Modal--cactus': isCactus });

const ItemsExport = () => {
  const {
    select: { items },
  } = useStore();

  const context = useContext(OrderContext);
  const state = useContextSelector(OrderEditContext, state => state);
  const isPreorder = useIsPreorder();

  const data = useListData();
  const files = useSelector(items.getFiles);
  const hasNewFiles = useSelector(state => state.items.hasNewFiles);

  const csvLoading = useSelector(state => state.items.exportStartTime['csv']);
  const xlsLoading = useSelector(state => state.items.exportStartTime['xls']);

  const fetching = csvLoading || xlsLoading;

  const [isOpen, setIsOpen] = useState(false);
  const [top, setTop] = useState(300);
  const [left, setLeft] = useState(450);

  const prevFiles = useRef(files);
  const { id } = useParams();

  const {
    items: { setFiles, watchFiles, handleExport, getFile },
  } = useDispatch();

  useEffect(() => {
    if (prevFiles.current.length !== files.length) {
      if (fetching) {
        setFiles({ files: files.slice(1) });
      }
      else{
        setFiles({ files })
      }
    }

    prevFiles.current = files;
  }, [files.length, fetching]); // eslint-disable-line

  const handleOpen = useCallback(
    e => {
      const targetRect = e.target.getBoundingClientRect();

      if (files.length) {
        setTop(targetRect.bottom + 5);
        setLeft(targetRect.left);
        setIsOpen(true);
        watchFiles();
      }
    },
    [files.length],
  ); // eslint-disable-line

  const handleGetFile = useCallback(
    file => async () => {
      setIsOpen(false);

      await getFile(file);
    },
    [],
  );

  if (!data.length || isPreorder) {
    return null;
  }

  return (
    <div style={{ display: 'flex' }}>
      <div
        className={classNames('justify-content-between', 'row', 'align-items-center', 'nowrap')}
        id="items-export-actions"
      >
        {(xlsLoading && (
          <div className="container-icon">
            <i className="loader loader--small" />
          </div>
        )) || (
          <i
            className="icon icon-expxls enabled"
            onClick={() =>
              handleExport({
                ...prepareExportData({ ...{ ...context, ...state }, id }),
                type: 'xls',
              })
            }
            title="Выгрузить в Excel"
          />
        )}
        &nbsp;&nbsp;
        {(csvLoading && (
          <div className="container-icon">
            <i className="loader loader--small" />
          </div>
        )) || (
          <i
            className="icon icon-expcsv enabled"
            onClick={() => {
              console.log('context', context)
              console.log('state', state)
              console.log('id', id)
              console.log('prepareExportData({ ...{ ...context, ...state }, id })', prepareExportData({ ...{ ...context, ...state }, id }))
              handleExport({
                ...prepareExportData({ ...{ ...context, ...state }, id }),
                type: 'csv',
              })
            }}
            title="Выгрузить в Csv"
          />
        )}
        &nbsp;&nbsp;
        <i
          className={classNames('files-shower', 'icon', {
            enabled: !!files.length,
            'icon-download-dis': !files.length,
            'icon-download': !!files.length,
            'files-shower--active': hasNewFiles,
          })}
          title={files.length ? 'Скачать файлы' : 'Нет сгенерированных файлов для клиента'}
          onClick={handleOpen}
        />
      </div>
      <ReactModal
        key={2}
        isOpen={!!files.length && isOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Modal"
        className={classModal}
        overlayClassName="Overlay Overlay__columns"
        bodyOpenClassName="ReactModal__Body--open ReactModal__Body--open__columns"
        ariaHideApp={false}
        style={{
          content: {
            top: `${top}px`,
            left: `${left}px`,
          },
        }}
      >
        <ul className="files-list">
          {files.map((file, index) => (
            <li key={`${index}-${file}`} onClick={handleGetFile(file)}>
              {file}
            </li>
          ))}
        </ul>
      </ReactModal>
    </div>
  );
};

export default ItemsExport;
