import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Transit = ({
  className,
  color = '#008000',
  name,
  width = '21',
  height = '14',
  viewBox = '0 0 21 14',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M7.54054 11H11.2568C11.6655 11 12 10.6591 12 10.2424V1.75758C12 1.34091 11.6655 1 11.2568 1H1.74324C1.33446 1 1 1.34091 1 1.75758V10.2424C1 10.6591 1.33446 11 1.74324 11H3.97297"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M6 13C7.10457 13 8 12.1046 8 11C8 9.89543 7.10457 9 6 9C4.89543 9 4 9.89543 4 11C4 12.1046 4.89543 13 6 13Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M16 13C17.1046 13 18 12.1046 18 11C18 9.89543 17.1046 9 16 9C14.8954 9 14 9.89543 14 11C14 12.1046 14.8954 13 16 13Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path d="M11 11H14.2705" stroke={color} strokeMiterlimit="10" />
      <path
        d="M18.1818 11H19.0909C19.5909 11 20 10.64 20 10.2V7.152C20 6.952 19.9182 6.76 19.7636 6.608L16.2727 3.256C16.1 3.088 15.8545 3 15.6 3H12"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path d="M20 7H15.8333C15.375 7 15 6.625 15 6.16667V3" stroke={color} strokeMiterlimit="10" />
    </svg>
  );
};

Transit.defaultProps = {
  className: '',
  color: '#008000',
  width: '21',
  height: '14',
  viewBox: '0 0 21 14',
};

Transit.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
