import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Mixpanel from 'services/mixpanel';

const AdsItem = props => {
  const {
    ads: { link, title, newsId, name },
  } = props;

  return (
    <li className="ads-list__item">
      <p>
        {link ? (
          <a href={link} rel="noopener noreferrer" className="news-link" target="_blank">
            {title}
          </a>
        ) : (
          <Link
            to={`/news/${newsId}`}
            className="news-link"
            onClick={() => {
              Mixpanel?.track('СтрГлавнаяСтраница.Объявление', {
                page: 'СтрГлавнаяСтраница',
                id: newsId,
              });
            }}
          >
            {title}
          </Link>
        )}
        <i>{ReactHtmlParser(name)}...</i>
      </p>
    </li>
  );
};

export default AdsItem;
