/* eslint-disable quote-props */
const colorsStatusSpecialPrices = {
  Отправлен: 'status--red',
  'Взят в работу': 'status--blue',
  Выполнен: 'status--green',
  'КП Отправлено вашему менеджеру': 'status--green',
  'В Работе': 'status--blue',
};

export default colorsStatusSpecialPrices;
