// @ts-nocheck
import get from 'lodash/get';
import moment from 'moment';
import notifier from 'services/notifier';
import alert from 'components/native/alert/function';
import { shipmentCollector } from 'pages/Logistic/constants';

export const handlerShipment = (modelList, payload) => {
  const command = get(payload, ['data', 'Command Name'], '');
  const success = get(payload, 'success', false);
  const response = get(payload, 'data', {});
  const id = get(payload, 'id', 0);

  let message = '';

  if (!success) {
    const errorText = get(payload, ['data', 'Error Text'], 'Что-то пошло не так');
    alert(errorText);
  }

  if (command === 'Delete') {
    modelList.deleteRow({
      searchKey: 'requestNo',
      needle: response['Document No_'],
    });

    message = `Запись на отгрузку ${id} удалена!`;
  }

  if (command === 'Insert') {
    modelList.addRow({
      ...shipmentCollector.get(),
      requestNo: id,
      planingShipmentDate: moment(response.ShipmentDate).format('DD.MM.YYYY'),
      requestDate: moment(response.ShipmentDate).format('DD.MM.YYYY'),
      orderNo: response.Orders?.split('|') || [],
    });
    message = `Запись на отгрузку ${id} добавлена!`;
  }

  if (command === 'Modify') {
    modelList.changeRow({
      searchKey: 'requestNo',
      needle: response['Document No_'],
      ...response,
    });

    message = `Запись на отгрузку ${id} изменена!`;
  }

  notifier.resolve(
    {
      success,
      message,
    },
    id,
  );
  shipmentCollector.clear();
};
