// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from 'common/ui/button';
import { Icon } from 'common/ui/icon';
import { OrderCard } from 'components/new/order-card';
import { formatDocStatus, getIconDoc, getFullname } from '../../utils';
import isEmpty from 'lodash/isEmpty'

import styles from './download-list-item.module.scss';

export const DownloadListItem = ({
  data: {
    comment,
    createdAt,
    docDelivery,
    docFormat,
    docType,
    status,
    requesterName,
    requesterSecondname,
    order,
    operationNo,
  },
}) => {
  const {
    newDownloadList: { fetchDownloadFile },
  } = useDispatch();

  const notDownload = status === 'Error' || status === 'Active';
  const editedOrder = {
    ...order,
    amount: order.currency === 'USD' ? order.price : order.priceRUB,
    shipmentMethod: order?.shipmentFunc,
    needShipmentRecord: '0',
  };
  const handleDownloadFile = () => {
    fetchDownloadFile({id: operationNo, name: docType});
  };

  return (
    <div className={styles['download-list-item']}>
      <Icon name={formatDocStatus(status)} className={styles['download-list-item__icon']} />
      <div className={styles['download-list-item__name']}>
        <Button disabled={notDownload} onClick={handleDownloadFile} theme="ternary">
          {docType}
        </Button>
        <span className={styles['download-list-item__comment']}>{comment}</span>
      </div>
      <div className={styles['download-list-item__info']}>
        <div className={styles['download-list-item__date']}>{createdAt}</div>
        <div className={styles['download-list-item__user']}>
          {getFullname(requesterName, requesterSecondname)}
        </div>
      </div>
      {!isEmpty(order) && <OrderCard className={styles['download-list-item__order']} data={editedOrder} />}
      <Icon
        name={getIconDoc(docFormat, docDelivery)}
        className={styles['download-list-item__document']}
      />
    </div>
  );
};

DownloadListItem.propTypes = {
  data: PropTypes.shape({
    comment: PropTypes.string,
    createdAt: PropTypes.string,
    docDelivery: PropTypes.string,
    docFormat: PropTypes.string,
    docType: PropTypes.string,
    status: PropTypes.string,
    requesterName: PropTypes.string,
    requesterSecondname: PropTypes.string,
    operationNo: PropTypes.string,
    order: PropTypes.shape({
      amount: PropTypes.string,
      comment: PropTypes.string,
      currency: PropTypes.string,
      needShipmentRecord: PropTypes.string,
      orderNo: PropTypes.string,
      shipment: PropTypes.string,
      shipmentFunc: PropTypes.string,
      shippingAddress: PropTypes.string,
      status: PropTypes.string,
      willDeleteTomorrow: PropTypes.string,
    }),
  }).isRequired,
};
