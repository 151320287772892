import React from 'react';
import sortStringNumbers from 'helpers/sortStringNumbers';

export default [
  {
    Header: 'Код',
    title: 'Код',
    id: 'id',
    isPreorder: true,
    accessor: 'id',
    width: 90,
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Изображение',
    title: 'Изображение',
    id: 'image',
    accessor: 'imageUrl',
    sortable: false,
    style: { overflow: 'visible', padding: '0px 10px' },
    width: 100,
    isPreorder: true,
    Cell: row => {
      if (!row?.original?.imageUrl) return null;

      return <img alt={row.original.name} src={row.original.imageUrl} />;
    },
  },
  {
    Header: 'Наименование',
    title: 'Наименование',
    id: 'name',
    accessor: 'name',
    width: 560,
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    isPreorder: true,
  },
  {
    Header: 'Бренд',
    title: 'Бренд',
    id: 'brand',
    width: 110,
    accessor: 'brand',
  },
  {
    Header: 'Part №',
    title: 'Part №',
    id: 'vendorPart',
    width: 150,
    accessor: 'vendorPart',
  },
];
