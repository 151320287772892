import React from 'react';
import sortStringNumbers from 'helpers/sortStringNumbers';
import sortDates from 'helpers/sortDates';
import createClosedOperationState from '../helpers/createClosedOperationState';
import { DocumentCell } from './cells';

export default [
  {
    Header: 'Дата учета',
    accessor: 'postingDate',
    sortMethod: sortDates,
  },
  {
    Header: 'Документ Но.',
    accessor: 'orderNo',
    Cell: DocumentCell,
  },
  {
    Header: 'Заказ Но.',
    accessor: 'extDocNo',
  },
  {
    Header: 'УПД/ТН',
    accessor: 'upd_tn',
  },
  {
    Header: 'Инициатор',
    accessor: 'initiator',
    Cell: row => (row && row.value) || '–',
  },
  {
    Header: 'Описание',
    headerStyle: { textAlign: 'left' },
    accessor: 'description',
    id: 'description',
    style: { textAlign: 'left' },
    width: 230,
    sortable: false,
  },
  {
    Header: 'Метод отгрузки',
    accessor: 'shipmentMethodCode',
    width: 120,
    sortable: false,
  },
  {
    Header: 'Операция закрыта',
    sortable: false,
    width: 130,
    Cell: row => {
      if (!row) return null;

      const closedOperationState = createClosedOperationState(row);
      return <p style={{ color: closedOperationState.color }}>{closedOperationState.label}</p>;
    },
  },
  {
    Header: 'Дата оплаты',
    accessor: 'dueDate',
    sortMethod: sortDates,
  },
  {
    Header: 'Валюта',
    accessor: 'currencyCode',
    sortable: false,
  },
  {
    Header: 'Сумма',
    accessor: 'amount',
    sortMethod: sortStringNumbers,
  },
];
