import { compose } from 'redux';
import { reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import alert from 'components/native/alert/function';
import { validate } from '../utils/validate';
import { Form } from '../unlocking-form';

const selector = formValueSelector('login-form');

const mapStateToProps = state => ({
  initialValues: {
    clientNo: selector(state, 'clientNo'),
    clientLogin: selector(state, 'clientLogin'),
    secondName: '',
    email: '',
    mobilePhone: '',
    description: 'Прошу разблокировать мою учетную запись.',
  },
});

export const UnlockingForm = compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'unlocking-form',
    validate,
    onSubmit: (values, { auth }) => auth.unlock(values),
    onSubmitFail: (_errors, _dispatch, submitError) => {
      if (submitError instanceof SubmissionError) {
        alert(submitError.errors._error);
      } else {
        throw submitError;
      }
    },
    onSubmitSuccess: (result, _dispatch, { onClose }) => {
      onClose();
      alert(result);
    },
  }),
)(Form);
