import React from 'react';
import {useSelector} from 'react-redux'
import { getUser } from 'pages/Orders/selectors';
//import LogRocket from 'logrocket'

const UserInfo = () => {
  const { Name, client, FIO }  = useSelector(getUser);

  /* useEffect(() => {
    LogRocket.identify(user.id, {
      name: `${user.client}|${user.login}`,
      subscriptionType: 'pro'
    })
  }, []) */
  return (
    <div className="info">
      <a href="#">{`${Name}${client}`}</a>
      <span>{FIO}</span>
    </div>
  );
};


export default UserInfo;
