import React, { useState, useEffect } from 'react';
import alert from '../../../../../components/native/alert/function';
import checkFormatTime from '../../../constances/checkFormatTime';
import formattingMask from '../../../helpers/formattingMask';
import addStepInterval from '../../../helpers/addStepInterval';
import checkAvailableTime from '../../../helpers/checkAvailableTime';
import { setCorrectTime } from 'helpers/timePicker';

const CustomTimePicker = ({
  label,
  originTimeFrom,
  originTimeTo,
  isAllow,
  timeDiff,
  timeMin,
  timeMax,
  input,
  isLoading,
}) => {
  const [timeFrom, setTimeFrom] = useState('');
  const [timeTo, setTimeTo] = useState('');
  const defaultInterval = 1;
  const interval = timeDiff === null ? defaultInterval : timeDiff;
  const timeFromName = 'timeFrom';
  const timeToName = 'timeTo';

  useEffect(() => {
    if (checkFormatTime.test(originTimeFrom) && checkFormatTime.test(originTimeTo)) {
      setTimeFrom(originTimeFrom);
      setTimeTo(originTimeTo);
    }
  }, [originTimeFrom, originTimeTo]);

  useEffect(() => {
    if (checkFormatTime.test(timeFrom) && checkFormatTime.test(timeTo)) {
      const { hasError, message } = checkAvailableTime(
        timeFrom,
        timeTo,
        interval,
        timeMin,
        timeMax,
      );
      if (hasError) {
        alert(message);
        setTimeFrom(originTimeFrom);
        setTimeTo(originTimeTo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeTo, timeFrom]);

  useEffect(() => {
    const result =
      checkFormatTime.test(timeFrom) && checkFormatTime.test(timeTo) ? { timeFrom, timeTo } : {};

    input.onChange(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrom, timeTo]);

  const handleChange = e => {
    const { value, name } = e.target;

    if (value.length <= 5) {
      const correctedTime = setCorrectTime(value);
      if (name === timeFromName) {
        setTimeFrom(correctedTime);
      }

      if (name === timeToName) {
        setTimeTo(correctedTime);
      }
    }
  };

  const handleStep = (name, increase) => () => {
    if (checkFormatTime.test(timeFrom) && checkFormatTime.test(timeTo)) {
      if (name === timeFromName) {
        const newTime = addStepInterval(timeFrom, increase);
        const formattedNewTime = formattingMask(newTime);
        setTimeFrom(formattedNewTime);
      }

      if (name === timeToName) {
        const newTime = addStepInterval(timeTo, increase);
        const formattedNewTime = formattingMask(newTime);
        setTimeTo(formattedNewTime);
      }
    }
  };

  const handleSelectText = event => event.target.select();

  const handlePaste = event => {
    event.preventDefault();
    return false;
  };

  return (
    <div>
      <label>{label}</label>
      <div className="time-picker">
        <div className="container-time">
          <div className="time-input">
            <input
              className="form-control"
              type="text"
              name={timeFromName}
              value={timeFrom}
              onChange={handleChange}
              onFocus={handleSelectText}
              onPaste={handlePaste}
              disabled={!isAllow}
              autoComplete="off"
            />
            {isAllow && timeDiff && (
              <div className="container-steps">
                <button
                  className="step step--up"
                  type="button"
                  onClick={handleStep(timeFromName, true)}
                >
                  <span className="Select-arrow" />
                </button>
                <button
                  className="step step--down"
                  type="button"
                  onClick={handleStep(timeFromName, false)}
                >
                  <span className="Select-arrow" />
                </button>
              </div>
            )}
            {isLoading && <span className="Suggest-spinner" />}
          </div>
          <div className="icon icon-arrows-icon" />
          <div className="time-input">
            <input
              className="form-control"
              type="text"
              name={timeToName}
              value={timeTo}
              onChange={handleChange}
              onFocus={handleSelectText}
              onPaste={handlePaste}
              disabled={!isAllow}
              autoComplete="off"
            />
            {isAllow && timeDiff && (
              <div className="container-steps">
                <button
                  className="step step--up"
                  type="button"
                  onClick={handleStep(timeToName, true)}
                >
                  <span className="Select-arrow" />
                </button>
                <button
                  className="step step--down"
                  type="button"
                  onClick={handleStep(timeToName, false)}
                >
                  <span className="Select-arrow" />
                </button>
              </div>
            )}
            {isLoading && <span className="Suggest-spinner" />}
          </div>
        </div>
        {isAllow && timeMin && timeMax && (
          <div className="time-work">
            {' '}
            с {timeMin} до {timeMax}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomTimePicker;
