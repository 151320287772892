import { CACHE_NAME } from 'common/config';

export default {
  async init(name = CACHE_NAME) {
    try {
      return await caches.open(name);
    } catch (e) {}

    return null;
  },
  async get(key, name, options = {}) {
    const cache = await this.init(name);

    if (cache) {
      try {
        return await cache.match(key, options);
      } catch (e) {
        return false;
      }
    }
  },
  async put(key, res, name) {
    const cache = await this.init(name);

    if (cache) {
      try {
        await cache.put(key, res);
      } catch (err) {
        console.error(err);
      }
    }
  },
  async clear(name) {
    try {
      if (name) {
        return await caches.delete(name);
      }

      const list = await caches.keys();

      return Promise.all(list.map(key => caches.delete(key)));
    } catch (e) {}
  },
};
