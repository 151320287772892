import { ResultCell, StatusCell } from 'pages/Queue/components/columns/Cells';

const queueHeaderColumns = [
  {
    Header: 'Дата',
    accessor: 'date',
    width: 110,
  },
  {
    Header: 'Команда',
    accessor: 'command',
    width: 70,
  },
  {
    Header: 'Состояние',
    Cell: StatusCell,
    id: 'status',
    width: 80,
  },
  {
    Header: 'Метод отгрузки',
    accessor: 'shipment',
    width: 120,
  },
  {
    Header: 'Дата отгрузки',
    accessor: 'shipmentDate',
    width: 120,
    headerStyle: { textAlign: 'left' },
  },
  {
    Header: 'Агент по доставке',
    accessor: 'shipmentAgent',
    width: 130,
    headerStyle: { textAlign: 'left' },
  },
  {
    Header: 'Конечный адрес\nи контакт',
    accessor: 'finalAddress',
    width: 150,
    headerStyle: { textAlign: 'left' },
  },
  {
    Header: 'Контрагент',
    accessor: 'contractorName',
    width: 120,
  },
  {
    Header: 'Представитель',
    accessor: 'representative',
    width: 130,
  },
  {
    Header: 'Результат',
    Cell: ResultCell,
    id: 'result',
    width: 80,
  },
  {
    Header: 'Ошибка',
    accessor: 'error',
    id: 'error',
    width: 240,
  },
  {
    Header: 'Комментарий',
    accessor: 'comment',
    width: 150,
  },
];

export default queueHeaderColumns;
