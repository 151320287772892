import React, {useCallback, forwardRef} from "react"
import TableList from 'components/table/list';
import selectTableHOC from 'react-table-v6/lib/hoc/selectTable';
import useSelectTable from "hooks/use-select-table";
import cn from 'classnames'

const SelectTable = selectTableHOC(TableList);

const SelectTableList = (props, ref) => {
  const {toggle, toggleAll, keyField, getTrProps, getTdProps, ...rest} = props
  const {selectAll, toggleSelection, isSelected, toggleAll: toggleAllFn, selected} = useSelectTable({toggle, toggleAll, keyField})

  const rowFn = useCallback(
    (state, info) => {
      if(!info) return {}

      const trProps = getTrProps?.(state, info) || {}
      const {className: rowClass = 'rt-tr', ...rest} = trProps

      return {
        ...rest,
        className: cn(
          rowClass, {
            [`${rowClass}--selected`]: selected.includes(info.original[keyField])
          }
        )
      }
    }
  , [selected.length])

  const cellFn = useCallback(
    (state, info) => {
      if(!info) return {}
      const tdProps = getTdProps?.(state, info) || {}

      return {
        ...tdProps,
        isSelected: selected.includes(info.original[keyField]),
        toggle: toggleSelection
      }
    }
  , [selected.length, toggleSelection])

  return (
    <SelectTable
      ref={ref}
      className='select-table'
      keyField={keyField}
      isSelected={isSelected}
      toggleSelection={toggleSelection}
      toggleAll={toggleAllFn}
      selectAll={selectAll}
      getTrProps={rowFn}
      getTdProps={cellFn}
      {...rest}
    />
  )
}

export default forwardRef(SelectTableList);
