import React from 'react';
import { useSelector } from 'react-redux';
import { isOrderPreorder, isZprOrder } from 'pages/Orders/selectors';

const OrderInfo = () => {
  const statuses = useSelector(state => state.order.statuses);
  const isZpr = useSelector(isZprOrder);
  const isPreorder = useSelector(isOrderPreorder);

  if (isZpr) {
    return (
      <div>
        <span className="g gkol0" />
        <span className="g">ЖКол = 0</span>
        <span className="g gkol" />
        <span className="g">ЖКол {'>'} Кол </span>
        <span className="g gprice" />
        <span className="g">ЖЦена {'<'} Цена</span>
      </div>
    );
  }

  if (statuses && !isPreorder) {
    return (
      <div className="transit-statuses">
        <h4>Статусы транзитов:</h4>
        <table>
          <tbody>
            {statuses.map(({ code, description }) => {
              return (
                <tr key={code}>
                  <td>{code}</td>
                  <td>{description}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

export default OrderInfo;
