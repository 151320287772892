const DEFAULT_LOCALE = 'ru';

const FIRST_DAY_OF_WEEK = {
  ru: 1,
  it: 1,
};

const FORMAT = 'dd.MM.yyyy';

const REGEX_FORMAT = /^[0-9]{2}[\.][0-9]{2}[\.][0-9]{4}$/g;

// Translate aria-labels
const LABELS = {
  ru: { nextMonth: 'следующий месяц', previousMonth: 'предыдущий месяц' },
};

const MONTHS = {
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
};

const LOCALES = {
  ru: 'ru',
};

const WEEKDAYS_LONG = {
  ru: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
};

const WEEKDAYS_SHORT = {
  ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
};

export {
  DEFAULT_LOCALE,
  FIRST_DAY_OF_WEEK,
  FORMAT,
  REGEX_FORMAT,
  LABELS,
  LOCALES,
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
};
