import React from 'react';
import { Link, useParams } from 'react-router-dom';
import parse from 'react-html-parser';
import Content from 'components/layout/content';
import colorsStatusSpecialPrices from 'pages/VAD/components/special-prices/constants/colors-status-special-prices';
import withApi from 'hoc/withApi';
import { useSelector, useStore } from 'react-redux';
import CommentsList from 'pages/VAD/components/common/components/comments-list';
import AddCommentForm from 'pages/VAD/components/form/comments-form';

const Detail = () => {
  const {
    select: { specialPrice },
  } = useStore();

  const { createDate, closeDate, status, body, comments, customerName } = useSelector(
    specialPrice.getDetail,
  );

  const { id } = useParams();

  const message = body
    ? parse(body)
    : 'Ваш запрос еще обрабатывается. Пожалуйста, обновите страницу позднее.';

  return (
    <div className="detail-view">
      <Content title={`Запрос ${id.replace('-', '/')}`}>
        <div className="row header">
          <div className="col link-container header-border-right">
            <Link to="/vad/special-prices/list" className="link-back">
              <i className="icon back" />
              <span className="back-text">Список запросов</span>
            </Link>
          </div>
          <div className="col data-container header-border-right">
            <div className="row-content">Дата обращения: {createDate}</div>
            <div className="row-content">Дата обработки: {closeDate}</div>
          </div>
          <div className="col data-container">
            <div className="row-content">Конечный заказчик: {customerName}</div>
            <div className="row-content">
              Статус: <span className={colorsStatusSpecialPrices[status]}>{status}</span>
            </div>
          </div>
        </div>
      </Content>
      <h3>Запрос</h3>
      <div className="message">{message}</div>
      <CommentsList comments={comments}>
        <AddCommentForm type="specialPrice" />
      </CommentsList>
    </div>
  );
};

export default withApi(Detail);
