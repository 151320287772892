import React from 'react';
import OrderColumns from 'pages/VAD/components/demofond/components/demofond-item/components/common/columns/orderColumns';
import TableList from 'components/table/list';
// import ProgressBar from './progress-bar'
import { DEMO_ORDER } from 'pages/VAD/constants';
import sumBy from 'lodash/sumBy';

const DemofondOrder = ({ demofondOrder = {} }) => {
  const { contact, email, phone, type, products } = demofondOrder;
  const isDemoOrder = !(Number(type) === DEMO_ORDER);

  return (
    <section className="actions-container demofond-order__container">
      <div className="demofond-order__container-desc col-sm-12">
        {contact && (
          <div>
            <label>Контактное лицо: </label> {contact}
          </div>
        )}
        {email && (
          <div>
            <label>Email: </label> {email}
          </div>
        )}
        {phone && (
          <div>
            <label>Телефон: </label> {phone}
          </div>
        )}
      </div>
      {isDemoOrder && (
        <div className="demofond-order__container-desc col-sm-12">
          <label>Период предоставления оборудования: &nbsp; </label> с {demofondOrder.receiptDate}{' '}
          по {demofondOrder.returnDate}
        </div>
      )}
      <div className="table__container">
        <TableList columns={OrderColumns} defaultPageSize={300} pageSize={200} />
        <div className="count_field">
          <span className="count_field--desc">Итого:</span>
          <span className="count_field--count">{sumBy(products, ({ qty }) => Number(qty))}</span>
        </div>
      </div>
      {/* <ProgressBar status={demofondOrder.status} isDemoOrder={isDemoOrder} /> */}
    </section>
  );
};

export default DemofondOrder;
