import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './image-icon.module.scss';

export const ImageIcon = ({ className = '', items = [] }) => {
  const classes = cn(styles['image-icon'], className);

  if (!Array.isArray(items)) {
    return null;
  }

  return (
    <div className={classes}>
      {items.map(item => (
        <span
          key={item.name}
          className={cn(styles['icon'], styles[`icon--${item.className}`])}
          title={item.label}
          role="img"
        />
      ))}
    </div>
  );
};

ImageIcon.propTypes = {
  /**
   * className
   */
  className: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      className: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};
