import cache from 'services/cache';
import { CACHE_NAME } from 'common/config';
import { batch } from 'react-redux';
import history from 'common/history';
import getItemsQuery from '../helpers/getItemsQuery';

export default ({ newOrdersNew, newOrder, api }) =>
  async ({ oldNo, newNo, existedOrder }, state) => {
    const { thirdCategory, secondCategory } = state.newCatalog.activeCategories;
    const { inList } = state.newOrdersNew;

    const locationState = history.location?.state || {};
    const { searchIds = [] } = locationState;

    try {
      !existedOrder && newOrdersNew.setLoadingOrders({ orderNo: oldNo, isFetching: true });
      if (
        !inList ||
        (state.newOrdersNew.goods.length &&
          !state.newOrdersNew.goods[0].hasOwnProperty('default') &&
          !state.newOrdersNew.goods[0].hasOwnProperty(`desiredQty_${newNo}`))
      ) {
        const query = getItemsQuery(state, oldNo, newNo || existedOrder?.orderNo);

        const [order, data] = await Promise.all([
          !existedOrder && newOrder.fetchOrderHeader(newNo),
          !inList
            ? api.get({ url: `new-orders-new/goods`, query: `?${query}` })
            : newOrdersNew.silentFetchGoods({
                activeCategory: thirdCategory?.id || secondCategory?.id,
                searchIds,
                query,
              }),
        ]);

        const items = data.items?.data || data.data || [];

        batch(() => {
          newOrdersNew.mergeGoods({ items: [...items], oldNo });
          (oldNo && newOrdersNew.replaceOrder({ oldNo, newOrder: order })) ||
            newOrdersNew.addOrder(order || existedOrder);
          newOrdersNew.mergeGoods({ items: [...items], oldNo });
        });
      } else {
        const order = existedOrder || (await newOrder.fetchOrderHeader(newNo));

        (oldNo && newOrdersNew.replaceOrder({ oldNo, newOrder: order })) ||
          newOrdersNew.addOrder(order);
      }
    } catch (err) {
      console.error(err);
      return {};
    } finally {
      !existedOrder && newOrdersNew.setLoadingOrders({ orderNo: oldNo, isFetching: false });
      await cache.clear(CACHE_NAME);
    }
  };
