import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from 'common/validates/validateShipingAgent';
import warn from 'common/warnes/warnShipingAgent';
import { RenderSelect } from 'components/fields';
import { optionsForTypeShippingAgent } from '../../../constants/optionsForSelect';
import SHIPPING_AGENT from '../../../constants/types-shiping-agent';
import Content from 'components/layout/content';
import AgentTKFields from './tk-fields';
import AgentClientFields from './client-fields';
import { useDispatch, useSelector } from 'react-redux';
import { SHIPPING_AGENT_FORM } from 'pages/Logistic/constants';
import { getAgentFormValue } from 'pages/Logistic/selectors';
import pick from 'lodash/pick';

const ShippingAgentForm = ({ form, submitting, handleSubmit }) => {
  const {
    agents: { getBranches },
  } = useDispatch();

  const type = useSelector(getAgentFormValue('type'));
  const [time, setTime] = useState();

  return (
    <Content title="Создать агента по доставке">
      <form onSubmit={handleSubmit}>
        <div className="shiping-agent__container">
          <div className="row">
            <Field
              name="type"
              label="Тип агента по доставке"
              options={optionsForTypeShippingAgent}
              placeholder="Выберите тип агента по доставке"
              required
              component={RenderSelect}
            />
          </div>
          {type && (
            <div className="row">
              <Field
                name="branch"
                label="Выбрать филиал компании"
                placeholder="Выбрать филиал компании"
                loadOptions={getBranches}
                handle={value => {
                  const time = pick(value, ['minTimeFrom', 'maxTimeTo', 'minTimeDiff']);
                  setTime(time);
                }}
                valueKey="code"
                labelKey="name"
                async
                required
                component={RenderSelect}
              />
            </div>
          )}
          <AgentTKFields />
          {type && time && type === SHIPPING_AGENT.client && (
            <AgentClientFields form={form} time={time} />
          )}
          {type && (
            <div className="row">
              <button
                type="submit"
                className="btn btn--rect btn-outline-danger"
                disabled={submitting}
              >
                Отправить
              </button>
            </div>
          )}
        </div>
      </form>
    </Content>
  );
};

export default reduxForm({
  form: SHIPPING_AGENT_FORM,
  touchOnChange: true,
  async onSubmit(formValues, { agents: { create } }, { history }) {
    await create(formValues);
    history.push('/logistic/agents');
  },
  validate,
  warn,
})(ShippingAgentForm);
