import { useState } from 'react';
import useDeepEffect from 'hooks/use-deep-effect';

/**
 *
 * @param {ClientRect} rectElem
 * @param {ClientRect} rectModal
 * @param {string} direction
 * @returns {{top: number, left: number}}
 */
const createNewPosition = (rectElem, rectModal, direction) => {
  const { left, top, height, width } = rectElem;

  const topWithHeight = top + height + 15;
  const leftOffset = direction === 'left' ? left - rectModal.width + width : left;

  return {
    top: topWithHeight,
    left: leftOffset,
  };
};

/** @type {ModalPosition} */
const initialPosition = { opacity: 0 };

/**
 * Hook to bound modal position to target elem position
 *
 * @param {ClientRect} rectElem - boundaries of target element
 * @param {ClientRect} rectModal - boundaries of modal to bound on target element
 * @param {string} direction - X-direction where to shift modal related to target
 * @returns {ModalPosition}
 */
const useModalPositionToElem = (rectElem, rectModal, direction = 'right', offset = {}) => {
  const [position, setPosition] = useState(initialPosition);

  useDeepEffect(() => {
    if (rectElem && rectModal) {
      let { top, left } = createNewPosition(rectElem, rectModal, direction);

      setPosition({
        top: `${top + (offset.y || 0)}px`,
        left: `${left + (offset.x || 0)}px`,
        opacity: 1,
        transform: 'none',
      });
    }
  }, [rectElem, rectModal, direction]);

  return position;
};

export default useModalPositionToElem;
