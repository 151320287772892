import { download } from 'common/util';
import alert from 'components/native/alert/function';
import { CACHE_NAME } from 'common/config';

export default {
  name: 'certificates',
  state: {},
  reducers: {},
  effects: ({ api }) => ({
    async getTypes() {
      try {
        const options = await api.get({
          url: 'certificates/types',
          cache: CACHE_NAME,
        });

        return { options: Object.values(options) };
      } catch (e) {
        return [];
      }
    },
    async download(query) {
      try {
        const { blob, filename } = await api.get({
          url: 'certificates/get',
          query,
        });

        download(blob, filename);
      } catch (e) {
        alert(e.message);
      }
    },
  }),
};
