import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';

export function checkEquality(values, initialValues, touched) {
  return Object.keys(values).every(valueKey => {
    if (touched[valueKey] && !Array.isArray(touched[valueKey]) && isObject(touched[valueKey])) {
      return Object.values(touched[valueKey]).every(objValueKey => !objValueKey);
    }
    if (!touched[valueKey]) {
      return true;
    }

    return isEqual(values[valueKey], initialValues[valueKey]);
  });
}
