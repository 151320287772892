import { getItemsBrandsFilter, getSubCategories } from 'pages/Orders/selectors';
import pick from 'lodash/pick';
import { CACHE_NAME } from 'common/config';

export default {
  name: 'catalog',
  state: {
    categories: [],
    subCategories: {},
    forSale: false,
    activeCategory: 0,
  },
  reducers: {
    loadFirst: (state, payload) => ({ ...state, categories: payload || [] }),
    loadSecond: (state, data) => {
      if (!data?.length) return state;

      const { parentId } = data[0];

      if (!parentId) return state;

      state.subCategories[parentId] = data;

      return state;
    },
    clear: state => ({ ...state, categories: [], subCategories: {}, activeCategory: 0 }),
    setForSale: (state, payload) => ({ ...state, forSale: payload }),
    setActiveCategory: (state, payload) => ({ ...state, activeCategory: payload }),
  },
  effects: dispatch => ({
    async loadCategories({ isPreorder, isDemofond }) {
      const { data } = await dispatch.api.get({
        url: isDemofond ? 'demofond/category/first' : 'orders/category/first',
        query: window.encodeURI(isPreorder ? '?preorder=1' : ''),
        cache: isDemofond ? '' : CACHE_NAME,
      });
      this.loadFirst(data);
    },
    async loadSubCategories({ category, isPreorder, isDemofond }, state) {
      this.setActiveCategory(category.id);
      const subCategories = getSubCategories(state)(category.id);

      if (subCategories.length) {
        this.loadSecond(subCategories);
        return;
      }

      const categoryId = window.encodeURI(category.id);

      const { data } = await dispatch.api.get({
        url: isDemofond
          ? `demofond/category/second${categoryId}`
          : `orders/category/second/${categoryId}`,
        query: window.encodeURI(isPreorder ? '?preorder=1' : ''),
        cache: isDemofond ? '' : CACHE_NAME,
      });
      this.loadSecond(data);
    },
    async getItems({ group, groups, row, orderNo = '', text = '', isPreorder }, state) {
      const brands = getItemsBrandsFilter(state);

      dispatch.api.clearHistory();

      try {
        const {
          data: { data, facets, characteristics, breadcrumbs },
        } = await dispatch.api.post({
          url: isPreorder ? `/orders/edit/group-preorder/${group}` : `/orders/edit/group/${group}`,
          query: (orderNo && `?order=${orderNo}`) || '',
          data: {
            search: text,
            brands,
            tree: row.original && pick(row.original, ['code1', 'code2', 'code3']),
          },
          log: true,
        });

        dispatch.items.load({ facets, characteristics, breadcrumbs, groups });

        return data;
      } catch (e) {
        return {};
      }
    },
  }),
  selectors: slice => ({
    getForSale() {
      return slice(catalog => !!Number(catalog.forSale));
    },
  }),
};
