/**
 * Created by marat.m on 15.08.2017.
 */
const os = require('os');

// eslint-disable-next-line import/prefer-default-export
export const isDevEnv = /sandbox|localhost|dev/.test(os.hostname());
export const isTestEnv = /test|preprod/.test(os.hostname());
export const isProdEnv =
  os.hostname() === 'b2b.merlion.com' || os.hostname() === 'trade.officeassistant.ru';
export const isPreProdEnv = /webdevprod/.test(os.hostname());
export const isCactus = os.hostname().includes('officeassistant');

const protocol = `https`;

export const STATIC_PATH = '/dist';
export const APP_NAME = 'Интернет заказ';
export const APP_NAME_CACTUS = 'Office Assistant';

export const CACHE_NAME = 'B2B-CACHE-V1';

export const ROOT_URL = `${protocol}://${os.hostname()}`;
export const API_URL = `${ROOT_URL}/api`;
export const FILE_URL = `${API_URL}/v1/file/get`;
export const SOCK_JS_URL = `${protocol}://${os.hostname()}/centrifugo/connection/sockjs`;

export const QUEUE_PAGE = '/queue';

export const WDS_PORT = 8081;

export const APP_CONTAINER_CLASS = 'js-app';
export const APP_CONTAINER_SELECTOR = `.${APP_CONTAINER_CLASS}`;

/**
 * error http status codes for handle
 * @type {number}
 */
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOTFOUND = 404;
export const API_CONSTRAINT = 488;
export const TOKEN_EXPIRED = 487;
export const NODE_API_CONSTRAINT = 489;
export const CSRF_CONSTRAINT = 499;
export const API_INTERNAL = 500;
export const MAINTENANCE = 503;

export const QUEUE_DOCS = 'QUEUE_DOCS';
export const QUEUE_MOVE = 'QUEUE_MOVE';
export const QUEUE_ORDER = 'QUEUE_ORDER';
export const QUEUE_DOCUMENT = 'QUEUE_DOCUMENT';
export const QUEUE_LINKS = 'QUEUE_LINKS';
export const QUEUE_ACCOUNTED = 'QUEUE_ACCOUNTED';
export const QUEUE_JOIN = 'QUEUE_JOIN';
export const QUEUE_CLAIM = 'QUEUE_CLAIM';

/* Справочника нет, засим - вот так */
export const WHATSAPP_VALUE = 1;
export const VIBER_VALUE = 2;
export const TELEGRAM_VALUE = 3;
export const SKYPE_VALUE = 4;

/** Настройки валидации файла привязок * */
export const LINKED_MAX_FILE_SIZE = 8388608;
export const LINKED_FILE_TYPES = [
  'application/excel',
  'application/vnd.ms-excel',
  'application/x-excel',
  'application/x-msexcel',
  'application/ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/data',
];

export const FEEDBACK_FILE_TYPES = [
  'application/excel',
  'application/vnd.ms-excel',
  'application/x-excel',
  'application/x-msexcel',
  'application/ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/data',
  'image/jpeg',
  'application/pdf',
  'image/png',
  'application/vnd.ms-powerpoint',
  'application/x-rar-compressed',
  'application/zip',
  'audio/mp3',
  'text/plain',
  'application/msword',
  'zip',
  'application/octet-stream',
  'application/zip',
  'application/x-zip',
  'application/x-zip-compressed',
  'rar',
];

export const DOC_TYPES_BASE = [9, 10, 11, 12, 14, 16];

export const DEFAULT_PAGE_SIZE = 25;

export const DEFAULT_PHOTO_BIG = {
  name: 'nophoto_big',
  url: 'https://static.merlion.ru/img/no-photo-big.jpg',
};

export const DEFAULT_PHOTO_BIG_2 = {
  name: 'nophoto_big',
  url: 'https://static.merlion.ru/img/no-photo-big.jpg',
};

export const DEFAULT_PHOTO_MID = {
  name: 'nophoto_mid',
  url: 'http://static.merlion.ru/img/nophoto.png',
};

export const DEFAULT_PHOTO_SMALL = {
  name: 'nophoto_small',
  url: 'http://static.merlion.ru/img/nophoto.png',
};

export const YM_TRACK_NUMBER = 53555377;
export const FOUND_WITH_AVAILABILITY = 300;

export const DEMOFOND_TYPE_TEST = 0;
export const DEMOFOND_TYPE_SALE = 1;

export const PRODUCT_NOT_FOUND = 'Товар не найден';
