// @ts-nocheck
import { availabilityFilter } from '../constants/availability';

export const getAvailFilters = avail => ({
  ...availabilityFilter,
  values: availabilityFilter.values.map(item => ({
    ...item,
    checked: item.id === avail,
  })),
});
