import reactRouterFetch from 'utils/react-router-fetch';
import apiRequest from 'models/effects/apiRequest';

const api = {
  name: 'api',
  state: {
    appFetchingError: null,
    manual: false,
    history: [],
  },
  reducers: {
    start: state => state,
    end: (state, { error }) => {
      if (!error) {
        state.appFetchingError = null;
      }

      return state;
    },
    log: (state, payload) => {
      state.history.push({ ...payload });
      state.history.length === 20 && state.history.splice(0, 10);

      return state;
    },
    clearHistory: state => ({ ...state, history: [] }),
    fail: (state, payload) => ({ ...state, appFetchingError: payload }),
    success: state => state,
    clearFails: state => ({ ...state, appFetchingError: null }),
    'user/login': state => ({
      ...state,
      manual: false,
      appFetchingError: null,
    }),
    'user/logout': state => ({
      ...state,
      manual: true,
    }),
  },
  effects: dispatch => ({
    async fetchRoute({ location, routes }, state) {
      await reactRouterFetch(routes, location, { dispatch, state, cache: false });
    },
    async get(conf, state) {
      return await apiRequest('GET', conf)(dispatch, state);
    },
    async post(conf, state) {
      return await apiRequest('POST', conf)(dispatch, state);
    },
    async put(conf, state) {
      return await apiRequest('PUT', conf)(dispatch, state);
    },
    async delete(conf, state) {
      return await apiRequest('DELETE', conf)(dispatch, state);
    },
    async upload({ url, name, file, onProgress }) {
      const data = new FormData();
      data.append(name, file, file.name);

      return await this.post({
        url,
        query: `${file.code ? `?fieldName=${encodeURIComponent(file.code)}` : ''}`,
        data,
        onProgress,
      });
    },
  }),
  selectors: slice => ({
    getAppFetchingError: () => slice(api => api.appFetchingError || {}),
  }),
};

export default api;
