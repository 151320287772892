import React from 'react';
import get from 'lodash/get';

const passData = [
  {
    accessor: 'functionality',
    Header: '',
    width: 0,
    show: true,
    Cell: () => null,
  },
  {
    Header: 'ФИО водителя',
    accessor: 'representativeFIO',
  },
  {
    Header: 'Марка и номер автомобиля',
    accessor: 'carModel',
    Cell: row => {
      if (!row) return null;
      const carModel = row.value || '';
      const stateNumber = get(row, ['original', 'stateNumber'], '') || '';

      return <span>{`${carModel} ${stateNumber}`}</span>;
    },
  },
];

export default passData;
