// @ts-nocheck
import Cookies from 'universal-cookie';

/**
 * @class {CookiesService} Cookies Class specification
 */
class CookiesService {
  /**
   * Create a cookies instance.
   */
  constructor() {
    this.cookies = new Cookies();
  }

  /**
   * GET  method
   * @see https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie#getname-options
   *
   * @param {string} name
   * @param {object} options
   * @returns {string|number|object}
   */
  get(name, options = {}) {
    return this.cookies.get(name, options);
  }

  /**
   * GET ALL  method
   * @see https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie#getalloptions
   *
   * @param {object} options
   * @returns {object}
   */
  getAll(options = {}) {
    return this.cookies.getAll(options);
  }

  /**
   * SET  method
   * @see https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie#setname-value-options
   *
   * @param {string} name
   * @param {string|number|object} value
   * @param {object} options
   * @returns
   */
  set(name, value, options = { path: '/' }) {
    this.cookies.set(name, value, options);
  }

  /**
   * REMOVE  method
   * @see https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie#removename-options
   *
   * @param {string} name
   * @param {object} options
   * @returns
   */
  remove(name, options = { path: '/' }) {
    this.cookies.remove(name, options);
  }

  /**
   * ADD CHANGE LISTENER method
   * @see https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie#addchangelistenercallback
   *
   * @callback cb
   * @returns
   */
  addChangeListener(cb) {
    this.cookies.addChangeListener(cb);
  }

  /**
   * REMOVE CHANGE LISTENER method
   * @see https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie#removechangelistenercallback
   *
   * @callback cb
   * @returns
   */
  removeChangeListener(cb) {
    this.cookies.removeChangeListener(cb);
  }

  setForever(name, value, options = { path: '/' }){
    let date = new Date()

    date.setFullYear(date.getFullYear() + 10)

    this.set(name, value, {...options, expires: date})
  }
}

const CookiesInstance = new CookiesService();

export { CookiesInstance as Cookies };
