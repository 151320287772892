import React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import Loader from 'components/loader';
import OsvGenerateButton from './OsvGenerateButton';
import {
  ACTIVE_GENERATION_STATUS,
  ERROR_GENERATION_STATUS,
  FILE_GENERATED_STATUS,
} from 'pages/Finances/constants';

const Sheet = ({ period }) => {
  const {
    select: { osv },
  } = useStore();
  const sheet = useSelector(osv.getSheet(period));
  const {
    osv: { send, get },
  } = useDispatch();

  const { status, operationNo, date, time, errorText } = sheet;
  const data = `${date} ${time}`;

  switch (status) {
    case ACTIVE_GENERATION_STATUS:
      return <Loader>Идет обработка</Loader>;
    case FILE_GENERATED_STATUS: {
      return (
        <div className="buttons">
          <i
            className="icon icon-load_xls"
            onClick={async () => await get(operationNo)}
            title={`Скачать (${data})`}
          />
          <i
            className="icon icon-sendmail"
            onClick={async () => await send({ operationNo, period })}
            title={`Отправить на  e-mail (${data})`}
          />
          <OsvGenerateButton small period={period} />
          <div className="date-generated">{data}</div>
        </div>
      );
    }
    case ERROR_GENERATION_STATUS:
      return <OsvGenerateButton period={period} error={errorText} />;
    default:
      return <OsvGenerateButton period={period} />;
  }
};

export default Sheet;
