import React, { Fragment, useCallback, useEffect, useMemo, useState, memo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { hasOrderEditAccess, smoothScrollToTop } from 'common/util';
import OrderAutoSign from 'pages/Orders/components/common/order-auto-sign';
import OrderActions from 'pages/Orders/components/common/order-actions';
import { isEmpty } from 'lodash';
import notifier from 'services/notifier';
import ItemsSpecification from 'pages/Orders/components/common/items-spec';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder, getUser, isFetchingItems } from 'pages/Orders/selectors';
import { getModelLoading, getTableListData } from 'common/selectors';
import useCurrentOrder from 'pages/Orders/hooks/useCurrentOrder';
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';
import { preparedData } from 'pages/Orders/components/order-edit/helpers/prepareData';
import { CARD_PAGE_PATH } from 'common/routes/constants';
import ItemsImport from '../items-import';
import ItemsExport from '../items-export';
import ItemsFilter from '../items-filter';
import Catalog from '../../catalog';
import useListData from "components/table/hooks/useListData"

const CATALOG_ORDER = 'Каталог товаров';
const CATALOG_PREORDER = 'Каталог товаров для предзаказа';

const OrderFinder = memo(() => {
  const order = useSelector(getOrder);
  const user = useSelector(getUser);
  const listData = useListData();
  const fetching = useSelector(getModelLoading('order'));
  const fetchingItems = useSelector(isFetchingItems);

  const [isCatalogOpened, setIsCatalogOpened] = useState(false);

  const { pathname } = useLocation();
  const { id } = useParams();
  const isCard = pathname.includes(CARD_PAGE_PATH);

  const {
    items: { save },
  } = useDispatch();

  const isNotPreorder = !useIsPreorder();

  useEffect(() => {
    setIsCatalogOpened(!order.orderNo && isEmpty(listData) && !fetchingItems || !isNotPreorder);
  }, [order.orderNo, isNotPreorder, listData.length, fetchingItems]); // eslint-disable-line

  const currentOrder = useCurrentOrder();
  const { sign, orderNo } = currentOrder;

  useEffect(() => {
    preparedData.clear();
  }, [id]);

  const catalogText = isNotPreorder ? CATALOG_ORDER : CATALOG_PREORDER;

  const preorderedStyle = useMemo(
    () => ({
      display: isNotPreorder ? 'flex' : 'none',
    }),
    [isNotPreorder],
  );

  const handleSave = useCallback(
    id => async () => {
      await save({ isCard, orderNo: id, notifier });
    },
    [pathname, id],
  );

  return (
    <>
      {isCatalogOpened && <Catalog order={currentOrder} />}
      <div
        className={[
          'align-items-center',
          'actions-container',
          'actions-container--order-filters',
          'row',
          'order-finder-container',
        ].join(' ')}
        style={{ margin: 0 }}
        id="order-finder-container"
      >
        <div
          className={['event-action', 'event-actions__dashed-columns', 'align-items-center'].join(
            ' ',
          )}
          style={{ margin: 0 }}
        >
          <div>
            <a
              href=""
              title="Показать группы товаров"
              className={classNames(
                'section-nav',
                'section-nav__no-underline',
                'section-nav--dashed',
                'section-nav--upper',
                {
                  'section-nav__drop-arrow': !isCatalogOpened,
                  'section-nav__pick-arrow': isCatalogOpened,
                },
              )}
              onClick={e => {
                e.preventDefault();
                smoothScrollToTop(2000);
                setIsCatalogOpened(!isCatalogOpened);
              }}
            >
              {catalogText}
            </a>
          </div>
          {isNotPreorder && (
            <ItemsFilter order={currentOrder} className="form-control form-control-sm" />
          )}
          <ItemsExport />
          {isNotPreorder && <ItemsImport style={preorderedStyle} />}
          <ItemsSpecification />
        </div>
        <div className={classNames('order-change-action', 'justify-content-end')}>
          {(orderNo &&
            ((!sign && hasOrderEditAccess(user, currentOrder) && (
              <>
                <OrderActions order={order} />
                <OrderAutoSign order={currentOrder} />
                &nbsp;&nbsp;
                <button
                  className="btn btn-danger btn--rect"
                  onClick={handleSave(id || orderNo)}
                  disabled={fetching}
                >
                  Добавить в заказ
                </button>
              </>
            )) || <span className="red">Редактирование заказа запрещено</span>)) ||
            (!!listData.length && (
              <button
                className="btn btn-danger btn--rect"
                onClick={handleSave()}
                disabled={fetching}
              >
                Добавить в заказ
              </button>
            ))}
        </div>
      </div>
    </>
  );
});

OrderFinder.displayName = 'OrderFinder';
OrderFinder.whyDidYouRender = false;

export default OrderFinder;
