import React, { useCallback, useState, useMemo, Fragment, useRef, useEffect } from 'react';
import ProgressBar from 'components/progress-bar';
import { updateSyncErrors } from 'redux-form';
import UploadedFile from 'components/UploadedFile';
import alert from 'components/native/alert/function';
import isFunction from 'lodash/isFunction';

const renderUploadFile = props => {
  const {
    input: { name, onChange, value },
    url,
    code,
    label,
    meta: { touched, error, valid, dirty, dispatch, form },
    model,
    progressClassName,
    deleteFile,
    speedDelay = 3,
  } = props;

  const inputRef = useRef();

  const progressStyle = useMemo(
    () => ({
      webkitTransition: `width ${speedDelay}s 0s ease-out`,
      '-moz-transition': `width ${speedDelay}s 0s ease-out`,
      '-o-transition': `width ${speedDelay}s 0s ease-out`,
      transition: `width ${speedDelay}s 0s ease-out`,
    }),
    [speedDelay],
  );

  const [progress, setProgress] = useState({ val: 0, max: 1 });
  const [fileName, setFileName] = useState();

  const onProgress = useCallback(({ loaded, total }) => {
    setProgress(progress => ({
      ...progress,
      val: loaded,
      max: total,
    }));
  }, []);

  const handleChoose = () => {
    if (fileName) {
      return alert('Удалите загруженный файл!', {
        noFixedLayout: true,
      });
    }

    inputRef.current.click();
  };

  useEffect(() => {
    if (dirty && valid) {
      dispatch.api
        .upload({
          url,
          name,
          file: value,
          onProgress,
        })
        .then(({ success, message }) => {
          if (!success) {
            dispatch(
              updateSyncErrors(form, {
                [name]: message,
              }),
            );
          } else {
            setFileName(value.name);
          }
        })
        .catch(err => {
          dispatch(
            updateSyncErrors(form, {
              [code]: err.message,
            }),
          );
        });
    }
    if (!value) {
      setProgress({ val: 0, max: 1 });
      setFileName('');

      inputRef.current.value = '';
    }
  }, [valid, value, name, url, dirty]);

  const handleChange = async ({ target: { files } }) => {
    const file = files[0];

    if (!file) {
      return;
    }

    file.code = code;
    onChange(file);
  };

  const handleDelete = async () => {
    if (!isFunction(deleteFile)) {
      return;
    }

    try {
      await deleteFile(fileName);

      onChange(undefined);
    } catch (e) {
      alert(e.message, {
        noFixedLayout: true,
      });
    }
  };

  return (
    <Fragment>
      <input
        ref={inputRef}
        name={name}
        onChange={handleChange}
        type="file"
        className="form-field-download__input-file"
      />
      {
        <label className="form-field-download__label" htmlFor={`${code}`} onClick={handleChoose}>
          {label}
        </label>
      }
      {(fileName && (
        <UploadedFile
          name={fileName}
          model={model}
          deleteDocument={isFunction(deleteFile) && handleDelete}
        />
      )) || (
        <ProgressBar
          max={progress.max}
          val={progress.val}
          className={progressClassName}
          style={progressStyle}
        />
      )}
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </Fragment>
  );
};

export default renderUploadFile;
