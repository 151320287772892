export const sortByName = (itemCategoryA, ItemCategoryB) => {
  if (itemCategoryA.name < ItemCategoryB.name) {
    return -1;
  }
  if (itemCategoryA.name > ItemCategoryB.name) {
    return 1;
  }
  return 0;
};

export const sortByFavorite = favoriteCategories => (itemCategoryA, ItemCategoryB) => {
  const includeA = favoriteCategories.includes(itemCategoryA.id);
  const includeB = favoriteCategories.includes(ItemCategoryB.id);
  if (includeA && !includeB) {
    return -1;
  }
  if (!includeA && includeB) {
    return 1;
  }
  return 0;
};
