import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../icon.module.scss';

export const Question = ({
  className,
  color = '#236192',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="18" height="18" rx="9" fill={color} />
    <path
      d="M8.1 11.522C8.09067 11.4753 8.086 11.424 8.086 11.368C8.086 11.312 8.086 11.2607 8.086 11.214C8.086 10.822 8.142 10.4767 8.254 10.178C8.37533 9.87933 8.52 9.60867 8.688 9.366C8.86533 9.114 9.052 8.88067 9.248 8.666C9.45333 8.45133 9.64 8.23667 9.808 8.022C9.98533 7.798 10.13 7.56467 10.242 7.322C10.3633 7.07 10.424 6.78533 10.424 6.468C10.424 6.03867 10.298 5.68867 10.046 5.418C9.80333 5.14733 9.39267 5.012 8.814 5.012C8.478 5.012 8.13733 5.07267 7.792 5.194C7.44667 5.306 7.148 5.44133 6.896 5.6L6.518 4.732C6.87267 4.51733 7.246 4.34933 7.638 4.228C8.03 4.09733 8.51533 4.032 9.094 4.032C9.50467 4.032 9.864 4.09267 10.172 4.214C10.4893 4.326 10.7507 4.48467 10.956 4.69C11.1707 4.89533 11.3293 5.138 11.432 5.418C11.5347 5.698 11.586 5.99667 11.586 6.314C11.586 6.734 11.5207 7.10267 11.39 7.42C11.2687 7.728 11.11 8.01267 10.914 8.274C10.7273 8.526 10.522 8.764 10.298 8.988C10.0833 9.212 9.878 9.45 9.682 9.702C9.49533 9.94467 9.33667 10.2107 9.206 10.5C9.08467 10.7893 9.024 11.13 9.024 11.522H8.1ZM7.82 13.356C7.82 13.104 7.89 12.908 8.03 12.768C8.17933 12.6187 8.37533 12.544 8.618 12.544C8.87 12.544 9.07067 12.6187 9.22 12.768C9.36933 12.908 9.444 13.104 9.444 13.356C9.444 13.5987 9.36933 13.7947 9.22 13.944C9.07067 14.0933 8.87 14.168 8.618 14.168C8.37533 14.168 8.17933 14.0933 8.03 13.944C7.89 13.7947 7.82 13.5987 7.82 13.356Z"
      fill="white"
    />
  </svg>
);

Question.defaultProps = {
  className: '',
  color: '#236192',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Question.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
