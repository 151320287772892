import 'abort-controller/polyfill';
import { API_MAP } from 'common/init-api';
import {CSRF_CONSTRAINT, FORBIDDEN, isDevEnv, isTestEnv, NOTFOUND, UNAUTHORIZED} from 'common/config'
import { def, undef } from 'common/util';
import fetchApi from 'utils/fetchApi';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import fetchProgress from 'utils/fetchProgress';
import { activeControllers } from 'common/collections';

export default (method, conf = {}) =>
  async (dispatch, state) => {
    const {
      url,
      query = '',
      data = null,
      successCallback = false,
      successPayload = {},
      headers = {},
      log = false,
      cancellation = true,
      onProgress,
      cache,
      apiVersion = 'v1',
    } = conf;

    if (undef(url)) {
      console.error('You must have an url key in request config!');
      throw { status: NOTFOUND, message: 'Запрашиваемый ресурс не найден' };
    }

    const key = Object.keys(API_MAP).find(key => new RegExp(key).test(url));

    if (!key) {
      console.error(`Check your init-api on ${url} key!`);
      throw { status: NOTFOUND, message: 'Запрашиваемый ресурс не найден' };
    }

    let matches = url.match(new RegExp(key));
    matches.shift();
    matches = matches.filter(element => element?.length > 0);

    const endpoint = `${API_MAP[key].endpoint.replace('%version%', apiVersion)}${
      matches.length ? `/${matches.join('/')}` : ''
    }${query}${
      isTestEnv || isDevEnv
        ? query.length
          ? '&XDEBUG_SESSION_START=PHPSTORM'
          : '?XDEBUG_SESSION_START=PHPSTORM'
        : ''
    }`;

    const heads = { ...headers, Authorization: `Bearer ${state.secure.csrf || 'initial'}` };
    const isSafetyMethods = ['GET', 'HEAD', 'OPTIONS'].includes(method);

    const options = {
      method,
      headers: { ...heads, 'Content-Type': 'application/json' },
      mode: 'same-origin',
      credentials: 'same-origin',
      cache,
      key: url,
    };
    let controller;
    const isFormData = data instanceof FormData;

    if (!isEmpty(data) || isFormData) {
      options.body = isFormData ? data : JSON.stringify(data);
      options.headers = isFormData ? heads : { ...heads, 'Content-Type': 'application/json' };
    }
    if (cancellation) {
      activeControllers.abort(url);

      if (def(AbortController)) {
        controller = new AbortController();
        options.signal = controller.signal;

        activeControllers.set(url, controller);
      }
    }

    dispatch.api.start()

    if (log) {
      dispatch.api.log({
        url,
        query,
        body: data,
        successPayload,
        headers,
      });
    }
    let result;

    try {
      if (isFunction(onProgress)) {
        result = { ...(await fetchProgress(endpoint, onProgress, options)), ...successPayload };
      } else {
        result = { ...(await fetchApi(endpoint, options)), ...successPayload };
      }

      const { success = true } = result

      if (API_MAP[key].success && success) {
        const successType = successCallback || API_MAP[key].success;
        const [model, action] = successType.split('/');

        if (!dispatch[model] || !dispatch[model][action]) {
          if (successType) {
            dispatch({ type: successType, payload: result });
          } else {
            console.error(
              `Invalid success for ${model}/${action}! Check your init-api.js for ${url}`,
            );
          }
        } else {
          dispatch[model][action](result);
        }
      }

      success && dispatch.api.success({ url, ...result });

      if (result.refresh) {
        dispatch.user.refresh(result);
      }

      if (!isSafetyMethods && result['csrf_token']) {
        dispatch.secure.csrf(result['csrf_token']);
      }

      dispatch.api.end({ error: false });

      return result;
    } catch (error) {
      if(error.status){
        dispatch.api.fail(error);
        dispatch.api.end({ error: true });

        if([UNAUTHORIZED, FORBIDDEN, CSRF_CONSTRAINT].includes(error.status)){
          activeControllers.abortAll();
        }

        if (error.status === UNAUTHORIZED) {
          return dispatch.auth.logout(false);
        }
      }

      throw error;
    }
  };
