import React from 'react';
import { reduxForm } from 'redux-form';
import validate from 'common/validates/validatePassword';
import Content from 'components/layout/content';
import PasswordForm from 'pages/Settings/components/password/components/form';
import { useSelector } from 'react-redux';
import { getSettingsInfo } from 'pages/Settings/selectors';
import { getUser } from 'pages/Orders/selectors';

const FORM_DATA = {
  firstField: {
    name: 'oldPass',
    label: 'Старый пароль',
  },
  secondField: {
    name: 'newPass',
    label: 'Новый пароль',
  },
  btnName: 'Сохранить',
};

const ChangePassword = props => {
  const info = useSelector(getSettingsInfo);
  const user = useSelector(getUser);

  return (
    <Content title={`Смена пароля ${info.login || user.login} (${user.client})`}>
      <PasswordForm
        formData={FORM_DATA}
        {...props}
      />
    </Content>
  );
};

export default reduxForm({
  form: 'changePassForm',
  validate,
  onSubmit: async (data, { personal: { changePass } }) => await changePass(data)
})(ChangePassword);
