import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Pdf = ({
  className,
  color = '#236192',
  name,
  width = '25',
  height = '33',
  viewBox = '0 0 25 33',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M1 1H15L24.0263 10L24 32H1V1Z" stroke={color} strokeLinejoin="round" />
      <path d="M15 10H24L15 1V10Z" stroke={color} strokeLinejoin="round" />
      <path
        d="M4 20.18C4.304 20.108 4.632 20.06 4.984 20.036C5.336 20.012 5.684 20 6.028 20C6.396 20 6.768 20.036 7.144 20.108C7.528 20.18 7.876 20.312 8.188 20.504C8.5 20.696 8.752 20.96 8.944 21.296C9.144 21.632 9.244 22.06 9.244 22.58C9.244 23.092 9.152 23.524 8.968 23.876C8.784 24.228 8.54 24.516 8.236 24.74C7.932 24.956 7.584 25.112 7.192 25.208C6.8 25.304 6.396 25.352 5.98 25.352C5.94 25.352 5.872 25.352 5.776 25.352C5.688 25.352 5.592 25.352 5.488 25.352C5.392 25.344 5.296 25.336 5.2 25.328C5.104 25.32 5.036 25.312 4.996 25.304V28.496H4V20.18ZM6.052 20.864C5.844 20.864 5.644 20.868 5.452 20.876C5.26 20.884 5.108 20.904 4.996 20.936V24.416C5.036 24.432 5.1 24.444 5.188 24.452C5.276 24.452 5.368 24.456 5.464 24.464C5.56 24.464 5.652 24.464 5.74 24.464C5.828 24.464 5.892 24.464 5.932 24.464C6.204 24.464 6.472 24.44 6.736 24.392C7.008 24.336 7.252 24.24 7.468 24.104C7.684 23.968 7.856 23.776 7.984 23.528C8.12 23.28 8.188 22.964 8.188 22.58C8.188 22.252 8.124 21.98 7.996 21.764C7.876 21.54 7.712 21.364 7.504 21.236C7.304 21.1 7.076 21.004 6.82 20.948C6.564 20.892 6.308 20.864 6.052 20.864Z"
        fill={color}
      />
      <path
        d="M10 20.096C10.152 20.072 10.32 20.056 10.504 20.048C10.696 20.032 10.892 20.024 11.092 20.024C11.3 20.016 11.5 20.012 11.692 20.012C11.892 20.004 12.076 20 12.244 20C12.916 20 13.496 20.104 13.984 20.312C14.48 20.52 14.888 20.812 15.208 21.188C15.528 21.556 15.764 22 15.916 22.52C16.076 23.04 16.156 23.612 16.156 24.236C16.156 24.804 16.08 25.352 15.928 25.88C15.784 26.4 15.552 26.864 15.232 27.272C14.912 27.672 14.496 27.996 13.984 28.244C13.472 28.484 12.852 28.604 12.124 28.604C11.996 28.604 11.828 28.6 11.62 28.592C11.42 28.592 11.212 28.584 10.996 28.568C10.78 28.56 10.58 28.552 10.396 28.544C10.212 28.536 10.08 28.524 10 28.508V20.096ZM12.268 20.888C12.164 20.888 12.048 20.888 11.92 20.888C11.8 20.888 11.68 20.892 11.56 20.9C11.44 20.908 11.328 20.916 11.224 20.924C11.128 20.932 11.052 20.94 10.996 20.948V27.668C11.036 27.676 11.112 27.684 11.224 27.692C11.336 27.692 11.452 27.696 11.572 27.704C11.692 27.704 11.808 27.708 11.92 27.716C12.032 27.716 12.108 27.716 12.148 27.716C12.708 27.716 13.176 27.62 13.552 27.428C13.936 27.236 14.24 26.98 14.464 26.66C14.696 26.332 14.86 25.96 14.956 25.544C15.052 25.12 15.1 24.684 15.1 24.236C15.1 23.844 15.056 23.452 14.968 23.06C14.88 22.66 14.728 22.3 14.512 21.98C14.296 21.66 14.004 21.4 13.636 21.2C13.276 20.992 12.82 20.888 12.268 20.888Z"
        fill={color}
      />
      <path
        d="M17 20.0962H21.572V20.9842H17.996V23.8522H21.332V24.7402H17.996V28.4962H17V20.0962Z"
        fill={color}
      />
    </svg>
  );
};

Pdf.defaultProps = {
  className: '',
  color: '#236192',
  width: '25',
  height: '33',
  viewBox: '0 0 25 33',
};

Pdf.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
