/* eslint-disable no-unused-vars, no-console */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBrowser } from 'common/util';
import StackTrace from 'stacktrace-js';
import { isDevEnv } from 'common/config';
import alert from 'components/native/alert/function';
import isEqual from 'lodash/isEqual'

class ErrorBoundary extends Component {
  state = { hasError: false };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !isEqual(this.state.errorInfo, nextState.errorInfo)
  }

  static getDerivedStateFromError(error){
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const { dispatch } = this.props;

    StackTrace.fromError(error)
      .then(stackFrames => {
        const reportLines = stackFrames.map(stackFrame => {
          const { lineNumber, functionName, fileName } = stackFrame;
          return `${fileName}:${lineNumber} ${functionName}`;
        });
        const stackReport = reportLines.join('\n');

        /*!isDevEnv &&*/
          dispatch.api.post({
            url: '/error',
            data: {
              error: error.toString(),
              hash: __webpack_hash__,
              info: stackReport,
              componentStack: errorInfo.componentStack,
              browser: getBrowser(),
              actions: this.props.lastActions
            },
          });
      })
      .catch(console.error);
  }

  forceLogout = async () => {
    const { dispatch } = this.props;
    await dispatch.auth.logout();
    window.location.href = '/';
  };

  render() {
    if (this.state.hasError) {
      alert(
        'Возникла непредвиденная ошибка, с которой мы уже начали разбираться.<br />' +
          'Извините, но чтобы продолжить работу, Вам придется еще раз авторизоваться.',
      ).then(this.forceLogout);
      return null;
    }

    return this.props.children;
  }
}

export default connect(state => ({
  lastActions: state.api.history
}))(ErrorBoundary);
