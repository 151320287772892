import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { FORBIDDEN } from 'common/config';
import { Header } from 'common/layout/header';
import { Footer } from 'common/layout/footer';
import { Forbidden } from 'components/new/forbidden';
import { ScrollToTop } from 'utils/new/scroll-to-top';

import styles from './base-layout.module.scss';

export const BaseLayout = ({ children, className }) => {
  const { status, message } = useSelector(state => state.api.appFetchingError) || {};

  return (
    <ScrollToTop>
      <div className={cn(styles['base-layout'], className)} data-testid="base-layout">
        <Header />
        {status === FORBIDDEN ? (
          <Forbidden message={message} />
        ) : (
          <main className={cn(styles['base-layout__inner'])}>{children}</main>
        )}
        <Footer />
      </div>
    </ScrollToTop>
  );
};

BaseLayout.defaultProps = {
  className: '',
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
