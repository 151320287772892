import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import alert from '../native/alert/function';
import { setCorrectTime, checkFormatTime, checkWorkingTime } from 'helpers/timePicker';
import RenderTimeInput from './renderTimeInput';

const RenderTimePicker = ({ timeMin, timeMax, timeDiff, input }) => {
  const [timeFrom, setTimeFrom] = useState('');
  const [timeTo, setTimeTo] = useState('');

  useEffect(() => {
    setTimeFrom(timeMin);
    setTimeTo(timeMax);
  }, [timeMin, timeMax]);

  useEffect(() => {
    let result = {};

    if (checkFormatTime.test(timeFrom) && checkFormatTime.test(timeTo)) {
      const workingTime = checkWorkingTime(timeFrom, timeTo, timeMin, timeMax, timeDiff);

      if (workingTime.success) {
        result = { timeFrom, timeTo };
      } else {
        alert(workingTime.message);
        setTimeFrom(timeMin);
        setTimeTo(timeMax);
      }
    }

    input.onChange(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrom, timeTo]);

  const handleChangeTimeFrom = ({ target: { value } }) => {
    if (value.length <= 5) {
      const correctedTime = setCorrectTime(value);
      setTimeFrom(correctedTime);
    }
  };

  const handleChangeTimeTo = ({ target: { value } }) => {
    if (value.length <= 5) {
      const correctedTime = setCorrectTime(value);
      setTimeTo(correctedTime);
    }
  };

  return (
    <div className="time-picker">
      <div className="container-time">
        <RenderTimeInput value={timeFrom} onHandleChange={handleChangeTimeFrom} />
        <RenderTimeInput value={timeTo} onHandleChange={handleChangeTimeTo} />
      </div>
    </div>
  );
};

RenderTimePicker.propTypes = {
  originTimeFrom: PropTypes.string,
  originTimeTo: PropTypes.string,
  timeMin: PropTypes.string,
  timeMax: PropTypes.string,
};

RenderTimePicker.defaultProps = {
  originTimeFrom: '',
  originTimeTo: '',
  timeMin: '',
  timeMax: '',
};

export default RenderTimePicker;
