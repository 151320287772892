/* eslint-disable react/button-has-type */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Loader } from 'common/ui/loader';
import styles from './button.module.scss';
import Group from "common/ui/button/group"
import Spread from "common/ui/button/spread"

/**
 * Renders a <Button /> component
 * @component
 * @example
 *
 * return (
 *   <Button />
 * )
 */
export const Button = forwardRef(
  (
    {
      children,
      className,
      color,
      disabled,
      hasShadow,
      loading,
      theme,
      type = 'button',
      tabIndex,
      ...rest
    },
    ref,
  ) => {
    const classes = cn(styles['button'], className, {
      [styles['button--loading']]: loading,
      [styles['button--with-shadow']]: hasShadow,
      [styles['button--primary']]: theme === 'primary',
      [styles['button--secondary']]: theme === 'secondary',
      [styles['button--ternary']]: theme === 'ternary',
      [styles['button--grouped']]: theme === 'grouped',
    });

    const buttonProps = {
      ...rest,
      className: classes,
      tabIndex: disabled ? -1 : tabIndex,
    };

    return (
      <button
        className={classes}
        disabled={disabled}
        type={type}
        data-testid="button"
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
      >
        {loading ? <Loader theme={theme} /> : children}
      </button>
    );
  },
);

Button.defaultProps = {
  children: null,
  className: '',
  color: 'brand-blue',
  disabled: false,
  hasShadow: false,
  loading: false,
  tabIndex: 0,
  theme: 'default',
  type: 'button',
  onClick: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onKeyUp: () => {},
};

Button.propTypes = {
  /**
   * children
   */
  children: PropTypes.node,
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * 'brand-blue' | 'dark-gray'
   */
  color: PropTypes.string,
  /**
   * Able to click
   */
  disabled: PropTypes.bool,
  /**
   * Button with shadow
   */
  hasShadow: PropTypes.bool,
  /**
   * Loading
   */
  loading: PropTypes.bool,
  /**
   * Accessible order
   */
  tabIndex: PropTypes.number,
  /**
   * 'default' | 'primary' | 'secondary' | 'ternary'
   */
  theme: PropTypes.string,
  /**
   * 'button' | 'reset' | 'submit'
   */
  type: PropTypes.string,
  /**
   * Click event handler
   */
  onClick: PropTypes.func,
  /**
   * Focus event handler
   */
  onFocus: PropTypes.func,
  /**
   * Keydown event handler
   */
  onKeyDown: PropTypes.func,
  /**
   * Keyup event handler
   */
  onKeyUp: PropTypes.func,
};

Button.Group = Group
Button.Spread = Spread
