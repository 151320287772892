import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'pages/Orders/selectors';
import { API_URL } from 'common/config';

const printItem = (serial, id, uid) => {
  window.open(
    `${API_URL}/serial/${serial}?model=${id}&uid=${uid}&XDEBUG_SESSION_START=PHPSTORM`,
    'print',
    'width=800,height=600,toolbar=yes,status=no,resizable=yes,fullscreen=0,scrollbars=yes',
  );
};

export const SerialNoCell = row => {
  const user = useSelector(getUser);

  if (!row) {
    return null;
  }

  if (Number(row.original.expired) === 1) {
    return row.value;
  }

  return (
    <a
      href="#"
      className="link link--underlined"
      onClick={e => {
        e.preventDefault();
        printItem(row.value, row.original.id, user.id);
      }}
      title="Распечатать лист рекламации"
    >
      {row.value}
    </a>
  );
};
