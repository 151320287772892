import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { clearFields } from 'redux-form';
import { LINKED_MAX_FILE_SIZE } from 'common/config';

const RenderInputFile = props => {
  const {
    input,
    meta: { touched, error, warning },
    fileIndex,
    deleteFile,
    addFile,
    formName,
    isRegistered,
    maxFileSize = LINKED_MAX_FILE_SIZE,
  } = props;

  const defaultFileName = 'Выберите файл';
  const [fileName, setFileName] = useState(defaultFileName);
  const [fileSize, setFileSize] = useState('');
  const [fileEmpty, setFileEmpty] = useState(true);
  const fileRef = React.createRef();
  const hasError = (error && error.length) || (warning && warning.length);

  useEffect(() => {
    if (!hasError) {
      if (fileRef.current.files.length && fileName !== fileRef.current.files[0].name) {
        setFileName(fileRef.current.files[0].name);
        setFileEmpty(false);
        setFileSize(fileSizeToText(fileRef.current.files[0].size));
      }
      if (!fileRef.current.files.length && fileName !== defaultFileName) {
        setFileEmpty(true);
        setFileSize('');
        setFileName(defaultFileName);
      }
    }
    if (hasError) {
      fileRef.current.value = null;
      setFileEmpty(true);
      setFileSize('');
      setFileName(defaultFileName);
    }
  }, [addFile, deleteFile, fileIndex, fileName, fileRef, hasError]);

  const fileSizeToText = size => {
    let calcSize = size / 1024;
    if (calcSize < 1) {
      return size + ' Б';
    }
    calcSize = calcSize / 1024;
    if (calcSize < 1) {
      return parseInt(calcSize * 1024) + ' КБ';
    }
    return parseInt(calcSize) + ' МБ';
  };

  const deleteFileHandle = e => {
    e.preventDefault();
    fileRef.current.value = null;
    props.clearFields(formName, false, false, [input.name]);
  };

  const handleClick = () => {
    fileRef.current.value = null;
    props.clearFields(formName, false, false, [input.name]);
    fileRef.current.click();
  };

  return (
    <div className="col-sm-9">
      <label className={props.required ? 'required' : ''}>{props.label}</label>
      <div>
        <div className={'file-description'}>
          <div className={fileEmpty ? 'file-empty' : 'file-not-empty'} onClick={handleClick}>
            {fileName}
          </div>
          {fileEmpty ? (
            <span className={'file-size-max'}>
              (.doc, .xls, .jpg, .mp4 и др.) Максимальный размер файла {maxFileSize / 1024 / 1024}{' '}
              МБ
            </span>
          ) : (
            <span className={'file-size'}>
              {' '}
              {fileSize} <div onClick={deleteFileHandle} className={'delete-file'} />
              {isRegistered && <i className="icon icon-registered" />}
            </span>
          )}
        </div>
        <div className={'file-container'}>
          <input {...input} type={'file'} value={undefined} ref={fileRef} />
        </div>
        {touched &&
          ((error && <span className="text-danger text-danger--small">{error}</span>) ||
            (warning && <span className="text-danger text-danger--small">{warning}</span>))}
      </div>
    </div>
  );
};
const mapDispatchToProps = {
  clearFields,
};
export default connect(null, mapDispatchToProps)(RenderInputFile);
