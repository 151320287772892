import React, { useMemo, Fragment, memo } from 'react';
import withToggle from 'hoc/withToggle';
import { Actions } from '../../common/columns/cells';
import TableList from 'components/table/list';
import { useSelector } from 'react-redux';
import { getColumnsVisibility, isColumnsStateSaved } from 'pages/Finances/selectors';
import columns from '../../common/columns/accountedColumns';
import useUpdateHeight from 'hooks/use-update-height';
import useContextSelector from 'hooks/context/useContextSelector';
import { ListContext } from 'pages/Finances/components/accounted/constants';
import { getEffectLoading, getTableListData } from 'common/selectors';
import useListData from "components/table/hooks/useListData"

const defaultSorted = [
  {
    id: 'postingDate',
    desc: true,
  },
];

const defaultFilterMethod = (filter, row) => {
  const id = filter.pivotId || filter.id;

  return row[id] !== undefined ? String(row[id]).includes(filter.value) : true;
};

const tableStyle = { height: '100%' };

const Table = withToggle({
  Cell: Actions,
})(TableList);

const AccountedList = ({ item }) => {
  const visibility = useSelector(getColumnsVisibility);
  const saved = useSelector(isColumnsStateSaved);
  const data = useListData();

  const loading = useSelector(getEffectLoading('accounted', 'get'));

  const height = useUpdateHeight('accounted-list', '90%');

  const { search, no, ozprNo } = useContextSelector(ListContext, state => state);

  const filtered = useMemo(
    () => [
      {
        id: 'orderNo',
        value: no,
      },
      {
        id: 'ozprNo',
        value: ozprNo,
      },
    ],
    [no, ozprNo],
  );

  return (
    <Fragment>
      {item && data.length && (
        <div className="order-item-info order-item-info--accounted">
          Товар <strong>{item}</strong> найден в документах:
        </div>
      )}
      <div className="order-list" id="accounted-list" style={{ height }}>
        <Table
          columns={columns}
          visibility={visibility}
          model="accountedColumns"
          saved={saved}
          noDataText={`${
            search ? 'С кодом товара ' + search : 'За выбранный период'
          } документы не найдены!`}
          style={tableStyle}
          defaultSorted={defaultSorted}
          defaultPageSize={5000}
          pageSize={5000}
          loading={loading}
          filtered={filtered}
          defaultFilterMethod={defaultFilterMethod}
          className="ReactTable--accounted"
        />
      </div>
    </Fragment>
  );
};

export default memo(AccountedList);
