import React from 'react';
import { Button } from 'common/ui/button';
import { Heading } from 'common/ui/heading';
import { Popup } from 'common/ui/popup';
import { usePopup } from 'hooks/new';
import { useTheme } from 'hooks/use-theme';
import { UNLOCKING_THEME, CACTUS_THEME } from 'common/themes';
import { UnlockingForm } from './unlocking-form';
import { isCactus } from 'common/config';

import styles from './unlocking.module.scss';

export const Unlocking = () => {
  useTheme(isCactus ? CACTUS_THEME : UNLOCKING_THEME);
  const [openPopup, closePopup, isPopupOpen] = usePopup();

  return (
    <>
      <Button className={styles['button']} onClick={openPopup}>
        запрос на разблокировку
      </Button>
      <Popup
        className={styles['popup']}
        classNameOverlay={styles['overlay']}
        body={<UnlockingForm onClose={closePopup} />}
        header={
          <Heading size="md" className={styles['header']}>
            Запрос на разблокировку
          </Heading>
        }
        visible={isPopupOpen}
        onClose={closePopup}
      />
    </>
  );
};
