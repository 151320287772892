import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Auto = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M2 4.5C1.72386 4.5 1.5 4.72386 1.5 5V13C1.5 13.2761 1.72386 13.5 2 13.5H16C16.2761 13.5 16.5 13.2761 16.5 13V5C16.5 4.72386 16.2761 4.5 16 4.5H2Z"
      stroke={color}
      strokeLinejoin="round"
    />
    <rect
      x="-0.5"
      y="0.5"
      width="17"
      height="2"
      transform="matrix(1 0 0 -1 1 16)"
      stroke={color}
      strokeLinejoin="round"
    />
    <rect
      x="-0.5"
      y="0.5"
      width="3"
      height="2"
      transform="matrix(1 0 0 -1 4 5)"
      stroke={color}
      strokeLinejoin="round"
    />
    <rect
      x="-0.5"
      y="0.5"
      width="3"
      height="2"
      transform="matrix(1 0 0 -1 12 5)"
      stroke={color}
      strokeLinejoin="round"
    />
    <path d="M12 7V11" stroke={color} strokeLinecap="round" />
    <path d="M8 9L4 9" stroke={color} strokeLinecap="round" />
    <path d="M14 9L10 9" stroke={color} strokeLinecap="round" />
  </svg>
);

Auto.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Auto.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
