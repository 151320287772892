import { NUBMER_TOP_ITEMS } from 'pages/Marketing/constants';
import { CACHE_NAME } from 'common/config';

export default {
  name: 'news',
  state: {
    news: [],
    ads: [],
    pageCnt: 0,
  },
  reducers: {
    load: (state, { data }) => {
      if (!data) {
        return state;
      }

      return {
        ...state,
        news: data?.news || state.news,
        ads: data?.ads || state.ads,
        pageCnt: data?.pages || state.pageCnt,
      };
    },
  },
  effects: ({ api }) => ({
    async get() {
      await api.get({
        url: 'news',
        query: `?only=1&top=${NUBMER_TOP_ITEMS}`,
        cache: CACHE_NAME,
      });
    },
    async getAds() {
      const payload = await api.get({
        url: 'ads',
        query: `?top=${NUBMER_TOP_ITEMS}`,
        cache: CACHE_NAME,
      });

      this.load(payload);
    },
    async getBanners() {
      const { data } = await api.get({ url: 'banners' });

      return data;
    },
  }),
};
