// @ts-nocheck
const MAX_DAYS = 3;
const DAYS_OF_WEEK = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

const createTitleDay = date => {
  const dayofWeek = date.getDay();
  const month = date.getMonth();
  const day = date.getDate();
  return `${day}.${month} ${DAYS_OF_WEEK[dayofWeek]}`;
};

export const createDaysForHint = () => {
  const today = new Date();
  const day = today.getDate();
  const days = [];
  let i = 1;

  while (i <= MAX_DAYS) {
    const date = new Date(today.setDate(day + i));
    days.push({
      value: date,
      title: createTitleDay(date),
    });
    i += 1;
  }

  return days;
};
