// @ts-nocheck
import sortDates from 'helpers/sortDates';
import getStatusConstructor from './get-color-status';
import formattingAmount from './formatting-amount';

const multiBrandsColumns = [
  {
    Header: 'Номер',
    accessor: 'no',
  },
  {
    Header: 'Дата обращения',
    accessor: 'createDate',
    sortMethod: sortDates,
  },
  {
    Header: 'Источник',
    accessor: 'projectCode',
  },
  {
    Header: 'Бренд',
    accessor: 'brand',
  },
  {
    Header: 'Конечный заказчик',
    accessor: 'customerName',
  },
  {
    Header: 'Направлено в отдел',
    accessor: 'projectDescription',
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: row => {
      if (!row) return null;

      const { value } = row;

      return getStatusConstructor(value);
    },
  },
  {
    Header: 'Сумма',
    accessor: 'amount',
    Cell: row => {
      if (!row) return null;

      const { value } = row;

      return formattingAmount(value);
    },
  },
  {
    Header: 'Дата обработки',
    accessor: 'closeDate',
    sortMethod: sortDates,
  },
];

export default multiBrandsColumns;
