import { useCallback } from 'react';
import { useSelector, useStore } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { OrderEditContext } from 'pages/Orders/components/order-edit/context';
import useContextSelector from 'hooks/context/useContextSelector';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { setPage } from '../../../../action/state';
import getFormValues from '../helpers/getFormValues';
import assignFormValues from '../helpers/assignFormValues';

export const useFilterFormChange = () => {
  const {
    select: { filters },
  } = useStore();
  const filterGroups = useSelector(filters.getCurrentGroups);
  const serverFiltration = useSelector(filters.isBackendFiltration);

  const page = useContextSelector(OrderEditContext, state => state.page);
  const text = useContextSelector(OrderEditContext, state => state.searchText);
  const skipFilterFlushRequest = useContextSelector(
    OrderEditContext,
    state => state.skipFilterFlushRequest,
  );

  const stateDispatch = useContextDispatch(OrderEditContext);

  return useCallback(
    debounce((formValues, { items, filters }) => {
      if (page) {
        stateDispatch(setPage(0));
      }

      if (!isEmpty(text) && serverFiltration && !skipFilterFlushRequest) {
        return items.handleSearch({ text, filters: getFormValues(formValues)(filterGroups) });
      }

      (formValues && filters.setActive(assignFormValues(formValues)(filterGroups))) ||
        filters.flush();
    }, 20),
    [filterGroups, page, text, skipFilterFlushRequest], // eslint-disable-line
  );
};
