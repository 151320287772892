import { undef, def } from '../util';
import { LINKED_FILE_TYPES, LINKED_MAX_FILE_SIZE } from '../config';

export default values => {
  const errors = {};
  const file = values.items;

  if (undef(file)) {
    errors.items = 'Выберите файл!';
  }

  if (def(file) && file.size > LINKED_MAX_FILE_SIZE) {
    errors.items = 'Размер принятого файла превысил максимально допустимый размер';
  }

  if (!LINKED_FILE_TYPES.includes(file?.type)) {
    errors.items = 'Недопустимый формат файла';
  }

  return errors;
};
