import { getUser } from 'pages/Orders/selectors';
import { formattingIdReturn } from 'pages/VAD/helper/util';
import alert from 'components/native/alert/function';

export const createComment =
  (dispatch, type) =>
  async ({ id, comment }, state) => {
    const { login } = getUser(state);

    try {
      const { success, message } = await dispatch.api.post({
        url: `${type}/add-comment/`,
        query: `?id=${encodeURIComponent(formattingIdReturn(id))}`,
        data: { comment },
      });

      if (!success) {
        alert(message);
        return false;
      }

      if (!dispatch[type]?.addComment) {
        console.error(`You must provide addComment method to ${type} model reducers!`);
        return false;
      }

      dispatch[type].addComment({ comment, login });

      return success;
    } catch (e) {
      alert(`Ошибка! Повторите действие!`);
    }
  };
