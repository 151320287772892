import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Clean = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M9.24539 5.19962C9.24539 4.14707 10.386 2.9846 12.0391 2.82805V1.17261H6.46157L5.28125 2.01199C6.27311 2.86469 6.72937 3.74737 6.72937 5.19962"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1731 2.66484L12.0391 2.82805V1.17261L13.1731 1.33249V2.66484Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57295 7H8.96797H6.32384L5.18149 10.4976C5.0605 10.8673 5 11.2496 5 11.6319V16.1754C5 16.6303 5.41637 17 5.92883 17H11.0712C11.5836 17 12 16.6303 12 16.1754V11.6635C12 11.3349 11.8078 11.0316 11.4947 10.8515C10.363 10.188 9.57295 8.71248 9.57295 7Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.45638 7.21254H9.48486V5.30728H6.45638V7.21254Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7C8 9.58189 8.1198 12.1726 9 15"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1729 3.22775C11.3415 4.15373 11.6126 4.94315 12.0127 5.93908C12.0689 6.07897 11.9003 6.18889 11.7879 6.08897C11.1729 5.54271 10.796 5.06639 10.4687 4.4302L9.42725 4.45018"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Clean.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Clean.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
