const colorStatusClaimQueue = result => {
  switch (result) {
    case 'Готово':
      return 'status--green';
    default:
      return 'status--red';
  }
};

export default colorStatusClaimQueue;
