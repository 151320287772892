import history from "common/history"

export function getDataByPath(state){
  const dataKey = history.location.pathname.substring(1)
  const data = state.data[dataKey]

  return Array.isArray(data) ? data : []
}

export function setDataByPath(state, data){
  const dataKey = history.location.pathname.substring(1)

  state.data[dataKey] = data
}
