import React from 'react';
import { BaseLayout } from 'common/layout/base-layout';
import { useDesign } from 'hooks/new';
import DefaultLayout from './layout/layout';

const NoMatch = ({ location, initiator }) => {
  const [_, isShpd] = useDesign();

  const Layout = isShpd ? BaseLayout : DefaultLayout;

  return initiator && initiator === 'api' ? (
    <Layout>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </Layout>
  ) : (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
export default NoMatch;
