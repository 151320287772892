import alert from 'components/native/alert/function';

const CONFIRMED_MESSAGE =
  'Для продолжения работы подтвердите ваши личные данные. <br /> Необходимо заполнить обязательные поля и дать согласие на обработку персональных данных."';

export const APPROVAL_MESSAGE =
    `<h6>УВАЖАЕМЫЕ ПАРТНЕРЫ !</h6>
    <p>В связи с изменениями политики обработки и хранения персональных данных,
    просим вас подтвердить согласие на обработку персональных данных.</p>`;

export const handlePropmt = (confirmed, approved) => (_, action) => {
  if (!confirmed) {
    return CONFIRMED_MESSAGE;
  }

  if (!approved && action === 'PUSH') {
    alert(APPROVAL_MESSAGE);
    return false;
  }

  return true;
};
