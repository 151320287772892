import React from 'react';
import { connect } from 'react-redux';
import { formatter, hasAccess, quatroFormatter } from 'common/util';
import { getUser } from 'pages/Orders/selectors';
import get from 'lodash/get';
import useRouteConfig from 'hooks/use-route-config';

const UserBalance = props => {
  const route = useRouteConfig();
  const showBalance = route.showBalance || route.parent?.showBalance;
  if (!showBalance) {
    return null;
  }

  const { balance, currency, user } = props;
  const { Date, Rate, Code } = currency || {};

  const excludeSoft = get(user, 'Exclude Soft', false);
  const showExcludeSoft = Boolean(Number(excludeSoft));

  const digitFormatter = formatter;

  return (
    (hasAccess(user, 'FIN_FU') && (
      <div>
        <div className="user-balance row">
          <div className="col-sm-3">
            Баланс БУ: <span>{digitFormatter.format(parseFloat(balance['Balance_Hard']))}</span>
          </div>
          <div className="col-sm-3">
            Кр.лимит: <span>{digitFormatter.format(parseFloat(balance['Max_Kredit']))}</span>
          </div>
          <div className="col-sm-3">
            Оборот: <span>{digitFormatter.format(parseFloat(balance['Oborot_USD']))}</span>
          </div>
          {Date && (
            <div className="col-sm-3">
              <b>
                Курс ЦБ {Date} {Code}: <span>{quatroFormatter.format(parseFloat(Rate))}</span>
              </b>
            </div>
          )}
          {!showExcludeSoft && (
            <div className="col-sm-3">
              Баланс ПУ: <span>{digitFormatter.format(parseFloat(balance['Balance_Soft']))}</span>
            </div>
          )}
          <div className="col-sm-3">
            В заказах: <span>{digitFormatter.format(parseFloat(balance['Summa_In_Orders']))}</span>
          </div>
        </div>
      </div>
    )) ||
    null
  );
};

const mapStateToProps = state => ({
  balance: state.user.balance,
  user: getUser(state),
  currency: state.user.currency,
});

export default connect(mapStateToProps)(UserBalance);
