import React, { useCallback, useEffect, useState } from 'react';
import Steps from './Steps';
import FormStepContainer from './FormStepContainer';
import { Field, FormSection, reduxForm, SubmissionError } from 'redux-form';
import { LENDING_FORM, requiredValidate } from './constants';
import { gt } from 'lodash';
import Content from 'components/layout/content';
import { useDispatch } from 'react-redux';
import { RenderSelect } from 'components/fields';
import DocumentsSection from './components/DocumentsSection';
import OfpField from './FormFields/OfpField';
import alert from 'components/native/alert/function';
import ReactHtmlParser from 'react-html-parser';

function LendingForm(props) {
  const { handleSubmit, pristine, submitting, error } = props;

  const [docList, setDocList] = useState([]);
  const [step, setStep] = useState(1);

  const {
    finances: { getContractors, getCreditDocList },
  } = useDispatch();

  useEffect(() => {
    pristine && setStep(1);
  }, [pristine]);

  const getDocuments = useCallback(value => {
    if (!value) {
      return;
    }

    const { orgLegalForm } = value;

    getCreditDocList(orgLegalForm || value).then(res => {
      setDocList(res);

      if (res.length) {
        setStep(step => step + 1);
      }
    });
  }, []);

  return (
    <Content
      title="Запрос кредитования"
      className="lending-form"
      titleClassName="lending-form__title"
    >
      <div className="lending-form__block-container">
        <Steps stepsNumber={2} currentStep={step} stepDescription="Загрузка документов" />
      </div>
      <div className="lending-form__block-container">
        <form onSubmit={handleSubmit} className="lending-form__form">
          <FormStepContainer
            step={1}
            currentStep={step}
            title="Выберите Контрагента и заполните поле ОПФ"
            useDivider={gt(step, 1)}
            styles={{ paddingBottom: '20px' }}
          >
            <FormSection name="ofpPartner" className="lending-form__form-section">
              <Field
                component={RenderSelect}
                async
                loadOptions={getContractors}
                validate={[requiredValidate]}
                name="contractor"
                label="Контрагент"
                valueKey="code"
                labelKey="name"
                placeholder="Выберите контрагента"
                onChange={getDocuments}
              />
              <OfpField onChange={getDocuments} />
            </FormSection>
          </FormStepContainer>
          {docList.length > 0 && (
            <FormStepContainer
              step={2}
              currentStep={step}
              title="Загрузите требуемые документы"
              className="lending-form__form-section"
            >
              <DocumentsSection list={docList} />
              <button className="lending-form__button" disabled={submitting}>
                ОТПРАВИТЬ ЗАПРОС
              </button>
              {error && <span className="text-danger text-danger__form-errors">{error}</span>}
            </FormStepContainer>
          )}
          {docList.length === 0 && step === 2 && (
            <h3 className="no-access">Нет документов для загрузки</h3>
          )}
        </form>
      </div>
    </Content>
  );
}

export default reduxForm({
  form: LENDING_FORM,
  touchOnChange: true,
  async onSubmit(values, { finances }, { reset }) {
    const { success, message } = await finances.onSubmit(values);

    if (success) {
      alert(message);
      reset();
    } else {
      throw new SubmissionError({
        _error: ReactHtmlParser(message),
      });
    }
  },
})(LendingForm);
