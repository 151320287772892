import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import MaskedTextInput from 'react-text-mask';
import classnames from 'classnames';
import { EditableSelect } from 'components/fields';
import { change, clearFields } from 'redux-form';
import { useDispatch } from 'react-redux';

export const RenderCustomerName = props => {
  const {
    form,
    name,
    input: { value, onChange },
    required,
    label,
    meta: { touched, error },
  } = props;

  const dispatch = useDispatch();
  const {
    demo: { getAgents },
  } = dispatch;

  const debounceFetch = debounce((value, callback) => {
    getAgents(value)
      .then(({ hits = [] }) =>
        callback(null, {
          options: [...hits, { fullname: value, inn: '' }],
        }),
      )
      .catch(err => callback(err, null));
  }, 1100);

  const handleChange = valueSelect => {
    onChange(valueSelect);
    dispatch(change(form, 'inn', valueSelect?.inn || null));
  };

  const handleInputChange = useCallback(
    value => !value && dispatch(clearFields(form, false, false, ['inn'])),
    [],
  );

  const loadOptions = (inputValue, callback) => {
    if (!inputValue) {
      return Promise.resolve({
        options: [],
      });
    }

    if (inputValue.trim() && !/A-Za-z0-9/.test(inputValue)) {
      debounceFetch(inputValue, callback);
    }
  };

  return (
    <div className="col-sm-6">
      {label && <label className={required ? 'required' : ''}>{label}</label>}
      <div>
        <EditableSelect
          name={name}
          async
          value={value}
          inputValue={value?.label || value?.fullname || ''}
          placeholder="Начните ввод"
          searchPromptText="Начните ввод для получения списка заказчиков"
          noResultsText="Заказчик не найден"
          loadingPlaceholder="Поиск заказчика"
          loadOptions={loadOptions}
          onChange={handleChange}
          onInputChange={handleInputChange}
          valueKey="na_no"
          labelKey="fullname"
          inputKey="fullname"
          onSelectResetsInput={false}
          onBlurResetsInput
          onCloseResetsInput={false}
        />
        {touched && error && <span className="text-danger text-danger--small">{error}</span>}
      </div>
    </div>
  );
};

export const RenderINN = ({
  input: { value, onChange },
  label,
  name,
  required,
  disabled,
  readonly,
  form,
  meta: { touched, error },
}) => {
  const dispatch = useDispatch();
  const {
    demo: { getInn },
  } = dispatch;

  const debounceChange = debounce(value => {
    getInn(value.replace(/_/g, '')).then(({ na_no: value, fullname: label }) => {
      if (value && label) {
        dispatch(change(form, 'customerName', { value, label }));
      }
    });
  }, 1000);

  const handleChange = ({ target: { value } }) => {
    onChange(value);
    debounceChange(value);
  };

  const clearValue = () => {
    dispatch(clearFields(form, false, false, 'customerName', 'inn'));
  };

  return (
    <div className="col-sm-6">
      <label className={required ? 'required' : ''}>{label}</label>
      <div className="inn-input">
        <MaskedTextInput
          name={name}
          value={value}
          type="text"
          className="form-control"
          disabled={!!disabled}
          readOnly={!!readonly}
          onChange={handleChange}
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
          placeholder="Введите ИНН 10 цифр"
        />
        <span
          className={classnames('inn-input__clear', {
            'inn-input__clear--show': !!value,
          })}
          onClick={clearValue}
        >
          ×
        </span>

        {touched && error && <span className="text-danger text-danger--small">{error}</span>}
      </div>
    </div>
  );
};

export const textareaField = ({ input, meta }) => {
  const { error, touched } = meta;
  return (
    <>
      <textarea {...input} />
      {touched && error && <div className="text-danger">{error}</div>}
    </>
  );
};
