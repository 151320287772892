import React, { Fragment } from 'react';
import classNames from 'classnames';
import createCategoryIconClassName from './createCategoryIconClassName';
import { getCategoryNameFromData } from 'features/catalog/utils';

export const createCategoryChildren = cat => isHovered => {
  const categoryIconClassName = createCategoryIconClassName(cat);

  return (
    <>
      <span className="catalog__sidebar__category__icon">
        <i
          className={classNames('icon', {
            [categoryIconClassName]: !isHovered,
            [`${categoryIconClassName}-red`]: isHovered,
          })}
        />
      </span>
      <span className="catalog__sidebar__category__text">{getCategoryNameFromData(cat.name)}</span>
      <i className={classNames('catalog__sidebar__category__arrow-right', 'icon', 'icon-ctlg')} />
    </>
  );
};
