import get from 'lodash/get';
import sortByDates from 'helpers/sortDates';
import sortByPeriods from 'helpers/sort-periods';
import { FileCell } from './cells';

const purchaseColumns = [
  {
    Header: 'За период',
    accessor: 'period',
    id: 'period',
    sortMethod: sortByPeriods,
  },
  {
    Header: 'Создан',
    accessor: 'operationNo',
    id: 'operationNo',
    sortMethod: sortByDates,
    Cell: row => get(row, ['original', 'createdDate'], null),
  },
  {
    Header: 'Файл',
    accessor: 'status',
    id: 'status',
    width: 140,
    Cell: FileCell,
  },
];

export default purchaseColumns;
