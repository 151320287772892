// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import { DEFAULT_PAGE_SIZE } from 'common/config';
import { getListData } from 'common/selectors';
import withApi from 'hoc/withApi';
import columns from './helpers/multi-brand-columns';

const defaultSorted = [
  {
    id: 'createDate',
    desc: true,
  },
  {
    id: 'no',
    desc: true,
  },
];

const MultiBrandList = () => {
  const list = useSelector(getListData);

  return (
    <Content title="Список заявок кофигураторов">
      <div className="actions-container actions-container--order align-items-center">
        <Link to="/orders/config" className="section-nav">
          <i className="icon icon-back-icon" />
          Конфигураторы
        </Link>
      </div>
      <div className="logistic-list">
        <TableList
          columns={columns}
          defaultSorted={defaultSorted}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          showPagination={!!list.length}
        />
      </div>
    </Content>
  );
};

export default withApi(MultiBrandList);
