import React, { useState } from 'react';
import chunk from 'lodash/chunk';
import isEqual from 'lodash/isEqual';
import isEmpty from "lodash/isEmpty"
import Content from 'components/layout/content';
import Analogs from './components/analogs';
import Properties from './components/properties';
import Photos from './components/photos';
import InfoHead from './components/info-head';
import InfoAvailability from './components/info-availability';
import Related from './components/related';
import InfoRequest from './components/info-request';
import OrderHeader from 'pages/Orders/containers/order-header';
import Breadcrumbs from './components/breadcrumbs';
import { useParams } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import { isDevEnv } from 'common/config';
import { useDispatch, useSelector, useStore } from 'react-redux';
import withApi from 'hoc/withApi';
import useCurrentOrder from 'pages/Orders/hooks/useCurrentOrder';
import useDeepEffect from "hooks/use-deep-effect"
import {usePrev} from "hooks"

const ProductView = () => {
  const { select } = useStore();
  const { id } = useParams();

  const {
    product: {
      getAnalogs,
      getAvailability,
      getImages,
      getRelated,
      getProperties,
      setAvailability,
      setRelated,
    },
  } = useDispatch();

  const order = useCurrentOrder();
  const { Code = 'USD' } = useSelector(select.user.getCurrencyCode);
  const productHead = useSelector(select.product.getHead);
  const availability = useSelector(state => state.product.availability);
  const related = useSelector(state => state.product.related);

  const [properties, setProperties] = useState({});
  const [images, setImages] = useState([]);
  const [analogs, setAnalogs] = useState([]);

  const prevOrder = usePrev(order);

  const relatedSlides = chunk(related, 5);
  const analogSlides = chunk(analogs, 4);

  useDeepEffect(() => {
    !isDevEnv && ym('reachGoal', 'ITEM_CARD');
    // eslint-disable-next-line no-console
    const showError = console.log;

    getAvailability(id).then(setAvailability).catch(showError);

    if (isEqual(prevOrder, order)) {
      getImages(id).then(setImages).catch(showError);
      getProperties(id).then(setProperties).catch(showError);
      getRelated(id).then(setRelated).catch(showError);
      getAnalogs(id).then(setAnalogs).catch(showError);
    }
  }, [id, order]); // eslint-disable-line

  return (
    <Content>
      <div className="order-edit">
        <OrderHeader />
      </div>
      <Breadcrumbs />
      <div className="product-view main-block">
        <div className="row">
          <div className="col-md-12 col-lg-7">
            <Photos images={images} />
          </div>
          <div className="col-md-12 col-lg-5">
            <div className="info">
              <InfoHead productHead={productHead} />
              <hr className="view__line info__line" />
              <InfoAvailability productAvailability={availability} order={order} currency={Code} />
              <InfoRequest
                product={availability}
                code={productHead?.code1}
                specificOfItem={productHead?.specificOfItem}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="view__line" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-7">
            <Properties properties={Object.values(properties)} />
          </div>
          <div className="col-md-12 col-lg-5">
            <Analogs analogSlides={analogSlides} order={order} currency={Code} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Related relatedSlides={relatedSlides} order={order} currency={Code} />
          </div>
        </div>
      </div>
    </Content>
  );
};

export default withApi(ProductView);
