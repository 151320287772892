import React from 'react';
import { useDispatch } from 'react-redux';

const DocumentReady = ({ doc }) => {
  const {
    queue: { getFile },
  } = useDispatch();

  return (
    <a
      key={doc.opNo}
      className="btn-download-blocked"
      href="#docDownload"
      onClick={async () => await getFile(doc.opNo)}
    >
      <i className="btn-download-blocked__pdf" />
      <p className="btn-download-blocked__body">
        <span className="btn-download-blocked__title">{doc.docName}</span>
        <span className="btn-download-blocked__date">
          {doc.date} {doc.time}
        </span>
      </p>
    </a>
  );
};

export default DocumentReady;
