import { API_URL } from 'common/config/app';

export const searchHintsApi = {
  'new-search-suggestions$': {
    endpoint: `${API_URL}/v1/sphinx/search/all-stub-suggestions`,
  },
  'new-search-main/?([A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/sphinx/search/id`,
  },
  'new-search-category/([А-ЯA-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/group`,
  },
};
