import get from 'lodash/get';

const editStatus = (orderNo, status) => item =>
  item.orderNo === orderNo ? { ...item, status } : item;

export const updateDownload = (list, action) => {
  const orderNo = get(action, ['payload', 'data', 'id'], 0);
  const command = get(
    action,
    ['payload', 'data', 'data', 'data', '0', 'command'],
    '',
  ).toLowerCase();
  const status = get(action, ['payload', 'data', 'data', 'data', '0', 'status'], '').toLowerCase();

  switch (command) {
    case 'download':
      return list.map(editStatus(orderNo, status));

    default:
      return list;
  }
};
