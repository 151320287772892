import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Xls = ({
  className,
  color = '#236192',
  name,
  width = '25',
  height = '33',
  viewBox = '0 0 25 33',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M1 1H15L24.0263 10L24 32H1V1Z" stroke={color} strokeLinejoin="round" />
      <path d="M15 10H24L15 1V10Z" stroke={color} strokeLinejoin="round" />
      <path
        d="M5.796 24.272L3.24 20.144H4.44L6.144 22.988L6.444 23.672L6.732 22.988L8.532 20.144H9.636L6.996 24.188L9.756 28.544H8.592L6.696 25.532L6.372 24.812L6.06 25.532L4.116 28.544H3L5.796 24.272Z"
        fill={color}
      />
      <path d="M15.3645 28.544H10.3965V20.144H11.3925V27.656H15.3645V28.544Z" fill={color} />
      <path
        d="M20.6565 26.408C20.6565 26.072 20.5565 25.8 20.3565 25.592C20.1565 25.376 19.9045 25.188 19.6005 25.028C19.3045 24.86 18.9805 24.704 18.6285 24.56C18.2765 24.416 17.9485 24.244 17.6445 24.044C17.3485 23.836 17.1005 23.584 16.9005 23.288C16.7005 22.984 16.6005 22.6 16.6005 22.136C16.6005 21.48 16.8285 20.96 17.2845 20.576C17.7405 20.192 18.3885 20 19.2285 20C19.7165 20 20.1605 20.036 20.5605 20.108C20.9605 20.18 21.2725 20.272 21.4965 20.384L21.1965 21.26C21.0125 21.172 20.7405 21.088 20.3805 21.008C20.0285 20.928 19.6285 20.888 19.1805 20.888C18.6525 20.888 18.2565 21.008 17.9925 21.248C17.7285 21.48 17.5965 21.756 17.5965 22.076C17.5965 22.388 17.6965 22.648 17.8965 22.856C18.0965 23.064 18.3445 23.252 18.6405 23.42C18.9445 23.58 19.2725 23.74 19.6245 23.9C19.9765 24.06 20.3005 24.248 20.5965 24.464C20.9005 24.672 21.1525 24.924 21.3525 25.22C21.5525 25.516 21.6525 25.884 21.6525 26.324C21.6525 26.684 21.5885 27.008 21.4605 27.296C21.3325 27.584 21.1445 27.832 20.8965 28.04C20.6485 28.248 20.3485 28.408 19.9965 28.52C19.6445 28.632 19.2445 28.688 18.7965 28.688C18.1965 28.688 17.7005 28.64 17.3085 28.544C16.9165 28.456 16.6125 28.356 16.3965 28.244L16.7325 27.344C16.9165 27.448 17.1885 27.552 17.5485 27.656C17.9085 27.752 18.3125 27.8 18.7605 27.8C19.0245 27.8 19.2725 27.776 19.5045 27.728C19.7365 27.672 19.9365 27.588 20.1045 27.476C20.2725 27.356 20.4045 27.208 20.5005 27.032C20.6045 26.856 20.6565 26.648 20.6565 26.408Z"
        fill={color}
      />
    </svg>
  );
};

Xls.defaultProps = {
  className: '',
  color: '#236192',
  width: '25',
  height: '33',
  viewBox: '0 0 25 33',
};

Xls.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
