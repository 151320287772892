import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import transform from 'lodash/transform';

export const getUserWidths =
  (slice, createSelector) =>
  ({ user }) =>
    createSelector(
      user.getInfo,
      slice(state => state.userWidths),
      ({ id }, widths) => widths[id] || {},
    );

export const getUserVisibility =
  (slice, createSelector) =>
  ({ user }) =>
    createSelector(
      user.getInfo,
      slice(state => state.userVisibility),
      ({ id }, visibility) => visibility[id] || {},
    );

export const getUserVisibilityValues =
  (slice, createSelector) =>
  ({ itemsColumns }) =>
    createSelector(
      itemsColumns.getUserVisibility,
      slice(state => state.values),
      (visibility, defaultValues) => visibility.values || defaultValues,
    );

export const getIsSimplified =
  (slice, createSelector) =>
  ({ itemsColumns }) =>
    createSelector(
      itemsColumns.getUserVisibility,
      slice(state => state.simplified),
      (visibility, simplified) => visibility.simplified || simplified,
    );

export const getDefaultVisibility =
  (slice, createSelector) =>
  ({ itemsColumns }) =>
    createSelector(
      slice(state => state.values),
      itemsColumns.getIsSimplified,
      (defaultValues, simplified) => ({
        ...defaultValues,
        id: true,
        vendorId: !simplified,
        image: simplified,
        orderDate: false,
        brand: !simplified,
        vendorPart: true,
        inventoryMSK1: true,
        availableClient: true,
        warehouseOfficeRG: true,
        availableMSK1: true,
        name: true,
        onlineReserve: false,
        inReserveHours: false,
        qty: true,
        desiredQty: true,
        selfReserve: false,
        price: true,
        desiredPrice: true,
        desiredPriceClient: false,
        priceLcyMSK: true,
        priceLcyRG: true,
        priceLCY: true,
        priceClient: true,
        currencyCode: false,
        minPackaged: false,
        salesLimitType: false,
        weight: false,
        volume: false,
        warranty: false,
        availableExpected: false,
        availableExpectedNext: false,
        TCComment: false,
        group1: false,
        group2: false,
        group3: false,
        RRP: false,
        RRPDate: false,
        deliveryDate: false,
        actualyDateFrom: false,
        actualyDateTo: false,
        'Код Валюты': true,
        statuses: true,
        barcodeEAN: false,
      }),
    );

export const getHiddenColumns =
  (slice, createSelector) =>
  ({ itemsColumns }) =>
    createSelector(
      itemsColumns.getUserVisibilityValues,
      itemsColumns.getDefaultVisibility,
      itemsColumns.getIsSimplified,
      (visibility, defaultVisibility, simplified) => {
        if (simplified) {
          const defaultColumns = Object.keys(defaultVisibility);

          return without(
            defaultColumns,
            'image',
            'name',
            'vendorPart',
            'priceLcyMSK',
            'priceLcyRG',
          );
        }

        return transform(
          Object.keys(visibility),
          (acc, columnId) => {
            if (!visibility[columnId] || columnId === 'image') {
              acc.push(columnId);
            }
          },
          [],
        );
      },
    );

export const getOrderListView =
  (slice, createSelector) =>
  ({ ordersColumns }) =>
    createSelector(
      ordersColumns.getUserVisibility,
      slice(state => state.view),
      (visibility, view) => visibility.view || view,
    );

export const getOrdersDefaultVisibilityValues = (slice, createSelector) => () =>
  createSelector(
    slice(state => state.values),
    values => values,
  );

export const getUserOrdersVisibilityValues =
  (_, createSelector) =>
  ({ ordersColumns }) =>
    createSelector(
      ordersColumns.getUserVisibility,
      ordersColumns.getOrdersDefaultVisibilityValues,
      (visibility, defaultValues) => {
        if (isEmpty(visibility)) {
          return defaultValues;
        }

        return visibility;
      },
    );

export const getOrdersHiddenColumns =
  (_, createSelector) =>
  ({ ordersColumns }) =>
    createSelector(ordersColumns.getUserOrdersVisibilityValues, values =>
      Object.keys(values).reduce((acc, columnId) => {
        if (!values[columnId]) {
          return [...acc, columnId];
        }

        return acc;
      }, []),
    );
