import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual'
import {
  FILTER_BY_OTHER,
  FILTER_BY_PROPERTIES,
  FILTER_BY_TEXT_INPUT,
  FILTER_BY_TILDA_VALUES,
  FILTER_TILDA_PROPERTIES,
} from '../constants';
import getCheckedValues from './serverData/getCheckedValues';

const assignFormValues = formGroups => filterGroups => {
  let checkedProps = {};

  const filters = filterGroups.reduce((filters, filterGroup) => {
    const formGroupValues = formGroups[`group-${filterGroup.key}`];

    if (isEmpty(formGroupValues)) {
      return filters;
    }

    switch (filterGroup.type) {
      case FILTER_BY_PROPERTIES: {
        const values = getCheckedValues(formGroupValues);

        return [
          ...filters,
          {
            id: filterGroup.key,
            value: values,
            check: row => values.includes(row[filterGroup.key]),
          },
        ];
      }
      case FILTER_BY_TILDA_VALUES: {
        checkedProps[filterGroup.key] = getCheckedValues(formGroupValues)

        return filters;
      }
      case FILTER_BY_TEXT_INPUT: {
        const value = formGroupValues[filterGroup.key];

        return [
          ...filters,
          {
            id: filterGroup.key,
            value,
            check: row => {
              if (!value.length || !row.name) {
                return true;
              }

              return value
                .split('*')
                .every(value => row.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            },
          },
        ];
      }
      case FILTER_BY_OTHER: {
        const value = formGroupValues[filterGroup.key];

        return [
          ...filters,
          {
            id: filterGroup.key,
            value,
            check: ({ _original: row }) => {
              switch (value) {
                case 'lineNo':
                  return parseInt(row.lineNo);
                case 'desiredQtyGreaterQty':
                  return parseInt(row.desiredQty) > parseInt(row.qty);
              }
            },
          },
        ];
      }
    }
  }, []);


  if (Object.keys(checkedProps).length) {
    filters.push({
      id: FILTER_TILDA_PROPERTIES,
      value: Object.values(checkedProps).flat(),
      check: row => {
        let equality = [true]

        for(let groupKey in checkedProps){
          if(checkedProps[groupKey].length){
            equality.push(checkedProps[groupKey].some(prop => row[FILTER_TILDA_PROPERTIES]?.includes(prop)))
          }
        }

        return equality.reduce((a, b) => a && b)
      },
    });
  }

  return filters.filter(filter => !!filter.value.length);
};

export default assignFormValues;
