import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ProgressBar = ({ val, max, className, style = {} }, ref) => {
  const width = (val / max) * 100;

  return (
    <div className={classNames('progress', className)}>
      <div className="stripes" />
      <div
        ref={ref}
        className="progress-bar"
        role="progressbar"
        aria-valuenow={val}
        aria-valuemin="0"
        aria-valuemax={max}
        style={{ width: width + '%', ...style }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  val: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default forwardRef(ProgressBar);
