const getDescription = mode => {
  const TEST_MODE = 0;
  const BUY_MODE = 1;

  switch (mode) {
    case TEST_MODE:
      return `<p>
      Уважаемые партнеры! </br></br>
      С целью увеличения эффективности совместного бизнеса, Мерлион упрощает доступ к демофонду компании.
      Оборудование предоставляется под конкретные проекты у ваших конечных заказчиков с указанием возможных коммерческих перспектив по результатам тестирования.
      </p>
      <p>
        Все демо-оборудование подлежит возврату, возможность предоставления определяется индивидуально по каждой заявке.
      </p>
      <p>Логистика: самовывоз</p>
      <span class="offer">Не является офертой</span>`;
    case BUY_MODE:
      return `<p>
        Уважаемые партнёры, предлагаемое к покупке оборудование демо-фонда ранее было
        использовано для проектов тестирования, имеет различную степень износа.
        Перед приобретением есть возможность получить отчёт о состоянии конкретного демо-образца.
      </p>
      <p>Логистика: самовывоз</p>
      <span class="offer">Не является офертой</span>`;
    default:
      return '';
  }
};

const dashboardList = mode => [
  {
    id: 1,
    title: '',
    src: 'demofond_dashboard/2.jpg',
    position: 'large',
    description: getDescription(mode),
  },
  {
    id: 2,
    title: 'Точка доступа',
    src: 'demofond_dashboard/4.png',
    position: 'min',
    description: '',
  },
  {
    id: 3,
    title: 'Серверы',
    src: 'demofond_dashboard/6.png',
    position: '',
    description: '',
  },
  {
    id: 4,
    title: 'Системы хранения данных',
    src: 'demofond_dashboard/7.png',
    position: '',
    description: '',
  },
  {
    id: 5,
    title: 'Коммутаторы',
    src: 'demofond_dashboard/1.png',
    position: '',
    description: '',
  },
  {
    id: 6,
    title: 'Межсетевые экраны',
    src: 'demofond_dashboard/3.png',
    position: '',
    description: '',
  },
  {
    id: 7,
    title: '',
    src: 'demofond_dashboard/5.jpg',
    position: 'vertical',
    description: '',
  },
];
export default dashboardList;
