const queueItemColumns = [
  {
    Header: 'Дата',
    accessor: 'date',
    width: 110,
  },
  {
    Header: 'Команда',
    accessor: 'command',
    width: 70,
  },
  {
    Header: 'Состояние',
    accessor: 'result',
    id: 'result',
  },
  {
    Header: 'Код Товара',
    accessor: 'id',
  },
  {
    Header: 'Кол.',
    accessor: 'qty',
  },
  {
    Header: 'Цена',
    accessor: 'price',
  },
  {
    Header: 'ЖКол.',
    accessor: 'desiredQty',
  },
  {
    Header: 'ЖЦена',
    accessor: 'desiredPrice',
  },
  {
    Header: 'Результат',
    accessor: 'status',
    id: 'status',
    width: 80,
  },
  {
    Header: 'Ошибка',
    accessor: 'error',
    id: 'error',
  },
  {
    Header: 'Комментарий',
    accessor: 'comment',
  },
];

export default queueItemColumns;
