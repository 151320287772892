export const RULES_FOR_ACTIONS = {
  'integrate-order': {
    statuses: ['Резерв'],
  },
  signature: {
    statuses: ['Резерв'],
  },
  unsignature: {
    statuses: ['На подтверждении'],
  },
  edit: {
    statuses: ['Резерв', 'ОЗПр'],
  },
  'balance-sheet': {
    statuses: [
      'Выдан',
      'Выдаётся',
      'Готов к выдаче',
      'Доставлен',
      'Комплектуется',
      'Ожидает оплаты',
      'Передан в доставку',
      'Резерв',
      'На подтверждении',
    ],
  },
  invoice: {
    statuses: [
      'Выдан',
      'Выдаётся',
      'Готов к выдаче',
      'Доставлен',
      'Комплектуется',
      'Ожидает оплаты',
      'Передан в доставку',
    ],
  },
  'transer-item': {
    statuses: ['Резерв'],
  },
  'commercial-offer': {
    statuses: ['ОЗПр'],
  },
  transit: {
    statuses: ['ОЗПр'],
  },
  transfer: {
    statuses: ['Резерв'],
  },
  excel: {
    statuses: [
      'ОЗПр',
      'Выдан',
      'Выдаётся',
      'Готов к выдаче',
      'Доставлен',
      'Комплектуется',
      'Ожидает оплаты',
      'Передан в доставку',
      'Резерв',
      'На подтверждении',
    ],
  },
  shipment: {
    statuses: [
      'Комплектуется',
      'Ожидает оплаты',
      'Готов к выдаче',
      'Выдаётся',
      'Передан в доставку',
    ],
  },
  removed: {
    statuses: ['Резерв', 'ОЗПр'],
  },
  'product-from-excel': {
    statuses: ['ОЗПр', 'Резерв'],
  },
};
