import { FILTER_BY_PROPERTIES, FILTER_BY_TILDA_VALUES } from '../../constants';
import createFieldName from '../createFieldName';
import transform from 'lodash/transform'

export default (items, names) => filterGroups => {
  if (!items.length) {
    return [];
  }

  return transform(filterGroups, (groups, group) => {
    if (group.type === FILTER_BY_TILDA_VALUES) {
      const { filters } = group;
      const filterListData = items.reduce(
        (filters, item) =>
          'propFiltersValues' in item ? [...filters, ...item.propFiltersValues] : filters,
        [],
      );

      const newFilters = new Set();

      for (let i = 0; i < filters.length; i++) {
        if (filterListData.includes(filters[i].key)) {
          newFilters.add(filters[i]);
        } else {
          names.add(createFieldName(group.key, filters[i].key));
        }
      }

      return groups.push({ ...group, filters: [...filters] });
    }
    if (group.type === FILTER_BY_PROPERTIES) {
      const { filters } = group;
      const filterListData = items.map(item => item[group.key]);

      const newFilters = new Set();

      for (let i = 0; i < filters.length; i++) {
        if (filterListData.includes(filters[i].key)) {
          newFilters.add(filters[i]);
        } else {
          names.add(createFieldName(group.key, filters[i].key));
        }
      }

      return groups.push({ ...group, filters: [...newFilters] });
    }

    groups.push(group);
  }, []);
};
