import React from 'react';
import classnames from 'classnames';
import colorStatusClaimQueue from '../helpers/color-status-claim-queue';
import colorResultClaimQueue from '../helpers/color-result-claim-queue';

const ClaimInfo = ({ info }) => (
  <div className="info-item__container">
    <div className="info-item">
      <label className="info-item__label">Дата:</label>
      <span className="info-item__text">{info['Date']}</span>
    </div>
    <div className="info-item">
      <label className="info-item__label">Команда:</label>
      <span className="info-item__text">{info['Command Name']}</span>
    </div>
    <div className="info-item">
      <label className="info-item__label">Состояние:</label>
      <span className={classnames('info-item__text', colorStatusClaimQueue(info['Status']))}>
        {info['Status']}
      </span>
    </div>
    <div className="info-item">
      <label className="info-item__label">Документ:</label>
      <span className="info-item__text">{info['Document No_']}</span>
    </div>
    <div className="info-item">
      <label className="info-item__label">Результат:</label>
      <span
        className={classnames('info-item__text', colorResultClaimQueue(info['Processing Result']))}
      >
        {info['Processing Result']}
      </span>
    </div>
  </div>
);

export default ClaimInfo;
