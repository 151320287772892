import React from 'react';
import parse from 'react-html-parser';

const Comment = ({ comment = {} }) => {
  const { author, createDate, createTime, comment: currentComment } = comment;

  return (
    <div className="item-comment">
      <div className="title">
        <i className="icon comment" />
        {author} | {createDate} {createTime}
      </div>
      <div className="body">
        <div className="text">{parse(currentComment)}</div>
      </div>
    </div>
  );
};

export default Comment;
