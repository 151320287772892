/* eslint-disable react/no-deprecated, react/no-children-prop */
import React, { Component } from 'react'
import {connect} from 'react-redux'
import Content from '../layout/content'
import Children from './childrens'
import Breadcrumb from './breadcrumb'
import Article from './article'
import {isShallowEqual} from 'common/util'
import withApi from '../../hoc/withApi'
import Mixpanel from 'services/mixpanel'

class Category extends Component{
    constructor(){
        super()
    }

    state = {
        shownArticle: null
    }

    trackArticle = () => {
        const {category} = this.props

        Mixpanel.track(`Переход на страницу ${category.Name}`, {
            to: category.Name
        })
    }

    componentDidMount() {
        this.trackArticle()
    }

    componentDidUpdate() {
        this.trackArticle()
    }

    handleArticle = index => {
        this.setState({
            shownArticle: index
        })
    }

    shouldComponentUpdate(nextProps, nextState){ // TODO deprecated
        return !isShallowEqual(this.props.category, nextProps.category) || !isShallowEqual(this.state, nextState)
    }

    componentWillReceiveProps(){
        this.setState({shownArticle: null})
    }

    render(){
        const {category, children, articles} = this.props

        const childs = articles.length > 1
            ? <Children childs={articles} isArticles={true} onClick={this.handleArticle} artIndex={this.state.shownArticle}/>
            : <Children childs={children[category.ID]} isArticles={false} />

        return (
            <div className='wiki-container'>
                <Breadcrumb />
                <Content title={category.Name}>
                    { childs }
                    {
                        articles.length > 1
                            ? this.state.shownArticle !== null ? <Article handleArticle={articles[this.state.shownArticle]}/> : null
                            : articles.length > 0 ? <Article handleArticle={articles[0]} noTitle /> : null
                    }
                </Content>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    children: state.wiki.children,
    category: state.wiki.category,
    articles: state.wiki.articles
})

export default withApi(connect(mapStateToProps)(Category))
