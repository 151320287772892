import { get, isEmpty, memoize } from 'lodash';
import { createSelector } from 'reselect';

export const selectProducts = state => get(state, 'actions.items', []) || [];

export const getProductColumnsVisibility = createSelector(selectProducts, products => {
  const product = get(products, '0', {});
  return !isEmpty(product)
    ? {
        bonus: !!parseFloat(product['Bonus Amt']),
        point: !!parseFloat(product['Point Qty']),
      }
    : {};
});

const getNews = state => get(state, 'news', {});
export const getEvents = state => get(state, 'events', {});
const getActions = state => get(state, 'actions', {});
export const getAction = createSelector(getActions, ({ action }) => action);

export const getActionsForNews = createSelector(getActions, actions => get(actions, 'data', []));

export const getEventsForNews = createSelector(getEvents, events => get(events, 'data', []));

export const getAds = createSelector(getNews, news => get(news, 'ads', []));

export const getLoadingEvents = createSelector(getNews, news =>
  get(news, ['eventsList', 'loading'], false),
);

export const getLoadingActions = createSelector(getActions, actions =>
  get(actions, 'loading', false),
);

export const getNewsList = createSelector(getNews, news => get(news, 'news', []));

export const getAdsList = createSelector(getNews, news => get(news, 'ads', []));

export const getEventsColleagues = createSelector(getEvents, events => events.colleagues || []);

export const getInvites = state => get(state, 'invites', {});
export const getInviteStatus = createSelector(getInvites, invites =>
  memoize(index => {
    const statuses = invites.status || [];

    return statuses[index];
  }),
);
