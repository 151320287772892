import isSingleActiveGroup from './isSingleActiveGroup';
import cloneDeep from 'lodash/cloneDeep'

export default (facets, filterGroups) => (activeGroup, activeGroups, activeKeys) =>
  filterGroups.reduce((groups, group) => {
    const isActiveGroup = group.key === activeGroup;
    const activeFacets = facets[group.key];

    if (!activeFacets) {
      return [...groups, group];
    }

    if (isActiveGroup) {
      if (isSingleActiveGroup(activeGroups, group)) {
        return [...groups, {...group, filters: group.filters.map(filter => ({...filter, disabled: false}))}]
      }

      return [...groups, group];
    }

    const activeCodes = activeFacets.map(facet => facet.code);
    const filters = cloneDeep(group.filters);

    for (let i = 0, l = filters.length; i < l; i++) {
      filters[i].disabled =
        !activeKeys.includes(filters[i].key) && !activeCodes.includes(filters[i].key);
    }

    return [...groups, { ...group, filters }];
  }, []);
