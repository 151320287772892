import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Async } from 'react-select';

const FilterVendor = ({ setFilter, style }) => {
  const [vendor, setVendor] = useState('');
  const {
    wiki: { getAttachmentsVendors },
  } = useDispatch();

  const handleChange = newValue => {
    setVendor(newValue);
    setFilter('vendor', newValue);
  };

  const loadOptions = (input, callback) => {
    getAttachmentsVendors()
      .then(options => callback(null, { options }))
      .catch(err => callback(err, null));
  };

  return (
    <Async
      name="filterVendor"
      id="filter-vendor"
      placeholder="Выберите вендора"
      noResultsText="Выберите вендора"
      loadingPlaceholder="Загружаем вендоров"
      loadOptions={loadOptions}
      searchable={false}
      onChange={handleChange}
      value={vendor}
      style={style}
    />
  );
};

export default FilterVendor;
