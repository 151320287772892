import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../icon.module.scss';

export const Reconstruction = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M6.83399 8.53131C7.44561 7.91968 7.77495 7.11986 7.63381 6.27299C7.44561 4.86154 7.9161 3.45009 8.99821 2.36798C10.4097 0.956536 12.5268 0.627198 14.2676 1.42702L11.4447 4.24992C10.8331 4.86154 10.739 5.8966 11.3036 6.60233C11.9622 7.30805 13.0444 7.30805 13.703 6.64938L16.573 3.77943C17.3728 5.52022 17.0435 7.63739 15.632 9.04884C14.5499 10.131 13.0914 10.5544 11.727 10.4132C10.8801 10.3191 10.0803 10.6485 9.46869 11.2131L4.24633 16.4354C3.49356 17.1882 2.31735 17.1882 1.56458 16.4354C0.811807 15.6826 0.811807 14.5064 1.56458 13.7537L6.83399 8.53131Z"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

Reconstruction.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Reconstruction.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
