import { clearSubmitErrors, SubmissionError } from 'redux-form';
import confirm from 'components/native/confirm/function';
import alert from 'components/native/alert/function';
import { blockRow, unblockRow, hideRow } from 'common/util';

export default {
  name: 'employees',
  state: {
    data: [],
    fetching: {
      delete: {},
      block: {},
    },
  },
  reducers: {
    load: (state, payload) => {
      state.data = payload?.data?.data || [];
      return state;
    },
    setFetching: (state, { type, index, flag }) => {
      state.fetching[type] = state.fetching[type] || {};
      state.fetching[type][index] = flag;

      return state;
    },
  },
  effects: dispatch => ({
    async invite(data) {
      dispatch(clearSubmitErrors('inviteForm'));

      const { success, message } = await dispatch.api.post({
        url: 'new-settings/invite',
        data,
      });

      if (!success) {
        throw new SubmissionError({ _error: message });
      }
    },
    async remove({ login, index }) {
      try {
        await confirm(`Вы действительно хотите удалить сотрудника ${login}?`);
        this.setFetching({ type: 'delete', index, flag: true });
        const { success, message } = await dispatch.api.get({
          url: '/settings/employees/delete',
          query: `?id=${login}`,
        });
        this.setFetching({ type: 'delete', index, flag: false });

        if (success) {
          await alert(message);
          hideRow(`row-${login}`);
        }
      } catch (err) {}
    },
    async block({ login, index, blocked }) {
      try {
        await confirm(`Вы действительно хотите
          ${!blocked ? 'заблокировать' : 'разблокировать'}
          сотрудника ${login}?`);
        this.setFetching({ type: 'block', index, flag: 'wait' });
        const {
          success,
          message,
          blocked: isBlocked,
        } = await dispatch.api.get({
          url: '/settings/employees/block',
          query: `?login=${login}&block=${Number(!blocked)}`,
        });
        if (success) {
          this.setFetching({ type: 'block', index, flag: isBlocked });
        }
        await alert(message);
        if (blocked) {
          unblockRow(`row-${login}`);
        } else {
          blockRow(`row-${login}`);
        }
      } catch (err) {}
    },
  }),
  selectors(slice, _, hasProps) {
    return {
      getEmployees() {
        return slice(employees => employees.data);
      },
      getIsEmployeeBlocking: hasProps((_, index) =>
        slice(employees => {
          const { block } = employees.fetching;
          return block[index];
        }),
      ),
      getIsEmployeeDeleting: hasProps((_, index) =>
        slice(employees => {
          const { delete: Delete } = employees.fetching;
          return Delete[index];
        }),
      ),
    };
  },
};
