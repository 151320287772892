// @ts-nocheck
import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import cn from 'classnames';
import 'react-select/dist/react-select.min.css';

import { Icon } from 'common/ui/icon';

import styles from './select.module.scss';

export const arrowRenderer = props => {
  const { isOpen } = props;
  const classIconOpened = `select__icon_${isOpen ? 'open' : 'close'}`;
  return <Icon className={classIconOpened} name={isOpen ? 'arrow-up' : 'arrow-down'} />;
};

/**
 * Renders a <Select /> component
 * @component
 * @example
 *
 * return (
 *   <Select />
 * )
 */
export const Select = ({
  className,
  clearable,
  blurOnChange,
  disabled,
  hasError,
  isLoading,
  name,
  options,

  menuPlacement,
  placeholder,
  searchable,
  onBlur,
  onChange,
  ...rest
}) => {
  const handleChange = value => {
    onChange(value);

    if (blurOnChange) {
      onBlur(name);
    }
  };

  const handleBlur = () => {
    if (!blurOnChange) {
      onBlur(name);
    }
  };
  return (
    <div
      className={cn(styles['select'], className, {
        [styles['select--error']]: hasError,
        [styles['select--disabled']]: disabled,
        [styles['select--loading']]: isLoading,
        [styles['select__outer--top']]: menuPlacement === 'top',
      })}
    >
      <ReactSelect
        arrowRenderer={arrowRenderer}
        clearable={clearable}
        menuPlacement={menuPlacement}
        name={name}
        placeholder={placeholder}
        searchable={searchable}
        options={options}
        {...rest}
        onBlur={handleBlur}
        onChange={handleChange}
        isLoading={isLoading}
      />
    </div>
  );
};

Select.defaultProps = {
  className: '',
  clearable: false,
  blurOnChange: false,
  disabled: false,
  id: null,
  isLoading: false,
  hasError: false,
  menuPlacement: '',
  options: [],
  placeholder: '',
  selectedValue: null,
  searchable: false,
  value: '',
  onBlur: () => {},
  onChange: () => {},
};

Select.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  clearable: PropTypes.bool,
  /**
   * blur on change
   */
  blurOnChange: PropTypes.bool,
  /**
   * Visually and functionally disable the select.
   */
  disabled: PropTypes.bool,
  /**
   * Makes the border color red.
   */
  hasError: PropTypes.bool,
  /**
   * Adds a HTML `id` attribute to the select. This is used for linking the HTML with a label
   */
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  menuPlacement: PropTypes.string,
  /**
   * Adds `name` HTML attribute to element, indicating the property name associated with the selected value.
   */
  name: PropTypes.string.isRequired,
  /**
   * Select options
   */
  placeholder: '',
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  searchable: PropTypes.bool,
  /**
   * The current selected value of the select.
   */
  selectedValue: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  /**
   * The current value of the select.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ]),
  /**
   * Fires when the select loses focus.
   */
  onBlur: PropTypes.func,
  /**
   * The function that is called when the select value changes.
   *
   * It receives two arguments: `onChange(newValue, event)`.
   *
   * The consumer of this component should use that data to update the `value` prop passed in to
   * this component.
   */
  onChange: PropTypes.func,
};
