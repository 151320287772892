import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const EnabledExcelButton = props => {
  const { row, isListView } = props;

  const {
    order: { handleExport },
  } = useDispatch();

  if (row.reserveOrOrder === 'РЕЗЕРВ' && isListView) {
    return (
      <i
        className="icon icon-excel-12x12 enabled"
        title="Выгрузить заказ в Excel"
        onClick={async () =>
          await handleExport({
            id: row.orderNo,
            type: 1,
            format: 1,
            email: '',
            action: 'dnld',
            docType: 1
          })
        }
      />
    );
  }

  if (isListView) {
    return <i className="icon icon-excel-12x12-dis" title="Выгрузить заказ в Excel" />;
  }

  return null;
};

export default EnabledExcelButton;
