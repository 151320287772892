import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './default-link.module.scss';

/**
 * Renders a <DefaultLink /> component
 * @component
 * @example
 *
 * return (
 *   <DefaultLink />
 * )
 */
export const DefaultLink = forwardRef(({ children, className, color, disabled, ...rest }, ref) => {
  const classes = cn(styles['default-link'], className, {
    [styles['default-link_brand-blue']]: color === 'brand-blue',
    [styles['default-link_disabled']]: disabled,
  });

  const linkProps = { ...rest, className: classes };
  if (rest.target === '_blank') {
    linkProps.rel = 'noreferrer noopener';
  }

  return (
    <a className={classes} data-testid="default-link" {...linkProps} ref={ref}>
      {children}
    </a>
  );
});

DefaultLink.defaultProps = {
  children: null,
  className: '',
  color: 'brand-blue',
  disabled: false,
  href: '',
  target: '_blank',
};

DefaultLink.propTypes = {
  /**
   * children
   */
  children: PropTypes.node,
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * 'white' | 'black' | 'brand-red' | 'brand-red-hover' | 'brand-blue' | 'brand-blue-hover' | 'accent-blue' | 'light-blue'
   * | 'accent-yellow' | 'accent-yellow-hover' | 'accent-green' | 'accent-green-hover'| 'gray' | 'dark-gray' | 'light-gray' | 'stroke-gray'
   */
  color: PropTypes.string,
  /**
   * Able to click
   */
  disabled: PropTypes.bool,
  /**
   * href
   */
  href: PropTypes.string,
  /**
   * Anchor target '_self' | '_blank' | '_parent' | '_top'
   */
  target: PropTypes.string,
};
