import React, { useCallback } from 'react';
import alert from 'components/native/alert/function';
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';
import { useDispatch, useSelector, useStore } from 'react-redux';
import DefaultField from 'pages/Orders/components/order-edit/header-form/components/defaultField';
import Select from 'react-select';

const ShipmentMethodField = ({
 input: { value, onChange, name },
 setPickup,
 label,
 className,
 arrowRenderer,
 optionComponent,
 valueComponent
}) => {
  const isPreorder = useIsPreorder();
  const {
    select: { order },
  } = useStore();
  const {
    order: { changeFieldValue },
  } = useDispatch();

  const { shipmentMethod: orderShipmentMethod, postingDescription = '' } = useSelector(
    order.getHeader,
  );
  const { shipmentMethodRows = [], preorderShipmentMethodRows = [] } = useSelector(
    order.getSettings,
  );
  const shipmentMethods = isPreorder ? preorderShipmentMethodRows : shipmentMethodRows;

  const fromMsk = postingDescription?.includes('Дозаказ со склада МСК');

  const handleChange = useCallback(
    ({ value, Func, packingType }) => {
      const pickup = !!Number(Func);

      setPickup(pickup);

      if (pickup && orderShipmentMethod) {
        alert(
          'Внимание, при выборе метода отгрузки с другого склада, ' +
            'резервы в текущем заказе будут изменены!',
        );
      }
      onChange(value);
      packingType && changeFieldValue('packingType', packingType);
    },
    [setPickup, orderShipmentMethod],
  );

  const options = shipmentMethods.map(shipmentMethod => ({
    value: shipmentMethod.Code,
    label: shipmentMethod.Description,
    Func: shipmentMethod.Func,
    packingType: shipmentMethod['Prefered Packing Type'],
    default: Number(shipmentMethod['IsDefault']),
  }));

  if (!options.length) {
    return (
      <DefaultField fieldName={name} label={label} placeholder="Нет доступных методов отгрузки" />
    );
  }

  return (
    <div>
      <label>{label}</label>
      <Select
        value={value}
        name={name}
        options={options}
        onChange={handleChange}
        searchable={false}
        disabled={fromMsk}
        clearable={false}
        className={className}
        arrowRenderer={arrowRenderer}
        optionComponent={optionComponent}
        valueComponent={valueComponent}
      />
    </div>
  );
};

export default ShipmentMethodField;
