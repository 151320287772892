// @ts-nocheck
export const smoothScrollToTop = (speed = 250) => {
  if (window && window.scrollY) {
    setTimeout(() => {
      window.scrollTo(0, window.scrollY - speed);
      smoothScrollToTop(speed);
    }, 5);
  }
};

export function scrollToTop() {
  if (window){
    window.scrollTo(0, 0);
  }
}
