export const orderStatusIcons = {
  ОЗПр: {
    iconName: 'delivery',
    color: 'gray',
  },
  Резерв: {
    iconName: 'checkmark',
    color: 'accent-green',
  },
  'На подтверждении': {
    iconName: 'approve',
    color: 'gray',
  },
  Комплектуется: {
    iconName: 'out',
    color: 'gray',
  },
  'Ожидает оплаты': {
    iconName: 'attention',
    color: 'brand-red',
  },
  'Готов к выдаче': {
    iconName: 'out',
    color: 'brand-blue',
  },
  Выдаётся: {
    iconName: 'out-arrow',
    color: 'brand-blue',
  },
  'Передан в доставку': {
    iconName: 'delivery-small',
    color: 'gray',
  },
  Выдан: {
    iconName: 'out',
    color: 'accent-green',
  },
  Доставлен: {
    iconName: 'delivery-small',
    color: 'accent-green',
  },
};
