import React, { useMemo, memo, useRef } from 'react';
import classNames from 'classnames';
import Mixpanel from 'services/mixpanel';

const Pagination = props => {
  const { page, pages, onChangePage, parent = 'Новости' } = props;

  const prevPage = useRef(page);

  const handleClick = page => () => {
    onChangePage(page);
    Mixpanel?.track(`СтрГлавнаяСтраница.${parent}${page > prevPage.current ? 'Вправо' : 'Влево'}`, {
      page: 'СтрГлавнаяСтраница',
    });
    prevPage.current = page;
  };

  const disabledNext = page === pages;
  const disabledPrev = page === 1;
  const isHide = !(pages === 1);

  const classNameForNext = useMemo(
    () =>
      classNames('pagination__arrow', {
        'pagination__arrow--disabled': disabledNext,
      }),
    [disabledNext],
  );

  const classNameForPrev = useMemo(
    () =>
      classNames('pagination__arrow', {
        'pagination__arrow--disabled': disabledPrev,
      }),
    [disabledPrev],
  );

  return (
    isHide && (
      <div className="pagination">
        <button
          disabled={disabledPrev}
          className={classNameForPrev}
          onClick={handleClick(page - 1)}
        >
          <i className="icon icon-arrow-left" />
        </button>
        <button
          disabled={disabledNext}
          className={classNameForNext}
          onClick={handleClick(page + 1)}
        >
          <i className="icon icon-arrow-right" />
        </button>
      </div>
    )
  );
};

export default memo(Pagination);
