import React, { memo } from 'react';
import Slider from './Slider';
import useRouteConfig from 'hooks/use-route-config';
import { isCactus } from 'common/config';

const Banner = ({ place = 'top' }) => {
  const { showBanner, parent = {} } = useRouteConfig();

  if ((!showBanner && !parent.showBanner) || isCactus) {
    return null;
  }

  return (
    <div className={`banner banner--${place}`}>
      <Slider place={place} />
    </div>
  );
};

export default memo(Banner);
