import React from 'react';
import { separateNumberFormatter } from 'common/util';

const MenuRenderer = ({ options, selectValue, valueArray }) => {
  const onChange = option => () => selectValue(option);

  const isChecked = optionValue => valueArray.some(({ value }) => value === optionValue);

  return (
    <table className="multiselect__table">
      <thead className="multiselect__table__head">
        <tr>
          <th> </th>
          <th>Номер заказа</th>
          <th>План. дата отгрузки</th>
          <th>Объем</th>
          <th style={{ width: '120px' }}>Сумма, ₽</th>
          <th>Инициатор</th>
          <th>Метод отгрузки</th>
          <th>Код.Склада</th>
          <th style={{ width: '200px' }}>Комментарий</th>
        </tr>
      </thead>
      <tbody className="multiselect__table__body">
        {options.map((option, index) => (
          <tr
            key={option.value}
            className={option.isDisabled ? 'multiselect__table__disabled' : ''}
          >
            <td>
              <input
                key={`multiselect-${option.value}-${index}`}
                type="checkbox"
                onChange={onChange(option)}
                checked={isChecked(option.value)}
                disabled={option.isDisabled}
              />
            </td>
            <td>{option.label}</td>
            <td>{option.date}</td>
            <td>{option.volume}</td>
            <td>{separateNumberFormatter(option.sum)}</td>
            <td>{option.initiator}</td>
            <td>{option.shipmentMethodCode}</td>
            <td>{option.logisticObject}</td>
            <td>{option.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MenuRenderer;
