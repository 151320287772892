import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const OutArrow = ({
  className,
  color = '#236192',
  name,
  width = '13',
  height = '17',
  viewBox = '0 0 13 17',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M12.5 7.5H0.5V16.5H12.5V7.5Z" stroke={color} strokeMiterlimit="10" />
      <path d="M8.5 7.5H4.5V10.5H8.5V7.5Z" stroke={color} strokeMiterlimit="10" />
      <path d="M11 14H9V15H11V14Z" fill={color} />
      <path
        d="M9 3L6.70588 1.07407C6.58824 0.975309 6.41176 0.975309 6.29412 1.07407L4 3"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <rect x="6" y="1" width="1" height="5" fill={color} />
    </svg>
  );
};

OutArrow.defaultProps = {
  className: '',
  color: '#236192',
  width: '13',
  height: '17',
  viewBox: '0 0 13 17',
};

OutArrow.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
