// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import cn from 'classnames';
import alert from 'components/native/alert/function';
import { ErrorMessage } from 'common/ui/error-message';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';
import {
  addStepInterval,
  checkAvailableTime,
  checkFormatTime,
  formattingMask,
  setCorrectTime,
} from './utils';

import styles from './time-picker.module.scss';

export const TimePicker = ({
  disabled,
  originTimeFrom,
  originTimeTo,
  timeDiff,
  timeMin,
  timeMax,
  onChange,
}) => {
  const [error, setError] = useState('');
  const [timeFrom, setTimeFrom] = useState('');
  const [timeTo, setTimeTo] = useState('');
  const defaultInterval = 1;
  const interval = timeDiff === null ? defaultInterval : timeDiff;
  const timeFromName = 'timeFrom';
  const timeToName = 'timeTo';

  useEffect(() => {
    if (checkFormatTime.test(originTimeFrom) && checkFormatTime.test(originTimeTo)) {
      setTimeFrom(originTimeFrom);
      setTimeTo(originTimeTo);
    }
  }, [originTimeFrom, originTimeTo]);

  useEffect(() => {
    if (checkFormatTime.test(timeFrom) && checkFormatTime.test(timeTo)) {
      const { hasError, message } = checkAvailableTime(
        timeFrom,
        timeTo,
        interval,
        timeMin,
        timeMax,
      );
      if (hasError) {
        alert(message);
        setError(message);
        setTimeFrom(originTimeFrom);
        setTimeTo(originTimeTo);
      }

      if (!hasError) {
        setError('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeTo, timeFrom]);

  useEffect(() => {
    const result =
      checkFormatTime.test(timeFrom) && checkFormatTime.test(timeTo) ? { timeFrom, timeTo } : {};

    onChange(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrom, timeTo]);

  const handleChange = e => {
    const { value, name } = e.target;

    if (value.length <= 5) {
      const correctedTime = setCorrectTime(value);
      if (name === timeFromName) {
        setTimeFrom(correctedTime);
      }

      if (name === timeToName) {
        setTimeTo(correctedTime);
      }
    }
  };

  const handleStep = (name, increase) => () => {
    if (checkFormatTime.test(timeFrom) && checkFormatTime.test(timeTo)) {
      if (name === timeFromName) {
        const newTime = addStepInterval(timeFrom, increase);
        const formattedNewTime = formattingMask(newTime);
        setTimeFrom(formattedNewTime);
      }

      if (name === timeToName) {
        const newTime = addStepInterval(timeTo, increase);
        const formattedNewTime = formattingMask(newTime);
        setTimeTo(formattedNewTime);
      }
    }
  };

  const handleSelectText = event => {
    event.target.select();
  };

  const handlePaste = event => {
    event.preventDefault();
    return false;
  };

  return (
    <div
      className={cn(styles['time-picker'], {
        [styles['time-picker--disabled']]: disabled,
      })}
    >
      <div className={styles['time-picker__wrapper']}>
        <div className={styles['time-picker__times']}>
          <div
            className={cn(styles['time-picker__input-wrapper'], {
              [styles['time-picker__input-wrapper--disabled']]: disabled,
            })}
          >
            <input
              className={cn(styles['time-picker__input'], {
                [styles['time-picker__input--error']]: error,
              })}
              type="text"
              name={timeFromName}
              value={timeFrom}
              onChange={handleChange}
              onFocus={handleSelectText}
              onPaste={handlePaste}
              disabled={disabled}
              autoComplete="off"
            />
            {!disabled && timeDiff && (
              <div className={styles['time-picker__steps']}>
                <IconButton
                  className={styles['time-picker__step']}
                  onClick={handleStep(timeFromName, true)}
                >
                  <Icon name="sort-up" />
                </IconButton>
                <IconButton
                  className={styles['time-picker__step']}
                  onClick={handleStep(timeFromName, false)}
                >
                  <Icon name="sort-down" />
                </IconButton>
              </div>
            )}
          </div>
          <span className={styles['time-picker__delimeter']}>&ndash;</span>
          <div className={styles['time-picker__input-wrapper']}>
            <input
              className={cn(styles['time-picker__input'], {
                [styles['time-picker__input--error']]: error,
              })}
              type="text"
              name={timeToName}
              value={timeTo}
              onChange={handleChange}
              onFocus={handleSelectText}
              onPaste={handlePaste}
              disabled={disabled}
              autoComplete="off"
            />
            {!disabled && timeDiff && (
              <div className={styles['time-picker__steps']}>
                <IconButton
                  className={styles['time-picker__step']}
                  onClick={handleStep(timeToName, true)}
                >
                  <Icon name="sort-up" />
                </IconButton>
                <IconButton
                  className={styles['time-picker__step']}
                  onClick={handleStep(timeToName, false)}
                >
                  <Icon name="sort-down" />
                </IconButton>
              </div>
            )}
          </div>
        </div>
        {!disabled && timeMin && timeMax && (
          <div className={styles['time-picker__footnote']}>
            {!error && `с ${timeMin} до ${timeMax}`}
            {error && <ErrorMessage className={styles['time-picker__error']}>{error}</ErrorMessage>}
          </div>
        )}
      </div>
    </div>
  );
};

TimePicker.defaultProps = {
  disabled: false,
  timeDiff: null,
  onChange: () => {},
};

TimePicker.propTypes = {
  disabled: PropTypes.bool,
  originTimeFrom: PropTypes.string.isRequired,
  originTimeTo: PropTypes.string.isRequired,
  timeDiff: PropTypes.string,
  timeMin: PropTypes.string.isRequired,
  timeMax: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
