/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { undef } from 'common/util';
import Select from 'react-select';

const defaultButton = props => (
  <a href="" {...props}>
    {props.children}
  </a>
);

class TablePagination extends Component {
  constructor(props) {
    super(props);

    this.getSafePage = this.getSafePage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.applyPage = this.applyPage.bind(this);

    this.state = {
      page: props.page,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.page !== state.page) {
      return {
        page: props.page,
      };
    }

    return null;
  }

  getSafePage(page) {
    if (isNaN(page)) {
      page = this.props.page;
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1);
  }

  changePage(page) {
    page = this.getSafePage(page);
    this.setState({ page });

    if (this.props.page !== page) {
      undef(this.props.manual) || (this.props.manual && this.props.unblock());
      this.props.onPageChange(page);
    }
  }

  applyPage(e) {
    if (e) {
      e.preventDefault();
    }
    const { page } = this.state;
    this.changePage(page === '' ? this.props.page : page);
  }

  buildPageLink(page, content) {
    return (
      <a
        href=""
        onClick={e => {
          e.preventDefault();
          this.changePage(page - 1);
        }}
        className={this.state.page + 1 === page ? 'active' : ''}
        key={page}
      >
        {content}
      </a>
    );
  }

  render() {
    const {
      pages,
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      canPrevious,
      canNext,
      onPageSizeChange,
      manual,
      total,
      PreviousComponent = defaultButton,
      NextComponent = defaultButton,
      sortedData,
      nextText = '>',
      previousText = '<',
      endText = '>>',
      startText = '<<',
    } = this.props;


    const start = page - 5 < 0 ? 0 : page - 5;
    const end = page + 6 > pages ? pages : page + 6;
    let count = start

    const links = Array.from(Array(Math.max(end - start, 0)), () => ++count)

    const rowsCountFrom = page * pageSize;
    const rowsCountToByPage = page * pageSize + pageSize;
    const rowsCountTotal = manual ? total || pages * pageSize : sortedData.length;
    const rowsCountTo = rowsCountToByPage > rowsCountTotal ? rowsCountTotal : rowsCountToByPage;

    const pageSizeSelectOptions = pageSizeOptions.map(value => ({ value, label: value }));

    return (
      <div className="pagination pagination--table" style={this.props.paginationStyle}>
        {showPageSizeOptions && (
          <div className="pagination__info">
            <p>
              {`Записи с ${rowsCountFrom + 1} до `}
              {`${rowsCountTo} из ${rowsCountTotal} записей.`}
            </p>
          </div>
        )}
        <div className="pagination__menu">
          <div className="-previous">
            <PreviousComponent
              onClick={e => {
                e.preventDefault();

                if (!canPrevious) return;
                this.changePage(0);
              }}
              disabled={!canPrevious}
            >
              {startText}
            </PreviousComponent>
          </div>
          <div className="-previous">
            <PreviousComponent
              onClick={e => {
                e.preventDefault();

                if (!canPrevious) return;
                this.changePage(page - 1);
              }}
              disabled={!canPrevious}
            >
              {previousText}
            </PreviousComponent>
          </div>
          <div className="-center">
            <span className="-pageInfo">{
              links.map(link => this.buildPageLink(link, link))
            }</span>
          </div>
          <div className="-next">
            <NextComponent
              onClick={e => {
                e.preventDefault();

                if (!canNext) return;
                this.changePage(page + 1);
              }}
              disabled={!canNext}
            >
              {nextText}
            </NextComponent>
          </div>
          <div className="-next">
            <NextComponent
              onClick={e => {
                e.preventDefault();

                if (!canNext) return;
                this.changePage(pages);
              }}
              disabled={!canNext}
            >
              {endText}
            </NextComponent>
          </div>
        </div>
        {showPageSizeOptions && (
          <span className="select-wrap -pageSizeOptions">
            <Select
              clearable={false}
              className="placement-top"
              onChange={option => {
                manual && this.props.unblock();
                onPageSizeChange(Number(option.value));
              }}
              options={pageSizeSelectOptions}
              value={pageSize}
            />
          </span>
        )}
      </div>
    );
  }
}

const mapDispatch = ({ list: { unblock } }) => ({
  unblock,
});

export default connect(null, mapDispatch)(TablePagination);
