import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

function SearchProductInput({ closeModal }) {
  const [searchText, setSearchText] = useState('');
  const {
    claims: { searchItems },
  } = useDispatch();

  const changeSearch = ({ target }) => {
    setSearchText(target.value);
  };

  const isValid = searchText.length > 2;

  return (
    <div className="order-search claim-order-search">
      <div className="row align-items-center">
        <input
          type="text"
          name={'itemSearch'}
          placeholder={'Поиск товара (минимум 3 символа)'}
          className={`form-control form-control-sm`}
          autoComplete="off"
          value={searchText}
          onChange={changeSearch}
          onKeyPress={({ key }) => {
            if (key === 'Enter' && isValid) {
              searchItems(searchText);
            }
          }}
        />
        <i
          className="icon icon-search enabled"
          onClick={() => {
            if (isValid) {
              searchItems(searchText);
            }
          }}
        />
      </div>
      <i className="icon icon-close12" onClick={closeModal} />
    </div>
  );
}

export default SearchProductInput;
