import React from 'react';
import { isArray } from 'lodash';
import { dateToRU } from 'common/util';

export const FileCell = row => {
  if (!row) return null;

  const { fileName, fileSize, fileUrl } = row.original;

  const ext = fileName?.split('.')?.pop() || 'xls';
  let classExt = 'pdf';

  switch (ext) {
    case 'ppt':
    case 'pptx':
      classExt = 'powp';
      break;
    case 'xls':
    case 'xlsx':
      classExt = 'xls';
      break;
    case 'doc':
    case 'docx':
      classExt = 'doc';
      break;
  }

  return fileUrl ? (
    <a href={fileUrl} className="attachment-file-link" target="_blank" rel="noopener noreferrer">
      <i className={`icon icon-form-${classExt}`} /> ({fileSize} КБ)
    </a>
  ) : (
    <a href="#" className="attachment-file-link">
      <i className={`icon icon-form-${classExt}`} /> ({fileSize} КБ)
    </a>
  );
};

export const formattingId = id => (id && id.includes('/') ? id.replace('/', '-') : id);
export const formattingIdReturn = id => (id.includes('-') ? id.replace('-', '/') : id);

export const formattingDataForSpecialPrice = values => {
  const queryData = { ...values };
  const { vendorData = {} } = values;

  delete queryData.vendorData;
  delete queryData.vendorCount;

  for (const prop in vendorData) {
    if (isArray(vendorData[prop])) {
      vendorData[prop].forEach((item, index) => {
        queryData[`f050[${index}][${prop}]`] = item;
      });
    }
  }

  queryData.f080 = dateToRU(queryData.f080);
  queryData.f120 = dateToRU(queryData.f120);
  queryData.f030 = queryData.inn;
  queryData.f040 = queryData.customerName.fullname || queryData.customerName.label;
  queryData.f041 = queryData.customerName.na_no || queryData.customerName.value;
  queryData.inn = undefined;
  queryData.customerName = undefined;

  return queryData;
};

export const formattingOrderType = type => {
  switch (Number(type)) {
    case 1:
      return 'Заказ тестирования';

    case 2:
      return 'Заказ покупки';

    default:
      return '';
  }
};

export const createTime = date => {
  const hours = date.getHours();
  const minuts = date.getMinutes();
  const seconds = date.getSeconds();

  return `${hours < 10 ? `0${hours}` : hours}:${minuts < 10 ? `0${minuts}` : minuts}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`;
};
