export const priceToString = price => {
  if (typeof price === 'string') return price;
  if (typeof price === 'number') return price.toString();
  return '';
};

const formatNumberStr = numberStr => {
  if (typeof numberStr !== 'string' || !numberStr.match(/\d/)) return '0';
  return numberStr;
};

const priceFormat = value => {
  // Example:
  // '25000.00' -> '25 000.00'
  // '.00' -> '0.00'
  const priceStr = priceToString(value);
  const priceParts = priceStr.split('.');
  const priceBeforeDot = formatNumberStr(priceParts[0]);
  const priceAfterDot = formatNumberStr(priceParts[1]);
  return [parseInt(priceBeforeDot).toLocaleString(), priceAfterDot].join(',');
};

export default priceFormat;
