import React from "react";
import { getFormattedPrice } from 'utils/new/numbers/format-number';

export const columns = [
  {
    className: "left-align",
    Header: "Наименование контрагента",
    accessor: "name",
    id: "name",
    width: 500,
  },
  {
    Header: "Код контрагента",
    accessor: "code",
    id: "code",
    width: 200,
  },
  {
    Header: "Баланс БУ",
    width: 200,
    Cell: ({ row }) => {
      const original = row.original ?? row._original;

      if (!original) {
        return (<span>...</span>);
      }

      return (<span>{getFormattedPrice(original.balanceBu)} {original.currency}</span>);
    },
  },
];
