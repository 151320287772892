import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../icon.module.scss';

export const Animals = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect x="1.5" y="15.5" width="15" height="2" rx="0.5" stroke={color} />
    <path
      d="M2.5 10C2.5 6.41015 5.41015 3.5 9 3.5C12.5899 3.5 15.5 6.41015 15.5 10V15.5H2.5V10Z"
      stroke={color}
    />
    <path
      d="M6.5 6C6.5 4.61929 7.61929 3.5 9 3.5C10.3807 3.5 11.5 4.61929 11.5 6V15.5H6.5V6Z"
      stroke={color}
    />
    <path d="M3 9H15" stroke={color} />
    <circle cx="9" cy="2" r="1.5" stroke={color} />
  </svg>
);

Animals.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Animals.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
