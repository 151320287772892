import React from 'react';
import { NavLink } from 'react-router-dom';
import useRouteConfig from 'hooks/use-route-config';

const SubMenu = ({ type }) => {
  const { routes, siblings } = useRouteConfig('/warranty/service');

  const subRoutes = !!routes?.length && routes || siblings;

  if (!subRoutes) return null;

  return (
    <div className="row submenu_container">
      {subRoutes.map(
        (route, index) =>
          (!('showInSub' in route) || route.showInSub) && (
            <NavLink
              key={index}
              to={route.path}
              className="link link--underlined"
              isActive={(match, location) =>
                route.path === location.pathname || (type && route.path.includes(type))
              }
            >
              {route.name}
            </NavLink>
          ),
      )}
    </div>
  );
};

export default SubMenu;
