import {
  getItemsBrandsFilter,
  getOrder,
  getUser,
  isLastApiCallItems,
} from 'pages/Orders/selectors';
import { download } from 'common/util';
import { ROUTE_PREORDERS_EDIT } from 'pages/Orders/constances/preorder';
import { CARD_PAGE_PATH } from 'common/routes/constants';
import notifier from 'services/notifier';
import alert from 'components/native/alert/function';
import Cookies from 'universal-cookie';
import { selector } from 'client/store';
import * as effects from './effects';

export default {
  name: 'items',
  state: {
    exportStartTime: {},
    files: [],
    hasNewFiles: false,
    found: 0,
    facets: {},
    characteristics: [],
    groups: [],
    breadcrumbs: {},
  },
  reducers: {
    setFiles(state, { file, type, files }) {
      if (files) {
        state.files = files;
      } else if (!state.files.includes(file)) {
        state.files.unshift(file);
        state.hasNewFiles = true;

        const fileType = type === 'xlsx' ? 'xls' : type;
        state.exportStartTime[fileType] = null;
      }
      return state;
    },
    setFound: (state, found) => ({ ...state, found: found || state.found }),
    watchFiles: state => ({ ...state, hasNewFiles: false }),
    startExport: (state, type) => {
      state.exportStartTime[type] = Math.floor(Date.now() / 1000);

      return state;
    },
    endExport: (state, type) => {
      const fileType = type === 'xlsx' ? 'xls' : type;
      state.exportStartTime[fileType] = null;

      return state;
    },
    setCharacteristics: (state, characteristics) => ({ ...state, characteristics }),
    setFacets: (state, facets) => ({...state, facets}),
    load: (state, payload) => ({ ...state, ...(payload.data || payload) }),
    'order/load': (state, { data = {} }) => ({ ...state, ...data }),
    'orders/load': (state, { data = {} }) => ({
      ...state,
      facets: data?.facets || {},
      characteristics: data?.characteristics || [],
      groups: data?.groups || [],
      breadcrumbs: data?.breadcrumbs || {},
    }),
    resetEnv: state => {
      state.facets = {};
      state.characteristics = [];
      state.groups = [];
      state.breadcrumbs = {};

      return state;
    },
  },
  effects: dispatch => ({
    ...Object.fromEntries(Object.entries(effects).map(([name, func]) => [name, func(dispatch)])),
    async getFile(file, state) {
      const { id } = getUser(state);

      try {
        const { blob, filename } = await dispatch.api.post({
          url: '/file/get/items',
          data: {
            path: file,
            uid: id,
          },
        });

        download(blob, filename);
      } catch (e) {
        console.error(e);
      }
    },
    async getByOrder({ id, isNotPreorder }) {
      const endpoint = !isNotPreorder ? ROUTE_PREORDERS_EDIT : 'orders/edit/';

      await dispatch.api.get({
        url: `${endpoint}${id}`,
        query: '?onlyItems=1',
      });
    },
    onNotify({ pathname, id, message = {} }, state) {
      const isCard = pathname.includes(CARD_PAGE_PATH);
      const user = getUser(state);
      const order = getOrder(state);

      const orderId = order.orderNo || (!isCard && id) || 'NEW';

      if (message.uid !== user.id && message.id === orderId) {
        notifier.notify(message.message);
      }
    },
    async onExpire() {
      await alert('Ваш запрос обрабатывается. Обновите страницу через некоторое время.');
      notifier.dismiss();
    },
    async getFromWarehouse(orderId) {
      return await dispatch.api.get({ url: `/msk-avail-filter/${orderId}` });
    },
    async getSuggests({ val, isDemo }, state) {
      const settingsBrands = getItemsBrandsFilter(state);
      const url = isDemo ? 'demofund/suggest-items' : 'orders/edit/suggest-items';

      const queryBrands = settingsBrands.reduce(
        (qs, { Brand }) => `${qs}&brands[]=${encodeURIComponent(Brand)}`,
        '',
      );

      try {
        const { data = {} } = await dispatch.api.get({
          url,
          query: `?text=${encodeURIComponent(val)}${queryBrands}`,
        });

        return data;
      } catch (e) {}
    },
    async toggleSale(payload, state) {
      const { catalog } = selector;
      const forSale = catalog.getForSale(state);
      const isCallingItems = isLastApiCallItems(state);
      const { groupNo, orderNo } = payload;
      const cookies = new Cookies();

      cookies.set('sale', Number(!forSale), { path: '/' });

      dispatch.catalog.setForSale(!forSale);

      if (isCallingItems || (groupNo && orderNo)) {
        await this.toggleFilters(payload);
      }
    },
    async setAvail({ avail, ...rest }, state) {
      const isCallingItems = isLastApiCallItems(state);
      const { groupNo, orderNo } = rest;

      const cookies = new Cookies();
      cookies.set('avail', avail, { path: '/' });
      const saveFilters = dispatch.itemsFilter.save({ avail });

      const getAvailItems =
        isCallingItems || (groupNo && orderNo) ? this.toggleFilters(rest) : Promise.resolve;

      return Promise.all([getAvailItems, saveFilters]);
    },
  }),
  selectors: (slice, createSelector) => ({
    getTotalFound() {
      return slice(items => items.found);
    },
    getCharacteristics() {
      return slice(items => items.characteristics || []);
    },
    getFacets() {
      return slice(items => items.facets || {});
    },
    getGroups() {
      return slice(items => items.groups || []);
    },
    getBreadcrumbs({ catalog }) {
      return createSelector(slice, catalog.getForSale, ({ breadcrumbs }, forSale) => {
        if (forSale) {
          return Object.keys(breadcrumbs).reduce(
            (crumbs, key) => ({
              ...crumbs,
              [key]: breadcrumbs[key]?.filter?.(({ sale }) => sale > 0) || [],
            }),
            {},
          );
        }

        return breadcrumbs;
      });
    },
    getFiles() {
      return slice(items => items.files || []);
    },
  }),
};
