// eslint-disable-next-line no-unused-vars
import { useContextSelector as contextSelector, Context } from 'use-context-selector';
/**
 * @callback ContextSelectorCallback
 * @param {object} state
 * @returns any
 */

/**
 * #TODO: waiting for stable useContextSelector https://github.com/facebook/react/pull/20646
 * @param {Context<ContextReducerProps>} context
 * @param {ContextSelectorCallback} callback
 * @returns {any}
 */
const useContextSelector = (context, callback) =>
  contextSelector(context, state => {
    const { getValue } = state;

    if (getValue) {
      return getValue(callback);
    }

    return callback(state);
  });

export default useContextSelector;
