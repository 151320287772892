import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import { RenderSelectInputAsync, renderCheckbox, renderTime } from '../fields';
import { RenderSelect, renderMasked } from 'components/fields';
import RenderInputFile from 'components/fields/renderInputFile';
import { mobileMask } from 'common/util';
import { handleWithRuleMaxLength } from '../../../helpers';
import { optionsMethodGetDocument, optionsTypeUnload } from '../../../constants/optionsForSelect';
import { acceptDefault } from 'components/constants/file-types';
import { MAX_FILE_SIZE } from '../../../constants/max-file-size-shiping-agent';
import { ContainerAddressFields, RenderInputText } from 'pages/Logistic/components/agents/fields';
import { useDispatch } from 'react-redux';

const accept = acceptDefault.join(', ');

const AgentClientFields = ({ form, time }) => {
  const {
    agents: { getTrucks, getAddress },
  } = useDispatch();

  return (
    <Fragment>
      <div className="row">
        <Field
          name="methodGetDocument"
          label="Способ выдачи документов"
          options={optionsMethodGetDocument}
          placeholder="Выбрать cпособ выдачи документов"
          required
          component={RenderSelect}
        />
        <Field
          name="typeUnload"
          label="Вариант выгрузки"
          options={optionsTypeUnload}
          placeholder="Выбрать вариант выгрузки"
          required
          component={RenderSelect}
        />
        <Field
          name="maxTruck"
          label="Максимально допустимый тип т/с"
          placeholder="Выбрать Максимально допустимый тип т/с"
          loadOptions={getTrucks}
          required
          async
          component={RenderSelect}
        />
      </div>
      <div className="row">
        <Field
          name="street"
          label="Выбрать адрес"
          hint="Если адрес не найден, обратитесь к Вашему менеджеру"
          required
          fetchData={getAddress}
          component={RenderSelectInputAsync}
        />
        <ContainerAddressFields />
        <Field
          name="phoneFirst"
          label="Телефон"
          required
          mask={mobileMask}
          component={renderMasked}
        />
        <Field name="phoneSecond" label="Телефон 2" mask={mobileMask} component={renderMasked} />
        <Field
          name="time"
          label='Доставить "С" и "По"'
          initTime={time}
          required
          component={renderTime}
        />
        <Field
          name="paidEntry"
          label="Платный въезд"
          title="Компенсируется партнером"
          component={renderCheckbox}
        />
      </div>
      <div className="row">
        <Field
          name="contactFirst"
          label="Контактное лицо (ФИО)"
          type="text"
          placeholder="Введите контакт"
          handleChangeWithRule={handleWithRuleMaxLength(30)}
          component={RenderInputText}
        />
        <Field
          name="contactSecond"
          label="Контактное лицо 2 (ФИО)"
          type="text"
          placeholder="Введите второй контакт"
          handleChangeWithRule={handleWithRuleMaxLength(50)}
          component={RenderInputText}
        />
      </div>
      <div className="row">
        <Field
          label="Загрузите схему проезда"
          accept={accept}
          name="otherFiles"
          formName={form}
          maxFileSize={MAX_FILE_SIZE}
          component={RenderInputFile}
          style={{ width: '260px', marginTop: '3px' }}
        />
      </div>
    </Fragment>
  );
};

export default AgentClientFields;
