import useLocalForage from 'hooks/use-local-forage';
import { Link } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTableListData } from 'common/selectors';
import sumBy from 'lodash/sumBy';
import {
  ACTIVE_GENERATION_STATUS,
  ERROR_GENERATION_STATUS,
  FILE_GENERATED_STATUS,
} from 'pages/Finances/constants';
import useListData from "components/table/hooks/useListData"

export const Actions = row => {
  const [, setLocationState] = useLocalForage('location.state');
  if (!row) return null;

  const { type, docNo, subType } = row.original;

  return (
    <div>
      {Number(type) === 3 && Number(subType) === 1 ? (
        <i className="icon icon-view-dis" />
      ) : (
        <Link
          to={{
            pathname: `/finances/accounted/${docNo}`,
            state: { type },
          }}
          onMouseDown={() => setLocationState({ type })}
          onContextMenu={() => setLocationState({ type })}
        >
          <i className="icon icon-view" />
        </Link>
      )}
    </div>
  );
};

export const AccountedVolumeHeader = () => {
  const data = useListData();

  return `Объем = ${sumBy(data, d => parseFloat(d.volume)).toFixed(3)}`;
};

export const FileCell = row => {
  if (!row) return null;

  const {
    value,
    original: { operationNo },
    tdProps: {
      rest: { get },
    },
  } = row;

  switch (value) {
    case FILE_GENERATED_STATUS:
      return <i className="icon icon-expxls" onClick={async () => await get(operationNo)} />;
    case ACTIVE_GENERATION_STATUS:
      return <div style={{ color: '#007700' }}>Идет обработка</div>;
    case ERROR_GENERATION_STATUS:
      return <span className="text-danger text-danger--small">Ошибка формирования</span>;
    default:
      return null;
  }
};
