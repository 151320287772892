import ObjCollector from 'services/objCollector';

export const demoCollector = new ObjCollector();

export const DEMOFOND_TABLE_NAME = 'demofond';
export const DEMOFOND_LIST_TABLE_NAME = 'demofond-list';
export const DEMOFOND_ORDER_FORM = 'demofundOrderForm';
export const TZ_FORM = 'fromTZ';
export const SPECIAL_PRICES_FORM = 'specialPricesForm';

export const optionsReturnPeriod = [
  {
    value: 7,
    label: '7 дней',
  },
  {
    value: 14,
    label: '14 дней',
  },
  {
    value: 21,
    label: '21 день',
  },
  {
    value: 28,
    label: '28 дней',
  },
];

export const DEMO_ORDER = 2;

export const DFTYPE = 'dftype';
