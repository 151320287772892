import { useState, useCallback, useEffect } from 'react';

const useSlide = slidersLength => {
  const [slide, setSlide] = useState(0);
  const [isPlay, setIsPlay] = useState(true);

  const interval = 3000;
  const timeReturnAutoPlay = 10000;

  const lastPosition = slidersLength - 1;
  const moreOne = slidersLength > 1;
  const handleSelectSlide = useCallback(slide => {
    setSlide(slide);
    setIsPlay(false);
  }, []);

  useEffect(() => {
    const play = () => {
      const newSlide = slide === lastPosition ? 0 : slide + 1;
      setSlide(newSlide);
    };

    let timer;
    if (moreOne) {
      if (isPlay) {
        timer = setTimeout(play, interval);
      } else {
        timer = setTimeout(setIsPlay, timeReturnAutoPlay, true);
      }
    }

    return () => clearTimeout(timer);
  }, [lastPosition, slide, isPlay, moreOne]);

  return [slide, handleSelectSlide];
};

export default useSlide;
