import React, { useState} from 'react'
import {useDispatch, useSelector, useStore} from 'react-redux'
import TableList from 'components/table/list';
import Content from 'components/layout/content';
import listColumns from './columns/listColumns';
import { CERTIFICATES_TABLE_NAME, certsCollector } from '../constants';
import classNames from 'classnames';
import FilterValidity from 'pages/Certificates/components/filters/FilterValidity';
import FilterType from 'pages/Certificates/components/filters/FilterType';
import FilterSearch from 'pages/Certificates/components/filters/FilterSearch';
import isEmpty from 'lodash/isEmpty';

const defaultSorted = [
  {
    id: 'dateIssued',
    desc: true,
  },
];

const getTrProps = (state, info) =>
  info ? { className: info.original.expired ? 'expired' : '' } : {};

const CertificatesList = () => {
  const {
    select: { list },
  } = useStore();
  const pages = useSelector(list.getPages(CERTIFICATES_TABLE_NAME));

  const { list: { clear: clearList } } = useDispatch()

  const [filtered, setFiltered] = useState();

  const handleSearch = () => {
    if(isEmpty(filtered)){
      clearList()
    }

    const filters = Object.entries(certsCollector.get()).reduce(
      (acc, [id, value]) => [...acc, { id, value }],
      [],
    );

    setFiltered(filters);
  };

  const handleSort = value => {
    certsCollector.set('sort', value);
    handleSearch();
  };

  return (
    <Content title="Поиск сертификата">
      <div
        className={classNames(
          'actions-container',
          'actions-container--certificates',
          'align-items-center',
        )}
      >
        <FilterSearch search={handleSearch} />
        <FilterValidity />
        <FilterType />
        <label className="show-all-checkbox">
          <input
            type="checkbox"
            name="showAll"
            onChange={({ target: { checked } }) => {
              if (checked) {
                return certsCollector.set('showAll', 1);
              }

              certsCollector.delete('showAll');
            }}
            defaultChecked={false}
          />
          показывать товар без сертификатов
        </label>
        <button className="btn btn-outline-danger btn--rect" onClick={handleSearch}>
          Найти
        </button>
      </div>
      <div
        className={classNames('certificates-list', {
          'certificates-list--hidden': isEmpty(filtered),
        })}
        id="certificates-list"
      >
        <TableList
          name={CERTIFICATES_TABLE_NAME}
          columns={listColumns}
          filtered={filtered}
          manual
          pages={pages}
          route={'/certificates/search'}
          getTrProps={getTrProps}
          defaultSorted={defaultSorted}
          onSortedChange={handleSort}
          showPagination={pages > 1}
        />
      </div>
    </Content>
  );
};

export default CertificatesList;
