import React, { useCallback } from 'react';
import Content from 'components/layout/content';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Link } from 'react-router-dom';
import { RenderCustomerName, RenderINN } from 'pages/VAD/components/fields';
import RenderInputFile from 'components/fields/renderInputFile';
import validate from 'common/validates/validateTZ';
import warn from 'common/warnes/warnTZ';
import alert from 'components/native/alert/function';
import { TZ_FORM } from 'pages/VAD/constants';
import { useDispatch } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { LoadingSubmitButton } from 'components/loading-sumbit-button';

const FormTZ = ({ history, handleSubmit, submitting, reset, error }) => {
  const {
    tz: { create },
  } = useDispatch();

  const onSubmit = useCallback(async values => {
    const { success, message } = await create(values);

    if (!success) {
      throw new SubmissionError({
        _error: {
          message: ReactHtmlParser(message),
        },
      });
    }

    reset();
    await alert('Ваш запрос отправлен, он появится в списке запросов через несколько минут.');
    history.push({ pathname: '/vad/tz/list' });
  }, []);

  return (
    <Content title="Запрос ТЗ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tz__container">
          <div className="row">
            <Field
              name="customerName"
              label="Название заказчика"
              required
              props={{ form: TZ_FORM }}
              component={RenderCustomerName}
            />
            <Field
              name="inn"
              label="ИНН заказчика"
              required
              props={{ form: TZ_FORM }}
              component={RenderINN}
            />
          </div>
          <div className="row add-file">
            <Field
              label="Загрузите файл с техническим заданием"
              name="otherFiles"
              formName={TZ_FORM}
              required
              component={RenderInputFile}
              style={{ width: '260px', marginTop: '3px' }}
              isRegistered={true}
            />
            <Link to="/category/994" className="add-vendor-data">
              <i className={'icon icon-help'} />
              Помощь
            </Link>
          </div>
          <div>{error}</div>
          <div className="row">
            <LoadingSubmitButton
              label={'Отправить'}
              fetching={submitting}
              btnClass="btn btn--rect btn-outline-danger"
            />
          </div>
        </div>
      </form>
    </Content>
  );
};

export default reduxForm({
  form: TZ_FORM,
  touchOnChange: true,
  validate,
  warn,
})(FormTZ);
