import React, { Fragment, useEffect, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { Cookies } from 'services/cookies';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getItemsAvailFilter } from 'pages/Orders/selectors';
import ItemsSearch from './items-search';
import BrandList from '../../containers/brand-list';
import SelectItemAccess from './select-item-access';

const ItemsFilter = () => {
  const { select } = useStore();
  const forSale = useSelector(select.catalog.getForSale);
  const avail = useSelector(getItemsAvailFilter);
  const {
    items: { toggleSale },
    catalog,
  } = useDispatch();

  const { state } = useLocation();

  useEffect(() => {
    catalog.setForSale(Cookies.get('sale'));
  }, [avail]);

  const handleChange = async () => {
    await toggleSale({
      groupNo: state?.groupNo,
      tree: state?.tree,
    });
  };

  return (
    <>
      <BrandList />
      <SelectItemAccess />
      <div>
        <span className="ws-switcher-container ws-switcher-container__simple">
          <input type="checkbox" id="for-sale-switcher" checked={forSale} onChange={handleChange} />
          <label htmlFor="for-sale-switcher">&nbsp;</label>
          &nbsp;Распродажа
        </span>
      </div>
      <ItemsSearch className="form-control--round" />
    </>
  );
};

export default memo(ItemsFilter);
