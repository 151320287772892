import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Note = ({
  className,
  color = '#ffa900',
  name,
  width = '13',
  height = '14',
  viewBox = '0 0 13 14',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M5.60557 1.78885C5.9741 1.0518 7.0259 1.05181 7.39443 1.78885L12.2764 11.5528C12.6088 12.2177 12.1253 13 11.382 13H1.61803C0.874652 13 0.391156 12.2177 0.723607 11.5528L5.60557 1.78885Z"
        stroke={color}
      />
      <rect x="6" y="4" width="1" height="5" fill={color} />
      <rect x="6" y="10" width="1" height="1" fill={color} />
    </svg>
  );
};

Note.defaultProps = {
  className: '',
  color: '#ffa900',
  width: '13',
  height: '14',
  viewBox: '0 0 13 14',
};

Note.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
