export const CATALOG_ID_TO_ICON_KEY = {
  // Комплектующие для компьютеров
  ML01: 'chip',
  // Оборудование для геймеров
  ML41: 'gamepad',
  // Ноутбуки
  K12: 'notebook',
  ML02: 'notebook',
  // Компьютеры
  ML03: 'computer',
  // Сетевое оборудование
  ML04: 'router',
  // Цифровое фото и видео
  ML05: 'photo',
  // Серверы и схд
  ML06: 'servers',
  // Аудио видео техника
  ML07: 'headphones',
  // Программное обеспечение
  ML08: 'windows',
  // Портативная электроника
  ML09: 'phone',
  // Телекоммуникации
  ML10: 'printer',
  K04: 'printer',
  // Периферия и аксессуары
  ML11: 'audio',
  K01: 'audio',
  // Бытовая техника
  K11: 'washer',
  ML12: 'washer',
  // Плохая упаковка
  ML16: 'badpack',
  // Автомобильная техника и электроника
  ML20: 'speedometer',
  // Канцтовары
  ML22: 'pencil',
  K02: 'pencil',
  // Мебель
  ML23: 'chair',
  K10: 'chair',
  // Мобильная связь
  ML25: 'mobile',
  K03: 'mobile',
  // Продукты питания
  ML28: 'burger',
  // Расходные материалы
  ML31: 'squares-stack',
  K07: 'squares-stack',
  // Системы безопасности и умный дом
  ML32: 'security',
  // Скс и инженерное оборудование
  ML33: 'engineering-equipment',
  // Спорт активный отдых хобби
  ML34: 'bicycle',
  K05: 'bicycle',
  // Сувениры и бизнес подарки
  ML35: 'wallet',
  // Телевизоры и аксессуары
  ML36: 'monitor',
  K06: 'monitor',
  // Хозяйственные товары
  ML39: 'spray',
  // Электроинструменты и садовая техника
  ML40: 'drill',
  // Источники бесперебойного питания
  ML43: 'ups',
  // Видеонаблюдение
  Z100: 'cctv',
  // Опции систем хранения
  Z110: 'storage-options',
  // Периферия
  Z102: 'periphery',
  // Серверные опции
  Z106: 'server-options',
  // Серверы и СХД
  Z107: 'server-and-storage',
  // Сетевое оборудование
  Z108: 'network-hardware',
  // СКС
  Z111: 'scs',
  // Телекоммуникации
  Z112: 'telecommunications',
  // Демо-оборудование iRU
  Z101: 'demo-iru',
  // Комплектующие для компьютеров
  Z103: 'components-for-computers',
  // Компьютеры
  Z104: 'computers',
  // Системы безопасности
  Z109: 'security-systems',
  // Прочее
  Z113: 'other',
  // Авто
  ML48: 'auto',
  // Программное обеспечение
  ML45: 'software',
  // Ремонт строительство дача
  ML46: 'repair',
  K09: 'repair',
  // Маркетинг
  K08: 'marketing-cactus',
};
export const LOADER_TEXT = 'Категории загружаются';
