/* eslint-disable import/no-cycle */
import { selector } from 'client/store';
import { batch } from 'react-redux';
import { isNeededBackendFiltration } from 'pages/Orders/selectors';
import { proxyArgsToMethods } from 'common/util';
import getDisabledFormCheckboxes from 'pages/Orders/components/common/order-list-filter/helpers/getDisabledFormCheckboxes';
import getTableSortedProductItems from 'pages/Orders/components/common/order-list-filter/helpers/getTableSortedProductItems';
import * as selectors from './selectors';
import {FiltersCollector} from "common/collections"

export default {
  name: 'filters',
  state: {
    active: [],
    groups: [],
    tags: [],
    facets: {},
  },
  reducers: {
    setActive: (state, payload) => ({ ...state, active: payload }),
    setGroups: (state, payload) => ({ ...state, groups: payload }),
    setFacets: (state, payload) => ({ ...state, facets: payload }),
    setTags: (state, payload) => {
      const { name, value } = payload;

      if (!name) {
        return { ...state, tags: [] };
      }

      if (!value) {
        state.tags = [...state.tags].filter(tag => Array.isArray(name) ? !name.includes(tag.name) : tag.name !== name);

        return state;
      }

      const index = [...state.tags].findIndex(tag => tag.name === name);

      if (index < 0) {
        state.tags.push(payload);

        return state;
      }

      state.tags[index] = { ...state.tags[index], ...payload };

      return state;
    },
  },
  effects: dispatch => ({
    flush() {
      FiltersCollector.clear()

      batch(() => {
        dispatch.filters.setActive([]);
        dispatch.filters.setTags({});
        dispatch.filters.setFacets({});
      });
    },
    disableGroups(instance, state) {
      const { filters } = selector;

      getDisabledFormCheckboxes(
        getTableSortedProductItems(instance.current),
        filters.getCurrentGroups(state),
        filters.getActiveGroups(state),
        filters.getActiveKeys(state),
        filters.getCurrentKey(state),
      ).then(dispatch.filters.setGroups);
    },
  }),
  selectors(...args) {
    return proxyArgsToMethods(selectors, args);
  },
};
