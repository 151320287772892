import React from 'react';
import useImagePreview from '../../../../hooks/useImagePreview';
import cn from "classnames"
import styles from "common/ui/image/image.module.scss"

export const ImageWrapper = React.forwardRef((props, ref) => {
  return (
    <div
      className="table-image-preview"
      ref={ref}
      style={{
        top: -9999,
        left: -9999,
      }}
    >
      <img className="table-image-preview__image" alt="" />
    </div>
  );
});

export default ({ imageAlt, smallImageUrl, wrapper, className }) => {

  const [handleTableImageMouseEnter, handleTableImageMouseLeave] = useImagePreview({
    delay: 300,
    wrapper: wrapper,
  });

  return (
    <div
      className="orders__table__row__preview-image"
      onMouseEnter={event => {
        handleTableImageMouseEnter({
          event,
          alt: imageAlt,
          url: smallImageUrl.replace('_s.', '_m.'),
        });
      }}
      onMouseLeave={handleTableImageMouseLeave}
    >
      <img
        alt={imageAlt}
        src={smallImageUrl}
        data-testid="image"
        className={cn(styles['image'], className)}
      />
    </div>
  );
};
