import { Add } from './svgs/add';
import { Animals } from './svgs/animals';
import { Appliances } from './svgs/appliances';
import { Approve } from './svgs/approve';
import { ArrowDown } from './svgs/arrow-down';
import { ArrowRight } from './svgs/arrow-right';
import { ArrowUp } from './svgs/arrow-up';
import { ArrowUpward } from './svgs/arrow-upward';
import { Attention } from './svgs/attention';
import { Audio } from './svgs/audio';
import { Auto } from './svgs/auto';
import { AutoTechnics } from './svgs/auto-technics';
import { Back } from './svgs/back';
import { Balance } from './svgs/balance';
import { Cable } from './svgs/cable';
import { Calendar } from './svgs/calendar';
import { Camera } from './svgs/camera';
import { Cancel } from './svgs/cancel';
import { Cartridge } from './svgs/cartridge';
import { CellPhone } from './svgs/cell-phone';
import { CellPhoneSmall } from './svgs/cell-phone-small';
import { Chair } from './svgs/chair';
import { Check } from './svgs/check';
import { Checkbox } from './svgs/checkbox';
import { Checkmark } from './svgs/checkmark';
import { Clean } from './svgs/clean';
import { CleanFilter } from './svgs/clean-filter';
import { Clear } from './svgs/clear';
import { Components } from './svgs/components';
import { Computer } from './svgs/computer';
import { Csv } from './svgs/csv';
import { Damage } from './svgs/damage';
import { Default } from './svgs/default';
import { Delete } from './svgs/delete';
import { DeleteLarge } from './svgs/delete-large';
import { Delivery } from './svgs/delivery';
import { DeliverySmall } from './svgs/delivery-small';
import { Done } from './svgs/done';
import { Download } from './svgs/download';
import { Edit } from './svgs/edit';
import { Error } from './svgs/error';
import { Exit } from './svgs/exit';
import { Eye } from './svgs/eye';
import { EyeCrossed } from './svgs/eye-crossed';
import { Facebook } from './svgs/facebook';
import { Flash } from './svgs/flash';
import { Food } from './svgs/food';
import { Game } from './svgs/game';
import { Gift } from './svgs/gift';
import { Headphones } from './svgs/headphones';
import { Ibp } from './svgs/ibp';
import { Info } from './svgs/info';
import { Laptop } from './svgs/laptop';
import { Lock } from './svgs/lock';
import { Mail } from './svgs/mail';
import { MailSmall } from './svgs/mail-small';
import { Minus } from './svgs/minus';
import { Monitor } from './svgs/monitor';
import { More } from './svgs/more';
import { Network } from './svgs/network';
import { New } from './svgs/new';
import { Note } from './svgs/note';
import { Notice } from './svgs/notice';
import { Out } from './svgs/out';
import { OutArrow } from './svgs/out-arrow';
import { Pdf } from './svgs/pdf';
import { Phone } from './svgs/phone';
import { Photo } from './svgs/photo';
import { Question } from './svgs/question';
import { Reconstruction } from './svgs/reconstruction';
import { Repairs } from './svgs/repairs';
import { Ruble } from './svgs/ruble';
import { RubleOutlined } from './svgs/ruble-outlined';
import { Sale } from './svgs/sale';
import { Score } from './svgs/score';
import { Search } from './svgs/search';
import { Server } from './svgs/server';
import { Settings } from './svgs/settings';
import { Shipment } from './svgs/shipment';
import { Software } from './svgs/software';
import { SortDown } from './svgs/sort-down';
import { SortUp } from './svgs/sort-up';
import { Sport } from './svgs/sport';
import { Squares } from './svgs/squares';
import { Star } from './svgs/star';
import { StarOutlined } from './svgs/star-outlined';
import { Stationery } from './svgs/stationery';
import { Stop } from './svgs/stop';
import { Success } from './svgs/success';
import { Telecom } from './svgs/telecom';
import { Tools } from './svgs/tools';
import { Transfer } from './svgs/transfer';
import { Transit } from './svgs/transit';
import { Tv } from './svgs/tv';
import { UsdOutlined } from './svgs/usd-outlined';
import { Union } from './svgs/union';
import { Unlock } from './svgs/unlock';
import { User } from './svgs/user';
import { View } from './svgs/view';
import { ViewDetail } from './svgs/view-detail';
import { ViewMoreDetail } from './svgs/view-more-detail';
import { Wait } from './svgs/wait';
import { Warning } from './svgs/warning';
import { Xls } from './svgs/xls';

export const iconsList = {
  Add,
  Animals,
  Appliances,
  Approve,
  ArrowDown,
  ArrowRight,
  ArrowUp,
  ArrowUpward,
  Attention,
  Audio,
  Auto,
  AutoTechnics,
  Back,
  Balance,
  Cable,
  Calendar,
  Camera,
  Cancel,
  Cartridge,
  CellPhone,
  CellPhoneSmall,
  Chair,
  Check,
  Checkbox,
  Checkmark,
  Clean,
  CleanFilter,
  Clear,
  Components,
  Computer,
  Csv,
  Damage,
  Default,
  Delete,
  DeleteLarge,
  Delivery,
  DeliverySmall,
  Done,
  Download,
  Edit,
  Error,
  Exit,
  Eye,
  EyeCrossed,
  Facebook,
  Flash,
  Food,
  Game,
  Gift,
  Headphones,
  Ibp,
  Info,
  Laptop,
  Lock,
  Mail,
  MailSmall,
  Minus,
  Monitor,
  More,
  Network,
  New,
  Note,
  Notice,
  Out,
  OutArrow,
  Pdf,
  Phone,
  Photo,
  Question,
  Reconstruction,
  Repairs,
  Ruble,
  RubleOutlined,
  Sale,
  Score,
  Search,
  Server,
  Settings,
  Shipment,
  Software,
  SortDown,
  SortUp,
  Sport,
  Squares,
  Star,
  StarOutlined,
  Stationery,
  Stop,
  Success,
  Telecom,
  Tools,
  Transfer,
  Transit,
  Tv,
  Union,
  Unlock,
  User,
  View,
  ViewDetail,
  ViewMoreDetail,
  Wait,
  Warning,
  Xls,
  Xlsx: Xls,
  UsdOutlined,
};
