import React from 'react';
import { reserveIcon } from '../../../helpers';
import sumBy from 'lodash/sumBy';
import { sumFormatter } from 'common/util';
import { priceToString } from 'helpers/priceFormat';

export default (aggregated, split, resultSet = []) => [
  {
    Header: 'Код',
    id: ' itemNo',
    accessor: aggregated ? '' : 'id',
    sortable: false,
    show: !split,
    width: 70,
    Footer: aggregated ? <b>Итого</b> : '',
  },
  {
    Header: 'ОР',
    id: 'reserve',
    accessor: d => (aggregated ? '' : reserveIcon(d.onlineReserve, false)),
    sortable: false,
    show: !split,
    style: { display: 'flex' },
    width: 30,
    Footer: '',
  },
  {
    Header: 'Полное наименование',
    headerStyle: { textAlign: 'left' },
    id: 'name',
    accessor: d => (aggregated ? '' : d.name),
    sortable: false,
    show: !split,
    width: 360,
    Footer: '',
  },
  {
    Header: split ? 'Доступно на дату' : 'Кол-во',
    id: 'qty',
    accessor: d => (split ? d['Кол-Во'] : d.qty),
    Cell: row => parseInt(row?.value),
    sortable: false,
    Footer: aggregated ? <b>{sumBy(resultSet, d => parseInt(split ? d['Кол-Во'] : d.qty))}</b> : '',
  },
  {
    Header: () => (
      <span>
        Объём cтроки, м<sup>3</sup>
      </span>
    ),
    headerTitle: 'Объём cтроки, м3',
    id: 'volume',
    accessor: d => (split ? d['Объем единицы'] * d['Кол-Во'] : d.volume * d.qty),
    Cell: row => parseFloat(row.value).toFixed(2),
    sortable: false,
    style: { textAlign: 'right', paddingRight: '20px' },
    Footer: aggregated ? (
      <b>
        {parseFloat(
          sumBy(resultSet, d => (split ? d['Объем единицы'] * d['Кол-Во'] : d.volume * d.qty)),
        ).toFixed(2)}
      </b>
    ) : (
      ''
    ),
  },
  {
    Header: () => (
      <span>
        Сумма строки,
        <br /> вкл НДС
      </span>
    ),
    id: 'price',
    accessor: row => {
      const value = split
        ? row['Сумма Строки'] || 0
        : priceToString(row.unitPrice).replace(/\s/g, '') * row.qty;
      return value || 0;
    },
    Cell: row => sumFormatter.format(parseFloat(row.value).toFixed(2)),
    sortable: false,
    headerStyle: { textAlign: 'right' },
    style: { textAlign: 'right', paddingRight: '20px' },
    Footer: () => {
      if (!aggregated) return '';

      const value = sumFormatter.format(
        sumBy(resultSet, row => {
          const value = split
            ? row['Сумма Строки']
            : priceToString(row.unitPrice).replace(/\s/g, '') * row.qty;
          return parseFloat(value) || 0;
        }).toFixed(2),
      );

      return <b>{value}</b>;
    },
  },
];
