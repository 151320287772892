import { useLocation } from 'react-router-dom';
import { PRODUCT_CARD_PAGE_PATH } from 'common/routes/constants';
import { useDesign } from 'hooks/new';

export default function useIsCard(itemId = 0) {
  const { pathname } = useLocation();
  const [isPilot] = useDesign(pathname);
  const [hasPilotCard] = useDesign(`${PRODUCT_CARD_PAGE_PATH}/${itemId}`);

  if (itemId) {
    return hasPilotCard;
  }

  return isPilot && pathname.includes(PRODUCT_CARD_PAGE_PATH);
}
