import get from 'lodash/get';
import sortStringNumbers from 'helpers/sortStringNumbers';
const turnoverColumns = [
  {
    Header: 'Месяц',
    accessor: 'date',
    id: 'date',
    width: 350,
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
    Cell: row => get(row, ['original', 'month'], null),
  },
  {
    Header: 'Оборот',
    accessor: 'amount',
    id: 'amount',
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
    Cell: row => row.value + ' Руб.',
    sortMethod: sortStringNumbers,
  },
];

export default turnoverColumns;
