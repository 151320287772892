import { QUEUE_DOCS } from 'common/config';
import alert from 'components/native/alert/function';
import history from "common/history"

export default dispatch =>
  async ({ id, isPilot, ...rest }) => {
    const result = await dispatch.api.post({
      url: `orders/document/${id}`,
      data: rest,
      cancellation: false,
    });

    const { success, operation, message } = result;

    if (success) {
      if (!isPilot) {
        history.push({
          pathname: `/queue/${id}`,
          search: `?operation_no=${operation}&queueType=QUEUE_DOCS`,
          state: { queueType: QUEUE_DOCS, operation, opNo: operation },
        });
      }
    } else {
      alert(message);
    }

    return result;
  };
