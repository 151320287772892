import React, { useEffect } from 'react';
import { usePrev } from 'hooks';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import ym, { YMInitializer } from 'react-yandex-metrika';
import { withRouter } from 'react-router-dom';
import { getCurrentRoute } from 'common/util';
import { routes } from 'common/routes';

const Tracking = props => {
  const { yAccount, gAccount } = props;

  const gOptions = props.gOptions || {};
  const yOptions = props.yOptions || {};

  const prev = usePrev({
    pathname: props.location.pathname,
    search: props.location.search,
  });

  useEffect(() => {
    ReactGA.initialize(gAccount, gOptions);
  }, []); // eslint-disable-line

  useEffect(() => {
    const { pathname, search } = props.location;
    const { location } = window;

    const page = pathname + search;
    const prevPage = prev && prev.pathname + prev.search;

    const route = getCurrentRoute(routes, pathname);

    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
    });
    ReactGA.pageview(page);

    ym('hit', `${location.origin}${page}`, {
      referrer: (prevPage && `${location.origin}${prevPage}`) || '',
      title: route.route.name,
    });
  }, [props.location.pathname, props.location.search]); // eslint-disable-line

  return <YMInitializer accounts={yAccount} options={yOptions} />;
};

Tracking.propTypes = {
  gAccount: PropTypes.string.isRequired,
  yAccount: PropTypes.arrayOf(PropTypes.number).isRequired,
  gOptions: PropTypes.object,
  yOptions: PropTypes.object,
};

export default withRouter(Tracking);
