import { useDispatch, useSelector, useStore } from 'react-redux';
import { getFormValue } from 'pages/Orders/selectors';
import DefaultField from 'pages/Orders/components/order-edit/header-form/components/defaultField';
import React, { useEffect, useState } from 'react';
import { useDesignSelect } from 'hooks/new';

const EndpointField = ({ input: { onChange, value, name }, label, show = true }) => {
  const {
    select: { order },
  } = useStore();

  const {
    order: { initForm },
  } = useDispatch();

  const Select = useDesignSelect();

  const agentInfo = useSelector(getFormValue('shipmentAgentInfo'));
  const { finalAddress, shipmentAgentCode } = useSelector(order.getHeader);
  const { shipmentEndpointRows = [] } = useSelector(order.getSettings);

  const [options, setOptions] = useState([]);
  const [endpoint, setEndpoint] = useState();

  const [agentCode] = agentInfo?.split('#') || [];
  const code = agentCode || shipmentAgentCode || '';

  useEffect(() => {
    const endpointOptions = shipmentEndpointRows.reduce(
      (options, { shipmentAgent, address, contact, city, postCode }) => {
        if (shipmentAgent.includes(code) || address === finalAddress) {
          const value = `${address} | ${contact} | ${postCode} | ${city}`;
          return [...options, { value, label: `${address} | ${contact}` }];
        }

        return options;
      },
      [],
    );

    setOptions(endpointOptions);
    setEndpoint(endpointOptions[0]?.value);
    initForm({ [name]: endpointOptions[0]?.value });
  }, [code, finalAddress]);

  useEffect(() => {
    onChange(endpoint);
  }, [endpoint]);

  if (!show || !options.length) {
    return (
      <DefaultField title={label} fieldName={name} placeholder="Нет доступных конечных адресов" />
    );
  }

  return (
    <div>
      <label>{label}</label>
      <Select
        name={name}
        options={options}
        value={value}
        onChange={({ value }) => {
          onChange(value);
        }}
        clearable={false}
        searchable={false}
        placeholder="Не выбран"
      />
    </div>
  );
};

export default EndpointField;
