import React from 'react';
import { Link } from 'react-router-dom';

const EmployeesBack = () => (
  <div className="backref-container text-right">
    <Link className="section-nav" to="/settings/employees">
      <i className="icon icon-back-icon" /> Список сотрудников
    </Link>
  </div>
);

export default EmployeesBack;
