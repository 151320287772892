import React, { useCallback, useState } from 'react';
import Content from 'components/layout/content';
import { compose } from 'redux';
import Select from 'react-select';
import { getPeriodOptions } from 'pages/Finances/util/helper';
import withApi from 'hoc/withApi';
import withContextReducer from 'hoc/withContextReducer';
import { ListContext } from './constants';
import {
  accountedReducer,
  accountedState,
  clearAccountedState,
  setAccountedState,
} from 'pages/Finances/reducer';
import useContextDispatch from 'hooks/context/useContextDispatch';
import useContextSelector from 'hooks/context/useContextSelector';
import { useDispatch } from 'react-redux';
import AccountedList from './components/list';
import { def } from 'common/util';

const options = getPeriodOptions();

const Accounted = () => {
  const [search, setSearch] = useState();
  const [item, setItem] = useState();

  const {
    accounted: { get },
  } = useDispatch();

  const dispatch = useContextDispatch(ListContext);
  const { no, ozprNo, period, requested } = useContextSelector(ListContext, state => state);

  const handleFilterChange =
    type =>
    ({ target }) => {
      dispatch(setAccountedState({ [type]: target.value }));
    };

  const handleSearchChange = ({ target: { value } }) => setSearch(value);

  const handleSearchByGood = async search => {
    if (!def(search)) return;

    dispatch(setAccountedState({ search, requested: true }));
    const { data } = await get({ itemNo: search });
    setItem(data?.itemName);
  };

  const handleSearchByPeriod = useCallback(async () => {
    if (!period) return;

    dispatch(setAccountedState({ search: '' }));
    await get({ dt: period });
  }, [period]);

  const clearSearch = async e => {
    e.preventDefault();

    setSearch();
    dispatch(clearAccountedState());

    if (requested) {
      await handleSearchByGood('');
    }
  };

  return (
    <Content title="Учтенные документы" style={{ padding: 0 }}>
      <div className="actions-container actions-container--order align-items-center">
        <div className="row events-actions">
          <div className="flex-nowrap align-items-center order-filters">
            <label htmlFor="filter-period">Вывести данные за</label>
            <Select
              name="filterPeriod"
              id="filter-period"
              options={options}
              searchable={false}
              clearable={false}
              onChange={newValue => {
                dispatch(setAccountedState({ period: newValue.value }));
              }}
              value={period}
              style={{
                width: 190,
                margin: '0 10px',
              }}
            />
            <button className="btn btn-outline-danger btn--rect" onClick={handleSearchByPeriod}>
              Найти
            </button>
          </div>
          <div className="order-filters">
            <label htmlFor="filter-no">Фильтры</label>
            <input
              type="text"
              placeholder="номер заказа"
              className="form-control form-control-sm"
              name="no"
              onChange={handleFilterChange('no')}
              value={no}
            />
            <label htmlFor="filter-ozpr">или</label>
            <input
              type="text"
              placeholder="номер ОЗПр"
              className="form-control form-control-sm"
              name="no"
              onChange={handleFilterChange('ozprNo')}
              value={ozprNo}
            />
            <a
              href=""
              onClick={e => {
                e.preventDefault();
                dispatch(setAccountedState({ no: '', ozprNo: '' }));
              }}
              className="clear-filters"
            >
              <i className="icon icon-remove-filters" title="Очистить фильтр" />
            </a>
          </div>
          <div className="order-search">
            <label htmlFor="">Поиск товара в учтенных документах</label>
            <input
              type="text"
              placeholder="Код товара"
              className="form-control form-control-sm"
              name="no"
              onChange={handleSearchChange}
              value={search}
            />
            <button
              className="btn btn-outline-danger btn--rect"
              onClick={async () => await handleSearchByGood(search)}
            >
              Найти
            </button>
            <a href="#" onClick={clearSearch} className="clear-filters">
              <i className="icon icon-remove-filters" title="Очистить фильтр" />
            </a>
          </div>
        </div>
      </div>
      <AccountedList item={item} />
    </Content>
  );
};

export default compose(
  withApi,
  withContextReducer(accountedReducer, accountedState, ListContext),
)(Accounted);
