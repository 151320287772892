import { getJoinedOrders, hasOutdatedDeficit } from 'pages/Orders/helpers';
import alert from 'components/native/alert/function';
import confirm from 'components/native/confirm/function';
import notifier from 'services/notifier';
import {getDataByPath, setDataByPath} from "models/list/helpers"

export const changeIntegrate =
  (flag = true) =>
  (state, { joined, dataKey }) => {
    let data = getDataByPath(state)

    data.forEach(row => {
      row.disabled = flag;
      row.joining = joined?.includes(row.orderNo) || false;
    });

    setDataByPath(state, data)

    return state;
  };

export default ({ api, list }) =>
  async order => {
    let message;
    let joined = getJoinedOrders();

    joined = joined.filter(join => order !== join);

    if (joined.length > 10) {
      message = { type: 'alert', content: 'Выбрано более 10 заказов' };
    } else if (!joined.length) {
      message = {
        type: 'alert',
        content: 'Нельзя объединять заказы с одинаковыми номерами',
      };
    } else if (hasOutdatedDeficit()) {
      message = {
        type: 'confirm',
        content: `В заказах ${[...joined, [order]].join(', ')}
        есть просроченные резервы дефицитного товара.\n
        Объединение приведет к начислению штрафа. Объединить в заказ №${order}?`,
      };
    } else {
      message = {
        type: 'confirm',
        content: `Заказы ${joined.join(', ')}
        будут объединены в заказ №${order}.\nПродолжить?`,
      };
    }

    switch (message.type) {
      case 'alert':
        alert(message.content);
        break;
      case 'confirm':
        try {
          await confirm(message.content);

          const { success, message: joinMessage } = await api.post({
            url: `orders/integrate/${order}`,
            data: { joined },
          });

          if (success) {
            notifier.show(order, `Заказ ${order} объединяется`);
            list.change({ joined, handler: changeIntegrate() });
          } else {
            alert(joinMessage);
          }
        } catch (e) {}
        break;
    }
  };
