/* eslint-disable no-unused-vars */
import React from 'react';
import { DOC_TYPES_BASE } from 'common/config';
import {
  ACTIVE_GENERATION_STATUS,
  ERROR_GENERATION_STATUS,
  FILE_GENERATED_STATUS,
} from 'pages/Finances/constants';

export const getIncludes = (no, includes, save) => {
  if (!includes) {
    return 'нет';
  }
  const links = [];

  for (let i = 1; i <= includes; i++) {
    links.push(
      <a href="#" onClick={() => save({no, index: i})} className="link link__includes">
        {i}
      </a>,
    );
  }

  return links;
};

export const isDisabled = docType => DOC_TYPES_BASE.includes(docType);

export const getFileCell = (status, operationNo, handleDownloadSheet) => {
  const handleClick = () => handleDownloadSheet(operationNo);
  switch (status) {
    case FILE_GENERATED_STATUS:
      return <i className="icon icon-expxls" title="Выгрузить в Excel" onClick={handleClick} />;
    case ACTIVE_GENERATION_STATUS:
      return <div style={{ color: '#007700' }}>Идет обработка</div>;
    case ERROR_GENERATION_STATUS:
      return <div style={{ color: '#ff0000' }}>Ошибка формирования</div>;
    default:
      return null;
  }
};

export const handlerDate = (diffDays, formatedDateFrom, formatedDateTo) => {
  if (diffDays <= 0) {
    return {
      error: true,
      message: 'Начальная дата периода должна быть меньше конечной даты.',
    };
  }
  if (diffDays > 90) {
    return {
      error: true,
      message: 'Период не должен превышать 90 дней.',
    };
  }
  return {
    error: false,
    dateFrom: formatedDateFrom,
    dateTo: formatedDateTo,
  };
};

export function getPeriodOptions() {
  const quarters = ['I квартал', 'II квартал', 'III квартал', 'IV квартал'];

  const date = new Date();
  const month = date.getMonth() + 1;
  let year = date.getFullYear();
  let quarter = Math.ceil(month / 3);
  const res = [];

  for (let i = quarters.length; i > 0; i--) {
    res.push({
      value: `${year}-${quarter * 3 - 2}-01`,
      label: i === quarters.length ? 'текущий квартал' : `${quarters[quarter - 1]}-${year}`,
    });

    if (quarter === 1) {
      year--;
      quarter = 4;
    } else {
      quarter--;
    }
  }

  return res;
}
