import React, { useMemo, useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import { DEFAULT_PAGE_SIZE } from 'common/config';
import Loader from 'components/loader';
import createColumns from '../columns/selectProducts';
import { PAGE_SIZE, NO_DATA_TEXT, DATA_LOADING, CLAIMS_FORM } from '../constants';
import { useDispatch } from 'react-redux';
import { untouch } from 'redux-form';

const InputsProducts = props => {
  const {
    isLoading,
    products,
    fields: { name },
  } = props;
  const columns = useMemo(() => createColumns(name), [name]);
  const [isRequested, setIsRequested] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    products.forEach((product, index) => {
      dispatch(untouch(CLAIMS_FORM, `products[${index}].factQty`));
      dispatch(untouch(CLAIMS_FORM, `products[${index}].claimTypes`));
      dispatch(untouch(CLAIMS_FORM, `products[${index}].file`));
    });
  }, [products]);

  useEffect(() => {
    isLoading && setIsRequested(true);
  }, [isLoading]);

  if (!isRequested) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="claims-new__products-inputs">
        <Loader>{DATA_LOADING}</Loader>
      </div>
    );
  }

  return (
    <div className="claims-new__products-inputs">
      <ReactTable
        noDataText={NO_DATA_TEXT}
        data={products}
        columns={columns}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        showPagination={products.length > PAGE_SIZE}
        minRows={1}
        nextText=">"
        previousText="<"
        endText=">>"
        startText="<<"
        rowsText="строк"
      />
    </div>
  );
};

export default InputsProducts;
