import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { centrifugo, isShallowEqual } from 'common/util';
import { getUser } from 'pages/Orders/selectors';
import notifier from 'services/notifier';
import alert from 'components/native/alert/function';

const formattingChannelName = (channels, user) => {
  const curChannels = Array.isArray(channels) ? channels : [channels];
  return curChannels.map(channel => `${channel}-${user.rtmClientKey}`);
};

class AppSocket extends React.Component {
  subscribe = (channel, user) => {
    const channels = formattingChannelName(channel, user);

    this.commonSubscription = channels.map(channel =>
      this.socket.subscribe(channel, this.onCommonReceive),
    );

    this.subscription = channels.map(channel =>
      this.socket.subscribe(`${channel}#${user.id}`, this.onReceive),
    );

    this.commonNotify = channels.map(channel =>
      this.socket.subscribe(channel, this.onReceiveNotify),
    );

    this.subscriptionExpire = channels.map(channel =>
      this.socket.subscribe(`${channel}-expired#${user.id}`, this.onExpire),
    );
  };

  unsubscribe = () => {
    this.subscription?.forEach(subs => subs.unsubscribe());
    this.subscriptionExpire?.forEach(subs => subs.unsubscribe());
    this.commonSubscription?.forEach(subs => subs.unsubscribe());
    this.commonNotify?.forEach(subs => subs.unsubscribe());

    if (this?.socket?.isConnected?.()) {
      this.socket.disconnect();
    }
  };

  componentDidMount() {
    const { channel, user } = this.props;

    this.socket = centrifugo(user);

    // eslint-disable-next-line no-console
    this.socket.on('connect', data => console.log({ ...data, channel }));
    // eslint-disable-next-line no-console
    this.socket.on('disconnect', data => console.log({ ...data, channel }));
    // eslint-disable-next-line no-console
    this.socket.on('error', data => console.log({ ...data, channel }));

    this.socket.connect();
    this.subscribe(channel, user);

    if (typeof this.props.onInit === 'function') {
      const nextProps = this.getNextProps();
      this.props.onInit(nextProps);
    }
  }

  componentDidUpdate(prevProps) {
    if (!isShallowEqual(this.props.channel, prevProps.channel)) {
      this.unsubscribe();
      notifier.dismiss();
      this.subscribe(this.props.channel, this.props.user);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onReceive = message => {
    if (typeof this.props.onReceive === 'function') {
      this.props.onReceive(message);
    }
  };

  onCommonReceive = message => {
    if (typeof this.props.onCommonReceive === 'function') {
      this.props.onCommonReceive(message);
    }
  };

  onReceiveNotify = message => {
    if (typeof this.props.onReceiveNotify === 'function') {
      this.props.onReceiveNotify(message.data);
    }
  };

  onExpire = message => {
    if (typeof this.props.onExpire === 'function') {
      this.props.onExpire(message);
    } else {
      alert('Ваш запрос обрабатывается. Обновите страницу через некоторое время.').then(
        notifier.dismiss,
      );
    }
  };

  getNextProps = () => ({
    socket: this.socket,
    subscription: this.subscription,
    commonSubscription: this.commonSubscription,
    subscriptionExpire: this.subscriptionExpire,
    commonNotify: this.commonNotify,
  });

  render() {
    const nextProps = this.getNextProps();
    return (
      <>{this.props.renderProps === true ? this.props.children(nextProps) : this.props.children}</>
    );
  }
}

export default compose(
  connect(state => ({
    user: getUser(state),
  })),
)(AppSocket);
