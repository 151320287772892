export const SIMPLIFIED_COLUMNS = {
  // id: true, INT-643
  vendorId: false,
  image: true,
  orderDate: false,
  brand: true,
  vendorPart: false,
  inventoryMSK1: false,
  availableClient: true,
  warehouseOfficeRG: false,
  availableMSK1: false,
  name: true,
  onlineReserve: false,
  inReserveHours: false,
  qty: true,
  desiredQty: true,
  selfReserve: false,
  price: true,
  desiredPrice: false,
  desiredPriceClient: false,
  priceClient: false,
  priceLcyMSK: false,
  priceLcyRG: false,
  priceLCY: true,
  currencyCode: false,
  minPackaged: true,
  salesLimitType: false,
  weight: false,
  volume: false,
  warranty: false,
  availableExpected: true,
  availableExpectedNext: false,
  TCComment: false,
  group1: false,
  group2: false,
  group3: true,
  RRP: true,
  RRPDate: false,
};
