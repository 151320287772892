import ObjCollector from 'services/objCollector';

export const RESULT_COLORS = {
  '-1': '#009900', // Ожидает
  0: '#009900', // В работе
  1: '#cc6600', // Подтверждено
  2: '#ff0000', // Не Подтверждено
  3: '#cc6600', // Подтверждено
  4: '#000000', // Отменено
  5: '#cc6600', // Доукомплектовано
  6: '#000000', // Отказано в Рассмотрении
  7: '#cc6600', // Догружено
  8: '#cc6600', // Возврат
  9: '#cc6600', // Возврат
};

export const claimsFilters = new ObjCollector();
