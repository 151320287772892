import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import { capitalize } from 'utils/new/strings';

export function makeGoodCategories(goods = []) {
  const goodsCategories = map(goods, good => ({
    id: good.id,
    value: good?.Code2 || good?.code2,
    label: capitalize((good?.Group2 || good?.group2)?.toLowerCase()),
    Code1: good?.Code1 || good?.code1,
    Code2: good?.Code2 || good?.code2,
    Code3: good?.Code3 || good?.code3,
    Group1: capitalize((good?.Group1 || good?.group1)?.toLowerCase()),
    Group2: capitalize((good?.Group2 || good?.group2)?.toLowerCase()),
    Group3: capitalize((good?.Group3 || good?.group3)?.toLowerCase()),
  }));

  const categories = [
    {
      id: 0,
      value: '',
      label: 'Товары в заказах из всех категорий',
    },
    ...goodsCategories,
  ];
  return uniqBy(categories, 'value');
}
