// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Card } from 'common/ui/card';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';
import { Popover } from 'common/ui/popover';
import Mixpanel from 'services/mixpanel';

import { orderStatusIcons } from './data';

import styles from './order-card.module.scss';

export const OrderCard = ({
  className,
  data: {
    amount,
    comment,
    needPass,
    orderNo,
    shipmentMethod,
    shippingAddress,
    status,
    willDeleteTomorrow,
  },
}) => {
  const handleClick = () => {
    Mixpanel?.track('СтрГлавная.Заказ', {
      status,
      id: orderNo,
    });
  };

  return (
    <Card
      className={cn(styles['order-card'], className, {
        [styles['order-card_to-be-deleted']]: Boolean(willDeleteTomorrow),
      })}
    >
      <div className={styles['order-card__top']}>
        <div className={cn(styles['order-card__info'])}>
          <div className={styles['order-card__row']}>
            <Link
              to={`/orders/view/${orderNo}`}
              className={styles['order-card__number']}
              onClick={handleClick}
            >
              {orderNo}
            </Link>
            <p className={styles['order-card__shipping-address']}>{shippingAddress}</p>
          </div>

          <div className={styles['order-card__row']}>
            {needPass ? (
              <span className={styles['order-card__shipment-record']}>Нужен пропуск</span>
            ) : (
              <span className={styles['order-card__amount']}>{amount}</span>
            )}
            <p className={styles['order-card__shipment-method']}>{shipmentMethod}</p>
          </div>
        </div>
        <Popover
          className={styles['order-card__popover']}
          wrapperClassName={styles['order-card__popover-wrapper']}
          hasBorder
          trigger="hover"
          triggerNode={
            <IconButton>
              <Icon
                name={orderStatusIcons[status]?.iconName}
                color={orderStatusIcons[status]?.color}
              />
            </IconButton>
          }
        >
          {status}
        </Popover>
      </div>

      {comment && <p className={styles['order-card__note']}>{comment}</p>}
    </Card>
  );
};

OrderCard.defaultProps = {
  className: '',
};

OrderCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    amount: PropTypes.string,
    comment: PropTypes.string,
    currency: PropTypes.string,
    needPass: PropTypes.bool,
    needShipmentRecord: PropTypes.string,
    orderNo: PropTypes.string,
    shipment: PropTypes.string,
    shipmentMethod: PropTypes.string,
    shippingAddress: PropTypes.string,
    status: PropTypes.string,
    willDeleteTomorrow: PropTypes.string,
  }).isRequired,
};
