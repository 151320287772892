import { CARD_PAGE_PATH } from 'common/routes/constants';
import { getUser } from 'pages/Orders/selectors';
import get from 'lodash/get';
import alert from 'components/native/alert/function';

export default ({ items }) =>
  async ({ subscription, pathname }, state) => {
    const isCard = pathname.includes(CARD_PAGE_PATH);

    if (isCard) {
      return;
    }

    const user = getUser(state);
    const fetchingFilesTime =
      state.items.exportStartTime['csv'] || state.items.exportStartTime['xls'];

    if (!fetchingFilesTime) {
      return;
    }

    const currentSubscription = subscription.find(
      subs => subs.channel === `queue-order#${user.id}`,
    );

    if (!currentSubscription) {
      items.watchFiles();

      return;
    }

    try {
      const { publications } = await currentSubscription.history();

      if (publications.length) {
        const lastMessage = publications.shift();
        const success = get(lastMessage, ['data', 'success'], false);
        const genTime = get(lastMessage, ['data', 'generationTime'], 0);
        const type = get(lastMessage, ['data', 'type'], 'xls');

        if (success === false) {
          alert(`Ошибка формирования файла <b>${lastMessage.data.file}</b>!`);
          items.endExport(type);

          return;
        }

        if (genTime > state.items.exportStartTime[type]) {
          items.setFiles({ file: lastMessage.data.file, type });
        }
      } else {
        items.watchFiles();
      }
    } catch (err) {
      console.log(err);
      items.watchFiles();
    }
  };
