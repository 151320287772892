import React from 'react';
import sortStringNumbers from 'helpers/sortStringNumbers';
import { getCurrencySymbol, getLCYCurrencySymbol } from 'common/util';
import nameColumn from '../helpers/nameColumn';
import onlineReserveColumn from '../helpers/onlineReserveColumn';
import { getOrderCurrency } from '../../../helpers';

const createColumnsOZPr = ({ currency, hasExportPrice, settingsCurrency, location }) => {
  const orderCurrency = getOrderCurrency('order', currency);

  return [
    {
      Header: '',
      id: 'no',
      width: 25,
      sortable: false,
      Cell: row => row && row.viewIndex + 1,
    },
    {
      Header: 'Код',
      width: 60,
      id: 'id',
      accessor: 'id',
      sortMethod: sortStringNumbers,
    },
    {
      Header: 'Part #',
      id: 'vendorPart',
      width: 85,
      accessor: 'vendorPart',
    },
    {
      Header: 'Наименование',
      width: 750,
      accessor: 'name',
      id: 'name',
      style: { textAlign: 'left' },
      Cell: nameColumn,
    },
    {
      Header: 'ЖКол',
      title: 'Желаемое количество',
      accessor: 'desiredQty',
      id: 'desiredQty',
      width: 70,
      sortMethod: sortStringNumbers,
    },
    {
      Header: () => {
        const currencySymbol = getCurrencySymbol(orderCurrency);

        return <span>Цена, {currencySymbol}</span>;
      },
      width: 80,
      accessor: 'unitPrice',
      id: 'unitPrice',
      style: { textAlign: 'right' },
      Cell: row => row && <strong>{row.value}</strong>,
      sortMethod: sortStringNumbers,
    },
    {
      Header: () => <span>Цена_ЭКСПОРТ, &#8381;</span>,
      accessor: 'exportPrice',
      id: 'exportPrice',
      width: 130,
      style: { textAlign: 'right' },
      sortMethod: sortStringNumbers,
      show: !!parseInt(hasExportPrice),
    },
   /* {
      Header: () => <span>Цена_ЭКСПОРТ, {getLCYCurrencySymbol(settingsCurrency)}</span>,
      accessor: 'exportPriceClient',
      id: 'exportPriceClient',
      width: 130,
      style: { textAlign: 'right' },
      sortMethod: sortStringNumbers,
      show: !!parseInt(hasExportPrice),
    },*/
    {
      Header: 'ОР',
      title: 'Онлайн Резерв (в случае платного резерва со стоимостью отмены резерва, Р/шт*ч)',
      accessor: 'cancelReserveCostHr',
      id: 'cancelReserveCostHr',
      width: 60,
      Cell: onlineReserveColumn,
    },
    {
      Header: 'ВР',
      title: 'Время нахождения в резерве, ч.',
      width: 60,
      accessor: 'onlineReserve',
      id: 'onlineReserve',
      Cell: row =>
        row && [2].indexOf(parseInt(row.value, 10)) !== -1 ? row.original.inReserveHours : '',
    },
    {
      Header: 'Доступно',
      width: 90,
      accessor: 'availableClient',
      id: 'availableClient',
      show: location === 'МСК1',
    },
    {
      Header: 'Транзит',
      width: 90,
      sortMethod: sortStringNumbers,
      // row['MSS_Transit'] и row['SL Type'] где?
    },
    {
      Header: 'Статус Дата',
      width: 100,
      accessor: 'transitStatus',
      id: 'transitStatus',
      Cell: row =>
        row && (
          <>
            {row.original.transitStatus}
            <br />
            {row.original.transitDate}
          </>
        ),
    },
    {
      Header: 'Резерв',
      width: 90,
      accessor: 'reserve',
      id: 'reserve',
    },
    {
      Header: 'Отгруж.',
      title: 'Количество отгружено',
      width: 90,
      accessor: 'qtyShipped',
      id: 'qtyShipped',
    },
    {
      Header: () => {
        const currencySymbol = getLCYCurrencySymbol();
        return <span>ЖЦена, {currencySymbol}</span>;
      },
      accessor: 'desiredPriceRUB',
      id: 'desiredPrice',
      width: 90,
      style: { textAlign: 'right' },
      sortMethod: sortStringNumbers,
    },
    {
      Header: 'Вес',
      width: 50,
      accessor: 'weight',
      id: 'weight',
      style: { textAlign: 'right' },
      Cell: row => (row && parseFloat(row.value).toFixed(2)) || null,
    },
    {
      Header: 'Объем',
      width: 70,
      accessor: 'volume',
      id: 'volume',
      style: { textAlign: 'right' },
      Cell: row => (row && parseFloat(row.value).toFixed(2)) || null,
    },
  ];
};

export default createColumnsOZPr;
