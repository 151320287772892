import DemofondDashboard from "pages/VAD/components/demofond/components/DemofondDashboard"
import Loader from "components/loader"
import {SOFTWARE_ID} from "pages/Orders/constances/groups"
import React, {useCallback, memo} from "react"
import {useDispatch, useSelector} from "react-redux"
import {getEffectLoading} from "common/selectors"
import {getActiveCategory, getSortedSubCategories} from "pages/Orders/selectors"
import useCurrentOrder from "pages/Orders/hooks/useCurrentOrder"
import createRowForSelectGroupAction from "pages/Orders/components/order-edit/helpers/createRowForSelectGroupAction"
import catalogLvlToSelectGroupActionLvl from "pages/Orders/components/order-edit/helpers/catalogLvlToSelectGroupLvl"
import {LOADER_TEXT} from "pages/Orders/components/catalog/constants"
import useSelectGroup from "pages/Orders/hooks/useSelectGroup"
import isEmpty from "lodash/isEmpty"

const SubCategories = ({ categories = [], favorite = {} }) => {
  const selectGroup = useSelectGroup();

  const isSecondLevelLoading = useSelector(getEffectLoading('catalog', 'loadSubCategories'));
  const activeCategory = useSelector(getActiveCategory);
  const sortedCategories = useSelector(getSortedSubCategories(activeCategory.id));

  const dispatch = useDispatch();
  const currentOrder = useCurrentOrder();

  const handleChoose = useCallback(
    ({ level, category1, category2, category3 }) =>
      async event => {
        event.preventDefault();

        if (level === 2 && SOFTWARE_ID === category2.id) {
          window.location.href = '/?action=Y16E945A&action1=Y7D4B6FD';
        }

        const lastCategory = category3 || category2;
        const selectGroupRow = createRowForSelectGroupAction({
          level,
          category1,
          category2,
          category3,
        });
        const selectGroupLevel = catalogLvlToSelectGroupActionLvl(level);

        await selectGroup(lastCategory.id, selectGroupRow, selectGroupLevel, currentOrder)(event);
      },
    [currentOrder?.id],
  );

  return (
    <div
      style={{
        display: !categories.length ? 'none' : 'block',
      }}
      className="catalog__detailed"
    >
      {!isEmpty(activeCategory) && (
        <p className="catalog__detailed__title">
          {activeCategory.name}
        </p>
      ) || <DemofondDashboard />}
      {isSecondLevelLoading && (
        <Loader className='loader__container--sticky'>{LOADER_TEXT}</Loader>
      )}
      <ul
        className="catalog__detailed__groups"
        style={{
          display: isSecondLevelLoading ? 'none' : 'block',
        }}
      >
        {sortedCategories.map(category2lvl => (
          <li key={category2lvl.id} className="catalog__detailed__group">
            <div
              className="favorite_container"
              onClick={async () => {
                await dispatch.favoriteCategories.save({
                  catId: category2lvl.id,
                  parentId: activeCategory.id,
                });
              }}
            >
              <div
                className={
                  'icon-favorite' +
                  (favorite[activeCategory.id]?.includes(category2lvl.id) ? ' delete' : ' add')
                }
              />
            </div>
            <a
              href="#"
              className="catalog__detailed__subgroup__link"
              onClick={handleChoose({
                level: 2,
                category1: activeCategory,
                category2: category2lvl,
              })}
            >
              <span className="catalog__detailed__group__text">{category2lvl.name}</span>
            </a>

            <ul className="catalog__detailed__subgroups">
              {category2lvl.childs.map(category3lvl => (
                <li key={category3lvl.id} className="catalog__detailed__subgroup">
                  <a
                    href="#link"
                    className="catalog__detailed__subgroup__link"
                    onClick={handleChoose({
                      level: 3,
                      category1: activeCategory,
                      category2: category2lvl,
                      category3: category3lvl,
                    })}
                  >
                    <span className="catalog__detailed__subgroup__text">{category3lvl.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(SubCategories);
