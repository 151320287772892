// @ts-nocheck
import get from 'lodash/get';
import { proxyArgsToMethods } from 'common/util';

import * as effects from './effects';

export default {
  name: 'newOrderActions',
  state: {
    jobId: '',
    jobType: '',
  },
  reducers: {
    setJobId: (state, payload) => {
      state.jobId = payload;
      return state;
    },
    setJobType: (state, payload) => {
      state.jobType = payload;
      return state;
    },
  },
  effects(dispatch) {
    const type = this.name;

    return {
      ...proxyArgsToMethods(effects, [dispatch, type]),
    };
  },
  selectors: slice => ({
    getJobId() {
      return slice(state => get(state, 'jobId', ''));
    },
    getJobType() {
      return slice(state => get(state, 'jobType', ''));
    },
  }),
};
