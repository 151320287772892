export default ({ customerName, contact, phone, email, returnPeriod, purpose }) => {
  const errors = {};

  if (!customerName) errors.customerName = 'Выберите заказчика';
  if (!contact) errors.contact = 'Заполните поле';
  if (!phone) errors.phone = 'Заполните поле';
  if (!email) errors.email = 'Заполните поле';
  if (!returnPeriod) errors.returnPeriod = 'Заполните поле';
  if (!purpose) errors.purpose = 'Заполните поле';
  if (email && !/.+@.+\..+/i.test(email)) errors.email = 'Введите корректную почту';

  return errors;
};
