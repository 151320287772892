// @ts-nocheck
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'common/ui/button';
import { Heading } from 'common/ui/heading';
import { SearchContext } from '../../context';
import { Item } from '../item';

import styles from '../hint/hint.module.scss';

export const Block = ({ item }) => {
  const { list, title, id } = item;
  const { onFetchMain, text } = useContext(SearchContext);

  return (
    <div className={styles['hint__block']}>
      <Heading className={styles['hint__header']} size="sm">
        {title}
      </Heading>
      <ul>
        {list.map(listItem => (
          <li key={listItem.id} className={styles['hint__item']}>
            <Item
              id={listItem.id}
              groups={listItem.groups}
              name={listItem.name}
              type={id}
              requestBody={listItem.requestBody}
            />
          </li>
        ))}
      </ul>
      {/* {list.length >= 10 && (
        <Link
          to={{
            pathname: '/orders/new',
            state: { text },
          }}
          text={text}
          className={styles['hint__all-results']}
        >
          Показать все результаты поиска
        </Link>
      )} */}
      {list.length >= 10 && (
        <Button
          theme="ternary"
          className={styles['hint__all-results']}
          id='show-all-by-hints'
          onClick={() => onFetchMain(text)}
        >
          Показать все результаты поиска
        </Button>
      )}
    </div>
  );
};

Block.defaultProps = {
  item: { title: '', list: [] },
};

Block.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        available: PropTypes.string,
        calced: PropTypes.string,
        ean: PropTypes.string,
        group: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        partnumber: PropTypes.string,
        rank: PropTypes.string,
        sphinx_weight: PropTypes.string,
      }),
    ),
    title: PropTypes.string,
  }),
};
