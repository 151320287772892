import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Link } from 'react-router-dom';
import 'moment/locale/ru';

import { formatDate } from 'react-day-picker/moment';
import Content from 'components/layout/content';
import { LoadingSubmitButton } from 'components/loading-sumbit-button';
import CustomDayPickerInput from 'components/customDayPickerInput';
import BalanceInfo from 'pages/Finances/components/balance/components/info';
import BalanceInput from 'pages/Finances/components/balance/components/input';
import TableInfo from 'pages/Finances/components/balance/components/table';
import { isCactus } from 'common/config';
import { getEffectLoading } from 'common/selectors';
import { getFilterBalanceSelector } from 'pages/Finances/selectors';

const Balance = () => {
  const {
    select: {
      balance: {
        getCustomerBalance,
        getTenderBalance,
        getCounterpartyBalance,
        getErrorMessage,
      },
    },
  } = useStore();

  const {
    balance: { getBalanceByDate },
    financeFilters,
  } = useDispatch();

  const loading = useSelector(getEffectLoading('balance', 'get'));
  const customerBalance = useSelector(getCustomerBalance);
  const tenderBalance = useSelector(getTenderBalance);
  const counterparty = useSelector(getCounterpartyBalance);
  const showErrorMessageBlock = useSelector(getErrorMessage);
  const { day: defaultDateForLoadBalance } = useSelector(getFilterBalanceSelector);

  const [selectedDay, setSelectedDay] = useState(defaultDateForLoadBalance);

  useEffect(() => {
    if(!defaultDateForLoadBalance || isNaN(new Date(defaultDateForLoadBalance))) {
      return;
    }

    const date = formatDate(defaultDateForLoadBalance, 'DD.MM.Y', 'ru');

    getBalanceByDate(date)
  }, []);

  const handleDayChange = selectedDay => {
    setSelectedDay(selectedDay);

    financeFilters.save({
      type: 'balance',
      day: selectedDay,
    });
  };

  const handleShowBalance = () => {
    if (!selectedDay || isNaN(new Date(selectedDay))) {
      return;
    }

    const date = formatDate(selectedDay, 'DD.MM.Y', 'ru');

    getBalanceByDate(date);
  };

  return (
    <Content title="Баланс на дату">
      <div className="finances-content">
        <div className="main-block balance-main-block row">
          <div className="datepicker-block col-md">
            <label>Дата</label>
            <CustomDayPickerInput
              value={formatDate(selectedDay, 'DD.MM.YYYY', 'ru')}
              onDateInputChange={handleDayChange}
            />

            <LoadingSubmitButton
              btnClass="btn btn-danger btn--rect"
              label="Показать"
              fetching={loading}
              onClick={handleShowBalance}
            />
          </div>

          {!isCactus && (
            <div className="right-block col-md">
              <Link to="/finances/payments" className="graph-link">
                <i className="icon icon-graph" />
                Графики платежей
              </Link>
            </div>
          )}
        </div>
        {!showErrorMessageBlock ? (
          <div className="grid-balance__container">
            {customerBalance && (
              <div className={!tenderBalance ? "grid-balance__block3" : "grid-balance__block1"}>
                <BalanceInfo balance={customerBalance} label="Основной" />
              </div>
            )}
            {tenderBalance && (
              <div className="grid-balance__block2">
                <BalanceInfo balance={tenderBalance} label="Тендерный" />
              </div>
            )}
            {counterparty && (
              <div className="grid-balance__block3">
                <h2 className="counterparty-header">Баланс контрагентов</h2>
                <div className="balance-container fit-content">
                  <BalanceInput />
                </div>
                <div className="balance-container fit-content">
                  <TableInfo />
                </div>
              </div>
            )}
          </div>)
        : (
          <div className="main-block balance-main-block row">
            <span className="balance-error-block">
              Ошибка загрузки данных. Обратитесь в службу поддержки: <a href="mailto:trade_support@officeassistant.ru">trade_support@officeassistant.ru</a>
            </span>
          </div>
          )
        }
        {!isCactus && (
          <div className="help">
            Если Вам что-либо непонятно, посмотрите раздел <Link to="/category/879">помощь</Link>.
          </div>
        )}
      </div>
    </Content>
  );
};

export default Balance;

