// @ts-nocheck
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { formatBalance } from './utils';

// export const getNewUser = state => get(state, 'newUser', {});

export const getOldUser = state => get(state, 'user', {});

export const getBalance = createSelector(getOldUser, user => {
  const balance = get(user, 'balance', {});

  return formatBalance(balance);
});

export const getFIO = createSelector(getOldUser, user => get(user, ['info', 'FIO'], ''));

export const getMenu = createSelector(getOldUser, user => {
  const menu = get(user, ['info', 'menu'], '{}');
  return JSON.parse(menu);
});

export const getPermissions = createSelector(getOldUser, user => {
  const permissions = get(user, ['info', 'permissions'], '[]');

  return JSON.parse(permissions);
});

export const getEmail = createSelector(getOldUser, user => get(user, ['info', 'EMail'], ''));

export const getRDCode = createSelector(getOldUser, user => get(user, ['info', 'RD Code'], ''));

export const getDesign = createSelector(getOldUser, user => get(user, ['info', 'designV2'], '0'));
