export default (orders, value) => {
  let code;
  let codeShipmentMethod;
  let error;

  if (!Array.isArray(orders) || !Array.isArray(value)) {
    return;
  }

  for (const order of orders) {
    for (const val of value) {
      if (order.value === val) {
        if (!code && !codeShipmentMethod) {
          code = order.logisticObject;
        } else if (code !== order.logisticObject) {
          error = 'Нельзя выбрать заказы с разными кодами';
        }
      }
    }
  }

  return error;
};
