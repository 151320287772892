/* eslint-disable no-console */
import { useRef, useEffect } from 'react';

/**
 * Hook to store value between re-rendering
 *
 * @template {any} K
 * @param {K} val
 * @returns {K}
 */
export const usePrev = val => {
  const ref = useRef(val);

  useEffect(() => {
    ref.current = val;
  });

  return ref.current;
};

/**
 * Hook to get props leads to unnesessary updates of element of given name
 *
 * @param {string} name - name of component to show in console
 * @param {KeyStringObject} props
 * @returns {void}
 */
export const useWhyDidYouUpdate = (name, props) => {
  const previousProps = useRef(props);

  useEffect(() => {
    if (previousProps.current) {
      const allKeys = Object.keys({ ...previousProps.current, ...props });

      /**
       * @type {KeyStringObject}
       */
      const changesObj = {};

      allKeys.forEach(key => {
        if (previousProps.current[key] !== props[key]) {
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      if (Object.keys(changesObj).length) {
        console.log('[why-did-you-update]', name, changesObj);
      }
    }

    previousProps.current = props;
  });
};
