import React from 'react';
import { Link } from 'react-router-dom';
import Opinions from '../opinions';
import classnames from 'classnames';
import { isCactus } from 'common/config';
import useRouteConfig from 'hooks/use-route-config';

const classFooter = classnames('footer', { 'footer--cactus': isCactus });

const Footer = ({ opinions }) => {
  const { props: routeProps = {} } = useRouteConfig();
  const { noFooter } = routeProps;

  if (noFooter) {
    return null;
  }

  return (
    <div className={classFooter} id="footer">
      <Opinions show={opinions} />
      <Link to="/help/feedback">Связаться со службой поддержки</Link>
      {!isCactus && <Link to="/help/content">Помощь в работе</Link>}
      <Link to="/settings/personal">Личные данные</Link>
    </div>
  );
};

export default Footer;
