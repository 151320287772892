import { get } from 'lodash';
import createValue from '../helpers/createValue';
import { SHIPMENT_MSK } from '../../../../helpers';

export default {
  key: 'shipmentMethodDefault',
  create: ({ shipmentMethodRows }) => ({
    label: 'Метод отгрузки:',
    value: createValue(get(shipmentMethodRows, [0, 'Description'], SHIPMENT_MSK)),
  }),
};
