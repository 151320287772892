import React, { useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link, useLocation } from 'react-router-dom';
import Content from 'components/layout/content';
import AppSocket from 'components/AppSocket';
import createProductsColumns from './helpers/createProductsColumns';
import createTargetsColumns from './helpers/createTargetsColumns';
import Accept from './components/Accept';
import withApi from 'hoc/withApi';
import { useDispatch, useSelector } from 'react-redux';
import { getAction, getProductColumnsVisibility, selectProducts } from 'pages/Marketing/selectors';
import ReactTable from 'react-table-v6';
import TablePagination from 'components/table/pagination';
import useIsCard from 'pages/new/orders/hooks/use-is-card';

const defaultProductId = '00';

const theadProps = () => ({
  style: {
    fontWeight: 'bold',
    color: 'black',
  },
});

const ActionsItem = () => {
  const { state } = useLocation();
  const {
    actions: { accept, receive },
  } = useDispatch();
  const isCard = useIsCard(defaultProductId);

  const action = useSelector(getAction);
  const products = useSelector(selectProducts);
  const targets = useSelector(({ actions }) => actions.targets);
  const productTypeVisibility = useSelector(getProductColumnsVisibility);

  const productsColumns = useMemo(
    () => createProductsColumns(productTypeVisibility, isCard),
    [productTypeVisibility, isCard],
  );

  return (
    <AppSocket channel="queue-stat" onReceive={receive}>
      <Content title={action.name}>
        <div className="news-item">
          {ReactHtmlParser(action.text)}
          {!!targets.length && (
            <div>
              <ReactTable
                data={targets}
                columns={createTargetsColumns()}
                getTheadThProps={theadProps}
                defaultPageSize={5000}
                pageSize={10}
                showPagination={targets.length > 10}
                nextText=">"
                previousText="<"
                PaginationComponent={TablePagination}
                showPageSizeOptions={false}
              />
            </div>
          )}
          <Accept action={action} accept={accept} />
          {!!products.length && (
            <div>
              <h5>Товары, участвующие в акции</h5>
              <br />
              <ReactTable
                data={products}
                columns={productsColumns}
                defaultPageSize={5000}
                pageSize={10}
                nextText=">"
                previousText="<"
                showPagination={products.length > 10}
                PaginationComponent={TablePagination}
                showPageSizeOptions={false}
              />
            </div>
          )}
        </div>
        <div className="backref-container text-left">
          <Link className="section-nav" to={state?.backRef || '/actions'}>
            <i className="icon icon-back-icon" />
            {state?.backName || 'Список акций'}
          </Link>
        </div>
      </Content>
    </AppSocket>
  );
};

export default withApi(ActionsItem);
