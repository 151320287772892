import React from 'react';
import priceFormat from 'helpers/priceFormat';
import { getCurrencySymbol } from 'common/util';
import { useContextSelector } from 'use-context-selector';
import { ShipmentContext } from 'pages/Logistic/constants';

export const AmountCell = row => {
  if (!row) return null;

  const { currencyCode, amount } = row.original;
  const sanitizedAmount = parseInt(amount).toFixed(2);
  const decoratedAmount = priceFormat(sanitizedAmount);
  const currencySymbol = getCurrencySymbol(currencyCode);

  return `${decoratedAmount} ${currencySymbol}`;
};

export const ScheduleCell = row => {
  const setScheduleNo = useContextSelector(ShipmentContext, state => state);

  if (!row) return null;

  const {
    original: { queueStatus, requestNo, requestDate },
  } = row;
  const requestStatus =
    queueStatus === 'Ok' || queueStatus === 'Active' ? 'Заявка отправлена' : null;

  const onClick = () => {
    if (requestStatus) {
      return false;
    }

    setScheduleNo(requestNo);
  };

  return (
    <span className="shipment-numbers" onClick={onClick}>
      {requestDate || requestStatus}
    </span>
  );
};

export const TimeCell = row => {
  if (!row) return null;

  const { timePlanBegin, timePlanEnd, datePlan } = row.original;

  return (
    <>
      {timePlanBegin || ''} - {timePlanEnd || ''}
      <br />
      {datePlan || ''}
    </>
  );
};

