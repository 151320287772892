import { useLayoutEffect } from 'react';

const createUpdateElementPositionHandler = props => params => {
  const { compensationTop, compensationLeft } = props;
  const { movingRef, relativeRef } = params;
  const movingHtmlNode = movingRef.current;
  const relativeHtmlNode = relativeRef.current;
  if (!movingHtmlNode || !relativeHtmlNode) return null;
  const relativeRect = relativeHtmlNode.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  const newTop = relativeRect.top + scrollTop + compensationTop;
  const newLeft = relativeRect.left + scrollLeft + compensationLeft;

  movingHtmlNode.style.top = `${newTop}px`;
  movingHtmlNode.style.left = `${newLeft}px`;

  return movingHtmlNode;
};

function useUpdateElementPosition(props) {
  const { movingRef, relativeRef, compensationTop, compensationLeft } = props;
  const updateElementPositionHandler = createUpdateElementPositionHandler({
    movingRef,
    compensationTop,
    compensationLeft,
  });

  useLayoutEffect(() => {
    updateElementPositionHandler({
      action: 'initial',
      movingRef,
      relativeRef,
    });
    const updateElementPositionResizeHandler = () => {
      updateElementPositionHandler({
        action: 'resize',
        movingRef,
        relativeRef,
      });
    };
    window.addEventListener('resize', updateElementPositionResizeHandler);
    return () => {
      window.removeEventListener('resize', updateElementPositionResizeHandler);
    };
  }, []); // eslint-disable-line
}

export default useUpdateElementPosition;
