// @ts-nocheck
import get from 'lodash/get';
import { CACHE_NAME } from 'common/config';
import { NUMBER_TOP_ITEMS } from '../constants';

export default {
  name: 'newNews',
  state: {
    items: [],
    fetching: false,
  },
  reducers: {
    fetchNewsRequest: state => {
      state.fetching = true;
      return state;
    },
    fetchNewsSuccess: (state, payload) => {
      state.items = get(payload, ['data', 'news'], []);
      state.fetching = false;
      return state;
    },
  },
  effects: ({ api }) => ({
    async fetchNews() {
      try {
        this.fetchNewsRequest();
        const result = await api.get({
          url: '/new-news',
          query: `?only=1&top=${NUMBER_TOP_ITEMS}`,
          cache: CACHE_NAME,
        });

        this.fetchNewsSuccess(result);

        return result;
      } catch (err) {
        return null;
      }
    },
  }),
  selectors: slice => ({
    getNews() {
      return slice(state => ({
        ...state,
        items: state.items ? state.items.slice(0, 3) : [],
      }));
    },
  }),
};
