import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OrdersFilter extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    placeholder: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  state = {
    [this.props.name + 'Value']: '',
  };

  handleChange = ({ target: { value } }) => {
    const newState = { ...this.state, [this.props.name + 'Value']: value };

    this.props.onChange(newState);
  };

  render() {
    return (
      <input
        type="text"
        name={this.props.name}
        placeholder={this.props.placeholder}
        className={this.props.className}
        value={this.props.value}
        onChange={this.handleChange}
      />
    );
  }
}

export default OrdersFilter;
