import { get } from 'lodash';

export const getInitialShipmentAgent =
  (slice, createSelector) =>
  ({ order }) =>
    createSelector(
      order.getHeader,
      order.getSettings,
      ({ shipmentAgentCode, shipmentAgentType, orderNo }, { shipmentAgentRows = [] }) =>
        orderNo
          ? `${shipmentAgentCode}#${shipmentAgentType}`
          : `${get(shipmentAgentRows, [0, 'Code'], '')}#${get(
              shipmentAgentRows,
              [0, 'Agent Type'],
              '',
            )}`,
    );

export const getInitialContractorCode =
  (slice, createSelector) =>
  ({ order }) =>
    createSelector(
      order.getHeader,
      order.getSettings,
      ({ contractorCode, contractId, currency }, { counterAgentRows = [] }) => {
        const { Code, IdDogovor, ...rest } = counterAgentRows[0] || {};

        return contractorCode
          ? `${contractorCode}|${contractId}|${currency}`
          : `${Code}|${IdDogovor}|${rest['Currency Code']}`;
      },
    );

export const getInitialPackingType =
  (slice, createSelector) =>
  ({ order }) =>
    createSelector(
      order.getHeader,
      order.getDefaultShipmentMethod,
      ({ packingType }, method) => packingType || method?.['Prefered Packing Type'] || 'СКОТЧ',
    );

export const isPickup =
  (slice, createSelector) =>
  ({ order }) =>
    createSelector(order.getShipmentMethod, ({ Func }) => Number(Func) === 1);

export const getInitialDocType =
  (slice, createSelector) =>
  ({ order }) =>
    createSelector(order.getHeader, ({ docType = 1 }) => docType);

export const getInitialShipmentMethod =
  (slice, createSelector) =>
  ({ order }) =>
    createSelector(
      order.getHeader,
      order.getDefaultShipmentMethod,
      ({ shipmentMethod }, { Code }) => shipmentMethod || Code,
    );

export const getInitialEndCustomer = slice => () => slice(order => order.header.endCustomer);
export const getInitialPostingDescription = slice => () =>
  slice(order => order.header.postingDescription);
