import React from 'react';
import { useSelector } from 'react-redux';
import { getOrder } from 'pages/Orders/selectors';
import { useDesign } from 'hooks/new';
import useCanEdit from 'pages/new/orders/hooks/use-can-edit';
import { Textarea } from 'common/ui/textarea';
import { CustomTextArea } from '../../../common/form-fields/CustomTextArea';

const PostingDescriptionField = props => {
  const { postingDescription = '' } = useSelector(getOrder);
  const fromMsk = postingDescription?.includes('Дозаказ со склада МСК');
  const [isPilot] = useDesign();

  const canEdit = useCanEdit();

  return (
    <div className="align-items-start">
      <label>{props.label}</label>
      {isPilot ? (
        <Textarea {...props} {...props.input} disabled={!canEdit || fromMsk} />
      ) : (
        <CustomTextArea {...props} disabled={!canEdit || fromMsk} />
      )}
    </div>
  );
};

export default PostingDescriptionField;
