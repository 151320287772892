import React from 'react';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import { DEMOFOND_LIST_TABLE_NAME } from 'pages/VAD/constants';
import columns from 'pages/VAD/components/common/columns/demofond/listColumns';
import { Link } from 'react-router-dom';
import withApi from 'hoc/withApi';

const defaultSorted = [
  {
    id: 'projectNo',
    desc: true,
  },
];

const DemofondList = () => {
  return (
    <Content title="Список заказов Демофонд">
      <div className="actions-container demofond__container">
        <Link to="/vad/demofund" className="section-nav demofond__section-nav">
          <i className="icon icon-back-icon" /> Список демо-оборудования
        </Link>
      </div>
      <TableList
        className="table-container"
        name={DEMOFOND_LIST_TABLE_NAME}
        columns={columns}
        defaultSorted={defaultSorted}
        defaultPageSize={5000}
        pageSize={5000}
      />
    </Content>
  );
};

export default withApi(DemofondList);
