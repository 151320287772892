import persistMiddleware from 'common/middlewares/persistMiddleware';
import logMiddleware from "common/middlewares/logMiddleware"
import { createStateSyncMiddleware } from 'redux-state-sync';
import { syncConfig } from 'client/store/configs/persist';

/**
 * #TODO: remove apiMiddleware and thunkMiddleware in favor to REMATCH
 */
export default [
  persistMiddleware,
  createStateSyncMiddleware(syncConfig),
  //logMiddleware
];
