/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Content from 'components/layout/content';
import { Link } from 'react-router-dom';
import Newsletter from './components/newsletter';
import ReactModal from 'react-modal';
import Tabs from './components/tabs';
import ShipmentTab from './components/shipment-tab';
import classnames from 'classnames';
import { isCactus } from 'common/config';
import AppSocket from 'components/AppSocket';
import { ShipmentContext } from 'pages/Logistic/constants';
import ShipmentScheduleForm from './components/shipment-schedule-form';
import withApi from 'hoc/withApi';
import { useDispatch } from 'react-redux';

const classModal = classnames('Modal', 'Modal__new-shipment', { 'Modal--cactus': isCactus });

function Shipments() {
  const [isOpen, setIsOpen] = useState(false);
  const [scheduleNo, setScheduleNo] = useState(null);
  const {
    shipments: { onReceive, onExpire },
  } = useDispatch();

  useEffect(() => {
    setIsOpen(!!scheduleNo);
  }, [scheduleNo]);

  const closeModal = useCallback(() => {
    setScheduleNo(null);
  }, []);

  const recordBtn = (
    <a
      onClick={e => {
        e.preventDefault();
        setScheduleNo('NEW');
      }}
      href="#"
      className={classnames(
        'section-nav',
        'section-nav__no-underline',
        'section-nav--upper',
        'shipment__open_modal',
      )}
    >
      <i className="icon icon-create-icon" />
      Запись на самовывоз
    </a>
  );

  return (
    <Content title="СОСТОЯНИЕ ОТГРУЗОК">
      <div className="logistic-shipments__content__description">
        Информация по отгрузкам прошедшего периода доступна в разделе{' '}
        <Link to="/finances/accounted">"Финансы" - "Учтенные документы"</Link>
        <ReactModal
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel="Modal"
          className={classModal}
          overlayClassName="Overlay Overlay__columns"
          ariaHideApp={false}
        >
          <ShipmentScheduleForm closeModal={closeModal} scheduleNo={scheduleNo} />
        </ReactModal>
      </div>
      <AppSocket channel={'queue-shipment-schedule'} onReceive={onReceive} onExpire={onExpire}>
        <ShipmentContext.Provider value={setScheduleNo}>
          <Tabs labels={['Доставка', 'Самовывоз']}>
            {active => (
              <Fragment>
                <div className="extra">
                  {active === 'Самовывоз' && !isCactus && recordBtn}
                  <Newsletter />
                </div>
                <ShipmentTab filter={active} />
              </Fragment>
            )}
          </Tabs>
        </ShipmentContext.Provider>
      </AppSocket>
    </Content>
  );
}

export default withApi(Shipments);
