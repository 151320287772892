import { useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSortedCategories } from 'pages/Orders/selectors';
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';
import { usePrev } from 'hooks';

function useLoadCategories(delay) {
  const isPreorder = useIsPreorder();
  const { pathname } = useLocation();
  const isDemofond = /demof[uo]nd/.test(pathname);
  const prevPathname = usePrev(pathname);

  const categories = useSelector(getSortedCategories);
  const {
    catalog: { loadCategories, loadSubCategories },
  } = useDispatch();

  useEffect(() => {
    if (!categories.length || pathname !== prevPathname) {
      loadCategories({ isPreorder, isDemofond });
    }
  }, [categories.length, isPreorder, isDemofond, pathname]); // eslint-disable-line

  const handleMouseEnter = useCallback(
    category => {
      loadSubCategories({ category, isDemofond, isPreorder });
    },
    [isPreorder, isDemofond],
  );

  const handleMouseEnterDebounced = debounce(handleMouseEnter, delay);

  return {
    handleMouseEnter: handleMouseEnterDebounced,
    handleMouseLeave: handleMouseEnterDebounced.cancel,
    categories,
  };
}

export default useLoadCategories;
