import { isUniqueInOrders } from 'pages/new/orders/pages/orders-new/utils';

export default (state, { orderNo, onlyGoods = false }) => {
  if (!state.inList) {
    state.goods = state.goods.filter(good => !isUniqueInOrders(state.orders, good, orderNo));
  }

  if (!onlyGoods) {
    state.orders = state.orders.filter(order => order.orderNo !== orderNo);
  }

  return state;
};
