import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Async } from 'react-select';
import { isCactus } from 'common/config';
import { getEffectLoading } from 'common/selectors';
import ProductGroup from 'pages/Help/components/contacts/components/product-group';
import { groupOptions } from 'pages/Help/util/groupOptions';
import Loader from 'components/loader';

const ContactsManagers = () => {
  const [category, setCategory] = useState();
  const [products, setProducts] = useState([]);

  const {
    user: { getManagerCategories, getCategoryProducts },
  } = useDispatch();
  const loading = useSelector(getEffectLoading('user', 'getCategoryProducts'));

  if (isCactus) return null;

  const handleCategoryChange = async newValue => {
    const value = newValue?.value;
    setCategory(value);

    if (value) {
      await getCategoryProducts(value).then(setProducts);
    } else {
      setProducts([]);
    }
  };

  const loadCategories = (input, callback) => {
    getManagerCategories()
      .then(categories => callback(null, { options: groupOptions(categories) }))
      .catch(err => callback(err, null));
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12 category-select">
          <label>
            <span className="label-text">Список SPM (Sales Product Manager) по:</span>
            <div className="select-line">
              <Async
                name="category"
                placeholder="Все группы"
                title="Товарные группы"
                style={{ width: '180px' }}
                value={category}
                loadOptions={loadCategories}
                onChange={handleCategoryChange}
              />
            </div>
          </label>
          <div className="description">
            Отвечают за развитие партнерского канала и выполнения плана по товарной группе
          </div>
        </div>
      </div>
      {(loading && <Loader>загрузка менеджеров</Loader>) ||
        products.map((product, index) => (
          <ProductGroup product={product} key={product.name + index} />
        ))}
    </Fragment>
  );
};

export default ContactsManagers;
