import createValue from '../helpers/createValue';
import strToFixedStr from '../helpers/strToFixedStr';

export default {
  key: 'volume',
  create: ({ order }) => ({
    label: 'Объём:',
    value: createValue(strToFixedStr((order && order.volume) || 0.0, 2)),
  }),
};
