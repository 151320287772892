import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AppSocket from 'components/AppSocket';
import Content from 'components/layout/content';
import useUpdateHeight from 'hooks/use-update-height';
import { def, undef } from 'common/util';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import withApi from 'hoc/withApi';
import withContextReducer from 'hoc/withContextReducer';
import orderFilterReducer, { initialFiltersState } from 'pages/Orders/reducer/orderFilterReducer';
import useContextSelector from 'hooks/context/useContextSelector';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { isFetchingOrders } from 'pages/Orders/selectors';
import { OrderFiltersContext } from 'pages/Orders/constances';
import styles from '../../styles/orders.module.scss';
import createOrderColumns from '../common/columns/orderColumns';
import OrdersTableList from '../orders-table-list';
import OrderHeaderFilter from './components/order-header-filter';

const defaultSorted = [
  {
    id: 'orderNo',
    desc: true,
  },
];

const trGroupProps = (state, info) => {
  const currentTimestamp = (new Date().getTime() / 1000) | 0;

  return (
    (def(info) && {
      id: info.original.orderNo || '',
      className:
        info.original.deleteDate && info.original.deleteDate >= currentTimestamp
          ? 'rt-tr-group--will-deleted'
          : '',
    }) ||
    {}
  );
};
const tBodyProps = state => ({
  id: 'orders-list-body',
  rows: state.sortedData,
});

const defaultFilterMethod = (filter, row) => {
  const { id, value } = filter;

  if (!row || !row[id]) {
    return true;
  }

  return value ? row[id].toLowerCase().includes(value.toLowerCase()) : true;
};

const contentStyle = { padding: 0 };

const OrdersList = () => {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const loading = useSelector(isFetchingOrders);

  const {
    order: { expire, receive, commonReceive },
  } = useDispatch();

  const dispatch = useContextDispatch(OrderFiltersContext);

  const {
    order: { handleGroupSign: groupSign },
  } = useDispatch();

  const filterNoValue = useContextSelector(OrderFiltersContext, state => state.filterNoValue);
  const filterOzprValue = useContextSelector(OrderFiltersContext, state => state.filterOzprValue);
  const filterContactValue = useContextSelector(
    OrderFiltersContext,
    state => state.filterContactValue,
  );
  const joined = useContextSelector(OrderFiltersContext, state => state.joined);

  const height = useUpdateHeight('order-list', '90%');

  const trProps = useCallback(
    (state, info) => {
      if (undef(info)) {
        return {};
      }

      return {
        className: classNames({
          'orders-row': joined.id,
          'orders-row--joined': joined.id === info.original.joinId,
          'orders-row--disabled': state.loading,
        }),
      };
    },
    [joined.id],
  );

  const filtered = useMemo(() => {
    if (filterContactValue) {
      return [
        { id: 'orderNo', value: filterNoValue },
        { id: 'ozprNo', value: filterOzprValue },
        { id: 'contact', value: filterContactValue },
      ];
    }
    return [
      { id: 'orderNo', value: filterNoValue },
      { id: 'ozprNo', value: filterOzprValue },
    ];
  }, [filterNoValue, filterOzprValue, filterContactValue]);

  const style = { height };

  const onReceive = useCallback(message => {
    receive({ data: message.data, isList: true, dispatch });
  }, []);

  const onCommonReceive = useCallback(message => {
    commonReceive({ message, dispatch });
  }, []);

  const handleCheckboxChange = id => {
    setSelectedOrders(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(orderId => orderId !== id)
        : [...prevSelected, id],
    );
  };

  const handleGroupSign = async () => {
    const success = await groupSign({ orderNo: selectedOrders });

    if (success) {
      setSelectedOrders([]);
    }
  };

  const orderColumns = createOrderColumns(handleCheckboxChange);

  return (
    <AppSocket
      channel={['queue', 'queue-order']}
      onReceive={onReceive}
      onExpire={expire}
      onCommonReceive={onCommonReceive}
    >
      <Content title="Список заказов" style={contentStyle}>
        <div
          className={classNames('actions-container', styles.actionsContainer, 'align-items-center')}
        >
          <div className={classNames('row', styles.eventActions)}>
            <div>
              <Link
                to="/orders/new"
                className={['section-nav', 'section-nav__no-underline', 'section-nav--upper'].join(
                  ' ',
                )}
              >
                <i className="icon-create">+</i> Создать заказ
              </Link>
            </div>
            <OrderHeaderFilter />
            <Link
              to="/finances/purchases"
              className="graph-link"
              style={{ marginLeft: 15, marginRight: 15 }}
            >
              Отчет о закупках
            </Link>
            {selectedOrders.length > 0 && (
              <button className="btn btn-outline-danger btn--rect" onClick={handleGroupSign}>
                Подписать
              </button>
            )}
          </div>
        </div>
        <div
          id="order-list"
          style={style}
          className={classNames('order-list', 'order-list__items', 'order-list__orders')}
        >
          <OrdersTableList
            columns={orderColumns}
            defaultSorted={defaultSorted}
            defaultPageSize={5000}
            pageSize={5000}
            filtered={filtered}
            loading={loading}
            getTrProps={trProps}
            getTrGroupProps={trGroupProps}
            getTbodyProps={tBodyProps}
            model="ordersColumns"
            defaultFilterMethod={defaultFilterMethod}
            showPagination={false}
            joined={joined.id}
            minRows={1}
          />
        </div>
      </Content>
    </AppSocket>
  );
};

export default compose(
  withApi,
  withContextReducer(orderFilterReducer, initialFiltersState, OrderFiltersContext),
)(OrdersList);
