import confirm from 'components/native/confirm/function';
import { QUEUE_ORDER } from 'common/config';
import notifier from 'services/notifier';
import alert from 'components/native/alert/function';

export default dispatch =>
  async ({ orderNo, auto = false, isList = false }) => {
    const confirmation = [
      'После подписания заказов нельзя будет редактировать!',
      '<br />Для получения груза необходимо иметь доверенность и паспорт',
    ].join('');

    try {
      await confirm(confirmation);

      const { success, message } = await dispatch.api.post({
        url: `orders/group-sign`,
        data: {
          signed: true,
          queueType: QUEUE_ORDER,
          command: 'sign',
          orderNo,
        },
        cancellation: false,
      });

      if (success) {
        for (const id of orderNo) {
          notifier.show(id, `Заказ ${id} отправлен на подпись`);

          isList &&
            dispatch.list.changeRow({
              searchKey: 'orderNo',
              needle: id,
              disabled: true,
              statusText: 'на подписании',
            });
        }

        return true;
      } else {
        alert(message);
      }
    } catch (e) {
      console.error(e);
    }
  };
