// @ts-nocheck
import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import Content from 'components/layout/content';
import { LoadingSubmitButton } from 'components/loading-sumbit-button';
import logoHpe from 'img/logo_hpe.png';
import logoHP from 'img/hp.png';
import logoIru from 'img/iru.png';
import logoMerlion from 'img/logo_merlion_e.png';

import styles from '../styles.module.scss';

const Configurators = () => {
  const {
    iquote: { authDesktop, authServer },
  } = useDispatch();

  const loadingDesktop = useSelector(getEffectLoading('iquote', 'authDesktop'));
  const loadingServer = useSelector(getEffectLoading('iquote', 'authServer'));

  return (
    <Content title="Конфигураторы">
      <div className={styles['configurator']}>
        <div className="actions-container actions-container--order align-items-center">
          <Link
            to="/orders/multibrands"
            className={cn('section-nav', styles['configurator__link-list'])}
          >
            <i className="icon icon-event-list" />
            Список заявок
          </Link>
        </div>
        <div className={styles['configurator__container']}>
          <section className={styles['configurator__section']}>
            <div className={styles['configurator__item-list']}>
              <div className={styles['configurator__item']}>
                <img className={styles['configurator__logo']} alt="логотип Hpe" src={logoHpe} />
                <LoadingSubmitButton
                  label="Конфигуратор HPE iQuote"
                  fetching={loadingServer}
                  position="right"
                  btnClass={cn('btn', styles['configurator__title'], styles['configurator__link'])}
                  onClick={authServer}
                />
                <span className={cn('section-nav', styles['configurator__tags'])}>
                  Servers, Storage, Networking
                </span>
              </div>
              <div className={styles['configurator__item']}>
                <img className={styles['configurator__logo']} alt="логотип HP" src={logoHP} />
                <LoadingSubmitButton
                  label="Конфигуратор HP iQuote"
                  fetching={loadingDesktop}
                  position="right"
                  btnClass={cn('btn', styles['configurator__title'], styles['configurator__link'])}
                  onClick={authDesktop}
                />
                <span className={cn('section-nav', styles['configurator__tags'])}>
                  Desktops, Workstations, Laptops
                </span>
              </div>
            </div>
            <p className={styles['configurator__text']}>
              Это облачный ресурс, который предоставляет возможность конфигурирования продуктов HP с
              подбором совместимых опций и отправки подобранных конфигураций в B2B Merlion в виде
              заявки на покупку
            </p>
            <p className={cn(styles['configurator__text'], styles['configurator__text--danger'])}>
              Внимание! Для переключения между конфигураторами iQuote сначала нажмите "Выйти из
              Системы" в открытом окне конфигуратора, затем перейдите по ссылке из B2B.
            </p>
          </section>
          <section className={styles['configurator__section']}>
            <div className={styles['configurator__item-list']}>
              <div className={styles['configurator__item']}>
                <img className={styles['configurator__logo']} alt="логотип Iru" src={logoIru} />
                <a
                  href="/?action=YB28EC01&action1=Y0CC1A0C&art_id=746"
                  className={styles['configurator__title']}
                >
                  Конфигуратор iRU
                </a>
                <p className={styles['configurator__text']}>
                  Предлагаем воспользоваться конфигуратором ПК iRU для самостоятельного подбора
                  параметров и предварительной калькуляции стоимости заказа.
                </p>
                <a
                  href="/contents/cms/img/%D0%9A%D0%BE%D0%BD%D1%84%D0%B8%D0%B3%D1%83%D1%80%D0%B0%D1%82%D0%BE%D1%80%20%D0%9F%D0%9A%20iRU.xlsm"
                  className={cn(
                    'section-nav',
                    styles['configurator__link-list'],
                    styles['configurator__link-list--bold'],
                  )}
                >
                  <i className="icon icon-arrow-download" />
                  Скачать конфигуратор
                </a>
                <p className={styles['configurator__text']}>
                  <a
                    href="https://iru.ru/partner/welcome/"
                    className={cn('section-nav', styles['configurator__link-list'])}
                  >
                    Партнерская программа iRU
                  </a>{' '}
                  позволит вам расширить возможности бизнеса. Особые условия, преимущества, четыре
                  уровня статусов, которые можно ежеквартально повышать.
                  <br />
                  <br />
                  Работа с iRU - выгодно!
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Content>
  );
};

export default Configurators;
