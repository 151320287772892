// @ts-nocheck
import { RULES_FOR_ACTIONS } from '../constants';

export const showAction = (status, action, disabledActions) => {
  if (disabledActions.includes(action)) {
    return false;
  }

  const { statuses } = RULES_FOR_ACTIONS[action];
  return statuses.includes(status);
};
