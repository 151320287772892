import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import React from 'react';

export default props => {
  const { isCactus, title, to, ...rest } = props;

  return isCactus ? (
    <a href={to} {...rest}>
      {ReactHtmlParser(`${title}...`)}
    </a>
  ) : (
    <Link to={to} {...rest}>
      {ReactHtmlParser(`${title}...`)}
    </Link>
  );
};
