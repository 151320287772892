import React from 'react';
import withApi from 'hoc/withApi';
import Pagination from 'components/pagination';
import { Link } from 'react-router-dom';
import dateFormat from 'common/util';
import Content from 'components/layout/content';
import ReactHtmlParser from 'react-html-parser';
import NewsLink from './news-link';
import { isCactus } from 'common/config';
import { useSelector } from 'react-redux';
import { getAdsList, getNewsList } from 'pages/Marketing/selectors';

const NewsList = () => {
  const news = useSelector(getNewsList);
  const ads = useSelector(getAdsList);
  const pageCnt = useSelector(({ news }) => news.pageCnt);

  if (!Array.isArray(news)) {
    return null;
  }

  const list = news.map(news => {
    const dates = news.created.split('.');
    const created = new Date(dates[2], dates[1] - 1, dates[0]);

    let linkProps = {
      to: `/news/${news.newsId}`,
      className: 'news-link',
      title: news.title,
    };

    if (isCactus) {
      linkProps = {
        ...linkProps,
        isCactus: true,
        to: news.link || `https://officeassistant.ru/news/${news.id}`,
        rel: 'noopener noreferrer',
        target: '_blank',
      };
    }

    return (
      <li key={news.newsId}>
        <span className="news-created">
          {dates[0]}
          <small>{dateFormat(created, 'mmmm')}</small>
        </span>
        <div>
          <NewsLink {...linkProps} />
          <i>{ReactHtmlParser(`${news.name}...`)}</i>
        </div>
      </li>
    );
  });

  return (
    <Content title="Новости">
      <div className="row justify-content-between news-list__list">
        <div className="col-8">
          <div className="news-list">
            <h5>
              <i className="icon icon-news" />
              Новости недели
            </h5>
            <ul>
              {list}
              <li className="justify-content-center">
                <Pagination total={pageCnt} perPage={4} />
              </li>
            </ul>
          </div>
        </div>
        <div className="col-4">
          <div className="ads-list">
            <h5>
              <i className="icon icon-ads" />
              Объявления
            </h5>
            <ul>
              {' '}
              {ads.map((ads, index) => (
                <li key={index}>
                  <p>
                    {ads.link ? (
                      <a
                        href={ads.link}
                        rel="noopener noreferrer"
                        className="news-link"
                        target="_blank"
                      >
                        {ads.title}
                      </a>
                    ) : (
                      <Link to={`/news/${ads.newsId}`} className="news-link">
                        {ads.title}
                      </Link>
                    )}
                    <i>{ReactHtmlParser(ads.name)}...</i>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default withApi(NewsList);
