import { getItemsBrandsFilter, getLastApiCall, getOrder } from 'pages/Orders/selectors';
import { objToQS, QSToObj } from 'common/util';
import isEmpty from "lodash/isEmpty"

export default ({ api, items, filters }) =>
  async ({ text, filters: activeFilters = {}, isDemo = false }, state) => {
    const { orderNo } = getOrder(state);
    const { query: lastQuery, body: lastBody = {}, headers = {} } = getLastApiCall(state);
    const brands = getItemsBrandsFilter(state);

    if (isDemo) {
      return await api.get({
        url: 'demofund/item',
        query: `?text=${encodeURIComponent(text)}`,
      });
    }

    const searchText = `${text}${activeFilters.name ? ` ${activeFilters.name}` : ''}`;

    const body = { brands, activeFilters };

    const query =
      (lastQuery &&
        objToQS({
          ...QSToObj(lastQuery),
          text: searchText,
          onlyItems: 1,
        })) ||
      `text=${encodeURIComponent(searchText)}&onlyItems=1`;

    try{
      const { data } = await api.post({
        url: `/orders/edit/search${orderNo ? `/${orderNo}` : ''}`,
        query: `?${query}`,
        data: { ...lastBody, ...body },
        headers,
        log: true,
      });

      const { meta = {}, filteredFacets } = data
      const facets = isEmpty(filteredFacets) ? data.facets : filteredFacets

      items.setCharacteristics([]);
      items.setFound(Number(meta['total_found']));
      isEmpty(filteredFacets) && items.setFacets(facets);
      filters.setFacets(facets);
    }
    catch(e){
      console.error(e)
    }
  };
