/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';

const DEFAULT_ACTION = {
  alt: '',
  url: '',
  top: -9999,
  left: -9999,
};

const IMAGE_PREVIEW__MOUSE_ENTER = 'IMAGE_PREVIEW__MOUSE_ENTER';
const IMAGE_PREVIEW__MOUSE_LEAVE = 'IMAGE_PREVIEW__MOUSE_LEAVE';
const IMAGE_ORIGINAL__LOAD = 'IMAGE_ORIGINAL__LOAD';

const createImagePreviewCoords = ({ window, document, tableWrapper, previewWrapper }) => {
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  const tableWrapperRect = tableWrapper.getBoundingClientRect();

  const top =
    tableWrapperRect.top + scrollTop - previewWrapper.offsetHeight + tableWrapperRect.height;
  const left = tableWrapperRect.left + scrollLeft + tableWrapperRect.width;

  return { top, left };
};

export default config => {
  const [virtualImage, setVirtualImage] = useState(null);
  const tablePreviewWrapperRef = config.wrapper;

  useEffect(() => {
    setVirtualImage(new Image());
    updateTableImagePreviewAction(DEFAULT_ACTION);
  }, []); // eslint-disable-line

  const updateTableImagePreviewAction = newTableImagePreviewAction => {
    if (!tablePreviewWrapperRef.current) {
      return;
    }

    tablePreviewWrapperRef.current.style.top = `${newTableImagePreviewAction.top}px`;
    tablePreviewWrapperRef.current.style.left = `${newTableImagePreviewAction.left}px`;
    tablePreviewWrapperRef.current.children[0].alt = newTableImagePreviewAction.alt;
    tablePreviewWrapperRef.current.children[0].src = newTableImagePreviewAction.url;
  };

  const updateImagePreview = action => {
    const { alt, url, name, tableWrapper } = action;

    const previewWrapper = tablePreviewWrapperRef.current;

    if (!previewWrapper) {
      return;
    }

    if (name === IMAGE_PREVIEW__MOUSE_ENTER) {
      updateTableImagePreviewAction({ url, alt });
    }

    if (
      name === IMAGE_ORIGINAL__LOAD &&
      previewWrapper.dataset.actionName === IMAGE_PREVIEW__MOUSE_ENTER
    ) {
      const imagePreviewCoords = createImagePreviewCoords({
        window,
        document,
        tableWrapper,
        previewWrapper,
      });

      updateTableImagePreviewAction({
        alt,
        url,
        top: imagePreviewCoords.top,
        left: imagePreviewCoords.left,
      });
    }

    if (name === IMAGE_PREVIEW__MOUSE_LEAVE) {
      updateTableImagePreviewAction(DEFAULT_ACTION);
    }

    previewWrapper.dataset.actionName = name;
  };

  const onTableImageMouseEnter = _.debounce(params => {
    const { url, tableWrapper } = params;

    updateImagePreview({
      name: IMAGE_PREVIEW__MOUSE_ENTER,
      updateTableImagePreviewAction,
      tablePreviewWrapperRef,
      virtualImage,
      tableWrapper,
      ...params,
    });

    virtualImage.src = url;
    virtualImage.onload = () => {
      updateImagePreview({
        name: IMAGE_ORIGINAL__LOAD,
        updateTableImagePreviewAction,
        tableWrapper,
        ...params,
      });
    };
  }, config.delay);

  const onTableImageMouseLeave = () => {
    updateImagePreview({
      name: IMAGE_PREVIEW__MOUSE_LEAVE,
    });
  };

  const handleTableImageMouseEnter = params => {
    const tableWrapper = params.event.currentTarget;
    onTableImageMouseEnter({ ...params, tableWrapper });
  };

  const handleTableImageMouseLeave = params => {
    onTableImageMouseEnter.cancel();
    onTableImageMouseLeave(params);
  };

  return [handleTableImageMouseEnter, handleTableImageMouseLeave];
};
