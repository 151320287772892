import sortStringNumbers from 'helpers/sortStringNumbers';
import sortDates from 'helpers/sortDates';
import sortStrings from "helpers/sortStrings"
import { AttachmentCell, ResultCell } from '../components/cells';

export default [
  {
    Header: 'Номер',
    id: 'docNo',
    accessor: 'docNo',
    width: 100,
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Дата регистрации',
    accessor: 'claimDate',
    sortMethod: sortDates,
    minWidth: 120
  },
  {
    Header: 'Товар Но.',
    accessor: 'itemNo',
    sortMethod: sortStringNumbers,
    minWidth: 100,
  },
  {
    Header: 'Наименование товара',
    accessor: 'fullDescription',
    minWidth: 200,
  },
  {
    Header: 'Факт. кол-во',
    accessor: 'quantityFactual',
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Кол-во в учт. счете',
    accessor: 'quantitySil',
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Тип',
    id: 'claimType',
    accessor: 'claimTypeName',
    minWidth: 100,
    sortMethod: sortStrings,
  },
  {
    Header: '№ учт. заказа',
    accessor: 'salesInvoiceNo',
  },
  {
    Header: 'УПД',
    accessor: 'upd',
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Дата рез-та',
    accessor: 'resultDate',
    sortMethod: sortDates,
  },
  {
    Header: 'Результат',
    id: 'resultType',
    accessor: 'resultTypeName',
    Cell: ResultCell,
    sortMethod: sortStrings,
  },
  {
    Header: 'Статус',
    accessor: 'claimStatus',
  },
  {
    Header: 'Вложения',
    accessor: 'attachments',
    Cell: AttachmentCell,
    sortable: false
  },
];
