// @ts-nocheck
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import noImage from 'common/assets/images/no-image.png';
import { highlightText } from 'utils/new/strings';
import { formatNumber, toPrimalNumber } from 'utils/new/numbers/format-number';

import ObjCollector from 'services/objCollector';

export const GoodsCollector = new ObjCollector();

export function prepareData({
  type,
  value,
  prevValue,
  itemId,
  lineNo,
  order = 'NEW',
  price = 0,
  count = 0,
  isRub,
}) {
  const newValue = parseFloat(toPrimalNumber(value));
  const priceType = isRub ? 'prices' : 'priceClients';

  if (newValue) {
    GoodsCollector.set(order, type, itemId, (price && Number(value)) || newValue);

    if (price && !GoodsCollector.has(order, priceType, itemId)) {
      GoodsCollector.set(order, priceType, itemId, parseFloat(toPrimalNumber(price)));
    }

    if (count && !GoodsCollector.has(order, 'qtys', itemId)) {
      GoodsCollector.set(order, 'qtys', itemId, Number(count));
    }
  } else if (type === 'qtys' && prevValue) {
    GoodsCollector.set(order, 'qtys', itemId, 0);
    GoodsCollector.set(order, 'act', itemId, 'delete');
    GoodsCollector.set(order, 'lineNo', itemId, lineNo);
  } else {
    GoodsCollector.delete(order, type === 'qtys' ? type : priceType, itemId);
  }
}

export function composeGoodStatuses(good = {}) {
  const statuses = [];

  if (isEmpty(good)) {
    return statuses;
  }

  const onlineReserve = String(good.onlineReserve);

  if (isEmpty(good)) {
    return statuses;
  }

  if (String(good.isNew) === '1') {
    statuses.push({
      icon: 'new',
      className: 'new',
      label: 'Новый товар',
    });
  }

  if (good.existsActions || good.ExistsActions) {
    statuses.push({
      icon: 'sale',
      className: 'sale',
      label: `Участвует в акции - ${good.actionDesc}`,
    });
  }

  if (String(good.EOL) === '1') {
    statuses.push({
      icon: 'stop',
      className: 'stop',
      label: 'Больше не производится',
    });
  }

  if (String(good.name).includes('плохая упаковка')) {
    statuses.push({
      icon: 'damage',
      className: 'damage',
      label: 'Плохая упаковка',
    });
  }

  if (onlineReserve.includes('3') || onlineReserve.includes('1')) {
    statuses.push({
      icon: 'flash',
      className: 'flash-yellow',
      label: 'Дефицитный товар',
    });
  }
  if (onlineReserve.includes('6') || onlineReserve.includes('4')) {
    statuses.push({
      icon: 'flash',
      className: 'flash-red',
      label: 'Просроченный резерв дефицитного товара',
    });
  }

  if (onlineReserve.includes('2')) {
    statuses.push({
      icon: 'rub',
      className: 'rub-green',
      label: 'Товар, за отмену резерва которого будет начислен штраф ',
    });
  }

  if (onlineReserve.includes('5')) {
    statuses.push({
      icon: 'rub',
      className: 'rub-red',
      label: 'Просроченный резерв со штрафом',
    });
  }

  return statuses;
}

const formatGoodAttribute = (attribute, text) => highlightText(attribute, text);

export function formatGoodsBySearch(goods, searchValue) {
  return goods.map(good => ({
    ...good,
    code: searchValue ? formatGoodAttribute(good.code, searchValue) : good.code,
    name: searchValue ? formatGoodAttribute(good.name, searchValue) : good.name,
  }));
}

export function isUniqueInOrders(orders, good, orderNo) {
  if (good.relationType === 'main') {
    return false;
  }

  const lineNo = good[`lineNo_${orderNo}`] || good['lineNo'] || 0;

  const lines = orders.filter(order => Number(good[`lineNo_${order.orderNo}`] || 0) > 0);

  if (orders.length === 1 && lines.length === 1) {
    return false;
  }

  return Number(lineNo) > 0 && lines.length < 2;
}

export function filterGoodsByTags(goods, tags) {
  const tagsWithoutAvail = tags.filter(tag => tag.id !== 'avail');

  if (tagsWithoutAvail.length === 0) {
    return goods;
  }

  return goods.filter(good => {
    const { filterKeys } = good;

    const tagsCheckedParameters = tagsWithoutAvail.map(tag => ({
      ...tag,
      parameters: tag.parameters.filter(param => param.checked),
    }));

    let index = -1;

    const results = {};

    tagsCheckedParameters.forEach(({ caption, parameters }) => {
      parameters.forEach(param => {
        index = filterKeys.indexOf(param.id);

        results[caption] = {
          ...results[caption],
          [param.id]: index > -1,
        };
      });
    });

    const resultValues = Object.values(results);
    return resultValues
      .map(resultValue => Object.values(resultValue).some(result => result === true))
      .every(i => i === true);
  });
}

export function mapGoods(goods) {
  return map(goods, good => ({
    ...good,
    anywhere:
      parseFloat(good.availableMSK1Raw) +
      parseFloat(good.availableOfficeRG) +
      parseFloat(good.transitReserveAvail),
    availableExpected: formatNumber(good.availableExpected, { fractions: 0 }),
    availableExpectedNext: formatNumber(good.availableExpectedNext, { fractions: 0 }),
    cancelReserveCostHr: formatNumber(good.cancelReserveCostHr, { fractions: 2 }),
    code: `${good.id} ${good.vendorPart}`,
    count: good.qty,
    desiredCount: good.desiredQty,
    imageUrl: good.imageUrl ? good.imageUrl : noImage,
    inventoryMSK1Raw: formatNumber(good.inventoryMSK1Raw),
    minPackaged: toPrimalNumber(good.minPackaged),
    desiredPriceRUB: formatNumber(toPrimalNumber(good.desiredPriceRUB), { fractions: 2 }),
    desiredPriceUSD: formatNumber(toPrimalNumber(good.desiredPriceClient), {
      fractions: 2,
    }),
    priceUSD: formatNumber(toPrimalNumber(good.unitPrice), { fractions: 2 }),
    priceRUB: formatNumber(toPrimalNumber(good.unitPriceLCY), {
      fractions: 2,
    }),
    priceListPriceRUB: formatNumber(toPrimalNumber(good.priceLCY), { fractions: 2 }),
    priceListPriceUSD: formatNumber(toPrimalNumber(good.priceClient), {
      fractions: 2,
    }),
    nextPriceRUB: 0,
    nextPriceUSD: 0,
    nextCount: null,
    status: parseFloat(good.transitReserveAvail) > 0 && good.status === 'transit' ? 'transit' : '',
    stock: parseFloat(good.availableClient),
    transitReserveAvail: formatNumber(good.transitReserveAvail),
    warehouseOfficeRGRaw: formatNumber(good.warehouseOfficeRGRaw),
  }));
}

export const isRUB = currency => ['RUB', 'RUR', 'РУБ'].includes(currency);

export const getFormattedPriceRUB = (price, rate) =>
  formatNumber(parseFloat(toPrimalNumber(price)) * rate, { fractions: 2 });

export const getFormattedPriceUSD = (price, rate) =>
  formatNumber(parseFloat(toPrimalNumber(price)) / rate, { fractions: 2 });
