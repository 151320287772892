import { download } from 'common/util';
import alert from 'components/native/alert/function';
import { compose } from 'redux';
import convertFromData from 'helpers/convertFromData';
import { formattingDataClaim, getMessageByClaim, isDoubleClaimAlert } from 'pages/Logistic/helpers';
import { CACHE_NAME } from 'common/config';
import isEmpty from "lodash/isEmpty"

export const getClaims =
  ({ api }) =>
  async () => {
    try {
      const { data } = await api.get({
        url: 'logistic/claims',
      });

      this.load(data.data);
      return data.data;
    } catch (e) {
      return [];
    }
  };

export const getAttachment =
  ({ api }) =>
  async query => {
    try {
      const { blob, filename } = await api.get({
        url: 'logistic/claims/get',
        query,
      });

      download(blob, filename);
    } catch (e) {
      alert(e.message);
    }
  };

export const create =
  ({ api }) =>
  async values => {
    if(isEmpty(values.products)){
      await alert('Добавьте товары в претензию!')
      return false
    }

    const data = compose(convertFromData, formattingDataClaim)(values);

    try {
      const {
        success,
        message,
        data: claimData = {},
      } = await api.post({
        url: 'logistic/add-claim',
        data,
      });

      const { docId } = claimData;

      if (success && docId) {
        await alert('Претензия создана');
        return docId;
      }
      await alert(getMessageByClaim(message));
      return false;
    } catch (e) {
      return false;
    }
  };

export const getPostedOrders =
  ({ list, api }) =>
  async () => {
    list.clear();
    await api.get({
      url: 'logistic/claims/orders-posted',
    });
  };

export const selectOrder = dispatch => async (value, state) => {
  const {
    claims: { getClaims, getProducts },
  } = dispatch;

  const { claims } = state;

  if (claims.length) {
    isDoubleClaimAlert(claims, value);
  } else {
    const claims = await getClaims();
    isDoubleClaimAlert(claims, value);
  }

  await getProducts(value);
};

export const getProducts =
  ({ api, claims }) =>
  async orderId => {
    const payload = await api.get({
      url: `/logistic/products/${orderId || 0}`,
    });

    if(isEmpty(payload?.data?.data)){
      alert('В выбранном заказе нет товарных позиций! Создание претензии невозможно.')
    }

    claims.loadProducts(payload);
  };

export const searchItems =
  ({ api }) =>
  async text => {
    await api.get({
      url: `orders/edit/search`,
      query: `?text=${text}&onlyItems=1`,
    });
  };

export const getItems =
  ({ api }) =>
  async group => {
    await api.get({
      url: `orders/edit/group/${group}`,
      query: `?order=NEW&onlyItems=1`,
      cache: CACHE_NAME,
    });
  };
