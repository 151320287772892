import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderCheckbox, renderFormat } from 'pages/Settings/components/fields';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';

const Form = ({ handleSubmit, priceDays }) => {
  const {
    setup: { onSubmitDispatch },
  } = useDispatch();

  const priceName = useSelector(state => state.setup.priceName);
  const fetchingSetup = useSelector(getEffectLoading('setup', 'onSubmitDispatch'));

  const priceDaysKeys = Object.keys(priceDays);

  return (
    <form onSubmit={handleSubmit(onSubmitDispatch)}>
      <div className="row">
        <div className="col-sm-6">
          <h5>Рассылка прайс-листа</h5>
        </div>
        <div className="col-sm-6 justify-content-end">
          <button type="submit" className="btn btn-danger btn-sm" disabled={fetchingSetup}>
            Сохранить
          </button>
        </div>
      </div>
      <div className="dispatch-container">
        <div>
          <Field name="sendPrice" label="Получать прайс-лист" component={renderCheckbox} />
        </div>
        <div>
          <Field name="priceFormat" label="Формат" component={renderFormat} />
        </div>
        <div>
          Присылаемый прайс: <b>{priceName}</b>
        </div>
      </div>
      {!!priceDaysKeys.length && (
        <div className="dispatch-days">
          Дни рассылки:
          {priceDaysKeys.map(
            day => (
              <Field key={day} name={`priceDays[${day}]`} label={day} component={renderCheckbox} />
            ), // eslint-disable-line
          )}
        </div>
      )}
    </form>
  );
};

const DispatchForm = () => {
  const priceDays = useSelector(state => state.setup.priceDays);

  const DispatchesForm = reduxForm({
    form: 'dispatch',
    initialValues: {
      priceDays,
      priceFormat: useSelector(state => state.setup.priceFormat),
      sendPrice: useSelector(state => state.setup.sendPrice),
    },
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Form);

  return <DispatchesForm priceDays={priceDays} />;
};

export default DispatchForm;
