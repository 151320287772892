import React from 'react';
import { useSelector, useStore } from 'react-redux';
import cn from 'classnames';
import { def } from 'utils/new/types';
import { formatExchangeRate } from 'features/user/utils';

import styles from './exchange-rate.module.scss';

export const ExchangeRate = ({ className = '' }) => {
  const {
    select: {
      user: { getCurrency },
    },
  } = useStore();

  const currency = useSelector(getCurrency);
  const { info, rate } = formatExchangeRate(currency);

  return (
    <div className={cn(styles['exchange-rate'], className)} data-testid="exchange-rate">
      {def(info) && def(rate) && (
        <>
          <span>{info}</span>
          <span>{rate}</span>
        </>
      )}
    </div>
  );
};
