import { matchRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { RouterConfigContext } from 'common/router-config-context';

export default function useMatchedRoute(link) {
  const { pathname } = useLocation();
  const routes = useContext(RouterConfigContext);

  const matchLink = link || pathname;
  const matchedRoutes = matchRoutes(Object.values(routes), matchLink);

  return matchedRoutes.find(({ match }) => match.isExact) || {};
}
