import React, { Fragment, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { clearFields } from 'redux-form';
import classNames from 'classnames';
import Select from 'react-select';
import { formatDate } from 'react-day-picker/moment';
import CustomDayPickerInput from 'components/customDayPickerInput';
import createOptionsFromObj from '../helpers/createOptions';
import { claimTypes } from '../constants';

const options = createOptionsFromObj(claimTypes);

export const RenderInputClaim = props => {
  const {
    input: { value, onChange },
    label,
    handle,
    meta: { touched, error },
  } = props;

  const {
    claims: { selectOrder },
  } = useDispatch();

  const handleChange = ({ target: { value } }) => {
    if (handle instanceof Function) {
      const result = handle(value);
      onChange(result);
    } else {
      onChange(value);
    }
  };

  return (
    <>
      {label && (
        <label className="claims-new__form__label" style={{ marginRight: '12px' }}>
          {label}
          <span className="claims-new__text__required">*</span>
        </label>
      )}
      <div>
        <input
          {...props}
          type="text"
          className={classNames('form-control', 'form-control-sm', 'claims-new__form__text-input')}
          value={value}
          onKeyDown={async e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              await selectOrder(value);
            }
          }}
          onChange={handleChange}
        />
        {touched && error && <span className="text-danger text-danger--small">{error}</span>}
      </div>
    </>
  );
};

export const RenderInputClaimVertical = props => {
  const {
    input,
    label,
    quantity = 'Кол-во',
    handle,
    meta: { touched, error },
  } = props;
  const handleChange = ({ target: { value } }) => {
    if (handle instanceof Function) {
      const result = handle(value);
      input.onChange(result);
    } else {
      input.onChange(value);
    }
  };

  return (
    <div className="claims-new__form__col" style={{ width: '25%' }}>
      <label className={classNames('claims-new__form__label', 'claims-new__form__label--vertical')}>
        {quantity}
        <br />
        {label}
      </label>
      <input
        className={classNames(
          'form-control',
          'form-control-sm',
          'claims-new__form__text-input',
          'claims-new__form__text-input--vertical',
        )}
        value={input.value}
        onChange={handleChange}
      />
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </div>
  );
};

export const RenderDatePicker = props => {
  const {
    input,
    label,
    meta: { touched, error },
  } = props;

  const handleDateChange = date => {
    const foramttedDate = formatDate(date, 'DD.MM.Y', 'ru');
    input.onChange(foramttedDate);
  };

  return (
    <>
      <label className="claims-new__form__label">
        {label}
        <span className="claims-new__text__required">*</span>
      </label>
      <CustomDayPickerInput
        value={input.value}
        onDateInputChange={handleDateChange}
        inputProps={{
          style: { width: 90, marginRight: 10 },
        }}
      />
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </>
  );
};

export const RenderTextAreaClaim = props => {
  const {
    input,
    label,
    handle,
    meta: { touched, error },
  } = props;

  const handleChange = ({ target: { value } }) => {
    if (handle instanceof Function) {
      const result = handle(value);
      input.onChange(result);
    } else {
      input.onChange(value);
    }
  };

  return (
    <>
      <label className="claims-new__form__label">
        {label}
        <span className="claims-new__text__required">*</span>
      </label>
      <textarea
        className={classNames(
          'form-control',
          'form-control-sm',
          'claims-new__form__textarea-input',
          'claims-new__form__textarea-input--vertical',
        )}
        value={input.value}
        onChange={handleChange}
      />
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </>
  );
};

export const RenderSelectClaim = props => {
  const {
    input,
    disabled,
    meta: { touched, error },
  } = props;

  return (
    <>
      <Select
        {...props}
        clearable
        className="claims-new__form__text-input"
        disabled={disabled}
        options={options}
        onChange={input.onChange}
        value={input.value}
        searchable={false}
      />
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </>
  );
};

export const RenderInputFile = props => {
  const {
    input,
    formName,
    meta: { touched, error, warning },
  } = props;

  const defaultFileName = 'Выберите файл';
  const [fileName, setFileName] = useState(defaultFileName);
  const [fileEmpty, setFileEmpty] = useState(true);
  const fileRef = React.createRef();
  const hasError = (error && error.length) || (warning && warning.length);

  const handleClick = () => {
    fileRef.current.value = '';
    props.clearFields(formName, false, false, [input.name]);
    fileRef.current.click();
  };

  const deleteFileHandle = e => {
    e.preventDefault();
    fileRef.current.value = '';
    props.clearFields(formName, false, false, [input.name]);
  };

  useEffect(() => {
    setFileEmpty(true);
    setFileName(defaultFileName);
  }, [input.name]);

  useEffect(() => {
    if (!hasError) {
      if (
        fileRef.current.files.length &&
        fileName !== fileRef.current.files[0].name &&
        input.value
      ) {
        setFileName(fileRef.current.files[0].name);
        setFileEmpty(false);
      }
      if (!fileRef.current.files.length && fileName !== defaultFileName) {
        setFileEmpty(true);
        setFileName(defaultFileName);
      }
    }
    if (hasError) {
      fileRef.current.value = '';
      setFileEmpty(true);
      setFileName(defaultFileName);
    }
  }, [fileName, fileRef, hasError]);

  return (
    <div className="claims-new__form__file-input">
      <div>
        <div className="file-description">
          <div
            className={fileEmpty ? 'file-empty' : 'file-not-empty'}
            onClick={handleClick}
            title={fileName}
          >
            {fileName}
          </div>
          {!fileEmpty && (
            <span className="file-size">
              <div onClick={deleteFileHandle} className="delete-file" />
            </span>
          )}
        </div>
        <div className="file-container">
          <input {...input} type="file" value={null} ref={fileRef} />
        </div>
        {touched &&
          ((error && <span className="text-danger text-danger--small">{error}</span>) ||
            (warning && <span className="text-danger text-danger--small">{warning}</span>))}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  clearFields,
};
export const RenderInputFileClaim = connect(null, mapDispatchToProps)(RenderInputFile);
