import { connect } from 'react-redux';
import { changeNotifySettings } from 'components/helpers/layout';
import BrowserWarning from 'components/layout/browser-warning';

const mapStateToProps = state => ({
  isDeprecationBrowser: state.notifySettings.isDeprecationBrowser,
});

const mapDispatchToProps = {
  changeNotifySettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowserWarning);
