import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tabs = ({ labels = [], defaultTab, children }) => {
  const [tab, setTab] = useState(defaultTab || labels[0]);

  return (
    <section className="tab__container">
      <div className="tab__header">
        <span className="tab__items">
          {labels.map(label => (
            <span
              className={classNames('tab__item', {
                'tab__item--active': tab !== label,
              })}
              key={label}
              onClick={() => setTab(label)}
            >
              {label}
            </span>
          ))}
        </span>
      </div>
      {children(tab)}
    </section>
  );
};

Tabs.propTypes = {
  labels: PropTypes.array.isRequired,
  defaultTab: PropTypes.string,
};

export default Tabs;
