import React, {useEffect} from 'react'
import classNames from 'classnames'
import {useDispatch, useSelector} from "react-redux"
import {getEffectLoading} from "common/selectors"
import Loader from "components/loader"

function ContactsSections(props) {
  const { contactsSections = [], columns = 1, wrapperStyle = {} } = props

  const isContactsFetching = useSelector(getEffectLoading('user', 'getUserContacts'));

  const {
    user: { getUserContacts },
  } = useDispatch();

  useEffect(() => {
    if(!contactsSections.length){
      getUserContacts()
    }
  }, [contactsSections.length])

  return (
    <div
      className="contacts"
      style={wrapperStyle}
    >
      {isContactsFetching && <Loader>Контакты загружаются</Loader>}
      {!isContactsFetching && contactsSections.map(contactSection => (
        <div
          key={contactSection.id}
          className={classNames('contact', 'col-md-12', {
            'col-lg-4': columns === 3,
            'col-lg-6': columns === 2,
            'col-lg-12': columns === 1,
          })}
        >
          {contactSection.children.map(contact => (
            <div key={contact.id} className="contact__section">
              <label className="contact__section__label">{contact.label}</label>
              <p className="contact__section__value">{contact.description}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default ContactsSections;
