import { useSelector, useStore } from 'react-redux';
import React from 'react';
import { getFormValue } from 'pages/Orders/selectors';

const CityField = ({ label, show = true }) => {
  const {
    select: { order },
  } = useStore();

  const { city: orderCity, shipmentFunc } = useSelector(order.getHeader);

  const endpoint = useSelector(getFormValue('endpoint')) || '';
  const endpointCity = endpoint.split(' | ').pop();
  const city = Number(shipmentFunc) !== 1 && (endpointCity || orderCity);

  if (!show || !city) {
    return null;
  }

  return (
    <div>
      <label>{label}</label> <span>{city}</span>
    </div>
  );
};

export default CityField;

