import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import formConfig from '../constants/form-config';
import { renderFile } from 'components/fields';

const SpecialPricesFormStepThree = ({ handleSubmit, previousPage }) => (
  <form onSubmit={handleSubmit}>
    <div className="special-prices__container">
      <div className="row">
        <div className="col-sm-6">
          <label>Загрузите дополнительный файл</label>
          <Field name="otherFile" label="Файл" component={renderFile} />
        </div>
      </div>
      <div className="row special-prices__container__buttons">
        <button type="button" className="btn btn--rect btn-outline-danger" onClick={previousPage}>
          Назад
        </button>
        <button type="submit" className="btn btn--rect btn-outline-danger">
          Отправить
        </button>
      </div>
    </div>
  </form>
);

SpecialPricesFormStepThree.propTypes = {
  previousStep: PropTypes.func.isRequired,
};

export default reduxForm(formConfig)(SpecialPricesFormStepThree);
