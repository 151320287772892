/* eslint-disable no-underscore-dangle */

import { isProdEnv } from 'common/config';
import { init } from '@rematch/core';
import persistPlugin, { getPersistor } from '@rematch/persist';
import selectPlugin from '@rematch/select';
import loadingPlugin from '@rematch/loading';
import immerPlugin from '@rematch/immer';

// import * as reducers from './reducers';

import injectSelector from './plugins/injectSelector';
import actionTypePlugin from './plugins/actionType';
import { persistConfig, nestedPersistConfig } from './configs/persist';
import { reducers } from './reducers';
import middlewares from './middlewares';
import * as models from './models';
import {initStateWithPrevTab} from "redux-state-sync"

const actionsBlacklist = [ 'api/start', 'api/end',  'loading/show', 'loading/hide', 'api/log', 'redux-form', 'persist']

/* eslint-enable no-underscore-dangle */
// noinspection JSUnusedLocalSymbols,JSUnusedLocalSymbols
const store = init({
  models,
  redux: {
    reducers,
    rootReducers: { 'user/logout': (state, action) => undefined },
    middlewares,
    devtoolOptions: {
      disable: isProdEnv,
      actionsBlacklist,
    },
  },
  plugins: [
    persistPlugin(persistConfig, nestedPersistConfig),
    selectPlugin(),
    loadingPlugin(),
    immerPlugin(),
    actionTypePlugin,
    injectSelector,
  ],
});

initStateWithPrevTab(store)

const persistor = getPersistor();
const selector = store.select;

export { store, persistor, selector, actionsBlacklist };
