import React from 'react';
import { Link } from 'react-router-dom';
import { def } from 'common/util';
import sortDates from '../../../../../helpers/sortDates';
import sortStringNumbers from '../../../../../helpers/sortStringNumbers';
import { formattingId } from '../../../helper/util';
import colorsStatusTZ from '../../../constants/colors-status-tz';

export const TZColumns = [
  {
    Header: 'Номер',
    accessor: 'no',
    Cell: row =>
      (def(row) && (
        <Link
          className={'link-detail-special-price'}
          to={`/vad/tz/detail/${formattingId(row.value)}`}
        >
          {row.value}
        </Link>
      )) ||
      null,
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Дата обращения',
    accessor: 'createDate',
    sortMethod: sortDates,
  },
  {
    Header: 'Конечный заказчик',
    accessor: 'customerName',
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: row =>
      (def(row) && <span className={colorsStatusTZ[row.value]}>{row.value}</span>) || null,
  },
  {
    Header: 'Дата обработки',
    accessor: 'closeDate',
  },
];
