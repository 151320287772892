import React, { useMemo } from 'react';
import OrderColumns from '../../common/columns/demofond/orderColumns';
import { demoCollector } from 'pages/VAD/constants';
import sumBy from 'lodash/sumBy';
import ReactTable from 'react-table-v6';

const DemofondTable = ({ isDemoOrder }) => {
  const columns = useMemo(() => OrderColumns(isDemoOrder), [isDemoOrder]);
  const data = Object.values(demoCollector.get());
  return (
    <div className="table__container">
      <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={300}
        pageSize={200}
        minRows={1}
        showPagination={data.length > 200}
      />
      <div className="count_field">
        <span className="count_field--desc">Итого:</span>
        <span className="count_field--count">{sumBy(data, row => Number(row.qty))}</span>
      </div>
    </div>
  );
};

export default DemofondTable;
