const CATEGORIES = {
  Авто: {
    id: 0,
    name: 'Авто',
    icon: 'auto',
  },
  'Автомобильная техника и электроника': {
    id: 1,
    name: 'Автомобильная техника и электроника',
    icon: 'auto-technics',
  },
  'Аудио-видео техника': {
    id: 2,
    name: 'Аудио видео техника',
    icon: 'audio',
  },
  'Бытовая техника': {
    id: 3,
    name: 'Бытовая техника',
    icon: 'appliances',
  },
  'Восстановленный товар': {
    id: 4,
    name: 'Восстановленный товар',
    icon: 'reconstruction',
  },
  Зоотовары: {
    id: 5,
    name: 'Зоотовары',
    icon: 'animals',
  },
  'Источники бесперебойного питания': {
    id: 6,
    name: 'Источники бесперебойного питания',
    icon: 'ibp',
  },
  Канцтовары: {
    id: 7,
    name: 'Канцтовары',
    icon: 'stationery',
  },
  'Комплектующие для компьютеров': {
    id: 8,
    name: 'Комплектующие для компьютеров',
    icon: 'components',
  },
  Компьютеры: {
    id: 9,
    name: 'Компьютеры',
    icon: 'computer',
  },
  Мебель: {
    id: 10,
    name: 'Мебель',
    icon: 'chair',
    children: [],
  },
  'Мобильная связь': {
    id: 11,
    name: 'Мобильная связь',
    icon: 'phone',
  },
  Ноутбуки: {
    id: 12,
    name: 'Ноутбуки',
    icon: 'laptop',
  },
  'Оборудование для геймеров': {
    id: 13,
    name: 'Оборудование для геймеров',
    icon: 'game',
  },
  'Периферия и аксессуары': {
    id: 14,
    name: 'Периферия и аксессуары',
    icon: 'monitor',
  },
  'Плохая упаковка': {
    id: 15,
    name: 'Плохая упаковка',
    icon: 'damage',
  },
  'Портативная электроника': {
    id: 16,
    name: 'Портативная электроника',
    icon: 'headphones',
  },
  'Программное обеспечение': {
    id: 17,
    name: 'Программное обеспечение',
    icon: 'software',
  },
  'Продукты питания': {
    id: 18,
    name: 'Продукты питания',
    icon: 'food',
  },
  'Расходные материалы': {
    id: 19,
    name: 'Расходные материалы',
    icon: 'cartridge',
  },
  'Ремонт, строительство, дача': {
    id: 20,
    name: 'Ремонт строительство дача',
    icon: 'repairs',
  },
  'Серверы и схд': {
    id: 21,
    name: 'Серверы и СХД',
    icon: 'server',
  },
  'Сетевое оборудование': {
    id: 22,
    name: 'Сетевое оборудование',
    icon: 'network',
  },
  'Системы безопасности и умный дом': {
    id: 23,
    name: 'Системы безопасности и умный дом',
    icon: 'camera',
  },
  'Скс и инженерное оборудование': {
    id: 24,
    name: 'СКС и инженерное оборудование',
    icon: 'cable',
  },
  'Спорт, активный отдых, хобби': {
    id: 25,
    name: 'Спорт активный отдых хобби',
    icon: 'sport',
  },
  'Сувениры и бизнес-подарки': {
    id: 26,
    name: 'Сувениры и бизнес подарки',
    icon: 'gift',
  },
  'Телевизоры и аксессуары': {
    id: 27,
    name: 'Телевизоры и аксессуары',
    icon: 'tv',
  },
  Телекоммуникации: {
    id: 28,
    name: 'Телекоммуникации',
    icon: 'telecom',
  },
  'Хозяйственные товары': {
    id: 29,
    name: 'Хозяйственные товары',
    icon: 'clean',
  },
  'Цифровое фото и видео': {
    id: 30,
    name: 'Цифровое фото и видео',
    icon: 'photo',
  },
  'Электроинструменты и садовая техника': {
    id: 31,
    name: 'Электроинструменты и садовая техника',
    icon: 'tools',
  },
};

const CATEGORIES_TABS = [
  {
    id: 0,
    name: 'Категории товаров',
    elementId: 'categories'
  },
  {
    id: 1,
    name: 'Избранные категории',
    elementId: 'fav'
  },
];

export { CATEGORIES, CATEGORIES_TABS };

// const CATEGORIES = [
//   {
//     id: 0,
//     name: 'Автомобильная техника и электроника',
//     icon: 'auto',
//   },
//   {
//     id: 1,
//     name: 'Аудио видео техника',
//     icon: 'audio',
//   },
//   {
//     id: 2,
//     name: 'Бытовая техника',
//     icon: 'appliances',
//   },
//   {
//     id: 3,
//     name: 'Источники бесперебойного питания',
//     icon: 'ibp',
//   },
//   {
//     id: 4,
//     name: 'Канцтовары',
//     icon: 'stationery',
//   },
//   {
//     id: 5,
//     name: 'Комплектующие для компьютеров',
//     icon: 'components',
//   },
//   {
//     id: 6,
//     name: 'Компьютеры',
//     icon: 'computer',
//   },
//   {
//     id: 7,
//     name: 'Мебель',
//     icon: 'chair',
//     children: [
//       {
//         id: 0,
//         name: 'Мебель другая',
//         children: [
//           {
//             id: 0,
//             name: 'Тумбы',
//           },
//           {
//             id: 1,
//             name: 'Шкафы',
//           },
//         ],
//       },
//       {
//         id: 1,
//         name: 'Мебельные аксессуары',
//         children: [
//           {
//             id: 0,
//             name: 'Зеркала',
//           },
//           {
//             id: 1,
//             name: 'Аксессуары для кресел',
//           },
//           {
//             id: 2,
//             name: 'Вешалки',
//           },
//           {
//             id: 3,
//             name: 'Коврики  защитные',
//           },

//           {
//             id: 4,
//             name: 'Пдставки для обуви и обувницы',
//           },
//           {
//             id: 5,
//             name: 'Рамки для сертификатов и дипломов',
//           },
//         ],
//       },
//       {
//         id: 2,
//         name: 'Металлическая обувь',
//         children: [
//           {
//             id: 0,
//             name: 'Шкафчики и брелки для ключей',
//           },
//           {
//             id: 1,
//             name: 'Стеллажи',
//           },
//           {
//             id: 2,
//             name: 'Шкафы архивные',
//           },
//           {
//             id: 3,
//             name: 'Шкафы для одежды',
//           },
//         ],
//       },
//       {
//         id: 3,
//         name: 'Сейфы',
//         children: [
//           {
//             id: 0,
//             name: 'Сейфы офисные',
//           },
//         ],
//       },
//       {
//         id: 4,
//         name: 'Стулья',
//         children: [
//           {
//             id: 0,
//             name: 'Стулья на заказ',
//           },
//           {
//             id: 1,
//             name: 'Стулья офисные',
//           },
//         ],
//       },
//       {
//         id: 5,
//         name: 'Столы',
//         children: [
//           {
//             id: 0,
//             name: 'Столы (составные части бандлов)',
//           },
//           {
//             id: 1,
//             name: 'Столы для компьютеров',
//           },
//           {
//             id: 2,
//             name: ' Столы письменные',
//           },
//         ],
//       },
//       {
//         id: 6,
//         name: 'Текстиль для дома',
//         children: [
//           {
//             id: 0,
//             name: 'Одеяла',
//           },
//           {
//             id: 1,
//             name: 'Скатерти',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 8,
//     name: 'Мобильная связь',
//     icon: 'phone',
//   },
//   {
//     id: 9,
//     name: 'Ноутбуки',
//     icon: 'laptop',
//   },
//   {
//     id: 10,
//     name: 'Оборудование для геймеров',
//     icon: 'game',
//   },
//   {
//     id: 11,
//     name: 'Периферия и аксессуары',
//     icon: 'monitor',
//   },
//   {
//     id: 12,
//     name: 'Плохая упаковка',
//     icon: 'box',
//   },
//   {
//     id: 13,
//     name: 'Портативная электроника',
//     icon: 'headphones',
//   },
//   {
//     id: 14,
//     name: 'Программное обеспечение',
//     icon: 'software',
//   },
//   {
//     id: 15,
//     name: 'Продукты питания',
//     icon: 'food',
//   },
//   {
//     id: 16,
//     name: 'Расходные материалы',
//     icon: 'cartridge',
//   },
//   {
//     id: 17,
//     name: 'Ремонт строительство дача',
//     icon: 'repairs',
//   },
//   {
//     id: 18,
//     name: 'Серверы и схд',
//     icon: 'server',
//   },
//   {
//     id: 19,
//     name: 'Сетевое оборудование',
//     icon: 'network',
//   },
//   {
//     id: 20,
//     name: 'Системы безопасности и умный дом',
//     icon: 'camera',
//   },
//   {
//     id: 21,
//     name: 'Скс и инженерное оборудование',
//     icon: 'cable',
//   },
//   {
//     id: 22,
//     name: 'Спорт активный отдых хобби',
//     icon: 'sport',
//   },
//   {
//     id: 23,
//     name: 'Сувениры и бизнес подарки',
//     icon: 'gift',
//   },
//   {
//     id: 24,
//     name: 'Телевизоры и аксессуары',
//     icon: 'tv',
//   },
//   {
//     id: 25,
//     name: 'Телекоммуникации',
//     icon: 'telecom',
//   },
//   {
//     id: 26,
//     name: 'Хозяйственные товары',
//     icon: 'clean',
//   },
//   {
//     id: 27,
//     name: 'Цифровое фото и видео',
//     icon: 'photo',
//   },
// ];

// const CATEGORIES_TABS = [
//   {
//     id: 0,
//     name: 'Категории',
//   },
//   {
//     id: 1,
//     name: 'Избранные категории',
//   },
// ];

// export { CATEGORIES, CATEGORIES_TABS };
