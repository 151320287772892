import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './heading.module.scss';

const HEADING_LEVEL = {
  xl: 1,
  lg: 2,
  md: 3,
  sm: 4,
  xs: 5,
};

/**
 * Renders a <Heading /> component
 * @component
 * @example
 *
 * return (
 *   <Heading />
 * )
 */
export const Heading = ({ className = '', children, size = 'xl' }) => {
  const classes = cn(styles['heading'], className, {
    [styles['heading_size_xs']]: size === 'xs',
    [styles['heading_size_sm']]: size === 'sm',
    [styles['heading_size_md']]: size === 'md',
    [styles['heading_size_lg']]: size === 'lg',
    [styles['heading_size_xl']]: size === 'xl',
  });

  const headingProps = {
    className: classes,
    'data-testid': 'heading',
  };

  return createElement(`h${HEADING_LEVEL[size]}`, headingProps, children);
};

Heading.defaultProps = {
  className: '',
  children: null,
  size: 'xl',
};

Heading.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * children
   */
  children: PropTypes.string,
  /**
   * 'xs' | 'sm' | 'md' | 'lg' | 'xl'
   */
  size: PropTypes.string,
};
