import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const CellPhoneSmall = ({
  className,
  color = '#236192',
  name,
  width = '12',
  height = '12',
  viewBox = '0 0 12 12',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M3.86539 8.13446C6.02913 10.2982 8.54375 10.7076 9.42094 10.7076C10.0642 9.83036 10.4151 9.12861 10.7075 8.48533C10.2397 7.90054 8.89463 7.25726 8.30983 7.08182L7.43264 7.90054C7.08176 7.78358 6.26305 7.54966 5.32737 6.61399C4.3917 5.67832 4.21626 4.91808 4.0993 4.5672L4.91802 3.74849C4.6841 2.8713 4.04083 1.93563 3.51451 1.35083C2.87123 1.58475 2.16948 1.9941 1.29229 2.63738C1.23381 3.45609 1.70164 6.02919 3.86539 8.13446Z"
        stroke={color}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

CellPhoneSmall.defaultProps = {
  className: '',
  color: '#236192',
  width: '12',
  height: '12',
  viewBox: '0 0 12 12',
};

CellPhoneSmall.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
