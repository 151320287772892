import React, { memo, useMemo, useLayoutEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getDisplayName } from 'common/util';

/**
 * HOC to add drag ability for Table columns with storing column position for table name
 *
 * @param {React.ComponentType} TableComponent
 * @returns {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}>  & React.RefAttributes<unknown>>}
 */
const withDrag = TableComponent => {
  const Draggable = memo(function Draggable(props) {
    const { columns, model, forwardedRef, ...rest } = props;
    const { columnsOrder } = rest;

    const dispatch = useDispatch();

    const { current: column } = useRef({
      draggedPos: null,
      draggedId: null,
    });

    useLayoutEffect(() => {
      setTimeout(() => {
        const headers = [...document.querySelectorAll('.rt-th--draggable')];

        headers.forEach(header => {
          header.setAttribute('draggable', true);

          header.ondragstart = e => {
            e.stopPropagation();

            column.draggedId = e.currentTarget.getAttribute('data-id');
            column.draggedPos = columnsOrder[column.draggedId];
          };

          header.ondrag = e => e.stopPropagation();
          header.ondragend = e => e.stopPropagation();
          header.ondragover = e => e.preventDefault();

          header.ondragover = e => {
            e.preventDefault();
            const { currentTarget } = e;
            const id = currentTarget.getAttribute('data-id');

            if (columns.draggedId !== id) currentTarget.style.border = '1px solid #dbdbdb';
          };

          header.ondragleave = e => {
            const { currentTarget } = e;

            currentTarget.style.border = 'none';
          };

          header.ondrop = e => {
            e.preventDefault();

            const { currentTarget } = e;
            const id = currentTarget.getAttribute('data-id');
            const position = columnsOrder[id];

            currentTarget.style.border = 'none';

            dispatch[model]?.orderPersist({
              [id]: column.draggedPos,
              [column.draggedId]: position === column.draggedPos ? position + 0.1 : position,
            });
          };
        });
      }, 50);
    }, [columnsOrder, columns, model]); // eslint-disable-line

    const reorderedColumns = columns
      .map(column =>
        column.id in columnsOrder ? { ...column, position: columnsOrder[column.id] } : column,
      )
      .sort((a, b) => a.position - b.position);

    const getTheadThProps = useCallback(
      (state, info, column) => ({
        className: 'rt-th--draggable',
        'data-id': column.id,
      }),
      [],
    );

    return (
      <TableComponent
        {...rest}
        ref={forwardedRef}
        columns={reorderedColumns}
        getTheadThProps={getTheadThProps}
      />
    );
  });

  Draggable.propTypes = {
    model: PropTypes.string.isRequired,
    columnsOrder: PropTypes.object.isRequired,
  };
  Draggable.displayName = `withDrag${getDisplayName(TableComponent)}`;

  return React.forwardRef((props, ref) => <Draggable {...props} forwardedRef={ref} />);
};

export default withDrag;
