import React from 'react'
import {useDispatch} from "react-redux"

export default function Registration(row){
  const {events: { watch }} = useDispatch()

  if(!row){
    return null
  }

  const { original: event } = row

  return event.status !== 'Открыта'
    ? 'Закончена'
    :  (<a
        rel="noopener noreferrer"
        href={event.link}
        target="_blank"
        onClick={() => {
          watch(event.no)
        }}
      >
        {event.type === '1' ? 'Зарегистрироваться' : 'Подробнее'}
      </a>);
}
