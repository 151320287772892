import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { hasServerGroupItems } from 'pages/Orders/selectors';
import { getEffectLoading } from 'common/selectors';
import { OrderEditContext } from 'pages/Orders/components/order-edit/context';
import useContextSelector from 'hooks/context/useContextSelector';
import { OrderViewContext } from 'pages/Orders/constances';

const ItemsSpecification = () => {
  const hasServerItems = useSelector(hasServerGroupItems);
  const fetching = useSelector(getEffectLoading('order', 'getSpecification'));

  const {
    order: { getSpecification },
  } = useDispatch();

  const { id } = useParams();
  const  { instance }  = useContext(OrderViewContext);

  const group = useContextSelector(OrderEditContext, state => state.group);
  const searchText = useContextSelector(OrderEditContext, state => state.searchText);

  if (!hasServerItems || group || searchText) {
    return null;
  }

  return (
    <div className={classNames('align-items-center', 'item-spec-container')}>
      {(fetching && (
        <div className="container-icon">
          <i className="loader loader--small" />
        </div>
      )) || (
        <i
          className="icon icon-specs enabled"
          onClick={async () => await getSpecification({ id, instance })}
          title="Выгрузить спецификацию серверов"
        />
      )}
    </div>
  );
};

export default ItemsSpecification;
