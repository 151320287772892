import React, { useMemo, useCallback, useRef } from 'react';
import ReactTable from 'react-table-v6';
import ChoosedTHead from 'pages/Settings/components/bindings/components/choosed-head';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import { linkedColumns } from 'pages/Settings/components/bindings/columns';
import {getCorrespondence, getFetchingBindingItems} from 'pages/Settings/selectors'
import withMemo from 'hoc/withMemo';

const tHeadProps = () => ({ className: 'rt-thead--linked' });

const GoodsList = ({ choosed }) => {
  const fetchingCorrespondence = useSelector(getFetchingBindingItems);
  const fetchingLinked = useSelector(getEffectLoading('bindings', 'chooseRow'));
  const searchingLinked = useSelector(getEffectLoading('bindings', 'handleSearchByML'));
  const showCurrent = useSelector(state => state.bindings.showCurrent);
  const current = useSelector(state => state.bindings.current);
  const correspondence = useSelector(getCorrespondence);

  const { bindings } = useDispatch();

  const mlRef = useRef();

  const memoLinkedColumns = useMemo(
    () => linkedColumns(correspondence.length),
    [correspondence.length],
  );

  const handleSaveCorrespondence = useCallback(async () => {
    await bindings.saveCorrespondence(choosed.no);
  }, [choosed.no]);

  const handleSearchByML = async () => {
    await bindings.searchByML(mlRef.current.value);
  };

  const clearML = e => {
    e.preventDefault();
    const val = mlRef.current.value;

    if (val) {
      mlRef.current.value = '';
      bindings.clearML();
    }
  };

  return (
    <div>
      <div className="goods-actions">
        <h6>Товары Merlion</h6>
      </div>
      <div className="goods-actions">
        <input
          type="text"
          className="form-control form-control-sm"
          ref={mlRef}
          placeholder="Поиск по коду ML"
        />{' '}
        {/* eslint-disable-line */}
        <a href="" onClick={clearML} className="clear-filters">
          <i className="icon icon-remove-filters" title="Очистить фильтр" />
        </a>
        <button
          className="btn btn--rect btn-outline-danger"
          onClick={handleSearchByML}
          disabled={searchingLinked}
        >
          Найти
        </button>
      </div>
      <div className="goods-container">
        <div className="linked-container">
          {fetchingLinked ? (
            <i className="loader" />
          ) : (
            <ReactTable
              data={showCurrent && current || correspondence}
              columns={memoLinkedColumns}
              loadingText={<i className="loader loader--outside" />}
              minRows={1}
              TheadComponent={ChoosedTHead}
              getTheadProps={tHeadProps}
              showPagination={false}
              defaultPageSize={5000}
              pageSize={5000}
              noDataText="ничего не найдено"
              loading={fetchingCorrespondence}
            />
          )}
        </div>
        {!!correspondence.length && !showCurrent && (
          <div className="correspond-actions text-right">
            <button
              className="btn btn--rect btn-danger"
              onClick={handleSaveCorrespondence}
              disabled={fetchingCorrespondence}
            >
              Сохранить привязки
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withMemo(['choosed'])(GoodsList);
