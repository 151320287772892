import React, { forwardRef } from 'react';
import { PRODUCT_NOT_FOUND } from 'common/config';
import classnames from 'classnames';
import { useDesign } from 'hooks/new';
import PreviewImage from 'pages/Orders/components/common/columns/components/preview-image';
import { Link, useParams } from 'react-router-dom';
import { checkAddItemCondition, trackingCardPage } from 'pages/Orders/helpers';
import EditableField from 'pages/Orders/components/common/columns/components/EditableField';
import {
  SET_ITEM_DESIRED_CLIENT_PRICE,
  SET_ITEM_DESIRED_PRICE,
  SET_ITEM_DESIRED_QTY,
  setItemDesiredQty,
} from 'pages/Orders/action';
import alert from 'components/native/alert/function';
import CheckedTextField from 'pages/Orders/components/common/columns/components/checked-text-field';
import { validatePrice, checkMaxQty, dateUTCSplit, hasOrderEditAccess } from 'common/util';
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getQtyLimit } from 'common/selectors/get-qty-limit';
import { prepareData } from 'pages/Orders/components/order-edit/helpers/prepareData';
import { getIsRegionalShipment, getUser } from 'pages/Orders/selectors';
import useSelectGroup from 'pages/Orders/hooks/useSelectGroup';

export const IdCell = props => {
  const product = props.original || props.row.original;
  const isBadPack = parseInt(product.linkedItemQty) > 0;
  const isActionsExists = product.existsActions;
  const notFound = PRODUCT_NOT_FOUND === product.name;

  const [isPilot] = useDesign();

  return (
    <>
      <span className={classnames({ 'orders__table__row__link--not-found': notFound })}>
        {product.id}
      </span>
      {!isPilot && isBadPack && (
        <i className="icon icon-product_bad_pack" title="Есть товар в плохой упаковке" />
      )}
      {!isPilot && isActionsExists && product.actionWWW && (
        <a target="_blank" rel="noopener noreferrer" href={product.actionWWW}>
          <i className="icon icon-product_action" title={`Акция - ${product.actionDesc}`} />
        </a>
      )}
    </>
  );
};

export const ImageCell = wrapper => props => {
  const row = props.original || props.row.original;
  if (!row.imageUrl) return null;

  return <PreviewImage imageAlt={row.name} smallImageUrl={row.imageUrl} wrapper={wrapper} />;
};

export const NameCell = (order, isPreorder) => props => {
  const row = props.original || props.row.original;
  const inOrder= Object.hasOwn(order, "orderNo");
  const isEmpty =
    !inOrder &&
    Number(row.availableClient) === 0 &&
    Number(row.availableMSK1) === 0;

  const classes = classnames("orders__table__row__link",
    { ["product-is-empty"]: isEmpty }
    );

  if (row) {
    const isEOL = row.EOL === '1';
    const isNew = row.isNew === '1';
    const isTransit = row.transitReserveAvail > 0;
    const isPreorderAvail = row.preorderAvail > 0;
    const notFound = PRODUCT_NOT_FOUND === row.name;

    return !isPreorder && !notFound ? (
      <Link
        className={classes}
        to={{
          pathname: `/products/view/${row.id}`,
          state: {
            orderNo: order?.orderNo || 'NEW',
          },
        }}
        target="_blank"
        onClick={trackingCardPage(row.id, row.value, 'СписокКаталогТоваров')}
      >
        {row.name}
        {isNew && (
          <span title="Новый товар" className="badge badge-success">
            New
          </span>
        )}
        {isEOL && (
          <span title="Снят с производства" className="badge badge-secondary">
            EOL
          </span>
        )}
        {isTransit && (
          <span title="Возможен резерв из транзита" className="icon icon-transit-icon" />
        )}
        {isPreorderAvail && (
          <span title="Доступен к предзаказу" className="icon icon-predzakaz-icon" />
        )}
      </Link>
    ) : (
      <span className={classnames({ 'orders__table__row__link--not-found': notFound })}>
        {row.name}
      </span>
    );
  }

  return null;
};

const QtyCell = (props, ref) => {
  const isPreOrder = useIsPreorder();
  const qtyLimit = useSelector(getQtyLimit);
  const isRegionalShipment = useSelector(getIsRegionalShipment);
  const {
    items: { handleDelete },
  } = useDispatch();
  const { id } = useParams();

  const row = props;
  const inOrder = row.inOrder;
  const { id: itemId, index, lineNo, availableClient, availableMSK1 } = row.original;

  const isEmpty =
    !inOrder &&
    Number(availableClient) === 0 &&
    Number(availableMSK1) === 0;

  const classes = classnames(
    "items-editable",
    "items-editable__qty",
    { ["product-is-empty"]: isEmpty }
  );

  if (!row || row.original.default) {
    return null;
  }

  const handleBlur = (state, dispatch) => e => {
    e.persist();
    const { value: targetValue } = e.target;
    const {
      value,
      original: { code1, salesLimitType, minPackaged, id, index },
    } = row;
    const argsToCheck = {
      value: targetValue,
      salesLimitType,
      minPackaged,
      code: code1,
      qtyLimit,
    };

    if (checkMaxQty(argsToCheck)) {
      dispatch(
        setItemDesiredQty({
          id: !isPreOrder ? id : index,
          value,
        }),
      );

      return;
    }

    if (
      isRegionalShipment &&
      !checkAddItemCondition(targetValue, minPackaged, salesLimitType)
    ) {
      dispatch(
        setItemDesiredQty({
          id: !isPreOrder ? id : index,
          value,
        }),
      );

      return;
    }
    prepareData(row, state, isPreOrder);
  };

  return (
    <div className="d-inline-flex">
      <EditableField
        key={!isPreOrder ? itemId : index}
        row={row}
        column={props.column}
        actionType={SET_ITEM_DESIRED_QTY}
        className={classes}
        blur={handleBlur}
      />
      &nbsp;&nbsp;
      {lineNo && (
        <i
          className="icon icon-delete enabled"
          title="Удалить"
          onClick={async () => await handleDelete({ item: row.original, id })}
        />
      )}
    </div>
  );
};

export const DesiredQtyCell = forwardRef(QtyCell);

const PriceCell = (props, ref) => {
  const {
    select: { user },
  } = useStore();
  const isPreorder = useIsPreorder();
  const { Rate } = useSelector(user.getCurrency);

  const row = props;
  const inOrder = row.inOrder;
  const { id: itemId, index, priceLCY, availableClient, availableMSK1 } = row.original;

  const isEmpty =
    !inOrder &&
    Number(availableClient) === 0 &&
    Number(availableMSK1) === 0;

  const classes = classnames(
    "items-editable",
    "items-editable--right",
    { ["product-is-empty"]: isEmpty }
  );

  if (!row || row.original.default) {
    return null;
  }

  return (
    <EditableField
      ref={ref}
      key={!isPreorder ? itemId : index}
      row={row}
      column={props.column}
      actionType={SET_ITEM_DESIRED_PRICE}
      className={classes}
      blur={(state, dispatch) => e => {
        e.persist();
        const _this = e.target;

        if (!_this.value.length) {
          return;
        }

        const prev = row.value;
        const oldPrice = priceLCY;
        const stateId = !isPreorder ? itemId : index;

        const validatedPrice = validatePrice(_this.value, oldPrice);
        if (prev !== _this.value && !validatedPrice.success) {
          alert(
            `Слишком большое отклонение ЖЦены от РЦены для товара ${itemId}<br />Введите корректное значение!`,
          ).then(() =>
            dispatch({
              type: SET_ITEM_DESIRED_PRICE,
              payload: {
                id: stateId,
                value: validatedPrice.price,
              },
            }),
          );

          return;
        }

        const desiredPriceClient = (parseFloat(_this.value) / parseFloat(Rate)).toFixed(2);

        if (row.value !== _this.value) {
          dispatch({
            type: SET_ITEM_DESIRED_PRICE,
            payload: {
              id: stateId,
              value: _this.value,
              rate: Rate,
            },
          });
        }

        prepareData(
          row,
          {
            ...state,
            [stateId]: {
              ...state[stateId],
              desiredPriceClient,
            },
          },
          isPreorder,
        );
      }}
    />
  );
};

export const DesiredPriceCell = forwardRef(PriceCell);

export const PriceClientCell = (props, ref) => {
  const {
    select: { user },
  } = useStore();
  const isPreorder = useIsPreorder();
  const { Rate } = useSelector(user.getCurrency);

  const row = props;
  const { id: itemId, index, priceLCY } = row.original;

  if (!row || row.original.default) {
    return null;
  }

  return (
    <EditableField
      key={!isPreorder ? itemId : index}
      row={row}
      ref={ref}
      column={props.column}
      actionType={SET_ITEM_DESIRED_CLIENT_PRICE}
      className="items-editable items-editable--right"
      blur={(state, dispatch) => e => {
        e.persist();
        const _this = e.target;

        if (!_this.value.length) {
          return;
        }

        const oldPrice = priceLCY;
        const prev = row.value;
        const stateId = !isPreorder ? itemId : index;

        const desiredPrice = parseFloat(_this.value) * parseFloat(Rate);
        const validatedPrice = validatePrice(desiredPrice, oldPrice);
        if (prev !== _this.value && !validatedPrice.success) {
          alert(
            `Слишком большое отклонение ЖЦены от РЦены для товара ${itemId}<br />Введите корректное значение!`,
          ).then(() => {
            dispatch({
              type: SET_ITEM_DESIRED_CLIENT_PRICE,
              payload: {
                id: stateId,
                value: prev,
              },
            });
          });

          return;
        }

        if (row.value !== _this.value) {
          dispatch({
            type: SET_ITEM_DESIRED_CLIENT_PRICE,
            payload: {
              id: stateId,
              value: _this.value,
              rate: Rate,
            },
          });
        }

        prepareData(
          row,
          {
            ...state,
            [stateId]: {
              ...state[stateId],
              desiredPrice,
            },
          },
          isPreorder,
        );
      }}
    />
  );
};

export const DesiredPriceClientCell = forwardRef(PriceClientCell);

export const MinPackagedCell = props => {
  const isPreorder = useIsPreorder();
  const qtyLimit = useSelector(getQtyLimit);

  const row = props;
  const { id: itemId, index, code1, salesLimitType, minPackaged } = row.original;

  if (!row || row.original.default) {
    return null;
  }

  const { value = 0 } = row;

  return (
    <CheckedTextField
      row={row}
      key={!isPreorder ? itemId : index}
      className={parseInt(value, 10) && 'table-text-btn'}
      title={parseInt(value, 10) && (row.salesLimitType || `1 клик = +${value} упаковка в Жкол`)}
      prepare={state => prepareData(row, state, isPreorder)}
      onClick={(state, dispatch) => () => {
        const val = parseInt(state[itemId].desiredQty, 10) + parseInt(value, 10);
        const options = {
          value: val,
          code: code1,
          salesLimitType,
          minPackaged,
          qtyLimit,
        };
        if (checkMaxQty(options)) {
          return false;
        }

        dispatch(
          setItemDesiredQty({
            id: !isPreorder ? itemId : index,
            value: val,
          }),
        );

        prepareData(row, state, isPreorder);
      }}
    >
      {value}
    </CheckedTextField>
  );
};

export const PackagedCell = props => {
  const isPreorder = useIsPreorder();
  const qtyLimit = useSelector(getQtyLimit);

  const row = props;
  const { id: itemId, index, code1, salesLimitType, minPackaged } = row.original;

  if (!row || row.original.default) {
    return null;
  }

  const rowValue = +row.value;

  return (
    <CheckedTextField
      row={row}
      key={!isPreorder ? itemId : index}
      className={(!!rowValue && 'table-text-btn') || ''}
      title={(!!rowValue && `1 клик = +${row.value} упаковка в Жкол`) || ''}
      prepare={state => prepareData(row, state, isPreorder)}
      onClick={(state, dispatch) => () => {
        const value = parseInt(state[itemId].desiredQty, 10) + rowValue;

        const options = {
          value,
          code: code1,
          salesLimitType,
          minPackaged,
          qtyLimit,
        };
        if (checkMaxQty(options)) {
          return false;
        }

        dispatch(
          setItemDesiredQty({
            id: !isPreorder ? itemId : index,
            value,
          }),
        );
      }}
    >
      {rowValue}
    </CheckedTextField>
  );
};

export const GroupCell = props => {
  const selectGroup = useSelectGroup();

  const row = props;
  const { code2, availableClient, availableMSK1 } = row.original;
  const inOrder = row.inOrder;

  const isEmpty =
    !inOrder &&
    Number(availableClient) === 0 &&
    Number(availableMSK1) === 0;

  const classes = classnames(
    "link",
    "link--underlined",
    { ["product-is-empty"]: isEmpty }
  );

  if (!row) {
    return null;
  }

  return (
    <a
      href="#"
      key={row.index}
      className={classes}
      onClick={selectGroup(code2, row, 1)}
    >
      {row.value}
    </a>
  );
};

export const SubGroupCell = props => {
  const selectGroup = useSelectGroup();

  const row = props;
  const { code3, availableClient, availableMSK1 } = row.original;
  const inOrder = row.inOrder;

  const isEmpty =
    !inOrder &&
    Number(availableClient) === 0 &&
    Number(availableMSK1) === 0;

  const classes = classnames(
    "link",
    "link--underlined",
    { ["product-is-empty"]: isEmpty }
  );

  if (!row) {
    return null;
  }

  return (
    <a
      href="#"
      key={row.index}
      className={classes}
      onClick={selectGroup(code3, row, 0)}
    >
      {row.value}
    </a>
  );
};

export const ExpectedNextCell = ({ row }) => {
  if (!row) {
    return null;
  }

  const {
    _original: { availableExpectedNext, dateExpectedNext },
  } = row;

  return `${availableExpectedNext || 0} ${
    (dateExpectedNext && '(' + dateExpectedNext + ')') || ''
  }`;
};

export const SignCell = props => {
  const user = useSelector(getUser);

  const { row, handleSignChange } = props;

  if (!row) {
    return null;
  }

  const enabled = hasOrderEditAccess(user, row.original || {});
  const [year, month, date] = dateUTCSplit(row.original?.shipmentDate);
  const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  // TODO: некорректное преобразование даты, данный способ используется в нескольких местах
  // Пример: new Date('2024', '10', '30') возвращает некорректную дату.
  const shipDate = new Date(year, month, date);

  const canSign =
    !row.original?.disabled &&
    enabled &&
    row.original?.reserveOrOrder === 'РЕЗЕРВ' &&
    shipDate > currentDate;

  const handleSignOrder = () => handleSignChange(row.original?.orderNo);

  return canSign ? <input type="checkbox" onClick={handleSignOrder} /> : null;
};
