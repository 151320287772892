import React from 'react';
import { Link } from 'react-router-dom';
import alert from 'components/native/alert/function';
import { isEmpty } from 'lodash';
import { def } from 'common/util';
import Mixpanel from 'services/mixpanel';
import { useSelector } from 'react-redux';
import { getUser } from 'pages/Orders/selectors';
import {
  ROUTE_PREORDERS_EDIT,
  ROUTE_PREORDERS_NEW,
  preorderTypes,
  ROUTE_PREORDERS_VIEW
} from '../constances/preorder';
import {getDataByPath, setDataByPath} from "models/list/helpers"
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';

export const SHIPMENT_MSK = 'Доставка со склада МСК';
export const SHIPMENT_SELF = 'Самовывоз';

export const current = new Date(new Date().setHours(0, 0, 0, 0));

export const reserveIcon = (reserve, order = true) => {
  let iconClass = 'icon icon-';
  let title = '';

  const or = parseInt(reserve);

  switch (or) {
    case 1:
      iconClass += 'noreserve';
      title = order
        ? 'В заказе есть товары, у которых резервирование запрещено'
        : 'Для товара резервирование запрещено';
      break;
    case 2:
      iconClass += 'payreserve';
      title = order
        ? 'В заказе есть товары с платной отменой резерва'
        : 'Для товара отмена резервирования платная';
      break;
    case 3:
      iconClass += 'deficit';
      title = order ? 'В заказе есть дефицитные товары' : 'Дефицитный товар';
      break;
    case 4:
      iconClass += 'noreserve-black';
      title = order
        ? 'В заказе есть товары с просроченным резервом'
        : 'Переносить в другой заказ запрещено';
      break;
    case 5:
      iconClass += 'payreserve-red';
      title = order
        ? 'В заказе есть товары с просроченным резервом'
        : 'Товар с просроченным резервированием';
      break;
    case 6:
      iconClass += 'deficit-red';
      title = order
        ? 'В заказе есть товары с просроченным резервом'
        : 'Товар с просроченным резервированием';
      break;
    default:
      return '';
  }

  return <i className={iconClass} title={title} />;
};

export const checkReserve = row => {
  if (!row) return null;

  const { reserve, orderNo: order, integrated, status } = row.original;

  const isPreorder = useIsPreorder();
  const urlTypeOrder = isPreorder ? `${ROUTE_PREORDERS_VIEW}${order}` : `/orders/view/${order}`;
  const url = status === 'учтен' ? '' : urlTypeOrder;

  const orderNo = integrated ? (
    <Link
      to={`orders/integrate/${order}`}
      className="link link--underlined"
      title="Просмотр объединенных заказов"
    >
      {order}
    </Link>
  ) : (
    <Link to={url} title="Просмотр заказа">
      {order}
    </Link>
  );

  return reserve ? (
    <>
      {reserveIcon(reserve)}
      {orderNo}
    </>
  ) : (
    orderNo
  );
};

export const getStatusCell = row => {
  if (!row) return null;

  let color = '#000000';
  const status = row.original.statusText || null;

  const notShipped = Number(row.original.notShipped) === 1;

  switch (status) {
    case 'на удалении':
    case 'на подписании':
    case 'снимается с подписи':
      color = '#cc6600';
      break;
    case 'комплектуется':
    case 'подписан клиентом':
      color = '#218e1d';
      break;
    case 'подписан':
    case 'утвержден':
    case 'Тендер':
      color = '#ff0000';
      break;
    case 'учтен':
      color = '#0000ff';
      break;
    default:
      break;
  }

  return (
    <span style={{ color }}>
      {status}
      {notShipped && (
        <>
          <br />
          <span style={{ color: '#ff0000' }}>недогруз!</span>
        </>
      )}
    </span>
  );
};

export const ContactCell = row => {
  const user = useSelector(getUser);

  if (!row) {
    return null;
  }

  const { contact = '' } = row.original;

  return (
    (contact === user['FIO'] && <span style={{ color: '#218e1d' }}>{contact}</span>) || contact
  );
};

export const hasCheckedInputs = () =>
  [...document.querySelectorAll('input.join-active')].some(elem => elem.checked);

export const checkAllInputs = check => {
  [...document.querySelectorAll("input[type='checkbox']")].forEach((elem, i) => {
    if (elem.checked !== check) {
      if (check) {
        console.log(i, ': checked!', elem)
        elem.click();
      } else {
        elem.checked = false;
      }
    }
  });
};

export const resetInputs = () => {
  [...document.querySelectorAll('input.join-active')].forEach(element => {
    element.classList.remove('join-active');
    element.checked = false;
  });
};

export const resetCheckboxes = selector => {
  [...document.querySelectorAll(selector)].forEach(element => {
    element.checked = false;
  });
};

export const hideRow = order => {
  document.getElementById(order).style.display = 'none';
};

export const getJoinedOrders = () =>
  [...document.querySelectorAll('input.join-active:checked')].map(element => element.id);

export const hasOutdatedDeficit = () =>
  [...document.querySelectorAll('input.join-active')].some(
    elem => Number(elem.dataset.reserve) === 5,
  );

export const getItemsFromMSKChecked = () => [
  ...document.querySelectorAll('input[name="warehouse-checkbox"]:checked'),
];

export const getItemsFromTransitChecked = () => [
  ...document.querySelectorAll('input[name="transit-checkbox"]:checked'),
];

export const toggleAllItemsFromMSK = checked =>
  (document.getElementById('warehouse-checkbox-all').checked = checked);

export const ItemLinkCell = ({ row }) => (
  <a
    href={`http://www.merlion.ru/b2b/${row.itemNo}`}
    target="_blank"
    rel="noopener noreferrer"
    className="link link--underlined"
  >
    {row.name} {row.EOL > 0 ? '(снят с производства)' : ''} {row.isNew > 0 ? '(новый товар)' : ''}
  </a>
);

export const getMoved = (data, forSend = false) =>
  data.reduce((res, obj, key) => {
    const qty = obj.desiredQty;

    if (forSend) {
      return { ...res, [key]: [obj.id, qty] };
    }

    return { ...res, [key]: { ...obj, desiredQty: qty } };
  }, {});

export const buildGroupsObjFromSuggest = arr =>
  (arr.length > 2 && {
    code1: arr[1]['code'],
    code2: arr[2]['code'],
    code3: arr[0]['code'],
    group1: arr[1]['name'],
    group2: arr[2]['name'],
    group3: arr[0]['name'],
  }) || {
    code1: arr[1]['code'],
    code2: arr[0]['code'],
    group1: arr[1]['name'],
    group2: arr[0]['name'],
  };

export const checkAddItemCondition = (qty, minPack, limitType, related = false) => {
  if (!/^\d+$/.test(qty)) {
    return false;
  }

  const desiredQty = parseInt(qty);
  const minPackage = parseInt(minPack);

  if (
    ['Только Упаковками', 'Кратно'].includes(limitType) &&
    minPackage &&
    desiredQty % minPackage > 0
  ) {
    alert(`Товар продается только упаковками по ${minPackage} шт.!`, {
      bodyOpenClassName: related
        ? 'ReactModal__Body--open ReactModal__Body--open--related'
        : 'ReactModal__Body--open',
    }).then(() => false);

    return false;
  }

  if (desiredQty && desiredQty < minPackage) {
    alert(`Минимальное количество товара для резервирования - ${minPackage} шт.`, {
      bodyOpenClassName: related
        ? 'ReactModal__Body--open ReactModal__Body--open--related'
        : 'ReactModal__Body--open',
    }).then(() => false);

    return false;
  }

  return true;
};

export const isPreOrder = (path, order) =>
  path.startsWith(ROUTE_PREORDERS_EDIT) ||
  path.startsWith(ROUTE_PREORDERS_NEW) ||
  (order && +order.docType === +preorderTypes.docType && +order.tender === +preorderTypes.tender);

export const isRequestItemsEndpoint = path => /(search|group|orders)/gi.test(path);

export const getOrderCurrency = (item, currency) => {
  if (!currency) currency = 'РУБ';

  if (item === 'order') {
    return currency;
  }

  if (item === 'title') {
    return currency !== 'РУБ' ? currency : 'USD';
  }

  return currency;
};

export const showErrorMessage = rows => {
  const messages = [];
  let isLoading = false;
  rows &&
    rows.map(row => {
      if (row.status === 'Err') {
        let isDouble = false;
        messages.map(mes => {
          if (mes === row.comment) {
            isDouble = true;
            return false;
          }
        });
        if (isDouble) {
          return false;
        }
        messages.push(`${row.comment}`);
      } else if (row.status === 'Act') {
        isLoading = true;
      }
    });
  messages.length && !isLoading && alert(messages.join(',\n'));
};

export const syncQueueEditRows = (state, payload) => {
  const { indexes } = state;

  const data = getDataByPath(state).reduce((items, item) => {
    let row;

    if (!isEmpty(indexes)) {
      row = payload.rows.find(
        row => row.id === item.id && item.index === indexes[item.id],
      );
    } else {
      row = payload.rows.find(payload => payload.id === item.id);
    }

    if (def(row)) {
      const { priceClient, ...rest } = row;

      if (row.status === 'Ok') {
        if (row.command === 'Delete') {
          if (!payload.isInList) {
            return items;
          }

          return [...items, { ...item, status: null, lineNo: null, desiredQty: 0 }];
        }

        return [
          ...items,
          {
            ...item,
            ...rest,
            desiredQty: row.desiredQty,
            desiredPriceRUB: row.desiredPrice,
            desiredPriceClient: row.desiredPriceClient,
          },
        ];
      }

      return [
        ...items,
        {
          ...item,
          status: row.status,
          desiredQty: row.desiredQty,
          desiredPriceRUB: row.desiredPrice,
          desiredPriceClient: row.desiredPriceClient,
        },
      ];
    }

    return [...items, item];
  }, []);

  setDataByPath(state, data)

  return state;
};

export const syncQueueMigrateRows = (state, payload) => {
  const data = getDataByPath(state).reduce((items, item) => {
    const row = payload.rows.find(load => load.itemNo === item.id);

    if (def(row)) {
      const { priceClient, ...rest } = row;

      if (row.result === 'Ok' && item.status !== row.result) {
        const desiredQty = Math.max(parseInt(item.desiredQty) - parseInt(row.itemQty), 0);

        if (desiredQty === 0) {
          return items;
        }

        return [
          ...items,
          {
            ...item,
            ...rest,
            desiredQty,
            status: row.result,
          },
        ];
      }

      return [...items, { ...item, status: row.result }];
    }

    return [...items, item];
  }, []);

  setDataByPath(state, data)

  return state;
};

export const trackingCardPage = (id, name, from) => () => {
  Mixpanel?.track('стрКарточкаТовара', {
    from,
    id,
    name,
  });
};
