import React from 'react';
import NewProductModal from './NewProductModal';
import { hrefReglamentClaims } from 'common/util';

function FormActions(props) {
  const { onSubmitButtonClick, submitting, ...rest } = props;
  return (
    <div className="claims-new__form-actions">
      <NewProductModal {...rest} />
      <a className="claims-new__reglament" href={hrefReglamentClaims}>
        <span className="icon icon-form-doc" />
        Регламент подачи претензий
      </a>
    </div>
  );
}

export default FormActions;
