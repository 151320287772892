export default values => {
  const errors = {};
  if (!values.oldPass) {
    errors.oldPass = 'Заполните поле';
  } else if (values.oldPass.length < 3 || /[;'\\<>-]+/.test(values.oldPass)) {
    errors.oldPass = "Пароль должен быть не менее 3 символов и не содержать ;'\\<>-";
  }

  if (!values.newPass) {
    errors.newPass = 'Заполните поле';
  } else if (!/^[0-9A-Za-z]{10,}$/.test(values.newPass)) {
    errors.newPass = 'Пароль должен быть не менее 10 символов и содержать латинские буквы и цифры';
  }

  if (values.oldPass && values.newPass && values.oldPass === values.newPass) {
    errors.newPass = 'Новый пароль должен отличаться от старого';
  }

  return errors;
};
