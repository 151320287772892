// @ts-nocheck
import get from 'lodash/get';
import alert from 'components/native/alert/function';

export const handleIquoteLink = (res, tab) => {
  const success = get(res, 'success', false);
  if (success) {
    const url = get(res, ['data', 'location'], '');
    if (url) {
      tab.location.href = url
    }
  } else {
    const message = get(res, 'message', 'Что-то пошло не так');
    alert(message);
  }
};
