import dateformat from 'dateformat';

export default payments =>
  payments.map((val, index) => {
    const { date, sum } = val;
    const month = date.substr(4, 2);
    const year = date.substr(0, 4);
    const text = dateformat(new Date(year, month - 1), 'mmm yyyy');
    const monthYear = `${year}-${month}`;

    return {
      ...val,
      dayOfMonth: val.date.substr(6, 2),
      monthYear,
      index,
      sum: parseInt(sum, 10),
      text,
    };
  });
