import notifier from 'services/new/notifier';
import { GoodsCollector } from 'pages/new/orders/pages/orders-new/utils';
import history from 'common/history';

export default ({
    newOrdersNew,
    newOrder,
    items,
    newOrderActions,
    newFilters,
    order: orderModel,
  }) =>
  async (props, { newOrderActions: { jobId }, newOrdersNew: { orders } }) => {
    const message = props.data?.data || props.data || {};
    const { fix } = props.data;
    const { order, message: agentMessage, success } = message;

    const orderNo = order?.orderNo || props.id;
    const { isOrderEdit } = props;

    if (fix?.done && !fix.error && fix?.payload?.file) {
      await items.getFile(fix.payload.file);
      newOrderActions.setJobId('');
      newOrderActions.setJobType('');

      return;
    }

    newOrdersNew.syncQueueEditRows({ message, isOrderEdit });
    orderModel.updateRows(props.data);

    if (fix?.done) {
      if (isOrderEdit) {
        newOrder.updateOrder(props.data);
        orderModel.updateOrder(props.data);
      } else if (!message.delete) {
        newOrdersNew.updateOrders(props);
      }
    }

    if (fix?.channel === 'queue' && jobId) {
      newOrdersNew.updateOrders(props);

      if (fix?.done) {
        await items.onReceive(props);
        newOrderActions.setJobId('');
        newOrderActions.setJobType('');

        if (fix?.payload.command === 'del' || fix?.payload.command === 'sign') {
          const newOrders = orders.filter(order => order.orderNo !== fix.id);

          if (newOrders.length === 0) {
            await items.setAvail({ avail: 0 });
            newFilters.clear();
          }
          newOrdersNew.setOrders(newOrders);
        }

        if (isOrderEdit && fix?.payload?.command === 'del') {
          setTimeout(() => {
            history.push('/orders');
          }, 1000);
        }
      }
    } else if (fix?.channel === 'queue-order' && fix?.done) {
      const errorMessage = agentMessage || `Неудачная попытка изменения состава заказа ${orderNo}!`;

      if (message.delete) {
        notifier.resolve(
          {
            success,
            message: success ? agentMessage : errorMessage,
          },
          message.id,
        );

        if (success) {
          newOrdersNew.updateOrders({ ...props, data: { ...props.data, id: props.data.fix.id } });

          GoodsCollector.delete(order, 'qtys', message.id);
          GoodsCollector.delete(order, 'prices', message.id);
          GoodsCollector.delete(order, 'priceClient', message.id);
        }
      } else {
        notifier.resolve(
          {
            success,
            message: success ? `Состав заказа ${orderNo} изменён!` : errorMessage,
          },
          orderNo,
        );

        GoodsCollector.delete(orderNo);
      }
    }
  };
