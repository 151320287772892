import React, { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

const BalanceInput = () => {
  const {
    select: {
      balance: {
        getSearchText
      },
    },
  } = useStore();

  const {
    balance: { setSearchText },
  } = useDispatch();

  const searchText = useSelector(getSearchText);

  useEffect(() => {
    return () => setSearchText('');
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;

    setSearchText(value);
  };

  const handleClear = () => {
    setSearchText('');
  };

  return (
    <div className="balance-input-container">
      <input
        className="test-input"
        placeholder="Поиск по наименованию или коду контрагента"
        value={searchText}
        onChange={handleInputChange}
      />

      <button onClick={handleClear} className="balance-input-container__clear-filters">
        <i className="icon icon-remove-filters" title="Очистить фильтр" />
      </button>
    </div>
  );
};

export default BalanceInput;
