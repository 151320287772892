import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Calendar = ({
  className,
  color = '#000',
  name,
  width = '18',
  height = '19',
  viewBox = '0 0 18 19',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M16 3C16.4 3 17 3.6 17 4V17C17 17.4 16.4 18 16 18H2C1.6 18 1 17.4 1 17V4C1 3.6 1.6 3 2 3H16ZM16 2H2C1 2 0 3 0 4V17C0 18 1 19 2 19H16C17 19 18 18 18 17V4C18 3 17 2 16 2Z"
        fill={color}
      />
      <path d="M5 0H4V4H5V0Z" fill={color} />
      <path d="M18 5H0V6H18V5Z" fill={color} />
      <path d="M14 0H13V4H14V0Z" fill={color} />
      <path d="M16 7H15V11H16V7Z" fill={color} />
      <path d="M13 7H12V11H13V7Z" fill={color} />
      <path d="M12 7V8H16V7H12Z" fill={color} />
      <path d="M12 10V11H16V10H12Z" fill={color} />
      <path d="M11 7H10V11H11V7Z" fill={color} />
      <path d="M8 7H7V11H8V7Z" fill={color} />
      <path d="M7 7V8H11V7H7Z" fill={color} />
      <path d="M7 10V11H11V10H7Z" fill={color} />
      <path d="M6 7H5V11H6V7Z" fill={color} />
      <path d="M3 7H2V11H3V7Z" fill={color} />
      <path d="M2 7V8H6V7H2Z" fill={color} />
      <path d="M2 10V11H6V10H2Z" fill={color} />
      <path d="M16 12H15V16H16V12Z" fill={color} />
      <path d="M13 12H12V16H13V12Z" fill={color} />
      <path d="M12 12V13H16V12H12Z" fill={color} />
      <path d="M12 15V16H16V15H12Z" fill={color} />
      <path d="M11 12H10V16H11V12Z" fill={color} />
      <path d="M8 12H7V16H8V12Z" fill={color} />
      <path d="M7 12V13H11V12H7Z" fill={color} />
      <path d="M7 15V16H11V15H7Z" fill={color} />
      <path d="M6 12H5V16H6V12Z" fill={color} />
      <path d="M3 12H2V16H3V12Z" fill={color} />
      <path d="M2 12V13H6V12H2Z" fill={color} />
      <path d="M2 15V16H6V15H2Z" fill={color} />
    </svg>
  );
};

Calendar.defaultProps = {
  className: '',
  color: '#000',
  width: '18',
  height: '19',
  viewBox: '0 0 18 19',
};

Calendar.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
