import validate from 'common/validates/validateSpecialPrices';
import { SPECIAL_PRICES_FORM } from 'pages/VAD/constants';

const formConfig = {
  form: SPECIAL_PRICES_FORM,
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
  validate,
};

export default formConfig;
