export const MAX_LIMIT_SYMBOLS = 300;

export const validate = values => {
  const errors = {};
  if (!values.clientNo) {
    errors.clientNo = 'Заполните поле';
  }

  if (!values.clientLogin) {
    errors.clientLogin = 'Заполните поле';
  }

  if (!values.secondName) {
    errors.secondName = 'Заполните поле';
  }

  if (!values.email) {
    errors.name = 'Заполните поле';
  }

  if (!/.+@.+\..+/i.test(values.email)) {
    errors.email = 'Неверно заполнено поле';
  }

  if (!values.mobilePhone) {
    errors.mobilePhone = 'Заполните поле';
  }

  if (!/\+(7|37|38)\(\d{3}\)\d{3}-\d{2}-\d{2}/.test(values.mobilePhone)) {
    errors.mobilePhone = 'Неверно заполнено поле';
  }

  if (values.description?.length > MAX_LIMIT_SYMBOLS) {
    errors.description = `Превышает допустимый лимит в ${MAX_LIMIT_SYMBOLS} символов`;
  }

  return errors;
};
