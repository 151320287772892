import React from 'react';
import { smoothScrollToTop } from 'common/util';

const ArrowUp = () => (
  <span
    className="arrow-up-container"
    id="arrow-up-container"
    title="Наверх"
    onClick={smoothScrollToTop}
  >
    <i className="arrow-up" />
  </span>
);

export default ArrowUp;
