import React, {memo, useEffect, useRef} from 'react'
import { isFetchingItems } from 'pages/Orders/selectors';
import {useDispatch, useSelector} from 'react-redux'
import cn from 'classnames';

const ItemsLoader = ({ className, loaderComponent: Loader }) => {
  const loading = useSelector(isFetchingItems);

  return (
    <div className={cn('items-loader', className)} style={{ display: loading ? 'block' : 'none' }}>
      {Loader || <i className="loader loader--ripple" />}
    </div>
  );
};

export default memo(ItemsLoader);
