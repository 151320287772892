import sortDates from 'helpers/sortDates';
import sortStringNumbers from "helpers/sortStringNumbers"

const expiredColumns = [
  {
    Header: 'Номер Заказа',
    accessor: 'orderNo',
    id: 'orderNo',
  },
  {
    Header: 'Номер Общего Заказа',
    accessor: 'generalOrderNo',
    id: 'generalOrderNo',
  },
  {
    Header: 'Дата Отгрузки',
    accessor: 'shippingDate',
    id: 'shippingDate',
    sortMethod: sortDates
  },
  {
    Header: 'Дата Заказа',
    accessor: 'orderDate',
    id: 'orderDate',
    sortMethod: sortDates
  },
  {
    Header: 'Дата Учета',
    accessor: 'accountingDate',
    id: 'accountingDate',
    sortMethod: sortDates,
  },
  {
    Header: 'Контрагент Номер',
    accessor: 'counterparty',
    id: 'counterparty',
  },
  {
    Header: 'Контрагент Название',
    accessor: 'counterpartyName',
    id: 'counterpartyName',
  },
  {
    Header: 'Описание Учета',
    accessor: 'accountDescription',
    id: 'accountDescription',
  },
  {
    Header: 'Код Метода Оплаты',
    accessor: 'paymentCode',
    id: 'paymentCode',
  },
  {
    Header: 'Тип Учета',
    accessor: 'businessType',
    id: 'businessType',
  },
  {
    Header: 'Код Валюты',
    accessor: 'currencyCode',
    id: 'currencyCode',
  },
  {
    Header: 'Сумма',
    accessor: 'amount',
    id: 'amount',
    sortMethod: sortStringNumbers
  },
  {
    Header: 'Товарная Накладная',
    accessor: 'packingList',
    id: 'packingList',
  },
  {
    Header: 'Представитель',
    accessor: 'representative',
    id: 'representative',
  },
  {
    Header: 'Платная ДО',
    accessor: 'paidUntil',
    id: 'paidUntil',
  },
];

export default expiredColumns;
