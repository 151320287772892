import { compose } from 'redux';
import withApi from 'hoc/withApi';

import React from 'react';
import withContextReducer from 'hoc/withContextReducer';
import { OrderEditContext } from 'pages/Orders/components/order-edit/context';
import orderEditReducer, { initialOrderState } from '../reducer/orderEditReducer';
import OrderEdit from '../components/order-edit';

export default compose(
  withApi,
  withContextReducer(orderEditReducer, initialOrderState, OrderEditContext),
)(OrderEdit);
