import React, { useState } from 'react';
import alert from 'components/native/alert/function';
import { demoCollector } from 'pages/VAD/constants';

const InputQty = row => {
  const [value, setValue] = useState(row?.value);

  if (!row) return null;

  const {
    original,
    tdProps: {
      rest: { activate },
    },
  } = row;

  const handleFocus = e => {
    const { value } = e.target;

    if (+value === 0) {
      setValue('');
    }
  };

  const handleBlur = e => {
    const { value } = e.target;
    const { id, availableMsk, availableReg } = original;

    if (!Number(value)) {
      activate(false);
      demoCollector.delete(id);
      setValue(0);
    } else if (!availableMsk && !availableReg) {
      alert('В настоящий момент оборудование отсутствует на складе');
      activate(false);
      demoCollector.delete(id);
      setValue(0);
    } else if (value > availableMsk && value > availableReg) {
      alert('Превышено максимальное количество доступного на складах');
      const maxAmount = Math.max(availableMsk, availableReg);

      activate(true);
      setValue(maxAmount);
      demoCollector.set(id, { ...original, qty: maxAmount });
    } else {
      activate(true);
      demoCollector.set(id, { ...original, qty: value });
    }
  };

  const handleChange = e => {
    const { value } = e.target;

    setValue(Math.abs(value));
  };

  return (
    <input
      value={value}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className="qty__input"
      min="0"
      type="number"
    />
  );
};

export default InputQty;
