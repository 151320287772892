import { def } from 'common/util';
import { isUniqueInOrders } from 'pages/new/orders/pages/orders-new/utils';

export default function syncQueueEditRows(state, payload) {
  const { message, isOrderEdit } = payload;
  const { orderNo: order, rows } = message;

  if (!rows?.length) {
    return state;
  }

  const data = state.goods.reduce((items, item) => {
    const row = rows.find(payload => payload.id === item.id);

    if (def(row)) {
      if (row.status === 'Ok') {
        if (row.command === 'Delete') {
          if (!state.inList && isUniqueInOrders(state.orders, item, order)) {
            return items;
          }

          return [
            ...items,
            {
              ...item,
              [`status_${order}`]: null,
              [`lineNo_${order}`]: null,
              [`desiredQty_${order}`]: 0,
              [`qty_${order}`]: 0,
            },
          ];
        }

        const newValues = {
          [`status_${order}`]: row.status,
          [`lineNo_${order}`]: row.lineNo,
          [`qty_${order}`]: row.qty,
          [`desiredQty_${order}`]: row.desiredQty,
          [`desiredPriceRUB_${order}`]: row.desiredPriceRUB,
          [`desiredPriceUSD_${order}`]: row.desiredPriceUSD,
          [`priceOrderRUB_${order}`]: row.priceOrderRUB,
          [`priceOrderUSD_${order}`]: row.priceOrderUSD,
        };

        if (isOrderEdit) {
          newValues.desiredPriceRUB = row.desiredPriceRUB;
          newValues.desiredPriceUSD = row.desiredPriceUSD;
          newValues.desiredQty = row.desiredQty;
          newValues.qty = row.qty;
        }

        if (row.availableWarehouse && row.availableEverywhere) {
          if (!isUniqueInOrders(state.orders, item, order)) {
            const { location } =
              state.orders.find(currentOrder => currentOrder.orderNo === order) || {};

            state.orders.forEach(activeOrder => {
              if (activeOrder.location === location) {
                newValues[`availableClient_${activeOrder.orderNo}`] = row.availableWarehouse;
                newValues[`availableMSK1_${activeOrder.orderNo}`] = row.availableEverywhere;
              }
            });
          } else {
            newValues[`availableClient_${order}`] = row.availableWarehouse;
            newValues[`availableMSK1_${order}`] = row.availableEverywhere;
          }
        }

        return [
          ...items,
          {
            ...item,
            ...newValues,
          },
        ];
      }

      return [
        ...items,
        {
          ...item,
          [`status_${order}`]: row.status,
        },
      ];
    }

    return [...items, item];
  }, []);

  return { ...state, goods: data };
}
