import React, { memo, useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import alert from 'components/native/alert/function';
import { HEADER_MAP } from '../helper/constants';
import { isCactus } from 'common/config';
import AppSocket from 'components/AppSocket';

const fixed = num => {
  if (!num) {
    return '0';
  }

  const parsed = parseInt(num, 10);

  return Number.isNaN(parsed) ? '0' : parsed.toLocaleString();
};

const ChartInfo = () => {
  const {
    select: { chart },
  } = useStore();
  const header = useSelector(chart.getHeader);
  const {
    chart: { setGenerating, receivePayment },
    finances: { generatePayments },
  } = useDispatch();
  const loading = useSelector(state => state.chart.generating);

  const [onEmail, setOnEmail] = useState(0);

  const handleChange = ({ target: { value } }) => setOnEmail(value);

  return (
    <AppSocket
      channel={'queue-payments'}
      onReceive={receivePayment}
      onExpire={() => {
        setGenerating(false);
        alert('Ваш запрос обрабатывается. Обновите страницу через некоторое время.');
      }}
    >
      <div className="chart-info">
        <div className="chart-info__content">
          <div className="chart-info__row">
            <p className="chart-info__row__text chart-info__header">Данные на текущую дату:</p>
          </div>

          {HEADER_MAP.map(([key, value]) => (
            <div className={`chart-info__row ${value.className}`} key={key}>
              <div className="chart-info__row__text">{value.description}</div>
              <div>
                <b>{fixed(header[key])}</b>
              </div>
            </div>
          ))}
        </div>

        {!isCactus && (
          <div className="chart-info__button">
            <div>
              {loading ? (
                <i className="loader loader--small" />
              ) : (
                <i
                  className="icon icon-expxls"
                  onClick={async () => {
                    setGenerating(true);
                    await generatePayments({ email: onEmail });
                  }}
                />
              )}
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="email"
                  value={0}
                  defaultChecked={true}
                  onChange={handleChange}
                />
                &nbsp;выгрузить
              </label>
              <label>
                <input type="radio" name="email" value={1} onChange={handleChange} />
                &nbsp;отправить email
              </label>
            </div>
          </div>
        )}
      </div>
    </AppSocket>
  );
};

export default memo(ChartInfo);
