import React from 'react';
import withApi from 'hoc/withApi';
import { useSelector } from 'react-redux';
import { getTableListData } from 'common/selectors';
import {Link, useParams} from 'react-router-dom'
import TableList from 'components/table/list';
import balanceColumns from 'pages/Warranty/components/service/columns/balance';
import deliveryColumns from 'pages/Warranty/components/service/columns/delivery';
import defaultColumns from 'pages/Warranty/components/service/columns/default';
import useLocationValue from "hooks/use-location-value"
import useListData from "components/table/hooks/useListData"

const Document = () => {
  const { id } = useParams()
  const { no = id, date, description, qtySum, ektpNo, ektpAmount } = useSelector(
    state => state.warranty.header,
  );
  const data = useListData();
  const type = useLocationValue('type', '');

  const noFromData = data?.[0]?.no || '';

  return (
    <div className="service-document-container">
      <h5 className="text-center">Документ №{type === 'offset-balance' && noFromData || no}</h5>
      <div className="service-header-container">
        <div>
          <Link to={`/warranty/service${type && '/' + type || ''}`} className="section-nav">
            <i className="icon icon-back-icon" /> Вернуться к списку
          </Link>
        </div>
        <div>
          Дата {type === 'receive' ? 'создания' : 'учета'}: <span>{date}</span>
        </div>
        {type === 'offset-balance' ? (
          <div>
            Номер Документа в Коммерции: <span>{ektpNo}</span>
          </div>
        ) : (
          <div>
            Название клиента: <span>{description}</span>
          </div>
        )}
        {type === 'offset-balance' ? (
          <div>
            Сумма Выплаты Клиенту: <span>{ektpAmount}</span>
          </div>
        ) : (
          ''
        )}
        <div>
          Количество по документу: <span>{qtySum}</span>
        </div>
      </div>
      <div className="service-table-container">
        <TableList
          columns={
            type === 'offset-balance'
              ? balanceColumns
              : type === 'delivery'
              ? deliveryColumns
              : defaultColumns
          }
          defaultPageSize={5000}
          pageSize={5000}
        />
      </div>
    </div>
  );
};

export default withApi(Document);
