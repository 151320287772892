// @ts-nocheck

export const fetchRepresentatives = (dispatch, type) => async payload => {
  try {
    const result = await dispatch.api.post({
      url: 'new-order/representatives',
      data: payload,
    });

    dispatch[type].fetchRepresentativesSuccess(result);
    return result;
  } catch (err) {
    return {};
  }
};

export const fetchShipmentDates = (dispatch, type) => async shipment => {
  try {
    const result = await dispatch.api.post({
      url: 'new-order/shipment-dates',
      data: {
        shipmentMethod: shipment.Code,
      },
    });

    dispatch[type].fetchShipmentDatesSuccess(result);
    return result;
  } catch (err) {
    return {};
  }
};

export const fetchShipmentTime = (dispatch, type) => async payload => {
  try {
    const result = await dispatch.api.post({
      url: 'new-order/shipment-agent-delivery-intervals',
      data: payload,
    });

    dispatch[type].fetchShipmentTimeSuccess(result);
    return result;
  } catch (err) {
    return {};
  }
};
