import React, {memo} from 'react'
import { Route, Switch } from 'react-router-dom';
import { RouterConfigContext } from 'common/router-config-context';
import { routesConfig } from 'common/collections';
import Layout from "components/layout"
import transform from 'lodash/transform'
import isEqual from 'lodash/isEqual'
import {useSelector, useStore} from "react-redux"

export const Router = memo(({ routes: propRoutes, ...extraProps }) => {
  const {
    select: { user },
  } = useStore();

  const routes = useSelector(user.getRoutes(propRoutes))

  if (!routes?.length) {
    return null;
  }

  return (
    <Switch>
      {routes.map(({ component: Component, ...route }) => {
        const { path, routes: children = [], ...rest } = route;
        const { parent } = extraProps;

        routesConfig.setSafe(path, route);

        if(children?.length){
          const childrenConfig = transform(children, (conf, child) => {
            conf[child.path] = { ...child, parent: route, siblings: children}
          }, {})

          routesConfig.mergeReverse(childrenConfig, (obj, src) => {
            if(obj){
              if(obj.parent && !src.parent){
                return {...src, routes: obj.parent.routes, props: obj.props}
              }

              const {props, ...rest} = src

              return {...obj, ...rest}
            }

            return src
          })
          routesConfig.setSafe(path, 'routes', children)
        }

        const renderRoute = props => (
          <>
            {Component && <Component {...props} {...extraProps} />}
            <Router routes={children} parent={route} />
          </>
        );

        return (
          <Route
            key="fixed" // thus preventing re-mount same component in a different Route, cause of Switch always choose one route for render
            path={path}
            exact={rest.exact}
            strict={rest.strict}
            render={props =>
              parent ? (
                renderRoute(props)
              ) : (
                <RouterConfigContext.Provider value={routesConfig.get()}>
                  <Layout>{renderRoute(props)}</Layout>
                </RouterConfigContext.Provider>
              )
            }
          />
        );
      })}
    </Switch>
  );
}, isEqual)
