import React from 'react';
import EnabledButtons from './components/EnabledButtons';
import DisabledButtons from './components/DisabledButtons';
import { hasOrderEditAccess, undef, dateUTCSplit } from 'common/util';

import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { getUser } from 'pages/Orders/selectors';
import useContextSelector from 'hooks/context/useContextSelector';
import { OrderFiltersContext } from 'pages/Orders/constances';

const OrderButtons = props => {
  const user = useSelector(getUser);
  const { row, order } = props;
  const joined = useContextSelector(OrderFiltersContext, state => state.joined);

  const isListView = undef(order);

  const shipDate = dateUTCSplit(row.shipmentDate);

  const buttonProps = {
    ...props,
    user,
    isListView,
    currentDate: new Date(new Date().setHours(0, 0, 0, 0)),
    shipDate: new Date(shipDate[0], shipDate[1], shipDate[2]),
  };

  const enabled = hasOrderEditAccess(user, row);

  const enabledOrDisabledButtons =
    enabled && !row.disabled && !joined?.id ? (
      <EnabledButtons {...buttonProps} />
    ) : (
      <DisabledButtons {...buttonProps} />
    );

  return (
    <div className="row align-items-baseline">
      {enabledOrDisabledButtons}
    </div>
  );
}

export default React.memo(OrderButtons, isEqual);
