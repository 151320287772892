const parseJsonObjectKeys = (object, keys) =>
  Object.keys(object).reduce((newObject, key) => {
    let value = object[key];

    if (keys.includes(key)) {
      try {
        value = JSON.parse(value);
      } catch (e) {}
    }

    return {
      ...newObject,
      [key]: value,
    };
  }, {});

export default parseJsonObjectKeys;
