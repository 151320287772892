import alert from 'components/native/alert/function';
import dateFormat, { download, objToQS, proxyArgsToMethods } from 'common/util';
import { CACHE_NAME } from 'common/config';
import * as creditEffects from './effects/lending-form';

export default {
  name: 'finances',
  state: {
    agreement: '',
    activeDocs: {},
  },
  reducers: {
    loadAgreement: (state, { text }) => ({ ...state, agreement: text }),
    setActiveDocs: (state, { index, flag }) => {
      state.activeDocs[index] = flag;
      return state;
    },
  },
  effects: dispatch => ({
    async getPayments(opNo) {
      try {
        const { blob, filename } = await dispatch.api.get({
          url: `finances/payments/get/${opNo}`,
          cancellation: false,
          cache: CACHE_NAME,
        });

        download(blob, filename);
      } catch (e) {
        alert(e.message);
      }
    },
    async getPurchase(opNo) {
      try {
        const { blob, filename } = await dispatch.api.get({
          url: `finances/purchases/get/${opNo}`,
          cache: CACHE_NAME,
        });

        download(blob, filename);
      } catch (e) {
        alert(e.message);
      }
    },
    async getTurnover({ dateFrom, dateTo }) {
      return await dispatch.api.get({
        url: 'finances/turnover',
        query: `?${objToQS({ dateFrom, dateTo })}`,
        cache: CACHE_NAME,
      });
    },
    async generatePayments(payload = {}) {
      const { dateFrom, dateTo, email = 0 } = payload;

      let from = dateFrom;
      let to = dateTo;

      if (!from) {
        from = new Date();
        from.setMonth(from.getMonth() - 1);
        from = dateFormat(from, 'dd.mm.yyyy');
      }

      if (!to) {
        to = new Date();
        to.setMonth(to.getMonth() + 2);
        to = dateFormat(to, 'dd.mm.yyyy');
      }

      return await dispatch.api.get({
        url: 'finances/payments/generate',
        query: `?${objToQS({ dateFrom: from, dateTo: to })}${email ? '&email=1' : ''}`,
      });
    },
    async generatePurchase({ dateFrom, dateTo }) {
      return await dispatch.api.get({
        url: '/finances/purchases/generate',
        query: `?${objToQS({ dateFrom, dateTo })}`,
      });
    },
    async generateBlocked({ orderNo, printType }) {
      dispatch.list.changeRow({
        searchKey: 'orderNo',
        needle: orderNo,
        generating: true,
      });

      await dispatch.api.post({
        url: 'finances/blocked/generate',
        data: { [orderNo]: printType },
        cancellation: false,
      });
    },
    async acceptAgreement() {
      try {
        const { message } = await dispatch.api.get({
          url: 'finances/agreement/confirm',
        });

        alert(message, { noFixedLayout: true });
      } catch (e) {
        alert('Ошибка! Попробуйте снова или Обратитесь к Вашему менеджеру!', {
          noFixedLayout: true,
        });
      }
    },
    blockedReceive({ data = [] }) {
      data.forEach(({ docNo, docs, status }) =>
        dispatch.list.changeRow({
          searchKey: 'orderNo',
          needle: docNo,
          docs,
          generating: status !== 'Готово',
        }),
      );
    },
    ...proxyArgsToMethods(creditEffects, dispatch),
  }),
  selectors: (slice, createSelector, hasProps) => ({
    getLoading: hasProps((models, index) => slice(finances => finances.activeDocs[index])),
  }),
};
