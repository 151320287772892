import { useState, useEffect, useCallback } from 'react';

export const useStickyToBottom = elemToStickId => {
  const [rect, setRect] = useState({});
  const [stickElem, setStickElem] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);

  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);

  const fixPosition = useCallback(() => {
    if (rect && stickElem) {
      if (rect.bottom < 0) {
        stickElem.style.position = 'fixed';
        stickElem.style.top = 0;
      } else {
        stickElem.style.position = 'absolute';
        stickElem.style.top = `${rect.bottom}px`;
      }
    }

    setScrollPosition(window.scrollY);
  }, [rect, stickElem]);

  useEffect(() => {
    setStickElem(document.getElementById(elemToStickId));
    fixPosition();
    window.addEventListener('scroll', fixPosition);

    return () => window.removeEventListener('scroll', fixPosition);
  }, [elemToStickId, fixPosition, rect.bottom]);

  return ref;
};
