import React from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { chunk } from 'common/util';
import { useSelector, useStore } from 'react-redux';
import { getTableListData } from 'common/selectors';
import isEmpty from 'lodash/isEmpty';
import useSelectGroup from 'pages/Orders/hooks/useSelectGroup';
import useListData from "components/table/hooks/useListData"
import { getCategoryNameFromData } from 'features/catalog/utils';

const Breadcrumbs = React.memo(() => {
  const {
    select: { items },
  } = useStore();

  const handleSelect = useSelectGroup();
  const data = useListData();
  const groups = useSelector(items.getGroups);
  const siblings = useSelector(items.getBreadcrumbs);

  if (isEmpty(groups)) {
    return null;
  }

  return (
    <div
      className={classNames('breadcrumbs breadcrumbs__groups', 'breadcrumbs__groups--order-groups')}
    >
      <ul>
        {Object.keys(groups).map((key, index, arr) => {
          const isActive = arr.length > 2 && index === 1;
          const siblingGroups = (siblings[key] && chunk(siblings[key], 12)) || [];
          const isFirst = index === 0;

          return (
            <li
              key={index}
              className={classNames('font-size__small', {
                active: isActive,
                'has-siblings': siblings[key],
              })}
            >
              <span
                onClick={
                  (isActive &&
                    handleSelect(
                      key,
                      {
                        original: {
                          code1: arr[0],
                          group1: groups[arr[0]],
                          code2: key,
                          group2: groups[key],
                        },
                      },
                      1,
                    )) ||
                  undefined
                }
              >
                {(isFirst && getCategoryNameFromData(groups[key])) || groups[key]}
              </span>
              {!!siblingGroups.length && (
                <div>
                  {siblingGroups.map((group, key) => (
                    <ul key={key}>
                      {group.map((siblingGroup, ind) => {
                        const row =
                          index === 1
                            ? {
                                code1: arr[0],
                                group1: groups[arr[0]],
                                code2: siblingGroup.id,
                                group2: siblingGroup.name,
                              }
                            : {
                                code1: arr[0],
                                group1: groups[arr[0]],
                                code2: arr[1],
                                group2: groups[arr[1]],
                                code3: siblingGroup.id,
                                group3: siblingGroup.name,
                              };

                        return (
                          <li
                            key={'last_' + ind}
                            onClick={handleSelect(
                              siblingGroup.id,
                              { original: row },
                              index === 1 ? 1 : 0,
                            )}
                          >
                            {siblingGroup.name}
                          </li>
                        );
                      })}
                    </ul>
                  ))}
                </div>
              )}
            </li>
          );
        })}
        <li>( {data.length} )</li>
      </ul>
    </div>
  );
});

export default Breadcrumbs;
