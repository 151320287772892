import { AUTH_ROUTE, LOGOUT_ROUTE, RECOVER_ROUTE } from 'common/routes/constants';

import { homeApi } from 'pages/new/home/api';
import { catalogApi } from 'features/catalog/api';
import { activeOrdersApi } from 'features/active-orders/api';
import { orderIntegrationApi } from 'pages/new/orders/pages/order-integration/api';
import { orderActionsApi } from 'features/order-actions/api';
import { orderListApi } from 'pages/new/orders/api';
import { orderApi } from 'pages/new/orders/pages/order/api';
import { ordersNewApi } from 'pages/new/orders/pages/orders-new/api';
import { searchHintsApi } from 'features/search-hints/api';
import { userApi } from 'features/user/api';
import { downloadListApi } from 'features/download-list/api';
import { goodExpansionApi } from 'features/good-expansion/api';
import { settingsApi } from 'pages/new/settings/api';
import { API_URL, FILE_URL, ROOT_URL } from './config';

export const API_MAP = {
  // Moved here cause at the end of the list this api won't work as expected
  ...downloadListApi,
  '^$': {
    endpoint: `${API_URL}/main`,
  },
  login: {
    endpoint: AUTH_ROUTE,
  },
  'logout/?([a-zA-Z0-9]+)?$': {
    endpoint: LOGOUT_ROUTE,
  },
  '^recover$': {
    endpoint: RECOVER_ROUTE,
  },
  user: {
    endpoint: `${API_URL}/v1/settings/restore/user`,
  },
  error: {
    endpoint: `${API_URL}/error`,
  },
  'renew-csrf': {
    endpoint: `${API_URL}/renew-csrf`,
  },
  'view/settings/get/?([a-zA-Z0-9]+)?$': {
    endpoint: `${API_URL}/settings`,
  },
  'orders/settings/get/?([a-zA-Z0-9]+)?$': {
    endpoint: `${API_URL}/settings/orders`,
  },
  'currency/settings/get/?([a-zA-Z0-9]+)?$': {
    endpoint: `${API_URL}/settings/currency`,
  },
  'view/settings/save': {
    endpoint: `${API_URL}/settings/save`,
  },
  news$: {
    endpoint: `${API_URL}/v1/news`,
    success: 'news/load',
  },
  'news/([0-9]+)$': {
    endpoint: `${API_URL}/v1/news`,
    success: 'news/load',
  },
  'events/?([a-z]+)?$': {
    endpoint: `${API_URL}/v1/events`,
    success: 'events/load',
  },
  'events/watch/([a-zA-Z0-9]+)?$': {
    endpoint: `${API_URL}/v1/events/index/watch`,
  },
  polls: {
    endpoint: `${API_URL}/v1/polls`,
    success: 'POLLS_SUCCESS',
  },
  'invite/send': {
    endpoint: `${API_URL}/v1/events/invite/send`,
  },
  orders$: {
    endpoint: `${API_URL}/v1/orders`,
  },
  'orders/search': {
    endpoint: `${API_URL}/v1/orders`,
  },
  'orders/iquote/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/iquote`,
    success: 'iquote/load',
  },
  'orders/create/iquote$': {
    endpoint: `${API_URL}/v1/orders/create/iquote`,
  },
  'orders/delete/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/index/delete`,
  },
  'orders/integrate/?([A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/orders/index/integrate`,
  },
  'orders/sign/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/index/sign`,
  },
  'orders/auto-sign/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/auto-sign`,
  },
  'orders/group-sign': {
    endpoint: `${API_URL}/v1/orders/index/group-sign`,
  },
  'orders/document/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/index/document`,
  },
  'orders/get/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/move/get-order-to-move`,
  },
  'orders/move/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/move`,
    success: 'order/load',
  },
  'orders/similar': {
    endpoint: `${API_URL}/v1/orders/move/get-similar-orders`,
  },
  'orders/new/?([A-Z0-9]*)': {
    endpoint: `${API_URL}/%version%/orders/create`,
    success: 'orders/load',
  },
  'orders/items': {
    endpoint: `${ROOT_URL}/dbauth`,
  },
  'orders/remains/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/remains`,
  },
  'preorders/view/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/`,
    success: 'order/load',
  },
  'orders/view/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/%version%/orders`,
    success: 'order/load',
  },
  'preorders/edit/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/preorder`,
    success: 'order/load',
  },
  'orders/edit/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/%version%/orders/edit`,
    success: 'order/load',
  },
  'orders/edit/search/?([A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/sphinx/search/main`,
  },
  'orders/edit/search-order/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/search-order`,
  },
  'orders/edit/suggest-items$': {
    endpoint: `${API_URL}/v1/sphinx/search/all-stub-suggestions`,
  },
  'orders/edit/header/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/header`,
  },
  'orders/create/header$': {
    endpoint: `${API_URL}/v1/orders/create/header`,
  },
  'orders/edit/transit-filter/([A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/orders/edit/transit-filter`,
  },
  'orders/create/headerFromTransit$': {
    endpoint: `${API_URL}/v1/orders/create/header`,
    success: 'ORDER_TRANSIT_SUCCESS',
  },
  'orders/edit/rows/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/rows`,
  },
  'orders/delete/row/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/delete-row`,
  },
  'orders/edit/group/([А-ЯA-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/group`,
  },
  'orders/edit/group-preorder/([А-ЯA-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/group-preorder`,
  },
  'orders/edit/import/?([A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/orders/edit/import`,
  },
  'orders/edit/import-file/?([A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/orders/edit/import-file`,
  },
  'orders/get/representatives$': {
    endpoint: `${API_URL}/v1/orders/edit/representatives`,
  },
  'orders/get/shipment-agent-delivery-intervals$': {
    endpoint: `${API_URL}/v1/orders/edit/shipment-agent-delivery-intervals`,
  },
  'orders/split/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/split`,
    success: 'order/load',
  },
  'orders/category/first': {
    endpoint: `${API_URL}/v1/orders/category/first-level`,
  },
  'orders/category/second/?([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/orders/category/two-levels`,
  },
  'orders/multibrands': {
    endpoint: `${API_URL}/v1/constructor`,
  },
  'preorders/category/first?([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/orders/category/first-level`,
  },
  'preorders/category/second/?([%A-Z0-9]*)$/?preorder=1': {
    endpoint: `${API_URL}/v1/orders/category/two-levels`,
  },
  'orders/category/properties-filters/?([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/orders/category/properties-filters`,
  },
  'products/view/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/item`,
    success: 'product/load',
  },
  'products/card/([A-Z0-9]+)$': {
    endpoint: '/items/view',
    success: 'orders/load',
  },
  'items/relate': {
    endpoint: '/items/relate',
    success: 'product/load',
  },
  'products/availability/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/item/index/price-and-available`,
  },
  'products/images/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/item/index/images`,
  },
  'products/properties/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/item/index/properties`,
  },
  'products/related/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/item/index/related`,
  },
  'products/analog/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/item/index/analogs`,
  },
  'queue/?([A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/queue`,
    success: 'queue/loadOrder',
  },
  'file/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/file`,
  },
  'file/generate/items$': {
    endpoint: `${FILE_URL}/export-items`,
  },
  'file/generate/spec/([0-9A-Z]+)$': {
    endpoint: `${FILE_URL}/export-specification`,
  },
  'file/get/([0-9A-Z]+)$': {
    endpoint: `${FILE_URL}`,
  },
  'file/get/items$': {
    endpoint: `${FILE_URL}/items`,
  },
  'file/get/service$': {
    endpoint: `${FILE_URL}/export-service`,
  },
  'file/get/linked$': {
    endpoint: `${FILE_URL}/export-linked-items`,
  },
  'file/get/linked-file$': {
    endpoint: `${FILE_URL}/linking-file`,
  },
  'pricelists/send': {
    endpoint: `${API_URL}/v1/pricelists/index/send`,
  },
  'pricelists/get': {
    endpoint: `${API_URL}/v1/pricelists/get`,
  },
  pricelists: {
    endpoint: `${API_URL}/v1/pricelists`,
  },
  'warranty/service/([A-Z0-9-]+)$': {
    endpoint: `${API_URL}/v1/warranty/service`,
  },
  'warranty/service/debt': {
    endpoint: `${API_URL}/v1/warranty/service/debt`,
  },
  'warranty/service/receive': {
    endpoint: `${API_URL}/v1/warranty/service/receive`,
  },
  'warranty/service/delivery': {
    endpoint: `${API_URL}/v1/warranty/service/delivery`,
  },
  'warranty/service/offset-balance': {
    endpoint: `${API_URL}/v1/warranty/service/offset-balance`,
  },
  'warranty/service/view/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/warranty/service/view`,
    success: 'warranty/setHeader',
  },
  'warranty/rules': {
    endpoint: `${API_URL}/v1/wiki/article/RULES`,
    success: 'wiki/load',
  },
  'warranty/serial/([A-Z0-9-]+)$': {
    endpoint: `${API_URL}/v1/warranty/serial`,
  },
  'help/content': {
    endpoint: `${API_URL}/v1/wiki/category/846`,
    success: 'wiki/load',
  },
  'category/([0-9]+)$': {
    endpoint: `${API_URL}/v1/wiki/category`,
    success: 'wiki/load',
  },
  'article/([0-9]+)$': {
    endpoint: `${API_URL}/v1/wiki/article`,
    success: 'wiki/load',
  },
  'vad/attachments$': {
    endpoint: `${API_URL}/v1/wiki/attachments`,
  },
  'vad/attachments/types$': {
    endpoint: `${API_URL}/v1/wiki/attachments/types`,
  },
  'vad/attachments/vendors$': {
    endpoint: `${API_URL}/v1/wiki/attachments/vendors`,
  },
  'vad/attachments/groups$': {
    endpoint: `${API_URL}/v1/wiki/attachments/groups`,
  },
  'certificates/search': {
    endpoint: `${API_URL}/v1/certificates/index/search`,
  },
  'certificates/types': {
    endpoint: `${API_URL}/v1/certificates/index/types`,
  },
  'certificates/get$': {
    endpoint: `${API_URL}/v1/certificates/get`,
  },
  'logistic/delivery/article': {
    endpoint: `${API_URL}/v1/logistic/delivery/article`,
  },
  'logistic/delivery': {
    endpoint: `${API_URL}/v1/logistic/delivery`,
    success: 'wiki/load',
  },
  'logistic/pickup$': {
    endpoint: `${API_URL}/v1/logistic/pickup`,
    success: 'wiki/load',
  },
  'logistic/pickup/article$': {
    endpoint: `${API_URL}/v1/logistic/pickup`,
    success: 'wiki/load',
  },
  'logistic/pickup/stocks': {
    endpoint: `${API_URL}/v1/logistic/pickup/stocks`,
  },
  'logistic/package': {
    endpoint: `${API_URL}/v1/wiki/article/PACKAGE_ART`,
    success: 'wiki/load',
  },
  'logistic/claims/([A-Z0-9_]+)$': {
    endpoint: `${API_URL}/v1/queue`,
    success: 'queue/loadOrder',
  },
  'logistic/claims/orders-posted': {
    endpoint: `${API_URL}/v1/logistic/claims/orders-posted`,
  },
  'logistic/claims/get$': {
    endpoint: `${API_URL}/v1/logistic/claims/get`,
  },
  'logistic/products/([a-zA-Z0-9_]+)$': {
    endpoint: `${API_URL}/v1/finances/index/accounted`,
  },
  'logistic/claims$': {
    endpoint: `${API_URL}/v1/logistic/claims`,
    success: 'claims/load',
  },
  'logistic/add-claim': {
    endpoint: `${API_URL}/v1/logistic/claims/create`,
    success: 'CREATE_CLAIM_SUCCESS',
  },
  'logistic/shipments/view/?([a-zA-Z0-9]+)?$': {
    endpoint: `${API_URL}/v1/logistic/shipments/view`,
    success: 'shipments/loadData',
  },
  'logistic/shipments': {
    endpoint: `${API_URL}/v1/logistic/shipments`,
  },
  'shipment-schedule/orders-get': {
    endpoint: `${API_URL}/v1/logistic/shipment-schedule/orders`,
  },
  'shipment-schedule/create': {
    endpoint: `${API_URL}/v1/logistic/shipment-schedule/create`,
  },
  'shipment-schedule/view/?([a-zA-Z0-9]+)?$': {
    endpoint: `${API_URL}/v1/logistic/shipment-schedule/view`,
  },
  'shipment-schedule/update/?([a-zA-Z0-9]+)?$': {
    endpoint: `${API_URL}/v1/logistic/shipment-schedule/update`,
  },
  'shipment-schedule/delete/?([a-zA-Z0-9]+)?$': {
    endpoint: `${API_URL}/v1/logistic/shipment-schedule/delete`,
  },
  'shipment-schedule/time-intervals/?([a-zA-Z0-9]+)?$': {
    endpoint: `${API_URL}/v1/logistic/shipment-schedule/time-intervals`,
  },
  /** ********* Настройки ************** */
  'settings/personal': {
    endpoint: `${API_URL}/v1/settings`,
    success: 'personal/load',
  },
  'personal-data': {
    endpoint: `${API_URL}/v1/settings`,
    success: 'personal/load',
  },
  'settings/setup': {
    endpoint: `${API_URL}/v1/settings/index/setup`,
    success: 'setup/load',
  },
  'settings/employees/edit': {
    endpoint: `${API_URL}/v1/settings`,
    success: 'personal/load',
  },
  'settings/employees/block': {
    endpoint: `${API_URL}/v1/settings/index/block`,
  },
  'settings/employees/delete': {
    endpoint: `${API_URL}/v1/settings/index/delete`,
  },
  'settings/save': {
    endpoint: `${API_URL}/v1/settings/index/save`,
  },
  'settings/permissions/save': {
    endpoint: `${API_URL}/v1/settings/save/permissions`,
  },
  'settings/dispatch/save': {
    endpoint: `${API_URL}/v1/settings/save/dispatch`,
  },
  'settings/add': {
    endpoint: `${API_URL}/v1/settings/index/add`,
  },
  'settings/employees': {
    endpoint: `${API_URL}/v1/settings/index/employees`,
    success: 'employees/load',
  },
  'settings/password-change': {
    endpoint: `${API_URL}/v1/settings/index/change-password`,
  },
  'settings/search-region': {
    endpoint: `${API_URL}/v1/settings/index/search`,
  },
  'settings/search-city': {
    endpoint: `${API_URL}/v1/settings/index/search`,
  },
  'settings/send-confirm': {
    endpoint: `${API_URL}/v1/settings/index/send-confirm`,
  },
  'settings/confirm$': {
    endpoint: `${API_URL}/v1/settings/confirm`,
    success: 'personal/confirm',
  },
  'settings/bindings$': {
    endpoint: `${API_URL}/v1/settings/bindings`,
  },
  'settings/bindings/find$': {
    endpoint: `${API_URL}/v1/settings/bindings/correspondence`,
  },
  'settings/bindings/find-ml/([A-Z0-9-_]+)$': {
    endpoint: `${API_URL}/v1/settings/bindings/find-by-ml`,
  },
  'settings/bindings/save$': {
    endpoint: `${API_URL}/v1/settings/bindings/save`,
  },
  'settings/bindings/load$': {
    endpoint: `${API_URL}/v1/settings/bindings/load`,
  },
  'settings/bindings/load-price$': {
    endpoint: `${API_URL}/v1/settings/bindings/load-price`,
  },
  'settings/bindings/save-price$': {
    endpoint: `${API_URL}/v1/settings/bindings/save-price`,
  },
  'settings/linked$': {
    endpoint: `${API_URL}/v1/settings/bindings/find`,
  },
  'settings/linked/remove$': {
    endpoint: `${API_URL}/v1/settings/bindings/delete`,
  },
  'settings/password-recover$': {
    endpoint: `${API_URL}/v1/settings/recover`,
  },
  /** ************* Финансы ************** */
  // генерация файла ОСВ
  'finances/osv/generate/([0-9]+)$': {
    endpoint: `${API_URL}/v1/finances/osv/generate`,
  },
  // отправить файл ОСВ на почту
  'finances/osv/send/([0-9]+)$': {
    endpoint: `${API_URL}/v1/finances/osv/send`,
  },
  'finances/osv/get/([0-9]+)$': {
    endpoint: `${API_URL}/v1/finances/get/osv`,
  },
  // страница "Финансы \ Оборотно-сальдовая ведомость"
  'finances/osv$': {
    endpoint: `${API_URL}/v1/finances/osv`,
    success: 'osv/load',
  },
  // страница "Финансы \ Баланс на дату"
  'finances/balance': {
    endpoint: `${API_URL}/v1/finances/balance`,
    success: 'balance/load',
  },
  // страница "Финансы \ Оборот"
  'finances/turnover': {
    endpoint: `${API_URL}/v1/finances/turnover`,
  },
  // страница "Финансы \ Документы с просроченным сроком возврата"
  'finances/expired': {
    endpoint: `${API_URL}/v1/finances/expired`,
  },
  // страница "Финансы \ Список блокирующих документов \ Запрос на генерацию документов
  'finances/blocked/generate': {
    endpoint: `${API_URL}/v1/finances/blocked/generate`,
  },
  // страница "Финансы \ Список блокирующих документов"
  'finances/blocked': {
    endpoint: `${API_URL}/v1/finances/blocked`,
  },
  // страница "Финансы \ Доверенности"
  'finances/proxy': {
    endpoint: `${API_URL}/v1/finances/proxy`,
  },
  // генерация файла графика платежей
  'finances/payments/generate': {
    endpoint: `${API_URL}/v1/finances/payments/generate`,
  },
  'finances/payments/get/([0-9]+)$': {
    endpoint: `${API_URL}/v1/finances/get/payments`,
  },
  // страница "Финансы \ Графики платежей"
  'finances/payments$': {
    endpoint: `${API_URL}/v1/finances/payments`,
  },
  // страница "Финансы \ Платежный календарь"
  'finances/calendar': {
    endpoint: `${API_URL}/v1/finances/payments/chart-data`,
    success: 'chart/load',
  },
  'finances/agreement$': {
    endpoint: `${API_URL}/v1/finances/agreement/text`,
    success: 'finances/loadAgreement',
  },
  'finances/agreement/confirm$': {
    endpoint: `${API_URL}/v1/finances/agreement`,
  },
  'finances/accounted$': {
    endpoint: `${API_URL}/v1/finances/index/accounted`,
  },
  'finances/accounted/([a-zA-Z0-9_]+)$': {
    endpoint: `${API_URL}/v1/finances/index/accounted`,
    success: 'accounted/load',
  },
  'finances/accounted/document/([a-zA-Z0-9_]+)$': {
    endpoint: `${API_URL}/v1/finances/index/document`,
  },
  'finances/accounted/attachments/([a-zA-Z0-9_]+)$': {
    endpoint: `${FILE_URL}/attachments`,
  },
  'finances/purchases/generate': {
    endpoint: `${API_URL}/v1/finances/purchases/generate`,
  },
  'finances/purchases/get/([0-9]+)$': {
    endpoint: `${API_URL}/v1/finances/get/purchases`,
  },
  'finances/purchases$': {
    endpoint: `${API_URL}/v1/finances/purchases`,
  },
  // страница "Помощь \ Контакты"
  'help/contacts$': {
    endpoint: `${API_URL}/v1/help/contacts`,
    success: 'user/loadContacts',
  },
  'help/contacts/categories': {
    endpoint: `${API_URL}/v1/help/contacts/categories`,
  },
  // получение списка менеджеров по категории
  'help/contacts/managers/?([A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/help/contacts/managers`,
  },
  'message/accept': {
    endpoint: `${API_URL}/message/accept`,
    success: 'MESSAGE_ACCEPT_SUCCESS',
  },
  actions$: {
    endpoint: `${API_URL}/v1/actions`,
    success: 'actions/load',
  },
  'actions/archive$': {
    endpoint: `${API_URL}/v1/actions/index/archive`,
    success: 'actions/load',
  },
  'actions/([0-9A-Za-z]+)$': {
    endpoint: `${API_URL}/v1/actions/index/view`,
    success: 'actions/load',
  },
  'actions/accept/([a-zA-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/actions/index/accept`,
  },
  'rfq/send': {
    endpoint: `${API_URL}/v1/rfq/index/send`,
  },
  'special-prices/list': {
    endpoint: `${API_URL}/v1/rfq`,
  },
  'search/contact-inn': {
    endpoint: `${API_URL}/v1/sphinx/search/contact-inn`,
  },
  'search/contact': {
    endpoint: `${API_URL}/v1/sphinx/search/contact`,
  },
  'finances/lending-form': {
    endpoint: `${API_URL}/v1/finances/credit/contractors`,
  },
  'finances/credit/doc-list': {
    endpoint: `${API_URL}/v1/finances/credit/doc-list`,
  },
  'finances/credit/upload-doc': {
    endpoint: `${API_URL}/v1/finances/credit/upload-doc`,
  },
  'finances/credit/delete-doc': {
    endpoint: `${API_URL}/v1/finances/credit/delete-doc`,
  },
  'finances/credit/ask': {
    endpoint: `${API_URL}/v1/finances/credit/ask`,
  },
  'shipments-subscription': {
    endpoint: `${API_URL}/v1/logistic/shipments-subscription`,
  },
  'subscription/update': {
    endpoint: `${API_URL}/v1/logistic/shipments-subscription/update`,
  },
  'shipments/branches': {
    endpoint: `${API_URL}/v1/logistic/shipments/branches`,
  },
  'shipments/trucks': {
    endpoint: `${API_URL}/v1/logistic/shipments/trucks`,
  },
  'logistic/agents$': {
    endpoint: `${API_URL}/v1/logistic/shipments/shipment-agent-list`,
    success: 'agents/loadAgents',
  },
  'logistic/agents/new': {
    endpoint: `${API_URL}/v1/logistic/shipments/add-shipping-agent`,
  },
  feedback: {
    endpoint: `${API_URL}/v1/feedback`,
  },
  'search/vendor': {
    endpoint: `${API_URL}/v1/sphinx/search/vendor`,
  },
  'special-prices/detail/([0-9]+(?:-[0-9]+)?)': {
    endpoint: `${API_URL}/v1/rfq/index/view`,
    success: 'specialPrice/loadDetail',
  },
  'detail/special-price/add-comment/([0-9]+)$': {
    endpoint: `${API_URL}/v1/rfq/index/add-comment`,
  },
  'get-attach': {
    endpoint: `${API_URL}/v1/rfq/index/get-attach`,
  },
  'search/street$': {
    endpoint: `${API_URL}/v1/sphinx/search/street?`,
    success: 'ADDRESS_SUCCESS',
  },
  'tz/list': {
    endpoint: `${API_URL}/v1/rfq/index/tech-spec`,
  },
  'tz/detail/([0-9-]+)$': {
    endpoint: `${API_URL}/v1/rfq/index/view-t-s`,
    success: 'tz/loadDetail',
  },
  'tz/create': {
    endpoint: `${API_URL}/v1/rfq/index/send-t-s`,
  },
  'tz/add-comment': {
    endpoint: `${API_URL}/v1/rfq/index/add-comment`,
  },
  'tz/reopen-status': {
    endpoint: `${API_URL}/v1/rfq/index/reopen`,
  },
  'shiping-agent/list': {
    endpoint: `${API_URL}/v1/logistic/shipments/shipment-agent-draft-list`,
  },
  'vad/demofund$': {
    endpoint: `${API_URL}/v1/demofond`,
  },
  'vad/demofond/create': {
    endpoint: `${API_URL}/v1/demofond/index/send`,
  },
  'demofund/suggest-items': {
    endpoint: `${API_URL}/v1/sphinx/search/demofond-suggestions`,
  },
  'demofund/item': {
    endpoint: `${API_URL}/v1/sphinx/search/demofond-main`,
  },
  'demofund/category/list/?([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/demofond/category/list`,
  },
  'demofond/category/first': {
    endpoint: `${API_URL}/v1/demofond/category/first-level`,
  },
  'demo/add-comment': {
    endpoint: `${API_URL}/v1/demofond/index/add-comment`,
  },
  'vad/demofond-list': {
    endpoint: `${API_URL}/v1/demofond/index/list`,
  },
  'vad/demofond-detail/?([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/demofond/index/view`,
    success: 'demo/loadDetail',
  },
  'demofond/category/second/?([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/demofond/category/two-levels`,
  },
  banners: {
    endpoint: `${API_URL}/v1/banners`,
  },
  ads: {
    endpoint: `${API_URL}/v1/news/index/ads`,
  },
  'msk-avail-filter/([a-zA-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/msk-avail-filter`,
  },
  'order-shipment-dates': {
    endpoint: `${API_URL}/v1/orders/edit/shipment-dates`,
  },
  'go-iquote/([0-9-]+)$': {
    endpoint: `${API_URL}/v1/item/index/go-iquote`,
  },
  'hp-iquote-servers': {
    endpoint: `${API_URL}/v1/orders/create/hp-iquote-servers`,
  },
  'hp-iquote-desktops': {
    endpoint: `${API_URL}/v1/orders/create/hp-iquote-desktops`,
  },
  'switch-design/([a-zA-Z0-9]+)$': {
    endpoint: `${API_URL}/v2/settings/switch`,
  },
  'sign-up': {
    endpoint: `${API_URL}/sign-up`,
    success: 'invitedUser/load',
  },
  register: {
    endpoint: `${API_URL}/v1/settings/index/register`,
  },
  'password/recover': {
    endpoint: `${API_URL}/password-recover`,
    success: 'invitedUser/load',
  },
  unlocking: {
    endpoint: `${API_URL}/unblock-request`,
  },
  // NEW
  ...homeApi,
  ...catalogApi,
  ...activeOrdersApi,
  ...orderIntegrationApi,
  ...orderListApi,
  ...orderApi,
  ...ordersNewApi,
  ...searchHintsApi,
  ...userApi,
  ...orderActionsApi,
  ...goodExpansionApi,
  ...settingsApi,
};

export default API_MAP;
