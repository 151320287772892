import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Edit = ({
  className,
  color = '#236192',
  name,
  width = '12',
  height = '12',
  viewBox = '0 0 12 12',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M8.26236 1.71718C8.82022 1.15933 9.7247 1.15933 10.2826 1.71719C10.8404 2.27504 10.8404 3.1795 10.2826 3.73736L4.26019 9.75962L1.94175 10.0578L2.23997 7.73944L8.26236 1.71718Z"
          stroke="#236192"
        />
        <rect
          width="3.85699"
          height="0.771397"
          transform="matrix(0.707114 0.7071 -0.707114 0.7071 7.09033 2.18224)"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={[width]} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Edit.defaultProps = {
  className: '',
  color: '#236192',
  width: '12',
  height: '12',
  viewBox: '0 0 12 12',
};

Edit.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
