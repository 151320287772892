import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './scroller.module.scss';

/**
 * Renders a <Scroller /> component
 * @component
 * @example
 *
 * return (
 *   <Scroller />
 * )
 */
export const Scroller = forwardRef(
  (
    {
      className = '',
      hasArrows = false,
      children = null,
      theme = 'primary',
      withScrollTrackPadding = false,
      ...rest
    },
    ref,
  ) => {
    const classes = cn(styles['scroller'], className, {
      [styles['scroller--with-arrows']]: hasArrows,
      [styles['scroller--primary']]: theme === 'primary',
      [styles['scroller--secondary']]: theme === 'secondary',
      [styles['scroller--with-scroll-track-padding']]: withScrollTrackPadding,
    });

    return (
      <div className={classes} data-testid="scroller" ref={ref} {...rest}>
        {children}
      </div>
    );
  },
);

Scroller.displayName = 'Scroller';

Scroller.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string.isRequired,
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
  /**
   * Scroll controls
   */
  hasArrows: PropTypes.bool.isRequired,
  /**
   *  'primary' | 'secondary'
   */
  theme: PropTypes.string.isRequired,
  withScrollTrackPadding: PropTypes.bool.isRequired,
};
