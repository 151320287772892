import React, { useState, useEffect, useRef } from 'react';
import { regExpOnlyNumber } from 'helpers/regexpes';
import alert from 'components/native/alert/function';
import { empty } from 'components/empty';

const MoveCell = row => {
  const [val, setVal] = useState(row?.original?.desiredQty);
  const { current: prevVal } = useRef(row?.original?.desiredQty);

  useEffect(() => {
    setVal(row?.original?.desiredQty);
  }, [row?.original?.desiredQty]);

  if (!row) return null;

  const {
    tdProps: {
      rest: { toggle, isSelected, moveRow },
    },
  } = row;

  const handleChange = e => {
    const { value } = e.target;

    if (!regExpOnlyNumber.test(value)) {
      alert(`Введите целое положительное число!`);
      return setVal(prevVal);
    }

    if (+value > +prevVal) {
      alert(`Максимальное кол-во - ${prevVal}`);
      return setVal(prevVal);
    }

    setVal(value);
  };

  const handleBlur = e => {
    const { value } = e.target;
    const { original } = row;
    const { minPackaged } = original;

    if (value === '') {
      alert('Поле Желаемого кол-ва не может быть пустым!');

      setVal(prevVal);
    } else if (value <= 0) {
      alert(`Нельзя перенести товар у которого Желаемое кол-во равно ${value}`);

      setVal(prevVal);
    } else if (+minPackaged !== 0 && value % minPackaged !== 0) {
      alert(`Данный товар продается в упаковке в количестве ${minPackaged} штук.
      Перенести такой товар с указанным значением не кратным ${minPackaged} - невозможно.`);

      setVal(prevVal);
    }

    if (isSelected) {
      moveRow({ original: { ...original, desiredQty: value }, index: row.index }, true, empty);
    }
  };

  const handeToggle = () => {
    toggle(`select-${row.original.id}`, false, { ...row.original, desiredQty: val });
  };

  return (
    <>
      <input
        autoComplete="off"
        type="text"
        name="move-item"
        onChange={handleChange}
        onBlur={handleBlur}
        value={val}
        className={[
          'form-control',
          'form-control-sm',
          'move-control',
          'move-control--with-button',
        ].join(' ')}
      />
      <i
        className={`move-button icon icon-${isSelected ? 'delete' : 'add'}`}
        onClick={handeToggle}
      />
    </>
  );
};

export default MoveCell;
