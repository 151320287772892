import React, { useState, useCallback, useRef, useEffect } from 'react';
import FormContainer from './FormContainer';
import { LINKED_MAX_FILE_SIZE } from 'common/config';
import { change } from 'redux-form';

const InputFile = props => {
  const {
    input,
    meta: { warning, dispatch },
    fileIndex,
    deleteFile,
    addFile,
    files,
  } = props;
  const defaultFileName = 'Выберите файл';
  const [fileName, setFileName] = useState(defaultFileName);
  const [fileSize, setFileSize] = useState('');
  const [fileEmpty, setFileEmpty] = useState(true);
  const [warn, setWarn] = useState(warning);
  const fileRef = useRef();
  const hasError = warning && warning.length;

  useEffect(() => {
    warning && setWarn(warning);
  }, [warning]);

  const fileSizeToText = useCallback(size => {
    let calcSize = size / 1024;
    if (calcSize < 1) {
      return size + ' Б';
    }
    calcSize = calcSize / 1024;
    if (calcSize < 1) {
      return parseInt(calcSize * 1024) + ' КБ';
    }
    return parseInt(calcSize) + ' МБ';
  }, []);

  if (fileRef.current) {
    if (!hasError) {
      if (fileRef.current.files.length && fileName !== fileRef.current.files[0].name) {
        setFileName(fileRef.current.files[0].name);
        setFileEmpty(false);
        setFileSize(fileSizeToText(fileRef.current.files[0].size));
        addFile();
      }
      if (!fileRef.current.files.length && fileName !== defaultFileName) {
        deleteFile(fileIndex);
        setFileEmpty(true);
        setWarn(null);
      }
    }
    if (hasError) {
      fileRef.current.value = null;
      dispatch(change('FEEDBACK_FORM', [input.name], null));
    }
  }

  const deleteFileHandle = () => {
    deleteFile(fileIndex);
  };

  return (
    <FormContainer error={warn} touched={warn} filesLength={files.length}>
      <div className={'file-description'}>
        <div
          className={fileEmpty ? 'file-empty' : 'file-not-empty'}
          onClick={() => fileRef.current.click()}
        >
          {fileName}
        </div>
        {fileEmpty ? (
          <span className={'file-size-max'}>
            (.doc, .xls, .jpg, .mp4 и др.) Максимальный размер файла{' '}
            {LINKED_MAX_FILE_SIZE / 1024 / 1024} МБ
          </span>
        ) : (
          <span className={'file-size'}>
            {' '}
            {fileSize} <div onClick={deleteFileHandle} className={'delete-file'} />
          </span>
        )}
      </div>
      <div className={'file-container'}>
        <input {...input} type={'file'} value={null} ref={fileRef} />
      </div>
    </FormContainer>
  );
};
export default InputFile;
