import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Photo = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M5.8 1.5C5.60508 1.5 5.42792 1.61328 5.34614 1.79022L4.41362 3.80769H2.06667C1.16404 3.80769 0.5 4.58465 0.5 5.46154V15.8462C0.5 16.723 1.16404 17.5 2.06667 17.5H15.9333C16.836 17.5 17.5 16.723 17.5 15.8462V5.46154C17.5 4.58465 16.836 3.80769 15.9333 3.80769H11.4531L10.5205 1.79022C10.4387 1.61328 10.2616 1.5 10.0667 1.5H5.8Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 15C9.98528 15 12 12.9853 12 10.5C12 8.01472 9.98528 6 7.5 6C5.01472 6 3 8.01472 3 10.5C3 12.9853 5.01472 15 7.5 15Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 13C8.88071 13 10 11.8807 10 10.5C10 9.11929 8.88071 8 7.5 8C6.11929 8 5 9.11929 5 10.5C5 11.8807 6.11929 13 7.5 13Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 7C14.7761 7 15 6.77614 15 6.5C15 6.22386 14.7761 6 14.5 6C14.2239 6 14 6.22386 14 6.5C14 6.77614 14.2239 7 14.5 7Z"
        fill={color}
      />
    </svg>
  );
};

Photo.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Photo.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
