// @ts-nocheck
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Spinner } from 'common/ui/spinner';
import ReactTooltip from 'react-tooltip';
import { IconButton } from '../icon-button';
import { Icon } from '../icon';

import styles from './search-input.module.scss';

/**
 * Renders a <SearchInput /> component
 * @component
 * @example
 *
 * return (
 *   <SearchInput />
 * )
 */
export const SearchInput = forwardRef(
  (
    {
      className = '',
      disabled,
      loading,
      showSearchIcon,
      value,
      theme = 'primary',
      onClear,
      onSearch,
      ...rest
    },
    ref,
  ) => {
    const classes = cn(styles['search-input'], className, {
      [styles['search-input--without-search-icon']]: !showSearchIcon,
      [styles['search-input--primary']]: theme === 'primary',
      [styles['search-input--secondary']]: theme === 'secondary',
    });
    const hasValue = value && value.length > 0;

    return (
      <div className={classes}>
        <input
          disabled={disabled}
          ref={ref}
          role="searchbox"
          value={value}
          type="search"
          data-testid="search-input"
          data-tip={`По запросу <b>${value}</b> ничего не найдено`}
          data-html
          data-for="not-found"
          data-event="hided"
          {...rest}
        />
        {loading && (
          <span
            className={cn(styles['search-input__spinner'], {
              [styles['search-input__spinner--without-search-icon']]: !showSearchIcon,
            })}
          >
            <Spinner size="xl" />
          </span>
        )}
        {hasValue && (
          <IconButton
            className={cn(styles['search-input__clear-button'], {
              [styles['search-input__clear-button--without-search-icon']]: !showSearchIcon,
            })}
            disabled={loading}
            onClick={onClear}
          >
            <Icon name="cancel" />
          </IconButton>
        )}
        {showSearchIcon && (
          <IconButton
            className={cn(
              styles['search-input__search-button'],
              styles['search-input__search-button--with-search'],
              {
                [styles['search-input__search-button--disabled']]: disabled,
                [styles['search-input__search-button--loading']]: loading,
              },
            )}
            disabled={loading}
            onClick={async () => onSearch(value)}
          >
            <Icon name="search" color="brand-blue" />
          </IconButton>
        )}
        <ReactTooltip
          id="not-found"
          place="bottom"
          className="brand__tooltip"
          globalEventOff="click"
          type="light"
        />
      </div>
    );
  },
);

SearchInput.displayName = 'SearchInput';

SearchInput.defaultProps = {
  className: '',
  disabled: false,
  id: null,
  loading: false,
  maxLength: Infinity,
  name: '',
  placeholder: '',
  readOnly: false,
  required: false,
  showSearchIcon: true,
  tabIndex: 0,
  value: '',
  theme: 'primary',
  onBlur: () => {},
  onClear: () => {},
  onChange: () => {},
  onFocus: () => {},
  onKeyPress: () => {},
  onKeyUp: () => {},
  onSearch: () => {},
};

SearchInput.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * Visually and functionally disable the search input.
   */
  disabled: PropTypes.bool,
  /**
   * Adds a HTML `id` attribute to the search-input. This is used for linking the HTML with a label
   */
  id: PropTypes.string,
  /**
   * Loading
   */
  loading: PropTypes.bool,
  /**
   * The maximum number of characters that a user can enter. `onChange` will not fire if a user
   * enters a character that exceeds `maxLength`.
   */
  maxLength: PropTypes.number,
  /**
   * Adds `name` HTML attribute to element, indicating the property name associated with the search input.
   */
  name: PropTypes.string,
  /**
   * Text that appears within the search-input when there is no `value`.
   */
  placeholder: PropTypes.string,
  /**
   * Adds `readonly` HTML attribute, allowing users to select (but not modify) the input.
   */
  readOnly: PropTypes.bool,
  /**
   * Adds the `required` HTML attribute to the search input.
   */
  required: PropTypes.bool,
  /**
   * show or hide search icon
   */
  showSearchIcon: PropTypes.bool,
  /**
   * Accessible order
   */
  tabIndex: PropTypes.number,
  /**
   *  'primary' | 'secondary'
   */
  theme: PropTypes.string,
  /**
   * The current value of the search input.
   */
  value: PropTypes.string,
  /**
   * Fires when the search input loses focus.
   */
  onBlur: PropTypes.func,
  /**
   * The function that is called when the search input value changes.
   *
   * It receives two arguments: `onChange(newValue, event)`.
   *
   * The consumer of this component should use that data to update the `value` prop passed in to
   * this component.
   */
  onChange: PropTypes.func,
  /**
   * The function that is called when the search input value is cleared.
   */
  onClear: PropTypes.func,
  /**
   * Fires when the search input receives focus.
   */
  onFocus: PropTypes.func,
  /**
   * Fires when the search input receives text that was pressed with keyboard.
   */
  onKeyPress: PropTypes.func,
  /**
   * Fires when the search input receives text that was pressed with keyboard.
   */
  onKeyUp: PropTypes.func,
  /**
   * Fires when the search icon is clicked.
   */
  onSearch: PropTypes.func,
};
