import React from 'react';
import { gte } from 'lodash';

function FormStepContainer({ children, title, step, useDivider, currentStep, styles }) {
  return (
    <div
      className="form-step-container"
      style={{ ...styles, display: gte(currentStep, step) ? 'block' : 'none' }}
    >
      <h5 className="form-step-container__title">{`${step}. ${title}`}</h5>
      <div className="form-step-container__content">{children}</div>
      {useDivider && <div className="divider" />}
    </div>
  );
}

export default FormStepContainer;
