import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const StarOutlined = ({
  className,
  color = '#ffa900',
  name,
  width = '14',
  height = '13',
  viewBox = '0 0 14 13',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M7 1.12978L8.60888 4.38972L8.72521 4.62544L8.98535 4.66324L12.5829 5.186L9.97969 7.72352L9.79146 7.907L9.83589 8.16608L10.4504 11.7491L7.23267 10.0574L7 9.93511L6.76733 10.0574L3.54957 11.7491L4.16411 8.16608L4.20854 7.907L4.02031 7.72352L1.41708 5.186L5.01465 4.66324L5.27478 4.62544L5.39112 4.38972L7 1.12978Z"
        stroke={color}
      />
      />
    </svg>
  );
};

StarOutlined.defaultProps = {
  className: '',
  color: '#ffa900',
  width: '14',
  height: '13',
  viewBox: '0 0 14 13',
};

StarOutlined.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
