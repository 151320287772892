import React from 'react';
import ReactTable from 'react-table-v6';

const getTrProps = () => ({ className: 'child__row' });

const CustomSubComponent = ({ data, columns, sorted }) => {
  const dataTable = Array.isArray(data) ? data : [];

  if (!dataTable.length) {
    return null;
  }

  return (
    <ReactTable
      className="custom-subcomponent"
      data={dataTable}
      columns={columns}
      pageSize={dataTable.length}
      defaultPageSize={dataTable.length}
      showPagination={false}
      getTrProps={getTrProps}
      sorted={sorted}
      minRows={1}
    />
  );
};

export default CustomSubComponent;
