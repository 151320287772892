/* eslint-disable no-unused-vars */

import React, { useRef, useCallback } from 'react';
import Content from 'components/layout/content';
import { Link, useLocation } from 'react-router-dom';
import { QUEUE_LINKS, QUEUE_PAGE } from 'common/config';
import TableList from 'components/table/list';
import { columns } from './columns';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import withApi from 'hoc/withApi';
import GoodsList from 'pages/Settings/components/bindings/components/goods';

const paginationStyle = { justifyContent: 'flex-start' };

const Bindings = () => {
  const { pathname } = useLocation();
  const { bindings } = useDispatch();

  const exportingLinked = useSelector(getEffectLoading('bindings', 'exportLinked'));
  const choosed = useSelector(state => state.bindings.choosed);
  const filtered = useSelector(state => state.bindings.filtered);
  const pages = useSelector(state => state.bindings.pages);
  const type = useSelector(state => state.bindings.type);

  const searchRef = useRef();

  const handleSearch = () => {
    bindings.search(searchRef.current.value);
  };

  const clearSearch = e => {
    e.preventDefault();
    const val = searchRef.current.value;

    if (val) {
      searchRef.current.value = ''; // eslint-disable-line
      bindings.clearSearch();
    }
  };

  const exportLinked = async e => {
    e.preventDefault();

    await bindings.exportLinked();
  };

  const chooseRow = row => async () => {
    await bindings.chooseRow(row);
  };

  const getTrProps = useCallback(
    (state, info) => ({
      onClick: chooseRow(info),
      className: `rt-tr__item-draft ${
        info && choosed.no === info.original.no ? 'rt-tr__item-draft--active' : ''
      }`,
    }),
    [choosed.no],
  );

  return (
    <Content title="Привязка товаров">
      <div className="bindings-actions">
        <div>
          <Link to="/settings/bindings/load-price" className="section-nav">
            <i className="icon icon-from-file" /> Загрузить прайс
          </Link>
        </div>
        <div>
          <Link to="/settings/bindings/load-links" className="section-nav">
            <i className="icon icon-from-file" /> Загрузить привязки из файла
          </Link>
        </div>
        <div>
          <Link
            to={{ pathname: QUEUE_PAGE, state: { queueType: QUEUE_LINKS } }}
            className="section-nav"
          >
            <i className="icon icon-queue" /> Просмотр очереди
          </Link>
        </div>
      </div>
      <div className="bindings-container">
        <div style={{ position: 'relative' }}>
          <div className="goods-actions">
            <h6>Мои товары</h6>
            <div>
              <label>
                <input
                  type="radio"
                  name="good-types"
                  checked={type === 'all'}
                  value="all"
                  onChange={bindings.switchType}
                />{' '}
                все
              </label>
              <label>
                <input
                  type="radio"
                  name="good-types"
                  checked={type === 'linked'}
                  value="linked"
                  onChange={bindings.switchType}
                />{' '}
                привязанные
              </label>
              <label>
                <input
                  type="radio"
                  name="good-types"
                  checked={type === 'unlinked'}
                  value="unlinked"
                  onChange={bindings.switchType}
                />{' '}
                непривязанные
              </label>
            </div>
          </div>
          <div className="goods-actions">
            `
            <input
              type="text"
              className="form-control form-control-sm"
              ref={searchRef}
              placeholder="Введите id, бренд, Part# или наименование товара"
            />{' '}
            {/* eslint-disable-line */}
            <a href="" onClick={clearSearch} className="clear-filters">
              <i className="icon icon-remove-filters" title="Очистить фильтр" />
            </a>
            <button className="btn btn--rect btn-outline-danger" onClick={handleSearch}>
              Найти
            </button>
          </div>
          <div className="goods-container">
            <TableList
              columns={columns}
              pages={pages}
              defaultPageSize={19}
              manual
              filtered={filtered}
              route={pathname}
              showPageSizeOptions={false}
              paginationStyle={paginationStyle}
              getTrProps={getTrProps}
            />
          </div>
          <button
            onClick={exportLinked}
            className="link-export-items btn btn--rect btn-sm btn-danger"
            disabled={exportingLinked}
          >
            Выгрузить привязки
          </button>
        </div>
        <GoodsList choosed={choosed} />
      </div>
    </Content>
  );
};

export default withApi(Bindings);
