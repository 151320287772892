import React, { useState, useEffect } from 'react';
import OrderButtons from '../../common/order-buttons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import get from 'lodash/get';
import ItemsSpecification from 'pages/Orders/components/common/items-spec';
import { isCactus } from 'common/config';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';

const documentZprType = isCactus
  ? [{ value: 3, label: 'Коммерческое предложение' }]
  : [
      { value: 3, label: 'Коммерческое предложение' },
      { value: 5, label: 'Статус транзитов' },
    ];

const OrderActions = ({ order }) => {
  const isZpr = Number(order.docType) === 1;
  const location = useLocation();

  const {
    order: { handleExport },
  } = useDispatch();
  const fetching = useSelector(getEffectLoading('order', 'handleExport'));

  const showSpecIcon = /orders\/view/.test(get(location, 'pathname', ''));

  const [documentType, setDocumentType] = useState(3);
  const [documentFormat, setDocumentFormat] = useState(1);
  const [documentEmail, setDocumentEmail] = useState('');
  const [documentTypeOptions, setDocumentTypeOptions] = useState(documentZprType);
  const [documentFormatOptions, setDocumentFormatOptions] = useState([{ value: 1, label: 'XLS' }]);

  useEffect(() => {
    setDocumentType(isZpr ? 1 : 3);
    setDocumentTypeOptions(
      isZpr
        ? [
            { value: 1, label: 'Балансовая записка' },
            { value: 2, label: 'Счет' },
          ]
        : documentZprType,
    );

    setDocumentFormatOptions(
      isZpr
        ? [
            { value: 1, label: 'XLS' },
            { value: 2, label: 'PDF' },
          ]
        : [{ value: 1, label: 'XLS' }],
    );
  }, [isZpr]);

  return (
    <div
      className={['actions-container', 'actions-container--order', 'align-items-center'].join(' ')}
    >
      <div className="row event-actions">
        <div>
          <Link to="/orders" className="section-nav">
            <i className="icon icon-back-icon" /> Список заказов
          </Link>
        </div>
        <div className="order-actions">
          <OrderButtons row={order} index={null} isOrderPage />
        </div>
        {showSpecIcon && <ItemsSpecification />}
        <div className="request-document">
          <label>Запрос документа</label>
          <Select
            name="document-type"
            title="Тип документа"
            style={{ width: 180 }}
            searchable={false}
            clearable={false}
            options={documentTypeOptions}
            value={documentType}
            onChange={option => setDocumentType(option.value)}
          />
          <Select
            name="document-format"
            title="Формат файла"
            style={{ width: '80px' }}
            value={documentFormat}
            searchable={false}
            clearable={false}
            options={documentFormatOptions}
            onChange={option => setDocumentFormat(option.value)}
          />
          <input
            className="email-checkbox"
            checked={documentEmail}
            onChange={e => setDocumentEmail(e.target.checked ? 'on' : '')}
            type="checkbox"
            name="email"
          />
          <label className="email-checkbox">Получить&nbsp;по&nbsp;почте</label>
          <button
            className="btn btn-danger btn--rect"
            onClick={async () =>
              await handleExport({
                id: order.orderNo,
                type: documentType,
                format: documentFormat,
                email: documentEmail
              })
            }
            disabled={fetching}
          >
            Продолжить
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderActions;
