import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTable from 'react-table-v6';
import Content from 'components/layout/content';
import alert from 'components/native/alert/function';
import { getEffectLoading } from 'common/selectors';
import { columns } from 'pages/Warranty/components/serial/columns/serial';
import { useLocation } from 'react-router-dom';

const getTdProps = (state, row, column) => ({
  className:
    column.id === 'expireDate' && Number(row?.original?.expired) === 1 ? 'serial-expired' : '',
});

const defaultSorted = [
  {
    id: 'expireDate',
    desc: true,
  },
];

const SerialCheck = () => {
  const fetching = useSelector(getEffectLoading('warranty', 'searchBySerial'));
  const [serial, setSerial] = useState('');
  const [mode, setMode] = useState('0');
  const [data, setData] = useState([]);
  const { pathname } = useLocation();

  const {
    warranty: { searchBySerial },
  } = useDispatch();

  const searchItems = useCallback(async () => {
    const val = serial.toUpperCase();

    if (val.length < 5) {
      alert('Серийный номер не может быть меньше 5 символов!');

      return false;
    }

    setData(await searchBySerial({ val, mode }));
  }, [serial, mode]);

  const handleModeChange = ({ target }) => {
    setMode(target.value);
  };
  const handleSerialChange = ({ target }) => {
    setSerial(target.value);
  };

  const isSerial = pathname.includes('serial');

  const serialTable = useMemo(() => {
    if (!isSerial) {
      return null;
    }

    return (
      <div className="service-table-container service-table-container__serial">
        <ReactTable
          data={data}
          columns={columns}
          getTdProps={getTdProps}
          defaultSorted={defaultSorted}
          loading={fetching}
          minRows={1}
          noDataText="Данных нет"
          showPagination={false}
        />
      </div>
    );
  }, [isSerial, fetching, data]);

  return (
    <Content title="Проверка серийных номеров">
      <div className="warranty-actions warranty-actions__serial justify-content-center">
        <div>
          <input
            type="text"
            name="serialNo"
            onChange={handleSerialChange}
            className="form-control form-control-sm warranty-filter"
            value={serial}
            placeholder="Введите серийный номер"
          />
          <button
            className="btn btn--rect btn-danger test"
            disabled={fetching}
            onClick={searchItems}
          >
            Проверить
          </button>
        </div>
        <div>
          <label>
            <input
              type="radio"
              value="1"
              name="mode"
              onChange={handleModeChange}
              checked={Number(mode) === 1}
            />
            поиск по началу
          </label>
          <label>
            <input
              type="radio"
              value="2"
              name="mode"
              onChange={handleModeChange}
              checked={Number(mode) === 2}
            />
            поиск по концу
          </label>
        </div>
      </div>
      {serialTable}
    </Content>
  );
};

export default SerialCheck;
