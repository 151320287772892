import React from 'react';
import FormContainer from './FormContainer';

const FieldTextarea = props => {
  const { input } = props;

  return (
    <FormContainer label="Описание проблемы" error={props.meta.error} touched={props.meta.touched}>
      <textarea {...input} className={'form-control'} />
    </FormContainer>
  );
};
export default FieldTextarea;
