import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import Catalog from 'pages/Orders/components/catalog';
import SearchProductInput from './SearchProductInput';
import SelectProduct from './SelectProduct';
import { isCactus } from 'common/config';
import { useSelector } from 'react-redux';
import { getClaimsFormValue } from 'pages/Logistic/selectors';
import Loader from 'components/loader';
import { isFetchingItems } from 'pages/Logistic/selectors';

const classModal = classNames('Modal', 'Modal__new-shipment', { 'Modal--cactus': isCactus });

function NewProductModal({ isLoading, products }) {
  const orderNo = useSelector(getClaimsFormValue('uzNo'));
  const isFetching = useSelector(isFetchingItems);

  const [step, setStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    isFetching && setStep(1);
  }, [isFetching]);

  const openModal = e => {
    e.preventDefault();
    setIsOpen(true);
  };

  const onModalBack = useCallback(() => {
    setStep(0);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setStep(0);
  }, []);

  const steps = [<Catalog />, <SelectProduct onRequestClose={closeModal} isLoading={isFetching} />];

  return (
    <div>
      {(isLoading && <Loader>Загружаются товары заказа {orderNo}</Loader>) ||
        (!!products.length && (
          <a
            onClick={openModal}
            className={classNames('section-nav', 'section-nav__no-underline', 'section-nav--upper')}
          >
            <i className="icon icon-create-icon" />
            Добавить товар
          </a>
        ))}
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className={classModal}
        overlayClassName="Overlay Overlay__columns"
        ariaHideApp={false}
      >
        <div className="claims-new-product__modal">
          <section className="claims-new-product__header">
            <div className="claims-new-product__title">
              <i
                onClick={onModalBack}
                className={'icon' + ((step === 1 && ' icon-back-icon') || ' icon-back-grey-icon')}
              />
              Добавление товара
            </div>
            <SearchProductInput closeModal={closeModal} />
          </section>
          {steps[step]}
        </div>
      </ReactModal>
    </div>
  );
}

export default NewProductModal;
