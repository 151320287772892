/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef} from 'react'
import classNames from 'classnames';
import { useStore, useSelector, useDispatch, batch } from 'react-redux';
import { clearFields, reset } from 'redux-form';
import { ORDER_LIST_FILTER_FORM } from './constants';
import { compose } from 'redux';
import cleanFilterGroups from './helpers/cleanFilterGroups';
import getProductFilters from './helpers/clientData/getProductFilters';
import { getTableListData } from 'common/selectors';
import { useFilterFormChange } from 'pages/Orders/components/common/order-list-filter/hooks/useFilterFormChange';
import FilterForm from './components/filter-form';
import useDeepEffect from "hooks/use-deep-effect"
import useUpdateEffect from "hooks/use-update-effect"
import useListData from "components/table/hooks/useListData"

const OrderListFilter = ({ onFilterHeadClick, isSearch }) => {
  const dispatch = useDispatch();

  const {
    select: { order, filters },
  } = useStore();

  const { initiator, docType, orderNo } = useSelector(order.getHeader);
  const serverFilterGroups = useSelector(filters.getServerFilters);
  const clientFilterGroups = useSelector(filters.getCurrentGroups);
  const items = useListData();

  const prevLength = useRef(items.length)

  const onChange = useFilterFormChange();

  useDeepEffect(() => {
    batch(() => {
      dispatch(reset(ORDER_LIST_FILTER_FORM));
      dispatch.filters.setGroups([]);
      dispatch.filters.flush()
    });
  }, [serverFilterGroups]);

  useUpdateEffect(() => {
    const isItemsLengthEqual = prevLength.current === items.length;

    if (orderNo && !isItemsLengthEqual) {
      let names = new Set();

      const groups = compose(
        cleanFilterGroups,
        getProductFilters(items, names),
      )(clientFilterGroups);

      batch(() => {
        dispatch.filters.setGroups(groups)
        names.size && dispatch(clearFields(ORDER_LIST_FILTER_FORM, false, false, ...names));
        names.size && dispatch.filters.setTags({ name: [...names] })
        items.length < 2 && dispatch.filters.flush()
      });

      names = null;
      prevLength.current = items.length
    }
  }, [items.length, orderNo, clientFilterGroups]);

  return (
    <div
      className={classNames('order-filter', {
        'with-order': initiator && docType !== 4,
      })}
      id="order-filter"
    >
      <a className="order-filter__head" onClick={onFilterHeadClick}>
        <i
          className={classNames(
            'order-filter__head__icon',
            'icon',
            'icon-order-filter_head_left-arrow',
            'enabled',
          )}
        />
        <p className="order-filter__head__text">ФИЛЬТРЫ</p>
      </a>
      {(items.length < 2 && !isSearch && (
        <div className="order-filter__notice">
          <p className="order-filter__notice__text">Нет данных</p>
        </div>
      )) || (
        <FilterForm
          onChange={onChange}
          serverFilterGroups={serverFilterGroups}
          clientFilterGroups={clientFilterGroups}
        />
      )}
    </div>
  );
};

OrderListFilter.displayName = 'OrderListFilter';

export default OrderListFilter;
