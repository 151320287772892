import React, { memo } from 'react';
import Comment from 'pages/VAD/components/common/components/comment';

const CommentsList = ({ comments = [], children }) => (
  <div className="comments-container">
    <h4>Комментарии ({comments.length})</h4>
    <section>
      {comments.map((comment, index) => (
        <Comment key={index} comment={comment} />
      ))}
      {children}
    </section>
  </div>
);

export default memo(CommentsList);
