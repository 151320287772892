import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Audio = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M10 1.47241V16.5276C10 16.9591 9.52632 17.1509 9.19474 16.8632L4.6 12.596C4.41053 12.4521 4.17368 12.3562 3.93684 12.3562H2.42105C1.61579 12.3562 1 11.7329 1 10.9179V7.08214C1 6.26706 1.61579 5.64375 2.42105 5.64375H3.93684C4.17368 5.64375 4.41053 5.54786 4.6 5.40402L9.19474 1.13679C9.47895 0.849111 10 1.0409 10 1.47241Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M13 5C14.3333 7.3 14.3333 10.7 13 13"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15 2C17.6667 5.94595 17.6667 12.0541 15 16"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

Audio.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Audio.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
