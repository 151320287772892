import React, { useLayoutEffect } from 'react';
import { BaseLayout } from 'common/layout/base-layout';
import GuestLayout from 'components/layout/guest-layout';
import { GuestLayoutV2 } from 'components/layout/guest-layout-v2';
import DefaultLayout from 'components/layout/layout';
import useMatchedRoute from 'hooks/use-matched-route';
import { isCactus } from 'common/config';
import { useTheme } from 'hooks/use-theme';
import { CACTUS_THEME, UNLOCKING_THEME } from 'common/themes';

const Layout = ({ children }) => {
  const { route = {} } = useMatchedRoute();
  const { props = {} } = route;

  useTheme(isCactus ? CACTUS_THEME : UNLOCKING_THEME);

  const isNewLayout = Boolean(
    props.isNewLayout && (props.auth || __webpack_chunkname__?.includes('new')),
  );

  useLayoutEffect(() => {
    document.body.classList.toggle('cactus', isCactus);
    document.body.classList.toggle('v1', !isNewLayout && !isCactus);
    document.body.classList.toggle('v2', isNewLayout && !isCactus);
  }, [isNewLayout, isCactus]);

  const Component = isNewLayout
    ? props.auth
      ? GuestLayoutV2
      : BaseLayout
    : props.auth
    ? GuestLayout
    : DefaultLayout;

  return <Component>{children}</Component>;
};

export default Layout;
