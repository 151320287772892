import React from 'react';
import { getFormattedPrice } from 'utils/new/numbers/format-number';

const BalanceInfo = ({ balance, label }) => {

  return (
    <div className="balance-container">
      <h2 className="balance-container__header">
        {label} {balance.code && ` (${balance.code})`}
      </h2>
      <div className="balance-cell">
        <h5 className="balance-cell__title">Баланс:</h5>
        <span className="balance-cell__value">{getFormattedPrice(balance.balance)} {balance.currency}</span>
      </div>
      <div className="balance-cell">
        <h5 className="balance-cell__title">Баланс БУ:</h5>
        <span className="balance-cell__value">{getFormattedPrice(balance.balanceBu)} {balance.currency}</span>
      </div>
      <div className="balance-cell">
        <h5 className="balance-cell__title">Баланс ПУ:</h5>
        <span className="balance-cell__value">{getFormattedPrice(balance.balancePu)} {balance.currency}</span>
      </div>
    </div>
  );
};

export default BalanceInfo;
