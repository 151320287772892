import { getFavoriteCategories } from 'pages/Orders/selectors';
import saveSettings from 'models/effects/saveSettings';

const favoriteCategories = {
  name: 'favoriteCategories',
  state: {
    cats: {}
  },
  reducers: {
    change: (state, payload) => ({ ...state, cats: payload }),
    'user/loadSettings': (state, { favoriteCategories: cats = {}}) => ({ ...state, cats })
  },
  effects: dispatch => ({
    async save({ catId, parentId }, state) {
      const favCats = getFavoriteCategories(state);
      let newFavCats = { ...favCats };

      if (parentId) {
        let parentCategory = [...(favCats[parentId] || [])];

        if (parentCategory.length) {
          if (parentCategory.includes(catId)) {
            parentCategory.splice(parentCategory.indexOf(catId), 1);
          } else {
            parentCategory.push(catId);
          }
        } else {
          parentCategory = [catId];
        }

        newFavCats[parentId] = parentCategory;
      } else {
        const { [catId]: favCat, ...categories } = favCats;

        if (favCat) {
          newFavCats = categories;
        } else {
          newFavCats[catId] = [];
        }
      }

      this.change(newFavCats);
      await saveSettings({
        favoriteCategory: newFavCats,
      })(dispatch);
    },
  }),
};

export default favoriteCategories;
