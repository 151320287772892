export default {
  onModel({ name }, { select, dispatch }) {
    const effects = dispatch[name];

    if (!effects) {
      return;
    }

    for (const effectName of Object.keys(effects)) {
      const func = effects[effectName];

      effects[effectName] = (payload, rootState, meta) => func(payload, rootState, meta, select);
    }
  },
};
