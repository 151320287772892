import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Content from 'components/layout/content';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import { LoadingSubmitButton } from 'components/loading-sumbit-button';
import withApi from 'hoc/withApi';

const Agreement = () => {
  const agreementText = useSelector(state => state.finances.agreement);
  const loading = useSelector(getEffectLoading('finances', 'acceptAgreement'));

  const {
    finances: { acceptAgreement },
  } = useDispatch();

  return (
    <Content title="Правила деловой этики">
      <div className="finances-content agreement-content">
        {ReactHtmlParser(agreementText)}
        <LoadingSubmitButton
          label="Принять"
          fetching={loading}
          onClick={async () => await acceptAgreement()}
          btnClass="btn btn-danger btn--rect"
        />
      </div>
    </Content>
  );
};

export default withApi(Agreement);
