import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import { def } from 'common/util';
import { columns } from '../constants/columns';
import CustomSubComponent from './custom-sub-component';
import FilterList from 'containers/filter-list';
import {useSelector, useStore} from 'react-redux'
import withApi from 'hoc/withApi';
import useListData from "components/table/hooks/useListData"

const TABLE_NAME = 'SpecialPrices';
const defaultSorted = [
  {
    id: 'createDate',
    desc: true,
  },
  {
    id: 'no',
    desc: true,
  },
];

const SpecialPricesList = () => {
  const [sortedForSubComponent, setSortedForSubComponent] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const {select: { specialPrice }} = useStore()

  const expanded = useSelector(specialPrice.getExpanded);
  const list = useListData();

  const handleUpdateFiltered = filtered => setFiltered(filtered);

  return (
    <Content title="Запросы спец цен">
      <div
        className={
          'actions-container actions-container--order align-items-center special-prices__list-header'
        }
      >
        <div>
          <Link
            to="/vad/special-prices/form"
            className={['section-nav', 'section-nav__no-underline', 'section-nav--upper'].join(' ')}
          >
            <i className="icon-create">+</i> Создать запрос
          </Link>
          <FilterList
            label="Конечный заказчик"
            filterBy="customerName"
            filtered={filtered}
            onUpdateFiltered={handleUpdateFiltered}
          />
        </div>
        <Link to="/category/993" className="add-vendor-data">
          <i className={'icon icon-help'} />
          Помощь
        </Link>
      </div>
      <div className="special-prices__list">
        <TableList
          name={TABLE_NAME}
          columns={columns}
          expanded={expanded}
          onSortedChange={setSortedForSubComponent}
          SubComponent={row =>
            def(row) && (
              <CustomSubComponent
                data={row.original.children || []}
                columns={columns}
                sorted={sortedForSubComponent}
              />
            )
          }
          defaultSorted={defaultSorted}
          filtered={filtered}
          showPagination={list.length > 5}
        />
      </div>
    </Content>
  );
};

export default withApi(SpecialPricesList);
