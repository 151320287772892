import { API_URL } from 'common/config/app';

export const catalogApi = {
  'new-orders/catalog/first-level': {
    endpoint: `${API_URL}/v1/orders/category/first-level`,
  },
  'new-orders/catalog/second-level/([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/orders/category/two-levels`,
  },
  'new-orders/catalog/favorite/second-level/([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/orders/category/two-levels`,
  },
  'new-orders/catalog/save': {
    endpoint: `${API_URL}/settings/save`,
  },
};
