// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { DownloadList } from 'features/download-list/download-list';
import { Navigation as NavigationLinks } from 'components/new/navigation';
import { Container } from 'common/layout/container';
import { Logo } from 'common/ui/logo';
import Mixpanel from 'services/mixpanel';

import styles from './navigation.module.scss';

const handleClick = () => {
  Mixpanel?.track('Логотип');
};

export const Navigation = ({ className }) => (
  <nav className={cn(styles['navigation'], className)} data-testid="navigation" role="navigation">
    <Container className={styles['navigation__container']}>
      <Link to="/" onClick={handleClick}>
        <Logo position="top" />
      </Link>
      <NavigationLinks isHeader />
      <DownloadList />
    </Container>
  </nav>
);

Navigation.defaultProps = {
  className: '',
};

Navigation.propTypes = {
  className: PropTypes.string,
};
