import React, { useCallback, useEffect, useState } from 'react';
import HeaderInfo from 'pages/Orders/components/common/header-info';
import ReactTable from 'react-table-v6';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getEffectLoading } from 'common/selectors';
import Loader from 'components/loader';
import isEmpty from 'lodash/isEmpty';
import confirm from 'components/native/confirm/function';

const OrderToMove = ({ user, orderSettings, headerKeys, items, columns, toOrder }) => {
  const {
    order: { handleMove, getOrderToMove },
  } = useDispatch();
  const { id } = useParams();

  const [orders, setOrders] = useState({});
  const loading = useSelector(getEffectLoading('order', 'handleMove'));
  const loadingOrder = useSelector(getEffectLoading('order', 'getOrderToMove'));

  const setCurrentOrders = useCallback(
    order => {
      setOrders({ ...orders, [toOrder]: order });
    },
    [orders, toOrder],
  );

  useEffect(() => {
    async function getOrder(id) {
      return await getOrderToMove(id);
    }

    if (toOrder && !orders[toOrder]) {
      getOrder(toOrder).then(setCurrentOrders);
    }
  }, [orders[toOrder], toOrder]);

  if (loadingOrder) {
    return <Loader>Заказ загружается</Loader>;
  }

  if (isEmpty(orders[toOrder])) {
    return null;
  }

  const data = [...orders[toOrder].goods, ...Object.values(items)];

  return (
    <React.Fragment>
      {orders[toOrder].orderNo !== 'NEW' && (
        <HeaderInfo
          order={orders[toOrder]}
          user={user}
          orderSettings={orderSettings}
          visible={headerKeys}
        />
      )}
      <div className="order-list">
        <ReactTable
          data={data}
          columns={columns()}
          noDataText="Данных нет"
          showPagination={false}
          minRows={1}
          defaultPageSize={5000}
          pageSize={5000}
          loading={loading}
        />
      </div>
      <div className="save-moved">
        <button
          className="btn btn-danger btn-sm"
          onClick={async () => {
            try {
              await confirm('Проверьте правильность внесённых изменений и нажмите ОК');

              return await handleMove({ from: id, to: toOrder, items });
            } catch (e) {
              console.error(e);
            }
          }}
          disabled={loading}
        >
          Сохранить
        </button>
      </div>
    </React.Fragment>
  );
};

export default OrderToMove;
