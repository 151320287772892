import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const User = ({
  className,
  color = '#236192',
  name,
  width = '16',
  height = '16',
  viewBox = '0 0 16 16',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M3.2002 13.8222C3.46686 12.2222 4.53353 10.8889 5.95575 10.2222"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M10.0444 10.2222C10.5778 10.4444 11.0667 10.8 11.4667 11.2C12.1778 11.9111 12.6667 12.8 12.8 13.8222"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M8.00027 11.1111C9.71849 11.1111 11.1114 9.51925 11.1114 7.55557C11.1114 5.59189 9.71849 4.00002 8.00027 4.00002C6.28205 4.00002 4.88916 5.59189 4.88916 7.55557C4.88916 9.51925 6.28205 11.1111 8.00027 11.1111Z"
        stroke={color}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

User.defaultProps = {
  className: '',
  color: '#236192',
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
};

User.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
