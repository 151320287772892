import ReactHtmlParser from 'react-html-parser';
import { FileCell } from 'pages/VAD/helper/util';

export default [
  {
    Header: 'Дата',
    accessor: 'created',
    width: 90,
    sortable: false,
  },
  {
    Header: 'Название',
    accessor: 'name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    sortable: false,
  },
  {
    Header: 'Направление',
    accessor: 'group',
    sortable: false,
    width: 200,
  },
  {
    Header: 'Вендор',
    accessor: 'vendor',
    sortable: false,
    Cell: row => (row && ReactHtmlParser(row.value)) || null,
    width: 150,
  },
  {
    Header: 'Тип',
    accessor: 'type',
    sortable: false,
    width: 150,
  },
  {
    Header: 'Файл',
    accessor: 'fileName',
    sortable: false,
    Cell: FileCell,
    width: 150,
  },
];
