import React from 'react';

const nameColumn = row => {
  if (!row) return null;

  const column = [];
  column.push(row.value);
  if (row.original.EOL > 0) {
    column.push(<span className="EOL">&nbsp(снят с производства)</span>);
  }
  if (row.original.isNew > 0) {
    column.push(<span className="EOL">&nbsp(новый товар)</span>);
  }

  return column;
};

export default nameColumn;
