import alert from 'components/native/alert/function';
import { preparedData } from 'pages/Orders/components/order-edit/helpers/prepareData';

export default dispatch =>
  async ({ value, id, isPilot }) => {
    if (!value) {
      return;
    }

    const text = value
      .trim()
      .split('\n')
      .reduce((text, row) => [...text, row.trim().split(/[\s\t]+/)], []);

    const { success, message, data } = await dispatch.api.post({
      url: `/orders/edit/import${id ? `/${id}` : ''}`,
      query: '?key=13',
      data: text,
      successPayload: {
        dataKey: isPilot && 'productFromExcel',
      },
    });

    if (message) {
      alert(message);
    }

    const importItems = [];
    const items = data?.data || [];

    items.forEach((item, index) => {
      if (Number(item.desiredQty)) {
        preparedData.set('qtys', item.id, item.desiredQty);
        preparedData.set('prices', item.id, item.desiredPriceRUB);
        preparedData.set('priceClients', item.id, item.desiredPriceClient);
        preparedData.set('line', item.id, index);
        importItems.push(item.id);
      }
    });

    preparedData.set('importItems', importItems);

    return { success, message, data };
  };

