import React from 'react';
import FormContainer from './FormContainer';

const FieldText = props => {
  const { input } = props;

  return (
    <FormContainer label="Тема обращения" error={props.meta.error} touched={props.meta.touched}>
      <input className={'form-control'} type={'text'} {...input} />
    </FormContainer>
  );
};
export default FieldText;
