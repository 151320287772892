import React, {useEffect} from 'react';
import { Field } from 'redux-form';
import { renderPassword } from '../../Settings/components/fields';
import { USER_ERROR_TYPE } from '../constants/error-type';
import HelpInRegistration from './HelpInRegistration';
import { isCactus } from 'common/config';
import GuestLayout from 'components/layout/guest-layout';
import { Unlocking } from './unlocking';
import {purgeStoredState} from "redux-persist"
import {clearPersistedState, persistConfig} from "client/store/configs/persist"

const mailSupport = isCactus
  ? 'mailto:trade_support@officeassistant.ru '
  : 'mailto:b2b_support@merlion.ru';

const renderField = ({ input, label, type, className, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div>
      <input {...input} type={type} className={className} />
      {touched && error && <span className="text-danger">{error}</span>}
    </div>
  </div>
);

export const showErrorMessage = error => {
  if (error) {
    if (USER_ERROR_TYPE[error.code]) {
      return (
        <div className="error__messages">
          <div className="error__header">Ваш логин - заблокирован.</div>
          <div>Вы можете обратиться:</div>
          <ul className="error__list">
            <li>
              к сотруднику из вашей фирмы, обладающему правами администратора в интернет-модуле,
            </li>
            <li>к вашему менеджеру в Merlion,</li>
            <li>
              <Unlocking />
            </li>
          </ul>
        </div>
      );
    }

    return (
      <div className="text-center error__message">
        <strong className="text-danger">{error.message}</strong>
      </div>
    );
  }

  return null;
};

const LoginForm = props => {
  const { error, handleSubmit, isFetching, passFetching, login, recover } = props;

  useEffect( () => {
    clearPersistedState()
  }, [])

  return (
    <GuestLayout>
      <h1 className="title">Вход в систему</h1>
      <div className="loginContainer">
        <form onSubmit={handleSubmit(login)}>
          <Field
            type="text"
            className="form-control"
            name="clientNo"
            label="Код клиента"
            component={renderField}
          />
          <Field
            type="text"
            className="form-control"
            name="clientLogin"
            label="Ваш логин"
            component={renderField}
          />
          <Field
            type="password"
            className="pass-control"
            name="password"
            label="Пароль"
            component={renderPassword}
          />
          <div className="login-submit">
            <input type="submit" disabled={isFetching} value="Войти" className="btn btn-danger" />
          </div>
        </form>
        {showErrorMessage(error)}
        {(!error || error.code !== USER_ERROR_TYPE[error.code]) && (
          <div className="pass-recovery row justify-content-center">
            <a href="#" onClick={recover}>
              Восстановить пароль
            </a>
            {passFetching && <i className="loader loader--small" />}
          </div>
        )}
      </div>
      <div className="partner-caption">
        <p>
          Если у Вас есть вопросы, обращайтесь в <a href={mailSupport}>службу поддержки</a>
        </p>
        <HelpInRegistration />
      </div>
    </GuestLayout>
  );
};

export default LoginForm;

