import { proxyArgsToMethods } from 'common/util';
import { changeOrder } from 'pages/new/orders/pages/order/utils';
import { changeRows } from 'pages/new/orders/utils/change-rows';
import { composeGoodIcons } from 'pages/new/orders/pages/order/utils';
import * as effects from './effects';
import * as selectors from './selectors';
import * as formSelectors from './selectors/order-form';

export default {
  name: 'order',
  state: {
    header: {},
    rows: [],
    statuses: [],
    splitRows: [],
    headerQueue: {},
    rowsQueue: {},
    formOpened: false,
  },
  reducers: {
    load: (state, { data }) => {
      if (!data) {
        return state;
      }

      const { data: rows, transit_statuses, order, splitItems } = data;

      return {
        ...state,
        rows: rows?.map(good => ({
          ...good,
          image: good.imageUrl,
          statuses: composeGoodIcons(good),
        })),
        statuses: transit_statuses || [],
        header: order || state.header,
        splitRows: splitItems || [],
      };
    },
    setHeader: (state, header) => {
      state.header = { ...state.header, ...header };
      return state;
    },
    toggleCreateForm: state => ({ ...state, formOpened: !state.formOpened }),
    'product/load': (state, { order }) => {
      state.header = { ...state.header, ...order };

      return state;
    },
    sign: (state, { command, status }) => {
      const withSign = status !== 'Error' && ['Sign', 'Unsign'].includes(command);

      if (!withSign) {
        return state;
      }

      state.header.status =
        command === 'Sign'
          ? 'подписан клиентом'
          : (status !== 'подписан клиентом' && status) || '-';
      state.header.sign = command === 'Sign';

      return state;
    },
    clearHeader: state => {
      state.header = {};
      return state;
    },
    'newOrdersNew/deleteOrder': state => {
      state.header = {};
      return state;
    },
    updateOrder: (state, payload) => {
      state.header = state.header ? changeOrder(state.header, payload) : state.header;
      return state;
    },
    updateRows: (state, payload) => {
      state.rows = changeRows(state.rows, payload);
      return state;
    },
  },
  effects(dispatch) {
    return {
      ...proxyArgsToMethods(effects, dispatch),
      async fetchOrder(orderNo = '') {
        try {
          const result = dispatch.api.get({
            url: `/orders/view/${orderNo}`,
          });

          this.load(result);

          return result;
        } catch (err) {
          return null;
        }
      },
    };
  },
  selectors(...args) {
    return proxyArgsToMethods(
      {
        ...selectors,
        ...formSelectors,
      },
      args,
    );
  },
};
