import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { registerField, unregisterField } from 'redux-form';
import styles from "pages/new/orders/pages/orders-new/components/order-create-form/order-create-form.module.scss"

const DefaultField = ({ title, placeholder, formName = 'ORDER_EDIT_FORM', fieldName }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(unregisterField(formName, fieldName));

    return () => dispatch(registerField(formName, fieldName));
  }, [formName, fieldName]);

  return (
    <div className={styles['order-create-form__default-field']}>
      <label>{title}</label>
      <span className="placeholder ">{placeholder}</span>
    </div>
  );
};

DefaultField.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
};

DefaultField.defaultProps = {
  title: '',
  placeholder: '',
  fieldName: '',
};

export default DefaultField;
