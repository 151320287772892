// @ts-nocheck
const sortString = (a, b) => {
  if (a.caption > b.caption) {
    return 1;
  }

  if (a.caption < b.caption) {
    return -1;
  }

  return 0;
};

export const sortFilters = filters => {
  if (filters.length === 1) {
    return filters;
  }

  const availFilterIndex = filters.findIndex(filter => filter.id === 'avail');
  const brandFilterIndex = filters.findIndex(filter => filter.id === 'brand');

  const [availFilter] = filters.splice(availFilterIndex, 1);
  const [brandFilter] = filters.splice(brandFilterIndex, 1);
  const sortedFilters = filters.sort(sortString);

  if (brandFilter) {
    return [availFilter, brandFilter, ...sortedFilters];
  }

  return [availFilter, ...sortedFilters];
};
