/* eslint-disable react/no-string-refs */

import React, {
  forwardRef,
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  memo,
  useRef,
} from 'react';
import { compose } from 'redux';
import ReactDOM from 'react-dom';
import ReactTable from 'react-table-v6';
import PropTypes from 'prop-types';
import cache from 'services/cache';
import { CACHE_NAME } from 'common/config';
import TablePagination from 'components/table/pagination';
import { useDispatch, useSelector, useStore } from 'react-redux';
import useDeepEffect from 'hooks/use-deep-effect';
import { usePrev } from 'hooks';
import { getEffectLoading } from 'common/selectors';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import useListData from 'components/table/hooks/useListData';
import { useLocation } from 'react-router-dom';

ReactTable.propTypes.PaginationComponent = PropTypes.any;
const loadingText = <i className="loader loader--ripple" />;
const watchedProps = ['page', 'pageSize', 'sorted', 'filtered'];

function TableList(props, ref) {
  const { name = 'default', filtered = [], manual, pages, pageSize: propSize, ...others } = props;
  const prevProps = usePrev(props);

  const innerRef = useRef();
  const tableRef = ref || innerRef;

  const [page, setPage] = useState(0);

  const {
    select: { listSettings, list },
  } = useStore();

  const data = useListData();
  const fetching = useSelector(getEffectLoading('list', 'fetchData'));
  const size = useSelector(listSettings.getPageSize(name));
  const total = useSelector(list.getTotal);

  const { pathname } = useLocation();

  const pageSize = propSize || size;

  const {
    list: { fetchData, unblock, clear },
    listSettings: { pageSizeChange },
  } = useDispatch();

  useLayoutEffect(() => {
    const tableDOM = ReactDOM.findDOMNode(tableRef.current);
    const tableHeader = tableDOM?.querySelector('.rt-thead.-header');
    const tableBody = tableDOM?.querySelector('.rt-tbody');

    if (tableHeader) {
      tableHeader.style.paddingRight = '0';

      if (tableBody && tableBody.scrollHeight > tableBody.clientHeight) {
        tableHeader.style.paddingRight = '17px';
      }
    }
  }, []);

  useDeepEffect(
    () => {
      setPage(0);

      if (manual) {
        unblock();

        fetchData({
          state: tableRef.current.getResolvedState(),
          instance: { ...tableRef.current, props: prevProps },
        });
      }
    },
    [filtered, manual],
    false,
  );

  useEffect(
    () => () => {
      name && cache.clear(`${CACHE_NAME}-${name}`);
    },
    [name],
  );

  useEffect(
    () => () => {
      clear(pathname.substring(1));
    },
    [pathname],
  );

  const handleFetchData = useCallback(
    (state, instance) => {
      if (!manual) {
        return;
      }

      const currentState = pick(state, watchedProps);
      const prevState = pick(instance.props, watchedProps);

      if (isEqual(currentState, prevState)) {
        return;
      }

      fetchData({ state, instance });
    },
    [manual],
  );

  const handlePageSizeChange = useCallback(
    size => {
      setPage(0);
      pageSizeChange({ size, name });
    },
    [name],
  );

  return (
    <ReactTable
      name={name}
      ref={tableRef}
      noDataText="Данных нет"
      data={data}
      onFetchData={handleFetchData}
      loading={fetching}
      loadingText={loadingText}
      page={page}
      manual={manual}
      pages={pages}
      total={total}
      pageSize={pageSize}
      showPagination={pages > 1 || data.length > pageSize}
      minRows={1}
      PaginationComponent={TablePagination}
      nextText=">"
      previousText="<"
      rowsText="строк"
      onPageSizeChange={handlePageSizeChange}
      onPageChange={setPage}
      filtered={filtered}
      {...others}
    />
  );
}

export default compose(memo, forwardRef)(TableList);

TableList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  fetchLink: PropTypes.string,
  name: PropTypes.string,
  onFetchData: PropTypes.func,
};
