const MASK = '##:##';

const formattingMask = value => {
  const onlyNumbers = value.replace(':', '');
  let i = 0;
  let lastReplacedIndex = -1;
  const fieldMask = MASK.replace(/#/g, (_, j) => {
    if (i >= onlyNumbers.length) {
      return '#';
    }
    lastReplacedIndex = j;
    return onlyNumbers[i++];
  });
  return fieldMask.substring(0, lastReplacedIndex + 1);
};

export default formattingMask;
