import React from 'react';
import { formattingPhone } from '../../../helpers';
import { def } from 'common/util';
import sortDates from 'helpers/sortDates';
import colorsStatusShipingAgent from '../../../constants/color-status-shiping-agent';

const agentsColumns = [
  {
    Header: 'Филиал компании',
    accessor: 'branchCity',
  },
  {
    Header: 'Тип (клиент/ТК)',
    accessor: 'type',
  },
  {
    Header: 'Дата создания',
    accessor: 'date',
    sortMethod: sortDates,
  },
  {
    Header: 'Адрес',
    accessor: 'address',
    width: 400,
  },
  {
    Header: 'Телефон',
    accessor: 'phone',
    Cell: row => (def(row) && formattingPhone(row.value)) || null,
  },
  {
    Header: 'Название',
    accessor: 'name',
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: row =>
      def(row) && <span className={colorsStatusShipingAgent[row.value]}>{row.value}</span>,
  },
];

export default agentsColumns;
