import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTableListData } from 'common/selectors';
import useRouteConfig from 'hooks/use-route-config';
import useMatchedRoute from 'hooks/use-matched-route';
import useListData from "components/table/hooks/useListData"

const ServiceExportButton = ({ type, docType }) => {
  const {
    warranty: { exportService },
  } = useDispatch();
  const data = useListData();

  const { routes } = useRouteConfig();
  const { match } = useMatchedRoute();

  if (!data.length || routes?.length) {
    return null;
  }

  const query = match?.params?.id ? { type, docType, id: match?.params?.id } : { type, docType };

  return (
    <div className="icon-container__border-left">
      <i
        className="icon icon-expxls icon__border-left enabled"
        title="Выгрузить в Excel"
        onClick={async () => await exportService(query)}
      />
    </div>
  );
};

export default ServiceExportButton;
