// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { AccessMenu } from 'services/new/access-menu';
import { getMenu, getPermissions } from 'features/user';

import styles from './navigation.module.scss';

export const Navigation = ({ isHeader }) => {
  const menu = useSelector(getMenu);
  const permissions = useSelector(getPermissions);

  const classes = cn({
    [styles['navigation__header']]: isHeader,
    [styles['navigation__footer']]: !isHeader,
  });

  return (
    <div className={classes}>
      <AccessMenu menu={menu} permissions={permissions} />
    </div>
  );
};

Navigation.defaultProps = {
  isHeader: false,
};

Navigation.propTypes = {
  isHeader: PropTypes.bool,
};
