import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { EditableSelect } from 'components/fields';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder } from 'pages/Orders/selectors';
import { CARD_PAGE_PATH } from 'common/routes/constants';
import { preorderTypes, ROUTE_PREORDERS_EDIT } from '../../constances/preorder';

const topResult = {
  id: 0,
  text: 'Показаны первые 10 результатов поиска',
  disabled: true,
};

const OrderSwitcher = props => {
  const { name = 'order-switcher', style = {}, placeholder = 'Начните ввод', className } = props;

  const { orderNo = 'NEW' } = useSelector(getOrder);
  const {
    order: { search, clearHeader, getOrder: fetchGetOrder },
  } = useDispatch();

  const history = useHistory();
  const { pathname } = useLocation();

  const [selected, setSelected] = useState({
    id: orderNo,
    text: orderNo,
  });

  const isCard = pathname.includes(CARD_PAGE_PATH);

  useEffect(() => {
    setSelected({
      id: orderNo === 'NEW' ? 'новый' : orderNo,
      text: orderNo === 'NEW' ? 'новый' : orderNo,
    });
  }, [orderNo]);

  useEffect(() => {
    if (pathname.includes('new')) {
      clearHeader();
    }
  }, [pathname]);

  const handleSearch = useCallback(
    debounce((input, callback) => {
      search(input)
        .then(orders => {
          const options = orders.length >= 10 ? [topResult, ...orders.slice(0, 10)] : orders;

          callback(null, { options });
        })
        .catch(() => false);
    }, 700),
  );

  const getItems = (input, callback) => {
    if (!input) {
      return Promise.resolve({
        options: [],
      });
    }

    if (input.trim() && !/A-Za-z0-9/.test(input)) {
      handleSearch(input, callback);
    }
  };

  const onChange = value => {
    if (value && value.id !== selected.id) {
      setSelected({
        id: value.id,
        text: value.id,
      });

      if (isCard && value?.id) {
        fetchGetOrder(value.id);
      } else {
        const url = value.tender === preorderTypes.tender ? ROUTE_PREORDERS_EDIT : '/orders/edit/';

        history.push({
          pathname: `${url}${value.id}`,
          state: {
            orderNo: value.id,
          }
        });
      }
    }
  };

  return (
    <div
      className={className || 'col-sm'}
      title="Начните набор для выбора из списка существующих заказов"
    >
      <EditableSelect
        name={name}
        async
        menuContainerStyle={{
          display: 'inline-block',
        }}
        menuStyle={{
          whiteSpace: 'nowrap',
          display: 'inline-block',
        }}
        onChange={onChange}
        style={style}
        loadOptions={getItems}
        placeholder={placeholder}
        searchPromptText="Начните ввод"
        value={selected}
        inputValue={selected.id}
        filterOption={() => true}
        valueKey="id"
        labelKey="text"
        inputKey="id"
        clearable={false}
        noResultsText="Начните ввод"
        loadingPlaceholder="Загрузка заказов"
        onSelectResetsInput={false}
        onBlurResetsInput={false}
        onCloseResetsInput={false}
      />
    </div>
  );
};

OrderSwitcher.propTypes = {
  name: PropTypes.string,
  orderNo: PropTypes.string.isRequired,
  style: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default OrderSwitcher;
