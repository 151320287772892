import React, { memo, Fragment } from 'react';
import { getHostName } from 'common/util';
import { Link } from 'react-router-dom';

const BannerItem = props => {
  const { href, src, track } = props;

  const hostname = getHostName(window.location.href);
  const itemHostName = getHostName(href);

  return (
    <>
      {hostname === itemHostName ? (
        <Link to={href} onClick={track}>
          {' '}
        </Link>
      ) : (
        <a href={href} onClick={track} target="_blank">
          {' '}
        </a>
      )}
      <img src={src} alt="Баннер" className="banner__img" />
    </>
  );
};

export default memo(BannerItem);
