// @ts-nocheck
export const formattingFilters = ({ characteristics, brands }) => {
  const filters = characteristics.map(filter => ({
    id: filter.id,
    caption: filter.caption,
    inputType: filter.inputType,
    parameters: filter.values || filter.values,
  }));

  if (brands.length) {
    filters.push({
      id: 'brand',
      caption: 'Бренд',
      inputType: 0,
      parameters: [...new Set(brands)].map(brand => ({
        id: brand,
        value: brand,
        propertyId: 'brand',
      })),
    });
  }

  return filters;
};
