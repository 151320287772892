import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const SortDown = ({
  className,
  color = '#d4d4d4',
  name,
  width = '12',
  height = '6',
  viewBox = '0 0 12 6',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M6.65079 5.44218C6.2763 5.76317 5.7237 5.76317 5.34921 5.44218L1.05246 1.75925C0.347567 1.15505 0.77485 -4.88345e-06 1.70326 -4.80229e-06L10.2967 -5.0047e-06C11.2252 -4.92353e-06 11.6524 1.15505 10.9475 1.75925L6.65079 5.44218Z"
        fill={color}
      />
    </svg>
  );
};

SortDown.defaultProps = {
  className: '',
  color: '#d4d4d4',
  width: '12',
  height: '6',
  viewBox: '0 0 12 6',
};

SortDown.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
