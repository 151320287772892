import { highlightText } from 'utils/new/strings';

const formatGoodAttribute = (attribute, text) => highlightText(attribute, text);

export function formatGoodsBySearch(goods, searchValue) {
  return goods.map(good => ({
    ...good,
    name: searchValue ? formatGoodAttribute(good.name, searchValue) : good.name,
  }));
}

export function formatOrderBySearch(order, text = '') {
  return {
    ...order,
    blankedOrderNo:
      order.blankedOrderNo && text.length >= 3
        ? highlightText(String(order.blankedOrderNo).trim(), text)
        : String(order.blankedOrderNo).trim(),
    orderNoLink: order.orderNo,
    initialOrderNo: order.orderNo,
    orderNo: text.length >= 3 ? highlightText(order.orderNo, text) : order.orderNo,
    items: order.items ? formatGoodsBySearch(order.items, text) : order.items,
  };
}
