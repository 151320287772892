import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {
  getUser,
  isItemsVisibilitySaved,
  isOrdersVisibilitySaved,
  hasFavoriteCategories,
} from 'pages/Orders/selectors';
import alert from 'components/native/alert/function';
import createContactsSections from 'pages/Help/helpers/createContactsSections';
import { getRoutesByPilot } from 'utils/new/get-routes-by-pilot';
import {getEffectLoading} from "common/selectors"

const loadState = (state, { user, balance, confirmed, orderSettings }) => ({
  ...state,
  info: user,
  balance,
  confirmed,
  orderSettings,
});

const user = {
  name: 'user',
  state: {
    info: {},
    balance: {},
    currency: {},
    contacts: {},
    orderSettings: {},
    confirmed: null,
  },
  reducers: {
    login: loadState,
    refresh: loadState,
    setOrderSettings: (state, payload) => ({ ...state, orderSettings: payload.orderSettings }),
    setCurrency: (state, payload) => ({ ...state, currency: payload.currency || {} }),
    setConfirmation: (state, payload) => ({ ...state, confirmed: payload }),
    setApproval: (state, payload) => ({
      ...state,
      info: { ...state.info, approvalReceived: payload },
    }),
    loadSettings: (state, payload) => ({ ...state, ...payload }),
    /**
     * #TODO: no need payload.data.contacts
     */
    loadContacts: (state, payload) => ({ ...state, contacts: payload.data?.contacts || payload }),
  },
  effects: ({ api }) => ({
    async checkPresence(_, state) {
      const user = getUser(state);

      if (isEmpty(user)) {
        const payload = await api.get({ url: '/user' });

        this.refresh(payload);
      }
    },
    async checkCurrency(_, state) {
      if (!isEmpty(state.user.currency)) {
        return;
      }

      const { id } = getUser(state);
      const payload = await api.get({
        url: `currency/settings/get${id ? `/${id}` : ''}`,
      });

      this.setCurrency(payload);
    },
    async checkOrderSettings(_, state) {
      if (!isEmpty(state.user.orderSettings)) {
        return;
      }

      const { id } = getUser(state);
      const payload = await api.get({
        url: `orders/settings/get${id ? `/${id}` : ''}`,
      });

      this.setOrderSettings(payload);
    },
    async checkViewSettings(id, state) {
      if(getEffectLoading('user', 'checkViewSettings')(state)){
        return;
      }
      const uid = id || getUser(state).id;

      const isEmptySettings =
        !isItemsVisibilitySaved(state)(uid) ||
        !isOrdersVisibilitySaved(state)(uid) ||
        !hasFavoriteCategories(state);

      if (!isEmptySettings) {
        return;
      }

      const payload = await api.get({
        url: `view/settings/get${uid ? `/${uid}` : ''}`,
      });

      this.loadSettings(payload);
    },
    async getManagerCategories() {
      return await api.get({
        url: 'help/contacts/categories',
      });
    },
    async getUserContacts() {
      try {
        const contacts = await api.get({
          url: 'help/contacts',
        });

        this.loadContacts(contacts);
      } catch (err) {}
    },
    async getCategoryProducts(cat) {
      try {
        const { success, data, message } = await api.get({
          url: `help/contacts/managers${cat && `/${cat}`}`,
        });

        if (success) {
          return data.data;
        }

        alert(message);
      } catch (err) {}
    },
  }),
  selectors: (slice, createSelector, hasProps) => ({
    getCurrency() {
      return slice(user => user?.currency || {});
    },
    getCurrencyCode() {
      return createSelector(this.getCurrency, ({ Code }) => Code || 'USD');
    },
    getCurrencyRate() {
      return createSelector(this.getCurrency, ({ Rate }) => Rate);
    },
    getOrderSettings() {
      return slice(user => user.orderSettings || {});
    },
    getInfo() {
      return slice(user => user.info);
    },
    getContacts() {
      return slice(user => user.contacts);
    },
    getDecoratedContacts() {
      return createSelector(this.getContacts, contacts => createContactsSections(contacts));
    },
    getIsAllowPrinting() {
      return createSelector(this.getInfo, user => Number(user['Ban Printing Documents']) !== 1);
    },
    getMenu() {
      return slice(user => {
        try {
          return JSON.parse(get(user, ['info', 'menu'], ''));
        } catch {
          return {};
        }
      });
    },
    getPilotUris() {
      return slice(user => {
        try {
          return JSON.parse(get(user, ['info', 'pilotUris'], ''));
        } catch {
          return [];
        }
      });
    },
    getRoutes: hasProps(({ user }, routes = []) =>
      createSelector(user.getPilotUris, menu => {
        return getRoutesByPilot(routes, menu);
      }),
    ),
    //TODO: временное решение 01.04.2022 B2B-2624
    getApproval() {
      return slice(() => true);
    },
    getConfirmed() {
      return slice(({ confirmed }) => confirmed);
    },
  }),
};

export default user;
