const colorStatusShipmentitem = status => {
  switch (status) {
    case 'утвержден':
      return 'status--red';
    case 'подписан клиентом':
      return 'status--red';
    case 'оплачен':
      return 'status--orange';
    case 'комплектуется':
      return 'status--green';
    default:
      return 'status--black';
  }
};

export default colorStatusShipmentitem;
