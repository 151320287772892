import React, { Fragment } from 'react';
import { isCactus } from 'common/config';

const content = isCactus
  ? {
      href: 'https://officeassistant.ru/partners/welcome/',
      module: 'модуле OfficeAssistant',
    }
  : {
      href: 'https://merlion.com/partners/welcome/',
      module: 'Интернет модуле',
    };

const HelpInRegistration = () => {
  const { href, module } = content;
  return (
    <Fragment>
      Для регистрации в {module} заполните форму{' '}
      <a href={href} rel="noopener noreferrer" target="_blank">
        здесь
      </a>{' '}
      или обратитесь к вашему менеджеру.
    </Fragment>
  );
};

export default HelpInRegistration;
