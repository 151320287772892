import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const DeleteLarge = ({
  className,
  color = '#236192',
  name,
  width = '16',
  height = '16',
  viewBox = '0 0 16 16',
  title = 'Удалить',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{title}</title>
    <mask id="path-1-inside-1_8792_13976" fill="white">
      <path d="M13.3334 4V13.1954C13.3334 14.0046 12.5949 14.6667 11.6923 14.6667H4.30771C3.40515 14.6667 2.66669 14.0046 2.66669 13.1954V4" />
    </mask>
    <path
      d="M12.3334 4V13.1954H14.3334V4H12.3334ZM12.3334 13.1954C12.3334 13.3524 12.1481 13.6667 11.6923 13.6667V15.6667C13.0417 15.6667 14.3334 14.6568 14.3334 13.1954H12.3334ZM11.6923 13.6667H4.30771V15.6667H11.6923V13.6667ZM4.30771 13.6667C3.85194 13.6667 3.66669 13.3524 3.66669 13.1954H1.66669C1.66669 14.6568 2.95836 15.6667 4.30771 15.6667V13.6667ZM3.66669 13.1954V4H1.66669V13.1954H3.66669Z"
      fill={color}
      mask="url(#path-1-inside-1_8792_13976)"
    />
    <path
      d="M1.33331 4H14.6666"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6666 3.99998H5.33331V2.39998C5.33331 1.81331 5.73331 1.33331 6.2222 1.33331H9.77776C10.2666 1.33331 10.6666 1.81331 10.6666 2.39998V3.99998Z"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

DeleteLarge.defaultProps = {
  className: '',
  color: '#236192',
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  title: 'Удалить',
};

DeleteLarge.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  title: PropTypes.string,
};
