import React, { useState } from 'react';
import { Field, getFormValues, reduxForm, SubmissionError } from 'redux-form';
import { renderText } from 'components/fields';
import { getDaysOptions, getMonthOptions, getYearsOptions, mask, workMask } from '../helpers/util';
import {
  renderBirthdayField,
  renderPersonalEmail,
  renderMasked,
  renderMessenger,
  renderCity,
  renderRegion,
  renderCheckbox,
} from './fields';
import { SKYPE_VALUE, TELEGRAM_VALUE, VIBER_VALUE, WHATSAPP_VALUE } from 'common/config';
import { Link } from 'react-router-dom';
import validate from 'common/validates/validatePersonal';
import { useDispatch, useSelector } from 'react-redux';
import alert from 'components/native/alert/function';
import classNames from 'classnames';

const handleSubmitFail = (errors, dispatch, submitError) => {
  if (submitError instanceof SubmissionError) {
    alert(submitError.errors._error);
  } else {
    throw submitError;
  }
};

function PersonForm(props) {
  const { user, onSubmit, isNew, handleSubmit, submitting } = props;

  const [daysOptions, setDaysOptions] = useState(null);

  const dispatch = useDispatch();

  const info = useSelector(getFormValues('personalForm'));
  const { birthMonth, birthYear, login, approvalReceived } = info;

  const handleMonthChange = newValue => {
    setDaysOptions(getDaysOptions(newValue.value, birthYear));
  };

  const handleYearChange = newValue => {
    setDaysOptions(getDaysOptions(birthMonth, newValue.value));
  };

  const sendConfirm = async email => {
    await dispatch.personal.confirmEmail({ email, login });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="personal-container">
        <div className="row">
          <Field name="secondName" type="text" label="Фамилия" required component={renderText} />
          <Field name="position" type="text" label="Должность" required component={renderText} />
          <Field name="name" type="text" label="Имя" required component={renderText} />
          <Field
            name="login"
            type="text"
            label="Логин"
            required={isNew}
            readonly={!isNew}
            component={renderText}
          />
          <Field name="thirdName" type="text" label="Отчество" component={renderText} />
          <div className="col-sm-6">
            <label>Дата Рождения</label>
            <div className="row birthday-field-container">
              <Field
                name="birthDate"
                options={daysOptions || getDaysOptions(birthMonth, birthYear)}
                style={{ width: '70px' }}
                placeholder="День"
                component={renderBirthdayField}
              />
              <Field
                name="birthMonth"
                options={getMonthOptions()}
                style={{ width: '120px' }}
                placeholder="Месяц"
                component={renderBirthdayField}
                handle={handleMonthChange}
              />
              <Field
                name="birthYear"
                options={getYearsOptions(1910)}
                style={{ width: '90px' }}
                placeholder="Год"
                component={renderBirthdayField}
                handle={handleYearChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <Field
            name="regionId"
            label="Регион"
            required
            component={renderRegion}
            info={info}
            isNew={isNew}
            placeholder="Выберите регион"
          />
          <Field
            name="cityId"
            label="Город"
            required
            component={renderCity}
            info={info}
            isNew={isNew}
          />
        </div>
        <div className="row">
          <Field
            name="workPhone"
            label="Рабочий телефон"
            mask={workMask}
            component={renderMasked}
          />
          <Field
            name="email"
            label="E-mail"
            required
            component={renderPersonalEmail}
            confirm={sendConfirm}
            isNew={isNew}
          />
          <Field
            name="mobilePhone"
            label="Мобильный телефон"
            required
            mask={mask}
            component={renderMasked}
          />
          <Field name="icq" label="ICQ" component={renderText} />
          <div className="col-sm-6 messengers-container">
            <label>Предпочитаемый мессенджер</label>
            <div>
              <Field
                id="wa"
                label="WhatsApp"
                name="messenger"
                defaultValue={WHATSAPP_VALUE}
                component={renderMessenger}
              />
              <Field
                id="vi"
                label="Viber"
                name="messenger"
                defaultValue={VIBER_VALUE}
                component={renderMessenger}
              />
              <Field
                id="te"
                label="Telegram"
                name="messenger"
                defaultValue={TELEGRAM_VALUE}
                component={renderMessenger}
              />
              <Field
                id="sk"
                label="Skype"
                name="messenger"
                defaultValue={SKYPE_VALUE}
                component={renderMessenger}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row justify-content-center required align-items-center agreement-container">
            <Field
              name="approvalReceived"
              className="personal-container__checkbox"
              label={
                isNew || user.login !== login
                  ? 'Согласие работника на передачу данных получено'
                  : 'Я согласен на обработку персональных данных'
              }
              required
              component={renderCheckbox}
            />
          </div>
          <div>
            <small>
              <span className="required">*</span> поля обязательные для заполнения
            </small>
          </div>
          <div className="form-actions">
            <div>
              <Link
                to={{ pathname: '/settings/setup', state: { login } }}
                className="btn btn-outline-danger btn--rect"
              >
                Настройки
              </Link>
              {!isNew ? (
                <Link to="/settings/change-password" className="btn btn-outline-danger btn--rect">
                  Сменить пароль
                </Link>
              ) : (
                <Link to="/settings/employees" className="btn btn-danger btn--rect">
                  Назад
                </Link>
              )}
              <button
                type="submit"
                disabled={submitting || !approvalReceived}
                className={classNames('btn btn--rect', {
                  'btn-outline-danger': !approvalReceived,
                  'btn-danger': approvalReceived,
                })}
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'personalForm',
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmitFail: handleSubmitFail,
})(PersonForm);
