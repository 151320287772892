import React, { useEffect } from 'react';
import AdsItem from './AdsItem';
import Pagination from './Pagination';
import useFlexPagination from '../../../hooks/useFlexPagination';
import Preloader from 'components/preloader';
import { Link } from 'react-router-dom';
import Mixpanel from 'services/mixpanel';
import { useDispatch, useSelector } from 'react-redux';
import { getAdsList } from 'pages/Marketing/selectors';
import { getEffectLoading } from 'common/selectors';

const AdsList = () => {
  const items = useSelector(getAdsList);
  const loading = useSelector(getEffectLoading('news', 'getAds'));
  const {
    news: { getAds },
  } = useDispatch();

  useEffect(() => {
    getAds();
  }, []);

  const itemsArr = Array.isArray(items) ? items : [];
  const [result, adsRef, handleChangePage] = useFlexPagination(itemsArr, 140);
  const { elements, page, pages } = result;
  const showPagination = !!elements.length && !loading;

  return (
    <div>
      <div className="ads-list ads-list__row">
        <h5>
          <i className="icon icon-ads" />
          <Link
            to="/news"
            onClick={() => {
              Mixpanel?.track('СтрГлавнаяСтраница.Объявления', {
                page: 'СтрГлавнаяСтраница',
              });
            }}
          >
            Объявления
          </Link>
        </h5>
        {loading && <Preloader />}
        {!loading && (
          <div ref={adsRef} className="item-list__container">
            {elements.map((elem, index) => {
              const show = page - 1 === index ? 'block' : 'none';
              const style = { display: show };

              return (
                <ul style={style} key={index}>
                  {elem.map(ads => (
                    <AdsItem ads={ads} key={ads.newsId} />
                  ))}
                </ul>
              );
            })}
          </div>
        )}
        {showPagination && (
          <div className="news-list__pagination">
            <Pagination
              page={page}
              pages={pages}
              onChangePage={handleChangePage}
              parent="Объявления"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdsList;
