import alert from 'components/native/alert/function';
import { getOrder } from 'pages/Orders/selectors';
import { SubmissionError } from 'redux-form';
import ReactHtmlParser from 'react-html-parser';
import { preparedData } from 'pages/Orders/components/order-edit/helpers/prepareData';

export default dispatch =>
  async ({ handleData, isPilot }, state) => {
    const formData = new FormData();

    formData.append('name', handleData.items.name);
    formData.append('items', handleData.items);

    const order = getOrder(state);

    const url = handleData.orderNo
      ? `/orders/edit/import-file/${handleData.orderNo}`
      : `/orders/edit/import-file/${order?.orderNo || 'NEW'}`;

    const { success, message, data } = await dispatch.api.post({
      url,
      query: '?key=130',
      data: formData,
      successPayload: {
        dataKey: isPilot && 'productFromExcel',
      },
    });

    if (!success) {
      throw new SubmissionError({
        _error: ReactHtmlParser(message),
      });
    }

    if (message) {
      alert(message);
    }

    const importItems = [];
    const items = data?.data || [];

    items.forEach((item, index) => {
      if (Number(item.desiredQty)) {
        preparedData.set('qtys', item.id, item.desiredQty);
        preparedData.set('prices', item.id, item.desiredPriceRUB);
        preparedData.set('priceClients', item.id, item.desiredPriceClient);
        preparedData.set('line', item.id, index);
        importItems.push(item.id);
      }
    });

    preparedData.set('importItems', importItems);

    return { success, message, data };
  };
