import confirm from 'components/native/confirm/function';
import { QUEUE_ORDER } from 'common/config';
import notifier from 'services/notifier';
import alert from 'components/native/alert/function';

export default dispatch =>
  async ({ id, auto = false, sign = true, isList = false }) => {
    const confirmation = sign
      ? [
          'После подписания заказ нельзя будет редактировать!',
          '<br />Для получения груза необходимо иметь доверенность и паспорт',
        ].join('')
      : 'Вы действительно хотите снять подпись с заказа?';

    try {
      await confirm(confirmation);

      const { success, message } = await dispatch.api.post({
        url: `orders/${auto ? 'auto-' : ''}sign/${id}`,
        data: {
          signed: sign,
          queueType: QUEUE_ORDER,
          command: sign ? 'sign' : 'unsign',
        },
        cancellation: false,
      });

      if (success) {
        notifier.show(id, `Заказ ${id} ${sign ? 'отправлен на подпись' : 'снимается с подписи'}`);
        isList &&
          dispatch.list.changeRow({
            searchKey: 'orderNo',
            needle: id,
            disabled: true,
            statusText: sign ? 'на подписании' : 'снимается с подписи',
          });
      } else {
        alert(message);
      }
    } catch (e) {
      console.error(e);
    }
  };
