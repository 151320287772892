import React from 'react';
import { hasAccess } from 'common/util';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'pages/Orders/selectors';

const DisabledSignatureButton = ({ row }) => {
  const user = useSelector(getUser);
  const {
    order: { handleSign },
  } = useDispatch();

  if (
    row.status === 'подписан клиентом' &&
    (row.initiator === user.login || hasAccess(user, 'ADM'))
  ) {
    return (
      <i
        className="icon icon-unsign enabled"
        title="Снять подпись клиента"
        onClick={async () => await handleSign({ id: row.orderNo, sign: false })}
      />
    );
  }

  return <i className="icon icon-sign-dis" title="Подпись клиента" />;
};

export default DisabledSignatureButton;
