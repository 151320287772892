import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Laptop = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M8 2.00005H10"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 14H11"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17L11 14"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 17L7 14"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 12L14 12"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 2C1.5 1.17157 2.17157 0.5 3 0.5H15C15.8284 0.5 16.5 1.17157 16.5 2V9C16.5 9.27614 16.2761 9.5 16 9.5H2C1.72386 9.5 1.5 9.27614 1.5 9V2Z"
        stroke={color}
      />
      <path
        d="M1.27715 11.8139C1.37098 11.0633 2.00908 10.5 2.76556 10.5H15.2344C15.9909 10.5 16.629 11.0633 16.7229 11.8139L17.2229 15.8139C17.3348 16.7092 16.6367 17.5 15.7344 17.5H2.26556C1.36332 17.5 0.665238 16.7092 0.777148 15.8139L1.27715 11.8139Z"
        stroke={color}
      />
    </svg>
  );
};

Laptop.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Laptop.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
