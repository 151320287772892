import { useSelector } from 'react-redux';
import { getLendingFormValue } from 'pages/Finances/selectors';
import React from 'react';
import { Field } from 'redux-form';
import { creditDocList, requiredValidate } from '../constants';
import { RenderSelect } from 'components/fields';
import { getEffectLoading } from 'common/selectors';

const OfpField = ({ onChange }) => {
  const contractor = useSelector(getLendingFormValue('ofpPartner.contractor'));
  const loadingDocuments = useSelector(getEffectLoading('finances', 'getCreditDocList'));

  if (!contractor) return null;

  const { orgLegalForm } = contractor;

  if (orgLegalForm) {
    return (
      <div className="form-field-select">
        <div className="form-field-select__label">
          <span className="form-field-select__label-text">Организационно-правовая форма</span>
          <div className="form-field-select__select-alt">{orgLegalForm}</div>
        </div>
      </div>
    );
  }

  return (
    <Field
      options={creditDocList}
      validate={[requiredValidate]}
      valueKey="code"
      labelKey="name"
      isLoading={loadingDocuments}
      component={RenderSelect}
      name="ofp"
      label="Организационно-правовая форма"
      placeholder="Выберите организационно-правовую форму"
      onChange={onChange}
    />
  );
};

export default OfpField;
