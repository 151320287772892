import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Preloader from 'components/preloader';
import EventItem from './EventItem';
import useFlexPagination from '../../../hooks/useFlexPagination';
import Pagination from './Pagination';
import Mixpanel from 'services/mixpanel';
import { useDispatch, useSelector } from 'react-redux';
import { getEventsForNews } from 'pages/Marketing/selectors';
import { getEffectLoading } from 'common/selectors';

const EventsList = () => {
  const events = useSelector(getEventsForNews);
  const loading = useSelector(getEffectLoading('events', 'get'));
  const {
    events: { get },
  } = useDispatch();

  useEffect(() => {
    get();
  }, []);

  const [result, eventsRef, handleChangePage] = useFlexPagination(events, 100);
  const { elements, pages, page } = result;
  const showPagination = !!elements.length && !loading;

  return (
    <div className="events-list">
      <div className="news-list news-list--mini news-list__row">
        <h5>
          <i className="icon icon-news" />
          <Link
            to="/events"
            onClick={() => {
              Mixpanel?.track('СтрГлавнаяСтраница.Мероприятия', {
                page: 'СтрГлавнаяСтраница',
              });
            }}
          >
            Мероприятия
          </Link>
        </h5>
        {loading && <Preloader />}

        {!loading && (
          <div ref={eventsRef} className="item-list__container">
            {(elements.length &&
              elements.map((elem, index) => {
                const show = page - 1 === index ? 'block' : 'none';
                const style = { display: show };

                return (
                  <ul style={style} key={index}>
                    {elem.map(event => (
                      <EventItem activity={event} key={event.no} />
                    ))}
                  </ul>
                );
              })) || (
              <p>
                Нет активных мероприятий. Перейти в <Link to="/events/archive">архив</Link>
              </p>
            )}
          </div>
        )}
        {showPagination && (
          <div className="news-list__pagination">
            <Pagination
              page={page}
              pages={pages}
              onChangePage={handleChangePage}
              parent="События"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EventsList;
