import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import HtmlParse from 'react-html-parser';

toast.configure({
  hideProgressBar: true,
});

const Request = ({ message }) => (
  <div className="notify-request">
    {message}
    <i className="loader" />
  </div>
);

const notifier = {
  show: (id, message = 'Идет обработка...') => {
    toast(<Request message={message} />, {
      toastId: id,
      updateId: id,
      autoClose: false,
      closeButton: false,
    });
  },

  resolve: ({
    success = false,
    message = 'Готово',
    autoClose,
    closeOnClick = true,
    onClick = Function.prototype
  }, id) => {
    toast.update(id, {
      render: <>{HtmlParse(message)}</>,
      type: success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
      autoClose: Number(autoClose) || 3000,
      closeButton: true,
      closeOnClick,
      className: 'animated',
      onClick,
    });
  },
  dismiss: toast.dismiss,
  notify: toast.info,
};

export default notifier;
