import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Unlock = ({
  className,
  color = '#236192',
  name,
  width = '16',
  height = '16',
  viewBox = '0 0 16 16',
  title = 'Заблокировать',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    title={title}
  >
    <title>{title}</title>
    <path
      d="M10.5 16H1.5C0.67334 16 0 15.3274 0 14.5V7.5C0 6.67261 0.67334 6 1.5 6H10.5C11.3267 6 12 6.67261 12 7.5V14.5C12 15.3274 11.3267 16 10.5 16ZM1.5 7C1.22461 7 1 7.224 1 7.5V14.5C1 14.776 1.22461 15 1.5 15H10.5C10.7754 15 11 14.776 11 14.5V7.5C11 7.224 10.7754 7 10.5 7H1.5Z"
      fill={color}
    />
    <path
      d="M15.5 7C15.224 7 15 6.776 15 6.5V4C15 2.34595 13.6541 1 12 1C10.3459 1 9 2.34595 9 4V6.5C9 6.776 8.776 7 8.5 7C8.224 7 8 6.776 8 6.5V4C8 1.79395 9.79395 0 12 0C14.2061 0 16 1.79395 16 4V6.5C16 6.776 15.776 7 15.5 7Z"
      fill={color}
    />
  </svg>
);

Unlock.defaultProps = {
  className: '',
  color: '#236192',
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  title: 'Заблокировать',
};

Unlock.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  title: PropTypes.string,
};
