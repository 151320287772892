// @ts-nocheck
import get from 'lodash/get';
import alert from 'components/native/alert/function';
import { CACHE_NAME } from 'common/config';
import { NUMBER_TOP_ITEMS } from '../constants';

export default {
  name: 'newActions',
  state: {
    items: [],
    pages: 0,
    pageSize: 25,
    fetching: false,
    acceptActionFetching: {},
  },
  reducers: {
    fetchActionsRequest: state => {
      state.fetching = true;
      return state;
    },
    fetchActionsSuccess: (state, payload) => {
      state.items = get(payload, ['data', 'data'], []);
      state.pages = get(payload, ['data', 'pages'], 0);
      state.pageSize = get(payload, ['data', 'pageSize'], 25);
      state.fetching = false;
      return state;
    },
    acceptActionRequest: (state, payload) => {
      state.acceptActionFetching[payload.index] = true;
      return state;
    },
    actionPullSuccess: (state, payload) => {
      state.acceptActionFetching[payload.rowIndex] = get(payload, 'result', true);
      return state;
    },
    actionPullError: (state, payload) => {
      state.acceptActionFetching[payload.rowIndex] = get(payload, 'result', false);
      return state;
    },
  },
  effects: ({ api }) => ({
    async fetchActions() {
      try {
        this.fetchActionsRequest();
        const result = await api.get({
          url: '/new-actions',
          query: `?p=1&pageSize=${NUMBER_TOP_ITEMS}`,
          cache: CACHE_NAME,
        });

        this.fetchActionsSuccess(result);

        return result;
      } catch (err) {
        return null;
      }
    },
    async acceptAction(row) {
      this.acceptActionRequest(row);
      const result = await api.post({
        url: `new-actions/accept/${row.original.no}`,
        data: { rowIndex: row.index },
      });

      return result;
    },
    receive(message) {
      this.actionPullSuccess(message.data);
    },
    expire(message) {
      alert('Ваш запрос обрабатывается. Обновите страницу через некоторое время.');
      this.actionPullError(message);
    },
  }),
  selectors: (slice, _, hasProps) => ({
    getActions() {
      return slice(state => state);
    },
    getAcceptActionFetching: hasProps((_, index) =>
      slice(state => get(state, ['acceptActionFetching', index], false)),
    ),
  }),
};
