import dateFormat from 'dateformat';
import createValue from '../helpers/createValue';

export default {
  key: 'currentDate',
  create: () => ({
    label: 'Дата:',
    value: createValue(dateFormat(new Date(), 'dd.mm.yyyy')),
  }),
};
