import { isObject, get, setWith, unset, isEmpty, merge, isFunction, mergeWith, cloneDeepWith, xor } from 'lodash';

/**
 * One more global store for objects
 */
export default (function ObjCollector() {
  const store = new Map();

  function ObjCollector(init = {}) {
    if (!isObject(init)) {
      throw Error(`ObjCollector expects object as param, ${typeof init} given`);
    }

    this.customizer = Function.prototype
    this.initial = {...init};

    this.counter = 0;
    store.set(this, init);
  }

  ObjCollector.prototype.get = function (...args) {
    if(isEmpty(args)){
      return cloneDeepWith(store.get(this), this.customizer)
    }

    return cloneDeepWith(get(store.get(this), args, null), this.customizer);
  };

  ObjCollector.prototype.set = function (...args) {
    if (args.length > 1) {
      const value = args.pop();
      const obj = this.get();

      setWith(obj, args, value, Object);

      store.set(this, obj);

      return;
    }

    store.set(this, args.pop());
  };

  ObjCollector.prototype.listen = function (cb = Function.prototype) {
    const obj = this.get()

    return new Proxy(obj, {
      set: function(target, key, value){
        cb(key, value)

        setWith(obj, key, value, Object);
        store.set(this, obj);

        return true;
      }
    })
  }

  ObjCollector.prototype.setSafe = function (...args) {
    const path = args.slice(0, args.length - 1);

    if (!this.has(...path)) {
      this.set(...args);
    }
  };

  ObjCollector.prototype.setIncrement = function(value) {
    this.set(this.counter, value)
    this.counter++
  }

  ObjCollector.prototype.add = function (obj = {}) {
    store.set(this, { ...this.get(), ...obj });
  };

  ObjCollector.prototype.addReverse = function (obj = {}) {
    store.set(this, { ...obj, ...this.get() });
  };

  ObjCollector.prototype.merge = function(obj = {}, customizer) {
    const current = {...this.get()}

    if(isFunction(customizer)){
      this.set(mergeWith(current, obj, customizer))
    }
    else{
      this.set(mergeWith(current, obj, this.customizer))
    }
  }

  ObjCollector.prototype.mergeReverse = function(obj = {}, customizer) {
    const current = {...this.get()}

    if(isFunction(customizer)){
      this.set(mergeWith(obj, current, customizer))
    }
    else{
      this.set(merge(obj, current))
    }
  }

  ObjCollector.prototype.has = function (...path) {
    return !!get(this.get(), path, null);
  };

  ObjCollector.prototype.includes = function(...path) {
    const needle = path.pop()
    const haystack = this.get(...path)

    if(!Array.isArray(haystack)){
      return false
    }

    return haystack.includes(needle)
  }

  ObjCollector.prototype.push = function(...path) {
    const needle = path.pop()
    let haystack = this.get(...path)

    if(!Array.isArray(haystack)){
      haystack = []
    }

    haystack.push(needle)

    this.set(...path, [...new Set(haystack)])
  }

  ObjCollector.prototype.pull = function(...path) {
    const needle = path.pop()
    const haystack = this.get(...path)

    if(!Array.isArray(haystack)){
      return false
    }

    this.set(...path, haystack.filter(id => id !== needle))
  }

  ObjCollector.prototype.xor = function (...path) {
    const newPath = path.slice(0, -1)

    this.delete(...path)

    return !isEmpty(this.get(...newPath))
  }

  ObjCollector.prototype.delete = function (...args) {
    if (args.length) {
      const obj = this.get();

      unset(obj, args);
      store.set(this, obj);

      return;
    }

    store.delete(this);
  };

  ObjCollector.prototype.clear = function () {
    const init = { ...this.initial };

    this.counter = 0;
    store.set(this, init);
  };

  ObjCollector.prototype.clearAll = function () {
    for (const collector of store.keys()) {
      collector.clear();
    }
  };

  ObjCollector.prototype.isEmpty = function () {
    return isEmpty(store.get(this));
  };

  return ObjCollector;
})();
