import { DEFAULT_PAGE_SIZE } from 'common/config';
import saveSettings from 'models/effects/saveSettings';

const load = (state, payload) => {
  const { pageSize } = payload.tableList || {};
  state.pageSize = pageSize;

  return state;
};

export default {
  name: 'listSettings',
  state: {
    pageSize: { default: DEFAULT_PAGE_SIZE },
  },
  reducers: {
    sizeChange: (state, { name, size }) => ({
      ...state,
      pageSize: {
        ...state.pageSize,
        [name]: size,
      },
    }),
    'user/loadSettings': load,
    'user/login': load,
  },
  effects: dispatch => ({
    async pageSizeChange({ size, name = 'default' }, { listSettings: { pageSize } }) {
      this.sizeChange({ name, size });

      await saveSettings({
        tableList: { pageSize: { ...pageSize, [name]: size } },
      })(dispatch);
    },
  }),
  selectors: (slice, createSelector, hasProps) => ({
    getPageSize: hasProps((models, tableName) =>
      slice(listSettings => listSettings.pageSize[tableName] || DEFAULT_PAGE_SIZE),
    ),
  }),
};
