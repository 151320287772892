import { FIELD_BOOL } from './input-type';

export const staticFilters = [
  {
    id: 'existActions',
    caption: 'Акция',
    inputType: FIELD_BOOL,
    values: [{ id: 'existActions', propertyId: 'existActions', measure: '', value: 'Да' }],
  },
  {
    id: 'newGood',
    caption: 'Новый',
    inputType: FIELD_BOOL,
    values: [{ id: 'isNew', propertyId: 'newGood', measure: '', value: 'Да' }],
  },
  {
    id: 'badPackaging',
    caption: 'Плохая упаковка',
    inputType: FIELD_BOOL,
    values: [{ id: 'плохая упаковка', propertyId: 'badPackaging', measure: '', value: 'Да' }],
  },
];

export const staticFilterIds = staticFilters.map(filter => filter.id);
