import React from 'react';

const DocumentWithError = props => {
  const { doc } = props;
  const { errorText } = doc;

  const isErrorPresent = typeof errorText === 'string' && errorText.length > 0;
  const message = isErrorPresent ? `Ошибка: ${errorText}` : 'Неизвестная ошибка';

  return (
    <p className="btn-download-blocked">
      <i className="btn-download-blocked__pdf" />
      <span className="btn-download-blocked__body">
        <span className="btn-download-blocked__title">{doc.docName}</span>
        <span className="btn-download-blocked__date">
          {doc.date} {doc.time}
        </span>
        <span title={doc.errorText} className="btn-download-blocked__error">
          {message}
        </span>
      </span>
    </p>
  );
};

export default DocumentWithError;
