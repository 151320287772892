import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { getCurrencySymbol } from 'common/util';
import useCarousel from '../../../hooks/useCarousel';
import { trackingCardPage } from 'pages/Orders/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import Preloader from 'components/preloader';
import isEqual from 'lodash/isEqual';
import { DEFAULT_PHOTO_SMALL } from 'common/config';

const Related = React.memo(props => {
  const { relatedSlides, order } = props;
  let refs = {};

  const { id } = useParams();
  const isRelatedFetching = useSelector(getEffectLoading('product', 'getRelated'));
  const {
    product: { addToOrder },
  } = useDispatch();

  const [slideShift, isFirstSlide, isLastSlide, handleNextSlideClick, handlePrevSlideClick] =
    useCarousel(
      {
        ...props,
        containerClassName: 'related__slide',
      },
      [relatedSlides.length],
    );

  if (isRelatedFetching) {
    return <Preloader />;
  }

  if (!relatedSlides.length) {
    return null;
  }

  relatedSlides.flat().forEach(slide => {
    refs = { ...refs, [`${slide.id}_${slide.groupId}`]: React.createRef() };
  });

  const addRelated = (id, uniqueId, price, clientPrice) => e => {
    e.preventDefault();
    addToOrder({
      qty: refs[uniqueId].current.value,
      price,
      priceClient: clientPrice,
      relatedId: id,
    });
  };

  return (
    <Fragment>
      <div className="row">
        <hr className="view__line" />
      </div>

      <div className="related">
        <h2 className={classNames('related__head', 'font-size__xlarge')}>
          С этим товаром покупают
        </h2>
        <div className="related__products">
          <div
            onClick={handlePrevSlideClick}
            className={classNames('related__arrow', 'related__arrow--left', {
              'related__arrow--disabled': isFirstSlide(),
            })}
          >
            <a href="#previousProducts">
              <i className="icon icon-back-grey-icon" />
            </a>
          </div>
          <div className="related__slider__window">
            <div style={{ left: `-${slideShift}px` }} className="related__slider__door">
              {relatedSlides.map((relatedSlide, index) => (
                <div key={index} className="related__slide">
                  {relatedSlide.map(product => {
                    const productUniqueId = `${product.id}_${product.groupId}`;
                    const photoSmall = product.imageUrl || DEFAULT_PHOTO_SMALL.url;

                    return (
                      <div className="related__product" key={productUniqueId}>
                        <Link
                          to={{
                            pathname: `/products/view/${product.id}`,
                            state: {
                              order: order?.orderNo || 'NEW',
                              relatedId: id,
                            },
                          }}
                          className="related__product__photo"
                          onClick={trackingCardPage(product.id, product.name, 'СопутТовары')}
                        >
                          <img
                            src={photoSmall}
                            className="related__product__image"
                            alt={product.name}
                          />
                        </Link>
                        <Link
                          to={{
                            pathname:
                              (order?.orderNo && `/orders/edit/${order.orderNo}`) || '/orders/new',
                            state: {
                              groupNo: product.code2,
                            },
                          }}
                          className={classNames(
                            'related__product__title',
                            'font-size__small',
                            'font-color__red',
                          )}
                        >
                          {product.group2}
                        </Link>
                        <Link
                          to={{
                            pathname: `/products/view/${product.id}`,
                            state: {
                              orderNo: order?.orderNo || 'NEW',
                              relatedParentId: id,
                            },
                          }}
                          className={classNames(
                            'related__product__title',
                            'font-size__small',
                            'font-color__black--hover',
                          )}
                          onClick={trackingCardPage(product.id, product.name, 'СопутТовары')}
                        >
                          {product.name}
                        </Link>
                        <p
                          className={classNames(
                            'related__product__id',
                            'font-size__small',
                            'font-color__grey',
                          )}
                        >
                          id {product.id}
                        </p>
                        <div className="related__product__footer">
                          <p
                            className={classNames(
                              'related__product__price',
                              'font-size__regular',
                              'font-color__black',
                            )}
                          >
                            <strong>
                              {product.priceLCY}
                              {getCurrencySymbol('РУБ')}
                            </strong>
                          </p>
                          <input
                            className={classNames(
                              'related__product__input-text',
                              'font-size__regular',
                              'font-color__black',
                            )}
                            ref={refs[productUniqueId]}
                            key={productUniqueId}
                          />
                          <a
                            href="#buy"
                            title="Добавить в заказ"
                            onClick={addRelated(
                              product.id,
                              productUniqueId,
                              product.priceLCY,
                              product.priceClient,
                            )}
                            key={productUniqueId}
                          >
                            <i className="icon icon-shop related__product__button" />
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
          <div
            onClick={handleNextSlideClick}
            className={classNames('related__arrow', 'related__arrow--right', {
              'related__arrow--disabled': isLastSlide(),
            })}
          >
            <a href="#prevSlide">
              <i className="icon icon-next-grey-icon" />
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
}, isEqual);

export default Related;
