import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getFormValue } from 'pages/Orders/selectors';
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';
import { createInitialDate } from 'pages/Orders/components/order-edit/helpers/createInitialDate';
import { getEffectLoading } from 'common/selectors';
import { useDesignSelect } from 'hooks/new';
import useUpdateEffect from 'hooks/use-update-effect';
import useDeepEffect from 'hooks/use-deep-effect';
import useCanEdit from 'pages/new/orders/hooks/use-can-edit';
import { CustomDatePicker } from '../../../common/form-fields/CustomDatePicker';

const ShipmentDateField = ({ input, name, label, className, arrowRenderer }) => {
  const { value, onChange } = input;

  const {
    select: { order },
  } = useStore();
  const {
    order: { getShipmentDates, initForm },
  } = useDispatch();

  const { orderShipmentDates, shipmentDate, docType } = useSelector(order.getHeader);
  const { shipmentDate: settingsShipmentDate, workDateRows } = useSelector(order.getSettings);
  const type = useSelector(getFormValue('docType'));
  const method = useSelector(getFormValue('shipmentMethod'));
  const isLoading = useSelector(getEffectLoading('order', 'getShipmentDates'));

  const [dates, setDates] = useState(
    (!isEmpty(orderShipmentDates) && orderShipmentDates) || workDateRows || [],
  );
  const dateString = JSON.stringify(dates);

  const initialDate = shipmentDate || settingsShipmentDate;

  const isPreorder = useIsPreorder();
  const isReserve = Number(docType) === 1 || (type === 1 && !isPreorder) || !type;

  const canEdit = useCanEdit();

  const Select = useDesignSelect();

  useEffect(() => {
    Number(type) === 1 && method && getShipmentDates(method).then(setDates);
  }, [method, type]);

  useDeepEffect(
    () => {
      const lastDay = dates[dates.length - 1];
      onChange(createInitialDate(lastDay, dates, isPreorder, isReserve));
    },
    [dates, method, isPreorder, isReserve],
    false,
  );

  useEffect(() => {
    initForm({
      shipmentDate: createInitialDate(initialDate, dates, isPreorder, isReserve),
    });
  }, [isPreorder, isReserve, initialDate, dateString]);

  const handleDateChange = useCallback(
    day => {
      onChange(createInitialDate(day?.value || day, dates, isPreorder, isReserve));
    },
    [isPreorder, isReserve, dateString],
  );

  const options = useMemo(
    () => dates.map(shipmentDate => ({ value: shipmentDate, label: shipmentDate })),
    [dateString],
  );

  const nativeChange = isReserve ? 'onChange' : 'onDateChange';
  const customProps = {
    value,
    input,
    name,
    label,
    options,
    isLoading,
    searchable: false,
    clearable: false,
    disabled: !canEdit || isPreorder,
    placeholder: 'Выберите дату отгрузки',
    [nativeChange]: handleDateChange,
    className,
    arrowRenderer,
  };

  return isReserve ? (
    <div>
      <label>{label}</label>
      <Select {...customProps} />
    </div>
  ) : (
    <CustomDatePicker {...customProps} />
  );
};

export default ShipmentDateField;
