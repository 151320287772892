import React, { memo } from 'react';

import classNames from 'classnames';
import useLoadCategories from './hooks/useLoadCategories';
import Loader from 'components/loader';
import { createCategoryChildren } from './helpers/createCategoryChildren';
import WithHover from './components/WithHover';
import { useDispatch, useSelector } from 'react-redux';
import SubCategories from 'pages/Orders/components/catalog/components/SubCategories';
import { getFavoriteCategories } from 'pages/Orders/selectors';
import isEqual from 'lodash/isEqual';
import { getEffectLoading } from 'common/selectors';
import { LOADER_TEXT } from 'pages/Orders/components/catalog/constants';

function Catalog(props) {
  const { isDemofond } = props;
  const favorite = useSelector(getFavoriteCategories, isEqual);
  const { favoriteCategories } = useDispatch();
  const isCategoriesLoading = useSelector(getEffectLoading('catalog', 'loadCategories'));

  const { categories, handleMouseEnter, handleMouseLeave } = useLoadCategories(400);
  const emptyCategories = !categories.length;

  return (
    <div className={classNames('catalog actions-container', { 'catalog--demofond': isDemofond })}>
      <div
        className={classNames('catalog__sidebar', {
          catalog__no_category: emptyCategories,
        })}
      >
        {(isCategoriesLoading && <Loader>{LOADER_TEXT}</Loader>) ||
          (emptyCategories && 'Нет доступных категорий') || (
            <ul className="catalog__sidebar__categories">
              {categories.map(category1lvl => {
                return (
                  <li
                    key={category1lvl.id}
                    className={classNames('catalog__sidebar__category', {
                      'catalog__sidebar__category--active': false,
                    })}
                  >
                    <div
                      className="favorite_container"
                      onClick={async () => {
                        await favoriteCategories.save({ catId: category1lvl.id });
                      }}
                    >
                      <div
                        className={
                          'icon-favorite' + (category1lvl.id in favorite ? ' delete' : ' add')
                        }
                      />
                    </div>
                    <WithHover
                      key={category1lvl.id}
                      component="a"
                      href="#link"
                      className="catalog__sidebar__category__link"
                      onMouseEnter={() => handleMouseEnter(category1lvl)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {createCategoryChildren(category1lvl)}
                    </WithHover>
                  </li>
                );
              })}
            </ul>
          )}
      </div>
      <SubCategories categories={categories} favorite={favorite} {...props} />
    </div>
  );
}

Catalog.whyDidYouRender = false;

export default memo(Catalog);
