import { compose } from 'redux';
import curryCreateFilterGroupsByServer from './serverData/curryCreateFilterGroups';
import curryAddNameFilterGroup from './clientData/curryAddNameFilterGroup';
import curryAddListFilterGroup from './clientData/curryAddListFilterGroup';
import curryAddFacetsFilterGroup from './clientData/curryAddFacetsFilterGroup';

export const buildFilterStrategies = (characteristics, facets) =>
  compose(
    curryAddNameFilterGroup,
    curryAddFacetsFilterGroup(facets),
    curryAddListFilterGroup,
    curryCreateFilterGroupsByServer,
  )(characteristics);
