/* eslint-disable no-unused-vars, react/no-children-prop */

import React, { forwardRef } from 'react';
import { ReactTableDefaults } from 'react-table-v6';
import { useSelector } from 'react-redux';
import InviteActions from 'pages/Marketing/components/events/components/invite-actions';
import { getInviteStatus } from 'pages/Marketing/selectors';
import classnames from 'classnames';

const TrComponent = props => {
  const { ri, ...rest } = props;

  if (ri) {
    return (
      <div>
        <ReactTableDefaults.TrComponent
          {...rest}
          className={classnames(rest.className, 'rt-tr--invite', {
            'rt-tr--invite--registered': Number(ri.original?.registered) === 2,
          })}
        />
        <InviteActions event={ri} />
      </div>
    );
  }

  return <ReactTableDefaults.TrComponent {...rest} />;
};

const TdComponent = props => {
  const { ri, ci, children, ...rest } = props;

  const inviteStatus = useSelector(state => getInviteStatus(state)(ri?.index));

  return (
    <ReactTableDefaults.TdComponent
      {...rest}
      children={inviteStatus && ci?.id === 'registration' ? inviteStatus : children}
    />
  );
};

const getTrProps = (state, ri) => ({ ri });
const getTdProps = (state, ri, ci) => ({ ci, ri });

const extra = {
  TrComponent,
  TdComponent,
  getTrProps,
  getTdProps,
};

const withInvite = Component => {
  const InviteComponent = (props, ref) =>
    <Component {...props} {...extra} ref={ref} />;

  return forwardRef(InviteComponent);
};

export default withInvite;
