import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const ArrowDown = ({
  className,
  color = '#333',
  name,
  width = '12',
  height = '6',
  viewBox = '0 0 12 6',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M1 1L6 5L11 1" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

ArrowDown.defaultProps = {
  className: '',
  color: '#333',
  width: '12',
  height: '6',
  viewBox: '0 0 12 6',
};

ArrowDown.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
