import { proxyArgsToMethods } from 'common/util';
import { getUser } from 'pages/Orders/selectors';
import saveSettings from 'models/effects/saveSettings';
import * as selectors from './selectors';

const columnVisibleState = {
  values: {
    ozprNo: true,
    THH: true,
    orderDate: true,
    type: true,
    reserveOrOrder: true,
    contact: true,
    shipment: true,
    shipmentDate: true,
    weight: true,
    volume: true,
    priceRUB: true,
    price: true,
    status: true,
    contractorName: true,
    postingDescription: true,
    endCustomer: true,
    sign: true,
  },
  view: 'list',
  userVisibility: {},
  toggle: false,
  userWidths: {},
};

const loadColumns = (state, payload) => {
  const { values = state.values } = payload.orders || {};
  const newValues = { ...state.values, ...values };
  const uid = payload.uid || payload.user?.id;

  return {
    ...state,
    values: newValues,
    userVisibility: {
      ...state.userVisibility,
      [uid]: newValues,
    },
  };
};

const ordersColumns = {
  name: 'ordersColumns',
  state: columnVisibleState,
  reducers: {
    toggle: (state, { userId, view, ...rest }) => {
      const values = { ...(state.userVisibility[userId] || state.values), ...rest };

      return {
        ...state,
        values,
        view: view || state.view,
        userVisibility: {
          ...state.userVisibility,
          [userId]: { ...values, view: view || state.userVisibility[userId]?.view || state.view },
        },
      };
    },
    setWidths: (state, { userId, ...rest }) => {
      return {
        ...state,
        userWidths: {
          ...state.userWidths,
          [userId]: {
            ...(state.userWidths[userId] || {}),
            ...rest,
          },
        },
      };
    },
    'user/loadSettings': loadColumns,
  },
  effects: dispatch => ({
    async visibilityPersist(payload, state) {
      const user = getUser(state);
      this.toggle({ userId: user.id, ...payload });

      await saveSettings({
        orders: {
          values: {
            ...state.ordersColumns.values,
            ...payload,
          },
        },
      })(dispatch);
    },
    async setWidthsPersist(payload, state) {
      const user = getUser(state);
      this.setWidths({ userId: user.id, ...payload });
    },
  }),

  selectors(...args) {
    return proxyArgsToMethods(selectors, args);
  },
};

export default ordersColumns;
