import React from 'react';
import { withRouter } from 'react-router-dom';
import { isCactus } from 'common/config';

const LinkOldVersion = ({ location: { pathname } }) => {
  const isOrder = pathname === '/orders/new';
  const isOrderList = pathname === '/orders';
  return (
    !isCactus &&
    (isOrder || isOrderList) && (
      <a
        href={
          (isOrderList && '/?action=YB28EC01&action1=Y9425C7D') ||
          '/?action=YB28EC01&action1=Y94B26C4'
        }
        className="link-old-version"
      >
        Старая версия
      </a>
    )
  );
};

export default withRouter(LinkOldVersion);
