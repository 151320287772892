import { get } from 'lodash';
import createValue from '../helpers/createValue';
import { SHIPMENT_MSK } from '../../../../helpers';

export default {
  key: 'shipmentInfoDefault',
  create: ({ settings, shipmentMethodRows }) => ({
    label: `${get(shipmentMethodRows, [0, 'Description'], SHIPMENT_MSK)}:`,
    value: createValue(settings.shipmentDate),
  }),
};
