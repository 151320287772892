import get from 'lodash/get';
import {
  ContactCell,
  DownloadCell,
  ResultCell,
  StatusCell,
} from 'pages/Queue/components/columns/Cells';

export const documentColumns = [
  {
    Header: '',
    id: 'download',
    sortable: false,
    width: 60,
    Cell: DownloadCell,
  },
  {
    Header: 'Дата запроса',
    id: 'date',
    accessor: 'date',
    sortable: false,
  },
  {
    Header: 'Инициатор',
    id: 'contact',
    Cell: ContactCell,
    sortable: false,
  },
  {
    Header: 'Документ',
    id: 'docType',
    accessor: 'docType',
    Cell: row => get(row, ['original', 'docType'], null),
    sortable: false,
  },
  {
    Header: 'Формат',
    id: 'format',
    accessor: d => (d.docFormat == 1 ? 'XLS' : 'PDF'),
    sortable: false,
  },
  {
    Header: 'Способ получения',
    id: 'getMethod',
    accessor: d => (d.docGetMethod == 1 ? 'Файл' : 'Email'),
    sortable: false,
  },
  {
    Header: 'Статус',
    id: 'status',
    accessor: 'status',
    Cell: StatusCell,
    sortable: false,
  },
];

export const migrateColumns = [
  {
    Header: 'Дата',
    id: 'date',
    accessor: 'date',
    sortable: false,
  },
  {
    Header: 'Комманда',
    id: 'command',
    accessor: 'command',
    width: 80,
    sortable: false,
  },
  {
    Header: 'Код',
    id: 'itemNo',
    width: 70,
    accessor: 'itemNo',
    sortable: false,
  },
  {
    Header: 'Наименование',
    id: 'itemName',
    accessor: 'itemName',
    minWidth: 500,
    sortable: false,
  },
  {
    Header: 'Кол',
    id: 'itemQty',
    accessor: 'itemQty',
    width: 40,
    sortable: false,
  },
  {
    Header: 'Результат',
    id: 'result',
    accessor: 'result',
    Cell: ResultCell,
    sortable: false,
  },
];

export const orderColumns = [
  {
    Header: 'Дата',
    id: 'date',
    accessor: 'date',
    sortable: false,
  },
  {
    Header: 'Документ',
    id: 'docNo',
    accessor: 'docNo',
    sortable: false,
  },
  {
    Header: 'Инициатор',
    id: 'contact',
    sortable: false,
    Cell: ContactCell,
  },
  {
    Header: 'Комманда',
    id: 'command',
    accessor: 'command',
    sortable: false,
  },
  {
    Header: 'Статус',
    id: 'status',
    accessor: 'status',
    Cell: StatusCell,
    sortable: false,
  },
  {
    Header: 'Коммент.',
    id: 'comment',
    accessor: 'comment',
    sortable: false,
  },
];

export const bindingColumns = [
  {
    Header: 'Дата',
    accessor: 'date',
    sortable: false,
  },
  {
    Header: 'Прайс-лист',
    accessor: 'sourceFile',
    sortable: false,
  },
  {
    Header: 'Состояние',
    id: 'status',
    accessor: 'linkedFileStatus',
    Cell: ResultCell,
    sortable: false,
  },
];

export const integrateColumns = id => [
  {
    Header: 'Дата',
    accessor: 'date',
    sortable: false,
  },
  {
    Header: 'Команда',
    accessor: 'command',
    sortable: false,
  },
  {
    Header: 'Состояние',
    accessor: 'status',
    Cell: StatusCell,
    sortable: false,
  },
  {
    Header: 'Документ',
    id: 'docNo',
    accessor: () => id,
    sortable: false,
  },
  {
    Header: 'Метод отгрузки',
    accessor: 'shipmentMethod',
    sortable: false,
  },
  {
    Header: 'Дата отгрузки',
    accessor: 'shipmentDate',
    sortable: false,
  },
  {
    Header: 'Время отгрузки',
    accessor: 'shipmentTime',
    sortable: false,
  },
  {
    Header: 'Агент по доставке',
    accessor: 'shipmentAgent',
    sortable: false,
  },
  {
    Header: 'Результат',
    accessor: 'result',
    Cell: ResultCell,
    sortable: false,
  },
];
