import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUser } from 'pages/Orders/selectors';
import { compose } from 'redux';
import DataTransition from 'components/data-transition';

const mapState = state => ({
  user: getUser(state),
  isAuth: state.auth,
  confirmed: state.user.confirmed,
  manual: state.api.manual,
  version: state.version.value,
  isCredPresence: state.secure.isCredPresence,
});

const mapDispatch = ({ api: { fetchRoute }, auth: { logout }, personal: { confirmEmail } }) => ({
  fetchRoute,
  logout,
  confirmEmail,
});

export default compose(
  withRouter,
  connect(mapState, mapDispatch)
)(DataTransition);
