import React, { useCallback } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import EmployeesBack from 'pages/Settings/components/employees/components/backref';
import Content from 'components/layout/content';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getUser } from 'pages/Orders/selectors';
import { getSettingsInfo } from 'pages/Settings/selectors';
import withApi from 'hoc/withApi';
import { isCactus } from 'common/config';
import { useRdCode } from 'hooks/new';
import { SwitcherVersion } from './switcher-version';
import { handlePropmt } from '../utils';
import PersonalForm from './PersonalForm';

const Personal = () => {
  const {
    select: {
      user: { getConfirmed, getApproval },
    },
  } = useStore();

  const location = useLocation();
  const history = useHistory();
  const isNew = location.pathname.includes('add');

  const user = useSelector(getUser);
  const info = useSelector(state => getSettingsInfo(state)(isNew));
  const confirmed = useSelector(getConfirmed);
  const approvalReceived = useSelector(getApproval);
  const {
    personal: { update },
  } = useDispatch();

  const hasAccess = useRdCode(['ТДБ']);
  const title = isNew
    ? `Новый сотрудник (${user.client})`
    : location.state?.needConfirm
    ? 'Подтверждение личных данных'
    : `Личные данные ${info.login !== user.login ? info.login : ''}`;

  const handleSubmit = useCallback(
    async data => {
      await update({ data, location, history });
    },
    [location],
  );

  return (
    <Content title={title}>
      {!isCactus && hasAccess && (
        <div className="actions-container actions-container--order align-items-center action-container__personal--min">
          <SwitcherVersion />
        </div>
      )}
      <Prompt
        when={!confirmed || !approvalReceived}
        message={handlePropmt(confirmed, approvalReceived)}
      />
      <PersonalForm user={user} isNew={isNew} initialValues={info} onSubmit={handleSubmit} />
      {user.login !== info.login && <EmployeesBack />}
    </Content>
  );
};

export default withApi(Personal);
