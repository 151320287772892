import Mixpanel from 'services/mixpanel';
import { Cookies } from 'services/cookies';
import alert from 'components/native/alert/function';
import { formValueSelector, SubmissionError, touch, untouch, clearSubmitErrors } from 'redux-form';
import ReactHtmlParser from 'react-html-parser';
import { getUser } from 'pages/Orders/selectors';
import cache from 'services/cache';
import chooseVersionUI from 'helpers/chooseVersionUI';
import { activeControllers } from 'common/collections';
import history from 'common/history';
import {APPROVAL_MESSAGE} from "pages/Settings/utils/handle-prompt"

const FORM_NAME = 'login-form';
const preloadedState = window.__PRELOADED_STATE__;

export default {
  name: 'auth',
  state: preloadedState?.secure?.isCredPresence || false,
  reducers: {
    'user/login': () => true,
    'user/logout': () => false,
  },
  effects: dispatch => ({
    async login(credentials) {
      try {
        const data = await dispatch.api.post({
          url: '/login',
          data: credentials,
        });

        if (!data.auth) {
          throw data.error;
        }

        Mixpanel.identify(data.user.id);
        Mixpanel.track('Авторизация', {
          version: JSON.parse(data.user.pilotUris).length ? 'новая' : 'старая',
        });
        Mixpanel.people.set({
          uid: data.user.id,
          name: data.user.client,
          'RD Code': data.user['RG Code'],
        });

        if (data?.user?.approvalReceived === 'false') {
          history.push('/settings/personal');
          alert(APPROVAL_MESSAGE);
        }

        if (!chooseVersionUI(data.user)) {
          dispatch.user.login(data);
        }

        return data;
      } catch (err) {
        throw new SubmissionError({
          _error: {
            code: err.code || err.status,
            message: ReactHtmlParser(err.message),
          },
        });
      }
    },
    async logout(manual = true, rootState) {
      const user = getUser(rootState);
      const uid = user.id || Cookies.get('uid');

      /**
       * clear sessions, redis and httpOnly cookies on backend
       * + Re-init store from persisted state
       */
      await window.persistor.pause();
      dispatch({ type: 'user/logout' });

      if (!manual) {
        alert('Ваша сессия истекла! Пожалуйста выполните вход.');
      }

      await window.persistor.persist();
      await cache.clear();

      Mixpanel.reset();

      activeControllers.abortAll();
      activeControllers.clearAll();

      if (uid) {
        try {
          return await dispatch.api.get({ url: `/logout/${uid}`, cancellation: false });
        } catch (err) {
          console.log(err);
        }
      }
    },
    async recover(event, rootState) {
      const selector = formValueSelector(FORM_NAME);
      const { clientNo: no, clientLogin: login } = selector(rootState, 'clientNo', 'clientLogin');

      event.preventDefault();

      if (!no || !login) {
        dispatch(touch(FORM_NAME, 'clientNo'));
        dispatch(touch(FORM_NAME, 'clientLogin'));
        dispatch(untouch(FORM_NAME, 'password'));

        alert(
          'Заполните <b>Код клиента</b>, <b>Логин</b> и нажмите <b>Восстановить пароль</b>' +
            '<br />Если вы не помните свой Код клиента или Логин обратитесь к вашему менеджеру',
        );

        return;
      }

      try {
        const { message } = await dispatch.api.post({
          url: 'recover',
          data: { client: no, login },
        });

        alert(message);
      } catch (e) {
        alert(e.message);
      }
    },
    async unlock(data) {
      dispatch(clearSubmitErrors('unlocking-form'));

      const { success, message } = await dispatch.api.post({
        url: 'unlocking',
        data,
      });

      if (!success) {
        throw new SubmissionError({ _error: message });
      }

      return message;
    },
  }),
};
