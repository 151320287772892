import confirm from 'components/native/confirm/function';
import alert from 'components/native/alert/function';
import { dateToRU } from 'common/util';
import { QUEUE_MOVE } from 'common/config';
import history from "common/history"

export default ({ api }) =>
  async ({ set, order }) => {
    const date = set[0]['Дата'];
    const { orderNo } = order;

    try {
      await confirm(
        `Чтобы создать новый заказ с методом отгрузки (${
          order.shipment
        }) и датой отгрузки (${dateToRU(date)}) нажмите Ок`,
      );

      const items = set.map(item => [item['Товар Но'], Number(item['Кол-Во'])]);

      const { success, message } = await api.post({
        url: `orders/move/${orderNo}`,
        query: `?ship_date=${date}`,
        data: { items },
      });

      if (success) {
        history.push({
          pathname: `/queue/${order.orderNo}`,
          state: {
            queueType: QUEUE_MOVE,
          },
        });
      } else {
        alert(message);
      }
    } catch (e) {
      console.error(e);
    }
  };
