/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react';
import Content from 'components/layout/content';
import SubMenu from 'components/sub-menu';
import { useHistory, useLocation } from 'react-router-dom';
import OrdersFilter from 'pages/Orders/components/common/orders-filter';
import useLocalForage from 'hooks/use-local-forage';
import useRouteConfig from 'hooks/use-route-config';
import isEmpty from 'lodash/isEmpty';
import useDeepEffect from 'hooks/use-deep-effect';
import ServiceExportButton from './components/common/exportButton';
import { validationSearchValue } from '../../helpers/validation-search-value';

const Service = () => {
  const { pathname } = useLocation();

  const { routes } = useRouteConfig();
  const [itemNoValue, setItemNoValue] = useState('');
  const [{ type }, setForageState] = useLocalForage('location.state', { type: '' });

  const history = useHistory();

  useDeepEffect(() => {
    if (!isEmpty(routes)) {
      setForageState({ type: '' });
    } else {
      const type = pathname.split('/').pop() || '';

      !pathname.includes('view') && setForageState({ type, hidden: true });
    }
  }, [pathname, routes]);

  const handleFilterChange = useCallback(({ itemNoValue }) => {
    setItemNoValue(itemNoValue);
  }, []);

  const submitSearch = () => {
    const varifiedValue = validationSearchValue(itemNoValue);
    if (varifiedValue) {
      history.push(`/warranty/service/${varifiedValue}`);
    }
  };

  return (
    <Content title="Гарантия" style={{ padding: 0, marginBottom: 10 }}>
      <div className="warranty-actions">
        <div className="row col-sm justify-content-center">
          <SubMenu type={type} />
        </div>
        <div className="col-sm-5">
          <OrdersFilter
            name="itemNo"
            onChange={handleFilterChange}
            className="form-control form-control-sm warranty-filter"
            value={itemNoValue}
            placeholder="Введите код товара или серийный номер"
          />
          <button type="button" className="btn btn-outline-danger btn--rect" onClick={submitSearch}>
            Найти
          </button>
          <ServiceExportButton
            type={(pathname.includes('view') && 'view') || type}
            docType={type}
          />
        </div>
      </div>
    </Content>
  );
};

export default Service;
