// @ts-nocheck
import { useSelector, useStore } from 'react-redux';
import { useDesign } from 'hooks/new';

const useCanEdit = orderNo => {
  const {
    select: { newOrdersNew, newOrder },
  } = useStore();

  const order = useSelector(newOrder.getOrder);

  const status = order?.statusV2;
  const activeOrder = useSelector(newOrdersNew.getOrder(orderNo));
  const { statusV2 = status, status: oldStatus } = activeOrder || {};

  const orderStatus = statusV2 || oldStatus;
  const changeProtect = order?.changeProtect || activeOrder?.changeProtect;

  const [isPilot] = useDesign();

  if (Number(changeProtect) === 1) {
    return false;
  }

  return (
    !isPilot ||
    orderStatus === 'Резерв' ||
    orderStatus === 'ОЗПр' ||
    !orderStatus ||
    Number(changeProtect) !== 0
  );
};

export default useCanEdit;
