import React, { useCallback, useEffect, useState } from 'react';
import { Async } from 'react-select';
import Content from 'components/layout/content';
import Article from 'components/wiki/article';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getEffectLoading } from 'common/selectors';
import withApi from 'hoc/withApi';

const StockInfo = () => {
  const [stock, setStock] = useState();
  const {
    shipments: { chooseStock, getStocks },
  } = useDispatch();

  const article = useSelector(state => state.wiki.article);
  const fetching = useSelector(getEffectLoading('shipments', 'chooseStock'));

  const { pathname } = useLocation();

  const handleStockChange = useCallback(
    async newValue => {
      setStock(newValue);

      await chooseStock({
        stock: (newValue && newValue.value) || 0,
        type: pathname.includes('delivery') ? 'delivery' : 'pickup',
      });
    },
    [pathname],
  );

  useEffect(() => {
    setStock(null);
  }, [pathname]);

  return (
    <Content title={pathname.includes('delivery') ? 'Доставка' : 'Самовывоз'}>
      <div className="wiki-container">
        <div className="stock-actions">
          Укажите город склада:
          <Async
            placeholder="Выберите склад"
            name="stocks"
            value={stock}
            loadOptions={getStocks}
            noResultsText={'Укажите склад'}
            onChange={handleStockChange}
            clearable={false}
            disabled={fetching}
            menuContainerStyle={{ zIndex: 1 }}
          />
          {fetching && <i className="loader loader--small" style={{ marginLeft: 10 }} />}
        </div>
        <Article handleArticle={article} />
      </div>
    </Content>
  );
};

export default withApi(StockInfo);
