import {download, objToQS} from 'common/util'
import alert from 'components/native/alert/function';
import { validationSearchValue } from '../helpers/validation-search-value';


export default {
  name: 'warranty',
  state: {
    header: {},
  },
  reducers: {
    setHeader: (state, { data }) => ({ ...state, header: data.header || {} }),
  },
  effects({ api }) {
    return {
      async exportService(query) {
        const { blob, filename } = await api.get({
          url: 'file/get/service',
          query: `?${objToQS(query)}`,
        });

        download(blob, filename);
      },
      async searchBySerial({ val, mode }) {
        const no = validationSearchValue(val);

        if (!no) {
          return [];
        }

        try {
          const { data } = await api.get({
            url: `/warranty/serial/${no}`,
            query: `?mode=${mode}`,
          });

          return data.data || [];
        } catch (e) {
          console.error(e);
          return [];
        }
      },
    };
  },
};
