// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './card.module.scss';

/**
 * Renders a <Card /> component
 * @component
 * @example
 *
 * return (
 *   <Card />
 * )
 */
export const Card = ({ className = '', style, children, dataTestId }) => {
  const classes = cn(styles['card'], className);
  return (
    <div className={classes} data-testid={dataTestId} style={style}>
      {children}
    </div>
  );
};

Card.defaultProps = {
  className: '',
  style: {},
  dataTestId: 'card',
};

Card.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  /**
   * children
   */
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};
