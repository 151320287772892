import React from 'react';
import { useSelector, useStore } from 'react-redux';
import Loader from 'components/loader';

const UploadedFile = ({ name, deleteDocument, model }) => {
  const { select } = useStore();
  const deleting = useSelector(select[model].getLoading(name));

  if (!name) return null;

  return (
    <div className="form-field-download__file-description-delete">
      <span className="form-field-download__file-description">{name}</span>
      {deleteDocument ? (
        deleting ? (
          <Loader />
        ) : (
          <i
            className="icon icon-file-delete enabled"
            onClick={async () => await deleteDocument(name)}
          />
        )
      ) : null}
    </div>
  );
};

export default UploadedFile;
