import alert from 'components/native/alert/function';
import notifier from 'services/notifier';
import React from 'react';
import formattingSubscribersToArray from 'pages/Logistic/helpers/formatting-subscribers';
import { CACHE_NAME } from 'common/config';

export { default as getOrders } from './getOrders';
export { default as sendSchedule } from './sendShedule';
export { default as getPlanTime } from './getPlanTime';
export { default as onReceive } from './onReceive';

export const deleteSchedule =
  ({ api }) =>
  async no => {
    try {
      notifier.show(no, `Запись ${no} удаляется`);

      const { success, message } = await api.get({
        url: `shipment-schedule/delete/${no}`,
      });

      if (!success) {
        alert(message);
        notifier.dismiss();
      }
    } catch (e) {
      console.error(e.message);
    }
  };

export const getSubscriptions =
  ({ api }) =>
  async () => {
    try {
      const {
        success,
        data = {},
        message,
      } = await api.get({
        url: '/shipments-subscription',
        cache: CACHE_NAME,
      });

      if (success) {
        const { subscriptions = [] } = data;

        return { success, subscriptions };
      }

      return { success, message };
    } catch ({ message }) {
      return { success: false, message };
    }
  };

export const subscribe =
  ({ api }) =>
  async data => {
    try {
      const { success, message } = await api.post({
        url: '/subscription/update',
        data: {
          agents: formattingSubscribersToArray(data),
        },
      });

      alert(message);

      return success;
    } catch (e) {
      console.error(e.message);
      alert('Ошибка! Попробуйте снова');

      return false;
    }
  };

export const onExpire = () => () => {
  alert('Ваш запрос обрабатывается. Вернитесь позже!');
  notifier.dismiss();
};

export const getStocks =
  ({ api }) =>
  async () => {
    try {
      const stocks = await api.get({
        url: '/logistic/pickup/stocks',
        cache: CACHE_NAME,
      });

      return { options: Object.values(stocks) };
    } catch (e) {
      console.error(e.message);
      return { options: [] };
    }
  };

export const chooseStock =
  ({ api, wiki }) =>
  async ({ stock, type }) => {
    const payload = await api.get({
      url: `/logistic/${type}/article`,
      query: `?stock=${stock}`,
      cache: CACHE_NAME,
    });

    wiki.load(payload);
  };
