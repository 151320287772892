// @ts-nocheck
const requiredField = 'Заполните поле';

export default values => {
  const errors = {};

  if (!values.customerName) {
    errors.customerName = requiredField;
  }

  if (!values.inn) {
    errors.inn = requiredField;
  }

  if (!values.contact) {
    errors.contact = requiredField;
  }

  if (!values.phone) {
    errors.phone = requiredField;
  }

  if (!values.email) {
    errors.email = requiredField;
  }

  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
    errors.email = 'Неверно введен e-mail';
  }

  return errors;
};
