import { COMMON_GUEST_THEME } from './common-guest';

export const SIGN_UP_THEME = {
  ...COMMON_GUEST_THEME,
  'color-black': '#000',
  'color-stroke-gray': '#d4d4d4',
  'color-gray': '#808080',
  'color-dark-gray': '#333',
  'color-light-blue': '#edf1fa',
  'color-brand-blue': '#236192',
  'color-brand-blue-hover': '#0d4572',
  'font-size-small': '12px',
  'font-size-huge': '32px',
  'line-height-huge': '42px',
  'z-index-base': 100,
  'page-footer-height': 'auto',
  'space-xl': '36px',
  'page-container-width': '1348px',
  'min-page-container-width': '1318px',
  'nav-footer-display': 'none',
};
