import { useContextSelector } from 'use-context-selector';
import { empty } from 'components/empty';
import { useRef } from 'react';

/**
 * Hook to get ContextReducer dispatcher by useContextSelector
 *
 * @param {React.Context<ContextReducerProps>} context
 * @returns {React.Dispatch<object|function>|function}
 */
const useContextDispatch = context => {
  const isWarned = useRef(false);

  return useContextSelector(context, ({ dispatch }) => {
    if (!dispatch) {
      /*if (!isWarned.current) {
        console.warn('No dispatch function provided in context! Using default instead');
        isWarned.current = true;
      }*/

      return empty;
    }

    return dispatch;
  });
};

export default useContextDispatch;
