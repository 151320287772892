import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { useParams } from 'react-router-dom';
import { textareaField } from '../fields';
import validate from 'common/validates/validateComment';
import useToggleHandler from 'hooks/use-toggle-handler';
import { useDispatch } from 'react-redux';
import { LoadingSubmitButton } from 'components/loading-sumbit-button';

const AddCommentForm = ({ handleSubmit, reset, submitting, type }) => {
  const { id } = useParams();
  const [isOpenFormComment, , handleFormClick] = useToggleHandler(false);

  const dispatch = useDispatch();

  const onSubmit = async ({ comment }) => {
    const success = await dispatch[type]?.createComment?.({ id, comment });

    if (success) {
      reset();
    }
  };

  return (
    <div className="filter">
      <a href="#" className="add_comment" onClick={handleFormClick} title="Добавить комментарий">
        <i className={'icon'} />
        <span className="addcomment_link">Добавить комментарий</span>
      </a>
      <div className="add_comment_form" style={{ display: isOpenFormComment ? 'block' : 'none' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="label">Комментарий</div>
          <div className="filed-container">
            <Field name="comment" component={textareaField} />
            <br />
            <LoadingSubmitButton label="Отправить" btnClass="red_button" fetching={submitting} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({
  form: 'ADD_COMMENT_FROM',
  validate,
})(AddCommentForm);
