import notifierDefault from 'services/notifier';
import getIntegrateData from 'pages/Orders/helpers/getIntegrateData';
import { changeIntegrate } from 'pages/Orders/models/order/effects/join';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import history from "common/history"

export default ({ order, list }) =>
  ({ data, isList = false, notifier = notifierDefault, isPilot }) => {
    const message = data?.data?.data?.[0];

    if (!message) {
      const { success, message, id, integrate } = data;

      notifier.resolve(
        {
          success,
          message,
        },
        id,
      );

      if (integrate) {
        list.change({ handler: changeIntegrate(false) });
      }

      return;
    }

    const { status, command: messageCommand, error, docNo, comment } = message;
    const command = messageCommand || data.command;

    const isSign = command?.toLowerCase() === 'sign';
    const isUnSign = command?.toLowerCase() === 'unsign';
    const isDelete = command?.toLowerCase() === 'delete';
    const isIntegrate = command === 'Integr';
    const isCreatedRemains = command?.toLowerCase() === 'remains';

    if (status !== 'Active' && status !== 'В очереди') {
      const hasError = status === 'Error' || status === 'Ошибка';

      if (hasError) {
        if (isIntegrate) {
          const { id, ...rest } = getIntegrateData(message);

          notifier.resolve(rest, id);
          list.change({ handler: changeIntegrate(false) });

          return;
        }

        if (isCreatedRemains) {
          notifier.resolve(
            {
              success: false,
              message: 'Заказ на остатки не создан!',
            },
            docNo,
          );

          return;
        }

        isList &&
          list.changeRow({
            searchKey: 'orderNo',
            needle: docNo,
            disabled: false,
            statusText: (isDelete && 'ошибка при удалении') || '-',
          });

        notifier.resolve(
          {
            success: false,
            message: error || 'Ошибка!',
          },
          docNo,
        );

        return;
      }

      if (isSign || isUnSign) {
        (isList &&
          list.changeRow({
            searchKey: 'orderNo',
            needle: docNo,
            disabled: !isUnSign,
            statusText: (isSign && 'подписан клиентом') || '-',
            status: (isSign && 'подписан клиентом') || '-',
          })) ||
          order.sign({ command, status });
      }

      if (isDelete) {
        if (isList) {
          list.deleteRow({ searchKey: 'orderNo', needle: docNo });
        }
        if (!isPilot) {
          history.push('/orders');
        }
      }

      if (isCreatedRemains) {
        const isError = !comment;

        const link = <strong>{comment}</strong>;
        const onClick = () => history.push(`/orders/edit/${comment}`);

        notifier.resolve(
          {
            success: !isError,
            onClick,
            message: isError
              ? 'Заказ на остатки не создан!'
              : `Создан заказ на остатки ${ReactDOMServer.renderToStaticMarkup(link)}`,
          },
          docNo,
        );

        return;
      }

      notifier.resolve(
        {
          success: true,
          message:
            command === 'Delete'
              ? `Заказ ${docNo} удален`
              : command?.toLowerCase() === 'sign'
              ? `Заказ ${docNo} подписан`
              : `Заказ ${docNo} снят с подписи`,
        },
        docNo,
      );
    }
  };
