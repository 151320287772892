import React from 'react';
import { range, map } from 'lodash';

const elementClassName = 'steps-visual__element';

function getStepsView(stepsNumber, currentStep, lendingFormIsInvalid) {
  const callback = step => {
    if (currentStep > step || (currentStep === stepsNumber && !lendingFormIsInvalid)) {
      return <div key={step} className={`${elementClassName} active`} />;
    }
    return <div key={step} className={elementClassName} />;
  };

  return map(range(1, stepsNumber + 1), callback);
}

function StepsVisual({ stepsNumber, currentStep, lendingFormIsInvalid }) {
  return (
    <div className="steps-visual">
      {getStepsView(stepsNumber, currentStep, lendingFormIsInvalid)}
    </div>
  );
}

export default StepsVisual;
