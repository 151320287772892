import React from 'react';
import { useDispatch } from 'react-redux';

export const AttachmentsCell = row => {
  const {
    certificates: { download },
  } = useDispatch();

  if (!row) return null;

  const {
    index,
    original: { expired },
    value = [],
  } = row;

  if (expired) {
    return `Дата окончания документа просрочена\n (скачивание недоступно)`;
  }

  if(!Array.isArray(value)){
    return null;
  }

  return value.map((url, i) => (
    <span key={`${index}-${i}`} className="num-icon" onClick={async () => await download(url)}>
      {++i}
    </span>
  ));
};
