import { range } from 'lodash';

const createCategoryInfo = (level, category) => ({
  [`code${level}`]: category.id,
  [`group${level}`]: category.name,
});

const createCategoriesInfo = (categories, categoryLvl) => {
  const categoryRangeLvls = range(1, categoryLvl + 1);

  return categoryRangeLvls.reduce((categoriesInfo, categoryRangeLvl) => {
    const categoryIndex = categoryRangeLvl - 1;
    return {
      ...categoriesInfo,
      ...createCategoryInfo(categoryRangeLvl, categories[categoryIndex]),
    };
  }, {});
};

const createRowForSelectGroupAction = params => {
  const { category1, category2, category3, level } = params;
  const categories = [category1, category2, category3];
  return { original: createCategoriesInfo(categories, level) };
};

export default createRowForSelectGroupAction;
