import { useEffect } from 'react';

const isClient = true;

const defaultTarget = isClient ? window : null;

const isListenerType1 = target => {
  return !!target.addEventListener;
};
const isListenerType2 = target => {
  return !!target.on;
};

export const useEvent = (name, handler = null, target = defaultTarget, options = {}) => {
  useEffect(() => {
    if (!handler) {
      return;
    }
    if (!target) {
      return;
    }
    if (isListenerType1(target)) {
      target.addEventListener(name, handler, options);
    } else if (isListenerType2(target)) {
      target.on(name, handler, options);
    }
    // eslint-disable-next-line consistent-return
    return () => {
      if (isListenerType1(target)) {
        target.removeEventListener(name, handler, options);
      } else if (isListenerType2(target)) {
        target.off(name, handler, options);
      }
    };
  }, [name, handler, target, options]);
};
