import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const CleanFilter = ({
  className,
  color = '#236192',
  name,
  width = '17',
  height = '16',
  viewBox = '0 0 17 16',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M0.5 0.5L5.5 8.5V14.5L8.5 12.5V8.5L13.5 0.5H0.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path d="M12 10H11V11H12V10Z" fill={color} />
      <path d="M13 11H12V12H13V11Z" fill={color} />
      <path d="M14 12H13V13H14V12Z" fill={color} />
      <path d="M15 13H14V14H15V13Z" fill={color} />
      <path d="M16 14H15V15H16V14Z" fill={color} />
      <path d="M16 11V10H15V11H16Z" fill={color} />
      <path d="M15 12V11H14V12H15Z" fill={color} />
      <path d="M14 13V12H13V13H14Z" fill={color} />
      <path d="M13 14V13H12V14H13Z" fill={color} />
      <path d="M12 15V14H11V15H12Z" fill={color} />
      <path d="M11 16V15H10V16H11Z" fill={color} />
      <path d="M17 16V15H16V16H17Z" fill={color} />
      <path d="M17 10V9H16V10H17Z" fill={color} />
      <path d="M11 10V9H10V10H11Z" fill={color} />
    </svg>
  );
};

CleanFilter.defaultProps = {
  className: '',
  color: '#236192',
  width: '17',
  height: '16',
  viewBox: '0 0 17 16',
};

CleanFilter.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
