import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { buildGroupsObjFromSuggest } from '../../../../helpers';
import { SOFTWARE_ID } from '../../../../constances/groups';
import useSelectGroup from 'pages/Orders/hooks/useSelectGroup';
import { escapeRegExp } from 'lodash';

const GroupsTree = props => {
  const selectGroup = useSelectGroup();

  const { groups, text, isDemofond } = props;
  const pattern = new RegExp('(' + escapeRegExp(text) + ')', 'gi');

  const handleClick = (code, groupLevel, arr) => async event => {
    if (isDemofond) {
      return selectGroup(code)(event);
    }

    if (SOFTWARE_ID === code) {
      window.location.href = '/?action=Y16E945A&action1=Y7D4B6FD';
    } else {
      await selectGroup(code, { original: buildGroupsObjFromSuggest(arr) }, groupLevel)(event);
    }
  };

  return groups.map((group, index) => (
    <div key={index} className="suggest-groups">
      {group.chain.map(({ code, name }, level, arr) => {
        const groupLevel = arr.length > 2 ? 0 : 1;
        const catName = name || '';

        return (
          (level === 0 && (
            <span key={code} className="active" onClick={handleClick(code, groupLevel, arr)}>
              {ReactHtmlParser(catName.toLowerCase().replace(pattern, `<b>${text}</b>`))}
            </span>
          )) || (
            <span key={code} className="active active__parent">
              {catName.charAt(0).toUpperCase() + catName.toLowerCase().slice(1)}
            </span>
          )
        );
      })}
    </div>
  ));
};

export default GroupsTree;
