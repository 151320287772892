import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Delete = ({
  className,
  color = '#236192',
  name,
  width = '12',
  height = '12',
  viewBox = '0 0 12 12',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M4 3V2C4 1.44772 4.44772 1 5 1H7C7.55228 1 8 1.44772 8 2V3" stroke={color} />
    <path
      d="M9.5 4L9.5 11C9.5 11.2761 9.27614 11.5 9 11.5L3 11.5C2.72386 11.5 2.5 11.2761 2.5 11L2.5 4C2.5 3.72386 2.72386 3.5 3 3.5L9 3.5C9.27614 3.5 9.5 3.72386 9.5 4Z"
      stroke={color}
    />
    <line x1="0.5" y1="3.5" x2="11.5" y2="3.5" stroke={color} strokeLinecap="round" />
  </svg>
);

Delete.defaultProps = {
  className: '',
  color: '#236192',
  width: '12',
  height: '12',
  viewBox: '0 0 12 12',
};

Delete.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
