import mixpanel from 'mixpanel-browser';
import { getCurrentRoute } from 'common/util';
import { isPreProdEnv, isProdEnv, isTestEnv } from 'common/config';

/**
 * for debug
 * @type {boolean}
 */

const token =
  (isTestEnv && 'f61fd6bf465a981ff6d5b15d665bd4de') ||
  ((isProdEnv || isPreProdEnv) && '89c6461badf5486a1e16e0b483a60f45');

mixpanel.init(token, {
  ignore_dnt: true,
});

const hasAccess = isTestEnv || isProdEnv || isPreProdEnv;

const changeLocation = (prevPath, path, routes) => {
  if (!hasAccess) {
    return;
  }

  if (prevPath === path) {
    return;
  }

  const to = getCurrentRoute(routes, path).route.name;
  const from = getCurrentRoute(routes, prevPath).route.name;

  mixpanel.track(`Переход на страницу ${to}`, {
    from: (from === 'Маркетинг' && 'ГлавнаяСтраница') || from,
    to: (to === 'Маркетинг' && 'ГлавнаяСтраница') || to,
  });
};

const trackCatalog = (data, track = 'ВыборИзКаталога') => {
  if (!hasAccess) {
    return;
  }

  const { group1, group2, group3, code1, code2, code3, level } = data;
  let props = {
    name_root: group1,
    id_root: code1,
    name_group: group2,
    id_group: code2,
  };

  if (level === 0) {
    props = {
      ...props,
      name_subgroup: group3,
      id_subgroup: code3,
    };
  }

  mixpanel.track(track, props);
};

export default {
  identify: id => {
    hasAccess && mixpanel.identify(id);
  },
  track: (name, props) => {
    hasAccess && mixpanel.track(name, props);
  },
  reset: () => {
    hasAccess && mixpanel.reset();
  },
  people: {
    set: props => {
      hasAccess && mixpanel.people.set(props);
    },
  },
  changePage: changeLocation,
  trackCatalog,
};
