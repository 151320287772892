// @ts-nocheck
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { THEME_COLORS } from 'common/constants/styles';
import { Icon } from 'common/ui/icon';

import styles from './chip.module.scss';

/**
 * Renders a <Chip /> component
 * @component
 * @example
 *
 * return (
 *   <Chip />
 * )
 */
export const Chip = ({
  className = '',
  iconColor,
  icon,
  viewBox,
  text,
  textColor,
  style,
  ...rest
}) => {
  const classes = cn(styles['chip'], className);

  return (
    <div className={classes} data-testid="chip" {...rest}>
      <Icon name={icon} color={iconColor} />
      <span style={{ color: THEME_COLORS[textColor], ...style }}>{text}</span>
    </div>
  );
};

Chip.defaultProps = {
  className: '',
  iconColor: '',
  style: {},
  text: '',
  textColor: 'black',
  viewBox: '',
};

Chip.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * icon color
   */
  iconColor: PropTypes.string,
  /**
   * icon name
   */
  icon: PropTypes.string.isRequired,
  /**
   * inner styles
   */
  style: PropTypes.shape({}),
  /**
   * text
   */
  text: PropTypes.string,
  /**
   * text color
   */
  textColor: PropTypes.string,
  /**
   * viewbox
   */
  viewBox: PropTypes.string,
};
