import { proxyArgsToMethods } from 'common/util';
import * as effects from './effects';
import * as selectors from './selectors';

export default {
  name: 'product',
  state: {
    head: {},
    availability: {},
    related: [],
  },
  reducers: {
    load: (state, { data }) => ({ ...state, head: data }),
    setAvailability: (state, availability) => ({ ...state, availability }),
    setRelated: (state, related) => ({ ...state, related }),
  },
  effects(dispatch) {
    return proxyArgsToMethods(effects, dispatch);
  },
  selectors(...args) {
    return proxyArgsToMethods(selectors, args);
  },
};
