import React, { useCallback } from 'react';
import Substitute from 'pages/Marketing/components/events/components/Substitute';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getEventsColleagues } from 'pages/Marketing/selectors';
import { getEffectLoading } from 'common/selectors';

const InviteActions = props => {
  const { event } = props;
  const registered = parseInt(event?.original?.registered);

  if (registered === 2) {
    return null;
  }

  const colleagues = useSelector(getEventsColleagues);
  const fetching = useSelector(getEffectLoading('events', 'changeInviteStatus'));

  const {
    events: { changeInviteStatus },
  } = useDispatch();

  const waitQueue = false;

  const handleClick = useCallback(type => () => changeInviteStatus(event, type), [event]);

  const handleSubstitute = useCallback(
    type => user => changeInviteStatus(event, type, user),
    [event],
  );

  return (
    <div className="invite-actions text-right">
      <div>
        <Substitute
          substitutions={colleagues}
          event={event}
          disabled={fetching || waitQueue}
          onSubstitute={handleSubstitute('refuse')}
        />
      </div>
      <button
        className="btn btn-outline-success btn--rect"
        disabled={fetching || waitQueue}
        onClick={handleClick('apply')}
      >
        Принять приглашение
      </button>
      <button
        className="btn btn-outline-danger btn--rect"
        disabled={fetching || waitQueue}
        onClick={handleClick('refuse')}
      >
        Отказаться
      </button>
    </div>
  );
};

InviteActions.propTypes = {
  event: PropTypes.object.isRequired,
};

export default InviteActions;
