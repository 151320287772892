/* eslint-disable react/forbid-prop-types */
import React, {
  cloneElement,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useOnClickOutside, usePrevious } from 'hooks/new';

import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';

import styles from './popover.module.scss';

/**
 * Renders a <Popover /> component
 * @component
 * @example
 *
 * return (
 *   <Popover />
 * )
 */
export const Popover = memo(
  forwardRef(
    (
      {
        className,
        contentClassName,
        wrapperClassName,
        children,
        closeOnOutsideClick,
        backIconColor,
        closeIconColor,
        hasArrow,
        hasBackButton,
        hasBorder = false,
        hasBorderRadius,
        hasCloser,
        hasShadow,
        hasToggle,
        isOpenOutside,
        showChildren = false,
        position = 'relative',
        style = {},
        wrapperStyle = {},
        trigger = 'click',
        triggerNode,
        onBackButtonClick,
        onClose,
        onOpen,
        modalRef,
      },
      callbackRef,
    ) => {
      const [isOpen, setIsOpen] = useState(false);
      const [openOutside, setOpenOutside] = useState(false);

      const location = useLocation();
      const prevLocation = usePrevious(location.pathname);

      const handleOpen = () => {
        setIsOpen(true);
        onOpen();
      };

      const ref = useRef();
      const componentRef = callbackRef || ref;

      const handleClose = useCallback(() => {
        if (isOpen) {
          setIsOpen(false);
          onClose();
        }
        if (openOutside) {
          setOpenOutside(false);
          onClose();
        }
      }, [onClose, isOpen, openOutside]);

      useEffect(() => {
        if (prevLocation && prevLocation !== location.pathname) {
          handleClose();
        }
      }, [handleClose, location.pathname, prevLocation]);

      useEffect(() => {
        if (isOpenOutside) {
          setOpenOutside(true);
        } else {
          setOpenOutside(false);
          setIsOpen(false);
        }
      }, [isOpenOutside]);

      useOnClickOutside(componentRef, handleClose, closeOnOutsideClick);

      const togglePopover = () => {
        if (isOpen) {
          handleClose();
        } else {
          handleOpen();
        }
      };

      const handlePopoverState = useCallback(() => {
        if (hasToggle) {
          togglePopover();
        } else {
          handleOpen();
        }
      }, [hasToggle, handleOpen]);

      const handleBackButtonClick = () => (onBackButtonClick ? onBackButtonClick() : handleClose());

      const popoverInnerClasses = cn(styles['popover__inner'], className, {
        [styles['popover__inner_open']]: isOpen,
        [styles['popover__inner_open-outside']]: openOutside,
        [styles['popover__inner_with-arrow']]: hasArrow,
        [styles['popover__inner_with-border']]: hasBorder,
        [styles['popover__inner_with-border-radius']]: hasBorderRadius,
        [styles['popover__inner_with-shadow']]: hasShadow,
      });

      return (
        <div
          className={cn(styles['popover'], wrapperClassName)}
          data-testid="popover"
          ref={componentRef}
          style={{ position, ...wrapperStyle }}
        >
          {triggerNode &&
            cloneElement(triggerNode, {
              role: 'presentation',
              onClick: trigger === 'click' || trigger === 'hover' ? handlePopoverState : null,
              onMouseOver: trigger === 'hover' ? handleOpen : null,
              onMouseOut: trigger === 'hover' ? handleClose : null,
            })}
          <div className={popoverInnerClasses} role="dialog" style={style} ref={modalRef}>
            <div className={styles['popover__header']}>
              {hasBackButton && (
                <IconButton
                  className={styles['popover__icon-back']}
                  onClick={handleBackButtonClick}
                >
                  <Icon name="back" color={backIconColor} />
                  Назад
                </IconButton>
              )}
              {hasCloser && (
                <IconButton className={styles['popover__icon-close']} onClick={handleClose}>
                  <Icon name="clear" color={closeIconColor} />
                </IconButton>
              )}
            </div>
            <div
              className={cn(styles['popover__content'], contentClassName, {
                [styles['popover__content--without-back-button']]: !hasBackButton,
              })}
            >
              {(showChildren || isOpen || openOutside) && children}
            </div>
          </div>
        </div>
      );
    },
  ),
);

Popover.defaultProps = {
  className: '',
  contentClassName: '',
  wrapperClassName: '',
  children: null,
  closeOnOutsideClick: true,
  backIconColor: 'brand-blue',
  closeIconColor: 'brand-red',
  hasArrow: false,
  hasBackButton: false,
  hasBorder: false,
  hasBorderRadius: false,
  hasCloser: false,
  hasShadow: false,
  hasToggle: true,
  showChildren: false,
  position: 'relative',
  style: {},
  wrapperStyle: {},
  trigger: 'click',
  onBackButtonClick: null,
  onClose: () => {},
  onOpen: () => {},
  isOpenOutside: false,
};

Popover.displayName = 'Popover';
Popover.whyDidYouRender = false;

Popover.propTypes = {
  /**
   * Back icon color
   */
  backIconColor: PropTypes.string,
  /**
   * Popover inner className
   */
  className: PropTypes.string,
  /**
   * Popover inner content className
   */
  contentClassName: PropTypes.string,
  /**
   * Popover wrapper className
   */
  wrapperClassName: PropTypes.string,
  /**
   * Children
   */
  children: PropTypes.node,
  /**
   * Close popover when outside click is detected or not
   */
  closeOnOutsideClick: PropTypes.bool,
  /**
   * Close icon color
   */
  closeIconColor: PropTypes.string,
  /**
   * Arrow on top of popover
   */
  hasArrow: PropTypes.bool,
  /**
   * back button to close popover
   */
  hasBackButton: PropTypes.bool,
  /**
   * Popover with border
   */
  hasBorder: PropTypes.bool,
  /**
   * Popover with border-radius
   */
  hasBorderRadius: PropTypes.bool,
  /**
   * Able to close popover
   */
  hasCloser: PropTypes.bool,
  /**
   * Popover with box-shadow
   */
  hasShadow: PropTypes.bool,
  /**
   * popover opens and closes when is clicked
   */
  hasToggle: PropTypes.bool,
  /**
   * popover renders or not renders its children
   */
  showChildren: PropTypes.bool,
  /**
   * Popover opens and closes outside
   */
  isOpenOutside: PropTypes.bool,
  /**
   * 'static' | 'relative'
   */
  position: PropTypes.string,
  /**
   * Popover inner inline styles
   */
  style: PropTypes.object,
  /**
   * Popover inline styles
   */
  wrapperStyle: PropTypes.object,
  /**
   * Popover trigger
   */
  trigger: PropTypes.node,
  /**
   * Popover root element
   */
  triggerNode: PropTypes.node.isRequired,
  /**
   * Fires when back button is clicked
   */
  onBackButtonClick: PropTypes.func,
  /**
   * Fires when popover closes
   */
  onClose: PropTypes.func,
  /**
   * Fires when popover opens
   */
  onOpen: PropTypes.func,
};
