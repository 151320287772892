import { undef } from 'common/util';

const fileValidators = (values, options) => {
  const { maxFileSize, fileTypeForbidden, feedbackFileTypes } = options;
  const file = values && values.length ? values[0] : undefined;

  if (undef(file)) {
    return;
  }

  const regexpTypeFile = /\.\w+$/;
  const fileType = file && file.name.match(regexpTypeFile)[0].toLowerCase();

  if (file.size > maxFileSize) {
    return 'Размер принятого файла превысил максимально допустимый размер';
  }
  if (file.name.indexOf('.') === -1) {
    return 'Неизвестный тип файла';
  }

  if (!feedbackFileTypes.includes(file.type)) {
    return 'Данный тип файла не поддерживается';
  }

  if (fileTypeForbidden.includes(fileType)) {
    return 'Недопустимый формат файла';
  }
};

export default fileValidators;
