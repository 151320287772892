/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import cn from 'classnames';
import {
  DEFAULT_LOCALE,
  FIRST_DAY_OF_WEEK,
  LABELS,
  MONTHS,
  WEEKDAYS_SHORT,
  WEEKDAYS_LONG,
} from 'common/constants/date';
import { validateDate, validateDayPickerInput } from 'utils/new/date';
import { useHover } from 'hooks/new';
import { formatStringByMask } from 'utils/new/strings';
import alert from 'components/native/alert/function';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';

import styles from './calendar-input.module.scss';

const MASK = '##.##.####';
const formatDateValue = formatStringByMask(MASK);
const validateDateValue = validateDayPickerInput();

/**
 * Renders a <CalendarInput /> component
 * @component
 * @example
 *
 * return (
 *   <CalendarInput />
 * )
 */
export const CalendarInput = ({
  className,
  dayPickerProps,
  inputProps,
  id,
  autoComplete,
  hasError,
  name,
  placeholder,
  value,
  onBlur,
  onChange,
}) => {
  const dayPickerRef = useRef();
  const [date, setDate] = useState('');
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(
    (dayPickerRef && dayPickerRef.current && dayPickerRef.current.state.showOverlay) === true,
  );
  const [hoverRef, isHovered, setIsHovered] = useHover();

  let calendarIconColor = 'gray';

  if (isHovered) {
    calendarIconColor = 'brand-blue';
  }

  if (!isHovered && !isDatePickerOpened) {
    calendarIconColor = 'gray';
  }
  if (!isHovered && isDatePickerOpened) {
    calendarIconColor = 'brand-blue';
  }

  if (inputProps.disabled) {
    calendarIconColor = 'gray';
  }

  const hideCalendar = () => {
    dayPickerRef.current.getInput().blur();
  };

  const showCalendar = () => {
    dayPickerRef.current.getInput().focus();
  };

  useEffect(() => {
    if (dayPickerRef.current) {
      setIsDatePickerOpened(dayPickerRef.current.state.showOverlay);
    }
  }, [isHovered]);

  useEffect(() => {
    if (date && !validateDate(date)) {
      alert('Введен неправильный формат даты');
    }
    setDate(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, value]);

  const handleChange = e => {
    e.persist();

    const { value } = e.target;

    e.target.value = validateDateValue(value);
  };

  const handleBlur = e => {
    const { value } = e.target;
    const formattedValue = formatDateValue(value);

    setDate(formattedValue);
    onBlur();
  };

  const handleDayPickerHide = () => {
    setIsDatePickerOpened(dayPickerRef.current.state.showOverlay);
    hideCalendar();
    setIsHovered(false);
  };

  return (
    <div
      className={cn(styles['calendar-input'], className, {
        [styles['calendar-input_disabled']]: inputProps.disabled,
        [styles['calendar-input_error']]: hasError,
        [styles['calendar-input_inside']]:
          !inputProps.disabled && (isHovered || isDatePickerOpened),
        [styles['calendar-input_outside']]:
          !inputProps.disabled && !isHovered && !isDatePickerOpened,
      })}
      data-testid="calendar-input"
      ref={hoverRef}
    >
      <DayPickerInput
        ref={dayPickerRef}
        dayPickerProps={{
          ...dayPickerProps,
          disabledDays: { before: new Date() },
          firstDayOfWeek: FIRST_DAY_OF_WEEK[DEFAULT_LOCALE],
          labels: LABELS[DEFAULT_LOCALE],
          locale: DEFAULT_LOCALE,
          localeUtils: MomentLocaleUtils,
          months: MONTHS[DEFAULT_LOCALE],
          showOutsideDays: true,
          weekdaysLong: WEEKDAYS_LONG[DEFAULT_LOCALE],
          weekdaysShort: WEEKDAYS_SHORT[DEFAULT_LOCALE],
        }}
        formatDate={formatDate}
        // format={FORMAT}
        format="DD.MM.YYYY"
        inputProps={{
          ...inputProps,
          autoComplete,
          id,
          name,
          onChange: handleChange,
          onBlur: handleBlur,
        }}
        parseDate={parseDate}
        placeholder={placeholder}
        value={date}
        onDayChange={onChange}
        onDayPickerHide={handleDayPickerHide}
      />
      <IconButton
        disabled={inputProps.disabled}
        onClick={showCalendar}
        className={cn(styles['calendar-input__icon-close'], {
          [styles['calendar-input__icon-close_disabled']]: inputProps.disabled,
        })}
      >
        <Icon
          className={styles['calendar-input__icon']}
          name="calendar"
          color={calendarIconColor}
        />
      </IconButton>
    </div>
  );
};

CalendarInput.defaultProps = {
  className: '',
  autoComplete: 'off',
  dayPickerProps: {},
  inputProps: {},
  id: null,
  hasError: false,
  name: '',
  placeholder: 'ДД.ММ.ГГГГ',
  value: '',
  onBlur: () => {},
  onChange: () => {},
};

CalendarInput.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * 'on' | 'off'
   */
  autoComplete: PropTypes.string,
  /**
   * Adds a HTML `id` attribute to the input. This is used for linking the HTML with a label
   */
  id: PropTypes.string,
  /**
   * DayPicker props
   */
  dayPickerProps: PropTypes.object,
  /**
   * DayPickerInput props
   */
  inputProps: PropTypes.object,
  /**
   * Makes the border color red.
   */
  hasError: PropTypes.bool,
  /**
   * Adds `name` HTML attribute to element, indicating the property name associated with the calendar input.
   */
  name: PropTypes.string,
  /**
   * Text that appears within the calendar input when there is no `value`.
   */
  placeholder: PropTypes.string,
  /**
   * Input value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /**
   * The function that is called when the input value looses focus.
   *
   * It receives two arguments: `onBlur(date)`.
   *
   * The consumer of this component should use that data to update the `value` prop passed in to
   * this component.
   */
  onBlur: PropTypes.func,
  /**
   * The function that is called when the input value changes.
   *
   * It receives two arguments: `onChange(date)`.
   *
   * The consumer of this component should use that data to update the `value` prop passed in to
   * this component.
   */
  onChange: PropTypes.func,
};
