import React from 'react';
import CheckboxField from './components/checkbox-field';
import alert from 'components/native/alert/function';
import QtyFieldTransit from './components/qty-field-transit';

const createColumns = props => {
  const { refsInput, refsCheckbox, buttonRef, cnt } = props;

  const handleSelectAll = e => {
    const { checked } = e.target;

    const hasAvailableLimit = refsInput.filter(
      input => input.current.value > input.current.available,
    );

    if (checked) {
      if (hasAvailableLimit.length > 0) {
        const items = hasAvailableLimit.map(el => el.current.id).join(',');

        alert(`Превышение доступного количества товара на складе МСК по товарам ${items}`);
      }
    }

    buttonRef.current.disabled = !checked;

    refsCheckbox.forEach(checkbox => {
      checkbox.current.change(checked, hasAvailableLimit.length > 0);
    });
  };

  return [
    {
      Header: () => (
        <input
          type="checkbox"
          defaultChecked={false}
          onChange={handleSelectAll}
          id="warehouse-checkbox-all"
        />
      ),
      headerStyle: {
        textAlign: 'center',
      },
      sortable: false,
      resizable: false,
      accessor: 'checkbox',
      width: 40,
      Cell: row => {
        if (!row) return null;
        const { index } = row;

        return (
          row && (
            <CheckboxField
              inputRef={refsInput[index]}
              buttonRef={buttonRef}
              ref={refsCheckbox[index]}
              cnt={cnt}
            />
          )
        );
      },
    },
    {
      Header: 'Код товара',
      accessor: 'id',
      width: 80,
    },
    {
      Header: 'Наименование',
      accessor: 'name',
      width: 350,
    },
    {
      Header: 'Кол-во упак.',
      accessor: 'packaged',
      width: 90,
    },
    {
      Header: 'Мин. кол-во',
      accessor: 'minPackaged',
      width: 90,
    },
    {
      Header: 'ЖКол',
      accessor: 'desiredQty',
      width: 70,
      Cell: row => {
        if (!row) return null;

        return <QtyFieldTransit ref={refsInput[row.index]} row={row} fromMsk />;
      },
    },
    {
      Header: 'Дост. Мск',
      accessor: 'availableMSK1',
      headerStyle: { textAlign: 'center' },
      width: 130,
    },
  ];
};

export default createColumns;
