const os = require('os');

export function getEnvParams() {
  const isDevEnv = /sandbox|localhost|webdevsb/.test(os.hostname());
  const isTestEnv = /test|preprod/.test(os.hostname());
  const isProdEnv =
    os.hostname() === 'b2b.merlion.com' || os.hostname() === 'trade.officeassistant.ru';
  const isPreProdEnv = /webdevprod/.test(os.hostname());
  const isCactus = os.hostname().includes('officeassistant');

  return {
    isCactus,
    isDevEnv,
    isProdEnv,
    isPreProdEnv,
    isTestEnv,
  };
}
