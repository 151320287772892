import React from 'react';
import colorsStatusSpecialPrices from '../../constants/colors-status-special-prices';

const StatusCell = row => {
  if (!row) return null;

  return <span className={colorsStatusSpecialPrices[row.value]}>{row.value}</span>;
};

export default StatusCell;
