/* eslint-disable import/no-cycle */
import alert from 'components/native/alert/function';
import { compose } from 'redux';
import { getUser } from 'pages/Orders/selectors';
import { FILE_ERROR } from '../components/calendar/helper/constants';
import mapPayments from '../components/calendar/helper/mapPayments';
import sortPayments from '../components/calendar/helper/sortPayments';
import { FILE_GENERATED_STATUS } from '../constants';

export default {
  name: 'chart',
  state: {
    header: {},
    payments: [],
    generating: false,
  },
  reducers: {
    load: (state, { data }) => ({ ...state, ...data }),
    setGenerating: (state, generating) => ({ ...state, generating }),
  },
  effects: ({ finances }) => ({
    async receivePayment({ data }, state) {
      const { EMail } = getUser(state);

      const { success, message, data: documents } = data.data;

      if (documents) {
        const { status, operationNo } = documents[0];

        if (status === FILE_ERROR) {
          this.setGenerating(false);
          return alert('Ошибка генерации файла!');
        }

        if (status === FILE_GENERATED_STATUS) {
          await finances.getPayments(operationNo);
          this.setGenerating(false);
        }

        return;
      }

      alert(!success ? message : `Файл отправлен на ${EMail}`);
      this.setGenerating(false);
    },
  }),
  selectors(slice, createSelector, hasProps) {
    return {
      getHeader() {
        return slice(chart => chart.header);
      },
      getPayments() {
        return createSelector(slice, ({ payments }) =>
          compose(mapPayments, sortPayments)(payments),
        );
      },
      getMonthTick: hasProps(({ chart }, value) =>
        createSelector(chart.getPayments, payments => payments[value] || {}),
      ),
      getMonthDates: hasProps(({ chart }, value) =>
        createSelector(
          chart.getPayments,
          payments => payments[value] || {},
          (payments, { monthYear }) =>
            payments.reduce((acc, { date, index }) => {
              const month = date.substr(4, 2);
              const year = date.substr(0, 4);
              const currentMonthYear = `${year}-${month}`;

              if (currentMonthYear === monthYear) {
                return [
                  ...acc,
                  {
                    month,
                    year,
                    monthYear,
                    index,
                  },
                ];
              }

              return acc;
            }, []),
        ),
      ),
    };
  },
};
