// @ts-nocheck
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'common/ui/button';
import { useDesign } from 'hooks/new';
import Mixpanel from 'services/mixpanel';
import { SearchContext } from '../../context';
import { formatName, trackingCardPage } from '../../utils';

import styles from '../hint/hint.module.scss';

export const Item = ({ id, groups, name, requestBody, type }) => {
  const { text, onFetchCatalogGroup, onFetchMain } = useContext(SearchContext);

  const formattedName = formatName(name, text);

  const [isPilot] = useDesign(`/products/card/${id}`);

  const pathname = isPilot ? `/products/card/${id}` : `/products/view/${id}`;

  const handleClick = type => () => {
    let options = {};
    let typeName = '';
    if (type === 'goods') {
      options = {
        запрос: text,
        'название товара': name,
        'id товара': id,
      };
      typeName = 'Товары';

      trackingCardPage(id, name, 'ПоискТоваров');
    }

    if (type === 'brands') {
      options = {
        запрос: text,
        'название бренда': name,
      };
      typeName = 'Бренд';

      onFetchMain(name);
    }

    if (type === 'groups') {
      options = {
        запрос: text,
        'id категории': id,
        'название категории': name,
      };

      typeName = 'Категория';

      onFetchCatalogGroup({
        categoryName: name,
        categoryId: id,
        groups,
        requestBody,
      })();
    }

    Mixpanel?.track(`Поиск.${typeName}`, options);
  };

  switch (type) {
    case 'goods':
      return (
        <Link
          title={name}
          to={{
            pathname,
            state: {
              search: text,
            },
          }}
          target="_blank"
          className={styles['hint__link']}
          onClick={handleClick('goods')}
        >
          {formattedName}
        </Link>
      );

    case 'brands':
      return (
        <Button
          theme="ternary"
          className={styles['hint__link-button']}
          onClick={handleClick('brands')}
        >
          {formattedName}
        </Button>
      );

    case 'groups':
      return (
        <>
          <Button
            theme="ternary"
            className={styles['hint__link-button']}
            onClick={handleClick('groups')}
          >
            {formattedName}
          </Button>
          <div className={styles['hint__groups']}>
            {groups.map(
              (group, index) =>
                group && (
                  <span className={styles['hint__group']} key={group}>
                    {index > 0 && ' / '} {group}
                  </span>
                ),
            )}
          </div>
        </>
      );

    default:
      return { formattedName };
  }
};

Item.defaultProps = {
  groups: [],
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  groups: PropTypes.array,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
