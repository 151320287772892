import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Stop = ({
  className,
  color = '#f15b5b',
  name,
  width = '18',
  height = '18',
  viewBox = '-1 -1 20 20',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M15 3L3 15" stroke={color} />
    <path
      d="M9 17C4.58172 17 1 13.4183 1 9H-1C-1 14.5228 3.47715 19 9 19V17ZM17 9C17 13.4183 13.4183 17 9 17V19C14.5228 19 19 14.5228 19 9H17ZM9 1C13.4183 1 17 4.58172 17 9H19C19 3.47715 14.5228 -1 9 -1V1ZM9 -1C3.47715 -1 -1 3.47715 -1 9H1C1 4.58172 4.58172 1 9 1V-1Z"
      fill={color}
    />
  </svg>
);

Stop.defaultProps = {
  className: '',
  color: '#f15b5b',
  width: '18',
  height: '18',
  viewBox: '-1 -1 20 20',
};

Stop.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
