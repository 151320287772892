import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getOrder } from 'pages/Orders/selectors';
import { isPreOrder } from 'pages/Orders/helpers';

const useIsPreorder = () => {
  const { pathname } = useLocation();
  const order = useSelector(getOrder);

  return isPreOrder(pathname, order);
};

export default useIsPreorder;
