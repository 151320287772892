import { getUser } from 'pages/Orders/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import React from 'react';

export const ContactCell = row => {
  const { login } = useSelector(getUser);

  if (!row) return null;

  const { contact } = row;

  return contact || login;
};

export const ResultCell = row => {
  const header = useSelector(state => state.queue.order);

  if (!row) return null;

  let color = '#000000';
  let res = row.value;

  switch (res) {
    case 'Active':
    case 'В очереди':
    case 'Canceled':
      color = '#008800';
      break;
    case 'Ok':
      color = '#008800';
      res = header['text1'] ? (
        <span>
          Успешно перенесено в заказ&nbsp;
          <Link
            to={`/orders/view/${header['text1']}`}
            className="link link--underlined"
            style={{ color: color }}
          >
            {header['text1']}
          </Link>
        </span>
      ) : (
        row.value
      );
      break;
    case 'Ошибка':
    case 'Error':
      color = '#ee2a24';
      break;
    case 'Сделано':
      color = '#F9992A';
      break;
    case 'Warning':
      color = '#0000cc';
      break;
    case 'Готово':
      color = '#008800';
      break;
    default:
      break;
  }

  return <span style={{ color: color }}>{res}</span>;
};

export const DownloadCell = row => {
  const {
    queue: { getFile },
  } = useDispatch();

  if (!row) return null;

  const { result, opNo } = row.original;
  const res = Number(result);

  return res === 1 ? (
    <a
      href="#"
      onClick={async e => {
        e.preventDefault();
        await getFile(opNo);
      }}
    >
      <i className="icon icon-download" title="Скачать документ" />
    </a>
  ) : res === 2 ? (
    <i className={'icon icon-download-dis'} />
  ) : (
    <i className="loader loader--small" />
  );
};

export const StatusCell = row => {
  if (!row) return null;

  const {
    original: { status },
  } = row;

  let color = '#ff0000';

  switch (status) {
    case 'В очереди':
    case 'Не указан ваш e-mail':
      color = '#F9992A';
      break;
    case 'Готово':
    case 'Отправлено':
      color = '#008800';
      break;
    case 'Ошибка':
    case 'Ошибка отправки':
      color = '#ee2a24';
      break;
    default:
      break;
  }

  return <span style={{ color: color }}>{status}</span>;
};
