/* eslint-disable no-console, react/jsx-key */
import React from 'react';
import { Link } from 'react-router-dom';
import Content from 'components/layout/content';
import { useDispatch, useSelector } from 'react-redux';
import AppSocket from 'components/AppSocket';
import Sheet from './components/Sheet';
import withApi from 'hoc/withApi';

const periods = {
  0: 'Текущий месяц',
  1: 'С прошлого месяца',
  3: 'За 3 месяца',
  6: 'За 6 месяцев',
};

/**
 * Страница "Финансы \ Оборотно-сальдовая ведомость"
 */
const OSV = () => {
  const { phone, email, name } = useSelector(state => state.osv.manager);
  const {
    osv: { loadSheets },
  } = useDispatch();

  return (
    <Content title="Оборотно-сальдовая ведомость">
      <div className="finances-content">
        <div className="description">(аналог баланса клиента, рассылаемого менеджерами)</div>
        <div className="main-block osv-table">
          <AppSocket channel="queue-osv" onReceive={loadSheets}>
            <table cellPadding="2">
              <thead>
                <tr>
                  <th>Выберите период</th>
                  {Object.keys(periods).map((period, key) => {
                    return <th key={key}>{periods[period]}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Файл с О/С ведомостью</td>
                  {
                    // перебираем периоды - столбцы
                    Object.keys(periods).map((period, key) => {
                      return (
                        <td key={key}>
                          <Sheet period={period} />
                        </td>
                      );
                    })
                  }
                </tr>
              </tbody>
            </table>
          </AppSocket>
        </div>
        <div className="fin-contacts">
          <div className="left">
            Сформированный в формате Excel документ, вы можете скачать, открыть или отправить на
            e-mail, указанный в <Link to={'/settings/personal'}>настройках</Link> вашей учетной
            записи. Если вас интересует баланс за другие периоды, вы можете обратиться к вашему
            финансовому менеджеру:
            <p>{name}</p>
            <p>
              тел.: (495) 981-8484
              {phone !== '' && <span>&nbsp;&#40;доб. {phone}&#41;</span>}
            </p>
            <p>
              e-mail: <a href={`mailto:${email}`}>{email}</a>
            </p>
          </div>
          <div className="right">
            <Link className="finance-documents" to={'/finances/accounted'}>
              <i className="icon icon-uz_list" />
              Учтенные документы
            </Link>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default withApi(OSV);
