import React, { useState } from 'react';
import { debounce, get } from 'lodash';
import { EditableSelect } from 'components/fields';
import RenderTimePicker from 'components/fields/renderTimePicker';
import parse from 'react-html-parser';
import { handleWithCustomRule, handleWithRuleMaxLength, highlightOptions } from '../../../helpers';
import { Field } from 'redux-form';
import classNames from 'classnames';

export const renderCheckbox = ({
  input,
  name,
  label,
  required,
  title,
  meta: { touched, error },
}) => (
  <div className="col-sm-6">
    <label className={required ? 'required' : ''}>{label}</label>
    <div>
      <input {...input} name={name} value={input.value} type="checkbox" title={title} />
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </div>
  </div>
);

export const renderTime = ({
  input,
  label,
  required,
  initTime: { minTimeFrom, maxTimeTo },
  meta: { touched, error },
}) => (
  <div className="col-sm-6">
    <label className={required ? 'required' : ''}>{label}</label>
    <div>
      <RenderTimePicker
        originTimeFrom={minTimeFrom}
        originTimeTo={maxTimeTo}
        timeMin={minTimeFrom}
        timeMax={maxTimeTo}
        input={input}
      />
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </div>
  </div>
);

export const RenderSelectInputAsync = props => {
  const {
    input,
    required,
    label,
    meta: { touched, error },
    fetchData,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const debounceFetch = debounce((value, callback) => {
    return fetchData(value)
      .then(res => {
        const data = get(res, ['data', 'hits'], []);
        const text = get(res, ['filters', 'text'], '');

        const options = highlightOptions(data, text).map(item => ({
          code: item.code,
          highlighted: parse(item.highlighted),
          name: item.name,
        }));

        setIsLoading(false);

        return callback(null, { options });
      })
      .catch(err => callback(err, null));
  }, 1100);

  const handleChange = valueSelect => {
    input.onChange((valueSelect && valueSelect.code) || null);
  };

  const loadOptions = (inputValue, callback) => {
    if (!inputValue) {
      return Promise.resolve({
        options: [],
      });
    }

    if (inputValue.trim() && !/A-Za-z0-9/.test(inputValue) && inputValue.length >= 3) {
      setIsLoading(true);
      debounceFetch(inputValue, callback);
    }
  };

  const customFilterOption = option => option;
  return (
    <div className="col-sm-6">
      <label className={required ? 'required' : ''}>{label}</label>
      <div className="field-address">
        <EditableSelect
          async={true}
          placeholder={'Начните ввод'}
          searchPromptText={'Начните ввод для получения список адресов (минимум 3 символа)'}
          noResultsText={'Адрес не найден'}
          loadingPlaceholder={'Поиск адреса'}
          loadOptions={loadOptions}
          onChange={handleChange}
          valueKey="code"
          labelKey="highlighted"
          inputKey="name"
          onSelectResetsInput={false}
          onBlurResetsInput={false}
          onCloseResetsInput={false}
          filterOption={customFilterOption}
        />
        {isLoading && <span className="Suggest-spinner" />}
        {touched && error && <span className="text-danger text-danger--small">{error}</span>}
      </div>
    </div>
  );
};
export const RenderInputText = ({
  input,
  label,
  required,
  min,
  colSm,
  handleChangeWithRule,
  meta: { touched, error },
}) => {
  const handleChange = handleChangeWithRule(input);
  return (
    <div className={colSm ? `col-sm-${colSm}` : 'col-sm-6'}>
      <label className={required ? 'required' : ''}>{label}</label>
      <input
        type="text"
        className={classNames('form-control', { 'form-control--min': min })}
        value={input.value}
        onChange={handleChange}
      />
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </div>
  );
};
export const ContainerAddressFields = () => {
  return (
    <div className="col-sm-6 address-container">
      <Field
        label="Дом"
        name="house"
        required
        min
        colSm="0"
        handleChangeWithRule={handleWithCustomRule(10)}
        component={RenderInputText}
      />
      <Field
        label="Корпус"
        name="korpus"
        min
        colSm="0"
        handleChangeWithRule={handleWithRuleMaxLength(10)}
        component={RenderInputText}
      />
      <Field
        label="Строение"
        name="stroenie"
        min
        colSm="0"
        handleChangeWithRule={handleWithRuleMaxLength(10)}
        component={RenderInputText}
      />
    </div>
  );
};
