import alert from 'components/native/alert/function';
import { download } from 'common/util';
import { batch } from 'react-redux';

export default {
  name: 'queue',
  state: {
    order: {},
    header: {},
    rows: {},
    processing: false,
  },
  reducers: {
    loadOrder: (state, { data }) => ({ ...state, order: data?.order || {} }),
    setHeader: (state, { data, id }) => {
      const headerQueue = state.header[id] || [];
      state.header[id] = [...headerQueue, data];

      return state;
    },
    setRows: (state, { rows, id }) => {
      const currentRows = state.rows[id] || [];
      state.rows[id] = [...currentRows, ...rows];

      return state;
    },
    setProcessing: (state, processing) => ({ ...state, processing }),
  },
  effects: ({ api, queue, list }) => ({
    async getFile(id) {
      try {
        const { blob, filename } = await api.get({
          url: `file/get/${id}`,
          query: '?checkFile=1',
        });

        download(blob, filename);
      } catch (err) {
        alert(err.message);
      }
    },
    onReceive({ data }) {
      batch(() => {
        queue.loadOrder(data);
        queue.setProcessing(false);
        list.replace(data);
      });
    },
    onExpire() {
      alert('Ваш запрос обрабатывается. Обновите страницу через некоторое время.');
      this.setProcessing(false);
    },
  }),
  selectors(slice, createSelector, hasProps) {
    return {
      getHeader: hasProps((models, id) => slice(queue => queue.header[id])),
      getRows: hasProps((models, id) => slice(queue => queue.rows[id])),
    };
  },
};
