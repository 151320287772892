export const hideDuplicate = () => {
  let lastValue = '';

  return value => {
    if (lastValue === value) return '';
    lastValue = value;
    return value;
  };
};

export const hideDublicateInArray = () => {
  let lastValue = '';

  return (array, key) => {
    if (array.length === 1) {
      if (lastValue === array[0][key]) return true;
      lastValue = array[0][key];
    }
    return false;
  };
};
