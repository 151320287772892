import { proxyArgsToMethods } from 'common/util';
import convertFromData from 'helpers/convertFromData';
import { formattingDataForSpecialPrice } from 'pages/VAD/helper/util';
import history from "common/history"
import * as effects from './effects';
import * as reducers from './reducers';

export default {
  name: 'specialPrice',
  state: {
    detail: {},
  },
  reducers,
  effects(dispatch) {
    const type = this.name;

    return {
      ...proxyArgsToMethods(effects, [dispatch, type]),
      async createRequest(values) {
        await dispatch.api.post({
          url: 'rfq/send',
          data: convertFromData(formattingDataForSpecialPrice(values)),
        });
      },
      async searchVendors(value) {
        try {
          const {
            data: { hits = [] },
          } = await dispatch.api.get({
            url: 'search/vendor',
            query: `?text=${value}`,
          });

          return hits;
        } catch (e) {
          return [];
        }
      },
    };
  },
  selectors: (slice, createSelector) => ({
    getDetail() {
      return slice(specialPrice => specialPrice.detail);
    },
    getExpanded({ list }){
      return createSelector(
        list.getData(history.location.pathname),
        list =>
          list.reduce(
            (acc, item, index) => ({...acc, [index]: true}), {}
          )
      )
    }
  }),
};
