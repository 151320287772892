import React from 'react';

const newsletterColumns = [
  {
    Header: 'Код магазина',
    accessor: 'code',
    width: 150,
    sortable: false,
    style: { textAlign: 'center' },
  },
  {
    Header: 'Магазин',
    accessor: 'name',
    width: 250,
    sortable: false,
    style: { textAlign: 'center' },
  },
  {
    Header: 'Адрес',
    accessor: 'address',
    sortable: false,
    style: { textAlign: 'center' },
  },
];

export default newsletterColumns;
