import { API_URL } from 'common/config/app';

export const goodExpansionApi = {
  'new-good-expansion/goods/related/([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/item/index/related`,
  },
  'new-good-expansion/goods/analogs/([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/item/index/analogs`,
  },
};
