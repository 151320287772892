import React, { useCallback, useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { MultiSelect, ScheduleTimeField } from '../../fields';
import { renderMasked, renderText } from 'components/fields';
import { autoNumberMask } from '../../../constants/text-masks';
import classNames from 'classnames';
import validate from '../helpers/shipment-schedule-validate';
import codeValidate from '../helpers/shipment-schedule-code-validate';
import CustomDayPickerInput from '../../fields/new-shipment-daypicker';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import { SHIPMENT_SCHEDULE_FORM } from 'pages/Logistic/constants';
import rippleLoader from 'img/Ripple-B.gif';
import Loader from 'components/loader';
import { getScheduleFormValue } from 'pages/Logistic/selectors';

const ShipmentSchedule = props => {
  const {
    scheduleNo,
    change,
    untouch,
    closeModal,
    handleSubmit,
    submitting,
    initialized,
    initialize,
    reset,
  } = props;

  const no = useSelector(getScheduleFormValue('no'));
  const orderNo = useSelector(getScheduleFormValue('orderNo'));

  const [numberType, setNumberType] = useState(false);
  const [orders, setOrders] = useState([]);

  const isLoadingOrders = useSelector(getEffectLoading('shipments', 'getOrders'));
  const isLoadingSchedule = useSelector(getEffectLoading('shipments', 'getSchedule'));
  const isInitialized = scheduleNo !== 'NEW' && initialized;

  const {
    shipments: { getOrders, getSchedule, sendSchedule, deleteSchedule },
  } = useDispatch();

  useEffect(() => {
    getOrders().then(setOrders);

    return reset;
  }, []);

  useEffect(() => {
    if (scheduleNo !== 'NEW') {
      getSchedule(scheduleNo).then(schedule => {
        if (schedule) {
          schedule.numberType = !!Number(schedule.notRussianStateNumber);
          setNumberType(schedule.numberType);
          initialize(schedule);
        }
      });
    }
  }, [scheduleNo]);

  const classNumber = initialized ? 'col-sm-9' : 'col-sm-12';
  const classDateRow = initialized ? 'row' : 'row new-shipment__form__row';

  const numberTypeToggle = useCallback(() => {
    setNumberType(!numberType);
  }, [numberType]);

  const handleSendSchedule = useCallback(
    async data => {
      await sendSchedule({ data, orders, scheduleNo: no });
      closeModal();
    },
    [no, orders],
  );

  if (scheduleNo !== 'NEW' && isLoadingSchedule) {
    return <Loader />;
  }

  if (scheduleNo !== 'NEW' && !isLoadingSchedule && !initialized) {
    return (
      <div className="new-shipment">
        <h2>Запись на самовывоз загружается</h2>
        <span>
          <img alt="" className="products__preloader__image" src={rippleLoader} />
        </span>
        <div className="new-shipment__form__buttons">
          <button
            type="button"
            className={classNames(
              'btn',
              'btn-outline-danger',
              'btn--rect',
              'new-shipment__form__buttons__cancel',
            )}
            onClick={closeModal}
          >
            Отменить
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="new-shipment">
      <h2>{isInitialized ? 'Запись на самовывоз ' + no : 'Запись на самовывоз'}</h2>
      <form className="new-shipment__form" onSubmit={handleSubmit(handleSendSchedule)}>
        <div className="row new-shipment__form__number new-shipment__form__row">
          <div className={classNumber}>
            {isInitialized ? (
              <span>
                <label>Номер заказа</label> {orderNo.join(',')}
              </span>
            ) : (
              <Field
                name="orderNo"
                label="Номер заказа"
                required
                placeholder=""
                component={MultiSelect}
                noResultsText="Заказы не найдены"
                formattingTimeIntervalCode={() => {
                  change('timeIntervalCode', null);
                  untouch('timeIntervalCode');
                }}
                isLoading={isLoadingOrders}
                options={orders}
                validate={[value => codeValidate(orders, value)]}
              />
            )}
          </div>
          {isInitialized && (
            <div className="col-sm-3 new-shipment__remove">
              <button
                type="button"
                className="btn btn-outline-danger btn--rect new-shipment__remove__button"
                onClick={async () => {
                  closeModal();
                  await deleteSchedule(no);
                }}
              >
                Удалить запись
              </button>
            </div>
          )}
        </div>
        <div className={classDateRow}>
          <div className="datepicker-block col-sm-4">
            <Field
              name="date"
              label="Планируемая дата приезда"
              required
              component={CustomDayPickerInput}
              disabled={isInitialized}
            />
          </div>
          <Field
            name="timeIntervalCode"
            label="Планируемое время приезда"
            required
            className="col-sm-4"
            component={ScheduleTimeField}
            noResultsText="Выберите другой день"
            orders={ orders }
            disabled={isInitialized}
            placeholder=""
          />
        </div>
        <div className="row new-shipment__form__row">
          <Field
            name="carModel"
            type="text"
            label="Марка автомобиля"
            required
            colSm={4}
            maxLength={160}
            className={'test'}
            component={renderText}
          />
          <div className="col-sm-4  new-shipment__form__shipment-today">
            <Field
              name="numberType"
              id="numberType"
              onChange={numberTypeToggle}
              type="checkbox"
              component="input"
            />
            <label htmlFor="numberType">Государственный номер не РФ</label>
          </div>
          {!numberType && (
            <Field
              name="stateNumber"
              type="text"
              label="Гос. номер"
              required
              placeholder="X123XX123"
              colSm={4}
              component={renderMasked}
              mask={autoNumberMask}
            />
          )}
          {numberType && (
            <Field
              name="notRussianStateNumber"
              type="text"
              label="Гос. номер не РФ"
              required
              colSm={4}
              maxLength={20}
              component={renderText}
            />
          )}
        </div>
        <div className="row new-shipment__form__row">
          <Field
            name="representativeFIO"
            type="text"
            label="ФИО представителя"
            required
            colSm={12}
            maxLength={160}
            component={renderText}
          />
        </div>
        <div className="new-shipment__form__buttons">
          <button
            type="button"
            className={classNames(
              'btn',
              'btn-outline-danger',
              'btn--rect',
              'new-shipment__form__buttons__cancel',
            )}
            onClick={closeModal}
          >
            Отменить
          </button>
          <button
            disabled={submitting || isLoadingOrders || isLoadingSchedule}
            type="submit"
            className={classNames(
              'btn',
              'btn-danger',
              'btn--rect',
              'new-shipment__form__buttons__save',
            )}
          >
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
};

const ShipmentScheduleForm = reduxForm({
  form: SHIPMENT_SCHEDULE_FORM,
  touchOnChange: true,
  touchOnBlur: true,
  validate,
})(ShipmentSchedule);

export default ShipmentScheduleForm;
