import { useState, useEffect, useCallback } from 'react';
import get from 'lodash/get';
import chunk from 'lodash/chunk';
import useClientRect from 'hooks/use-client-rect';

const useFlexPagination = (items, itemHeight) => {
  const [rect, ref] = useClientRect();
  const [page, setPage] = useState(1);
  const [elements, setElements] = useState([]);

  const height = get(rect, 'height', 0);
  const pageSize = Math.floor(height / itemHeight);
  const total = items.length;
  const pages = Math.ceil(total / pageSize);

  const handleChangePage = useCallback(pageNumber => {
    setPage(pageNumber);
  }, []);

  useEffect(() => {
    const elements = chunk(items, pageSize);

    setElements(elements);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, pageSize]);

  const result = { elements, pages, page };

  return [result, ref, handleChangePage];
};

export default useFlexPagination;
