import { LINKED_MAX_FILE_SIZE } from 'common/config';
import { checkDateWithFormatted } from 'common/util';
import { handlerDate } from 'pages/VAD/helper/handlerDate';

const validateSpecialPrices = values => {
  const maxLength = 200;
  const verdorMaxLength = 40;
  const requiredField = 'Заполните поле';
  const errMaxLength = `Значение поля не может превышать ${maxLength} символов`;
  const verdorErrMaxLength = `Значение поля не может превышать ${verdorMaxLength} символов`;
  const errors = {};

  if (!values.inn) {
    errors.inn = requiredField;
  }

  if (!values.customerName) {
    errors.customerName = requiredField;
  }

  if (
    values.customerName &&
    values.customerName.fullname &&
    values.customerName.fullname.length > maxLength
  ) {
    errors.customerName = errMaxLength;
  }

  if (!values.f050) {
    errors.f050 = requiredField;
  }

  if (!values.f051) {
    errors.f051 = requiredField;
  }
  if (values.f051 && values.f051.length > maxLength) {
    errors.f051 = errMaxLength;
  }

  if (values.f060 && values.f060.length > maxLength) {
    errors.f060 = errMaxLength;
  }

  if (values.f070 && values.f070.length > maxLength) {
    errors.f070 = errMaxLength;
  }

  if (!values.f090) {
    errors.f090 = requiredField;
  }

  if (!values.f100) {
    errors.f100 = requiredField;
  }

  if (values.f110 && values.f110.length > maxLength) {
    errors.f110 = errMaxLength;
  }

  if (!values.f120) {
    errors.f120 = requiredField;
  }

  if (!values.specFile) {
    errors.specFile = requiredField;
  }

  if (
    values.specFile &&
    values.specFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    errors.specFile = 'Недопустимый формат файла';
  }

  if (values.specFile && values.specFile.size > LINKED_MAX_FILE_SIZE) {
    errors.specFile = 'Размер принятого файла превысил максимально допустимый размер';
  }

  if (values.f130 && values.f130.length > maxLength) {
    errors.f130 = errMaxLength;
  }

  if (values.f160 && values.f160.length > maxLength) {
    errors.f160 = errMaxLength;
  }

  if (values.f150 && values.f150.length > maxLength) {
    errors.f150 = errMaxLength;
  }

  if (values.f140 && values.f140.length > maxLength) {
    errors.f140 = errMaxLength;
  }

  if (values.f170 && values.f170.length > maxLength) {
    errors.f170 = errMaxLength;
  }

  if (values.f180 && values.f180.length > maxLength) {
    errors.f180 = errMaxLength;
  }

  if (values.f190 && values.f190.length > maxLength) {
    errors.f190 = errMaxLength;
  }

  if (values.f120) {
    const result = checkDateWithFormatted(values.f080, values.f120, handlerDate);
    errors.f120 = result.error && result.message;
  }

  errors.vendorData = {};
  errors.vendorData.vendor = [];
  errors.vendorData.partnerStatus = [];
  errors.vendorData.status = [];

  for (let i = 0; i <= values.vendorCount - 1; i++) {
    if (
      values.vendorData &&
      values.vendorData.vendor &&
      values.vendorData.vendor[i] &&
      values.vendorData.vendor[i].length > verdorMaxLength
    ) {
      errors.vendorData.vendor.push(verdorErrMaxLength);
    } else if (values.vendorData && values.vendorData.vendor && values.vendorData.vendor[i]) {
      errors.vendorData.vendor.push(false);
    } else {
      errors.vendorData.vendor.push(requiredField);
    }

    if (
      values.vendorData &&
      values.vendorData.partnerStatus &&
      values.vendorData.partnerStatus[i] &&
      values.vendorData.partnerStatus[i].length > maxLength
    ) {
      errors.vendorData.partnerStatus.push(errMaxLength);
    } else if (
      values.vendorData &&
      values.vendorData.partnerStatus &&
      values.vendorData.partnerStatus[i]
    ) {
      errors.vendorData.partnerStatus.push(false);
    } else {
      errors.vendorData.partnerStatus.push(requiredField);
    }

    if (
      values.vendorData &&
      values.vendorData.status &&
      values.vendorData.status[i] &&
      values.vendorData.status[i].length > maxLength
    ) {
      errors.vendorData.status.push(errMaxLength);
    } else if (values.vendorData && values.vendorData.status && values.vendorData.status[i]) {
      errors.vendorData.status.push(false);
    } else {
      errors.vendorData.status.push(requiredField);
    }
  }

  return errors;
};

export default validateSpecialPrices;
