/* eslint-disable quote-props */
/* eslint-disable prettier/prettier */

const ORDER_STATUSES = {
  ОЗПр: {
    icon: 'delivery',
    iconColor: 'gray',
    name: 'Заказ (ОЗПр)',
  },
  Резерв: {
    icon: 'checkmark',
    iconColor: 'accent-green',
    name: 'Резерв (ЗПр)',
  },
  'На подтверждении': {
    icon: 'approve',
    iconColor: 'gray',
    name: 'На подтверждении',
  },
  Комплектуется: {
    icon: 'out',
    iconColor: 'gray',
    name: 'Комплектуется',
  },
  'Ожидает оплаты': {
    icon: 'attention',
    iconColor: 'brand-red',
    name: 'Ожидает оплаты',
  },
  'Готов к выдаче': {
    icon: 'out',
    iconColor: 'brand-blue',
    name: 'Готов к выдаче',
  },
  Выдается: {
    icon: 'out-arrow',
    iconColor: 'brand-blue',
    name: 'Выдаётся',
  },
  'Передан в доставку': {
    icon: 'delivery-small',
    iconColor: 'gray',
    name: 'Передан в доставку',
  },
  Выдан: {
    icon: 'out',
    iconColor: 'accent-green',
    name: 'Выдан',
  },
  Доставлен: {
    icon: 'delivery-small',
    iconColor: 'accent-green',
    name: 'Доставлен',
  },
};

const ORDER_STATUSES_FOR_ORDER_LIST = {
  Резерв: {
    icon: 'checkmark',
    iconColor: 'accent-green',
    name: 'Резерв (ЗПр)',
  },
  'На подтверждении': {
    icon: 'approve',
    iconColor: 'gray',
    name: 'На подтверждении',
  },
  'Ожидает оплаты': {
    icon: 'attention',
    iconColor: 'brand-red',
    name: 'Ожидает оплаты',
  },
  Комплектуется: {
    icon: 'out',
    iconColor: 'gray',
    name: 'Комплектуется',
  },
  'Готов к выдаче': {
    icon: 'out',
    iconColor: 'brand-blue',
    name: 'Готов к выдаче',
  },
  Выдается: {
    icon: 'out-arrow',
    iconColor: 'brand-blue',
    name: 'Выдается',
  },
  Выдан: {
    icon: 'out',
    iconColor: 'accent-green',
    name: 'Выдан',
  },
  'Передан в доставку': {
    icon: 'delivery-small',
    iconColor: 'gray',
    name: 'Передан в доставку',
  },
  Доставлен: {
    icon: 'delivery-small',
    iconColor: 'accent-green',
    name: 'Доставлен',
  },
  ОЗПр: {
    icon: 'delivery',
    iconColor: 'gray',
    name: 'Заказ (ОЗПр)',
  },
};

const SHIPPING_METHODS = {
  доставка: {
    icon: 'delivery-small',
    iconColor: 'accent-green',
    name: 'Доставка',
  },
  самовывоз: {
    icon: 'out',
    iconColor: 'accent-green',
    name: 'Самовывоз',
  },
};

export { ORDER_STATUSES, SHIPPING_METHODS, ORDER_STATUSES_FOR_ORDER_LIST };
