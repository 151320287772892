import React, { Fragment } from 'react';
import get from 'lodash/get';
import List from './common/list';
import withApi from 'hoc/withApi';
import { Link } from 'react-router-dom';
import sortStringNumbers from 'helpers/sortStringNumbers';
import sumBy from 'lodash/sumBy';
import Message from 'pages/Warranty/components/service/components/common/message';

const columns = [
  {
    Header: '',
    Cell: row => {
      if (!row) return null;

      return (
        <Link
          to={{
            pathname: `/warranty/service/view/${row.row.docNo}`,
            state: { type: 'delivery', id: row.row.docNo },
          }}
        >
          <i className="icon icon-view" />
        </Link>
      );
    },
    sortable: false,
    width: 40,
  },
  {
    Header: 'Дата учета',
    id: 'date',
    accessor: d => d.postingDateSort,
    Cell: row => get(row, ['original', 'postingDate'], null),
    width: 110,
  },
  {
    Header: 'Номер',
    id: 'docNo',
    accessor: 'docNo',
    width: 150,
  },
  {
    Header: 'Место выдачи',
    id: 'deliveryPlace',
    accessor: 'deliveryPlace',
    width: 300,
  },
  {
    Header: 'Количество по документу',
    id: 'qty',
    accessor: 'qty',
    sortMethod: sortStringNumbers,
    width: 170,
  },
];

const width = `${sumBy(columns, 'width') + 30}px`;

const Delivery = () => {
  return (
    <Fragment>
      <Message />
      <List columns={columns} width={width} />
    </Fragment>
  );
};

Delivery.keepLocationState = true

export default withApi(Delivery);
