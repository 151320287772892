import { CACHE_NAME } from 'common/config';
import isEqual from 'lodash/isEqual';

export default ({ api, newOrdersNew, newFilters }) =>
  async ({ activeCategory: categoryId, prevCategoryId, searchIds = [], query = '' }, state) => {
    const { orders } = state.newOrdersNew;

    const search = query || (orders.length
      ? orders.map(order => `orders[]=${order.orderNo}`).join('&')
      : 'order=NEW');

    try {
      if (searchIds.length) {
        //#TODO: same key
        let key = 'search';
        let url = 'new-orders-new/goods'

        if(searchIds[0]['LinkedItemNo']){
          key = 'itemList';
          url = 'items/relate'
        }

        const items = await api.post({
          url,
          data: { [key]: searchIds },
          query: `?${search}`,
        });

        !query && newOrdersNew.setGoods(items);
        return { items };
      }

      if (categoryId) {
        const itemsRequest = api.get({
          url: `new-orders-new/goods/${categoryId}`,
          query: `?${search}`,
        });
        const filtersRequest = api.get({
          url: `new-orders-new/filters/${categoryId}`,
          cache: CACHE_NAME,
        });

        const [items, filters] = await Promise.all([itemsRequest, filtersRequest]);

        !query && newOrdersNew.setGoods(items);

        if (!isEqual(prevCategoryId, categoryId)) {
          newFilters.setFilters(filters);
        }

        return { items };
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  };
