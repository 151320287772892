import React from 'react';

const InfoItem = ({ label, text, className }) => {
  const classNameText = ['info-item__text', className].join(' ');
  return (
    <div className="info-item">
      <label className="info-item__label">{label}:&nbsp;</label>
      <span className={classNameText}>{text}</span>
    </div>
  );
};

export default InfoItem;
