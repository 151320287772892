import React, { Fragment, useState, useEffect, useRef, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { chunk } from 'common/util';
import ReactModal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { isCactus } from 'common/config';
import debounce from 'lodash/debounce';
import { resetCheckboxes } from 'pages/Orders/helpers';
import { OrderEditContext } from 'pages/Orders/components/order-edit/context';
import { useDispatch, useSelector } from 'react-redux';
import { getItemsBrandsFilter } from 'pages/Orders/selectors';
import useContextSelector from 'hooks/context/useContextSelector';

const classModal = classNames('Modal', 'Modal__brand-columns', { 'Modal--cactus': isCactus });

const BrandList = memo(({ rows }) => {
  const {
    brands: { choose, clear },
  } = useDispatch();
  const brands = useSelector(state => state.brands);
  const allBrands = useSelector(getItemsBrandsFilter);

  const [searchBrand, setSearchBrand] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState('all');
  const buttonBrand = useRef();
  const searchText = useContextSelector(OrderEditContext, state => state.searchText);
  const selectedBrands = useRef(allBrands);

  const chooseBrand = useCallback(
    debounce(brands => {
      choose(brands);
    }, 1000),
    [],
  );

  useEffect(() => {
    if (searchText && allBrands.length) {
      ReactTooltip.show(buttonBrand.current);
    }
  }, [searchText, allBrands.length]);

  const handleClear = useCallback(() => {
    setSearchBrand('');
    resetCheckboxes('input[name="brand[]"]');
    selectedBrands.current = [];

    clear();
  }, []);

  const isChecked = useCallback(
    brand => {
      return allBrands.filter(checkedBrand => checkedBrand.Brand === brand).length > 0;
    },
    [allBrands.length],
  );

  if (isCactus) {
    return null;
  }

  const handleChooseBrand = e => {
    const target = e.target;
    const brand = target.getAttribute('data-brand');

    if (target.checked) {
      selectedBrands.current = [
        ...selectedBrands.current,
        {
          Brand: brand,
          Exclusive: target.getAttribute('data-exclusive'),
        },
      ];
    } else {
      selectedBrands.current = selectedBrands.current.filter(({ Brand }) => Brand !== brand);
    }

    chooseBrand(selectedBrands.current);
  };

  return (
    <Fragment>
      <div>
        {!!allBrands.length && <i className="icon icon-filter-brands" />}
        <a
          ref={buttonBrand}
          href=""
          title="Выбрать бренды, по которым будут накладываться фильтры на товарные группы и осуществляться поиск"
          className="section-nav section-nav__no-underline section-nav--dashed section-nav__drop-arrow"
          data-tip="Показаны результаты поиска с учетом выбранных фильтров по бренду"
          data-for="button-brend"
          data-event="hided"
          onClick={e => {
            e.preventDefault();
            setIsOpen(true);
          }}
        >
          БРЕНДЫ
        </a>
      </div>
      <ReactTooltip
        id="button-brend"
        place="bottom"
        className="brand__tooltip"
        globalEventOff="click"
        type="light"
      />
      <ReactModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        bodyOpenClassName="ReactModal__Body--open ReactModal__Body--open__brands"
        contentLabel="Modal"
        className={classModal}
        overlayClassName="Overlay Overlay__columns"
        ariaHideApp={false}
      >
        <div
          className="order-edit-header text-left underlined underlined--red row"
          style={{ padding: '0 0 10px' }}
        >
          <div className="col">
            <i
              className={`brands-switcher ${type === 'all' ? 'active' : ''}`}
              onClick={() => setType('all')}
            >
              Все бренды
            </i>
            <i
              className={`brands-switcher ${type === 'choosed' ? 'active' : ''}`}
              onClick={() => setType('choosed')}
            >
              Выделенные бренды
            </i>
          </div>
          <div className="col text-center justify-content-center brand-search">
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Найти"
              onChange={({ target: { value } }) => setSearchBrand(value)}
              value={searchBrand}
            />
          </div>
          <div className="col text-right">
            <i className="brands-switcher brands-switcher--red" onClick={handleClear}>
              Сбросить фильтры
            </i>
            <i className="icon icon-close12 brand-list-closer" onClick={() => setIsOpen(false)} />
          </div>
        </div>
        <div className="brand-list">
          <div style={{ display: type === 'all' ? 'flex' : 'none' }}>
            {brands.map((brands, index) => (
              <div key={index}>
                {brands
                  .filter(
                    brand =>
                      (searchBrand &&
                        brand.Brand.toLowerCase().indexOf(searchBrand.toLowerCase()) === 0) ||
                      !searchBrand,
                  )
                  .map(brand => (
                    <label key={brand.Brand}>
                      <input
                        type="checkbox"
                        name="brand[]"
                        onChange={handleChooseBrand}
                        defaultValue={brand.Brand}
                        data-brand={brand.Brand}
                        data-exclusive={brand.Exclusive}
                        defaultChecked={isChecked(brand.Brand)}
                      />
                      &nbsp;{brand.Brand}
                    </label>
                  ))}
              </div>
            ))}
          </div>
          <div style={{ display: type === 'choosed' ? 'flex' : 'none' }}>
            {chunk(allBrands, rows || 15).map((brands, index) => (
              <div key={index}>
                {brands
                  .filter(
                    brand =>
                      (searchBrand &&
                        brand.Brand.toLowerCase().indexOf(searchBrand.toLowerCase()) === 0) ||
                      !searchBrand,
                  )
                  .map(brand => (
                    <label key={brand.Brand}>
                      <input
                        type="checkbox"
                        name="brand[]"
                        defaultValue={brand.Brand}
                        onChange={choose}
                        data-brand={brand.Brand}
                        data-exclusive={brand.Exclusive}
                        checked={true}
                      />
                      &nbsp;{brand.Brand}
                    </label>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </ReactModal>
    </Fragment>
  );
});

BrandList.propTypes = {
  rows: PropTypes.number,
};

export default BrandList;
