import { FLUSH_STATE, SET_STATE } from '../action/state';

export const initialOrderState = {
  group: false,
  page: 0,
  sorted: [],
  isNotPreorder: true,
  groups: {},
  searchText: '',
  import: false,
  // for pressure next request while resetting filters in new search
  skipFilterFlushRequest: false,
};

export const initialMoveState = {
  orderToMove: '',
  moved: {},
  changed: {},
  expanded: false,
  itemsToMove: {},
};

export default (state, action) => {
  switch (action.type) {
    case FLUSH_STATE:
      return {
        ...state,
        group: false,
        page: 0,
        searchText: '',
        groups: {},
        skipFilterFlushRequest: false,
      };
    case SET_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
