import { fileTypeForbidden } from 'components/constants/file-types';
import { undef } from '../util';
import { FEEDBACK_FILE_TYPES, LINKED_MAX_FILE_SIZE } from '../config';

export default values => {
  const errors = {};

  if (!values || !('theme' in values) || !values.theme.length) {
    errors.theme = 'Поле обязательно для заполнения';
  }
  if (values && 'theme' in values && values.theme.length > 100) {
    errors.theme = 'Тема сообщения не может быть длинее 100 символов';
  }
  if (values && 'theme' in values && values.theme.length < 10) {
    errors.theme = 'Тема сообщения не может быть меньше 10 символов';
  }

  if (!values || !('description' in values) || !values.description.length) {
    errors.description = 'Поле обязательно для заполнения';
  }
  if (values && 'description' in values && values.description.length > 1000) {
    errors.description = 'Тема сообщения не может быть длинее 1000 символов';
  }

  return errors;
};

export const fileType = values => {
  const regexpTypeFile = /\.\w+$/;
  const file = values && values.length ? values[0] : undefined;

  const fileType = file && file.name?.match(regexpTypeFile);

  if (fileTypeForbidden.includes(fileType)) {
    return 'Недопустимый формат файла';
  }
};

export const filesValidators = values => {
  const file = values && values.length ? values[0] : undefined;

  if (undef(file)) {
    return;
  }

  if (file.name.indexOf('.') === -1) {
    return 'Неизвестный тип файла';
  }

  if (file.type !== 'image/jpeg' && file.size > LINKED_MAX_FILE_SIZE) {
    return 'Размер принятого файла превысил максимально допустимый размер';
  }

  const regexpTypeFile = /\.(\w+)$/;
  const fileType = file?.type.toString() || file?.name?.match(regexpTypeFile)[1];

  if (!FEEDBACK_FILE_TYPES.includes(fileType)) {
    return 'Данный тип файла не поддерживается';
  }

  if (fileTypeForbidden.includes(fileType)) {
    return 'Недопустимый формат файла';
  }
};
