// @ts-nocheck
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { CACHE_NAME } from 'common/config';
import { capitalize } from 'utils/new/strings';
import { makeCrumbs, makeFavoriteCategoriesFromStorage } from './utils';

export default {
  name: 'newCatalog',
  state: {
    fetching: false,
    activeCategories: {
      firstCategory: null,
      secondCategory: null,
      thirdCategory: null,
    },
    crumbs: [],
    facets: {},
    categories: [],
    subCategories: [],
    fromOrigin: '',
  },
  reducers: {
    clearCatalogSubCategories: state => {
      state.subCategories = [];
      return state;
    },
    fetchCatalogCategoriesRequest: state => {
      state.fetching = true;
      return state;
    },
    fetchCatalogCategoriesSuccess: (state, payload) => {
      state.categories = get(payload, 'data', []);
      state.fetching = false;

      return state;
    },
    fetchCatalogSubCategoriesRequest: state => {
      state.fetching = true;

      return state;
    },
    fetchCatalogSubCategoriesSuccess: (state, payload) => {
      state.subCategories = get(payload, 'data', {});
      state.fetching = false;

      return state;
    },
    fetchCatalogFavoriteSubCategoriesSuccess: (state, payload) => {
      const newSubCategories = get(payload, 'data', {});
      state.favoriteSubCategories = uniq([...state.subCategories, ...newSubCategories]);
      state.fetching = false;

      return state;
    },
    setActiveCatalogCategories: (state, payload) => {
      state.activeCategories = { ...state.activeCategories, ...payload };

      return state;
    },
    fetchCatalogGroupRequest: state => {
      state.fetching = true;
      return state;
    },
    fetchCatalogGroupSuccess: state => {
      state.fetching = false;

      return state;
    },
    setCrumbs: (state, payload) => {
      state.crumbs = payload || [];
      return state;
    },
    setFromOrigin: (state, payload) => {
      state.fromOrigin = payload;

      return state;
    },
  },
  effects(dispatch) {
    const { api, ...models } = dispatch;
    const { newOrdersNew, newUser } = models;
    return {
      async fetchCatalogCategories() {
        try {
          this.fetchCatalogCategoriesRequest();

          const result = await api.get({
            url: 'new-orders/catalog/first-level',
            cache: CACHE_NAME,
          });

          this.fetchCatalogCategoriesSuccess(result);

          return result;
        } catch (err) {
          return null;
        }
      },
      async fetchCatalogSubCategories(categoryId) {
        try {
          this.fetchCatalogSubCategoriesRequest();

          const result = await api.get({
            url: `new-orders/catalog/second-level/${categoryId}`,
            cache: CACHE_NAME,
          });

          this.fetchCatalogSubCategoriesSuccess(result);

          return result;
        } catch (err) {
          return null;
        }
      },
      async fetchCatalogFavoriteSubCategories(categoryId) {
        try {
          const result = await api.get({
            url: `new-orders/catalog/favorite/second-level/${categoryId}`,
          });

          this.fetchCatalogFavoriteSubCategoriesSuccess(result);

          return result;
        } catch (err) {
          return null;
        }
      },
      async saveCatalogFavoriteCategory(favoriteCategories) {
        const result = await api.post({
          url: 'new-orders/catalog/save',
          data: {
            favoriteCategory: favoriteCategories,
          },
        });

        newUser.fetchSettings();

        return result;
      },
      async fetchCatalogGroup(categoryId) {
        try {
          const result = await newOrdersNew.fetchGoods({ activeCategory: categoryId });

          const data = get(result, ['items', 'data'], []);
          const crumbs = makeCrumbs(data);
          if (crumbs.length > 0) {
            this.setCrumbs(crumbs);
            this.setActiveCatalogCategories({
              firstCategory: crumbs[0],
              secondCategory: crumbs[1],
              thirdCategory: crumbs[2],
            });
          }
        } catch (err) {
          return null;
        }
      },
    };
  },
  selectors: (slice, createSelector) => ({
    getCatalog() {
      return slice(state => {
        const sortedCategoriesByName = sortBy(state.categories, 'name');
        const sortedSubCategoriesByName = sortBy(state.subCategories, 'name');
        const sortedFavoriteCategoriesByName = sortBy(state.favoriteCategories, 'name');

        return {
          ...state,
          categories: map(sortedCategoriesByName, sorted => ({
            ...sorted,
            name: capitalize(sorted.name.toLowerCase()),
          })),
          favoriteCategories: map(sortedFavoriteCategoriesByName, sorted => ({
            ...sorted,
            name: capitalize(sorted.name.toLowerCase()),
          })),
          subCategories: map(sortedSubCategoriesByName, sorted => ({
            ...sorted,
            name: capitalize(sorted.name),
          })),
        };
      });
    },
    getActiveCatalogCategories() {
      return slice(state => get(state, 'activeCategories', {}));
    },
    getActiveCategory() {
      return createSelector(
        this.getActiveCatalogCategories,
        ({ thirdCategory, secondCategory }) => thirdCategory?.id || secondCategory?.id,
      );
    },
    getFavoriteCategoriesIds() {
      return createSelector(
        slice,
        rootState => get(rootState, ['newUser', 'favoriteCategories'], null),
        (_, favoriteCategories) => favoriteCategories,
      );
    },
    getFavoriteCategories() {
      return createSelector(
        slice,
        rootState => get(rootState, ['newUser', 'favoriteCategories'], {}),
        (_, favoriteCategories) => {
          const favCats = Object.keys(favoriteCategories)
            .map(favKey => favoriteCategories[favKey])
            .flat();

          return makeFavoriteCategoriesFromStorage(favCats);
        },
      );
    },
    getCrumbs() {
      return slice(state => {
        const fromOrigin = get(state, 'fromOrigin', '');

        if (fromOrigin === 'relatedGoods') {
          return [];
        }

        return state.crumbs;
      });
    },
    getHasCrumbs() {
      return slice(state => !isEmpty(get(state, 'crumbs', [])));
    },
    getCrumbsFromFacets() {
      return slice(state => {
        const facets = get(state, 'facets', {});
        const crumbs = [];

        if (!isEmpty(facets)) {
          if (facets.code1[0].code && facets.code1[0].name) {
            crumbs.push({
              id: facets.code1[0].code,
              name: capitalize(facets.code1[0].name),
            });
          }

          if (facets.code2[0].code && facets.code2[0].name) {
            crumbs.push({
              id: facets.code2[0].code,
              name: capitalize(facets.code2[0].name),
            });
          }

          if (facets.code3[0].code && facets.code3[0].name) {
            crumbs.push({
              id: facets.code3[0].code,
              name: capitalize(facets.code3[0].name),
            });
          }
        }

        return crumbs;
      });
    },
    getFacets() {
      return slice(state => get(state, 'facets', {}));
    },
    getFromOrigin() {
      return slice(state => get(state, 'fromOrigin', ''));
    },
  }),
};
