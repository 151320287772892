// @ts-nocheck
import { CURRENCIES } from 'common/constants/currency';

/**
 * @param   {string} value  number string
 * @returns {string}        formatted string without spaces and commas compatible with Intl.NumberFormat
 */
const toPrimalNumber = value => value?.toString().replace(/,/g, '.').replace(/\s/g, '') || 0;

const toNumber = (value = '') => value?.toString().replace(/,/g, '').replace(/\s/g, '') || 0;

const changePeriodToComma = (value = '') =>
  value?.toString().replace(/\./g, ',').replace(/\s/g, '') || 0;

/**
 * @param   {number|string} value  number or string to be formatted by Intl.NumberFormat
 * @param   {object} options  Intl.NumberFormat options
 * @returns {string}      formatted number
 */
function formatCurrency(value = 0, options = {}) {
  const defaultOptions = {
    currency: 'RUB',
    display: 'symbol',
    fractions: 2,
    locale: 'ru-RU',
    style: 'currency',
    ...options,
  };

  return new Intl.NumberFormat(defaultOptions.locale, {
    style: defaultOptions.style,
    currency: defaultOptions.currency,
    currencyDisplay: defaultOptions.display,
    maximumFractionDigits: defaultOptions.fractions,
    minimumFractionDigits: defaultOptions.fractions,
  }).format(value);
}

/**
 * @param   {number} value  number to be formatted by Intl.NumberFormat
 * @param   {object} options  Intl.NumberFormat options
 * @returns {string}      formatted number
 */
function formatNumber(value = 0, options) {
  const defaultOptions = { fractions: 0, locale: 'ru-RU', style: 'decimal', ...options };
  return new Intl.NumberFormat(defaultOptions.locale, {
    maximumFractionDigits: defaultOptions.fractions,
    minimumFractionDigits: defaultOptions.fractions,
    style: defaultOptions.style,
  }).format(value);
}

function removeCurrencySymbol(value = '') {
  return `${value}`.replace(/\D+$/g, '');
}

/**
 * @param   {string} amount  number string with(out) currency symbol
 * @param   {object} options  Intl.NumberFormat options
 * @returns {string}
 */
function formatPrice(amount = '', options = {}) {
  const { currency = 'RUB' } = options;
  let newAmount = amount;

  if (amount?.includes(CURRENCIES[currency]?.code)) {
    newAmount = removeCurrencySymbol(amount);
  }

  return formatCurrency(toPrimalNumber(newAmount), {
    ...options,
    currency: CURRENCIES[currency].name,
    locale: CURRENCIES[currency].locale,
  });
}

function getFormattedPrice(price, options = { fractions: 2 }) {
  return formatNumber(toPrimalNumber(price), options);
}

export {
  formatCurrency,
  formatNumber,
  formatPrice,
  toPrimalNumber,
  removeCurrencySymbol,
  toNumber,
  changePeriodToComma,
  getFormattedPrice,
};
