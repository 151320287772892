import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Field } from 'redux-form';
import ReactModal from 'react-modal';
import {
  RenderInputClaim,
  RenderDatePicker,
  RenderInputClaimVertical,
  RenderTextAreaClaim,
} from '../fields';
import Required from './required';
import checkMaxLengthOnlyhDig from '../helpers/check-length-dig';
import checkMaxLength from '../helpers/check-length';
import SelectOrderTable from './SelectOrderTable';
import { isCactus } from 'common/config';
import { useSelector } from 'react-redux';
import { getClaimsFormValue } from 'pages/Logistic/selectors';

const classModal = classNames('Modal', 'claims-new__modal', { 'Modal--cactus': isCactus });

const InputsClaims = () => {
  const products = useSelector(getClaimsFormValue('products'));
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [products]);

  const handleModalClose = () => setIsOpen(false);
  const handleModalOpen = () => setIsOpen(true);

  return (
    <Fragment>
      <div className="claims-new__claims-inputs">
        <input type="submit" style={{ display: 'none' }} />
        <div className={classNames('claims-new__form__row', 'claims-new__form__row--left')}>
          <Field
            name="uzNo"
            label=" Номер учтенного заказа"
            style={{ width: '110px' }}
            maxLength={15}
            component={RenderInputClaim}
          />
          <div className="claims-new__form__text-input__actions">
            <button
              className="claims-new__form__text-input__select-link"
              type="button"
              onClick={handleModalOpen}
            >
              Выбрать из списка
            </button>
          </div>
          <Required text="Обязательно для заполнения." />
        </div>
        <div
          className={classNames('claims-new__form__row', 'claims-new__form__row--right')}
          style={{
            justifyContent: 'space-between',
          }}
        >
          <Field name="recDate" label="Дата получения груза от ТК" component={RenderDatePicker} />
          <Required text='обязательно для заполения если метод отгрузки "доставка"' />
        </div>
        <div
          className={classNames(
            'claims-new__form__row',
            'claims-new__form__row--left',
            'claims-new__form__row--vertical-inputs',
          )}
        >
          <Field
            name="cargoReceipt"
            label="полученных мест"
            quantity="Номер"
            handle={checkMaxLengthOnlyhDig(8)}
            component={RenderInputClaimVertical}
          />
          <Field
            name="receivedPlaces"
            label="грузовой квитанции"
            handle={checkMaxLengthOnlyhDig(8)}
            component={RenderInputClaimVertical}
          />
          <Field
            name="receivedBags"
            label="принятых мешков"
            handle={checkMaxLengthOnlyhDig(8)}
            component={RenderInputClaimVertical}
          />
          <Field
            name="receivedBoxes"
            label="принятых коробок"
            handle={checkMaxLengthOnlyhDig(8)}
            component={RenderInputClaimVertical}
          />
        </div>
        <div className={classNames('claims-new__form__row', 'claims-new__form__row--right')}>
          <Field
            name="sealNumbers"
            label="Номера пломб"
            handle={checkMaxLength(160)}
            component={RenderTextAreaClaim}
          />
          <Required text="Обязательно для заполнения если указано кол-во принятых мешков" />
        </div>
        <hr />
      </div>
      <ReactModal
        ariaHideApp={false}
        onRequestClose={handleModalClose}
        isOpen={isOpen}
        contentLabel="Выберите список претензий"
        className={classModal}
        overlayClassName="Overlay Overlay--confirm"
        shouldCloseOnOverlayClick={true}
      >
        <i className="icon icon-close12 claims-new__icon-close" onClick={handleModalClose} />
        <SelectOrderTable />
      </ReactModal>
    </Fragment>
  );
};

export default InputsClaims;
