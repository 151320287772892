import { validateMessage } from '../util';

const validateComment = values => {
  const requiredField = 'Заполните поле';
  const errors = {};

  if (!values.comment) {
    errors.comment = requiredField;
  }

  if (values.comment && validateMessage(values.comment).length > 4000) {
    errors.comment = 'Превышен максимальный размер комментария';
  }

  return errors;
};

export default validateComment;
