import React, { useEffect, useRef } from 'react';
import { ItemsTableContext } from '../../../../containers/items-table';
import { addSelectedIndexRows, deleteSelectedIndexRows } from '../../../../action';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import useContextSelector from 'hooks/context/useContextSelector';
import useContextDispatch from 'hooks/context/useContextDispatch';

const CheckedTextField = props => {
  const {
    row: {
      original: { id, lineNo, index },
    },
    onClick,
    prepare,
    children,
    ...others
  } = props;

  const state = useContextSelector(ItemsTableContext, state => state);
  const dispatch = useContextDispatch(ItemsTableContext);
  const { selectedIndexRows } = state;

  const stateId = id;

  const prevState = useRef(state[stateId]);

  useEffect(() => {
    if (!isEmpty(prevState.current) && !isEqual(prevState.current, state[stateId])) {
      prepare(state);
    }

    prevState.current = { ...state[stateId] };
  }, [state[stateId]]);

  return (
    <span
      data-line={lineNo}
      onClick={e => {
        const val = parseInt(e.target.innerHTML, 10);
        if (index) {
          if (val) {
            dispatch(addSelectedIndexRows({ [id]: index }));
          } else {
            selectedIndexRows && selectedIndexRows[id] && dispatch(deleteSelectedIndexRows(id));
          }
        }
        onClick(state, dispatch)(val);
      }}
      {...others}
    >
      {children}
    </span>
  );
};

export default CheckedTextField;
