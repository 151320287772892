import React, { useState } from 'react';
import Select from 'react-select';
import FormContainer from './FormContainer';

const FieldSelect = props => {
  const { input, options } = props;

  const [selectValue, setSelectValue] = useState(options[0]);

  const handleChange = value => {
    input.onChange(value ? value.value : null);
    setSelectValue(value);
  };

  return (
    <FormContainer label={'Цель обращения'}>
      <Select
        {...input}
        className="form-field-select__select"
        onChange={value => handleChange(value)}
        options={options}
        value={selectValue}
        clearable={false}
        searchable={false}
      />
    </FormContainer>
  );
};
export default FieldSelect;
