import alert from 'components/native/alert/function';

const loadState = (state, payload) => ({
  ...state,
  message: payload.userMessage,
  agreement: payload.agreement,
});

const entranceMessage = {
  name: 'message',
  state: {
    message: null,
    agreement: null,
  },
  reducers: {
    'user/login': loadState,
    'user/refresh': loadState,
    flushMessage: state => ({ ...state, message: null }),
    flushAgreement: state => ({ ...state, agreement: null }),
  },
  effects: dispatch => ({
    async accept({ id, type = '' }) {
      try {
        const { success, message } = await dispatch.api.post({
          url: 'message/accept',
          data: { id },
        });

        if (success) {
          if (type === 'fin') {
            this.flushAgreement();
          } else {
            this.flushMessage();
          }
        } else {
          alert(message);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async agree({ id, setIsOpen }) {
      try {
        const { message } = await dispatch.api.get({
          url: 'finances/agreement/confirm',
        });

        if (message) {
          await this.accept({ id, setIsOpen, type: 'fin' });
        }
      } catch (e) {
        console.log(e);
      }
    },
  }),
};

export default entranceMessage;
