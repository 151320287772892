// @ts-nocheck
import { getBrowser } from 'common/util';

export const formattingData = data => {
  const formData = new FormData();
  const browser = getBrowser();
  formData.append('target', data.target);
  formData.append('description', data.description);
  formData.append('subject', data.theme);

  if (data.files) {
    Object.keys(data.files).forEach(fileKey => {
      formData.append('attach[]', data.files[fileKey][0]);
    });
  }

  Object.keys(browser).forEach(key => {
    formData.append(`browser[${key}]`, browser[key]);
  });

  return formData;
};
