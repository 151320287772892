import { filesValidators } from '../validates/validateFeedBack';

const warmTZ = values => {
  const warnings = {};
  if (values.otherField) {
    warnings.otherField = filesValidators(values.otherField);
  }
  return warnings;
};

export default warmTZ;
