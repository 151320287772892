import { checkAddItemCondition } from 'pages/Orders/helpers';
import alert from 'components/native/alert/function';
import { preparedData } from 'pages/Orders/components/order-edit/helpers/prepareData';
import { selector } from 'client/store';

export default dispatch =>
  async ({ qty, price, relatedParent, analogParent, priceClient = 0, relatedId = 0 }, state) => {
    if (!Number(qty)) {
      return false;
    }

    let clientPrice;

    const { order, user } = selector;

    const { orderNo, currency: orderCurrency } = order.getHeader(state);
    const { Code, Rate } = user.getCurrency(state);

    const productAvailability = state.product.availability;
    const relatedProducts = state.product.related;

    const itemId = productAvailability.id;
    const currencyCode = orderCurrency || Code;
    const relatedItem = relatedProducts.find(item => item.id === relatedId);

    const minPackaged = Number(relatedItem?.minPackaged || productAvailability.minPackaged);
    const salesLimitType = relatedItem?.salesLimitType || productAvailability.salesLimitType;

    if (!checkAddItemCondition(qty, minPackaged, salesLimitType, relatedId)) {
      return false;
    }

    if (!relatedId && price / 2 > productAvailability.priceLCY) {
      alert(
        `Слишком большое отклонение ЖЦены от РЦены для товара ${itemId}<br />Рекомендуемая цена - ${productAvailability.priceLCY}!`,
      );

      return false;
    }

    if (currencyCode !== '' && currencyCode !== 'РУБ') {
      clientPrice = priceClient || parseFloat(price) / parseFloat(Rate);
    }

    const curItemId = relatedId || itemId;

    const parentId = (relatedId && itemId) || relatedParent || analogParent;

    preparedData.set({
      qtys: { [curItemId]: qty },
      prices: { [curItemId]: price },
      priceClients: { [curItemId]: clientPrice },
      source: parentId || 0,
      analog: !!analogParent,
      related: !!relatedParent || !!relatedId,
      card: true,
    });

    await dispatch.items.save({ isCard: true, orderNo });
  };
