import { FOUND_WITH_AVAILABILITY } from 'common/config';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { compose } from 'redux';
import cleanFilterGroups from 'pages/Orders/components/common/order-list-filter/helpers/cleanFilterGroups';
import { buildFilterStrategies } from 'pages/Orders/components/common/order-list-filter/helpers/buildFilterStrategies';
import assignFilteredFacets from 'pages/Orders/components/common/order-list-filter/helpers/clientData/assignFilteredFacets';
import unblockFilters from 'pages/Orders/components/common/order-list-filter/helpers/clientData/unblockFilters';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const isBackendFiltration =
  (slice, createSelector) =>
  ({ items }) =>
    createSelector(items.getTotalFound, total => total > FOUND_WITH_AVAILABILITY);

export const getServerFilters =
  () =>
  ({ items }) =>
    createDeepEqualSelector(items.getCharacteristics, items.getFacets, (chars, facets) =>
      compose(cleanFilterGroups, buildFilterStrategies)(chars, facets),
    );

export const getServerGroups =
  (slice, createSelector) =>
  ({ filters }) =>
    createSelector(filters.getServerFilters, groups => cloneDeep(groups));

export const getGroups = slice => () => slice(filters => filters.groups || []);
export const getFacets = slice => () => slice(filters => filters.facets || {});
export const getTags = slice => () => slice(filters => filters.tags || []);

export const getTagsWithoutKey =
  (slice, createSelector) =>
  ({ filters }) =>
    createSelector(filters.getTags, tags =>
      tags.map(tag => {
        const { key, ...rest } = tag;
        return {
          ...rest,
          keyType: key,
        };
      }),
    );

export const getActiveKeys =
  (slice, createSelector) =>
  ({ filters }) =>
    createSelector(filters.getTags, tags => {
      return tags.map(tag => tag.code)
    });

export const getActiveGroups =
  (slice, createSelector) =>
  ({ filters }) =>
    createSelector(filters.getTags, tags => tags.map(tag => tag.groupId));

export const getCurrentKey =
  (slice, createSelector) =>
  ({ filters }) =>
    createSelector(filters.getTags, tags => tags[tags.length - 1]?.groupId || null);

export const getActive = slice => () => slice(filters => filters.active);

export const getCurrentGroups =
  (slice, createSelector) =>
  ({ filters }) =>
    createSelector(
      filters.getServerFilters,
      filters.getServerGroups,
      filters.getGroups,
      filters.getFacets,
      filters.getActiveGroups,
      filters.getCurrentKey,
      filters.getActiveKeys,
      (...args) => {
        const [server, serverGroups, client, facets, activeGroups, activeGroup, activeKeys] = args;

        const groups = client.length ? client : server;

        if (!isEmpty(facets)) {
          return assignFilteredFacets(facets, serverGroups)(activeGroup, activeGroups, activeKeys);
        }

        if (!client.length) {
          unblockFilters(serverGroups);
        }

        return groups;
      },
    );
