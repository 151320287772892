import createValue from '../helpers/createValue';
import createPriceRUB from '../helpers/createPriceRUB';

export default {
  key: 'priceRUB',
  create: ({ order, user, currency }) => ({
    label: 'Сумма:',
    value: createValue(createPriceRUB({ user, order, currency })),
  }),
};
