import ObjCollector from 'services/objCollector'

export const activeControllers = new ObjCollector();

activeControllers.abort = function (url) {
  const activeController = this.get(url);

  activeController?.abort?.();
};

activeControllers.customizer = function(value){
  if(value instanceof AbortController){
    return value
  }
}

activeControllers.abortAll = function () {
  const activeControllers = this.get();

  Object.values(activeControllers).forEach(controller => {
    controller?.abort?.();
  });

  this.clear();
};
export const routesConfig = new ObjCollector();
export const FiltersCollector = new ObjCollector()
