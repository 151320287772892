import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const ArrowUp = ({
  className,
  color = '#333',
  name,
  width = '12',
  height = '6',
  viewBox = '0 0 12 6',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M11 5L6 0.999999L1 5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width={width}
            height={height}
            fill="white"
            transform="translate(12 6) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

ArrowUp.defaultProps = {
  className: '',
  color: '#333',
  width: '12',
  height: '6',
  viewBox: '0 0 12 6',
};

ArrowUp.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
