/* eslint-disable no-undef */
import React, { Fragment, useMemo, useEffect } from 'react';
import { useDispatch, batch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getParentRoute } from 'common/util';
import { routes } from 'common/routes';
import {
  UNAUTHORIZED,
  NOTFOUND,
  API_CONSTRAINT,
  API_INTERNAL,
  CSRF_CONSTRAINT,
  isDevEnv,
  MAINTENANCE,
  NODE_API_CONSTRAINT,
  TOKEN_EXPIRED,
} from 'common/config';
import LoginForm from 'pages/Login/containers/login-form';
import NoMatch from 'components/no-match';
import MessageModal from 'containers/message-modal';
import alert from 'components/native/alert/function';
import isEmpty from 'lodash/isEmpty';
import { matchRoutes } from 'react-router-config';
import Maintenance from 'components/maintenance';
import notifier from 'services/notifier';
import isEqual from 'lodash/isEqual';
import { TokenExpired } from 'components/token-expired';

const View = props => {
  const { location, children, version, user, isAuth, isAuthenticated, isCredPresence, status, message } = props;

  const dispatch = useDispatch();

  const branch = matchRoutes(routes, location.pathname);
  const noAuthChecking = branch.some(({ route: { props } }) => props && props.auth);

  const handleErrors = () =>
    batch(() => {
      notifier.dismiss();
      dispatch.secure.renewCSRF();
    });

  useEffect(() => {
    switch (status) {
      case API_INTERNAL:
        alert(
          'Возникла непредвиденная ошибка!<br />Спасибо за помощь в обнаружении!:-)<br />Наши специалисты вот-вот займутся её устранением',
        ).then(handleErrors);

        break;
      case API_CONSTRAINT:
      case NODE_API_CONSTRAINT: {
        alert(message);

        break;
      }
      case CSRF_CONSTRAINT: {
        alert(`${message} Пожалуйста, повторите действие.`).then(handleErrors);
      }
    }
  }, [status, message]);

  const modalStyle = useMemo(() => {
    const isNewVersion = !isDevEnv && version && version !== __webpack_hash__;

    return {
      display: isNewVersion || status ? 'none' : 'block',
    };
  }, [version, status]);

  if (!noAuthChecking && (isAuthenticated !== isAuth || isEmpty(user) || !isCredPresence)) {
    return <LoginForm {...props} />;
  }

  switch (status) {
    case UNAUTHORIZED:
      return <LoginForm {...props} />;
    case NOTFOUND:
      return <NoMatch initiator="api" {...props} />;
    case API_CONSTRAINT: {
      const parentRoute = getParentRoute(routes, location.pathname);

      return <Redirect to={parentRoute.path} />;
    }
    case API_INTERNAL:
      return <Fragment>{children}</Fragment>;
    case CSRF_CONSTRAINT:
      /*
      LogRocket.captureMessage('CSRF not match!', {
        tags: {
          subscription: 'Pro',
        },
        extra: {
          pageName: location.pathname,
        },
      })*/

      return <Fragment>{children}</Fragment>;
    case MAINTENANCE:
      return <Maintenance>{message}</Maintenance>;
    case TOKEN_EXPIRED:
      return <TokenExpired message={message} />;
    default:
      return (
        <Fragment>
          {children}
          <MessageModal style={modalStyle} />
        </Fragment>
      );
  }
};

View.whyDidYouRender = false;

export default View;

