import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import formConfig from '../constants/form-config';
import { renderFile } from 'components/fields';

// noinspection HtmlUnknownTarget,HtmlUnknownTarget,HtmlUnknownTarget,HtmlUnknownTarget,HtmlUnknownTarget
const SpecialPricesFormStepTwo = ({ handleSubmit, previousStep }) => (
  <form onSubmit={handleSubmit}>
    <div className="special-prices__container">
      <div className="row">
        <label className="special-prices__label">
          Загрузите Excel файл формата xlsx, содержащий Партномер производителя, количество позиций
          и наименование продукта.
        </label>
        <div className="col-sm-6">
          <Field name="specFile" label="Файл" component={renderFile} />
        </div>
        <div className="col-sm-6" />
        <div className="clo-sm-12">
          <div className="example">
            <a href="/contents/cms/img/files/Special_prices_example.xlsx">
              <span className="icon form-xls" />
              Скачать пример файла
            </a>
          </div>
        </div>
      </div>
      <div className="row special-prices__container__buttons">
        <button type="button" className="btn btn--rect btn-outline-danger" onClick={previousStep}>
          Назад
        </button>
        <button type="submit" className="btn btn--rect btn-outline-danger">
          Далее
        </button>
      </div>
    </div>
  </form>
);

SpecialPricesFormStepTwo.propTypes = {
  previousStep: PropTypes.func.isRequired,
};

export default reduxForm(formConfig)(SpecialPricesFormStepTwo);
