// @ts-nocheck
import React, { useEffect } from 'react';
import get from 'lodash/get';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { useHistory, useParams } from 'react-router-dom';
import Content from 'components/layout/content';
import { renderText, renderMasked } from 'components/fields';
import TableList from 'components/table/list';
import { mobileMask } from 'common/util';
import { RenderCustomerName, RenderINN } from 'pages/VAD/components/fields';
import alert from 'components/native/alert/function';
import withApi from 'hoc/withApi';
import validate from 'common/validates/validateCreateIquote';
import { useDispatch, useSelector, connect } from 'react-redux';
import columns from '../helpers/create-iquote-columns';

const FORM_IQUOTE = 'FORM_IQUOTE';

const CreateIquote = ({ handleSubmit, submitting, form, reset }) => {
  const { id } = useParams();
  const history = useHistory();
  const { message, configuration } = useSelector(state => state.iquote);

  const {
    iquote: { createIquoteOrder },
  } = useDispatch();

  const submit = values =>
    createIquoteOrder({
      ...values,
      configuration,
      iquoteId: id,
    }).then(() => {
      history.push({ pathname: '/orders/multibrands' });
      return false;
    });

  useEffect(() => {
    if (message) {
      alert(message);
    }

    return () => {
      reset(form);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <Content title="Создать заказ из HPE iQuote">
      <form onSubmit={handleSubmit(submit)}>
        <div className="iquote-form form form--whole-rows">
          <div className="row">
            <Field
              name="customerName"
              label="Название заказчика"
              props={{ form: FORM_IQUOTE }}
              required
              component={RenderCustomerName}
            />
            <Field
              name="inn"
              label="ИНН заказчика"
              props={{ form: FORM_IQUOTE }}
              required
              component={RenderINN}
            />
          </div>
          <div className="row">
            <Field
              label="Контактные данные (ФИО)"
              name="contact"
              required
              colSm={4}
              component={renderText}
            />
            <Field
              label="Мобильный телефон"
              name="phone"
              required
              colSm={4}
              mask={mobileMask}
              component={renderMasked}
            />
            <Field label="E-mail" name="email" required colSm={4} component={renderText} />
          </div>
          <div className="row">
            <TableList columns={columns} />
          </div>
          <div className="row row--action">
            <button
              type="submit"
              className="btn btn--rect btn-outline-danger"
              disabled={submitting}
            >
              Создать
            </button>
          </div>
        </div>
      </form>
    </Content>
  );
};

const initValuesConnect = connect(state => ({
  initialValues: {
    contact: get(state, ['user', 'info', 'FIO'], ''),
    phone: get(state, ['user', 'info', 'MobilePhone'], ''),
    email: get(state, ['user', 'info', 'EMail'], ''),
  },
}));

export default compose(
  withApi,
  initValuesConnect,
  reduxForm({
    form: FORM_IQUOTE,
    validate,
  }),
)(CreateIquote);
