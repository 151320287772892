import React from 'react';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import expiredColumns from './common/columns/expiredColumns';
import withApi from 'hoc/withApi';

const defaultSorted = [
  {
    id: 'accountingDate',
    desc: true,
  },
];

const Expired = () => (
  <Content title="Документы с просроченным сроком возврата">
    <div className="finances-list">
      <TableList columns={expiredColumns} defaultSorted={defaultSorted} />
    </div>
  </Content>
);

export default withApi(Expired);
