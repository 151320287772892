// @ts-nocheck
import React, { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Scroller } from 'common/ui/scroller';
import { Spinner } from 'common/ui/spinner';
import { DownloadListItem } from './download-list-item';

import styles from './download-list-body.module.scss';

export const DownloadListBody = () => {
  const {
    select: {
      newDownloadList: { getDownloadList, getFetching },
    },
  } = useStore();
  const {
    newDownloadList: { fetchDownloadList },
  } = useDispatch();

  const downloadList = useSelector(getDownloadList);
  const fetching = useSelector(getFetching);

  useEffect(() => {
    fetchDownloadList();
  }, [fetchDownloadList]);

  const notFiles = (
    <p>В этом списке будут отображаться файлы документов для скачивания, которые вы запросили.</p>
  );

  const defaultText = (
    <p>
      Не все файлы доступны для моментального скачивания. Некоторые нужно готовить. <br /> В этом
      списке — подготовленные для скачивания файлы.
    </p>
  );

  return (
    <>
      {isEmpty(downloadList) ? notFiles : defaultText}
      <Scroller
        className={cn(styles['download-list-body'], {
          [styles['download-list-body--empty']]: downloadList.length === 0 && !fetching,
          [styles['download-list-body--fetching']]: downloadList.length === 0 && fetching,
        })}
        withScrollTrackPadding
      >
        {fetching && downloadList.length === 0 ? (
          <Spinner center size="xxl" />
        ) : (
          <>
            {Object.values(downloadList)
              .slice(0, 14)
              .map(item => (
                <DownloadListItem key={item.orderNo} data={item} />
              ))}
          </>
        )}
      </Scroller>
    </>
  );
};
