import React from 'react';
import classNames from 'classnames';

const Dot = props => {
  const { active, onSelectSlide } = props;

  const className = classNames('banner__dot', {
    'banner__dot--active': active,
  });

  return <div className={className} onClick={onSelectSlide} />;
};

export default Dot;
