import React, { Fragment, useState } from 'react';
import ReactModal from 'react-modal';
import ModalContentNewsLetter from './modal-content-newsletter';
import classNames from 'classnames';
import { isCactus } from 'common/config';

const classModal = classNames('Modal', 'Modal__newsletter', { 'Modal--cactus': isCactus });

const Newsletter = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <Fragment>
      <div className="newsletter">
        <button className="btn btn-outline-danger btn--rect newsletter__button" onClick={openModal}>
          Подписаться
        </button>
        <span className="newsletter__description">
          Вы можете подписаться на рассылку уведомлений о доставке
        </span>
      </div>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className={classModal}
        overlayClassName="Overlay Overlay__columns"
        ariaHideApp={false}
      >
        <ModalContentNewsLetter closeModal={closeModal} />
      </ReactModal>
    </Fragment>
  );
};

export default Newsletter;
