const regExpDefault = new RegExp('\\.', 'g');

const formattingByMask =
  (mask, serchRegExp = regExpDefault) =>
  value => {
    const onlyNumbers = value.replace(serchRegExp, '');
    let i = 0;
    let lastReplacedIndex = -1;
    const fieldMask = mask.replace(/#/g, (_, j) => {
      if (i >= onlyNumbers.length) {
        return '#';
      }
      lastReplacedIndex = j;
      return onlyNumbers[i++];
    });
    return fieldMask.substring(0, lastReplacedIndex + 1);
  };

export default formattingByMask;
