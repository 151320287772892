import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Preloader from 'components/preloader';
import AppSocket from 'components/AppSocket';
import ActionItem from './ActionItem';
import useFlexPagination from '../../../hooks/useFlexPagination';
import Pagination from './Pagination';
import Mixpanel from 'services/mixpanel';
import { useDispatch, useSelector } from 'react-redux';
import { getActionsForNews } from 'pages/Marketing/selectors';
import { getEffectLoading } from 'common/selectors';

const ActionsList = () => {
  const actions = useSelector(getActionsForNews);
  const loadingActions = useSelector(getEffectLoading('actions', 'get'));
  const {
    actions: { get, accept, receive },
  } = useDispatch();

  useEffect(() => {
    get();
  }, []);

  const [result, actionsRef, handleChangePage] = useFlexPagination(actions, 100);
  const { elements, pages, page } = result;
  const showPagination = !!elements.length && !loadingActions;

  return (
    <div className="actions-list">
      <AppSocket channel="queue-stat" onReceive={receive}>
        <div className="news-list news-list--mini news-list__row">
          <h5>
            <i className="icon icon-news" />
            <Link
              to="/actions"
              onClick={() => {
                Mixpanel?.track('СтрГлавнаяСтраница.Акции', {
                  page: 'СтрГлавнаяСтраница',
                });
              }}
            >
              Акции
            </Link>
          </h5>
          {loadingActions && <Preloader />}

          {!loadingActions && (
            <div ref={actionsRef} className="item-list__container">
              {(elements.length &&
                elements.map((elem, index) => {
                  const show = page - 1 === index ? 'block' : 'none';
                  const style = { display: show };

                  return (
                    <ul style={style} key={index}>
                      {elem.map((action, indexElem) => (
                        <ActionItem
                          key={action.no}
                          action={action}
                          accept={accept}
                          index={Number(`${index}${indexElem}`)}
                        />
                      ))}
                    </ul>
                  );
                })) || (
                <p>
                  Нет активных акций. Перейти в <Link to="/actions/archive">архив</Link>
                </p>
              )}
            </div>
          )}
          {showPagination && (
            <div className="news-list__pagination">
              <Pagination
                page={page}
                pages={pages}
                onChangePage={handleChangePage}
                parent={'Акции'}
              />
            </div>
          )}
        </div>
      </AppSocket>
    </div>
  );
};

export default ActionsList;
