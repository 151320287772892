// @ts-nocheck
import React, { useCallback } from 'react';
import { dateToRU } from 'common/util';
import { CalendarInput } from 'common/ui/calendar-input';
import { useDesign } from 'hooks/new';
import { validateDate } from 'utils/new/date';
import CustomDayPickerInput from 'components/customDayPickerInput';

export const CustomDatePicker = ({
  input: { value, onChange },
  onDateChange,
  label,
  disabled,
}) => {
  const [isPilot, isShpd] = useDesign();
  const handleDateInputChange = useCallback(day => {
    const date = dateToRU(day);

    if (isShpd) {
      onChange(validateDate(date) ? date : null);
      onDateChange(validateDate(date) ? date : null);
    } else {
      onChange(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <label>{label}</label>

      {isPilot ? (
        <CalendarInput
          value={value}
          inputProps={{ disabled }}
          onChange={handleDateInputChange}
        />
      ) : (
        <CustomDayPickerInput
          value={value}
          inputProps={{ disabled }}
          dayPickerProps={{
            disabledDays: [{ before: new Date() }],
          }}
          onDateInputChange={handleDateInputChange}
        />
      )}
    </div>
  );
};
