import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Eye = ({
  className,
  color = '#000',
  name,
  width = '26',
  height = '15',
  viewBox = '0 0 26 15',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M13 0.5C6.1 0.5 0.5 6.8 0.5 8.1C0.5 9.4 6.1 14.5 13 14.5C19.9 14.5 25.5 9.3 25.5 8.1C25.5 6.9 19.9 0.5 13 0.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M13 11.5C15.2091 11.5 17 9.70914 17 7.5C17 5.29086 15.2091 3.5 13 3.5C10.7909 3.5 9 5.29086 9 7.5C9 9.70914 10.7909 11.5 13 11.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Eye.defaultProps = {
  className: '',
  color: '#000',
  width: '26',
  height: '15',
  viewBox: '0 0 26 15',
};

Eye.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
