import { API_URL } from 'common/config/app';

export const orderApi = {
  'new-order/view/([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/orders`,
    success: 'order/load'
  },
  'new-order/header/([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v2/orders/index/header`,
  },
  'new-order/save/([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v1/orders/edit/update`,
  },
  'new-order/delete/row/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/delete-row`,
  },
  'new-order/representatives$': {
    endpoint: `${API_URL}/v1/orders/edit/representatives`,
  },
  'new-order/shipment-dates$': {
    endpoint: `${API_URL}/v1/orders/edit/shipment-dates`,
  },
  'new-order/shipment-agent-delivery-intervals': {
    endpoint: `${API_URL}/v1/orders/edit/shipment-agent-delivery-intervals`,
  },
  'new-order/settings/get/?([a-zA-Z0-9]+)?$': {
    endpoint: `${API_URL}/settings/orders`,
  },
};
