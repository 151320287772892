import React, { useMemo, useState, useCallback, useEffect } from 'react';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import defaultSelect from './constants/default-select';
import createOptionsForFilter from './helpers/create-options';

const FilterList = props => {
  const { list, label, filterBy, filtered, onUpdateFiltered } = props;
  const [selected, setSelected] = useState(defaultSelect);

  const createFilterContactOptions = useMemo(
    () => createOptionsForFilter(list, filterBy),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list.length],
  );

  const handleChange = select => {
    const { value } = select;
    const filter = { id: filterBy, value };
    setSelected(select);
    updateFiltered(filter);
  };

  useEffect(() => {
    if (isEmpty(filtered)) {
      setSelected(defaultSelect);
    }
  }, [filtered]);

  const updateFiltered = useCallback(
    selectedFilter => {
      const hasFilter = filtered.some(filter => filter.id === selectedFilter.id);

      const updatedFiltered =
        isEmpty(filtered) || !hasFilter
          ? [...filtered, selectedFilter]
          : filtered.map(filter => (filter.id === selectedFilter.id ? selectedFilter : filter));

      onUpdateFiltered(updatedFiltered);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtered],
  );

  return (
    <div className="flex-nowrap align-items-center order-filters filter-select">
      <label className="filter-select__label">{label}</label>
      <Select
        searchable={false}
        clearable={false}
        wrapperStyle={{ width: 180 }}
        value={selected}
        options={createFilterContactOptions}
        onChange={handleChange}
      />
    </div>
  );
};

export default FilterList;
