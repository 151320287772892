import React, { useEffect, useState, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { checkOverflow } from 'common/util';

const TooltipCell = ({ index, children }) => {
  const [isPlaced, setIsPlaced] = useState(true);
  const block = useRef(null);

  useEffect(() => {
    setIsPlaced(!checkOverflow(block.current));
  }, []);

  return (
    <>
      <div className="cell__tooltip" data-for={`popup${index}`} data-tip="React-tooltip">
        <div ref={block}>{children}</div>
      </div>
      <ReactTooltip
        id={`popup${index}`}
        getContent={() => <span>{children}</span>}
        disable={isPlaced}
        place="top"
        effect="float"
      />
    </>
  );
};

export default TooltipCell;
