import React from 'react';
import Content from 'components/layout/content';
import { formattingOrderType } from 'pages/VAD/helper/util';
import { useSelector, useStore } from 'react-redux';
import withApi from 'hoc/withApi';
import AddCommentForm from 'pages/VAD/components/form/comments-form';
import CommentsList from 'pages/VAD/components/common/components/comments-list';
import DemofondOrder from './components/demofond-order';
import DemofondInfo from './components/demofond-info';

const DemofondItem = () => {
  const {select: { demo }} = useStore()
  const order = useSelector(demo.getOrder)
  const { comments, type, projectNo } = order

  const title = `${formattingOrderType(type)} ${projectNo}`

  return (
    <div className='detail-view'>
      <Content title={title} className="demofond-detail" style={{background: 'none'}}>
        <DemofondInfo demofondOrder={order} />
        <DemofondOrder demofondOrder={order} />
      </Content>
      <CommentsList comments={comments}>
        <AddCommentForm type="demo" />
      </CommentsList>
    </div>
  );
};

export default withApi(DemofondItem);
