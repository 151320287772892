import React, { useCallback, useMemo, useState } from 'react';
import ReactModal from 'react-modal';
import DemofondOrderForm from '../../../containers/demofond-order-form';
import alert from 'components/native/alert/function';
import { demofondModeContext } from 'pages/VAD/context';
import classNames from 'classnames';
import { DEMOFOND_TYPE_TEST, isCactus } from 'common/config';
import useContextSelector from 'hooks/context/useContextSelector';
import { demoCollector } from 'pages/VAD/constants';

const classModal = classNames('Modal', { 'Modal--cactus': isCactus });

const OrderActions = () => {
  const [isTestOpen, setIsTestOpen] = useState(false);
  const [isBuyOpen, setIsBuyOpen] = useState(false);

  const demofondMode = useContextSelector(demofondModeContext, state => state.demofondMode);

  const toggleModal = useCallback(() => {
    if (!demoCollector.isEmpty()) {
      demofondMode === DEMOFOND_TYPE_TEST ? setIsTestOpen(!isTestOpen) : setIsBuyOpen(!isBuyOpen);
    } else {
      alert('Не выбрано ни одного товара!');
    }
  }, [demofondMode, isTestOpen, isBuyOpen]);

  const modalProps = useMemo(
    () => ({
      onRequestClose: toggleModal,
      contentLabel: 'Modal',
      className: classModal,
      overlayClassName: 'Overlay Overlay__columns',
      ariaHideApp: false,
    }),
    [toggleModal],
  );

  return (
    <span className="demofund-orders demofond__buttons">
      <button type="button" className="btn btn-danger" onClick={toggleModal}>
        {(!demofondMode && 'Заявка на тестирование') || 'Купить оборудование'}
      </button>
      <ReactModal isOpen={isTestOpen} {...modalProps}>
        <DemofondOrderForm
          closeModal={toggleModal}
          isDemoOrder
          title="Заказ оборудования для тестирования"
        />
      </ReactModal>
      <ReactModal isOpen={isBuyOpen} {...modalProps}>
        <DemofondOrderForm closeModal={toggleModal} title="Заказать покупку оборудования" />
      </ReactModal>
    </span>
  );
};

export default OrderActions;
