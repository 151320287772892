import checkFormatTime from '../constances/checkFormatTime';

const intervalMin = 30;

const addStepInterval = (time, isIncrease = true) => {
  if (!checkFormatTime.test(time)) return time;

  const [hours, minutes] = time.split(':');
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  if (isIncrease) {
    date.setMinutes(parseInt(minutes) + parseInt(intervalMin));
  } else {
    date.setMinutes(parseInt(minutes) - parseInt(intervalMin));
  }

  let newMinutes = date.getMinutes();
  let newHours = date.getHours();

  if (newMinutes < 10) newMinutes = `0${newMinutes}`;
  if (newHours < 10) newHours = `0${newHours}`;

  return [newHours, newMinutes].join('');
};

export default addStepInterval;
