import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

/**
 * Hook to deep compare dependencies instead of Object.is
 *
 * @param {React.EffectCallback} effect
 * @param {any[]} deps - dependencies array to observe
 * @param {boolean} runAfterFirst - whether effect should run after initial render
 * @returns {void}
 */
const useDeepEffect = (effect, deps, runAfterFirst = true) => {
  const isFirst = useRef(runAfterFirst);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isSame = prevDeps.current.every((obj, index) => isEqual(obj, deps[index]));

    if (isFirst.current || !isSame) {
      effect();
    }

    isFirst.current = false;
    prevDeps.current = deps;
  }, [deps, effect]);
};

export default useDeepEffect;
