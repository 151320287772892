import { FLUSH_STATE, SET_STATE } from 'pages/Orders/action/state';
import { dateToDB } from 'common/util';
import { SET_EVENTS_BRAND, SET_EVENTS_DATE, SET_EVENTS_REGION } from './actions';

export const initialEventsState = {
  regionValue: '',
  brandValue: '',
  filtered: [],
  selectedDay: null,
};

export default (state, action) => {
  const newValue = action.payload?.value;

  switch (action.type) {
    case SET_EVENTS_REGION:
      return {
        ...state,
        regionValue: newValue,
        filtered: newValue
          ? [...state.filtered, { id: 'city', value: newValue }]
          : state.filtered.filter(elem => elem.id !== 'city'),
      };
    case SET_EVENTS_BRAND:
      return {
        ...state,
        brandValue: newValue,
        filtered: newValue
          ? [...state.filtered, { id: 'brand', value: newValue }]
          : state.filtered.filter(elem => elem.id !== 'brand'),
      };
    case SET_EVENTS_DATE: {
      const { day, selected } = action.payload;
      const newValue = dateToDB(day);

      return {
        ...state,
        selectedDay: selected ? null : day,
        filtered:
          day && !selected
            ? [...state.filtered, { id: 'start', value: newValue }]
            : state.filtered.filter(elem => elem.id !== 'start'),
      };
    }
    case FLUSH_STATE:
      return {
        ...state,
        regionValue: '',
        brandValue: '',
        filtered: [],
        selectedDay: null,
      };
    case SET_STATE:
      return {
        ...state,
        ...action.payload,
      };
  }
};
