import { Cookies } from 'services/cookies';

export default ({ B2BVersion }) => {
  const frontVersion = Cookies.get('ui_version')
  const backVersion = B2BVersion == 1 ? 'main' : 'new'

  if(frontVersion === backVersion || !frontVersion && backVersion === 'new'){
    return false
  }

  Cookies.setForever('ui_version', backVersion);

  location.reload(true);
};
