import { createSelector } from 'reselect';
import get from 'lodash/get';
import { DEFAULT_PAGE_SIZE } from 'common/config';
import { memoize } from 'lodash';

export const getEffectLoading = (model, effect) => state =>
  state.loading.effects[model][effect] || false;
export const getModelLoading = model =>
  createSelector(
    state => state.loading.effects[model] || {},
    model => Object.values(model).some(effect => effect) || false,
  );

export const getListData = state => get(state, ['list', 'data'], []);

export const getTableListData = createSelector(getListData, data =>
  Array.isArray(data) ? data : [],
);

export const getPageSizePersisted = state => get(state, ['listSettings', 'pageSize'], {});
export const getPageSize = createSelector(getPageSizePersisted, pageSize =>
  memoize(type => pageSize[type] || DEFAULT_PAGE_SIZE),
);
