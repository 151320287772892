import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

const SubItems = ({ childs = [] }) => {
  return (
    <ul>
      {childs.map((child, key) => (
        <li key={key}>
          <NavLink to={`/category/${child.ID}`} className="link link--underlined">
            {child.Name}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

const Breadcrumb = ({ childs, parents }) => {
  if (!parents) return null;

  return (
    <ul className="wiki-menu">
      {parents.map(({ Name, ID }) =>
        childs?.[ID]?.length ? (
          <li>
            <span>{Name}</span>
            <SubItems childs={childs[ID]} />
          </li>
        ) : (
          <li>
            <a href="#" className="link link--underlined">
              {Name}
            </a>
          </li>
        ),
      )}
    </ul>
  );
};

const mapStateToProps = state => ({
  childs: state.wiki.children,
  parents: state.wiki.parents,
});

export default connect(mapStateToProps)(Breadcrumb);
