import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PREORDERS_EDIT, preorderTypes } from '../../../../constances/preorder';
import EnabledMoveToAnotherOrderButton from './EnabledMoveToAnotherOrderButton';
import EnabledRemoveButton from './EnabledRemoveButton';
import EnabledExcelButton from './EnabledExcelButton';
import EnabledSignatureButton from './EnabledSignatureButton';
import OrdersJoinButton from './OrdersJoinButton';

const EnabledButtons = props => {
  const { row } = props;
  const isPreorder = +row.docType === +preorderTypes.docType && row.tender === preorderTypes.tender;
  const to = isPreorder ? `${ROUTE_PREORDERS_EDIT}${row.orderNo}` : `/orders/edit/${row.orderNo}`;

  return (
    <React.Fragment>
      <i title="Отгрузки по заказу" className="icon icon-logistic-dis" />
      <Link to={to} className="icon icon-edit_new" title="Редактирование заказа (Подбор товара)" />
      <EnabledMoveToAnotherOrderButton {...props} />
      <EnabledRemoveButton {...props} />
      <EnabledExcelButton {...props} />
      <EnabledSignatureButton {...props} />
      <OrdersJoinButton {...props} />
    </React.Fragment>
  );
};

export default EnabledButtons;
