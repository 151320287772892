import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { THEME_COLORS } from 'common/constants/styles';

import styles from '../icon.module.scss';

export const Cable = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M8.28244 3.80573L7.25342 2.80365L8.62545 1.46753L9.65447 2.46962"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M10.1003 6.24422L8.17943 4.37366C8.04223 4.24005 8.04223 4.03963 8.14513 3.93942L9.79157 2.33608C9.89447 2.23587 10.1003 2.23587 10.2375 2.36948L12.1583 4.24004C12.2955 4.37366 12.2955 4.57407 12.1926 4.67428L10.5462 6.27762C10.4433 6.41123 10.2375 6.37783 10.1003 6.24422Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M10.855 6.31104L13.2217 8.61584C13.6676 9.05007 13.942 9.65133 13.942 10.2526V16.9666"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M12.2271 4.97485L14.9368 7.61368C15.3827 8.04792 15.6571 8.64918 15.6571 9.25043V16.9665"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M4.50949 7.48005L3.48047 6.47796L4.8525 5.14185L5.88152 6.14393"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M6.32732 9.91854L4.40648 8.04797C4.26928 7.91436 4.26928 7.71394 4.37218 7.61373L6.01862 6.01039C6.12152 5.91019 6.32732 5.91019 6.46453 6.0438L8.38537 7.91436C8.52257 8.04797 8.52257 8.24839 8.41967 8.3486L6.77323 9.95194C6.67033 10.0856 6.46453 10.0521 6.32732 9.91854Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M7.08203 9.98535L9.44879 12.2902C9.8947 12.7244 10.1691 13.3256 10.1691 13.9269V16.9666"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M8.4541 8.64917L11.1639 11.288C11.6098 11.7222 11.8842 12.3235 11.8842 12.9247V16.9665"
        stroke={color}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

Cable.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Cable.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
