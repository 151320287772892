import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const New = ({
  className,
  color = '#008000',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M1.58047 8.87324L1.0809 7.94366H1.03548L1.18081 8.87324V11.9577H0V6H0.908317L3.31536 9.15211L3.79676 10.0563H3.84218L3.69685 9.15211V6.04225H4.87766V12H3.96934L1.58047 8.87324Z"
        fill={color}
      />
      <path
        d="M5.9697 6.04225H9.73921V7.07324H7.21409V8.4507H9.51213V9.48169H7.21409V10.9268H9.78463V11.9577H5.9697V6.04225Z"
        fill={color}
      />
      <path
        d="M12.3684 9.34648L12.4865 10.3944H12.5228L12.65 9.32958L13.7582 6.04225H14.5756L15.6656 9.34648L15.7928 10.3944H15.8291L15.9654 9.32958L16.7374 6.04225H18L16.2469 12H15.4113L14.2759 8.71268L14.1215 7.80845H14.0761L13.9217 8.72113L12.7953 12H11.9143L10.1703 6.04225H11.5237L12.3684 9.34648Z"
        fill={color}
      />
    </svg>
  );
};

New.defaultProps = {
  className: '',
  color: '#008000',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

New.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
