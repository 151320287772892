import React, { useEffect, useCallback, useRef } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import OrderEditHeaderForm from 'pages/Orders/containers/order-header-form';
import useLocalForage from '../../../hooks/use-local-forage';
import HeaderInfo from './common/header-info';
import OrderSwitcher from './common/order-switcher';
import classNames from 'classnames';
import { ROUTE_PREORDERS_NEW, ROUTE_PREORDERS_EDIT } from '../constances/preorder';
import { isEmpty, isEqual } from 'lodash';
import { isCactus } from 'common/config';
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';
import { flushState } from 'pages/Orders/action/state';
import { CARD_PAGE_PATH } from 'common/routes/constants';
import { getOrder } from 'pages/Orders/selectors';
import { OrderEditContext } from 'pages/Orders/components/order-edit/context';
import useContextDispatch from 'hooks/context/useContextDispatch';
import FormContent from 'pages/Orders/components/order-edit/header-form/components/form-content';
import cache from 'services/cache';
import { CACHE_ORDER_LIST } from 'pages/new/orders/constants/api-cache';
import { useDesign } from 'hooks/new';
import { ID_ORDER_HEADER_FORM } from '../constances';
import { preparedData } from 'pages/Orders/components/order-edit/helpers/prepareData';
import {getDiff} from "common/util"
import useDeepEffect from "hooks/use-deep-effect"

const classModal = classNames('Modal', 'Modal__order-edit-form', { 'Modal--cactus': isCactus });

const headerInfoFields = [
  'shipmentInfo',
  'priceRUB',
  'weight',
  'volume',
  'paymentCode',
  'dueDate',
  'contractorName',
];

const OrderHeader = props => {
  const {
    user,
    shipmentEndpointRows,
    counterAgentRows,
    shipmentAgentRows,
    packingRows,
    shipmentMethodRows,
    isHeaderModalOpened,
  } = props;

  const stateDispatch = useContextDispatch(OrderEditContext);

  const isNotPreorder = !useIsPreorder();
  const { pathname } = useLocation();
  const { id } = useParams();
  const history = useHistory();

  const order = useSelector(getOrder);
  const dispatch = useDispatch();
  const { items } = dispatch;

  const [forageOrder, setForageOrder] = useLocalForage('location.state', null);

  const IS_ORDER_EDIT = pathname.includes('edit');
  const IS_ORDER_NEW = pathname.includes('new');
  const IS_CARD = pathname.includes(CARD_PAGE_PATH);

  const to = isNotPreorder ? '/orders/new' : ROUTE_PREORDERS_NEW;
  const newOrderText = isNotPreorder ? 'Новый заказ' : 'Новый предзаказ';

  const showCurrent = useCallback(
    async e => {
      e.preventDefault();

      if (IS_CARD || !IS_ORDER_EDIT) {
        const toEdit = isNotPreorder ? '/orders/edit/' : ROUTE_PREORDERS_EDIT;

        return history.push(`${toEdit}${order.orderNo}`);
      }

      await items.getByOrder({ id: id || order.orderNo, isNotPreorder });

      stateDispatch(flushState());
    },
    [id, isNotPreorder, pathname, order.orderNo, IS_ORDER_EDIT, IS_CARD],
  );

  useEffect(() => {
    IS_ORDER_NEW && setForageOrder(null)
          .then(() => dispatch.order.setHeader({}))
  }, [IS_ORDER_NEW]);

  useDeepEffect(() => {
    if(!IS_ORDER_NEW){
      const isPreOrder = parseInt(order?.tender) === 4;
      const isPreOrderForage = parseInt(forageOrder?.tender) === 4;

      const newOrder =
        (((isPreOrder && !isNotPreorder) || (isNotPreorder && !isPreOrder)) && order) || null;
      const newForageOrder =
        (((isPreOrderForage && !isNotPreorder) || (isNotPreorder && !isPreOrderForage)) &&
          forageOrder) || {};

      const {hidden, ...localOrder} = newForageOrder

      if (!isEmpty(newOrder) && !isEqual(newOrder, localOrder)) {
        setForageOrder({ ...newOrder, hidden: true });
      }
      else if(!isEmpty(localOrder)){
        dispatch.order.setHeader(localOrder)
      }
    }
  }, [order, forageOrder, isNotPreorder, IS_ORDER_NEW]); // eslint-disable-line

  const handleFormClose = useCallback(() => dispatch.order.toggleCreateForm(), []); // eslint-disable-line

  /**
   * socket events callbacks
   */
  const onReceive = useCallback(
    async ({ data }) => {
      await items.onReceive({ data, pathname, id });

      if (data?.fix?.done && data?.success) {
        preparedData.clear();
      }
    },
    [pathname, id],
  );

  const onBroadcast = useCallback(
    async ({ data }) => {
      await items.onBroadcast({ data, pathname, id });
      await cache.clear(CACHE_ORDER_LIST);
    },
    [pathname, id],
  );

  const onConnect = useCallback(
    ({ subscription }) => {
      items.onConnect({ subscription, pathname });
    },
    [pathname],
  );

  const onNotify = useCallback(
    ({ data }) => {
      items.onNotify({ message: data, pathname, id });
    },
    [pathname, id],
  );
  /**
   * end socket events callbacks
   */

  return (
    <div
      className="actions-container align-items-center actions-container--no-bg"
      id="order-header-container"
      style={{ margin: 0 }}
    >
      <div
        className={classNames(
          'order-edit-action',
          'event-action',
          'event-actions__dashed-columns',
          'align-items-center',
          'event-actions__order',
        )}
      >
        {id ? (
          <Link
            to={{
              pathname: to,
              state: {
                hidden: true,
              },
            }}
            className={classNames('section-nav', 'section-nav__no-underline', 'section-nav--upper')}
          >
            <i className="icon-create">+</i>
            {newOrderText}
          </Link>
        ) : (
          <Link
            to="/orders"
            className={classNames('section-nav', 'section-nav__no-underline', 'section-nav--upper')}
          >
            <i className="icon icon-back-icon" />
            Список заказов
          </Link>
        )}
        <div className="no-dash">
          <a
            title={(id && 'Редактирование заголовка заказа') || 'Создание заголовка заказа'}
            href=""
            className={classNames(
              'section-nav',
              'section-nav__no-underline',
              'section-nav--dashed',
              'section-nav__drop-arrow',
            )}
            onClick={e => {
              e.preventDefault();
              dispatch.order.toggleCreateForm();
            }}
          >
            ЗАКАЗ
          </a>

          <OrderEditHeaderForm
            onReceive={onReceive}
            onBroadcast={onBroadcast}
            onConnect={onConnect}
            onNotify={onNotify}
            onSubmit={async formData => {
              const success = await dispatch.order.handleOrder({ formData });

              if (success) {
                handleFormClose();
              }
            }}
          >
            {props => (
              <ReactModal
                ariaHideApp={false}
                isOpen={isHeaderModalOpened}
                onRequestClose={handleFormClose}
                contentLabel="Modal"
                className={classModal}
                overlayClassName="Overlay Overlay__columns"
                shouldCloseOnOverlayClick={false}
                parentSelector={() => document.getElementById(ID_ORDER_HEADER_FORM)}
              >
                <FormContent {...props} onCancel={handleFormClose} />
              </ReactModal>
            )}
          </OrderEditHeaderForm>
        </div>
        <OrderSwitcher
          orderNo={(order && order.orderNo) || 'NEW'}
          className="no-dash col-md-5 order-switcher"
        />
      </div>
      <div className="order-header">
        <HeaderInfo
          order={order}
          user={user}
          visible={headerInfoFields}
          counterAgentRows={counterAgentRows}
          shipmentAgentRows={shipmentAgentRows}
          shipmentEndpointRows={shipmentEndpointRows}
          packingRows={packingRows}
          shipmentMethodRows={shipmentMethodRows}
          className={classNames(
            'actions-container',
            'actions-container--no-bg',
            'actions-container--order-header',
            'actions-container__header',
          )}
        />
      </div>
      <div className="order-current">
        {order?.orderNo && (
          <a href="#" onClick={showCurrent} className={classNames('link', 'link__current')}>
            <i className="icon icon-pushcart" />
            ТЕКУЩИЙ ЗАКАЗ
          </a>
        )}
      </div>
    </div>
  );
};

OrderHeader.whyDidYouRender = false;
export default OrderHeader;
