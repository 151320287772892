import React from 'react';
import Menu from '../menu';
import Logout from 'pages/Login/components/logout';
import UserInfo from './user-info';
import UserContacts from 'pages/Help/components/contacts/components/ContactsWidget';
import UserBalance from './user-balance';
import { Link } from 'react-router-dom';

import cactusLogo from 'img/cactus-logo.png';
import mainLogo from 'img/logo_top.gif';
import { isCactus } from 'common/config';

const logo = isCactus ? cactusLogo : mainLogo;

const Header = () => {
  return (
    <div>
      <div className="userInfo" id="user-info">
        <div>
          <Link to="/">
            <img src={logo} alt="" className="logo" />
          </Link>
        </div>
        <div>
          <Logout />
        </div>
        <div>
          <UserInfo />
        </div>
        <UserBalance />
        <div className="header__contacts-widget">
          <UserContacts />
        </div>
      </div>
      <div className="mainMenu" id="main-menu">
        <Menu />
      </div>
      <h1 className="title">{}</h1>
    </div>
  );
};

export default Header;
