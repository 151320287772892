export { default as addGoods } from './addGoods';
export { default as deleteGood } from './deleteGood';
export { default as fetchGoods } from './fetchGoods';
export { default as selectOrder } from './selectOrder';
export {default as receive} from './onReceive';
export {default as silentFetchGoods} from './fetchGoods'

export function fetchGoodsInOrders({ api, newOrdersNew }) {
  return async (payload, state) => {
    const query = state.newOrdersNew.orders.map(order => `orders[]=${order.orderNo}`).join('&');

    try {
      const items = await api.get({
        url: `new-orders-new/goods`,
        query: `?${query}`,
      });

      newOrdersNew.setGoods(items);
    } catch {
      return null;
    }
  };
}
