const getMinutes = time => {
  if (time) {
    const [hours, minutes] = time.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
  }
  return 0;
};

const checkAvailableTime = (timeFrom, timeTo, interval, timeToMin, timeToMax) => {
  const midnight = 0;
  const allowableTime = `Допустимое время отгрузки заказа с ${timeToMin} до ${timeToMax}`;
  const errorTime = {
    hasError: false,
    message: '',
  };

  const min = getMinutes(timeToMin);
  const max = getMinutes(timeToMax);
  const from = getMinutes(timeFrom);
  const to = getMinutes(timeTo);

  if (from < min) {
    errorTime.hasError = true;
    errorTime.message = allowableTime;
    return errorTime;
  }

  if (to > max) {
    errorTime.hasError = true;
    errorTime.message = allowableTime;
    return errorTime;
  }

  if (to === midnight) {
    errorTime.hasError = true;
    errorTime.message = allowableTime;
    return errorTime;
  }

  if (Math.abs(to - from) < interval * 60) {
    errorTime.hasError = true;
    errorTime.message = `Промежуток времени доставки не может быть менее ${interval} ч.`;
    return errorTime;
  }

  if (from > to) {
    errorTime.hasError = true;
    errorTime.message = allowableTime;
    return errorTime;
  }

  return errorTime;
};

export default checkAvailableTime;
