import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'pages/Orders/selectors';
import { routesConfig } from 'common/collections';
import renderLinks from 'utils/renderLinks';
import LinkOldVersion from './link-old-version';

const Menu = () => {
  const { menu, permissions } = useSelector(getUser);

  let userPermissions = [];
  let userMenu = [];

  try {
    userPermissions = JSON.parse(permissions);
    userMenu = JSON.parse(menu);
  } catch (e) {
    console.log(e); // eslint-disable-line
  }

  const navLinks = useMemo(() =>
    renderLinks(Object.values(routesConfig.get()), userPermissions, userMenu)
  , [permissions, menu]); // eslint-disable-line

  return (
    <>
      <nav>{navLinks}</nav>
      <LinkOldVersion />
    </>
  );
};

export default Menu;
