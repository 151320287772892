// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Block } from '../block';

import styles from './hint.module.scss';

export const Hint = ({ hints }) => (
  <div className={styles['hint']}>
    {hints && hints.list.map(item => item.list.length > 0 && <Block key={item.id} item={item} />)}
  </div>
);

Hint.propTypes = {
  hints: PropTypes.shape({
    hasItems: PropTypes.bool.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        list: PropTypes.arrayOf(
          PropTypes.shape({
            available: PropTypes.string,
            calced: PropTypes.string,
            ean: PropTypes.string,
            group: PropTypes.string,
            id: PropTypes.string,
            name: PropTypes.string,
            partnumber: PropTypes.string,
            rank: PropTypes.string,
            sphinx_weight: PropTypes.string,
          }),
        ).isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};
