import React, { useEffect } from 'react';
import { demofondModeContext } from 'pages/VAD/context';
import { DEMOFOND_TYPE_TEST, DEMOFOND_TYPE_SALE } from 'common/config';
import useContextSelector from 'hooks/context/useContextSelector';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { useDispatch } from 'react-redux';
import { Cookies } from 'services/cookies';
import { DFTYPE } from '../../../constants';
import { changeDemofondMode } from '../../../action';

const DemofondSwitcher = () => {
  const demofondMode = useContextSelector(demofondModeContext, state => state.demofondMode);
  const dispatch = useContextDispatch(demofondModeContext);
  const {
    catalog: { clear },
  } = useDispatch();

  useEffect(() => {
    Cookies.set(DFTYPE, DEMOFOND_TYPE_TEST);

    return clear;
  }, []);

  const handleChangeMode = val => () => {
    Cookies.set(DFTYPE, val);
    dispatch(changeDemofondMode(val));
    clear();
  };

  return (
    <section className="demofond__switcher">
      <label className="demofond__switcher_item">
        <input
          type="radio"
          name="demofondMode"
          checked={demofondMode === DEMOFOND_TYPE_TEST}
          onChange={handleChangeMode(DEMOFOND_TYPE_TEST)}
        />
        <span className="demofond__switcher_item--checkmark" />
        Тестировать
      </label>
      <label className="demofond__switcher_item">
        <input
          type="radio"
          name="demofondMode"
          checked={demofondMode === DEMOFOND_TYPE_SALE}
          onChange={handleChangeMode(DEMOFOND_TYPE_SALE)}
        />
        <span className="demofond__switcher_item--checkmark" />
        Купить
      </label>
    </section>
  );
};

export default DemofondSwitcher;
