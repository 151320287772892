import { useSelector } from 'react-redux';
import { getRDCode } from 'features/user';
import useRouteConfig from 'hooks/use-route-config';

const SHPD = 'ШПД';

export const useDesign = (path = '') => {
  const route = useRouteConfig(path);

  const { props: routeProps = {} } = route;
  const rdCode = useSelector(getRDCode);

  const isPilot = !!routeProps.isNewLayout;
  const isShpd = rdCode === SHPD;

  return [isPilot, isShpd];
};
