/* eslint-disable no-console */
import React, { useCallback } from 'react';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import AppSocket from 'components/AppSocket';
import { useDispatch } from 'react-redux';
import columns from './columns';
import withApi from 'hoc/withApi';
import PeriodPicker from 'pages/Finances/components/common/period-picker';

const defaultSorted = [
  {
    id: 'operationNo',
    desc: true,
  },
];

const Payments = () => {
  const {
    list: { replace },
    finances: { getPayments },
  } = useDispatch();

  const getTdProps = useCallback(
    (state, row) => {
      if (!row) return {};

      return {
        get: getPayments,
      };
    },
    [getPayments],
  );

  return (
    <Content title="Графики платежей">
      <div className="finances-content">
        <AppSocket channel={'queue-payments'} onReceive={({ data }) => replace(data)}>
          <PeriodPicker
            type="payments"
            model="finances"
            effect="generatePayments"
            position="left"
          />
          <div className="finances-list payments-list">
            <TableList
              defaultSorted={defaultSorted}
              columns={columns}
              defaultPageSize={5}
              pageSize={5}
              showPagination={false}
              getTdProps={getTdProps}
            />
          </div>
        </AppSocket>
      </div>
    </Content>
  );
};

export default withApi(Payments);
