import { getUser } from 'pages/Orders/selectors';

export default store => next => action => {
  if (action.type.includes('PERSIST')) {
    const user = getUser(store.getState());

    action.payload = { ...action.payload, userId: user.id };
  }

  return next(action);
};
