import React, { useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Field } from 'redux-form';
import createFieldName from '../helpers/createFieldName';
import { DebouncedInput, RenderSelect } from 'components/fields';
import { undef } from 'common/util';
import { FILTER_BY_TILDA_VALUES } from '../constants';

export const RenderTextInputFilters = ({ filterGroup, show }) => {
  const dispatch = useDispatch();
  const inputId = `order-filter__group__${filterGroup.key}` + '__item__input_text';

  const filter = filterGroup.filters[0];
  const fieldName = createFieldName(filterGroup.key, filter.key);

  const handleChange = useCallback(value => {
    dispatch.filters.setTags({
      key: filterGroup.name,
      value,
      name: fieldName,
      defaultValue: '',
    });
  }, []); // eslint-disable-line

  return (
    <div className="col-sm" style={{ display: (!show && 'block') || 'none' }}>
      <Field
        id={inputId}
        name={fieldName}
        handle={handleChange}
        component={DebouncedInput}
        placeholder={filter.placeholder}
        onKeyDown={filter.onKeyDown}
        className={classNames(
          'form-control',
          'form-control-sm',
          'order-filter__group__item__text-field',
        )}
      />
    </div>
  );
};

export const RenderListFilters = ({ filterGroup }) => {
  const dispatch = useDispatch();
  const inputId = `order-filter__group__${filterGroup.key}` + '__item__other';

  const fieldName = createFieldName(filterGroup.key, 'other');
  const options = useMemo(
    () =>
      filterGroup.filters.reduce(
        (options, filter) =>
          ((undef(filter.show) || filter.show) && [
            ...options,
            {
              value: filter.key,
              label: filter.name,
            },
          ]) ||
          options,
        [],
      ),
    [filterGroup.filters],
  );

  const handleChange = useCallback(
    newVal =>
      dispatch.filters.setTags({
        key: 'other',
        value: newVal?.label,
        name: fieldName,
        defaultValue: 0,
      }),
    [],
  ); // eslint-disable-line

  return (
    <Field
      id={inputId}
      name={fieldName}
      options={options}
      placeholder="Дополнительный фильтр"
      className="col-sm"
      direction="top"
      handle={handleChange}
      clearable={true}
      searchable={true}
      defaultValue={0}
      component={RenderSelect}
    />
  );
};

const RenderCheckboxFilter = ({ filterGroup, show }) => {
  const dispatch = useDispatch();

  const handleChange = useCallback(e => {
    const {
      dataset: { group, key, id, code },
      name,
      checked,
    } = e.target;

    dispatch.filters.setTags({
      key: group,
      code: code,
      value: (checked && key) || null,
      groupId: id,
      name: name,
      defaultValue: false,
    });
  }, []); // eslint-disable-line

  return (
    <ul
      className={classNames(
        'order-filter__group__items',
        {
          ['order-filter__group__items' + `--${filterGroup.row}-columns`]: true,
        },
        { 'order-filter__group__items--hidden': show },
      )}
    >
      {filterGroup.filters.map((filter, index) => {
        const fieldName = createFieldName( filterGroup.key, filter.key );
        const checkboxId = `order-filter__group__${filterGroup.key}` + `__item__checkbox_${index}`;

        return (
          <li key={`item_row_${filter.key}_${index}`}>
            <Field
              key={`item_check_${filter.key}_${index}`}
              id={checkboxId}
              name={fieldName}
              component="input"
              type="checkbox"
              data-code={filter.key}
              data-group={filterGroup.name}
              data-key={filter.name}
              data-id={filterGroup.key}
              onChange={handleChange}
              value={filter.value}
              disabled={filter.disabled}
              className="order-filter__group__item__checkbox"
            />
            <label
              key={`item_check_label_${filter.key}_${index}`}
              htmlFor={checkboxId}
              className={classNames('noselect', 'order-filter__group__item__text')}
            >
              {filter.name}
              {typeof filter.count === 'number' && (
                <span className="order-filter__group__item__counter">{filter.count}</span>
              )}
            </label>
          </li>
        );
      })}
    </ul>
  );
};

RenderCheckboxFilter.displayName = 'CheckboxFilter';

export { RenderCheckboxFilter };
