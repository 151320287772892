import { API_URL } from 'common/config/app';
import {FILE_URL} from "common/config"

export const downloadListApi = {
  'new-download-list$': {
    endpoint: `${API_URL}/v1/file/index/last-requested`,
  },
  'new-download-list/file/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/file/get`,
  },
  'new-download-list/file/get$': {
    endpoint: `${FILE_URL}/file`
  },
};
