import { compose } from 'redux';
import convertFromData from 'helpers/convertFromData';
import { formattingDataShipingAgentForm } from 'pages/Logistic/helpers';
import alert from 'components/native/alert/function';
import { CACHE_NAME } from 'common/config';

export default {
  name: 'agents',
  state: [],
  reducers: {
    loadAgents: (state, { data }) => data.data,
  },
  effects: ({ api }) => ({
    async get() {
      await api.get({
        url: 'logistic/agents',
        cache: CACHE_NAME,
      });
    },
    async create(values) {
      const data = compose(convertFromData, formattingDataShipingAgentForm)(values);

      const { message = 'Форма отправлена' } = await api.post({
        url: 'logistic/agents/new',
        data,
      });

      await alert(`${message} <br> Заявка появится в списке через несколько минут.`);
    },
    async getBranches() {
      try {
        const { branches: options } = await api.get({
          url: 'shipments/branches',
          cache: CACHE_NAME,
        });

        /**
         * #TIP: for Async Select return object with options
         * https://github.com/JedWatson/react-select/blob/677dd4fe7b219954c934176756c4d6239751e9f7/src/Async.js#L106
         */
        return { options };
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    async getTrucks() {
      try {
        const { trucks } = await api.get({
          url: 'shipments/trucks',
          cache: CACHE_NAME,
        });

        return {
          options: trucks.map(({ code, vehicleWeight, cubage }) => ({
            value: code,
            label: `${code} (${vehicleWeight}кг, ${cubage}м3)`,
          })),
        };
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    async getAddress(value) {
      return await api.get({
        url: 'search/street',
        query: `text=${encodeURIComponent(value)}`,
      });
    },
  }),
};
