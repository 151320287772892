const createOptionsFromObj = obj => {
  const resultArr = [];
  for (const key in obj) {
    resultArr.push({
      label: key,
      value: obj[key],
    });
  }
  return resultArr;
};

export default createOptionsFromObj;
