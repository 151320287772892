/* eslint-disable no-unused-vars, react-hooks/rules-of-hooks */

import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { change, touch } from 'redux-form';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import MaskedTextInput from 'react-text-mask';
import { def } from 'common/util';

import { EditableSelect } from 'components/fields';
import { getEffectLoading } from 'common/selectors';
import { useNewLayout } from 'hooks/use-new-layout';
import { Icon } from 'common/ui/icon';
import { getCities, isEmailConfirmed } from '../../selectors';
import useRouteConfig from 'hooks/use-route-config';

export const renderPassword = ({
  input,
  label,
  required,
  disabled,
  placeholder,
  readonly,
  hasToogle = true,
  meta: { touched, error },
  className = 'col-sm-6',
}) => {
  const [type, setType] = useState('password');
  const toggle = useCallback(() => setType(type === 'input' ? 'password' : 'input'), [type]);

  return (
    <div className={className}>
      <label className={required ? 'required' : ''}>{label}</label>
      <div className="pass-control">
        <input
          {...input}
          name={input.name}
          value={input.value}
          type={type}
          className="form-control"
          placeholder={placeholder}
          disabled={!!disabled}
          readOnly={!!readonly}
          autoComplete="new-password"
        />
        {hasToogle && (
          <i
            className={cn('icon', 'enabled', {
              'icon-eye': type === 'input',
              'icon-eye-slash': type === 'password',
            })}
            onClick={toggle}
          />
        )}
        {touched && error && <span className="text-danger text-danger--small">{error}</span>}
      </div>
    </div>
  );
};

const Confirm = ({ confirmedStatus, name, value, confirm, isNew, pristine }) => {
  const dispatch = useDispatch();
  const fetching = useSelector(getEffectLoading('personal', 'confirmEmail'));

  const handleClick = e => {
    e.preventDefault();

    if (!value || !/.+@.+\..+/i.test(value)) {
      dispatch(touch('personalForm', name));
    } else {
      confirm(value);
    }
  };

  if (confirmedStatus === 'wait') {
    return <span className="confirmed confirmed--waiting">Ожидает подтверждения</span>;
  }

  if (confirmedStatus === '1' && !isNew && value) {
    return <span className="confirmed">Подтверждён</span>;
  }

  return (
    <button
      className="btn btn-outline-danger btn--rect btn-confirm"
      type="button"
      onClick={handleClick}
      disabled={fetching || !pristine}
    >
      Подтвердить
    </button>
  );
};

const ConfirmIconStatus = ({ confirmedStatus, isNew, value }) =>
  confirmedStatus === '1' && !isNew && value ? (
    <Icon name="done" width="14" height="14" className="icon-confirm" />
  ) : (
    <Icon name="warning" className="icon-confirm" />
  );

export const renderPersonalEmail = ({
  input,
  label,
  name,
  required,
  disabled,
  confirm,
  isNew,
  meta: { touched, error, pristine },
}) => {
  const isNewLayout = useNewLayout();
  const { path } = useRouteConfig();
  const isSignUp = path === '/sign-up';

  const confirmedStatus = useSelector(isEmailConfirmed);

  return (
    <div className="col-sm-6">
      <label htmlFor={name} className={required ? 'required' : ''}>
        {label}
      </label>
      <div className="email-field">
        <input {...input} name={name} type="email" className="form-control" disabled={!!disabled} />
        {!isSignUp && (
          <>
            <Confirm
              confirmedStatus={confirmedStatus}
              name={name}
              value={input.value}
              confirm={confirm}
              isNew={isNew}
              pristine={pristine}
              isNewLayout={isNewLayout}
            />
            {isNewLayout && (
              <ConfirmIconStatus
                confirmedStatus={confirmedStatus}
                isNew={isNew}
                value={input.value}
              />
            )}
          </>
        )}
      </div>
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </div>
  );
};

export const renderMasked = ({
  input: { onChange, value },
  label,
  name,
  required,
  disabled,
  mask,
  placeholder,
  meta: { touched, error },
}) => {
  return (
    <div className="col-sm-6">
      <label className={required ? 'required' : ''}>{label}</label>
      <div>
        <div className="email-field">
          <MaskedTextInput
            name={name}
            className="form-control"
            disabled={!!disabled}
            mask={mask}
            onChange={event => onChange(event.target.value.replace(/#(?=_)/, '').replace(/_/g, ''))}
            value={value}
            placeholder={placeholder}
          />
        </div>
        {touched && error && <span className="text-danger text-danger--small">{error}</span>}
      </div>
    </div>
  );
};

export const renderBirthdayField = ({
  input: { value, onChange },
  name,
  options,
  placeholder,
  style,
}) => {
  return (
    <Select
      name={name}
      options={options}
      value={value}
      onChange={({ value }) => {
        onChange(value);
      }}
      placeholder={placeholder}
      noResultsText="Сначала выберите месяц и год"
      clearable={false}
      searchable={false}
      autosize={true}
      style={style}
    />
  );
};

export const renderRegion = props => {
  const {
    input,
    name,
    style,
    label,
    placeholder,
    required,
    arrowRenderer,
    info: { region, regionId, regionName },
    meta: { touched, error },
  } = props;
  const dispatch = useDispatch();
  const {
    personal: { loadInfo, searchRegion, searchCity },
  } = dispatch;

  const fetchRegion = debounce(
    (input, callback) =>
      searchRegion(input)
        .then(items => callback(null, { options: items }))
        .catch(err => callback(err, null)),
    1100,
  );

  const getItems = (input, callback) => {
    if (!input || /[A-Za-z]/.test(input) || !input.trim()) {
      let options = (regionId && regionName && [{ id: regionId, text: regionName }]) || [];
      return Promise.resolve({ options: options });
    }

    fetchRegion(input, callback);
  };

  const onChange = async value => {
    loadInfo({ region: value });

    if (value) {
      await searchCity(value.id);
    } else {
      loadInfo({
        city: value,
        cityId: value,
        regionId: value,
        regionName: value,
        cityName: value,
      });

      dispatch(change('personalForm', 'cityId', value));
    }
  };

  return (
    <div className="col-sm-6">
      <label className={required ? 'required' : ''}>{label}</label>
      <div>
        <EditableSelect
          name={name}
          async={true}
          onChange={async value => {
            await onChange(value);
            input.onChange(value?.id || null);
          }}
          style={style}
          loadOptions={getItems}
          placeholder={regionId ? 'Начните ввод' : placeholder}
          searchPromptText={'Начните ввод'}
          noResultsText={'Начните ввод'}
          value={region || regionId || 0}
          inputValue={region?.text || regionName || ''}
          filterOption={() => true}
          valueKey="id"
          labelKey="text"
          clearValueText="Очистить"
          loadingPlaceholder="Загрузка регионов"
          onSelectResetsInput={false}
          onBlurResetsInput={false}
          onCloseResetsInput={false}
          arrowRenderer={arrowRenderer}
        />
        {touched && error && <span className="text-danger text-danger--small">{error}</span>}
      </div>
    </div>
  );
};

export const renderCity = ({
  input,
  name,
  style,
  label,
  info,
  required,
  placeholder = 'Выберите город',
  arrowRenderer,
  meta: { touched, error },
}) => {
  const { cityId = 0, cityName, region, regionId } = info;
  const cities = useSelector(getCities);
  const loading = useSelector(getEffectLoading('personal', 'searchCity'));

  const {
    personal: { searchCity },
  } = useDispatch();

  return (
    <div className="col-sm-6">
      <label className={required ? 'required' : ''}>{label}</label>
      <div>
        <Select
          name={name}
          style={style}
          placeholder={cities ? 'Начните ввод' : placeholder}
          value={input.value}
          options={cities || (cityId && cityName && [{ id: cityId, text: cityName }]) || []}
          onChange={newValue => {
            input.onChange(newValue?.id || null);
          }}
          searchable={true}
          noResultsText="Сначала выберите регион"
          valueKey="id"
          labelKey="text"
          clearValueText="Очистить"
          isLoading={loading}
          arrowRenderer={arrowRenderer}
          onOpen={async () => {
            const regionId = region?.id || regionId || null;

            if (!cities && regionId) {
              await searchCity(regionId);
              input.onChange(null);
            }
          }}
        />
        {touched && error && <span className="text-danger text-danger--small">{error}</span>}
      </div>
    </div>
  );
};

export const renderMessenger = ({ input, id, label, defaultValue }) => (
  <div>
    <input
      {...input}
      id={id}
      type="radio"
      name={input.name}
      value={defaultValue}
      defaultChecked={parseInt(input.value, 10) === defaultValue}
    />
    <label htmlFor={id}>{label}</label>
  </div>
);

export const renderCheckbox = ({
  input,
  defaultName,
  name,
  label,
  level,
  disabled,
  defaultValue,
  dataParent,
  required,
  className
}) => (
  <label className={cn(className, { 'col-sm-3': def(level) && level === 0, required })}>
    <input
      type="checkbox"
      name={defaultName || name}
      defaultChecked={input.value != 0}
      disabled={disabled === 'true'}
      value={defaultValue}
      onChange={input.onChange}
      data-parent={dataParent}
      data-level={level}
    />
    {label}
  </label>
);

export const renderFormat = ({ input: { value, onChange }, name, label }) => (
  <label>
    {label}
    <Select
      name={name}
      options={[
        { value: 0, label: 'XLS' },
        { value: 1, label: 'XML' },
      ]}
      searchable={false}
      clearable={false}
      onChange={newValue => {
        onChange(newValue.value);
      }}
      value={value}
    />
  </label>
);
