import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Accept from '../../actions-item/components/Accept';
import { Link } from 'react-router-dom';
import Mixpanel from 'services/mixpanel';

const ActionItem = props => {
  const { action, accept, index } = props;
  const date = `${action.start} - ${action.end}`;

  return (
    <div className="b2b-action__item">
      <div className="b2b-action__item__brand">{ReactHtmlParser(action.brand)}</div>
      <div className="b2b-action__item__description">
        <div className="b2b-action__item__date">{date}</div>
        <h2 className="b2b-action__item__title">
          <Link
            to={`/actions/${action.no}`}
            target="_blank"
            onClick={() => {
              Mixpanel?.track('СтрГлавнаяСтраница.Акция', {
                page: 'СтрГлавнаяСтраница',
                id: action.no,
              });
            }}
          >
            {action.name}
          </Link>
        </h2>
        <div className="b2b-action__item__text">{action.description}</div>
      </div>
      <div className="b2b-action__item__signup">
        <Accept accept={accept} action={action} index={index} />
      </div>
    </div>
  );
};

export default ActionItem;
