import React, { useState, Fragment } from 'react';
import { Field } from 'redux-form';
import { renderText } from 'components/fields';
import SelectVendor from './fields/select-vendor';
import HiddenInput from './fields/hidenInput';
import { change } from 'redux-form';
import { SPECIAL_PRICES_FORM } from 'pages/VAD/constants';

const VendorData = () => {
  const [rowIndex, setRowIndex] = useState(1);
  const [dataRow, setDataRow] = useState([`row${rowIndex}`]);
  const isShowButtonRemove = rowIndex >= 2;

  const addRow = e => {
    e.preventDefault();
    change(SPECIAL_PRICES_FORM, 'vendorCount', dataRow.length + 1);
    setRowIndex(dataRow.length + 1);
    setDataRow([...dataRow, `row${rowIndex}`]);
  };

  const removeRow = e => {
    e.preventDefault();
    change(SPECIAL_PRICES_FORM, 'vendorCount', dataRow.length - 1);
    setRowIndex(dataRow.length - 1);
    dataRow.pop();
    setDataRow(dataRow);
  };

  return (
    <Fragment>
      <div className="col-sm-12">
        <p className="special-prices-text">
          Требования вендоров указать Partner ID и Статус. В случае если спецификация
          мультибрендовая, просьба указать по каждому вендору спецификации.
        </p>
      </div>
      {dataRow.map((name, index) => (
        <Fragment key={index}>
          <Field
            colSm={'4'}
            name={`vendor[${index}]`}
            label="Вендор"
            required
            component={SelectVendor}
          />
          <Field
            colSm={'4'}
            name={`partnerStatus[${index}]`}
            placeholder={'Если у вас нет Partner ID, укажите «нет»'}
            label="Partner ID"
            type="text"
            required
            component={renderText}
          />
          <Field
            colSm={'4'}
            name={`status[${index}]`}
            label="Статус партнера у вендора"
            placeholder={'Если у вас нет Статуса, укажите «нет»'}
            type="text"
            required
            component={renderText}
          />
        </Fragment>
      ))}
      <div className={'col-sm-12'}>
        <Field name={'vendorCount'} type={'text'} value={dataRow.length} component={HiddenInput} />
        <a className={'add-vendor-data'} onClick={addRow}>
          <i className={'icon icon-add-vendor'} />
          Добавить вендора и Partner ID
        </a>
        {isShowButtonRemove && (
          <button className="remove-vendor-data" onClick={removeRow}>
            Удалить вендора и Partner ID
          </button>
        )}
      </div>
    </Fragment>
  );
};

export default VendorData;
