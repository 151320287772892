import { actionsBlacklist } from 'client/store';

export default () => next => action => {
  const result = next(action);

  if (actionsBlacklist.includes(action.type) || /persist|redux\-form/.test(action.type)) {
    return result;
  }

  let { type, payload } = action;

  if (type === 'api/fetchRoute') {
    const { routes, ...rest } = payload;
    payload = rest;
  }

  next({
    type: 'api/log',
    payload: { type, payload },
  });

  return result;
};
