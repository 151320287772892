// @ts-nocheck
import get from 'lodash/get';

export const chooseQueueMessage = socketMessage => {
  const fix = get(socketMessage, ['data', 'fix'], null);
  const orderNo = get(fix, 'id', 0);
  const doneMessage = get(fix, ['payload', 'message'], '');

  const message = {
    id: orderNo,
    success: false,
    text: '',
  };

  if (!fix?.done && fix?.error === null) {
    message.success = false;
  }

  if (fix?.done && fix?.error === null) {
    message.success = true;
    message.text = doneMessage;
  }

  if (fix?.error !== null) {
    message.success = false;
    message.text = fix?.error;
  }

  return message;
};
