/* eslint-disable react/no-string-refs */
import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from 'components/layout/content';
import { Link } from 'react-router-dom';
import TableList from 'components/table/list';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';

import DayPicker from 'react-day-picker';
import { FIRST_DAY_OF_WEEK, MONTHS, WEEKDAYS_SHORT } from 'common/util';
import { EVENTS_TABLE_NAME } from 'pages/Marketing/constants';
import { setEventsBrand, setEventsDate, setEventsRegion } from 'pages/Marketing/duck/actions';
import { flushState, setState } from 'pages/Orders/action/state';
import columns from 'pages/Marketing/components/events/helpers/columns';
import withInvite from 'pages/Marketing/hoc/withInvite';
import { getEvents } from 'pages/Marketing/selectors';
import { EventsContext } from 'pages/Marketing/helpers/context';
import useContextDispatch from 'hooks/context/useContextDispatch';
import useContextSelector from 'hooks/context/useContextSelector';

const defaultSorted = [
  {
    id: 'start',
    desc: false,
  },
];

const InviteTable = withInvite(TableList);

const Events = () => {
  const events = useSelector(getEvents);
  const { list } = useDispatch();
  const { pathname } = useLocation();
  const stateDispatch = useContextDispatch(EventsContext);

  const { regionValue, brandValue, filtered, selectedDay } = useContextSelector(
    EventsContext,
    state => state,
  );

  const tableRef = useRef();

  useEffect(() => {
    stateDispatch(flushState());
  }, [pathname]);

  const type = useMemo(() => pathname.split('/')[2], [pathname]);

  const { invites, pages, regions, brands, dates } = events;
  const existingDates = dates ? dates.map(date => new Date(date * 1000)) : null;
  const isInvite = type === 'invite';

  let title, link, linkName;

  switch (type) {
    case 'archive':
      title = 'Архивные мероприятия';
      link = '/events';
      linkName = 'Активные мероприятия';
      break;
    case 'invite':
      title = 'Приглашения на мероприятия';
      link = '/events';
      linkName = 'Активные мероприятия';
      break;
    default:
      title = 'Маркетинговые мероприятия';
      link = '/events/archive';
      linkName = 'Архивные мероприятия';
      break;
  }

  const handleRegionFilter = useCallback(newValue => {
    list.unblock();
    stateDispatch(setEventsRegion(newValue));
  }, []);

  const handleBrandFilter = useCallback(newValue => {
    list.unblock();
    stateDispatch(setEventsBrand(newValue));
  }, []);

  const handleDayClick = useCallback((day, { highlighted, selected }) => {
    if (highlighted) {
      list.unblock();
      stateDispatch(setEventsDate({ day, selected }));
    }
  }, []);

  const handleDayFilterReset = () => {
    list.unblock();
    stateDispatch(
      setState({
        selectedDay: null,
        filtered: [],
      }),
    );
  };

  const handleSortChange = useCallback(() => {
    list.unblock();
  }, []);

  const Table = isInvite ? InviteTable : TableList;

  return (
    <Content title={title}>
      <div className="row">
        <div className="col-10">
          <div className="actions-container align-items-center">
            <div className="col-8 event-actions">
              <div className="col-sm">
                <Link to={link} className="section-nav">
                  <i className="icon icon-event_list" /> {linkName}
                </Link>
              </div>
              <div className="col-sm">
                <Select
                  name="regions"
                  placeholder="Все регионы"
                  value={regionValue}
                  options={regions}
                  onChange={handleRegionFilter}
                  noResultsText="Все регионы"
                />
              </div>
              <div className="col-sm">
                <Select
                  name="brands"
                  placeholder="Все вендоры"
                  value={brandValue}
                  options={brands}
                  onChange={handleBrandFilter}
                  noResultsText="Все вендоры"
                />
              </div>
              <div className="col-sm">
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    list.unblock();
                    stateDispatch(flushState());
                  }}
                  className="clear-filters"
                >
                  <i className="icon icon-remove-filters" title="Очистить фильтр" />
                </a>
              </div>
            </div>
            <div className="col-4 justify-content-end align-items-center invite-block">
              <Link to="/events/invite" className="section-nav">
                <i className="icon icon-bell">
                  <i className="invite-counter">{invites}</i>
                </i>
                Приглашения на мероприятия
              </Link>
            </div>
          </div>
          <div className="table-container">
            <Table
              name={EVENTS_TABLE_NAME}
              ref={tableRef}
              columns={columns}
              filtered={filtered}
              onSortedChange={handleSortChange}
              pages={pages}
              route={pathname}
              type={type}
              defaultSorted={defaultSorted}
              manual
              showPagination={pages > 1}
            />
          </div>
        </div>
        <div className="col-2">
          <DayPicker
            numberOfMonths={3}
            locale="ru"
            months={MONTHS['ru']}
            weekdaysShort={WEEKDAYS_SHORT['ru']}
            firstDayOfWeek={FIRST_DAY_OF_WEEK['ru']}
            modifiers={{ highlighted: existingDates }}
            selectedDays={selectedDay}
            onDayClick={handleDayClick}
          />
          <div className="reset-day-filter">
            <span onClick={handleDayFilterReset}>Сбросить фильтр</span>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default Events;
