import { download } from 'common/util';
import alert from 'components/native/alert/function';

export default {
  name: 'priceList',
  state: {},
  reducers: {
    setSending: (state, { index, flag }) => {
      const { [index]: foo, ...rest } = state;

      if (flag) {
        return { ...state, [index]: flag };
      }
      return rest;
    },
  },
  effects: ({ api }) => ({
    async getFile({ lol, type }) {
      try {
        const { blob, filename } = await api.get({
          url: 'pricelists/get',
          query: `?lol=${lol}&type=${type}`,
        });

        download(blob, filename);
      } catch (e) {
        console.log(e.message);
      }
    },
    async sendFile({ lol, type, index }) {
      this.setSending({ index, flag: true });

      try {
        const { message } = await api.get({
          url: 'pricelists/send',
          query: `?lol=${lol}&type=${type}`,
        });
        this.setSending({ index, flag: false });
        alert(message);
      } catch (e) {
        this.setSending({ index, flag: false });
        console.error(e.message);
      }
    },
  }),
  selectors(slice, createSelector, hasProps) {
    return {
      getSending: hasProps((models, index) => slice(priceList => index in priceList)),
    };
  },
};
