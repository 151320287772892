import createColumnsOZPr from './createColumnsOZPr';
import createColumnsPreorder from './createColumnsPreorder';
import createColumnsZPr from './createColumnsZPr';
import { preorderTypes } from '../../../constances/preorder';

const createColumns = order => {
  if (order.docType === +preorderTypes.docType && order.tender === preorderTypes.tender)
    return createColumnsPreorder;
  if (Number(order.docType) === 1) return createColumnsZPr(order);
  return createColumnsOZPr(order);
};

export default createColumns;
