import sortStringNumbers from '../../../../../helpers/sortStringNumbers';
import get from 'lodash/get';
import sortStrings from '../../../../../helpers/sortStrings';

const proxyColumns = [
  {
    Header: 'Контрагент',
    accessor: 'counterparty',
    id: 'counterparty',
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Поставщик',
    accessor: 'supplier',
    id: 'supplier',
    sortMethod: sortStrings,
  },
  {
    Header: 'ФИО',
    accessor: 'name',
    id: 'name',
    sortMethod: sortStrings,
  },
  {
    Header: 'Паспорт',
    accessor: 'passport',
    id: 'passport',
    sortMethod: sortStrings,
  },
  {
    Header: 'Доверенность',
    accessor: 'proxy',
    id: 'proxy',
    sortMethod: sortStrings,
  },
  {
    Header: 'Договор',
    accessor: 'contract',
    id: 'contract',
    sortMethod: sortStrings,
  },
  {
    Header: 'Дата начала',
    accessor: 'sortDateBegin',
    id: 'dateBegin',
    sortMethod: sortStringNumbers,
    Cell: row => get(row, ['original', 'dateBegin'], null),
  },
  {
    Header: 'Дата окончания',
    accessor: 'sortDateEnd',
    id: 'dateEnd',
    sortMethod: sortStringNumbers,
    Cell: row => get(row, ['original', 'dateEnd'], null),
  },
];

export default proxyColumns;
