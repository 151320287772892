const getMinutes = time => {
  if (time) {
    const [hours, minutes] = time.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
  }
  return 0;
};

const declinationOfTime = diff => {
  const hours = (diff * 10) / 10;
  const isInteger = Number.isInteger(hours);
  const hourFloor = Math.floor(diff);
  const minIntervalText = 'Минимальный интервал времени';
  const declensions = ['час', 'часа', 'часов'];
  const cases = [2, 0, 1, 1, 1, 2];
  const declensionHour =
    declensions[
      hourFloor % 100 > 4 && hourFloor % 100 < 20
        ? 2
        : cases[hourFloor % 10 < 5 ? hourFloor % 10 : 5]
    ];

  if (isInteger) {
    return `${minIntervalText} ${hourFloor} ${declensionHour}`;
  }

  return `${minIntervalText} ${hourFloor} ${declensionHour} 30 минут`;
};

const getIntervalInMinutes = interval => interval * 60;

export const setCorrectTime = value => {
  if (/\D/.test(value.replace(':', ''))) {
    return '';
  }

  if (value.length <= 2) {
    if (parseInt(value[0], 10) >= 3) {
      return `0${value}:`;
    }

    if (parseInt(value[0], 10) === 0 && parseInt(value[1], 10) === 0) {
      return `${value}:`;
    }

    if (parseInt(value[0], 10) === 0 && parseInt(value[1], 10) <= 9) {
      return `${value}:`;
    }

    if (parseInt(value[0], 10) === 1 && parseInt(value[1], 10) <= 9) {
      return `${value}:`;
    }

    if (parseInt(value[0], 10) === 2 && parseInt(value[1], 10) <= 3) {
      return `${value}:`;
    }

    if (parseInt(value[0], 10) === 2 && parseInt(value[1], 10) >= 4) {
      return `${value[0]}`;
    }
  } else {
    if (parseInt(value[3], 10) !== 3) {
      return `${value[0]}${value[1]}:00`;
    }

    if (parseInt(value[3], 10) === 3) {
      return `${value}0`;
    }
  }

  return value;
};

export const checkFormatTime = /^([01]\d|2[0-3]):[0-5][0-9]/;

export const checkWorkingTime = (timeFrom, timeTo, timeMin, timeMax, timeDiff) => {
  const from = getMinutes(timeFrom);
  const to = getMinutes(timeTo);
  const min = getMinutes(timeMin);
  const max = getMinutes(timeMax);
  const interval = getIntervalInMinutes(timeDiff);
  const diff = to - from;

  if (from < min || to > max) {
    return {
      success: false,
      message: `Допустимый интервал времени с ${timeMin} до ${timeMax}`,
    };
  }

  if (from > to) {
    return {
      success: false,
      message: `${timeFrom} не может быть позднее ${timeTo}`,
    };
  }

  if (diff < interval) {
    return {
      success: false,
      message: declinationOfTime(timeDiff),
    };
  }

  return {
    success: true,
    message: '',
  };
};
