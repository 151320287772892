import React from 'react';
import CheckboxFieldTransit from './components/checkbox-field-transit';
import QtyFieldTransit from './components/qty-field-transit';

const orderFromTransitColumns = (inputs, checkboxes, button) => {
  const toggleAll = e => {
    checkboxes.forEach(checkbox => {
      checkbox.current.handleChange(e);
    });
  };

  return [
    {
      Header: () => <input type="checkbox" onChange={toggleAll} defaultChecked={false} />,
      accessor: 'checkbox',
      width: 40,
      Cell: row =>
        (row && (
          <CheckboxFieldTransit
            ref={checkboxes[row.index]}
            inputRef={inputs[row.index]}
            buttonRef={button}
          />
        )) ||
        null,
    },
    {
      Header: 'Код товара',
      accessor: 'id',
      width: 80,
    },
    {
      Header: 'Наименование',
      accessor: 'name',
      width: 320,
    },
    {
      Header: 'Кол-во упак.',
      accessor: 'packaged',
      width: 90,
    },
    {
      Header: 'Мин. кол-во',
      accessor: 'minPackaged',
      width: 90,
    },
    {
      Header: 'ЖКол',
      accessor: 'qtyNeed',
      width: 70,
      Cell: row => (row && <QtyFieldTransit row={row} ref={inputs[row.index]} />) || null,
    },
    {
      Header: 'Дост. в транзите',
      accessor: 'availableTransit',
      width: 130,
    },
    {
      Header: 'Мин. дата транзита',
      accessor: 'tranDate',
      width: 130,
    },
  ];
};

export default orderFromTransitColumns;
