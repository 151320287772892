import { UNAUTHORIZED } from 'common/config';
import { getFilename } from 'common/util';
import cache from 'services/cache';

const handleResult = async res => {
  const contentType = res.headers.get('Content-Type');
  const contentDisposition = res.headers.get('Content-Disposition');

  if (contentType?.includes('json')) {
    return await res.json();
  }

  if (contentDisposition?.includes('attachment')) {
    const blob = await res.blob();

    return {
      blob,
      filename: getFilename(contentDisposition),
      csrf_token: res.headers.get('X-CSRF'),
    };
  }
};

const fetchApi = async (endpoint, options, n = 2) => {
  const { cache: cacheName, key, ...fetchOptions } = options;

  const headers = new Headers(fetchOptions.headers);
  fetchOptions.headers = headers;

  if (cacheName) {
    const cached = await cache.get(endpoint, cacheName);

    if (cached) {
      return await handleResult(cached);
    }
  }

  const res = await fetch(endpoint, fetchOptions);

  if (!res.ok) {
    const { status, csrf_token, message } = await res.clone().json();

    if (n === 1) {
      throw { status, message, csrf: csrf_token };
    }

    if (status === UNAUTHORIZED) {
      const newOptions = {
        ...fetchOptions,
        headers: {
          ...headers,
          Authorization: `Bearer ${csrf_token}`,
          'X-Token-Type': 'refresh',
        },
        cache: cacheName,
      };

      /**
       * Clear all cache on short token expiration
       */
      await cache.clear();

      return await fetchApi(endpoint, newOptions, n - 1);
    }

    throw { status, message, csrf: csrf_token };
  }

  if (cacheName) {
    await cache.put(endpoint, res.clone(), cacheName);
  }

  return await handleResult(res);
};

export default fetchApi;
