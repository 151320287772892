import { createFilter } from 'redux-persist-transform-filter';
import Storage from 'utils/StorageModel';
import localForage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {purgeStoredState} from "redux-persist"

const catalogFilter = createFilter('orderCatalog', ['forSale']);

export const storage = new Storage(localForage.createInstance(), {
  driver: [localForage.INDEXEDDB, localForage.WEBSQL, localForage.LOCALSTORAGE],
  name: 'b2b-store',
  storeName: 'b2bStore',
  version: 3,
});

export async function clearPersistedSettings() {
  const keys = await localForage.keys();

  return await Promise.all(
    keys.filter(key => key.includes('persist:settings')).map(key => localForage.removeItem(key)),
  );
}

export const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'contacts', 'orderCatalog', 'newOrdersNew'],
  transforms: [catalogFilter],
  stateReconciler: autoMergeLevel2,
  timeout: null,
};

/**
 * double clear persisted state, prob a bug
 * @returns {Promise<void>}
 */
export async function clearPersistedState(){
  await purgeStoredState(persistConfig)
  await purgeStoredState(persistConfig)
}

export const settingsPersistConfig = {
  key: 'settings',
  storage,
  timeout: null,
};
export const syncConfig = {
  whitelist: ['secure/csrf'],
  broadcastChannelOption: { type: 'idb' },
};

export const persistedOrderConfig = {
  ...settingsPersistConfig,
  key: 'settings:order',
  whitelist: ['userVisibility', 'userWidths', 'view'],
};

export const persistedItemsConfig = {
  ...settingsPersistConfig,
  key: 'settings:items',
  whitelist: ['userVisibility', 'userOrder', 'simplified', 'userWidths'],
};
export const persistedItemsFilterConfig = {
  ...settingsPersistConfig,
  key: 'settings:items-filters',
  whitelist: ['userFilters'],
};

export const persistedAccountedConfig = {
  ...settingsPersistConfig,
  key: 'settings:accounted',
};
export const persistedFinancesConfig = {
  ...settingsPersistConfig,
  key: 'settings:finances',
};
export const persistedListConfig = {
  ...settingsPersistConfig,
  key: 'settings:table-list',
  whitelist: ['pageSize'],
};
export const persistedNotifyConfig = {
  ...settingsPersistConfig,
  key: 'settings:notify',
};
export const persistedSecureConfig = {
  ...settingsPersistConfig,
  key: 'settings:secure',
  whitelist: ['csrf'],
};

export const nestedPersistConfig = {
  ordersColumns: persistedOrderConfig,
  itemsFilter: persistedItemsFilterConfig,
  itemsColumns: persistedItemsConfig,
  favoriteCategories: { ...settingsPersistConfig, key: 'favorite:category' },
  financeFilters: persistedFinancesConfig,
  accountedColumns: persistedAccountedConfig,
  listSettings: persistedListConfig,
  notifySettings: persistedNotifyConfig,
  secure: persistedSecureConfig,
};
