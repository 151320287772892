//@ts-check

import React from 'react';

/**
 * Component for init some API requests with loading state
 *
 * @param {LoadingSubmitButtonProps} props
 *
 * @returns {HTMLElement}
 * @constructor
 */
export const LoadingSubmitButton = props => {
  const { label, fetching, btnClass, style, position, onClick } = props;

  let pos = position || 'right';

  return (
    <div className="loading-button" style={style}>
      {fetching && pos === 'left' && <i className="loader" style={{ marginRight: 7 }} />}
      {typeof onClick === 'function' ? (
        <button type="submit" className={btnClass} disabled={fetching} onClick={onClick}>
          {label}
        </button>
      ) : (
        <button type="submit" className={btnClass} disabled={fetching}>
          {label}
        </button>
      )}
      {fetching && pos === 'right' && <i className="loader" style={{ marginLeft: 7 }} />}
    </div>
  );
};
