// @ts-nocheck
import groupBy from 'lodash/groupBy';

export function groupOrdersByShipment(orderNo, ordersToIntegrate) {
  const grouped = ordersToIntegrate ? groupBy(ordersToIntegrate[orderNo], 'shipment') : null;

  if (grouped) {
    return Object.keys(grouped).map(groupKey => ({
      label: groupKey,
      data: grouped[groupKey],
    }));
  }

  return [];
}
