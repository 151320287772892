import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Transfer = ({
  className,
  color = '#236192',
  name,
  width = '12',
  height = '12',
  viewBox = '0 0 12 12',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M4.5 4H1V11H8V7.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6 6L10 2M10 2V5M10 2H7"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Transfer.defaultProps = {
  className: '',
  color: '#236192',
  width: '12',
  height: '12',
  viewBox: '0 0 12 12',
};

Transfer.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
