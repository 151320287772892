import { useLayoutEffect } from 'react';

export const useSplitScroll = () => {
  useLayoutEffect(() => {
    const chunkBody = document.querySelector('.chunk_body');

    const chunkHeader = document.querySelector('.chunk_header');
    const orderTable = document.querySelector('.order_lines > div:nth-child(2)');

    if (!orderTable && !chunkHeader) {
      return;
    }

    const syncVertical = e => {
      chunkBody.scrollTop = e.target.scrollTop;
    };

    const syncHorizontal = e => {
      chunkHeader.scrollLeft = e.target.scrollLeft;
      orderTable.scrollTop = e.target.scrollTop;
    };

    orderTable.addEventListener('scroll', syncVertical, false);
    chunkBody.addEventListener('scroll', syncHorizontal, false);

    return () => {
      orderTable.removeEventListener('scroll', syncVertical, false);
      chunkBody.removeEventListener('scroll', syncHorizontal, false);
    };
  }, []);
};
