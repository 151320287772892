// @ts-nocheck
import get from 'lodash/get';
import map from 'lodash/map';
import noImage from 'common/assets/images/no-image.png';
import { formatCurrency, toPrimalNumber } from 'utils/new/numbers/format-number';

export default {
  name: 'newGoodExpansion',
  state: {
    relatedGoods: {},
    analogGoods: {},
  },
  reducers: {
    clearGoodExpansion: state => {
      state.analogGoods = {};
      state.relatedGoods = {};
      return state;
    },
    fetchRelatedGoodsRequest: (state, payload) => {
      state.relatedGoods[payload.goodId] = {};
      state.relatedGoods[payload.goodId].fetching = true;
      return state;
    },
    fetchRelatedGoodsSuccess: (state, payload) => {
      state.relatedGoods[payload.goodId].data = get(payload, 'data', []);
      state.relatedGoods[payload.goodId].fetching = false;
      return state;
    },
    fetchAnalogGoodsRequest: (state, payload) => {
      state.analogGoods[payload.goodId] = {};
      state.analogGoods[payload.goodId].fetching = true;
      return state;
    },
    fetchAnalogGoodsSuccess: (state, payload) => {
      state.analogGoods[payload.goodId].data = get(payload, 'data', []);
      state.analogGoods[payload.goodId].fetching = false;
      return state;
    },
  },
  effects: ({ api }) => ({
    async fetchRelatedGoods(payload) {
      const { goodId, orderNo } = payload;
      try {
        this.fetchRelatedGoodsRequest({ goodId });
        const result = await api.get({
          url: `new-good-expansion/goods/related/${goodId}`,
          query: `?order=${orderNo || 'NEW'}`,
        });

        this.fetchRelatedGoodsSuccess({ goodId, ...result });
        return result;
      } catch (err) {
        return {};
      }
    },
    async fetchAnalogGoods(payload) {
      const { goodId, orderNo } = payload;
      try {
        this.fetchAnalogGoodsRequest({ goodId });
        const result = await api.get({
          url: `new-good-expansion/goods/analogs/${goodId}`,
          query: `?order=${orderNo || 'NEW'}`,
        });

        this.fetchAnalogGoodsSuccess({ goodId, ...result });
        return result;
      } catch (err) {
        return {};
      }
    },
  }),
  selectors: (slice, createSelector, hasProps) => ({
    getRelatedGoodsFetchingById: hasProps((_, goodId) =>
      slice(newOrder => get(newOrder, ['relatedGoods', goodId, 'fetching'], false)),
    ),
    getRelatedGoodsById: hasProps((_, goodId) =>
      slice(newOrder => {
        const relatedGoods = get(newOrder, ['relatedGoods', goodId, 'data'], []);

        if (relatedGoods.length > 0) {
          return map(relatedGoods, relatedGood => ({
            ...relatedGood,
            img: relatedGood.imageUrl ? relatedGood.imageUrl : noImage,
            priceLCY: formatCurrency(toPrimalNumber(relatedGood.priceLCY)),
          }));
        }

        return [];
      }),
    ),
    getAnalogGoodsFetchingById: hasProps((_, goodId) =>
      slice(newOrder => get(newOrder, ['analogGoods', goodId, 'fetching'], false)),
    ),
    getAnalogGoodsById: hasProps((_, goodId) =>
      slice(newOrder => {
        const analogGoods = get(newOrder, ['analogGoods', goodId, 'data'], []);

        if (analogGoods.length > 0) {
          return map(analogGoods, analogGood => ({
            ...analogGood,
            img: analogGood.imageUrl ? analogGood.imageUrl : noImage,
            priceLCY: formatCurrency(toPrimalNumber(analogGood.priceLCY)),
          }));
        }

        return [];
      }),
    ),
  }),
};
