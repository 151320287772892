import React from 'react';
import { Button } from 'common/ui/button';
import { Heading } from 'common/ui/heading';
import { Popup } from 'common/ui/popup';
import { usePopup } from 'hooks/new';
import { PersonalDataText } from '../personal-data-text';

import styles from './personal-data-agreement.module.scss';

export const PersonalDataAgreement = () => {
  const [openPopup, closePopup, isPopupOpen] = usePopup();

  return (
    <>
      <div className={styles['agreement']}>
        Отправляя Анкету, Вы выражаете свое согласие с условиями
        <Button theme="secondaty" className={styles['link']} onClick={openPopup}>
          политики обработки персональных данных
        </Button>
        . Вы осведомлены, что отражаемые в данной Анкете персональные данные будут считаться
        общедоступными. Под обработкой персональных данных понимается сбор, систематизация,
        накопление, хранение, уточнение (обновление, изменение), использование, распространение (в
        том числе передача и трансграничная передача), обезличивание, блокирование, уничтожение и
        любые другие действия (операции) с персональными данными. Обработка персональных данных
        осуществляется в срок с момента регистрации.
      </div>
      <Popup
        className={styles['popup']}
        body={<PersonalDataText />}
        header={<Heading size="lg">Политика обработки персональных данных</Heading>}
        visible={isPopupOpen}
        onClose={closePopup}
      />
    </>
  );
};
