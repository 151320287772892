import React from 'react';
import { useDispatch } from 'react-redux';
const EnabledRemoveButton = ({ row, isListView }) => {
  const {
    order: { handleDelete },
  } = useDispatch();

  return (
    <a
      href="#"
      className="icon icon-delorder"
      title="Удаление"
      onClick={async () => {
        await handleDelete({ ...row, isList: isListView });
      }}
    />
  );
};

export default EnabledRemoveButton;
