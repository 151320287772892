// README:
// we need specify some string before key like a number
// https://stackoverflow.com/questions/48014307
const createFieldName = (filterGroupKey, filterKey) =>
  /**
   * Caused by RRF splitting dots in name into array, we need to replace dots
   * in name and convert them back while compare or submitting
   */
  `group-${filterGroupKey}.${filterKey?.replace('.', '%%') || ''}`;
export default createFieldName;
