import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { hasCheckedInputs } from '../../helpers';
import useContextSelector from 'hooks/context/useContextSelector';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { setJoined } from 'pages/Orders/action/state';
import { OrderFiltersContext } from 'pages/Orders/constances';

const OrdersJoin = props => {
  const { info, index } = props;
  const {
    order: { handleJoin },
  } = useDispatch();

  const { id: joinedId, index: joinedIndex } = useContextSelector(
    OrderFiltersContext,
    state => state.joined,
  );
  const dispatch = useContextDispatch(OrderFiltersContext);

  const joinChange = useCallback(
    (id, index) => e => {
      if (hasCheckedInputs() && id === joinedId) {
        return false;
      } else if (e.target.classList.contains('join-active')) {
        dispatch(setJoined({ id: 0, index: 0 }));
      } else {
        dispatch(setJoined({ id, index }));
      }
    },
    [joinedId],
  );

  const disabled = info.disabled || (joinedId && joinedId !== info.joinId);

  return (
    <span className="order-join-container">
      <input
        title="Объединить заказ"
        type="checkbox"
        onChange={joinChange(info.joinId, index)}
        className={joinedId === info.joinId ? 'join join-active' : 'join'}
        id={info.orderNo}
        data-reserve={info.reserve}
        defaultChecked={(joinedId && joinedIndex === index) || !!info.joining}
        disabled={disabled}
      />
      <i
        className="icon icon-join"
        title="Объединить заказ"
        style={
          joinedId === info.joinId && !info.disabled
            ? { display: 'inline-block' }
            : { display: 'none' }
        }
        onClick={async () => await handleJoin(info.orderNo)}
      />
    </span>
  );
};

export default OrdersJoin;
