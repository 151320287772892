import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Approve = ({
  className,
  color = '#008000',
  name,
  width = '19',
  height = '11',
  viewBox = '0 0 19 11',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M5 5.5L9 10L18 1M8.5 5.5L13 1M1 6L5 10" stroke={color} />
    </svg>
  );
};

Approve.defaultProps = {
  className: '',
  color: '#008000',
  width: '19',
  height: '11',
  viewBox: '0 0 19 11',
};

Approve.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
