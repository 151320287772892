import React from 'react';
import StepsVisual from './StepsVisual';

function Steps({ lendingFormIsInvalid, stepsNumber, currentStep, stepDescription }) {
  return (
    <div className="steps">
      <h4 className="steps__title">Завершите шаги для рассмотрения заявки кредитования</h4>
      <div className="steps__content">
        <div className="steps__stepper-title">
          <span className="steps__counter">{`Шаг ${currentStep} из ${stepsNumber} `}</span>
          <span className="steps__description">{stepDescription}</span>
        </div>
        <StepsVisual
          stepsNumber={stepsNumber}
          currentStep={currentStep}
          lendingFormIsInvalid={lendingFormIsInvalid}
        />
      </div>
    </div>
  );
}

export default Steps;
