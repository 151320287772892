const defaultColumns = [
  {
    Header: 'Дата отгрузки',
    id: 'deliveryDate',
    accessor: 'deliveryDate',
    width: 110,
  },
  {
    Header: 'Код',
    id: 'no',
    accessor: 'no',
    width: 70,
  },
  {
    Header: 'Наименование',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    id: 'name',
    accessor: 'name',
    width: 600,
  },
  {
    Header: 'Серийный номер',
    id: 'serialNo',
    accessor: 'serialNo',
    sortable: false,
  },
  {
    Header: 'Описание',
    id: 'description',
    accessor: 'description',
    sortable: false,
  },
  {
    Header: 'Вид обсл./ М. выдачи',
    id: 'SMode',
    accessor: d => `${d.SMode} / ${d.deliveryPlace}`,
  },
  {
    Header: 'Кол.',
    id: 'qty',
    accessor: 'qty',
    sortable: false,
    width: 50,
  },
  {
    Header: 'Номер заказа продажи',
    id: 'zprNo',
    accessor: 'zprNo',
    sortable: false,
  },
  {
    Header: 'Номер товарной накладной',
    id: 'packListNo',
    accessor: 'packListNo',
    sortable: false,
  },
  {
    Header: 'Цена торговли',
    id: 'priceTrade',
    accessor: 'priceTrade',
    sortable: false,
    width: 100,
  },
];

export default defaultColumns;
