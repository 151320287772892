import parseJsonObjectKeys from 'utils/parseJsonObjectKeys';

export const getHeader = slice => () => slice(order => order.header || {});

export const getRows = slice => () => slice(order => order.rows || []);

export const hasRows = slice => () => slice(order => Boolean(order?.rows?.length));

export const getSettings =
  (slice, createSelector) =>
  ({ user }) =>
    createSelector(user.getOrderSettings, settings =>
      parseJsonObjectKeys(settings, [
        'counterAgentRows',
        'shipmentAgentRows',
        'shipmentEndpointRows',
        'packingRows',
        'shipmentMethodRows',
        'workDateRows',
        'preorderShipmentMethodRows',
        'shipmentDate',
      ]),
    );

export const getDefaultShipmentMethod =
  (slice, createSelector) =>
  ({ order }) =>
    createSelector(
      order.getSettings,
      ({ shipmentMethodRows = [] }) =>
        shipmentMethodRows.find(method => +method['IsDefault']) || shipmentMethodRows[0] || {},
    );

export const getShipmentMethod =
  (slice, createSelector) =>
  ({ order }) =>
    createSelector(
      order.getHeader,
      order.getSettings,
      order.getDefaultShipmentMethod,
      ({ shipmentMethod }, { shipmentMethodRows = [] }, defaultMethod) =>
        shipmentMethodRows.find(({ Code }) => Code === shipmentMethod) || defaultMethod,
    );
