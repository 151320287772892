/**
 * Метод для распределения товарных позиций по доступности склад->везде (включает в себя склад)->отсутствует
 *
 * @param data - массив товаров
 * @param fields - объект с названиями полей (склад, доступно везде). название поля состоит из
 * availableClient, availableMSK1
 * @returns { warehouse: [], regionalWarehouse: [], empty: [] } - коллекция товаров, распределенных по следующим правилам
 * warehouse - склад
 * regionalWarehouse - региональный склад
 * empty - товары отсутствуют
 */
export function getAvailableCollection(data, fields) {
  const { available, availableRegional } = fields;

  return data.reduce(
    (accumulator, value) => {
      if (!value[availableRegional] && !value[available]) {
        accumulator.empty.push(value);

        return accumulator;
      }

      if (!value[available]) {
        accumulator.regionalWarehouse.push(value);

        return accumulator;
      }

      accumulator.warehouse.push(value);

      return accumulator;
    },
    { warehouse: [], regionalWarehouse: [], empty: [] },
  );
}

export function getGoodsSortByAbc(availableCollection) {
  return [].concat(
    [...availableCollection.warehouse].sort((a, b) => a.name.localeCompare(b.name)),
    [...availableCollection.regionalWarehouse].sort((a, b) => a.name.localeCompare(b.name)),
    [...availableCollection.empty].sort((a, b) => a.name.localeCompare(b.name)),
  );
}
