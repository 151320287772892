import alert from 'components/native/alert/function';
import { download } from 'common/util';

export default {
  name: 'accounted',
  state: {
    document: {},
    shipments: [],
    export: [],
  },
  reducers: {
    load: (state, { data: { data, ...rest } }) => ({ ...state, ...rest }),
  },
  effects: ({ api }) => ({
    async get(data) {
      try {
        return await api.post({
          url: '/finances/accounted',
          data,
        });
      } catch (e) {
        console.error(e);

        return {};
      }
    },
    async exportDocument(payload, state) {
      const { document } = state.accounted;

      return await api.post({
        url: `finances/accounted/document/${document.orderNo}`,
        data: { ...payload, document, doc_type: 12 },
      });
    },
    async getAttachment({ no, index }) {
      try {
        const { blob, filename } = await api.get({
          url: `finances/accounted/attachments/${no}`,
          query: `?page=${index}`,
        });

        download(blob, filename);
      } catch (e) {
        alert(e.message);
      }
    },
  }),
};
