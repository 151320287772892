import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Check = ({
  className,
  color = '#236192',
  name,
  width = '19',
  height = '12',
  viewBox = '0 0 19 12',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M18.3536 1.35355C18.5488 1.15829 18.5488 0.841709 18.3536 0.646447C18.1583 0.451184 17.8417 0.451184 17.6464 0.646447L18.3536 1.35355ZM8 11L7.64645 11.3536C7.84171 11.5488 8.15829 11.5488 8.35355 11.3536L8 11ZM1.35355 3.64645C1.15829 3.45118 0.841709 3.45118 0.646446 3.64645C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L1.35355 3.64645ZM17.6464 0.646447L7.64645 10.6464L8.35355 11.3536L18.3536 1.35355L17.6464 0.646447ZM8.35355 10.6464L1.35355 3.64645L0.646446 4.35355L7.64645 11.3536L8.35355 10.6464Z"
      fill={color}
    />
  </svg>
);

Check.defaultProps = {
  className: '',
  color: '#fff',
  width: '19',
  height: '12',
  viewBox: '0 0 19 12',
};

Check.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
