export default values => {
  const errors = {};

  if (!values.newPass) {
    errors.newPass = 'Заполните поле';
  } else if (
    values.newPass.length < 10 ||
    !/[0-9]+/.test(values.newPass) ||
    !/[A-Za-z]+/.test(values.newPass) ||
    /[^0-9a-zA-Z]+/.test(values.newPass)
  ) {
    errors.newPass = 'Пароль должен быть не менее 10 символов и содержать латинские буквы и цифры';
  }

  if (!values.confirmPass) {
    errors.confirmPass = 'Заполните поле';
  }

  if (values.newPass && values.confirmPass && values.confirmPass !== values.newPass) {
    errors.newPass = 'Поля Новый пароль и Подтвердите пароль должны совпадать';
  }

  return errors;
};
