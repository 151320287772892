// @ts-nocheck
import get from 'lodash/get';
import { CACHE_NAME } from 'common/config';
import { ORDER_STATUSES } from 'common/constants/order';
import { formatPrice } from 'utils/new/numbers/format-number';
import { bool } from 'utils/new/types';

export default {
  name: 'newActiveOrders',
  state: {
    items: [],
  },
  reducers: {
    fetchActiveOrdersSuccess: (state, payload) => {
      state.items = get(payload, 'data', []);
      return state;
    },
  },
  effects: ({ api }) => ({
    async fetchActiveOrders() {
      try {
        await api.get({
          url: 'new-orders/active',
          cache: CACHE_NAME,
        });
      } catch (err) {}
    },
  }),
  selectors: slice => ({
    getActiveOrders() {
      return slice(state => {
        const items = get(state, ['items'], []);
        const orderStatuses = Object.keys(ORDER_STATUSES).map(
          orderStatus => ORDER_STATUSES[orderStatus].name,
        );

        const sortedItems = items
          ? items
              .slice()
              .sort((a, b) => orderStatuses.indexOf(a.label) - orderStatuses.indexOf(b.label))
          : [];

        return sortedItems.map(sortedItem => ({
          ...sortedItem,
          data: sortedItem.data.map(dataItem => ({
            ...dataItem,
            amount: formatPrice(dataItem.amount, { currency: dataItem.currency }),
            needPass:
              (bool(dataItem.needShipmentRecord) && dataItem.needShipmentRecord === true) ||
              dataItem.needShipmentRecord.includes('1'),
            shippingAddress: dataItem.shippingAddress || 'Адрес не указан',
            shipmentMethod: dataItem.shipmentMethod || 'Метод отгрузки не указан',
          })),
        }));
      });
    },
  }),
};
