import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Food = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M8.14265 16.7143H1.85693V9.28576L3.57122 7.00005H9.85693L8.14265 9.28576V16.7143Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.85693 7.00005L11.5712 9.28576V10.4286"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.28564 16.7143H16.7142"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.857 7.00005H3.57129V5.8572C3.57129 5.51434 3.79986 5.28577 4.14272 5.28577H9.28558C9.62843 5.28577 9.857 5.51434 9.857 5.8572V7.00005Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.85693 9.28577H5.85693"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2859 16.7143C14.8638 16.7143 16.143 15.4351 16.143 13.8572C16.143 12.2792 14.8638 11 13.2859 11C11.7079 11 10.4287 12.2792 10.4287 13.8572C10.4287 15.4351 11.7079 16.7143 13.2859 16.7143Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5715 12.1429V1.28577L13.8572 1.8572L12.1429 1.28577L10.1429 1.8572L8.14293 1.28577L6.42864 1.8572L4.71436 1.28577V5.28577"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Food.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Food.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
