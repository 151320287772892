export const handlerDate = (diffDays, formatedDateFrom, formatedDateTo) => {
  if (diffDays < 0) {
    return {
      error: true,
      message: 'Начальная дата периода должна быть меньше конечной даты.',
    };
  }

  return {
    error: false,
    dateFrom: formatedDateFrom,
    dateTo: formatedDateTo,
  };
};
