import React, { memo, useMemo } from 'react';
import DashboardItem from './DashboardItem';
import dashboardList from '../../../constants/dashboard-list';
import { demofondModeContext } from 'pages/VAD/context';
import useContextSelector from 'hooks/context/useContextSelector';
import { useLocation } from 'react-router-dom';

const DemofondDashboard = () => {
  const { pathname } = useLocation();
  const mode = useContextSelector(demofondModeContext, state => state.demofondMode);

  if (!/demof[uo]nd/.test(pathname)) {
    return null;
  }

  const list = useMemo(() => dashboardList(mode), [mode]);
  return (
    <div className="dashboard">
      {list.map(item => (
        <DashboardItem
          key={item.id}
          title={item.title}
          src={item.src}
          position={item.position}
          description={item.description}
          mode={mode}
        />
      ))}
    </div>
  );
};

export default memo(DemofondDashboard);
