import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import List from './common/list';
import sumBy from 'lodash/sumBy';
import withApi from 'hoc/withApi';
import Message from './common/message';

const columns = [
  {
    Header: '',
    Cell: row => {
      if (!row) return null;

      return (
        <Link
          to={{
            pathname: `/warranty/service/view/${row.row.docNo}`,
            state: { type: 'offset-balance', id: row.row.docNo },
          }}
        >
          <i className="icon icon-view" />
        </Link>
      );
    },
    sortable: false,
    width: 40,
  },
  {
    Header: 'Дата учета',
    id: 'date',
    accessor: d => d.postingDateSort,
    Cell: row => (row && get(row, ['original', 'postingDate'], null)) || null,
    width: 100,
  },
  {
    Header: 'Номер документа',
    id: 'docNo',
    accessor: 'docNo',
    width: 140,
  },
  {
    Header: 'Кол-во',
    id: 'qty',
    accessor: 'qty',
    width: 80,
  },
  {
    Header: 'Номер Документа в Коммерции',
    id: 'ektpNo',
    accessor: 'ektpNo',
    width: 230,
  },
  {
    Header: 'Сумма Выплаты Клиенту',
    id: 'ektpAmount',
    accessor: 'ektpAmount',
    style: { textAlign: 'right' },
    width: 180,
  },
];

const width = `${sumBy(columns, 'width') + 30}px`;

const Balance = () => {
  return (
    <Fragment>
      <Message />
      <List columns={columns} width={width} />
    </Fragment>
  );
};

Balance.keepLocationState = true

export default withApi(Balance);
