import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';

import ReactHtmlParser from 'react-html-parser';
import { getCurrentRoute } from 'common/util';
import { routes } from 'common/routes';
import ForbiddenLayout from './layout/forbidden-layout';
import useRouteConfig from "hooks/use-route-config"

const ForbiddenPage = memo(
  props => {
    const {name} = useRouteConfig()

    return (
      <ForbiddenLayout {...props}>
        <div className="no-access">
          {ReactHtmlParser(props.message || `
Нет доступа к разделу
<b>${name}</b>.
<br />Обратитесь к вашему менеджеру.`)}
        </div>
      </ForbiddenLayout>
    )
  },
  (prev, next) => isEqual(prev.appFetchingError, next.appFetchingError));

export default ForbiddenPage;
