const balanceColumns = [
  {
    Header: 'Дата учета',
    id: 'postingDate',
    accessor: 'PDate',
    width: 110,
  },
  {
    Header: 'Код',
    id: 'no',
    accessor: 'code',
    width: 110,
  },
  {
    Header: 'Наименование',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    id: 'name',
    accessor: 'name',
  },
  {
    Header: 'Серийный номер',
    id: 'serialNo',
    accessor: 'serialNo',
    width: 150,
  },
  {
    Header: 'Кол.',
    id: 'qty',
    accessor: 'qty',
    width: 100,
  },

  {
    Header: 'Цена',
    id: 'unitPrice',
    accessor: 'unitPrice',
    width: 100,
  },
];

export default balanceColumns;
