import React from 'react';
import { Link } from 'react-router-dom';

const DemofondInfo = ({ demofondOrder }) => {
  const { status, customerName, date } = demofondOrder;

  return (
    <section className="demofond-info__container actions-container">
      <Link to="/vad/demofond-list" className="section-nav demofond__section-nav">
        <i className="icon icon-back-icon" /> Список заказов
      </Link>
      <span className="demofond__separator" />
      <div>
        {date && (
          <span>
            <label>Дата обращения:</label> {date}
          </span>
        )}
        {status && (
          <span>
            <label>Статус:</label> {status}
          </span>
        )}
      </div>
      <span className="demofond__separator" />
      <div>
        {customerName && (
          <span>
            <label>Конечный заказчик:</label> {customerName}
          </span>
        )}
      </div>
    </section>
  );
};

export default DemofondInfo;
