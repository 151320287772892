import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import Accept from '../actions-item/components/Accept';

export const makeColumns = props => {
  const { isArch, backName, backLink, accept } = props;

  return [
    {
      Header: 'Вендор',
      accessor: 'brand',
      Cell: row => (row && ReactHtmlParser(row.value)) || null,
      width: 130,
      sortable: false,
      style: {
        padding: '10px 20px',
      },
    },
    {
      Header: 'Период',
      id: 'start',
      accessor: 'sortStart',
      width: 170,
      Cell: row => (row && `${row.original.start} - ${row.original.end}`) || null,
    },
    {
      Header: 'Акция',
      id: 'name',
      accessor: 'name',
      Cell: row =>
        (row && (
          <Link
            to={{
              pathname: `/actions/${row.original.no}`,
              state: { backRef: backLink, backName },
            }}
            className="link link--underlined"
          >
            {row.value}
          </Link>
        )) ||
        null,
      sortable: false,
      style: { textAlign: 'left' },
      width: 300,
    },
    {
      Header: 'Краткое описание',
      accessor: 'description',
      Cell: row => (row && ReactHtmlParser(row.value)) || null,
      sortable: false,
      style: { textAlign: 'left' },
      minWidth: 300,
    },
    {
      Header: 'До завершения',
      id: 'daysUE',
      accessor: d => `${d.daysUE} дней`,
      width: 130,
    },
    {
      Header: 'Прогресс',
      accessor: 'progress',
    },
    {
      Header: '',
      accessor: 'registered',
      Cell: row => {
        if (isArch || !row) {
          return null;
        }

        return <Accept key={row.index} index={row.index} action={row.original} accept={accept} />;
      },
      sortable: false,
      show: !isArch,
      width: 200,
    },
  ];
};
