import React from 'react';
import classnames from 'classnames';
import sortDates from 'helpers/sortDates';
import colorStatusClaimQueue from '../../claims/helpers/color-status-claim-queue';
import colorResultClaimQueue from '../../claims/helpers/color-result-claim-queue';
import { def } from 'common/util';

const ClaimQueueColumns = [
  {
    Header: 'Дата',
    accessor: 'date',
    sortMethod: sortDates,
  },
  {
    Header: 'Команда',
    accessor: 'command',
  },
  {
    Header: 'Состояние',
    accessor: 'status',
    Cell: row =>
      def(row) && (
        <span className={classnames('info-item__text', colorStatusClaimQueue(row.value))}>
          {row.value}
        </span>
      ),
  },
  {
    Header: 'Код',
    accessor: 'itemNo',
  },
  {
    Header: 'Наименование',
    accessor: 'itemName',
  },
  {
    Header: 'Кол',
    accessor: 'itemQty',
  },
  {
    Header: 'Результат',
    accessor: 'result',
    Cell: row =>
      def(row) && (
        <span className={classnames('info-item__text', colorResultClaimQueue(row.value))}>
          {row.value}
        </span>
      ),
  },
];

export default ClaimQueueColumns;
