// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Container } from 'common/layout/container';
import { SearchHints } from 'features/search-hints';
import { UserInfo } from 'features/user/user-info';

import { ExchangeRate } from '../exchange-rate';

import styles from './user-menu.module.scss';

export const UserMenu = ({ className }) => (
  <div className={cn(styles['user-menu'], className)} data-testid="user-menu">
    <Container className={styles['user-menu__container']}>
      <ExchangeRate />
      <SearchHints />
      <UserInfo />
    </Container>
  </div>
);

UserMenu.defaultProps = {
  className: '',
};

UserMenu.propTypes = {
  className: PropTypes.string,
};
