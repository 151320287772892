import { capitalize, highlightText } from 'utils/new/strings';
import Mixpanel from 'services/mixpanel';

export const formatGroups = group => {
  let [child, parent, grandParent] = group.chain;

  if(!grandParent){
    grandParent = parent
  }

  const groups = [...new Set([parent.name, grandParent.name])]
    .map((item) => capitalize(item.toLowerCase()))

  const tree = grandParent
    ? {
      code1: grandParent?.code,
      code2: parent?.code,
      code3: child.code,
    }
    : {
      code1: parent?.code,
      code2: child?.code,
    }

  return {
    id: child.code,
    name: child.name,
    groups,
    requestBody: {
      search: `${grandParent?.name}_${child.name}`,
      tree
    },
  };
};

export const formatName = (name, text) => highlightText(name, text);

export const trackingCardPage = (id, name, from) => () => {
  Mixpanel?.track('стрКарточкаТовара', {
    from,
    id,
    name,
  });
};
