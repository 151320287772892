import React, { useState } from 'react';
import { Field } from 'redux-form';
import { useSelector, useStore } from 'react-redux';
import { getModelLoading } from 'common/selectors';
import TypeField from '../form-fields/type';
import ShipmentDateField from '../form-fields/shipmentDate';
import ShipmentMethodField from '../form-fields/shipmentMethod';
import ShipmentAgentField from '../form-fields/shipmentAgent';
import ShipmentTimeField from '../form-fields/shipmentTime';
import EndpointField from '../form-fields/endpoint';
import CityField from '../form-fields/city';
import ContractorCodeField from '../form-fields/contractorCode';
import RepresentativeField from '../form-fields/representative';
import PackingTypeField from '../form-fields/packingType';
import EndCustomerField from '../form-fields/endCustomer';
import PostingDescriptionField from '../form-fields/postingDescription';
import DeliveryIntervalField from '../form-fields/deliveryInterval';

const FormContent = ({ submitting, onCancel, pickup, setPickup, reset }) => {
  const {
    select: { order },
  } = useStore();

  const { orderNo, docType } = useSelector(order.getHeader);
  const isLoading = useSelector(getModelLoading('order'));

  const handleReset = () => {
    reset();
    onCancel();
  };

  return (
    <div className="order-edit-area">
      <div
        className={['order-edit-header', 'text-left', 'underlined', 'underlined--red'].join(' ')}
      >
        {(orderNo && `ЗАКАЗ ${orderNo}`) || 'НОВЫЙ ЗАКАЗ'}
      </div>
      <div className="row order-edit-form">
        <div className="col">
          <Field name="docType" label="Тип заказа:" docType={docType} component={TypeField} />
          <Field name="shipmentDate" label="Дата отгрузки:" component={ShipmentDateField} />
          <Field
            name="shipmentMethod"
            label="Метод отгрузки:"
            setPickup={setPickup}
            component={ShipmentMethodField}
          />
          <Field
            name="shipmentAgentInfo"
            label="Агент по доставке:"
            component={ShipmentAgentField}
            show={!pickup}
          />
          <Field
            name="shipmentTime"
            label="Время доставки:"
            component={ShipmentTimeField}
            show={!pickup}
          />
          <Field
            name="endpoint"
            label="Конечный адрес и контакт:"
            show={!pickup}
            component={EndpointField}
          />
          <Field name="postCode" label="Город:" show={!pickup} component={CityField} />
        </div>
        <div className="col">
          <Field
            name="contractorCode"
            label="Контрагент/Договор:"
            component={ContractorCodeField}
          />
          <Field name="representative" label="Представитель:" component={RepresentativeField} />
          <Field name="packingType" label="Упаковка:" component={PackingTypeField} />
          <Field
            name="endCustomer"
            label="Комментарии по заказчику:"
            component={EndCustomerField}
          />
          <Field
            name="postingDescription"
            label="Примечание:"
            component={PostingDescriptionField}
          />
          <Field
            name="shipmentAgentDeliveryInterval"
            show={!pickup}
            component={DeliveryIntervalField}
          />
        </div>
      </div>
      <div className="form-actions">
        <button
          type="submit"
          className="btn btn--rect btn-danger"
          disabled={submitting || isLoading}
          style={{ marginRight: 15 }}
        >
          Сохранить заголовок
        </button>
        <button type="button" className="btn btn--rect btn-outline-danger" onClick={handleReset}>
          Отмена
        </button>
      </div>
    </div>
  );
};

export default FormContent;
