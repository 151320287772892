import { useState, useLayoutEffect } from 'react';

/**
 * Hook to update containers height while window resizing
 *
 * @param {string} containerId - id of container to update height on
 * @param {number} initHeight - initial height of container
 */
export default (containerId, initHeight) => {
  const [height, setHeight] = useState(initHeight);

  useLayoutEffect(() => {
    const container = document.getElementById(containerId);

    function updateHeight() {
      setHeight(
        document.documentElement.clientHeight -
          Number(container?.offsetTop) -
          Number(document.getElementById('footer')?.offsetHeight) -
          35,
      );
    }

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, []); // eslint-disable-line

  return height;
};
