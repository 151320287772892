import React from 'react';
import { Field } from 'redux-form';
import sortStringNumbers from 'helpers/sortStringNumbers';
import curryCreateFieldName from '../helpers/curryCreateFieldName';
import { ALLOWED_EXTENSIONS, CLAIMS_FORM } from '../constants';
import normalizeNumberField from '../normalizers/normalizeNumberField';
import { RenderSelectClaim, RenderInputFileClaim, RenderInputClaim } from '../fields';
import { RenderHideInput } from 'components/fields';
import checkMaxLength from '../helpers/check-length';

const createColumns = fieldsGroupName => {
  const createFieldName = curryCreateFieldName(fieldsGroupName);

  return [
    {
      Header: 'Номер',
      accessor: 'no',
      width: 120,
      Cell: row => {
        if (!row) return null;

        const { index, value } = row;
        return (
          <React.Fragment>
            <Field
              name={createFieldName('itemId', index)}
              defaultValue={value}
              component={RenderHideInput}
            />
            {value}
          </React.Fragment>
        );
      },
    },
    {
      Header: 'Описание',
      headerStyle: { textAlign: 'left' },
      accessor: 'name',
      style: { textAlign: 'left' },
      width: 310,
      sortable: false,
    },
    {
      Header: 'Кол',
      accessor: 'orderedQty',
      width: 50,
      sortMethod: sortStringNumbers,
    },
    {
      Header: 'Факт. Кол.',
      accessor: '',
      sortable: false,
      width: 80,
      Cell: row => {
        if (!row) return null;

        const { index } = row;
        return (
          <Field
            name={createFieldName('factQty', index)}
            handle={normalizeNumberField({
              min: 0,
            })}
            maxLength={5}
            component={RenderInputClaim}
          />
        );
      },
    },
    {
      Header: 'Тип претензии',
      accessor: '',
      sortable: false,
      width: 170,
      className: 'type-claim',
      Cell: row => {
        if (!row) return null;

        const { index } = row;
        const claimsTypeFieldName = createFieldName('claimType', index);
        return (
          <Field
            name={claimsTypeFieldName}
            placeholder="Выбрать тип претензии"
            component={RenderSelectClaim}
          />
        );
      },
    },
    {
      Header: () => {
        const allowedExtensions = ALLOWED_EXTENSIONS.join(',');
        return (
          <p className="claims-new__table__head">
            Вложение
            <br />
            <span className="claims-new__table__note">
              {`(${allowedExtensions} `}
              <span className="claims-new__table__required">максимум 10 Mb</span>
            </span>
          </p>
        );
      },
      accessor: '',
      width: 250,
      sortable: false,
      Cell: row => {
        if (!row) return null;

        const { index } = row;
        const fieldKey = createFieldName('file', index);

        return (
          <Field
            accept=".gif, .jpg, .doc, .xls, .zip, .rar"
            name={fieldKey}
            formName={CLAIMS_FORM}
            component={RenderInputFileClaim}
            style={{ width: '260px', marginTop: '3px' }}
          />
        );
      },
    },
    {
      Header: () => {
        return (
          <p className="claims-new__table__head">
            Пояснения по факту расхождения
            <br />
            <span className="claims-new__table__note">(максимум 100 символов)</span>
          </p>
        );
      },
      accessor: '',
      width: 200,
      sortable: false,
      headerStyle: { textAlign: 'left' },
      Cell: row => {
        if (!row) return null;

        const { index } = row;

        return (
          <Field
            name={createFieldName('comment', index)}
            handle={checkMaxLength(100)}
            component={RenderInputClaim}
          />
        );
      },
    },
  ];
};

export default createColumns;
