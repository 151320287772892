import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Computer = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect x="3.5" y="0.5" width="11" height="17" rx="1.5" stroke={color} />
      <rect x="3.25" y="4.25" width="11.5" height="0.5" stroke={color} strokeWidth="0.5" />
      <rect x="3.25" y="6.25" width="11.5" height="0.5" stroke={color} strokeWidth="0.5" />
      <rect x="5.25" y="9.25" width="2.5" height="0.5" stroke={color} strokeWidth="0.5" />
      <rect x="10.25" y="9.25" width="0.5" height="0.5" stroke={color} strokeWidth="0.5" />
      <rect x="12.25" y="9.25" width="0.5" height="0.5" stroke={color} strokeWidth="0.5" />
    </svg>
  );
};

Computer.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Computer.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
