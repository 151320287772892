// @ts-nocheck
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export const fetchCurrency = (dispatch, type) => async (_, state) => {
  try {
    if (!isEmpty(state.newUser.currency)) {
      dispatch[type].setCurrency({});
    }
    const { id } = get(state, ['user', 'info'], {}) || {};

    const payload = await dispatch.api.get({
      url: `new-currency/settings/get${id ? `/${id}` : ''}`,
    });

    dispatch[type].setCurrency(payload);
    return payload;
  } catch (err) {
    return {};
  }
};

export const fetchSettings = (dispatch, type) => async (_, state) => {
  try {
    const { uid } = get(state, 'user', {}) || {};

    const payload = await dispatch.api.get({
      url: `new-view/settings/get${uid ? `/${uid}` : ''}`,
    });

    dispatch.newUser.loadSettings(payload);
    return payload;
  } catch (err) {
    return {};
  }
};
