import React from 'react';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import { EditableSelect } from 'components/fields';

const selectVendor = ({
  required,
  label,
  input: { value, onChange, onBlur },
  meta: { touched, error },
}) => {
  const {
    specialPrice: { searchVendors },
  } = useDispatch();

  const getAsyncOptions = (inputValue, callback) => {
    if (!inputValue.length) {
      return callback();
    }

    searchVendors(inputValue)
      .then(hits =>
        hits.map(({ name }) => ({
          label: name,
          value: name,
        })),
      )
      .then(options => {
        callback(null, { options });
      })
      .catch(err => callback(err, null));
  };

  const loadOptions = debounce(getAsyncOptions, 1000);

  const handleChange = newValue => {
    onChange(newValue === null ? newValue : newValue.value);
    onBlur();
  };

  return (
    <div className="col-sm-4">
      <label className={required ? 'required' : ''}>{label}</label>
      <EditableSelect
        placeholder={value ? '' : 'Начните ввод'}
        searchPromptText=""
        loadingPlaceholder="Загрузка"
        loadOptions={loadOptions}
        value={value}
        inputValue={value}
        onChange={handleChange}
        onInputChange={onChange}
        valueKey="value"
        labelKey="label"
        autosize={false}
        async={true}
        onSelectResetsInput={false}
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        onBlurSetInputValue={true}
      />
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </div>
  );
};

export default selectVendor;
