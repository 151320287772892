import React from 'react';
import Content from 'components/layout/content';
import PeriodPicker from '../common/period-picker';
import TableList from 'components/table/list';
import turnoverColumns from '../common/columns/turnoverColumns';

const defaultSorted = [
  {
    id: 'date',
    desc: true,
  },
];

const Turnover = () => (
  <Content title="Оборот">
    <div className="finances-content">
      <PeriodPicker
        type="turnover"
        model="finances"
        effect="getTurnover"
        diffDates={365}
        position="left"
      >
        {({ isRequested }) => {
          if (!isRequested) {
            return null;
          }

          return (
            <div className="finances-list">
              <TableList defaultSorted={defaultSorted} columns={turnoverColumns} />
            </div>
          );
        }}
      </PeriodPicker>
    </div>
  </Content>
);

export default Turnover;
