import React, { useEffect } from 'react';
import NewsItem from './NewsItem';
import Pagination from './Pagination';
import useFlexPagination from '../../../hooks/useFlexPagination';
import Preloader from 'components/preloader';
import { Link } from 'react-router-dom';
import Mixpanel from 'services/mixpanel';
import { useDispatch, useSelector } from 'react-redux';
import { getNewsList } from 'pages/Marketing/selectors';
import { getEffectLoading } from 'common/selectors';

const NewsList = () => {
  const items = useSelector(getNewsList);
  const loading = useSelector(getEffectLoading('news', 'get'));
  const {
    news: { get },
  } = useDispatch();

  useEffect(() => {
    get();
  }, []);

  const itemsArr = Array.isArray(items) ? items : [];
  const [result, newsRef, handleChangePage] = useFlexPagination(itemsArr, 100);
  const { elements, pages, page } = result;
  const showPagination = !!elements.length && !loading;

  return (
    <div>
      <div className="news-list news-list__row">
        <h5>
          <i className="icon icon-news" />
          <Link
            to="/news"
            onClick={() =>
              Mixpanel?.track('СтрГлавнаяСтраница.Новости', {
                page: 'СтрГлавнаяСтраница',
              })
            }
          >
            Новости недели
          </Link>
        </h5>
        {loading && <Preloader />}
        {!loading && (
          <div ref={newsRef} className="item-list__container">
            {elements.map((elem, index) => {
              const show = page - 1 === index ? 'block' : 'none';
              const style = { display: show };

              return (
                <ul style={style} key={index}>
                  {elem.map(news => (
                    <NewsItem news={news} key={news.newsId} />
                  ))}
                </ul>
              );
            })}
          </div>
        )}
        {showPagination && (
          <div className="news-list__pagination">
            <Pagination
              page={page}
              pages={pages}
              onChangePage={handleChangePage}
              parent="Новости"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsList;
