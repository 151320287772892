/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import MenuRenderer from './new-shipment-menu';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { RenderSelect } from 'components/fields';
import { getEffectLoading } from 'common/selectors';
import { SHIPMENT_SCHEDULE_FORM } from 'pages/Logistic/constants';

const inputProps = {
  maxLength: 160,
};

export const MultiSelect = props => {
  const {
    input: { value, onChange },
    meta: { touched, error },
    options,
    label,
    required,
    ...rest
  } = props;

  const [selectedValues, setSelectedValues] = useState(value);
  const [updatedOptions, setOptionsUpdate] = useState(options);

  const onChangeSelect = values => {
    const newValues = values?.split(',') || [];
    setSelectedValues(newValues);
    onChange(newValues);
    const selectedItem = options.find(option => option.value === newValues[0]);

    const newOptions = selectedItem
      ? options.map(option => {
          const isDisabled = option.logisticObject !== selectedItem.logisticObject;
          return {
            ...option,
            isDisabled,
          };
        })
      : options;

    setOptionsUpdate(newOptions);
  };

  return (
    <React.Fragment>
      {label && <label className={required ? 'required' : ''}>{label}</label>}
      {error && touched && <span className="text-danger text-danger--small">{error}</span>}
      <Select
        multi
        options={(updatedOptions.length && updatedOptions) || options}
        onChange={onChangeSelect}
        value={selectedValues && selectedValues.length ? selectedValues : value}
        menuRenderer={MenuRenderer}
        removeSelected={false}
        closeOnSelect={false}
        simpleValue
        inputProps={inputProps}
        defaultValue={value}
        {...rest}
      />
    </React.Fragment>
  );
};

export const ScheduleTimeField = ({ orders = [], ...rest }) => {
  const {
    orderNo = [],
    date,
    no,
    timeInterval,
    timeIntervalCode,
  } = useSelector(getFormValues(SHIPMENT_SCHEDULE_FORM)) || {};

  const isLoading = useSelector(getEffectLoading('shipments', 'getPlanTime'));

  const [options, setOptions] = useState([]);
  const {
    shipments: { getPlanTime },
  } = useDispatch();

  useEffect(() => {
    if (!no && orders.length) {
      const order = orders.find(({ value }) => orderNo.find(no => no === value));

      getPlanTime({
        date,
        orders: orderNo,
        logisticObject: order?.logisticObject,
      }).then(setOptions);
    } else {
      setOptions([
        {
          value: timeIntervalCode,
          label: timeInterval,
        },
      ]);
    }
  }, [orders.length, date, orderNo.length]);

  return <RenderSelect options={options} isLoading={isLoading} {...rest} />;
};
