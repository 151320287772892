import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../icon.module.scss';

export const Appliances = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M13.5 10C13.5 12.4853 11.4853 14.5 9 14.5C6.51472 14.5 4.5 12.4853 4.5 10C4.5 7.51472 6.51472 5.5 9 5.5C11.4853 5.5 13.5 7.51472 13.5 10Z"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 10C11.5 11.3807 10.3807 12.5 9 12.5C7.61929 12.5 6.5 11.3807 6.5 10C6.5 8.61929 7.61929 7.5 9 7.5C10.3807 7.5 11.5 8.61929 11.5 10Z"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="2" y="3" width="14" height="1" fill={color} />
    <rect x="2" y="1" width="14" height="16" rx="2" stroke={color} />
  </svg>
);

Appliances.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Appliances.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
