import Mixpanel from 'services/mixpanel';
import notifier from 'services/new/notifier';
import { getSource } from 'services/mixpanel/utils/get-source';

export default ({ newOrdersNew, api }) =>
  async ({ order, items }) => {
    notifier.show(order, `Состав заказа ${order} изменяется`);
    const ids = Object.keys(items.qtys);

    newOrdersNew.changeRows({
      searchKey: 'id',
      needle: ids,
      [`status_${order}`]: 'act',
    });


    const { qtys, countItems } = Object.values(items.qtys).reduce(
      (acc, value) => ({
        qtys: acc.qtys + value,
        countItems: acc.countItems + 1,
      }),
      { qtys: 0, countItems: 0 },
    );

    const track = order ? 'Заказ.ДобавитьВЗаказ' : 'СтрКаталог.НовыйЗаказВыбор';

    Mixpanel?.track(track, {
      заказ: order,
      источник: getSource(),
      строки: countItems,
      количество: qtys,
    });

    try {
      newOrdersNew.setLoadingOrders({ orderNo: order, isFetching: true, isItems: true });
      await api.post({
        url: `/orders/edit/rows/${order}`,
        data: items,
      });
    } catch (err) {
      console.error(err);
      notifier.resolve({ message: `Неудачная попытка изменения состава заказа ${order}!` });

      newOrdersNew.changeRows({
        searchKey: 'id',
        needle: ids,
        [`status_${order}`]: null,
      });
    } finally {
      newOrdersNew.setLoadingOrders({ orderNo: order, isFetching: false, isItems: true });
    }
  };
