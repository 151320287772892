import React from 'react';
import Content from 'components/layout/content';
import ContactsSections from './components/ContactsSections';
import { useSelector, useStore } from 'react-redux';
import ContactsManagers from './components/ContactsManagers';

const Contacts = () => {
  const {
    select: { user },
  } = useStore();
  const contacts = useSelector(user.getDecoratedContacts);

  return (
    <Content title="Контакты">
      <div className="help-content">
        <div className="main-block">
          <ContactsSections columns={2} contactsSections={contacts} />
          <ContactsManagers />
        </div>
      </div>
    </Content>
  );
};

export default Contacts;
