const colorStatusDemofond = status => {
  switch (status) {
    case 'Резерв оборудования':
      return 'status--black';

    case 'Обработка заказа':
    case 'Подготовка к передаче':
    case 'Готов к передаче':
      return 'status--blue';

    case 'Оборудование предоставлено':
      return 'status--orange';

    case 'Оборудование возвращено':
    case 'Заказ завершен':
      return 'status--green';

    case 'Наступление срока возврата':
    case 'Отклонена':
      return 'status--red';
    default:
      return 'status--black';
  }
};

export default colorStatusDemofond;
