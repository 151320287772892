import React, {useEffect, useState, useRef, memo} from 'react'
import {unstable_batchedUpdates} from "react-dom"
import { Route } from 'react-router-dom';
import View from 'components/view';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import Mixpanel from 'services/mixpanel';
import { checkConfirmation, checkNewVersion, checkApproval } from './helpers/transition';
import LoadingBar from "components/loading-bar"
import {useSelector, useStore} from "react-redux"
import {usePrev} from "hooks"
import useUpdateEffect from 'hooks/use-update-effect';

const DataTransition = memo(function DataTransition(props){
  const { location, manual, isAuth, version, confirmed, isCredPresence, routes: propRoutes = [] } = props;
  const { fetchRoute, ...rest } = props;
  const received = props?.user?.approvalReceived === "true";
  const {
    select: { user, api },
  } = useStore();

  const routes = useSelector(user.getRoutes(propRoutes))
  const appFetchingError = useSelector(api.getAppFetchingError, isEqual);

  const [currentLocation, setCurrentLocation] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(isCredPresence);

  const prevConfirmed = useRef(confirmed);

  const path = `${location.pathname}${location.search}`;
  const prevPath = usePrev(path)

  useEffect(() => {
    checkNewVersion(version);
  }, [version]); // eslint-disable-line

  useUpdateEffect(() => {
    checkConfirmation(props, prevConfirmed.current);
    prevConfirmed.current = confirmed;
  }, [confirmed]);

 /* useEffect(() => {
    checkApproval({ isAuth, received })
  }, [isAuth, received]);*/


  useEffect(() => {
    if (!manual) {
      fetchRoute({ location, routes }).then(() => {
        unstable_batchedUpdates(() => {
          setCurrentLocation(location);
          setIsAuthenticated(isAuth);
        })

        Mixpanel?.changePage(prevPath, path, routes);
      });
    } else if (!isAuth) {
      setIsAuthenticated(isAuth);
    }
  }, [path, isAuth, manual]); // eslint-disable-line

  return (
    <>
      <LoadingBar />
      <Route
        location={currentLocation}
        render={() =>
          (!isEmpty(currentLocation) || appFetchingError.status) && <View {...rest} {...appFetchingError} isAuthenticated={isAuthenticated} />
        }
      />
    </>
  );
}, isEqual);

DataTransition.whyDidYouRender = false;
DataTransition.displayName = 'DataTransition'

export default DataTransition;
