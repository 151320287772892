import React from 'react';

const Shape = props => {
  const { x, y, height, width, status, count } = props;

  const maxWidth = count > 15 ? 20 : 40;

  const className =
    status === 'Expired'
      ? 'chart-block__shape_expired'
      : status === 'Pay5d'
      ? 'chart-block__shape_pay5d'
      : 'chart-block__shape_pay30d';

  if (width > maxWidth) {
    const customX = (width - maxWidth) / 2 + x;
    return (
      <path
        d={`M ${customX},${y} h ${maxWidth} v ${height} h -${maxWidth} Z`}
        className={`chart-block__shape ${className}`}
      />
    );
  }

  return (
    <path
      d={`M ${x},${y} h ${width} v ${height} h -${width} Z`}
      className={`chart-block__shape ${className}`}
    />
  );
};

export default Shape;
