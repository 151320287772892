export const BRAND_KEY = 'brand';
export const PRODUCT_SUB_GROUP_TABLE_KEY = 'group3';
export const PRODUCT_SUB_GROUP_SERVER_KEY = 'code3';
export const PRODUCT_GROUP_TABLE_KEY = 'group2';
export const PRODUCT_GROUP_SERVER_KEY = 'code2';

export const ORDER_LIST_FILTER_FORM = 'ORDER_LIST_FILTER_FORM';

export const LOAD_SERVER_STRATEGY = 'LOAD_SERVER_STRATEGY';
export const LOAD_TABLE_STRATEGY = 'LOAD_TABLE_STRATEGY';
export const LOAD_FACETS_STRATEGY = 'LOAD_FACETS_STRATEGY';

export const FILTER_BY_TILDA_VALUES = 'FILTER_BY_TILDA_VALUES';
export const FILTER_BY_PROPERTIES = 'FILTER_BY_PROPERTIES';
export const FILTER_BY_TEXT_INPUT = 'FILTER_BY_TEXT_INPUT';
export const FILTER_BY_OTHER = 'FILTER_BY_OTHER';

export const FILTER_TILDA_PROPERTY = 'propFiltersValueSet'; // TODO: remove
export const FILTER_TILDA_PROPERTIES = 'propFiltersValues'; // TODO: rename to FILTER_TILDA_PROPERTY

export const DEFAULT_TEXT_INPUT_VALUE = '';
export const DEFAULT_CHECKBOX_VALUE = false;
export const DEFAULT_LIST_VALUE = false;

export const filterStrategies = {
  buildFilterOnMountStrategy: LOAD_FACETS_STRATEGY,
  defaultBuildStrategy: LOAD_FACETS_STRATEGY,
};
