import React from 'react';
import Select from 'react-select';
import CustomDayPickerInput from 'components/customDayPickerInput';

export const RenderDatePicker = ({
  colSm,
  input: { value, onChange },
  label,
  required,
  meta: { touched, error },
}) => (
  <div className={colSm ? `col-sm-${colSm}` : 'col-sm-6'}>
    <div className="datepicker-input">
      <label className={required ? 'required' : ''}>{label}</label>
      <div className="datepicker-wrap">
        <CustomDayPickerInput
          value={value}
          dayPickerProps={{
            disabledDays: [{ before: new Date() }],
          }}
          onDateInputChange={onChange}
        />
      </div>
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </div>
  </div>
);

export const RenderSelect = ({
  input: { value, onChange },
  name,
  label,
  options,
  placeholder,
  handle,
  required,
  meta: { touched, error },
}) => (
  <div className="col-sm-6">
    <label className={required ? 'required' : ''}>{label}</label>
    <Select
      autosize
      clearable={false}
      name={name}
      options={options}
      placeholder={placeholder}
      searchable={false}
      value={value}
      onChange={newValue => {
        if (typeof handle === 'function') {
          handle(newValue);
        }
        onChange(newValue.value);
      }}
    />
    {touched && error && <span className="text-danger text-danger--small">{error}</span>}
  </div>
);
