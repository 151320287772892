// @ts-nocheck
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { SIGN, UNSIGN } from '../constants';

const editStatus = (orderNo, order, status, apiStatus) =>
  order.orderNo === orderNo ? { ...order, ...apiStatus[status] } : order;

export const changeOrder = (order, payload) => {
  const fix = get(payload, ['fix'], null);
  const orderNo = get(fix, ['id'], 0);
  const { order: socketOrder } = fix.payload;
  const command = get(fix, ['payload', 'data', '0', 'command'], '')?.toLowerCase();

  let status = '-';

  if (!fix.done && fix.error === null && !fix.finish) {
    status = 'fetching';
  }

  if (fix.done && fix.error === null && !fix.finish) {
    status = 'done';
  }
  if (fix.done && fix.error !== null && !fix.finish) {
    status = 'error';
  }
  if (fix.done && fix.finish) {
    status = 'finish';
  }

  switch (command) {
    case 'sign':
      return editStatus(orderNo, order, status, SIGN);

    case 'unsign':
      return editStatus(orderNo, order, status, UNSIGN);

    default:
      if (!isEmpty(socketOrder)) {
        return { ...order, ...socketOrder };
      }

      return order;
  }
};
