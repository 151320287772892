import React, { memo } from 'react';
import classNames from 'classnames';
import { getLCYCurrencySymbol } from 'common/util';
import priceFormat from '../../../../../helpers/priceFormat';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import Preloader from 'components/preloader';

const InfoAvailability = ({ productAvailability }) => {
  const isAvailabilityFetching = useSelector(getEffectLoading('product', 'getAvailability'));
  const isOrderFetching = useSelector(getEffectLoading('order', 'getOrder'));

  if (isAvailabilityFetching || isOrderFetching) {
    return <Preloader />;
  }

  if (isEmpty(productAvailability)) {
    return null;
  }

  return (
    <table className="info__table">
      <thead>
        <tr>
          <td className={classNames('font-size__small', 'font-color__grey')}>Доступное</td>
          <td className={classNames('font-size__small', 'font-color__grey')}>Склад</td>
          <td className={classNames('font-size__small', 'font-color__grey')}>Транзит</td>
          <td className={classNames('font-size__small', 'font-color__grey')}>
            Цена{', ' + getLCYCurrencySymbol('РУБ')}
          </td>
          <td className={classNames('font-size__small', 'font-color__grey')}>РРЦ</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={classNames('font-size__regular', 'font-color__black')}>
            <strong>{productAvailability.availableClient}</strong>
          </td>
          <td className={classNames('font-size__regular', 'font-color__black')}>
            <strong>{productAvailability.inventoryMSK1}</strong>
          </td>
          <td className={classNames('font-size__regular', 'font-color__black')}>
            <strong>{productAvailability.availableExpected}</strong>
          </td>
          <td className={classNames('font-size__regular', 'font-color__black')}>
            <strong>{productAvailability.priceLCY}</strong>
          </td>
          <td className={classNames('font-size__regular', 'font-color__black')}>
            <strong>{priceFormat(productAvailability.RRP)}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default memo(InfoAvailability, isEqual);
