export const validBrowsers = {
  windows: {
    edge: '>=16',
  },
  macos: {
    safari: '>10.1',
  },
  mobile: {
    safari: '>=9',
    'android browser': '>3.10',
  },
  chrome: '>=40',
  firefox: '>=40',
  opera: '>=36',
  yandex: '>=16',
};
