import React from 'react';
import PropTypes from 'prop-types';
import renderLinks from 'utils/renderLinks';
import { routesConfig } from 'common/collections';

const KEY = 0;
const PARENT_NAME = '';
const IS_DESIGN_V2 = true;

export const AccessMenu = ({ menu, permissions }) => (
  <>
    {renderLinks(
      Object.values(routesConfig.get()),
      permissions,
      menu,
      KEY,
      PARENT_NAME,
      IS_DESIGN_V2,
    )}
  </>
);

AccessMenu.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.object).isRequired,
};
