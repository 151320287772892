import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import alert from 'components/native/alert/function';
import withApi from 'hoc/withApi';

function Confirm() {
  const { success, message } = useSelector(({ personal: { confirm } }) => confirm);
  const {
    user: { setConfirmation },
  } = useDispatch();

  const auth = useSelector(state => state.auth);

  useEffect(() => {
    alert(`${message}${(!auth && '<br />Войдите для продолжения работы') || ''}`);

    if (success) {
      setConfirmation({ success: true });
    }
  }, [auth, message, success]);

  return <Redirect to="/settings/personal" />;
}

export default withApi(Confirm);
