/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, memo, useEffect} from 'react'
import { batch, useDispatch, useSelector, useStore } from 'react-redux';
import classNames from 'classnames';
import {
  change as reduxFormChange,
  reset as reduxFormReset,
  initialize as reduxFormInitialize,
} from 'redux-form';
import createDefaultFilterValue from '../order-list-filter/helpers/createDefaultFilterValue';
import { ORDER_LIST_FILTER_FORM } from '../order-list-filter/constants';
import {FiltersCollector} from "common/collections"

const CLEAR_ALL_FILTER_KEY = 'all';

const FilterTag = props => {
  const { children, keyType, code, groupId, ...wrapperProps } = props;

  useEffect(() => {
    FiltersCollector.push('availableFilters', groupId, code)

    return () => {
      FiltersCollector.pull('availableFilters', groupId, code)
    }
  }, [groupId, keyType])

  return (
    <a {...wrapperProps} href={`#disable-filter__${keyType}`} className="filter-tags__tag">
      <p className={classNames('icon', 'icon-close-grey', 'filter-tags__tag__icon')} />
      <span className="filter-tags__tag__text">{children}</span>
    </a>
  );
};

const FilterTags = () => {
  const {
    select: { filters },
  } = useStore();

  const dispatch = useDispatch();
  const filterTags = useSelector(filters.getTagsWithoutKey);

  const handleFilterTagClick = useCallback(
    filterTag => () => {
      batch(() => {
        dispatch(
          reduxFormChange(
            ORDER_LIST_FILTER_FORM,
            filterTag.name,
            createDefaultFilterValue(filterTag),
          ),
        );
        dispatch.filters.setTags({ name: filterTag.name });
      });
    },
    [],
  );

  const handleClearAllTags = useCallback(() => {
    batch(() => {
      dispatch(reduxFormReset(ORDER_LIST_FILTER_FORM));
      dispatch(reduxFormInitialize(ORDER_LIST_FILTER_FORM, {}));
      dispatch.filters.setTags({});
    });
  }, []);

  if (!filterTags.length) {
    return null;
  }

  return (
    <div className="filter-tags__wrapper">
      <div className="filter-tags">
        {filterTags.map(filterTag => {
          const { keyType, value, name } = filterTag;

          return (
            <FilterTag key={name} {...filterTag} onClick={handleFilterTagClick(filterTag)}>
              {(keyType === 'other' ? '' : keyType + ': ') + value}
            </FilterTag>
          );
        })}
        <FilterTag
          key={CLEAR_ALL_FILTER_KEY}
          filterKey={CLEAR_ALL_FILTER_KEY}
          onClick={handleClearAllTags}
        >
          Очистить все
        </FilterTag>
      </div>
    </div>
  );
};

FilterTags.whyDidYouRender = false;

export default memo(FilterTags);
