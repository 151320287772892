import React from 'react';
import { Link } from 'react-router-dom';
import forbiddenImage from 'img/forbidden.png';
import useRouteConfig from 'hooks/use-route-config';

import styles from './forbidden.module.scss';

export const Forbidden = ({ message = '' }) => {
  const { name } = useRouteConfig();

  return (
    <div className={styles['forbidden']}>
      <img alt="forbidden" src={forbiddenImage} width="117" height="144" />
      <span className={styles['text']}>
        {message || `У Вас нет доступа к разделу ${name}.`} Обратитесь к вашему менеджеру
      </span>
      <Link to="/" className="link--button">
        Вернуться на главную
      </Link>
    </div>
  );
};
