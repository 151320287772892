import React from 'react';
import sortDates from 'helpers/sortDates';
import { AttachmentsCell } from './cells';

export default [
  {
    Header: 'Вложения',
    width: 350,
    accessor: 'attachments',
    id: 'attachments',
    sortable: false,
    Cell: AttachmentsCell,
  },
  {
    Header: 'Код',
    width: 100,
    accessor: 'no',
    id: 'no',
  },
  {
    Header: 'Наименование',
    width: 730,
    accessor: 'name',
    id: 'name',
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
  },
  {
    Header: 'Сертификат',
    width: 230,
    accessor: 'certName',
    id: 'certName',
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
  },
  {
    Header: 'Дата выдачи',
    width: 130,
    accessor: 'dateIssued',
    id: 'dateIssued',
    sortMethod: sortDates,
  },
  {
    Header: 'Дата окончания',
    width: 130,
    accessor: 'dateExpires',
    id: 'dateExpires',
  },
  {
    Header: 'Кем выдан',
    width: 110,
    accessor: 'issuer',
    id: 'issuer',
  },
];
