import React from 'react';
import Content from 'components/layout/content';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { getNewsList } from 'pages/Marketing/selectors';
import withApi from 'hoc/withApi';

const NewsItem = () => {
  const news = useSelector(getNewsList);

  return (
    <Content title={news.title}>
      <div className="news-item">
        <div className="news_date">{news.created}</div>
        {ReactHtmlParser(news.content)}
      </div>
    </Content>
  );
};

export default withApi(NewsItem);
