// @ts-nocheck
import isEmpty from 'lodash/isEmpty';

export const formatDataOrderList = (data = []) => {
  if (isEmpty(data)) {
    return data;
  }

  return {
    filters: [
      {
        id: 'status',
        values: data.status.value.split(','),
      },
      {
        id: 'contact',
        values: [data.contacts.value],
      },
    ],
    sort: [
      {
        id: data.sort.value,
        desc: data.sort.desc,
      },
    ],
  };
};
