import { DEFAULT_LIST_VALUE, FILTER_BY_OTHER } from '../../constants';

export default filterGroups => {
  const inOtherGroup = {
    name: null,
    key: 'other',
    type: FILTER_BY_OTHER,
    filters: [
      {
        key: 'lineNo',
        name: 'Товар в заказе',
        value: 'lineNo',
        defaultValue: null,
        selected: DEFAULT_LIST_VALUE,
      },
      {
        key: 'desiredQtyGreaterQty',
        name: 'ЖКол > Кол',
        value: 'desiredQtyGreaterQty',
        defaultValue: null,
        selected: DEFAULT_LIST_VALUE,
      },
    ],
  };

  return [...filterGroups, inOtherGroup];
};
