import React from 'react';
import { Player } from 'video-react';
import ReactHtmlParser from 'react-html-parser';

export const sanitizeContent = content => {
  let media = null;

  const script = /<script .+>(.+)<\/script>/gi.exec(content);

  if (script) {
    media = /[^{]+([^}]+})/gi.exec(script[1]);

    try {
      media = JSON.parse(media[1] || '{}');
      media.src = media.src.includes('https') ? media.src : `https://${media.src}`;

      return (
        <Player playsInline width={media.width} height={media.height} poster={`${media.src}.jpg`}>
          <source src={media.src} />
        </Player>
      );
    } catch (exception) {
      return ReactHtmlParser(content);
    }
  } else {
    return ReactHtmlParser(content);
  }
};
