import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Cancel = ({
  className,
  color = '#236192',
  name,
  width = '12',
  height = '12',
  viewBox = '0 0 12 12',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M3 3L9 9" stroke={color} strokeLinecap="round" />
      <path d="M9 3L3 9" stroke={color} strokeLinecap="round" />
    </svg>
  );
};

Cancel.defaultProps = {
  className: '',
  color: '#236192',
  width: '12',
  height: '12',
  viewBox: '0 0 12 12',
};

Cancel.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
