import {
  SET_FILTER_CONTACT_VALUE,
  SET_FILTER_NO_VALUE,
  SET_FILTER_OZPR_VALUE,
  SET_JOINED,
  SET_SEARCH_ORDER_VALUE,
} from '../action/index';

export const initialFiltersState = {
  filterNoValue: '',
  filterOzprValue: '',
  filterContactValue: '',
  searchOrderValue: '',
  joined: {
    id: 0,
    index: 0,
  },
};

export default (state, action) => {
  switch (action.type) {
    case SET_FILTER_NO_VALUE:
      return { ...state, filterNoValue: action.payload, filterOzprValue: '' };
    case SET_FILTER_OZPR_VALUE:
      return { ...state, filterOzprValue: action.payload, filterNoValue: '' };
    case SET_FILTER_CONTACT_VALUE:
      return { ...state, filterContactValue: action.payload };
    case SET_SEARCH_ORDER_VALUE:
      return { ...state, searchOrderValue: action.payload };
    case SET_JOINED:
      return { ...state, joined: action.payload };
    default:
      return state;
  }
};
