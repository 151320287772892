import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Csv = ({
  className,
  color = '#236192',
  name,
  width = '25',
  height = '33',
  viewBox = '0 0 25 33',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M1 1H15L24.0263 10L24 32H1V1Z" stroke={color} stroke-linejoin="round" />
      <path d="M15 10H24L15 1V10Z" stroke={color} stroke-linejoin="round" />
      <path
        d="M8.724 28.196C8.484 28.38 8.18 28.508 7.812 28.58C7.444 28.652 7.052 28.688 6.636 28.688C6.132 28.688 5.66 28.604 5.22 28.436C4.78 28.26 4.396 27.996 4.068 27.644C3.74 27.292 3.48 26.844 3.288 26.3C3.096 25.748 3 25.096 3 24.344C3 23.56 3.104 22.896 3.312 22.352C3.528 21.8 3.808 21.352 4.152 21.008C4.504 20.656 4.896 20.4 5.328 20.24C5.76 20.08 6.2 20 6.648 20C7.128 20 7.52 20.028 7.824 20.084C8.128 20.132 8.388 20.2 8.604 20.288L8.364 21.164C7.98 20.98 7.432 20.888 6.72 20.888C6.392 20.888 6.068 20.948 5.748 21.068C5.428 21.188 5.14 21.384 4.884 21.656C4.636 21.928 4.436 22.284 4.284 22.724C4.132 23.164 4.056 23.704 4.056 24.344C4.056 24.92 4.128 25.424 4.272 25.856C4.416 26.288 4.612 26.648 4.86 26.936C5.116 27.224 5.412 27.44 5.748 27.584C6.092 27.728 6.464 27.8 6.864 27.8C7.224 27.8 7.536 27.764 7.8 27.692C8.072 27.62 8.3 27.528 8.484 27.416L8.724 28.196Z"
        fill={color}
      />
      <path
        d="M13.9955 26.408C13.9955 26.072 13.8955 25.8 13.6955 25.592C13.4955 25.376 13.2435 25.188 12.9395 25.028C12.6435 24.86 12.3195 24.704 11.9675 24.56C11.6155 24.416 11.2875 24.244 10.9835 24.044C10.6875 23.836 10.4395 23.584 10.2395 23.288C10.0395 22.984 9.93947 22.6 9.93947 22.136C9.93947 21.48 10.1675 20.96 10.6235 20.576C11.0795 20.192 11.7275 20 12.5675 20C13.0555 20 13.4995 20.036 13.8995 20.108C14.2995 20.18 14.6115 20.272 14.8355 20.384L14.5355 21.26C14.3515 21.172 14.0795 21.088 13.7195 21.008C13.3675 20.928 12.9675 20.888 12.5195 20.888C11.9915 20.888 11.5955 21.008 11.3315 21.248C11.0675 21.48 10.9355 21.756 10.9355 22.076C10.9355 22.388 11.0355 22.648 11.2355 22.856C11.4355 23.064 11.6835 23.252 11.9795 23.42C12.2835 23.58 12.6115 23.74 12.9635 23.9C13.3155 24.06 13.6395 24.248 13.9355 24.464C14.2395 24.672 14.4915 24.924 14.6915 25.22C14.8915 25.516 14.9915 25.884 14.9915 26.324C14.9915 26.684 14.9275 27.008 14.7995 27.296C14.6715 27.584 14.4835 27.832 14.2355 28.04C13.9875 28.248 13.6875 28.408 13.3355 28.52C12.9835 28.632 12.5835 28.688 12.1355 28.688C11.5355 28.688 11.0395 28.64 10.6475 28.544C10.2555 28.456 9.95147 28.356 9.73547 28.244L10.0715 27.344C10.2555 27.448 10.5275 27.552 10.8875 27.656C11.2475 27.752 11.6515 27.8 12.0995 27.8C12.3635 27.8 12.6115 27.776 12.8435 27.728C13.0755 27.672 13.2755 27.588 13.4435 27.476C13.6115 27.356 13.7435 27.208 13.8395 27.032C13.9435 26.856 13.9955 26.648 13.9955 26.408Z"
        fill={color}
      />
      <path
        d="M18.7625 25.916L19.0505 27.188H19.0625L19.3745 25.892L21.4025 20.144H22.3985L19.2185 28.676H18.7745L15.5345 20.144H16.6265L18.7625 25.916Z"
        fill={color}
      />
    </svg>
  );
};

Csv.defaultProps = {
  className: '',
  color: '#236192',
  width: '25',
  height: '33',
  viewBox: '0 0 25 33',
};

Csv.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
