import { getLastApiCall, getOrder, getUser } from 'pages/Orders/selectors';
import { isRequestItemsEndpoint } from 'pages/Orders/helpers';
import { objToQS, QSToObj } from 'common/util';
import alert from 'components/native/alert/function';

export { default as importFromText } from './importText';
export { default as importFromFile } from './importFile';
export { default as handleDelete } from './delete';
export { default as save } from './save';
export { default as refresh } from './refresh';
export { default as onConnect } from './onConnect';
export { default as onReceive } from './onReceive';
export { default as onBroadcast } from './onBroadcast';
export { default as handleSearch } from './search';

export const handleExport =
  ({ api, items }) =>
  async ({ type = 'xls', data, fileName }, state) => {

    const user = getUser(state);
    items.startExport(type);

    try {
      return await api.post({
        url: '/file/generate/items',
        query: `?type=${type}&uid=${user.id}&fname=${fileName}`,
        data,
      });
    } catch (e) {
      alert('Серверная ошибка!');
      items.endExport(type);
    }
  };

export const handleItemsExport = ({api}) =>
  async (orderNo) => {
    try {
      return await api.get({
        url: `/orders/document/${orderNo}`,
        query: '?type=100'
      });
    } catch (e) {
      alert('Серверная ошибка!');
    }
  };

export const toggleFilters =
  ({ api, catalog }) =>
  async ({ groupNo, tree }, state) => {
    const { orderNo = '' } = getOrder(state);

    const { url, query, body, headers } = getLastApiCall(state);

    if (isRequestItemsEndpoint(url)) {
      return await api.post({
        url,
        query: (query && `?${objToQS({ ...QSToObj(query), onlyItems: 1 })}`) || `?onlyItems=1`,
        data: body,
        headers,
        log: true,
      });
    }

    if (groupNo && orderNo) {
      return await catalog.getItems({
        group: groupNo,
        row: { original: tree },
        orderNo,
      });
    }
  };
