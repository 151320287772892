import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Server = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M3.0501 16.6444H14.9501C15.3351 16.6444 15.6501 16.3244 15.6501 15.9332V4.51991C15.6501 4.30658 15.5801 4.1288 15.4051 3.98658L12.6751 1.53325C12.5701 1.42658 12.3951 1.35547 12.2201 1.35547H5.7801C5.6051 1.35547 5.4301 1.42658 5.3251 1.53325L2.5951 3.98658C2.4201 4.1288 2.3501 4.30658 2.3501 4.51991V15.9332C2.3501 16.3244 2.6651 16.6444 3.0501 16.6444Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M15.6501 4.91103H2.3501"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M15.6501 8.82214H2.3501"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M15.6501 12.7332H2.3501"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path d="M3.75 7.39991H5.85" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M12.5 6.6888V7.75547" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path
        d="M13.8999 6.6888V7.75547"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path d="M3.75 11.311H5.85" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M12.5 10.5999V11.6666" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path
        d="M13.8999 10.5999V11.6666"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path d="M3.75 15.2221H5.85" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M12.5 14.511V15.5777" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path
        d="M13.8999 14.511V15.5777"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Server.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Server.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
