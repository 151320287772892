import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useNewLayout } from 'hooks/use-new-layout';

import styles from './switcher.module.scss';

export const Switcher = ({className, checked, disabled, onChange }) => {
  const isNewLayout = useNewLayout();

  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <span
      className={cn(styles['switcher'], {
        [styles['switcher-v2']]: isNewLayout,
      }, className)}
    >
      <label htmlFor="switcher" className={styles['switcher__label']}>
        <input
          type="checkbox"
          id="switcher"
          className={styles['switcher__input']}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
        <span className={styles['switcher__slider']} />
      </label>
    </span>
  );
};
