import React from 'react';
import get from 'lodash/get';
import sortStringNumbers from 'helpers/sortStringNumbers';

export default [
  {
    Header: 'Дата учета',
    accessor: 'sortingDate',
    id: 'postingDate',
    width: 100,
    Cell: row => get(row, ['original', 'postingDate'], null),
  },
  {
    Header: 'Документ Но.',
    accessor: 'docNo',
    id: 'docNo',
    width: 100,
  },
  {
    Header: 'Заказ Но.',
    accessor: 'orderNo',
    id: 'orderNo',
    sortable: false,
    width: 100,
  },
  {
    Header: 'ОЗПр Но.',
    accessor: 'ozprNo',
    id: 'ozprNo',
    sortable: false,
    width: 100,
  },
  {
    Header: () => <span title="Номер отгрузочного документа">Номер УПД</span>,
    accessor: 'updNo',
    id: 'updNo',
    sortable: true,
    width: 210,
  },
  {
    Header: 'Тип документа',
    accessor: 'typeName',
    id: 'type',
    sortable: false,
  },
  {
    Header: 'Инициатор',
    accessor: 'initiator',
    id: 'initiator',
  },
  {
    Header: 'Описание',
    headerStyle: { textAlign: 'left' },
    accessor: 'description',
    id: 'description',
    style: { textAlign: 'left' },
    width: 310,
  },
  {
    Header: 'Город получатель',
    accessor: 'deliveryCity',
    id: 'deliveryCity',
    sortable: false,
  },
  {
    Header: 'Контрагент',
    accessor: 'contractor',
    id: 'contractor',
    sortable: false,
  },
  {
    Header: 'Закрыто',
    accessor: d => (
      <span style={{ color: d.closed && d.closed.includes('да') ? 'green' : 'red' }}>
        {d.closed}
      </span>
    ),
    id: 'closed',
    sortable: false,
    width: 100,
  },
  {
    Header: 'Дата оплаты',
    accessor: 'paymentDate',
    id: 'paymentDate',
    sortable: false,
    width: 100,
  },
  {
    Header: 'Просрочено дней',
    accessor: 'exceededDays',
    id: 'exceededDays',
    sortable: false,
  },
  {
    Header: 'Сумма просрочки',
    accessor: 'exceededSum',
    id: 'exceededSum',
    style: { textAlign: 'right' },
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Сумма',
    headerStyle: { textAlign: 'right' },
    accessor: 'sum',
    id: 'sum',
    sortable: false,
    style: { textAlign: 'right' },
    width: 100,
  },
  {
    Header: 'Валюта',
    accessor: 'currency',
    id: 'currency',
    width: 70,
  },
  {
    Header: 'Примечание',
    accessor: 'note',
    id: 'note',
    sortable: false,
  },
];
