import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Monitor = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M15.2222 12.5H2.77778C2.05264 12.5 1.5 11.9394 1.5 11.3077V3.69231C1.5 3.06061 2.05264 2.5 2.77778 2.5H15.2222C15.9474 2.5 16.5 3.06061 16.5 3.69231V11.3077C16.5 11.9394 15.9474 12.5 15.2222 12.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M11 16H7L7.5 13H10.5L11 16Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M6 16H12"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 10L16 10" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
    </svg>
  );
};

Monitor.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Monitor.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
