import {useCallback, useReducer} from 'react'

/**
 * Hook to toggle any thing
 *
 * @param {boolean} initial  - initial toggle state
 * @returns {[
 *   boolean,
 *   React.Dispatch<React.SetStateAction<boolean>>,
 *   (e?: Event) => void
 * ]}
 */
const useToggleHandler = initial => {
  const [toggled, setToggled] = useReducer(toggled => !toggled, initial);

  const toggle = useCallback(
    e => {
      e.preventDefault();
      setToggled();
    },
    [],
  );

  return [toggled, setToggled, toggle];
};

export default useToggleHandler;
