import { objToQS } from 'common/util';
import React from 'react';

export default ({ api }) =>
  async ({ date, orders, logisticObject = '' }) => {
    const queryOrders = orders.reduce(
      (qs, order) => `${qs}&orders[]=${encodeURIComponent(order)}`,
      '',
    );

    try {
      const { data } = await api.get({
        url: 'shipment-schedule/time-intervals',
        query: `?${objToQS({ date, logisticObject })}${queryOrders}`,
      });

      return data.map(({ scheduled, power, remainsV, description, no: value }) => {
        let disabled = false;
        let label = description;

        if (scheduled >= power) {
          disabled = true;
        } else if (scheduled < power && remainsV < 0) {
          disabled = true;
          label = (
            <span className="error">
              {`${label} Возможна отгрузка при уменьшении объема на ${Math.abs(remainsV).toFixed(
                2,
              )} куб.м`}
            </span>
          );
        }

        return { value, label, disabled };
      });
    } catch (e) {
      return [];
    }
  };
