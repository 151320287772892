import React from 'react';
import { getDaysInMonth, getYearsRange, MONTHS } from 'common/util';
import { Field } from 'redux-form';
import Select from 'react-select';
import get from 'lodash/get';
import { renderCheckbox } from '../components/fields';

export const getSelectedBindings = () =>
  [...document.querySelectorAll('input[name="correspondence[]"]:checked')].map(elem => elem.value);

export const getDaysOptions = (month, year) =>
  getDaysInMonth(month - 1, year).reduce(
    (prev, curr) => [...prev, { value: curr, label: curr }],
    [],
  );

export const getYearsOptions = startYear =>
  getYearsRange(startYear).reduce((prev, curr) => [...prev, { value: curr, label: curr }], []);

export const getMonthOptions = () =>
  MONTHS['rod'].reduce((prev, curr, index) => [...prev, { value: index + 1, label: curr }], []);

export const mask = input => {
  const raw = input ? input.replace(/\D+/g, '') : [];

  if (raw[0] == 3 || (raw[0] == 3 && (raw[1] == 7 || raw[1] == 8))) {
    return [
      '+',
      /\d/,
      /\d/,
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
  }
  return ['+', '7', '(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
};

export const workMask = () => [
  '+',
  '7',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '#',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const createDataParent = () => {
  let parentName = 'ADM';
  return (level, name) => {
    if (level == 0) {
      parentName = name;
      return '';
    }
    if (level == 1) {
      return parentName;
    }
  };
};

const setData = createDataParent();

export const unflatPermissions = (perms, prev) =>
  perms.reduce((final, elem, index) => {
    const curLevel = elem['Уровень'];

    if (curLevel > prev) {
      final.push('<ul class="col-sm-9">');
      prev = curLevel;
    } else if (curLevel < prev) {
      final.push('</ul></li>');
      prev = curLevel;
    } else if (index) {
      final.push('</li>');
    } else {
      prev = curLevel;
    }

    final.push(
      `<li key={${elem['Но_']}}>
        <input
          type="checkbox"
          name='${elem['Но_']}'
          label='${elem['Название']}'
          level="${curLevel}"
          disabled="${elem['Доступ фирме'] == 0}"
          value="${elem['Доступ']}"
          dataparent=${setData(curLevel, elem['Но_'])}
        />`,
    );

    return final;
  }, []);

export const transform = node => {
  if (node.type === 'tag' && node.name === 'input') {
    const {
      attribs: { disabled, name, value, label, level, dataparent },
    } = node;
    return (
      <Field
        key={name}
        name={name}
        defaultName={name}
        defaultValue={value}
        label={label}
        level={level}
        disabled={disabled}
        component={renderCheckbox}
        dataParent={dataparent}
      />
    );
  }
};

export const isDisabled = (label, value) => Object.values(value).some(elem => elem.label === label);

export const getPriceColumns = (headerRow, { selectedValue, handleChange }) => {
  const headerOptions = [
    { value: '', label: 'Выберите колонку' },
    {
      value: 'id',
      label: 'Код товара',
      disabled: isDisabled('Код товара', selectedValue),
    },
    {
      value: 'name',
      label: 'Наименование товара',
      disabled: isDisabled('Наименование товара', selectedValue),
    },
    { value: 'brand', label: 'Бренд', disabled: isDisabled('Бренд', selectedValue) },
    { value: 'partNo', label: 'Part#', disabled: isDisabled('Part#', selectedValue) },
    { value: 'ean', label: 'EAN', disabled: isDisabled('EAN', selectedValue) },
  ];

  return [
    ...[
      {
        Header: '',
        accessor: '',
        Cell: row => row.index + 1,
        sortable: false,
        filterable: false,
        width: 20,
      },
    ],
    ...headerRow.map((key, index) => ({
      Header: () => (
        <div>
          <Select
            key={key}
            name="col[]"
            className="price-row-selector"
            options={headerOptions}
            value={selectedValue[index] || null}
            onChange={handleChange(index)}
            clearable={false}
            searchable={false}
            placeholder="Выберите колонку"
          />
        </div>
      ),
      accessor: '',
      Cell: row => get(row, ['original', index], null),
      sortable: false,
      filterable: false,
    })),
  ];
};

const FORMATTED_DAYS = {
  Понедельник: 'Пн',
  Вторник: 'Вт',
  Среда: 'Ср',
  Четверг: 'Чт',
  Пятница: 'Пт',
};

export const formattingDays = days =>
  Object.keys(days).map(day => ({
    label: FORMATTED_DAYS[day],
    day,
  }));

const FORMATS = ['xls', 'xml'];
const NOT_SEND_PRICE = 0;

export const getActiveFormat = (priceFormat, sendPrice) =>
  sendPrice == NOT_SEND_PRICE ? 'sendPrice' : FORMATS[priceFormat];
