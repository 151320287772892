// @ts-nocheck
import { formatCurrency } from 'utils/new/numbers/format-number';

export function formatBalance(balance) {
  return {
    CustNo: balance.CustNo,
    Max_Kredit: formatCurrency(balance.Max_Kredit),
    Balance_Hard: formatCurrency(balance.Balance_Hard),
    Balance_Soft: formatCurrency(balance.Balance_Soft),
    Summa_In_Orders: formatCurrency(balance.Summa_In_Orders),
    Oborot_USD: formatCurrency(balance.Oborot_USD),
  };
}

export function formatExchangeRate(currency) {
  const code = currency?.Code;
  const date = currency?.Date?.replaceAll('/', '.');
  const rate = currency.Rate ? formatCurrency(currency.Rate, { fractions: 4 }) : currency.Rate;

  return {
    info: `${code} ЦБ ${date}`,
    rate: rate ? `${rate}` : rate,
  };
}
