import React from 'react';
import priceFormat from 'helpers/priceFormat';
import sortStringNumbers from 'helpers/sortStringNumbers';
import sortStrings from 'helpers/sortStrings';
import MoveCell from 'pages/Orders/components/order-move-form/cells/move-cell';
import { ItemLinkCell, reserveIcon } from '../../../helpers';

const initColumns = ({ forMove = true }) => [
  {
    Header: '',
    id: 'no',
    width: 25,
    sortable: false,
    Cell: row => (row && row.viewIndex + 1) || null,
  },
  {
    Header: 'ОР',
    id: 'reserve',
    sortable: false,
    accessor: row => reserveIcon(row.onlineReserve, false),
    width: 40,
  },
  {
    Header: 'Код',
    id: 'itemNo',
    accessor: 'id',
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Наименование',
    id: 'name',
    accessor: 'name',
    minWidth: 610,
    style: { textAlign: 'left' },
    sortMethod: sortStrings,
    Cell: ItemLinkCell,
  },
  {
    Header: 'Мин.Кол',
    id: 'minPackaged',
    sortable: false,
    accessor: row => row?.minPackaged || '',
    width: 70,
  },
  {
    Header: 'В Упак.',
    id: 'packaged',
    sortable: false,
    accessor: row => row?.packaged || '',
    width: 70,
  },
  {
    Header: 'Кол',
    id: 'qty',
    sortable: false,
    accessor: 'qty',
    width: 70,
  },
  {
    Header: 'ЖКол',
    id: 'desiredQty',
    sortable: false,
    accessor: row => row?.desiredQty || '',
    width: 70,
  },
  {
    Header: 'Перенести',
    id: 'move',
    headerStyle: { textAlign: 'left' },
    sortable: false,
    accessor: 'move',
    show: forMove,
    Cell: MoveCell,
  },
  {
    Header: 'Дост',
    id: 'available',
    sortable: false,
    accessor: 'availableClient',
    width: 70,
    Cell: row => {
      if (!row) return null;

      const { value } = row;
      return parseInt(value) < 0 ? 0 : value || 0;
    },
  },
  {
    Header: () => <span>Цена, &#8381;</span>,
    id: 'price',
    accessor: 'price',
    style: { textAlign: 'right' },
    headerStyle: { textAlign: 'right' },
    Cell: row =>
      (row && <span style={{ color: row.original.color }}>{row.original.price}</span>) || null,
    sortMethod: sortStringNumbers,
  },
  {
    Header: () => <span>Сумма, &#8381;</span>,
    id: 'total',
    accessor: row => row.total,
    style: { textAlign: 'right' },
    headerStyle: { textAlign: 'right' },
    Cell: row =>
      (row && <span style={{ color: row.original.color }}>{row.original.total}</span>) || null,
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'РЦена, $',
    id: 'priceClient',
    accessor: 'priceClient',
    style: { textAlign: 'right' },
    headerStyle: { textAlign: 'right' },
    sortMethod: sortStringNumbers,
    Cell: row => (row && <span style={{ color: row.original.color }}>{row.value}</span>) || null,
  },
  {
    Header: () => <span>ЖЦена, &#8381;</span>,
    id: 'desiredPrice',
    accessor: 'desiredPrice',
    sortable: false,
    style: { textAlign: 'right' },
    headerStyle: { textAlign: 'right' },
    sortMethod: sortStringNumbers,
    Cell: row => <span style={{ color: row.original.color }}>{priceFormat(row.value)}</span>,
  },
  {
    Header: 'Вес',
    id: 'weight',
    accessor: 'weight',
    Cell: row => parseFloat(row.value).toFixed(2),
    width: 70,
  },
  {
    Header: 'Объем',
    id: 'volume',
    accessor: 'volume',
    Cell: row => (row && parseFloat(row.value).toFixed(2)) || null,
    width: 70,
  },
];

export default initColumns;

