import React from 'react';
import showInvoices from '../helpers/showInvoices';
import { useSelector } from 'react-redux';
import { getOrderView, getUser } from 'pages/Orders/selectors';

const createSourceGreenClassName = ({ user, order, zprType }) => {
  if (!zprType || order.initiator !== user.login) return '';
  return 'green';
};

const HeaderInfo = () => {
  const user = useSelector(getUser);
  const order = useSelector(getOrderView);
  const {
    orderNo,
    invoices,
    type,
    orderDate,
    docType,
    initiator,
    notShipped,
    status,
    storageOrOrder,
    shipment,
    shipmentDate,
    price,
    priceRUB,
    weight,
    volume,
    contact,
    endCustomer,
    delivaryDate,
  } = order;
  const isZpr = Number(docType) === 1;

  return (
    <div
      className={['actions-container', 'actions-container--order', 'align-items-center'].join(' ')}
    >
      <div className="row order-info">
        {!isZpr && invoices && (
          <div>
            <label>Учтенный заказ:</label> <span>{showInvoices(invoices)}</span>
          </div>
        )}
        <div>
          <label>Код:</label> <span>{orderNo}</span>
        </div>
        <div>
          <label>Источник: </label>
          <span className={createSourceGreenClassName({ user, order, isZpr })}>{type}</span>
        </div>
        <div>
          <label>Дата создания:</label> <span>{orderDate}</span>
        </div>
        {isZpr ? (
          <div>
            <label>Инициатор:</label>&nbsp;
            <span className={initiator === user.login ? 'green' : ''}>{initiator}</span>
          </div>
        ) : (
          <div>
            <label>Склад/Заказ:</label>&nbsp;
            <span>{storageOrOrder}</span>
          </div>
        )}
        <div>
          <label>Метод отгрузки:</label> <span>{shipment}</span>
        </div>
        <div>
          <label>Дата отгрузки:</label> <span>{shipmentDate}</span>
        </div>
        <div>
          <label>Статус:</label>&nbsp;
          <span>
            {status}
            {notShipped === 1 ? <span className="red"> &mdash; недогруз!</span> : ''}
          </span>
        </div>
        <div>
          <label>Сумма:</label>{' '}
          <span>
            {priceRUB}
          </span>
        </div>
        <div>
          <label>Вес:</label> <span>{weight}</span>
        </div>
        <div>
          <label>Объем:</label> <span>{parseFloat(volume).toFixed(2)}</span>
        </div>
        {!isZpr && (
          <div>
            <label>Контакт: </label>
            <span className="green">{contact}</span>
          </div>
        )}
        {!isZpr && (
          <div>
            <label>Конечный заказчик:</label>&nbsp;
            <span>{endCustomer}</span>
          </div>
        )}
        {!isZpr && delivaryDate && (
          <div>
            <label>Дата поставки:</label>&nbsp;
            <span>{delivaryDate}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderInfo;
