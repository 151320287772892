import { checkOverflow } from 'common/util';

export const getScrolledElements = () => ({
  table: document.getElementById('items-list-table'),
  header: document.getElementById('items-table-list-header'),
  container: document.getElementById('order-items-list'),
  finder: document.getElementById('order-finder-container'),
  scroll: document.getElementById('fake-scroll'),
  arrow: document.getElementById('arrow-up-container'),
});

export const syncScrollWidth = ({ scroll, header, table }) => {
  if (scroll && table) {
    setTimeout(() => {
      scroll.getElementsByTagName('*')[0].style.width = `${table.scrollWidth + table.offsetLeft - 30}px`;

      if(header){
        header.getElementsByTagName('*')[0].style.width = `${table.scrollWidth}px`;
      }

      scroll.style.visibility = checkOverflow(scroll) ? 'visible' : 'hidden';
    }, 10);
  }
};

export const initWheel = ({ container, table, header, scroll }) => {
  const tableContainer = table || container

  scroll.scrollLeft = tableContainer.scrollLeft

  if(header){
    header.scrollLeft = scroll.scrollLeft
  }
};

export const initScrolls = ({ table, header, container, scroll, arrow }) => {
  if (container && scroll) {
    const tableRect = container.getBoundingClientRect();

    if(arrow){
      if (tableRect.top < 0) {
        arrow.classList.add('visible');
      } else {
        arrow.classList.remove('visible');
      }
    }

    if (tableRect.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
      scroll.classList.add('sticky');
    } else {
      scroll.classList.remove('sticky');
    }

    if(header){
      header.scrollLeft = scroll.scrollLeft;
    }

    if(table){
      table.scrollLeft = scroll.scrollLeft;
    }

  }
};
