import {
  FILTER_BY_PROPERTIES,
  FILTER_BY_TILDA_VALUES,
  FILTER_TILDA_PROPERTIES,
} from '../constants';
import isSingleActiveGroup from './clientData/isSingleActiveGroup';
import {FiltersCollector} from "common/collections"
import transform from 'lodash/transform'

const getDisabledFilters = (filters, keys, active, group) => {
  const processedFilters = filters.map(filter => {
    const disabled = !active.includes(filter.key) && !keys.has(filter.key)

    if(!disabled){
      FiltersCollector.push('availableFilters', group, filter.key)
    }

    return ({ ...filter, disabled })
  });

  let available = FiltersCollector.get('availableFilters', group)

  if(available?.length === filters.length && filters.length > 1){
    FiltersCollector.delete('availableFilters', group)
  }

  return processedFilters
}


const getCheckedKeys = (items, index) => {
  const { length } = items;
  const keys = new Set();

  if (index === FILTER_TILDA_PROPERTIES) {
    for (let i = 0; i < length; i++) {
      items[i]['_original'][index].forEach(keys.add, keys);
    }
  } else {
    for (let i = 0; i < length; i++) {
      keys.add(items[i]['_original'][index]);
    }
  }

  return keys;
};

const getDisabledFormCheckboxes = async (
  tableSortedProductItems,
  listFilterGroups,
  activeGroups,
  activeKeys,
  activeKey,
) => {
  let checkedProps = null;

  return transform(listFilterGroups, (groups, group) => {
    if (isSingleActiveGroup(activeGroups, group)) {
      const filters = group.filters.reduce(
        (filters, filter) => [...filters, { ...filter, disabled: false }],
        [],
      );
      groups.push({ ...group, filters })

      return true;
    }

    if (group.key === activeKey) {
      const filters = group.filters.reduce(
        (filters, filter) => [...filters, {
          ...filter,
          disabled:
            !activeKeys.includes(filter.key)
            && !FiltersCollector.includes('availableFilters', group.key, filter.key)
        }],
        [],
      );

      groups.push({ ...group, filters })

      return true;
    }

    switch (group.type) {
      case FILTER_BY_PROPERTIES: {
        const checkedKeys = getCheckedKeys(tableSortedProductItems, group.key);
        groups.push({ ...group, filters: getDisabledFilters(group.filters, checkedKeys, activeKeys, group.key) })

        break;
      }
      case FILTER_BY_TILDA_VALUES: {
        if (!checkedProps) {
          checkedProps = getCheckedKeys(tableSortedProductItems, FILTER_TILDA_PROPERTIES);
        }

        groups.push({ ...group, filters: getDisabledFilters(group.filters, checkedProps, activeKeys, group.key) })

        break;
      }
      default:
        groups.push(group)
    }
  }, []);
};

export default getDisabledFormCheckboxes;
