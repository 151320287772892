import React from 'react';
import classNames from 'classnames';

function Loader(props) {
  const { wrapperStyle = {}, className } = props;
  return (
    <div className={classNames('loader__container', className)} style={wrapperStyle}>
      <i className="loader loader--small" />
      <p className="loader__text">{props.children}</p>
    </div>
  );
}

export default Loader;
