import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { hideDuplicate, hideDublicateInArray } from 'pages/Help/util/hide-dublicates';

const ProductGroup = ({ product }) => {
  const { children, title } = product;

  const hideDuplicateManager = hideDuplicate();
  const hideDiplicateEmail = hideDuplicate();
  const hideBorder = hideDublicateInArray();

  return (
    <table className="product-group">
      <thead>
        <tr>
          <th className="product-group__title" colSpan="3">
            {title}
          </th>
        </tr>
      </thead>
      <tbody>
        {children.map(({ managers, title }, i) => {
          return (
            <tr
              key={title + i}
              className={classNames('product-group__product', {
                'product-group__product--border-hide': hideBorder(managers, 'code'),
              })}
            >
              <td className="product-group__product__title">{title}</td>
              <td>
                {managers.map(({ fio }, i) => (
                  <div key={fio + i}>{hideDuplicateManager(fio)}</div>
                ))}
              </td>
              <td>
                {managers.map(({ email }, i) => (
                  <a
                    key={email + i}
                    href={`mailto:${email}`}
                    className="product-group__product__email"
                  >
                    {hideDiplicateEmail(email)}
                  </a>
                ))}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

ProductGroup.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string,
    children: PropTypes.array,
  }),
};

export default ProductGroup;
