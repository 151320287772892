export default {
  name: 'actions',
  state: {
    action: {},
    fetching: {},
    items: null,
    targets: [],
    pages: 0,
    pageSize: 25,
  },
  reducers: {
    fetch: (state, { index, value }) => {
      state.fetching[index] = value;

      return state;
    },
    load: (state, payload) =>
      payload.action ? { ...state, ...payload } : { ...state, ...payload.data },
  },
  effects: dispatch => ({
    async get() {
      await dispatch.api.get({ url: 'actions' });
    },
    async accept({ index, original }) {
      this.fetch({ index, value: true });

      await dispatch.api.post({
        url: `actions/accept/${original.no}`,
        data: { rowIndex: index },
      });
    },
    receive({ data: { success, result = true, rowIndex } }) {
      if (success) {
        this.fetch({ index: rowIndex, value: result });
      }
    },
  }),
};
