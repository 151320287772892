import alert from 'components/native/alert/function';
import { SubmissionError } from 'redux-form';
import ReactHtmlParser from 'react-html-parser';
import { CACHE_NAME } from 'common/config';

export const getCreditDocList =
  ({ api }) =>
  async value => {
    if (Number(value)) {
      return [];
    }

    try {
      const {
        success,
        data = [],
        message,
      } = await api.get({
        url: 'finances/credit/doc-list',
        query: `?orgLegalForm=${value}`,
        cache: CACHE_NAME,
      });

      if (!success) {
        alert(message);
      }

      return data;
    } catch (e) {
      console.error(e.message);
      return [];
    }
  };

export const deleteUploadedDocument =
  ({ api, finances }) =>
  async name => {
    finances.setActiveDocs({ index: name, flag: true });
    await api.get({
      url: 'finances/credit/delete-doc',
      query: `?filename=${name}`,
    });
    finances.setActiveDocs({ index: name, flag: false });
  };

export const getContractors =
  ({ api }) =>
  async () => {
    try {
      const { data } = await api.get({
        url: 'finances/lending-form',
        cache: CACHE_NAME,
      });

      return { options: data };
    } catch (e) {
      return { options: [] };
    }
  };

export const onSubmit =
  ({ api }) =>
  async values => {
    const { uploadDocuments = {}, ofpPartner } = values;
    const { contractor } = ofpPartner;
    const documents = Object.values(uploadDocuments).map(({ code, name }) => ({
      code,
      name,
    }));

    try {
      return await api.post({
        url: '/finances/credit/ask',
        data: { contractor, documents },
      });
    } catch (e) {
      throw new SubmissionError({
        _error: ReactHtmlParser(e.message),
      });
    }
  };
