import React, { Fragment, useReducer } from 'react';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { DateUtils } from "react-day-picker"
import CustomDayPickerInput from 'components/customDayPickerInput';
import { claimsFilters } from '../constants';
import { createAction } from 'redux-actions';

const DATE_FORMAT = 'DD.MM.YYYY';

const initState = {
  id: '',
  upd: '',
  dateFrom: null,
  dateTo: null,
};

const setFilter = createAction('SET');

const searchReducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET':
      return { ...state, ...payload };
    case 'RESET':
      return { ...state, ...initState };
    default: {
      return state;
    }
  }
};

const disabledFrom = day => {
  if(claimsFilters.has('dateTo')){
    return DateUtils.isDayAfter(day, parseDate(claimsFilters.get('dateTo'), DATE_FORMAT))
  }

  return false
}

const disabledTo = day => {
  if(claimsFilters.has('dateFrom')){
    return DateUtils.isDayBefore(day, parseDate(claimsFilters.get('dateFrom'), DATE_FORMAT))
  }

  return false
}

const DateSearch = ({ filter }) => {
  const [{ id, upd, dateFrom, dateTo }, dispatch] = useReducer(searchReducer, initState);

  const handleTextInputChange =
    inputKey =>
    ({ target: { value } }) => {
      dispatch(setFilter({ [inputKey]: value }));
      claimsFilters.set(inputKey, value);
    };

  const handleDateInputChange = inputKey => date => {
    const inputValue = formatDate(date, DATE_FORMAT, 'ru');
    dispatch(setFilter({ [inputKey]: inputValue }));

    claimsFilters.set(inputKey, inputValue);
  };


  const handleClearClick = () => {
    dispatch({ type: 'RESET' });
    claimsFilters.clear();

    handleSubmitClick();
  };

  const handleSubmitClick = () => {
    const filters = Object.entries(claimsFilters.get()).reduce(
      (acc, [id, value]) => [...acc, { id, value }],
      [],
    );

    filter(filters);
  };

  return (
    <Fragment>
      <div className="datepicker-block">
        <label>Дата с</label>
        <CustomDayPickerInput
          value={dateFrom}
          onDateInputChange={handleDateInputChange('dateFrom')}
          dayPickerProps={{
            disabledDays: disabledFrom
          }}
        />
        <label>по</label>
        <CustomDayPickerInput
          value={dateTo}
          onDateInputChange={handleDateInputChange('dateTo')}
          dayPickerProps={{
            disabledDays: disabledTo
          }}
        />
        <div className="logistic-inputs">
          <input
            type="text"
            name="id"
            placeholder="ID товара"
            className="form-control form-control-sm"
            value={id}
            onChange={handleTextInputChange('id')}
          />
          <input
            style={{ marginLeft: '10px' }}
            type="text"
            name="id"
            placeholder="УПД"
            className="form-control form-control-sm"
            value={upd}
            onChange={handleTextInputChange('upd')}
          />
        </div>
        <button type="submit" className="btn btn-danger btn--rect" onClick={handleSubmitClick}>
          Показать
        </button>
        <i
          className="clear-filters enabled icon icon-remove-filters"
          title="Очистить фильтр"
          onClick={handleClearClick}
        />
      </div>
    </Fragment>
  );
};

export default DateSearch;
