import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import NewsLink from '../../news-link';
import dateFormat from 'common/util';
import Mixpanel from 'services/mixpanel';
import { isCactus } from 'common/config';

const NewsItem = props => {
  const { news } = props;

  const dates = news.created.split('.');
  const created = new Date(dates[2], dates[1] - 1, dates[0]);

  let linkProps = {
    to: `/news/${news.newsId}`,
    className: 'news-link',
    title: news.title,
  };

  if (isCactus) {
    linkProps = {
      ...linkProps,
      isCactus: true,
      to: news.link || `https://officeassistant.ru/news/${news.id}`,
      rel: 'noopener noreferrer',
      target: '_blank',
    };
  }

  return (
    <li className="news-list__item">
      <span className="news-created">
        {dates[0]}
        <small>{dateFormat(created, 'mmmm')}</small>
      </span>
      <div>
        <NewsLink
          {...linkProps}
          onClick={() =>
            Mixpanel?.track('СтрГлавнаяСтраница.Новость', {
              page: 'СтрГлавнаяСтраница',
              id: news.newsId,
            })
          }
        />
        <i>{ReactHtmlParser(`${news.name}...`)}</i>
      </div>
    </li>
  );
};

export default NewsItem;
