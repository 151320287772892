import React from 'react';

const Legend = ({ legendItems: { expired, pay5d, pay30d } }) => (
  <ul className="chart-block__legend">
    {expired && (
      <li>
        <svg viewBox="0 0 32 32" version="1.1">
          <path d="M0,0h32v32h-32z" className="chart-block__legend__expired" />
        </svg>
        <span>Просроченные платежи</span>
      </li>
    )}
    {pay5d && (
      <li>
        <svg viewBox="0 0 32 32" version="1.1">
          <path d="M0,0h32v32h-32z" className="chart-block__legend__pay5d" />
        </svg>
        <span>Предстоящие платежи в ближайшие 5 дней</span>
      </li>
    )}
    {pay30d && (
      <li>
        <svg viewBox="0 0 32 32" version="1.1">
          <path d="M0,0h32v32h-32z" className="chart-block__legend__pay30d" />
        </svg>
        <span>Предстоящие платежи</span>
      </li>
    )}
  </ul>
);

export default Legend;
