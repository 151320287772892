import React from 'react';
import sortDates from 'helpers/sortDates';
import sortStringNumbers from 'helpers/sortStringNumbers';
import NumberCell from '../components/cells/NumberCell';
import StatusCell from '../components/cells/StatusCell';

export const columns = [
  {
    expander: true,
    width: 0,
    Expander: () => null,
  },
  {
    Header: 'Номер',
    accessor: 'no',
    Cell: NumberCell,
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Дата обращения',
    accessor: 'createDate',
    sortMethod: sortDates,
  },
  {
    Header: 'Конечный заказчик',
    accessor: 'customerName',
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: StatusCell,
  },
  {
    Header: 'Дата обработки',
    accessor: 'closeDate',
    sortMethod: sortDates,
  },
];
