import React, { useCallback } from 'react';
import AppSocket from 'components/AppSocket';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import purchaseColumns from './common/columns/purchaseColumns';
import PeriodPicker from 'pages/Finances/components/common/period-picker';
import { useDispatch } from 'react-redux';
import withApi from 'hoc/withApi';

const defaultSorted = [
  {
    id: 'operationNo',
    desc: true,
  },
];

const Purchase = () => {
  const {
    list,
    finances: { getPurchase },
  } = useDispatch();

  const getTdProps = useCallback(
    (state, row) => {
      if (!row) return {};

      return {
        get: getPurchase,
      };
    },
    [getPurchase],
  );

  return (
    <AppSocket channel={'queue-purchases'} onReceive={({ data }) => list.replace(data)}>
      <Content title="Отчет о закупках">
        <div className="finances-content">
          <div className="main-block payments-main-block">
            <PeriodPicker
              type="purchase"
              model="finances"
              effect="generatePurchase"
              position="left"
              label="Сформировать отчет"
            />
          </div>
          <div className="finances-list payments-list">
            <TableList
              defaultSorted={defaultSorted}
              defaultPageSize={5}
              pageSize={5}
              columns={purchaseColumns}
              showPagination={false}
              getTdProps={getTdProps}
            />
          </div>
        </div>
      </Content>
    </AppSocket>
  );
};

export default withApi(Purchase);
