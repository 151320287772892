import {useState, useEffect, useCallback} from 'react'
import clamp from 'lodash/clamp'

export default ({ containerClassName, type = 'Horizontal'}, deps = [] ) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [slidesWidth, setSlidesWidth] = useState([])
  const [slidesHeight, setSlidesHeight] = useState([])
  const [slideShift, setSlideShift] = useState(0)

  useEffect(() => {
    const slides = document.getElementsByClassName(containerClassName);

    setSlidesWidth([...slides].map((slide) => slide.offsetWidth))
    setSlidesHeight([...slides].map((slide) => slide.offsetHeight))
  }, deps) // eslint-disable-line

  const slidesCount = slidesWidth.length;

  const isFirstSlide = () => slideIndex === 0;
  const isLastSlide = () => slideIndex === slidesCount - 1;

  const handleSlideShift = useCallback(
    index => {
      const slidesArr = type === 'Horizontal' ? slidesWidth : slidesHeight;

      if (!index) {
        setSlideShift(0);
        return;
      }

      const shift = slidesArr.slice(1, index + 1).reduce((sum, value) => sum + value);
      setSlideShift(shift);
    },
    [slidesCount], // eslint-disable-line
  );

  const handleNextSlideClick = () => {
    const nextIndex = clamp(slideIndex + 1, 0, slidesCount - 1)

    setSlideIndex(nextIndex)
    handleSlideShift(nextIndex)
  }
  const handlePrevSlideClick = () => {
    const nextIndex = clamp(slideIndex - 1, 0, slidesCount - 1)

    setSlideIndex(nextIndex)
    handleSlideShift(nextIndex)
  }

  return [slideShift, isFirstSlide, isLastSlide, handleNextSlideClick, handlePrevSlideClick];
};
