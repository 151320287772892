import { get, size, eq, map } from 'lodash';
import { createSelector } from 'reselect';
import { getFormValues, formValueSelector } from 'redux-form';
import { def } from 'common/util';
import { getUser } from '../../Orders/selectors';

import { LENDING_FORM } from '../components/LendingForm/constants';

const selector = formValueSelector(LENDING_FORM);
export const getLendingFormValue = fieldName => state => selector(state, fieldName);

const financesSelector = state => get(state, 'finances', {});
const lendingFormSelector = state => get(state, `form[${LENDING_FORM}]`, {});
const loadingFinanceFileConfig = state => state.finances.loadingFinanceFileConfig;
const fieldsRequires = state => state.finances.creditFilesRequired;
export const lendingFormValuesSelector = getFormValues(LENDING_FORM);

export const financesContractorsSelector = createSelector(
  financesSelector,
  state => state.contractors,
);

export const financesOrgLegalFormSelector = createSelector(financesSelector, state =>
  map(state.creditDocList, creditDoc => ({
    required: Boolean(Number(creditDoc.required)),
    description: creditDoc.description,
    title: creditDoc.name,
    name: creditDoc.code,
  })),
);

export const lendingFormCurrentStepSelector = createSelector(lendingFormValuesSelector, state => {
  const ofpPartner = get(state, 'ofpPartner');
  const _orgLegalForm = get(ofpPartner, `${LENDING_FORM}__contractors.orgLegalForm`);

  if (_orgLegalForm || eq(size(ofpPartner), 2)) {
    return 2;
  }

  return 1;
});

export const lendingFormIsInvalidSelector = createSelector(
  [lendingFormSelector, loadingFinanceFileConfig, fieldsRequires],
  (state, loading, fielsRequires) => {
    const syncErrors = get(state, 'syncErrors');
    if (loading) {
      if (syncErrors && 'uploadDocuments' in syncErrors) {
        let res = false;
        for (const key in syncErrors.uploadDocuments) {
          if (fielsRequires.includes(key)) {
            res = true;
          }
        }
        return res;
      }
      return false;
    }
    return true;
  },
);

export const getColumns = state => state.accountedColumns;

export const getUserColumnsVisibility = createSelector(
  getColumns,
  columns => columns.userVisibility || {},
);

export const getColumnsVisibility = createSelector(
  getUser,
  getUserColumnsVisibility,
  state => state.accountedColumns.values,
  (user, userVisibility, defaultValues) => ({ ...defaultValues, ...userVisibility[user.id] }),
);

export const isColumnsStateSaved = createSelector(
  getUser,
  getUserColumnsVisibility,
  (user, visibility) => def(visibility[user.id]),
);

const getFinanceFilters = state => get(state, 'financeFilters', {});

const getUserFilters = createSelector(getFinanceFilters, financeFilters =>
  get(financeFilters, 'userFilters', {}),
);

export const getPaymentsData = createSelector(financesSelector, finances =>
  get(finances, 'paymentsData', []),
);

export const getLastPayment = createSelector(getPaymentsData, payments => payments[0] || {});

const getDefaultBalance = createSelector(getFinanceFilters, financeFilters =>
  get(financeFilters, 'balance', {}),
);

export const getFilterBalanceSelector = createSelector(
  getUser,
  getUserFilters,
  getDefaultBalance,
  (user, userFilters, defaultBalance) =>
    (userFilters[user.id] && userFilters[user.id].balance) || defaultBalance,
);
