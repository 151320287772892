import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getOrderView, getOrderItems, getOrderSplitItems } from 'pages/Orders/selectors';
import { useSplitScroll } from 'pages/Orders/hooks/useSplitScroll';
import { getEffectLoading } from 'common/selectors';
import ReactTable from 'react-table-v6';
import Content from 'components/layout/content';
import HeaderInfo from 'pages/Orders/components/common/header-info';
import TableList from 'components/table/list';
import { dateToRU } from 'common/util';
import columns from './common/columns/splitColumns';
import withApi from 'hoc/withApi';

const headerInfoFields = ['orderNo', 'orderDate', 'shipmentMethod'];
const getTheadProps = (state, rowInfo, column) => ({
  title: typeof column.Header === 'function' ? column.headerTitle : column.Header,
});
const getTfootProps = () => ({
  className: 'rt-tfoot--splited',
});

const getTdProps = (state, info, column) => ({
  className: column.id === 'name' ? 'text-left text-ellipsis' : '',
});
const empty = () => null;

const OrderSplitForm = () => {
  const order = useSelector(getOrderView);
  const data = useSelector(getOrderItems);
  const fetching = useSelector(getEffectLoading('order', 'handleSplit'));
  const goods = useSelector(getOrderSplitItems);
  const {
    order: { handleSplit },
  } = useDispatch();
  const history = useHistory();

  useSplitScroll();

  const colWidth = goods.length < 4 ? 100 / goods.length : 100 / 3;

  const splitedGoodsHeader = goods.map(set =>
    set.length ? (
      <div style={{ width: `${colWidth}%`, height: '100%' }}>
        <div className="row split_actions">
          <div className="col-sm-3">
            <strong>{dateToRU(set[0]['Дата'])}</strong>
          </div>
          <div className="col-sm-9 text-right">
            <button
              className="btn btn-outline-danger btn--rect"
              disabled={fetching}
              onClick={async () => await handleSplit({ set, order })}
            >
              Создать заказ на дату
            </button>
          </div>
        </div>
        <ReactTable
          data={set}
          columns={columns(true, true, set)}
          showPagination={false}
          minRows={1}
          defaultPageSize={1}
          TrGroupComponent={empty}
          getTfootProps={getTfootProps}
          getTheadThProps={getTheadProps}
        />
      </div>
    ) : (
      ''
    ),
  );

  const splitedGoodsLines = goods.map(set =>
    set.length ? (
      <div style={{ width: `${colWidth}%` }}>
        <ReactTable
          data={set}
          columns={columns(false, true)}
          showPagination={false}
          minRows={1}
          TheadComponent={empty}
          TfootComponent={empty}
          defaultPageSize={5000}
          pageSize={5000}
        />
      </div>
    ) : null,
  );

  return (
    <Content title="Разделить заказ на дату" style={{ padding: 0 }}>
      {data.length ? (
        <div className="order_split_container">
          <div>
            <HeaderInfo order={order} visible={headerInfoFields} />
            <div className="order_lines">
              <TableList
                columns={columns(true, false, data)}
                defaultPageSize={1}
                TrGroupComponent={empty}
                getTfootProps={getTfootProps}
                getTheadThProps={getTheadProps}
              />
              <div>
                <TableList
                  columns={columns(false, false)}
                  TheadComponent={empty}
                  TfootComponent={empty}
                  defaultPageSize={5000}
                  pageSize={5000}
                  getTdProps={getTdProps}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="chunk_header">{splitedGoodsHeader}</div>
            <div className="chunk_body">{splitedGoodsLines}</div>
          </div>
        </div>
      ) : (
        <h6 className="text-center">В заказе нет товаров</h6>
      )}
    </Content>
  );
};

export default withApi(OrderSplitForm);
