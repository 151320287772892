import { getLastApiCall, getNullOrder, isLastApiCallItems } from 'pages/Orders/selectors';
import { isPreOrder } from 'pages/Orders/helpers';
import { getTableListData } from 'common/selectors';
import { objToQS, QSToObj } from 'common/util';
import { ROUTE_PREORDERS_EDIT } from 'pages/Orders/constances/preorder';

export default ({ api }) =>
  async ({ orderNo, pathname }, state) => {
    const data = getTableListData(state);

    if (!data.length) {
      return;
    }

    const order = getNullOrder(state);
    const preOrder = isPreOrder(pathname, order);

    const last = getLastApiCall(state);

    if (isLastApiCallItems(state)) {
      return await api.post({
        ...last,
        query:
          (last.query &&
            `?${objToQS({
              ...QSToObj(last.query),
              orderNo,
              onlyItems: 1,
            })}`) ||
          `?orderNo=${orderNo}&onlyItems=1`,
        log: true,
      });
    }

    const endpoint = !preOrder ? 'orders/edit/' : ROUTE_PREORDERS_EDIT;

    await api.get({
      url: `${endpoint}${orderNo}`,
      query: '?onlyItems=1',
      log: true,
    });
  };
