import alert from 'components/native/alert/function';
import { formattingIdReturn } from 'pages/VAD/helper/util';
import { download, proxyArgsToMethods } from 'common/util';
import * as effects from './effects';
import * as reducers from './reducers';

export default {
  name: 'tz',
  state: {
    detail: {},
  },
  reducers: {
    ...reducers,
    setStatus: (state, status) => {
      state.detail.status = status;

      return state;
    },
  },
  effects(dispatch) {
    const type = this.name;

    return {
      ...proxyArgsToMethods(effects, [dispatch, type]),
      async create({ inn, customerName, otherFiles }) {
        const { fullname, label, na_no, value } = customerName;

        try {
          return await dispatch.api.post({
            url: 'tz/create/',
            data: {
              f030: inn,
              f040: fullname || label,
              f041: na_no || value,
              otherFiles: otherFiles?.[0],
            },
          });
        } catch ({ message }) {
          alert('Серверная ошибка! Повторите действие');

          return { success: false, message };
        }
      },
      async reopen(id) {
        const { success } = await dispatch.api.get({
          url: 'tz/reopen-status',
          query: `?id=${encodeURIComponent(formattingIdReturn(id))}`,
        });

        if (success) {
          this.setStatus('Взят в работу');
        }
      },
      async getAttachment({ id, line }) {
        try {
          const { blob, filename } = await dispatch.api.get({
            url: `get-attach`,
            query: `?id=${encodeURIComponent(formattingIdReturn(id))}?line=${line}`,
          });

          download(blob, filename);
        } catch (e) {}
      },
    };
  },
  selectors: slice => ({
    getRequest() {
      return slice(tz => tz.detail);
    },
  }),
};
