import React, { useMemo } from 'react';
import classNames from 'classnames';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import { AccViewColumns } from '../../common/columns/accuntedViewColumns';
import { Link } from 'react-router-dom';
import { getIncludes } from 'pages/Finances/util/helper';
import useLocationValue from 'hooks/use-location-value';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'pages/Orders/selectors';
import AccountedExport from './request';
import withApi from 'hoc/withApi';

const AccountedView = () => {
  const { client } = useSelector(getUser);
  const { orderNo, description, postingDate, sum, initiator, note, docNo } = useSelector(
    state => state.accounted.document,
  );
  const shipments = useSelector(state => state.accounted.shipments);

  const {
    accounted: { getAttachment },
  } = useDispatch();

  const locType = useLocationValue('type', 2);
  const type = Number(locType);

  const columns = useMemo(() => AccViewColumns(type), [type]);
  const docNoByType = type === 3 ? docNo : orderNo;

  return (
    <Content title={type === 2 ? 'Счет' : 'Кредит Нота'}>
      <div
        className={classNames(
          'actions-container',
          'actions-container--order',
          'actions-container__header',
        )}
      >
        <div className="row align-items-center">
          <div className="col-sm row">
            <Link to="/finances/accounted" className="section-nav">
              <i className="icon icon-back-icon" />
              Список учтенных документов
            </Link>
          </div>
          <AccountedExport type={type} orderNo={orderNo} />
        </div>
      </div>
      <div
        className={classNames(
          'actions-container',
          'actions-container--order',
          'actions-container__header',
        )}
      >
        <div className="row event-actions">
          <div>
            <span>Номер: </span>
            {docNoByType}
          </div>
          <div>
            <span>Клиент: </span>
            {client}
          </div>
          <div>
            <span>Описание учета: </span>
            {description}
          </div>
          <div>
            <span>Дата учета: </span>
            {postingDate}
          </div>
          <div>
            <span>Сумма: </span>
            {sum}
          </div>
          <div>
            <span>Инициатор: </span>
            {initiator}
          </div>
          <div>
            <span>Примечание: </span>
            {note}
          </div>
        </div>
      </div>
      {shipments.length > 0 && (
        <div
          className={classNames(
            'actions-container',
            'actions-container--order',
            'actions-container__header',
          )}
        >
          {shipments.map((shipment, index) => (
            <div key={index} className="row event-actions">
              {/*<div>
                    <label
                      styles={{margin: 0, display: 'inline-flex'}}
                      className='align-items-center'>
                      <input
                        type="checkbox"
                        onChange={showItems(index)}
                      />
                      &nbsp;Показать товары
                    </label>
                  </div>*/}
              <div>
                <span>№ заказа: </span>
                {shipment.orderNo}
              </div>
              <div>
                <span>Дата отгрузки: </span>
                {shipment.postingDate}
              </div>
              <div>
                <span>ЛО: </span>
                {shipment.location}
              </div>
              <div>
                <span>Метод отгрузки: </span>
                {shipment.method}
              </div>
              <div>
                <span>Статус обработки: </span>
                {shipment.status}
              </div>
              <div>
                <span>Статус Время: </span>
                {shipment.statusTime}
              </div>
              <div>
                <span>Недогруз: </span>нет
              </div>
              <div>
                <span>Вложения: </span>
                {getIncludes(docNo, shipment.includes, getAttachment)}
              </div>
            </div>
          ))}
        </div>
      )}
      {type === 2 && (
        <div className="accounted-note">
          <p>
            Уважаемый пользователь!
            <br />
            Напоминаем Вам, что это Предварительный счет.
            <br />В окончательном документе, по согласованию сторон, могут быть изменены:
            номенклатура, наименование товара и его количество.
            <br />
            Также может быть изменена цена товара в связи с изменением курса валют или изменением
            ценовой политики производителя, вендора.
          </p>
        </div>
      )}
      <div className="order-list">
        <TableList columns={columns} defaultPageSize={5000} pageSize={5000} />
      </div>
    </Content>
  );
};

export default withApi(AccountedView);
