import { UNAUTHORIZED } from 'common/config';
import { activeControllers } from 'common/collections';

const fetchProgress = (url, onProgress, options = {}, n = 2) =>
  new Promise((resolve, reject) => {
    const headers = { ...options.headers };

    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.open(options.method || 'GET', url);

    Object.keys(headers).forEach(header => {
      xhr.setRequestHeader(header, headers[header]);
    });

    xhr.onload = () => {
      const res = JSON.parse(xhr.response);

      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(res);
      }

      if (xhr.status === UNAUTHORIZED) {
        if (n === 1) {
          return reject(res);
        }

        const { csrf_token } = res;

        const newOptions = {
          ...options,
          headers: {
            ...headers,
            Authorization: `Bearer ${csrf_token}`,
            'X-Token-Type': 'refresh',
          },
        };

        resolve(fetchProgress(url, onProgress, newOptions, n - 1));
      }

      reject(res);
    };

    if (xhr.upload && onProgress && n > 1) {
      xhr.upload.onprogress = onProgress;
    }

    xhr.upload.onload = function (event) {
      {
        if (n === 1) {
          return;
        }

        console.log(`Загружено ${event.loaded}`);
      }
    };

    activeControllers.set(url, xhr);

    xhr.send(options.body);
  });

export default fetchProgress;
