import React from 'react';
import { useParams, Link } from 'react-router-dom';
import parse from 'react-html-parser';
import Content from 'components/layout/content';
import Attach from 'pages/VAD/components/common/components/attach';
import AddCommentForm from 'pages/VAD/components/form/comments-form';
import colorsStatusTZ from 'pages/VAD/constants/colors-status-tz';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import withApi from 'hoc/withApi';
import CommentsList from 'pages/VAD/components/common/components/comments-list';

const TZDetail = () => {
  const { id } = useParams();
  const {
    select: { tz },
  } = useStore();

  const {
    createDate,
    closeDate,
    status,
    body,
    comments = [],
    customerName,
    attaches,
  } = useSelector(tz.getRequest);

  const isReopening = useSelector(getEffectLoading('tz', 'reopen'));

  const {
    tz: { reopen },
  } = useDispatch();

  const isWorking = status === 'Взят в работу';
  const isDone = status === 'Запрос обработан, спецификация отправлена менеджеру';
  const message = body
    ? parse(body)
    : 'Ваш запрос еще обрабатывается. Пожалуйста, обновите страницу позднее.';

  return (
    <div className="detail-view">
      <Content title={`Запрос ${id}`}>
        <div className="row header">
          <div className="col link-container header-border-right">
            <Link to="/vad/tz/list" className="link-back">
              <i className="icon back" />
              <span className="back-text">Список запросов</span>
            </Link>
          </div>
          <div className="col data-container header-border-right">
            <div className="row-content">Дата обращения: {createDate}</div>
            <div className="row-content">Дата обработки: {closeDate}</div>
          </div>
          <div className="col data-container">
            <div className="row-content">Конечный заказчик: {customerName}</div>
            <div className="row-content">
              Статус: <span className={colorsStatusTZ[status]}>{status}</span>
            </div>
          </div>
        </div>
      </Content>
      {isDone && (
        <div className="row" style={{ marginTop: 10 }}>
          <button
            className="btn btn-danger btn--rect"
            onClick={async () => await reopen(id)}
            disabled={isReopening}
          >
            Восстановить
          </button>
        </div>
      )}
      <div className="message">{message}</div>
      {attaches && (
        <div className="row" style={{ marginTop: 10, display: 'block' }}>
          {attaches.map(({ lineId, filename }) => (
            <Attach key={lineId} fileName={filename} lineId={lineId} />
          ))}
        </div>
      )}
      <CommentsList comments={comments}>{isWorking && <AddCommentForm type="tz" />}</CommentsList>
    </div>
  );
};

export default withApi(TZDetail);
