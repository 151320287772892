import React from 'react';
import sortStringNumbers from 'helpers/sortStringNumbers';
import InputQty from '../../../demofond/components/InputQty';

const ItemColumns = demofondMode => [
  {
    Header: 'Код',
    accessor: 'id',
    sortMethod: sortStringNumbers,
    width: 90,
  },
  {
    Header: 'Наименование',
    accessor: 'name',
    style: {
      textAlign: 'left',
    },
    minWidth: 250,
  },
  {
    Header: 'Бренд',
    accessor: 'brand',
    width: 90,
  },
  {
    Header: 'Партномер',
    accessor: 'partNo',
    width: 150,
  },
  {
    Header: 'Группа',
    accessor: 'group',
    width: 200,
  },
  {
    Header: 'Доступно Мск',
    accessor: 'availableMsk',
    width: 100,
  },
  {
    Header: 'Доступно Рег. склад',
    accessor: 'availableReg',
    width: 130,
  },
  {
    Header: 'Жкол',
    sortable: false,
    accessor: 'qty',
    id: 'qty',
    width: 90,
    Cell: InputQty,
  },
  {
    Header: 'Цена, ₽',
    accessor: 'price',
    id: 'price',
    show: !!demofondMode,
    width: 115,
    minWidth: 115,
    sortMethod: sortStringNumbers,
    Cell: () => {
      return <span>Цена по запросу</span>;
    },
  },
];

export default ItemColumns;
