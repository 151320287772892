import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useHover } from 'hooks/new';
import { Container } from 'common/layout/container';
import { DefaultLink } from 'common/ui/default-link';
import { Icon } from 'common/ui/icon';
import { Logo } from 'common/ui/logo';
import { Navigation } from 'components/new/navigation';
import Mixpanel from 'services/mixpanel';

import styles from './footer.module.scss';

export const Footer = ({ className }) => {
  const [mainLinkRef, isMainLinkHovered] = useHover();
  // const [facebookRef, isFacebookHovered] = useHover();

  const handleClick = track => () => {
    Mixpanel?.track(track);
  };

  return (
    <footer className={cn(styles['footer'], className)} data-testid="footer">
      <Container className={styles['footer__container']}>
        <Link to="/">
          <Logo position="bottom" />
        </Link>
        <Navigation />
      </Container>
      <div className={styles['footer__info']}>
        <Container className={styles['footer__info-container']}>
          <div className={styles['footer__info-left']}>
            <DefaultLink
              className={styles['footer__link']}
              href="https://merlion.com/"
              ref={mainLinkRef}
              onClick={handleClick('Сайт')}
            >
              <span>На основной сайт MERLION</span>
              <Icon name="arrow-right" color={isMainLinkHovered ? 'stroke-gray' : 'gray'} />
            </DefaultLink>
            <span className="footer__copy">&copy; 2020, MERLION. Все права защищены.</span>
          </div>
          {/* <div className={styles['footer__info-right']} ref={facebookRef}>
            <DefaultLink
              className={styles['footer__link']}
              href="https://www.facebook.com/MERLION.1992/"
              onClick={handleClick('Facebook')}
            >
              <Icon name="facebook" color={isFacebookHovered ? 'stroke-gray' : 'gray'} />
            </DefaultLink>
          </div> */}
        </Container>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  className: '',
};

Footer.propTypes = {
  className: PropTypes.string,
};
