import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useStore } from 'react-redux';
import { getCategoryNameFromData } from 'features/catalog/utils';

export default React.memo(() => {
  const {
    select: { order, product },
  } = useStore();

  const { orderNo = 'NEW' } = useSelector(order.getHeader);
  const groups = useSelector(product.getGroups);

  const groupKeys = Object.keys(groups);

  if (!groupKeys.length) {
    return null;
  }

  const groupTree = groupKeys.reduce((tree, group, index) => {
    return { ...tree, [`code${index + 1}`]: group };
  }, {});

  return (
    <div className="product-view breadcrumbs breadcrumbs__groups">
      {groupKeys.map((key, index) => {
        if (index === 0) {
          return (
            <span className="font-size__small" key={index}>
              {getCategoryNameFromData(groups[key])}
            </span>
          );
        }

        const path = orderNo !== 'NEW' && `/orders/edit/${orderNo}` || '/orders/new';

        return (
          <Link
            key={index}
            className="font-size__small link"
            to={{
              pathname: path,
              state: {
                groupNo: key,
                orderNo,
                tree: groupTree,
              },
            }}
          >
            {groups[key]}
          </Link>
        );
      })}
    </div>
  );
});
