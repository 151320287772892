import React, { useMemo } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
} from 'recharts';

import Content from 'components/layout/content';
import Shape from './components/Shape';
import CustomLegend from './components/Legend';
import MonthTick from './components/MonthTick';
import ChartInfo from './components/ChartInfo';
import { useSelector, useStore } from 'react-redux';
import withApi from 'hoc/withApi';

const responsiveContainerStyles = {
  width: '175%',
  height: 600,
};

const barChartStyles = {
  margin: { top: 30, right: 5, bottom: 0, left: 5 },
};

const labelFormatter = value => value.toLocaleString();

const Calendar = () => {
  const {
    select: { chart },
  } = useStore();

  const payments = useSelector(chart.getPayments);

  const legendItems = useMemo(
    () => ({
      expired: payments.some(({ status }) => status === 'Expired'),
      pay5d: payments.some(({ status }) => status === 'Pay5d'),
      pay30d: payments.some(({ status }) => status === 'Pay30d'),
    }),
    [payments],
  );

  return (
    <Content title="Платежный календарь">
      <div className="finances-chart-content">
        <ChartInfo />
        {payments.length > 0 && (
          <div className="chart-block">
            <ResponsiveContainer {...responsiveContainerStyles}>
              <BarChart data={payments} {...barChartStyles}>
                <CartesianGrid vertical={false} className="chart-block__grid" />
                <XAxis dataKey="dayOfMonth" tickLine={false} interval={0} />
                <XAxis
                  dataKey="index"
                  xAxisId="month"
                  axisLine={false}
                  tickLine={false}
                  interval={0}
                  tick={<MonthTick />}
                />
                <Legend verticalAlign="bottom" legendItems={legendItems} content={CustomLegend} />
                <Bar dataKey="sum" minPointSize={5} shape={Shape}>
                  <LabelList
                    className="chart-block__label"
                    position="top"
                    formatter={labelFormatter}
                  />
                  {payments.map((d, i) => (
                    <Cell key={`cell-${i}`} status={d.status} count={payments.length} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </Content>
  );
};

export default withApi(Calendar);
