// @ts-nocheck
/* eslint-disable class-methods-use-this */
class LocalStorage {
  isLocalStorageAvailable = null;

  eventHandlers = {};

  setItem(key, item) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  getItem(key) {
    let result = null;

    const data = localStorage.getItem(key);
    try {
      result = data ? JSON.parse(data) : null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        `Error while parsing data from localstorage for key: ${key}.
        Error is: ${e.message}, stack is: ${e.stack}`,
      );
    }

    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  reset() {
    localStorage.clear();
  }

  resetItem(key) {
    localStorage.removeItem(key);
  }

  addListener(key, cb) {
    this.eventHandlers[key] = event => {
      if (event.key === key) {
        cb();
      }
    };
    window.addEventListener('storage', this.eventHandlers[key]);
  }

  removeListener(key) {
    window.removeEventListener('storage', this.eventHandlers[key]);
  }
}

const LocalStorageInstance = new LocalStorage();

export { LocalStorageInstance as LocalStorage };

// import localForage from 'localforage';
// import { Storage } from './storage';

// export const LocalStorage = new Storage(localForage.createInstance(), {
//   driver: [localForage.INDEXEDDB, localForage.WEBSQL, localForage.LOCALSTORAGE],
//   name: 'b2b-store',
//   storeName: 'b2bStore',
//   version: 3,
// });
