export default payments =>
  [...payments].sort(({ date: dateA }, { date: dateB }) => {
    const dayA = dateA.substr(6, 2);
    const monthA = dateA.substr(4, 2);
    const yearA = dateA.substr(0, 4);

    const dayB = dateB.substr(6, 2);
    const monthB = dateB.substr(4, 2);
    const yearB = dateB.substr(0, 4);

    return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
  });
