import React from 'react';
import { Heading } from 'common/ui/heading';

export const TokenExpired = ({ message }) => (
  <div className="token-expired">
    <div className="token-expired__message">
      <Heading className="token-expired__heading">Ссылка недействительна.</Heading>
      {message}
    </div>
  </div>
);
