import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, FormSection } from 'redux-form';
import formConfig from '../constants/form-config';
import {
  optionsForDeliveryMethods,
  optionsForDeliveryOptions,
} from '../constants/optionsForSelect';
import { RenderDatePicker, RenderSelect } from './fields';
import { renderText } from 'components/fields';
import VendorData from './vendor-data';
import hiddenInput from './fields/hidenInput';
import { RenderCustomerName, RenderINN } from '../../fields';
import { SPECIAL_PRICES_FORM } from 'pages/VAD/constants';
import PropTypes from 'prop-types';

const SpecialPricesFormStepOne = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="special-prices__container">
      <div className="row">
        <div className="col-sm-12 special-prices__container--header">
          <p className="special-prices-text">
            Данный функционал позволяет запросить спеццены по мультивендорной спецификации через
            Merlion у вендора.
          </p>
          <Link to="/category/993" className={'add-vendor-data'}>
            <i className={'icon icon-help'} />
            Помощь
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <p className="special-prices-text">Заполните Название заказчика и ИНН заказчика:</p>
        </div>
        <Field
          name="customerName"
          label="Название заказчика"
          props={{ form: SPECIAL_PRICES_FORM }}
          required
          component={RenderCustomerName}
        />
        <Field
          name="inn"
          label="ИНН заказчика"
          props={{ form: SPECIAL_PRICES_FORM }}
          required
          component={RenderINN}
        />
        <FormSection name={'vendorData'}>
          <VendorData />
        </FormSection>
        <Field
          name="f100"
          label="Способ поставки"
          options={optionsForDeliveryOptions}
          placeholder="Выбрать способо поставки"
          required
          component={RenderSelect}
        />
        <Field
          name="f090"
          label="Вариант поставки"
          options={optionsForDeliveryMethods}
          placeholder="Выбрать вариант поставки"
          required
          component={RenderSelect}
        />
        <Field
          colSm={3}
          name="f120"
          label="Крайний срок ответа"
          required
          component={RenderDatePicker}
        />
      </div>
      <div className="row">
        <Field name="f060" label="Ссылка на конкурс" type="text" component={renderText} />
        <Field
          name="f070"
          label="Участие Партнера в разработке ТЗ"
          type="text"
          component={renderText}
        />
        <Field name="f110" label="Бюджет проекта" type="text" component={renderText} />
        <Field colSm={3} name="f080" label="Дата подачи документов" component={RenderDatePicker} />
        <Field
          name="f160"
          label="Были поставки от Вашей компании через Мерлион"
          type="text"
          component={renderText}
        />
        <Field
          name="f150"
          label="Есть ли конкуретное предложение от других вендоров или других дистрибьютеров"
          type="text"
          component={renderText}
        />
        <Field
          name="f130"
          label="КАМ в вендоре, отвечающий за спеццены"
          type="text"
          component={renderText}
        />

        <Field name="f140" label="Договоренности с вендором" type="text" component={renderText} />
        <Field
          colSm={2}
          name="f170"
          label="Желаемый % рентабельности"
          type="text"
          component={renderText}
          maxLength={5}
        />
        <Field colSm={4} name="f180" label="Сайт заказчика" type="text" component={renderText} />
        <Field colSm={6} name="f190" label="Прочее" type="text" component={renderText} />
        <Field name="f041" component={hiddenInput} />
      </div>
      <div className="row special-prices__container__buttons">
        <button className="btn btn--rect btn-outline-danger" type="submit">
          Далее
        </button>
      </div>
    </div>
  </form>
);

SpecialPricesFormStepOne.propTypes = {
  initialValues: PropTypes.shape({
    clientId: PropTypes.string,
    clientName: PropTypes.string,
    f080: PropTypes.instanceOf(Date),
    f120: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default reduxForm(formConfig)(SpecialPricesFormStepOne);
