import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { isCactus, isPreProdEnv } from 'common/config';
import Iframe from './iframe';

const classModal = classNames('Modal', 'Modal__opinions', { 'Modal--cactus': isCactus });

const Opinions = ({ show = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const onLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      {!isCactus && (
        <div
          className={classNames('opinions', {
            'opinions--hidden': !show,
          })}
          onClick={() => setIsOpen(true)}
        >
          Отзывы и предложения
          <i className="arrow" />
        </div>
      )}
      {!isCactus && (
        <div className="footer-opinions" onClick={() => setIsOpen(true)}>
          <i className="icon icon-otzv" />
          Отзывы и предложения
        </div>
      )}
      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        contentLabel="Modal"
        className={classModal}
        overlayClassName="Overlay Overlay__opinions"
        shouldCloseOnOverlayClick={false}
      >
        <div className="opinions-container">
          <i
            className="icon icon-close12"
            onClick={() => {
              setIsOpen(false);
              setIsLoading(true);
            }}
          />
          <i style={{ display: isLoading ? 'block' : 'none' }} className="loader" />
          <div
            style={{
              display: isLoading ? 'none' : 'block',
              height: '100%',
              width: '100%',
            }}
          >
            <p className="opinions-caption">Отзывы и предложения</p>
            <Iframe
              width="100%"
              height="94%"
              src={`https://wopinions.merlion.com/?refresh=1${
                isPreProdEnv ? '&stand=preprod' : ''
              }`}
              frameborder="0"
              onLoad={onLoad}
            />
            <a
              className="opinions-link"
              href="https://opinions.merlion.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Перейти на страницу проекта
            </a>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default Opinions;
