import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { getItemsFromMSKChecked, toggleAllItemsFromMSK } from '../../../../helpers';

const CheckboxField = (props, ref) => {
  const { inputRef, buttonRef, cnt } = props;

  const [checked, setChecked] = useState(false);

  const handleChange = (checked, notified) => {
    inputRef.current.toggle(checked, notified);
    setChecked(checked);

    if (checked) {
      if (getItemsFromMSKChecked().length === cnt) {
        toggleAllItemsFromMSK(true);
      }

      buttonRef.current.disabled = false;
    } else {
      const hasCheckedItems = getItemsFromMSKChecked().length > 0;

      if (!hasCheckedItems) {
        buttonRef.current.disabled = true;
      }

      toggleAllItemsFromMSK(false);
    }
  };

  useImperativeHandle(ref, () => ({
    change: (checked, notified) => {
      handleChange(checked, notified);
    },
  }));

  return (
    <input
      type="checkbox"
      name="warehouse-checkbox"
      checked={checked}
      onChange={({ target }) => handleChange(target.checked)}
    />
  );
};

export default forwardRef(CheckboxField);
