import React from 'react';
import classNames from 'classnames';

const Preloader = () => (
  <div className="products__preloader">
    <p
      className={classNames('products__preloader__text', 'font-size__regular', 'font-color__grey')}
    >
      Данные загружаются
    </p>
    <i className="products__preloader__image loader" />
  </div>
);

export default Preloader;
