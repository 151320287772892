import { getOrder } from 'pages/Orders/selectors';
import notifierDefault from 'services/notifier';

export default ({ api }) =>
  async ({ item, id, notifier = notifierDefault }, state) => {
    const order = getOrder(state);
    const orderId = order?.orderNo || id;

    notifier.show(item.id, `Товар ${item.id} удаляется`);

    await api.post({
      url: `/orders/delete/row/${orderId}`,
      data: {
        itemId: item.id,
        qty: item.desiredQty,
        price: item.desiredPriceRUB,
        lineNo: item.lineNo,
      },
      cancellation: false,
    });
  };
