import { selector } from 'client/store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import OrderHeaderForm from 'pages/Orders/components/order-edit/header-form';

const initValues = selector(({ order }) => ({
  docType: order.getInitialDocType,
  shipmentAgentInfo: order.getInitialShipmentAgent,
  contractorCode: order.getInitialContractorCode,
  packingType: order.getInitialPackingType,
  shipmentMethod: order.getInitialShipmentMethod,
  endCustomer: order.getInitialEndCustomer,
  postingDescription: order.getInitialPostingDescription
}));

const mapStateToProps = state => ({
  initialValues: initValues(state),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'ORDER_EDIT_FORM',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
  }),
)(OrderHeaderForm);
