import React, { useEffect, useMemo, Fragment } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import ContactsSections from 'pages/Help/components/contacts/components/ContactsSections';
import Loader from 'components/loader';
import useDelayedHoveredState from 'hooks/use-delay-hovered-state';
import Mixpanel from 'services/mixpanel';
import isEmpty from 'lodash/isEmpty';
import { getEffectLoading } from 'common/selectors';
import useToggleHandler from 'hooks/use-toggle-handler';

function ContactsWidget() {
  const {
    select: { user },
  } = useStore();

  const contacts = useSelector(user.getDecoratedContacts);
  const isContactsFetching = useSelector(getEffectLoading('user', 'getUserContacts'));

  const {
    user: { getUserContacts },
  } = useDispatch();

  const {
    isHovered: isWidgetHovered,
    handleMouseEnter: handleWidgetMouseEnter,
    handleMouseLeave: handleWidgetMouseLeave,
    top,
  } = useDelayedHoveredState({ delay: 500, trackPosition: true });

  useEffect(() => {
    isWidgetHovered &&
      Mixpanel?.track('ВашМенеджер', {
        page: 'СтрГлавнаяСтраница',
      });

    if (isEmpty(contacts) && isWidgetHovered) {
      getUserContacts();
    }
  }, [isWidgetHovered, contacts]);

  const [isShowAllContacts, , toggleShowAllContacts] = useToggleHandler();

  const contactsSections = useMemo(() => {
    if (isShowAllContacts) {
      return contacts;
    }

    return contacts.filter(({ id }) => id === 'manager');
  }, [isShowAllContacts, contacts]);

  return (
    <div
      className="contacts-widget"
      onMouseEnter={handleWidgetMouseEnter}
      onMouseLeave={handleWidgetMouseLeave}
    >
      <a href="#your-manager" className="contacts-widget__link">
        <i className="contacts-widget__link__icon icon icon-manag" />
        Ваш менеджер
      </a>
      {isWidgetHovered && (
        <div className="contacts-widget__dropdown" style={{ top: top + 'px' }}>
          {(isContactsFetching && <Loader>Контакты загружаются</Loader>) || (
            <Fragment>
              <ContactsSections
                columns={1}
                contactsSections={contactsSections}
                wrapperStyle={{ width: '390px', marginTop: '20px' }}
              />
              <a
                href="#toggle-contacts"
                className="contacts-widget__dropdown__link"
                onClick={toggleShowAllContacts}
              >
                {(isShowAllContacts && 'Скрыть контакты') || 'Все контакты'}
              </a>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
}

export default ContactsWidget;
