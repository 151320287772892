import React from 'react';
import { Link } from 'react-router-dom';

const EnabledMoveToAnotherOrderButton = props => {
  const { row, shipDate, currentDate } = props;

  if (+row.docType === 1 && shipDate > currentDate) {
    return (
      <Link
        to={`/orders/move/${row.orderNo}`}
        className="icon icon-migrate"
        title="Перенести товар в другой заказ"
      />
    );
  }

  return <i className="icon icon-migrate-dis" title="Перенести товар в другой заказ" />;
};

export default EnabledMoveToAnotherOrderButton;
