import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultField from 'pages/Orders/components/order-edit/header-form/components/defaultField';
import CustomTimePicker from 'pages/Orders/components/common/form-fields/CustomTimePicker';
import { getEffectLoading } from 'common/selectors';
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';
import { getFormValue } from 'pages/Orders/selectors';
import { TimePicker } from 'common/ui/time-picker';
import { def } from 'common/util';
import { useDesign } from 'hooks/new';
import useCanEdit from 'pages/new/orders/hooks/use-can-edit';
import pick from 'lodash/pick';

const ShipmentTimeField = props => {
  const { name, label, show = true } = props;
  const {
    order: { getShipmentTime, changeFieldValue, initForm },
  } = useDispatch();

  const isLoading = useSelector(getEffectLoading('order', 'getShipmentTime'));

  const agentInfo = useSelector(getFormValue('shipmentAgentInfo'));
  const [agentCode] = agentInfo?.split('#') || [];

  const day = useSelector(getFormValue('shipmentDate'));
  const type = useSelector(getFormValue('docType'));

  const [time, setTime] = useState({});
  const isPreorder = useIsPreorder();

  const [isPilot] = useDesign();
  const canEdit = useCanEdit();

  const { timeFrom, timeTo, changeAllow = 0, timeDiff, timeFromMin, timeToMax } = time;

  useEffect(() => {
    if (!def(agentCode)) {
      return;
    }

    day &&
      getShipmentTime({ day, agentCode }).then(time => {
        setTime(time);
        changeFieldValue(
          'deliveryInterval',
          pick(time, ['changeAllow', 'timeDiff', 'timeFromMin', 'timeToMax']),
        );
        initForm({ shipmentTime: { timeFrom: time.timeFrom, timeTo: time.timeTo } });
      });
  }, [day, agentCode]);

  const customProps = {
    ...props,
    originTimeFrom: timeFrom,
    originTimeTo: timeTo,
    isAllow: (canEdit && isPilot) || Number(changeAllow) === 1,
    timeDiff,
    timeMin: timeFromMin,
    timeMax: timeToMax,
    isLoading,
  };

  const disabled = !canEdit || Number(changeAllow) !== 1;

  if (!show || !time) {
    return <DefaultField title={label} fieldName={name} placeholder="Нет времени отгрузки" />;
  }

  if (+type !== 1 || isPreorder || (!isLoading && !def(agentCode))) {
    return <DefaultField title={label} fieldName={name} placeholder="Нет времени отгрузки" />;
  }

  return isPilot ? (
    <TimePicker
      onChange={value => props.input.onChange(value)}
      {...customProps}
      disabled={disabled}
    />
  ) : (
    <CustomTimePicker {...customProps} />
  );
};

export default ShipmentTimeField;
