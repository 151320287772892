// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DisabledOrderShipmentsButton = ({ row }) => {
  if (row.sign && !row.status.includes('учтен')) {
    return (
      <Link to={`/logistic/shipments/view/${row.orderNo}`}>
        <i title="Отгрузки по заказу" className="icon icon-logistic" />
      </Link>
    );
  }

  return <i title="Отгрузки по заказу" className="icon icon-logistic-dis" />;
};

DisabledOrderShipmentsButton.propTypes = {
  row: PropTypes.shape({
    sign: PropTypes.string,
    status: PropTypes.string,
    orderNo: PropTypes.string,
  }).isRequired,
};

export default DisabledOrderShipmentsButton;
