import { getEnvParams } from './get-env-params';

const os = require('os');

const { isDevEnv } = getEnvParams();

const protocol = `http${!isDevEnv ? 's' : ''}`;

const APP_CONTAINER_CLASS = 'js-app';
const APP_CONTAINER_SELECTOR = `.${APP_CONTAINER_CLASS}`;

const APP_NAME = 'Интернет заказ';
const APP_NAME_CACTUS = 'Office Assistant';

const API_URL = `${protocol}://${os.hostname()}/api`;

const FILE_URL = `${API_URL}/v1/file/get`;

const QUEUE_PAGE = '/queue';

const SOCK_JS_URL = `${protocol}://${os.hostname()}/centrifugo/connection/sockjs`;

const STATIC_PATH = '/dist';

const WDS_PORT = 8081;

export {
  APP_CONTAINER_CLASS,
  APP_CONTAINER_SELECTOR,
  APP_NAME,
  APP_NAME_CACTUS,
  API_URL,
  FILE_URL,
  QUEUE_PAGE,
  SOCK_JS_URL,
  STATIC_PATH,
  WDS_PORT,
};
