import React, { useState } from 'react';
import LoadingSubmitButton  from 'components/loading-sumbit-button';
import ReactTable from 'react-table-v6';
import { getPriceColumns } from 'pages/Settings/helpers/util';
import { useDispatch, useSelector } from 'react-redux';
import { getLinkingsRows } from 'pages/Settings/selectors';
import { getEffectLoading } from 'common/selectors';

const tHeadProps = () => ({ className: 'rt-thead--priced' });
const tableProps = () => ({ className: 'rt-table--priced' });

const LoadPrice = ({ isPrice }) => {

  const data = useSelector(getLinkingsRows);

  const {
    linkings: { priceLoad },
  } = useDispatch();

  const [startRow, changeStartRow] = useState(1);
  const [selectedValue, changeSelectedValue] = useState(1);

  const fetchingSave = useSelector(getEffectLoading('linkings', 'priceLoad'));

  if (!isPrice || !data.length) {
    return null;
  }

  const handleStartRowChange = e => changeStartRow(e.target.value);
  const handleChange = index => selected =>
    changeSelectedValue({ ...selectedValue, [index]: selected });

  const handlePriceLoad = async () => {
    await priceLoad({
      selected: selectedValue,
      start: startRow,
      cnt: columns.length - 1,
    });
  };

  let rows = [...data];
  const row = rows.shift();
  const columns = getPriceColumns(row, { selectedValue, handleChange });

  return (
    <div>
      <div className="price-rows-actions">
        <LoadingSubmitButton
          label="Загрузить"
          fetching={fetchingSave}
          onClick={handlePriceLoad}
          btnClass="btn btn-danger btn--rect btn-sm"
          position="left"
        />
        начиная с{' '}
        <input
          type="text"
          className="form-control form-control-sm"
          value={startRow}
          onChange={handleStartRowChange}
        />{' '}
        строки
      </div>
      <div className="price-rows-container">
        <ReactTable
          data={rows}
          columns={columns}
          minRows={1}
          showPagination={false}
          defaultPageSize={5000}
          pageSize={5000}
          noDataText="ничего не найдено"
          getTheadProps={tHeadProps}
          getTableProps={tableProps}
        />
      </div>
    </div>
  );
};

export default LoadPrice;
