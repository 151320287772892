import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Components = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M14.7647 17.5H3.23529C2.80836 17.5 2.5 17.189 2.5 16.7727V1.22727C2.5 0.811027 2.80836 0.5 3.23529 0.5H14.7647C15.1916 0.5 15.5 0.811026 15.5 1.22727V16.7727C15.5 17.189 15.1916 17.5 14.7647 17.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M11.817 10.1309C11.5675 10.0062 11.3492 9.91265 11.1309 10.1309C10.9127 10.3492 11.0062 10.5675 11.1309 10.817C11.2557 11.0665 12.0041 12.4075 12.3472 12.7193C12.7214 13.0936 13.3451 13.0936 13.7193 12.7193C14.0936 12.3451 14.0936 11.7214 13.7193 11.3472C13.4075 11.0353 12.0665 10.2557 11.817 10.1309Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M10.4125 11.725C9.75026 11.9198 9.06537 11.9851 8.32272 11.8734C5.59967 11.4638 3.70589 8.90709 4.11432 6.19189C4.52276 3.47669 7.08489 1.5903 9.80794 1.99991C12.531 2.40953 14.4248 4.9662 14.0163 7.6814C13.8988 8.46305 13.6285 9.1376 13.1994 9.74618"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 7C10.5 7.82843 9.82843 8.5 9 8.5C8.17157 8.5 7.5 7.82843 7.5 7C7.5 6.17157 8.17157 5.5 9 5.5C9.82843 5.5 10.5 6.17157 10.5 7Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <rect x="4" y="14" width="1" height="1" fill={color} />
      <rect x="6" y="14" width="1" height="1" fill={color} />
    </svg>
  );
};

Components.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Components.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
