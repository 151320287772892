/* eslint-disable no-unused-vars, no-console */
import { useState, useRef, useEffect, useCallback } from 'react';
import { isShallowEqual } from 'common/util';
import localForage from 'localforage';
import isNull from 'lodash/isNull';

/**
 * Hook to work with browser storages
 *
 * @param {string} key - storage key
 * @param {any} [initialValue = {}] - default storage value of key
 * @returns
 *
 *
 * @link https://github.com/localForage/localForage
 */
export default function useLocalForage(key, initialValue = {}) {
  const prevItem = useRef();
  const [item, setItem] = useState(initialValue);

  useEffect(() => {
    localForage
      .getItem(key)
      .then(val => setItem(val || initialValue))
      .catch(error => {
        console.log(error);

        setItem(initialValue);
      });
  }, [key]); // eslint-disable-line

  const setLocalItem = useCallback(
    async value => {
      try {
        if (!isShallowEqual(value, prevItem.current)) {
          if (isNull(value)) {
            await localForage.removeItem(key);
          } else {
            await localForage.setItem(key, value);
          }

          setItem(value);
          prevItem.current = value;
        }
      } catch (error) {
        console.log(error);
      }
    },
    [key],
  );

  return [item, setLocalItem];
}
