import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import Select from 'react-select';
import ReactModal from 'react-modal';
import validate from 'common/validates/validateInvite';
import classNames from 'classnames';
import { isCactus } from 'common/config';

const classModal = classNames('Modal', { 'Modal--cactus': isCactus });

const renderField = ({ input, label, meta: { touched, error } }) => (
  <div>
    <input {...input} type="text" className="form-control" placeholder={label} />
    {touched && error && <span className="text-danger text-danger--small">{error}</span>}
  </div>
);

const Content = properties => {
  const { mail, sending } = properties;

  if (mail) {
    return (
      <div>
        <p>
          Приглашение будет отправленно Вашему коллеге на адрес <b>{mail}</b>
        </p>
        <div className="row justify-content-center modal-actions">
          <button
            className="btn btn-outline-success btn--rect"
            disabled={sending}
            onClick={properties.sendModal}
          >
            Ок
          </button>
          <button
            className="btn btn-outline-danger btn--rect"
            disabled={sending}
            onClick={properties.closeModal}
          >
            Отмена
          </button>
        </div>
      </div>
    );
  }

  const Form = props => {
    const { handleSubmit, submitting, reset } = props;

    return (
      <div>
        <p>Вы можете предложить Вашего коллегу вместо себя</p>
        <form
          onSubmit={handleSubmit(properties.sendModal.bind(this))}
          onReset={properties.closeModal}
        >
          <div className="row justify-content-center substitute-form">
            <Field name="substFio" component={renderField} label="ФИО" />
            <Field name="substEmail" component={renderField} label="EMail" />
          </div>
          <div className="row justify-content-center modal-actions">
            <button
              type="submit"
              className="btn btn-outline-success btn--rect"
              disabled={submitting || sending}
            >
              Ок
            </button>
            <button
              type="reset"
              className="btn btn-outline-danger btn--rect"
              onClick={reset}
              disabled={submitting || sending}
            >
              Отмена
            </button>
          </div>
        </form>
      </div>
    );
  };

  const InviteForm = reduxForm({ form: 'substitute-form', validate })(Form);

  return <InviteForm />;
};

class Substitute extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    isOpen: false,
    mail: '',
    substitute: '',
    sending: false,
  };

  openModal = newItem => {
    this.setState({
      mail: newItem ? newItem.value : '',
      substitute: newItem,
      isOpen: true,
    });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  sendModal = cred => {
    this.setState({ sending: true });
    const user = cred.substFio
      ? { value: cred.substEmail, label: cred.substFio, contact: '' }
      : this.state.substitute;

    this.props.onSubstitute(user).then(() => this.setState({ sending: false, isOpen: false }));
  };

  render() {
    const { substitutions, event } = this.props;

    return (
      <div>
        <Select
          name="substitutes"
          placeholder="Предложить замену"
          clearable={false}
          value={this.state.substitute}
          options={[...[{ value: 0, label: 'Добавить контакт', contact: '' }], ...substitutions]}
          onChange={this.openModal}
          noResultsText="Все регионы"
          disabled={this.props.disabled || false}
        />
        <ReactModal
          isOpen={this.state.isOpen}
          onRequestClose={this.closeModal}
          contentLabel="Modal"
          className={classModal}
          overlayClassName="Overlay"
          ariaHideApp={false}
        >
          <div>
            <p>
              Вы отказываетесь от приглашения на мероприятие <b>«{event.row.name}»</b>
            </p>
            <Content
              mail={this.state.mail}
              sendModal={this.sendModal}
              closeModal={this.closeModal}
              sending={this.state.sending}
            />
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default Substitute;
