import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { setState } from '../../../../action/state';
import GroupsTree from './GroupsTree';
import Item from './Item';
import { OrderEditContext } from 'pages/Orders/components/order-edit/context';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder } from 'pages/Orders/selectors';
import useContextDispatch from 'hooks/context/useContextDispatch';

const SuggestContent = props => {
  const { items, brands, groups, text, wholeItemsLength, isDemofond } = props;

  const stateDispatch = useContextDispatch(OrderEditContext);

  const { orderNo = 'NEW' } = useSelector(getOrder);
  const {
    items: { handleSearch },
  } = useDispatch();


  const handleClick =
    ({ name }) =>
    async e => {
      e.preventDefault();

      if (isDemofond) {
        await handleSearch({ text: name, isDemo: true });

        return;
      }

      stateDispatch(
        setState({
          skipFilterFlushRequest: true,
        }),
      );

      await handleSearch({ text: name });

      stateDispatch(
        setState({
          groups: {},
          searchText: name,
          skipFilterFlushRequest: true,
        }),
      );
    };

  const showAllItems = useCallback(
    async event => {
      await handleClick({ name: text })(event);
    },
    [text],
  );

  return (
    <div className="suggest-content">
      {!!items.length && (
        <div className="suggests suggests__items">
          <span className="caption">Товары</span>
          <div>
            {items.map(item => (
              <Item
                key={item.id}
                item={item}
                text={text}
                orderNo={orderNo}
                isDemofond={isDemofond}
              />
            ))}
          </div>
          {wholeItemsLength > 10 && (
            <span className="show-all-items" onClick={showAllItems}>
              Показать все результаты поиска
            </span>
          )}
        </div>
      )}
      {!!groups.length && (
        <div className="suggests suggests__groups">
          <span className="caption">Категории</span>
          <GroupsTree groups={groups} text={text} isDemofond={isDemofond} />
        </div>
      )}
      {!!brands.length && (
        <div className="suggests suggests__brands">
          <span className="caption">Бренды</span>
          {brands.map(brand => (
            <span key={brand.name} className="active" onClick={handleClick(brand)}>
              {brand.name}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

SuggestContent.propTypes = {
  items: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
};

export default memo(SuggestContent);
