export default {
  name: 'events',
  state: {
    data: [],
    invites: 0,
    regions: [],
    brands: [],
    pages: 0,
    invitesStatuses: {},
    colleagues: [],
    dates: [],
  },
  reducers: {
    load: (state, payload) => {
      const statuses = {};
      const data = payload?.data?.data || [];

      data.forEach(({ contactStatus }, key) => {
        statuses[key] = contactStatus;
      });

      return {
        ...state,
        ...payload.data,
        invitesStatuses: statuses,
      };
    },
    invite: (state, { index, inviteStatus }) => {
      state.invitesStatuses[index] = inviteStatus;

      return state;
    },
  },
  effects: ({ api }) => ({
    async get() {
      await api.get({
        url: 'events',
        query: '?only=1',
      });
    },
    async changeInviteStatus({ event: { original, index }, type, substitute = {} }) {
      await api.post({
        url: 'invite/send',
        data: {
          event: original,
          type,
          index,
          substitute,
        },
      });
    },
    watch(no){
      api.get({url: `events/watch/${no}`})
    }
  }),
};
