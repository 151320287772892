import { getUnixTimeStamp } from './get-unix-time';

const sortByPeriods = (prevDateDMY, nextDateDMY) => {
  const [startPrevDate, endPrevDate] = prevDateDMY.split('-');
  const [startNextDate, endNextDate] = nextDateDMY.split('-');
  const prevDate = getUnixTimeStamp(startPrevDate);
  const nextDate = getUnixTimeStamp(startNextDate);

  if (prevDate === nextDate) {
    const prevDate = getUnixTimeStamp(endPrevDate);
    const nextDate = getUnixTimeStamp(endNextDate);

    if (prevDate < nextDate) return -1;
    if (prevDate > nextDate) return 1;
  }

  if (prevDate < nextDate) return -1;
  if (prevDate > nextDate) return 1;
  return 0;
};

export default sortByPeriods;
