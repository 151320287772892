import React, { useCallback, useRef } from 'react';
import TableList from 'components/table/list';
import Content from 'components/layout/content';
import AppSocket from 'components/AppSocket';
import { useDispatch, useSelector, useStore } from 'react-redux';
import withApi from 'hoc/withApi';
import { OrderViewContext } from 'pages/Orders/constances';
import OrderInfo from './components/OrderInfo';
import HeaderInfo from './components/HeaderInfo';
import OrderActions from './components/OrderActions';
import createColumns from './columns/createColumns';

const getTrProps = () => ({ style: { minHeight: '40px' } });
const getTdProps = (state, info) => ({
  style: { color: info?.original?.color || '#000' },
});
const defaultSorted = [{ id: 'id', desc: true }];

const OrdersItem = () => {
  const tableRef = useRef();
  const { select } = useStore();
  const order = useSelector(select.order.getHeader);

  const {
    order: { receive, expire },
  } = useDispatch();

  const columns = createColumns({
    ...order,
    settingsCurrency: useSelector(select.user.getCurrency),
  });

  const onReceive = useCallback(message => {
    receive({ data: message.data });
  }, []);

  return (
    <AppSocket channel="queue" onReceive={onReceive} onExpire={expire}>
      <Content title="Просмотр заказа">
        <OrderViewContext.Provider value={{instance: tableRef}}>
          <OrderActions order={order} />
        </OrderViewContext.Provider>
        <HeaderInfo />
        <div className="order-list" id="order-list" style={{ height: '90%' }}>
          <TableList
            columns={columns}
            defaultSorted={defaultSorted}
            style={{ height: '100%' }}
            defaultPageSize={5000}
            pageSize={5000}
            getTrProps={getTrProps}
            getTdProps={getTdProps}
            ref={tableRef}
          />
        </div>
        <OrderInfo />
      </Content>
    </AppSocket>
  );
};

export default withApi(OrdersItem);
