import { matchRoutes } from 'react-router-config';
import {def, undef} from "common/util"

function reactRouterFetch(routes, location, options) {
  const branch = matchRoutes(routes, location.pathname);
  const {
    dispatch,
    state: { secure },
  } = options;

  if (branch.length > 0) {
    const noAuthChecking = branch.some(({ route: { props } }) => props?.auth);

    if (!secure.isCredPresence && !noAuthChecking) {
      return false;
    }

    const currentBranch = branch.find(({match:{ isExact }}) => isExact)
    const { props = {} } = currentBranch?.route || {}

    if(undef(options.cache)){
      options.cache = props.cache;
    }

    options.apiVersion = props.apiVersion || 'v1'

    const promises = branch
      .filter(({ route, match }) => match.isExact && route.component && route.component.fetch)
      .map(({ route, match }) => route.component.fetch(match, location, options));

    const needCurrency = branch.some(({ route }) => route.showBalance);
    const isOrders = branch.some(({ route: { path = '/' } }) => path.match(/(orders|products)/));

    if (!noAuthChecking) {
      if (needCurrency) {
        promises.unshift(dispatch.user.checkCurrency());
      }
      if (isOrders) {
        promises.unshift(dispatch.user.checkOrderSettings());
      }

      promises.unshift([dispatch.user.checkPresence(), dispatch.user.checkViewSettings()]);
    }

    if (promises && promises.length > 0) {
      if (promises.length === 1 && Array.isArray(promises[0])) {
        dispatch.api.clearFails();
      }

      return Promise.all(promises);
    }
  }

  return false;
}

export default reactRouterFetch;
