import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Out = ({
  className,
  color = '#236192',
  name,
  width = '13',
  height = '10',
  viewBox = '0 0 13 10',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M12.5 0.5H0.5V9.5H12.5V0.5Z" stroke={color} strokeMiterlimit="10" />
      <path d="M8.5 0.5H4.5V3.5H8.5V0.5Z" stroke={color} strokeMiterlimit="10" />
      <path d="M11 7H9V8H11V7Z" fill={color} />
    </svg>
  );
};

Out.defaultProps = {
  className: '',
  color: '#236192',
  width: '13',
  height: '10',
  viewBox: '0 0 13 10',
};

Out.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
