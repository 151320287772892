import { acceptDefault } from 'components/constants/file-types';
import { filesValidators } from '../validates/validateFeedBack';

const warnsShipingAgent = values => {
  const warnings = {};
  if (values.otherField) {
    warnings.otherField = filesValidators(values.otherField, acceptDefault);
  }
  return warnings;
};

export default warnsShipingAgent;
