import React, { useState, useEffect, memo } from 'react';
import classNames from 'classnames';
import { validatePrice, checkMaxQty } from 'common/util';
import { regExpOnlyNumber } from 'helpers/regexpes';
import alert from 'components/native/alert/function';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import { getQtyLimit } from 'common/selectors/get-qty-limit';
import isEqual from 'lodash/isEqual';
import { redirectSpecificOfItem } from 'utils/redirect-specific-item';
import { getDesign } from 'features/user';

const InfoRequest = ({ product, code, specificOfItem }) => {
  const fetching = useSelector(getEffectLoading('product', 'addToOrder'));
  const isAvailabilityFetching = useSelector(getEffectLoading('product', 'getAvailability'));
  const qtyLimit = useSelector(getQtyLimit);
  const design = useSelector(getDesign);

  const { id, desiredQty = 0, desiredPriceRUB = 0, priceLCY = 0 } = product;

  const {
    product: { addToOrder },
  } = useDispatch();

  const [qty, setQty] = useState('');
  const [price, setPrice] = useState('');

  const handleChange = ({ target: { value } }) => {
    if (!isNaN(value) && value >= 0) {
      setPrice(value);
    }
  };

  const handleBlur = () => {
    const oldPrice = parseFloat(priceLCY).toFixed(2);
    const validatedPrice = validatePrice(price, oldPrice);

    if (oldPrice) {
      if (!validatedPrice.success) {
        alert(
          `Слишком большое отклонение ЖЦены от РЦены для товара ${id}.<br /> Введите корректное значение!`,
        );
        setPrice(validatedPrice.price);
      }
    }
  };

  const handleBlurByQty = () => {
    const { salesLimitType, minPackaged, id } = product;
    const argsToCheck = {
      value: qty,
      code,
      salesLimitType,
      minPackaged,
      qtyLimit,
    };

    redirectSpecificOfItem(specificOfItem, design, id, setQty);

    if (checkMaxQty(argsToCheck)) {
      setQty(qtyLimit);
    }
  };

  useEffect(() => {
    setQty(desiredQty);
    setPrice(parseFloat(desiredPriceRUB).toFixed(2));

    return () => {
      setQty('');
      setPrice('');
    };
  }, [id, desiredQty, desiredPriceRUB]);

  return (
    <div className="info__form">
      <div className="info__form__count">
        <label className={classNames('info__form__label', 'font-color__grey', 'font-size__small')}>
          Количество
        </label>
        <input
          type="text"
          className="info__form__input-text"
          value={qty}
          onChange={e => setQty(e.target.value)}
          onBlur={handleBlurByQty}
        />
      </div>
      <div className="info__form__price">
        <label className={classNames('info__form__label', 'font-color__grey', 'font-size__small')}>
          Желаемая цена
        </label>
        <input
          type="text"
          className="info__form__input-text"
          value={price}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="info__form__buy">
        <button
          className={classNames('info__form__button', 'font-size__medium')}
          disabled={fetching || isAvailabilityFetching}
          onClick={async () => await addToOrder({ qty, price })}
        >
          Добавить в заказ
        </button>
      </div>
    </div>
  );
};

export default memo(InfoRequest, isEqual);
