import { useEffect, useState } from 'react';

const useStateWithCallback = () => (initialState, callback) => {
  const [state, setState] = useState < T > initialState;

  useEffect(() => callback(state), [state, callback]);

  return [state, setState];
};

export { useStateWithCallback };
