import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../icon.module.scss';

export const Minus = ({
  className,
  color = '#f15b5b',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width={width} height={height} rx="9" fill={color} />
    <rect x="4" y="9" fill="#FFFFFF" width="10" height="1" />
  </svg>
);

Minus.defaultProps = {
  className: '',
  color: '#f15b5b',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Minus.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
