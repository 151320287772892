const OrderColumns = isDemoOrder => [
  {
    Header: 'Код',
    accessor: 'id',
    width: 90,
  },
  {
    Header: 'Наименование',
    accessor: 'name',
    headerStyle: {
      textAlign: 'left',
    },
    style: {
      textAlign: 'left',
    },
    minWidth: isDemoOrder ? 618 : 503,
    width: '100%',
  },
  {
    Header: 'Бренд',
    accessor: 'brand',
    width: 90,
  },
  {
    Header: 'Партномер',
    accessor: 'partNo',
    width: 90,
  },
  {
    Header: 'Жкол',
    accessor: 'qty',
    width: 90,
  },
];

export default OrderColumns;
