import { fileTypeForbidden } from 'components/constants/file-types';
import { MAX_FILE_SIZE } from 'pages/Logistic/constants/max-file-size-shiping-agent';
import fileValidators from '../../utils/file-validators';
import { FEEDBACK_FILE_TYPES } from '../config';

const MAX_LENGTH_CONTACT_FIRST = 30;
const MAX_LENGTH_CONTACT_SECOND = 50;
const MAX_LENGTH = 10;
const requiredField = 'Заполните поле';
const regExpPhone = /\+(7|37|38)\(\d{3}\)\d{3}-\d{2}-\d{2}/;
const invalidPhoneField = 'Поле заполнено неверно';
const fileValidatorOptions = {
  maxFileSize: MAX_FILE_SIZE,
  fileTypeForbidden,
  feedbackFileTypes: FEEDBACK_FILE_TYPES,
};

const validateShipingAgent = values => {
  const errors = {};

  if (!values.typeShipingAgent) {
    errors.typeShipingAgent = requiredField;
  }

  if (!values.shipingAgent) {
    errors.shipingAgent = requiredField;
  }

  if (!values.company) {
    errors.company = requiredField;
  }

  if (!values.phoneFirst) {
    errors.phoneFirst = requiredField;
  } else if (!regExpPhone.test(values.phoneFirst)) {
    errors.phoneFirst = invalidPhoneField;
  }

  if (values.phoneSecond && !regExpPhone.test(values.phoneSecond)) {
    errors.phoneSecond = invalidPhoneField;
  }

  if (!values.time) {
    errors.time = requiredField;
  }

  if (!values.methodGetDocument) {
    errors.methodGetDocument = requiredField;
  }

  if (!values.typeUnload) {
    errors.typeUnload = requiredField;
  }

  if (!values.maxTruck) {
    errors.maxTruck = requiredField;
  }

  if (values.contactFirst && values.contactFirst.length > MAX_LENGTH_CONTACT_FIRST) {
    errors.contactFirst = `Максимальная длина ${MAX_LENGTH_CONTACT_FIRST} символов`;
  }

  if (values.contactSecond && values.contactSecond.length > MAX_LENGTH_CONTACT_SECOND) {
    errors.contactSecond = `Максимальная длина ${MAX_LENGTH_CONTACT_SECOND} символов`;
  }

  if (values.house && values.house.length > MAX_LENGTH) {
    errors.house = `Максимальная длина ${MAX_LENGTH} символов`;
  }

  if (values.korpus && values.korpus.length > MAX_LENGTH) {
    errors.korpus = `Максимальная длина ${MAX_LENGTH} символов`;
  }

  if (values.stroenie && values.stroenie.length > MAX_LENGTH) {
    errors.stroenie = `Максимальная длина ${MAX_LENGTH} символов`;
  }

  if (values.otherFiles) {
    errors.otherFiles = fileValidators(values.otherFiles, fileValidatorOptions);
  }

  if (!values.rent) {
    errors.rent = requiredField;
  }

  if (!values.branch) {
    errors.branch = requiredField;
  }

  if (!values.street) {
    errors.street = requiredField;
  }

  if (!values.house) {
    errors.house = requiredField;
  }

  if (!values.contactFirst) {
    errors.contactFirst = requiredField;
  }

  if (values.time && !values.time.timeFrom && !values.time.timeTo) {
    errors.time = requiredField;
  }

  return errors;
};

export default validateShipingAgent;
