export default values => {
  const errors = {};
  if (!values.clientNo || !values.clientNo.trim()) {
    errors.clientNo = 'Заполните поле';
  }
  if (!values.clientLogin || !values.clientLogin.trim()) {
    errors.clientLogin = 'Заполните поле';
  }
  if (!values.password) {
    errors.password = 'Заполните поле';
  }
  return errors;
};
