// @ts-nocheck
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { compose } from 'redux';
import {formattingFilters, getAvailTag, setHashFilters, sortFilters} from '../utils'
import { buildFilters, getFiltersFromGoods } from '../utils/build-filters';
import {getItemsAvailFilter} from "pages/Orders/selectors"

export default {
  name: 'newFilters',
  state: {
    tag: {},
    isEditTag: 0,
    tags: [],
    filters: [],
  },
  reducers: {
    addTag: (state, payload) => {
      const indexTag = state.tags.findIndex(tag => tag.id === payload.id);

      if (indexTag !== -1) {
        state.tags[indexTag] = {
          ...state.tags[indexTag],
          ...payload,
        };
      } else {
        state.tags.push(payload);
      }
    },
    removeTag: (state, payload) => {
      state.tags = state.tags.filter(tag => tag.id !== payload);
    },
    setFilters: (state, payload) => {
      state.filters = get(payload, ['data', 'filters'], []);
    },
    setIsEditTag: (state, payload) => {
      state.isEditTag = payload;
    },
    setTag: (state, payload) => {
      state.tag = payload;
    },
    clear: state => {
      state.tag = {};
      state.isEditTag = false;
      state.tags = [];
      state.filters = [];
    },
  },
  selectors: (slice, createSelector) => ({
    getFilters({ newOrdersNew }) {
      return createSelector(
        newOrdersNew.getGoods,
        slice(state => get(state, 'filters', [])),
        (goods, filters) =>
          compose(
            sortFilters,
            formattingFilters,
            buildFilters(filters),
            getFiltersFromGoods,
          )(goods),
      );
    },
    getTags() {
      return slice(state => get(state, 'tags', []) || []);
    },
    getTagsIds(){
      return createSelector(
        this.getTags,
        tags => tags.map(tag => tag.id)
      )
    },
    getTag() {
      return slice(state => get(state, 'tag', {}));
    },
    getAvailTag(){
      return createSelector(
        getItemsAvailFilter,
        getAvailTag
      )
    },
    getLastTag(){
      return createSelector(
        this.getTags,
        tags => tags[tags.length - 1] || {}
      )
    },
    getIsEditTag() {
      return slice(state => get(state, 'isEditTag', false));
    },
    getActiveTag() {
      return createSelector(
        this.getIsEditTag,
        this.getTags,
        (isEditTag, tags) => tags.find(tag => tag.id === isEditTag)
      )
    },
    isFiltersAvailable({ newOrdersNew }) {
      return createSelector(
        newOrdersNew.hasGoods,
        this.getFilters,
        this.getTags,
        (hasGoods, filters, tags) => {
          return hasGoods && filters.length > tags.length
        }
      )
    },
    getHashFilters() {
      return slice(state => setHashFilters(get(state, 'filters', [])));
    }
  }),
};
