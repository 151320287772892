import React, {useReducer, useEffect, useRef, useCallback, useMemo} from 'react'
import { Context } from 'use-context-selector';
import { getDisplayName } from 'common/util';

/**
 * HOC to add useReducer functionality to Context.Provider for control some state
 *
 * @param {React.Reducer<React.ReducerState<any>, React.ReducerAction<any>>} reducer
 * @param {object} initialState
 * @param {Context<any>} Context
 * @param {String} [clearStateActionType = '']
 *
 * @returns {function(React.ComponentType): React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
export default (reducer, initialState, Context, clearStateActionType = '') =>
  WrappedComponent => {
    const { Provider } = Context;

    const Reduced = (props, ref) => {
      const [state, dispatch] = useReducer(reducer, initialState);

      useEffect(
        () => () => {
          if (clearStateActionType) {
            dispatch({
              type: clearStateActionType,
            });
          }
        },
        [],
      );

      const customDispatch = useCallback(
        action => (typeof action === 'function' ? action(customDispatch) : dispatch(action)),
        [],
      );

      const getValue = callback => callback(state);

      const contextValue = useMemo(() => ({
        getValue, dispatch: customDispatch
      }), [state, customDispatch])

      return (
        <Provider value={contextValue}>
          <WrappedComponent {...props} ref={ref} />
        </Provider>
      );
    };

    Reduced.displayName = `WithContextReducer(${getDisplayName(WrappedComponent)})`;

    return React.forwardRef(Reduced);
  };
