import React from 'react';

function getRequiredSymbol(required) {
  if (required) {
    return <span className="form-field-download__required">*</span>;
  }

  return null;
}

const Label = ({ title, required, children }) => {
  if (title) {
    return (
      <div className="form-field-download__title">
        {title}
        {getRequiredSymbol(required)}
        {children}
      </div>
    );
  }

  return null;
};

export default Label;
