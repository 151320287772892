import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Content from 'components/layout/content';
import AppSocket from 'components/AppSocket';
import OrderHeader from './queue-header';
import {
  QUEUE_ACCOUNTED,
  QUEUE_CLAIM,
  QUEUE_DOCS,
  QUEUE_JOIN,
  QUEUE_LINKS,
  QUEUE_MOVE,
  QUEUE_ORDER,
} from 'common/config';
import TableList from 'components/table/list';
import {
  bindingColumns,
  documentColumns,
  integrateColumns,
  migrateColumns,
  orderColumns,
} from 'pages/Queue/components/columns';
import withApi from 'hoc/withApi';
import { useDispatch, useSelector } from 'react-redux';
import useLocationValue from 'hooks/use-location-value';
import PropTypes from 'prop-types';

const defaultSorted = [
  {
    id: 'date',
    desc: true,
  },
];

export const Queue = ({ channel, children, receive, expire, columns = [] }) => {
  const queueType = useLocationValue('queueType');
  const { id } = useParams();
  const processing = useSelector(state => state.queue.processing);

  const {
    queue: { onReceive, onExpire },
  } = useDispatch();

  let title = '';
  let backRoute = '/orders';
  let backTitle = 'Список заказов';
  let backIcon = 'back-icon';

  let queueColumns = columns;

  switch (queueType) {
    case QUEUE_ACCOUNTED:
      backRoute = `/finances/accounted/${id}`;
      backTitle = 'Просмотр заказа';

    // eslint-disable-next-line no-fallthrough
    case QUEUE_DOCS:
      title = `Документы: заказ ${id}`;
      queueColumns = documentColumns;

      break;
    case QUEUE_CLAIM:
      backRoute = '/logistic/claims';
      backTitle = 'Список претензий';
      title = `Очередь создания претензий`;

      break;
    case QUEUE_MOVE:
      title = 'Очередь миграции';
      queueColumns = migrateColumns;

      break;
    case QUEUE_JOIN:
      title = 'Очередь объединения заказов';
      queueColumns = integrateColumns(id);

      break;
    case QUEUE_ORDER:
      title = 'Очередь обработки заказов';
      queueColumns = orderColumns;

      break;
    case QUEUE_LINKS:
      backRoute = '/settings/bindings';
      backTitle = 'Привязка товаров';
      backIcon = 'bindings';
      title = 'Очередь загрузки';
      queueColumns = bindingColumns;

      break;
  }

  return (
    <AppSocket
      channel={channel || 'queue'}
      onReceive={receive || onReceive}
      onExpire={expire || onExpire}
    >
      <Content title={title} className="queue-container">
        <div className="row queue-actions">
          <div
            className="col-sm row align-items-center justify-content-between"
            style={{ position: 'relative' }}
          >
            <div>
              <Link to={backRoute} className="section-nav">
                <i className={`icon icon-${backIcon}`} />
                {backTitle}
              </Link>
              &nbsp;&nbsp;
              {children}
            </div>
            {![QUEUE_MOVE, QUEUE_JOIN, QUEUE_LINKS].includes(queueType) && (
              <span className="alert-queue">
                Формирование документа может занять некоторое время. Пожалуйста, обновите страницу
                позже
              </span>
            )}
            {[QUEUE_MOVE, QUEUE_JOIN].includes(queueType) && processing && <i className="loader" />}
          </div>
        </div>
        <OrderHeader />
        <div className="row queue-table">
          <TableList columns={queueColumns} defaultSorted={defaultSorted} />
        </div>
      </Content>
    </AppSocket>
  );
};

Queue.propTypes = {
  columns: PropTypes.array,
  channel: PropTypes.string,
  receive: PropTypes.func,
  expire: PropTypes.func,
};

export default withApi(Queue);
