// @ts-nocheck
import notifier from 'services/new/notifier';
import { FORMAT_FILE } from 'common/constants/format-file';

export const fetchDownloadFiles = (dispatch, type) => async payload => {
  const { orderNo, action, email, format, docType, type: typeDoc } = payload;
  const options = {
    action,
    format: FORMAT_FILE[format],
    email,
    type: typeDoc,
    docType,
  };
  try {
    const result = await dispatch.api.post({
      url: `new-download-files/${orderNo}`,
      data: options,
    });

    dispatch[type].setJobType('doc');
    return result;
  } catch (err) {
    return null;
  }
};

export const fetchOrderSignature = (dispatch, type) => async orderNo => {
  try {
    notifier.show(orderNo, `Заказ ${orderNo} отправлен на подпись`);
    const result = await dispatch.api.post({
      url: `new-order-signature/${orderNo}`,
      data: {
        command: 'sign',
        queueType: 'QUEUE_ORDER',
        signed: true,
      },
    });

    dispatch[type].setJobId(result.jobId);
    dispatch[type].setJobType('order');

    return result;
  } catch (err) {
    notifier.resolve(
      {
        success: false,
        message: `Ошибка отправки на подпись!`,
      },
      orderNo,
    );
    return null;
  }
};

export const fetchOrderUnsignature = (dispatch, type) => async orderNo => {
  try {
    notifier.show(orderNo, `Заказ ${orderNo} снимается с подписи`);
    const result = await dispatch.api.post({
      url: `new-order-unsignature/${orderNo}`,
      data: {
        command: 'unsign',
        queueType: 'QUEUE_ORDER',
        signed: false,
      },
    });

    dispatch[type].setJobId(result.jobId);
    dispatch[type].setJobType('order');

    return result;
  } catch (err) {
    notifier.resolve(
      {
        success: false,
        message: `Ошибка отправки на снятие с подписи!`,
      },
      orderNo,
    );
    return null;
  }
};

export const fetchOrderRemoved = (dispatch, type) => async orderNo => {
  try {
    notifier.show(orderNo, `Заказ ${orderNo} удаляется`);
    const result = await dispatch.api.post({
      url: `new-order-removed/${orderNo}`,
      data: {
        command: 'del',
        queueType: 'QUEUE_ORDER',
      },
    });

    dispatch[type].setJobId(result.jobId);
    dispatch[type].setJobType('order');

    return result;
  } catch (err) {
    notifier.resolve(
      {
        success: false,
        message: `Ошибка удаления заказа!`,
      },
      orderNo,
    );

    return null;
  }
};

export const fetchOrdersShipment = (dispatch, type) => orderNo => {
  // dispatch(request('order'))
  // dispatch[type].fetchOrdersShipmentSuccess(OrdersShipment);
};

export const fetchShipmentSubscription = (dispatch, type) => async () => {
  try {
    return await dispatch.api.get({
      url: 'new-order-shipment-subscription',
    });
  } catch (err) {
    return null;
  }
};
