import React, { useEffect } from 'react';
import { CustomHidden } from '../../../common/form-fields/CustomHidden';
import { useDispatch } from 'react-redux';
import { registerField, unregisterField } from 'redux-form';

const DeliveryIntervalField = ({ show = true, ...rest }) => {
  const { name } = rest;

  const dispatch = useDispatch();

  useEffect(() => {
    const action = !show ? unregisterField : registerField;
    dispatch(action('ORDER_EDIT_FORM', name));
  }, [show]);

  return <CustomHidden {...rest} />;
};

export default DeliveryIntervalField;
