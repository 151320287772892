import React from 'react';
import logoutImg from 'img/logout.gif';
import { useDispatch } from 'react-redux';

const Logout = () => {
  const {
    auth: { logout },
  } = useDispatch();

  return (
    <a
      href="#"
      onClick={e => {
        e.preventDefault();
        logout();
      }}
      className="logout-link"
      title="Выйти"
    >
      <img src={logoutImg} alt="Выйти" />
    </a>
  );
};

export default Logout;
