import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import useDelayedHoveredState from '../hooks/use-delay-hovered-state';

export class TitleWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return createPortal(this.props.children, this.el);
  }
}

const withTitle = content => WrappedComponent => {
  const {
    isHovered: isWidgetHovered,
    setIsHovered,
    top,
    left,
    handleMouseEnter: handleWidgetMouseEnter,
    handleMouseLeave: handleWidgetMouseLeave,
  } = useDelayedHoveredState({
    delay: 800,
    trackPosition: true,
  });

  return (
    <Fragment>
      <div
        onMouseEnter={handleWidgetMouseEnter}
        onMouseLeave={e => {
          if (e && e.relatedTarget && [...e.relatedTarget.classList].includes('title-widget')) {
            return;
          }

          handleWidgetMouseLeave();
        }}
      >
        <div onMouseDown={() => setIsHovered(false)}>{WrappedComponent}</div>
      </div>
      {isWidgetHovered && (
        <TitleWrapper>
          <div
            className="title-widget title-widget__dropdown"
            style={{ top, left }}
            onClick={e => {
              e.stopPropagation();
              setIsHovered(false);
            }}
            onMouseLeave={handleWidgetMouseLeave}
          >
            {content}
          </div>
        </TitleWrapper>
      )}
    </Fragment>
  );
};

export default withTitle;
