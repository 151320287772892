import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import OrderFromTransit from '../../components/common/order-from-transit';
import OrderFromWarehouse from '../../components/common/order-from-warehouse';
import classNames from 'classnames';
import { isCactus } from 'common/config';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'pages/Orders/selectors';

const classModal = classNames('Modal', 'order-actions-modal', { 'Modal--cactus': isCactus });

const OrderActions = ({ order }) => {
  const user = useSelector(getUser);
  const allowTranRes = Number(user['Allow TranRes']);

  const {
    order: { createRemains, handleSign: sign },
  } = useDispatch();

  const { current: isAllowTranRes } = useRef(allowTranRes === 1);
  const [visible, setVisible] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [componentForModal, setComponentForModal] = useState(null);

  const handleRemains = useCallback(async () => {
    setVisible(false);
    await createRemains(order.orderNo);
  }, [order.orderNo]);

  const handleSign = useCallback(async () => {
    setVisible(false);
    await sign({ id: order.orderNo });
  }, [order.orderNo]);

  if (Number(order.docType) !== 1) {
    return null;
  }

  const isRegional = +order.isRegional === 1;

  const getComponent = componentName => {
    switch (componentName) {
      case 'transit':
        return <OrderFromTransit closeModal={closeModal} />;
      case 'warehouse':
        return <OrderFromWarehouse closeModal={closeModal} />;
      default:
        return null;
    }
  };

  const _setModalVisibility = componentName => {
    if (visible) {
      setVisible(false);
    }

    const component = getComponent(componentName);
    setComponentForModal(component);
    setModalVisibility(!modalVisibility);
  };

  const closeModal = () => {
    setModalVisibility(false);
  };

  return (
    <div className="order-edit-links">
      <input
        type="checkbox"
        id="order-toggle-actions"
        value={visible}
        checked={visible}
        onChange={() => setVisible(!visible)}
      />
      <label htmlFor="order-toggle-actions">
        <span />
        <span />
        <span />
        <span />
      </label>
      <ul>
        <li>
          <Link to={`/orders/move/${order.orderNo}`} className="link">
            Перенести в другой заказ
          </Link>
        </li>
        <li>
          <Link to={`/orders/split/${order.orderNo}`} className="link">
            Разделить заказ на дату
          </Link>
        </li>
        <li onClick={handleRemains}>Создать заказ на остатки</li>
        <li onClick={handleSign}>Поставить подпись клиента</li>
        {isAllowTranRes && (
          <li
            onClick={() => {
              _setModalVisibility('transit');
            }}
          >
            Создать резерв из транзита
          </li>
        )}
        {isRegional && (
          <li
            onClick={() => {
              _setModalVisibility('warehouse');
            }}
          >
            Создать дозаказ со склада МСК
          </li>
        )}
        {/*  <li onClick={props.deleteOrder(order)}>Удалить заказ</li>*/}
      </ul>
      <ReactModal
        isOpen={modalVisibility}
        onRequestClose={_setModalVisibility}
        className={classModal}
        overlayClassName="Overlay"
        ariaHideApp={false}
      >
        {componentForModal}
      </ReactModal>
    </div>
  );
};

export default OrderActions;
