import React from 'react';
import { Link } from 'react-router-dom';

export default [
  {
    Header: '',
    id: 'actions',
    accessor: d => (
      <Link to={`/orders/view/${d.orderNo}`} className="icon icon-view" title="Просмотр заказа" />
    ),
    sortable: false,
  },
  {
    Header: 'Код',
    id: 'orderNo',
    accessor: 'orderNo',
    sortable: false,
  },
  {
    Header: 'Дата',
    id: 'orderDate',
    accessor: 'orderDate',
    sortable: false,
  },
  {
    Header: 'Источник',
    id: 'type',
    accessor: 'type',
    sortable: false,
  },
  {
    Header: 'Инициатор',
    id: 'contact',
    accessor: 'contact',
    sortable: false,
  },
  {
    Header: 'Метод отгрузки',
    id: 'shipment',
    accessor: 'shipment',
    sortable: false,
  },
  {
    Header: 'Дата отгрузки',
    id: 'shipmentDate',
    accessor: 'shipmentDate',
    sortable: false,
  },
  {
    Header: () => <span>Сумма, &#8381;</span>,
    id: 'priceTotal',
    accessor: 'priceTotal',
    Cell: row => parseFloat(row.value).toFixed(2),
    style: { textAlign: 'right' },
    headerStyle: { textAlign: 'right' },
    width: 90,
    sortable: false,
  },
  {
    Header: 'Вес',
    id: 'weight',
    Cell: row => parseFloat(row.value).toFixed(1),
    style: { textAlign: 'right' },
    headerStyle: { textAlign: 'right' },
    width: 50,
    accessor: 'weight',
    sortable: false,
  },
  {
    Header: 'Объем',
    id: 'volume',
    Cell: row => (row && parseFloat(row.value).toFixed(3)) || null,
    style: { textAlign: 'right' },
    headerStyle: { textAlign: 'right' },
    width: 70,
    accessor: 'volume',
    sortable: false,
  },
  {
    Header: 'Описание учета',
    id: 'postingDescription',
    accessor: 'postingDescription',
    sortable: false,
  },
];
