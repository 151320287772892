import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Info = ({
  className,
  color = '#236192',
  name,
  width = '16',
  height = '16',
  viewBox = '0 0 16 16',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle cx="8" cy="8" r="7.5" stroke={color} />
      <circle cx="8" cy="4" r="1" fill={color} />
      <rect x="7" y="6" width="2" height="7" fill={color} />
    </svg>
  );
};

Info.defaultProps = {
  className: '',
  color: '#236192',
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
};

Info.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
