import alert from 'components/native/alert/function';
import { download } from 'common/util';
import { ACTIVE_GENERATION_STATUS, FILE_GENERATED_STATUS } from 'pages/Finances/constants';

export default {
  name: 'osv',
  state: {
    manager: {},
    sheets: {},
  },
  reducers: {
    load: (state, payload) => ({ ...state, ...payload }),
    setStatus: (state, { period, status }) => {
      if (!state.sheets[period]) {
        state.sheets[period] = {};
      }

      state.sheets[period].status = status;

      return state;
    },
    loadSheets: (state, { data }) => ({ ...state, sheets: data }),
  },
  effects: ({ api }) => ({
    async get(opNo) {
      try {
        const { blob, filename } = await api.get({
          url: `finances/osv/get/${opNo}`,
        });

        download(blob, filename);
      } catch (e) {
        alert(e.message);
      }
    },
    async send({ period, operationNo }) {
      this.setStatus({ period, status: ACTIVE_GENERATION_STATUS });

      const { message } = await api.get({
        url: `finances/osv/send/${operationNo}`,
      });

      this.setStatus({ period, status: FILE_GENERATED_STATUS });
      alert(message);
    },
    async generate(period) {
      this.setStatus({ period, status: ACTIVE_GENERATION_STATUS });

      await api.get({
        url: `finances/osv/generate/${period}`,
      });
    },
  }),
  selectors: (slice, createSelector, hasProps) => ({
    getSheet: hasProps((models, period) => slice(osv => osv.sheets[period] || {})),
  }),
};
