export const checkFormatTime = /^([01]\d|2[0-3]):[0-5][0-9]/;

export const intervalMin = 30;

export const addStepInterval = (time, isIncrease = true) => {
  if (!checkFormatTime.test(time)) return time;

  const [hours, minutes] = time.split(':');
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  if (isIncrease) {
    date.setMinutes(parseInt(minutes, 10) + parseInt(intervalMin, 10));
  } else {
    date.setMinutes(parseInt(minutes, 10) - parseInt(intervalMin, 10));
  }

  let newMinutes = date.getMinutes();
  let newHours = date.getHours();

  if (newMinutes < 10) newMinutes = `0${newMinutes}`;
  if (newHours < 10) newHours = `0${newHours}`;

  const newTimeTo = [newHours, newMinutes].join('');

  return newTimeTo;
};

const getMinutes = time => {
  if (time) {
    const [hours, minutes] = time.split(':');
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  }
  return 0;
};

export const checkAvailableTime = (timeFrom, timeTo, interval, timeMin, timeMax) => {
  const midnight = 0;
  const allowableTime = `Допустимое время отгрузки заказа с ${timeMin} до ${timeMax}`;
  const errorTime = {
    hasError: false,
    message: '',
  };

  const min = getMinutes(timeMin);
  const max = getMinutes(timeMax);
  const from = getMinutes(timeFrom);
  const to = getMinutes(timeTo);

  if (from < min) {
    errorTime.hasError = true;
    errorTime.message = allowableTime;
    return errorTime;
  }

  if (to > max) {
    errorTime.hasError = true;
    errorTime.message = allowableTime;
    return errorTime;
  }

  if (to === midnight) {
    errorTime.hasError = true;
    errorTime.message = allowableTime;
    return errorTime;
  }

  if (Math.abs(to - from) < interval * 60) {
    errorTime.hasError = true;
    errorTime.message = `Промежуток времени доставки не может быть менее ${interval} ч.`;
    return errorTime;
  }

  if (from > to) {
    errorTime.hasError = true;
    errorTime.message = allowableTime;
    return errorTime;
  }

  return errorTime;
};

const MASK = '##:##';

export const formattingMask = value => {
  const onlyNumbers = value.replace(':', '');
  let i = 0;
  let lastReplacedIndex = -1;
  const fieldMask = MASK.replace(/#/g, (_, j) => {
    if (i >= onlyNumbers.length) {
      return '#';
    }
    lastReplacedIndex = j;
    // eslint-disable-next-line no-plusplus
    return onlyNumbers[i++];
  });
  return fieldMask.substring(0, lastReplacedIndex + 1);
};

export const setCorrectTime = value => {
  if (/\D/.test(value.replace(':', ''))) {
    return '';
  }

  if (value.length <= 2) {
    if (parseInt(value[0], 10) >= 3) {
      return `0${value}:`;
    }

    if (parseInt(value[0], 10) === 0 && parseInt(value[1], 10) === 0) {
      return `${value}:`;
    }

    if (parseInt(value[0], 10) === 0 && parseInt(value[1], 10) <= 9) {
      return `${value}:`;
    }

    if (parseInt(value[0], 10) === 1 && parseInt(value[1], 10) <= 9) {
      return `${value}:`;
    }

    if (parseInt(value[0], 10) === 2 && parseInt(value[1], 10) <= 3) {
      return `${value}:`;
    }

    if (parseInt(value[0], 10) === 2 && parseInt(value[1], 10) >= 4) {
      return `${value[0]}`;
    }
  } else {
    if (parseInt(value[3], 10) !== 3) {
      return `${value[0]}${value[1]}:00`;
    }

    if (parseInt(value[3], 10) === 3) {
      return `${value}0`;
    }
  }

  return value;
};
