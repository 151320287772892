import React from 'react';
import { get, isEqual, uniqBy, sortBy } from 'lodash';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { formValueSelector } from 'redux-form';

import { SHIPMENT_SCHEDULE_FORM, SHIPPING_AGENT_FORM } from 'pages/Logistic/constants';
import { CLAIMS_FORM } from 'pages/Logistic/components/claims-new/constants';
import { getEffectLoading } from 'common/selectors';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const selector = formValueSelector(SHIPPING_AGENT_FORM);
export const getAgentFormValue = fieldName => state => selector(state, fieldName);

export const rentSelector = state => selector(state, 'rent');
export const branchSelector = state => selector(state, 'branch');

const getLogistic = state => get(state, 'logistic', {});

export const optionsRentSelector = createDeepEqualSelector(
  state => state.agents,
  branchSelector,
  (agents, branch) => {
    const rents = agents.reduce((array, { branchCity, velocity }) => {
      if (branchCity !== branch) return array;
      return [...array, { value: velocity, label: velocity }];
    }, []);

    const uniqRent = uniqBy(rents, 'value');

    return sortBy(uniqRent, 'value');
  },
);

export const optionsShippingAgentCode = createDeepEqualSelector(
  state => state.agents,
  branchSelector,
  rentSelector,
  (agents, branch, rent) =>
    agents.reduce((array, { branchCity, velocity, code, name, pickPoint }) => {
      if (branchCity !== branch || velocity === rent) return array;
      return [
        ...array,
        {
          value: code,
          label: `${name} ${pickPoint} ${code}`,
        },
      ];
    }, []),
);

const scheduleFormSelector = formValueSelector(SHIPMENT_SCHEDULE_FORM);
export const getScheduleFormValue = fieldName => state => scheduleFormSelector(state, fieldName);

const claimsFormSelector = formValueSelector(CLAIMS_FORM);
export const getClaimsFormValue = fieldName => state => claimsFormSelector(state, fieldName);

export const isFetchingItems = createSelector(
  getEffectLoading('claims', 'getItems'),
  getEffectLoading('claims', 'searchItems'),
  (...args) => args.some(arg => arg),
);

const getList = state => get(state, ['list', 'data'], []);
const getVolume = createSelector(getLogistic, logistic =>
  get(logistic, ['shipmentItemHeader', 'volume'], 0),
);

export const getVolumeShipment = createSelector(getVolume, getList, (volume, list) => {
  const volumeFromList = list.reduce((acc, cur) => acc + parseFloat(cur.volume), 0);

  return volume ? parseFloat(volume).toFixed(3) : volumeFromList.toFixed(3);
});
