import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Error = ({
  className,
  color = '#f15b5b',
  name,
  width = '30',
  height = '30',
  viewBox = '-1 -1 32 32',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M7.35355 6.64645C7.15829 6.45118 6.84171 6.45118 6.64645 6.64645C6.45118 6.84171 6.45118 7.15829 6.64645 7.35355L7.35355 6.64645ZM22.6464 23.3536C22.8417 23.5488 23.1583 23.5488 23.3536 23.3536C23.5488 23.1583 23.5488 22.8417 23.3536 22.6464L22.6464 23.3536ZM6.64645 7.35355L22.6464 23.3536L23.3536 22.6464L7.35355 6.64645L6.64645 7.35355Z"
      fill={color}
    />
    <path
      d="M23.3536 7.35355C23.5488 7.15829 23.5488 6.84171 23.3536 6.64645C23.1583 6.45118 22.8417 6.45118 22.6464 6.64645L23.3536 7.35355ZM6.64645 22.6464C6.45118 22.8417 6.45118 23.1583 6.64645 23.3536C6.84171 23.5488 7.15829 23.5488 7.35355 23.3536L6.64645 22.6464ZM22.6464 6.64645L6.64645 22.6464L7.35355 23.3536L23.3536 7.35355L22.6464 6.64645Z"
      fill={color}
    />
    <path
      d="M15 29C7.26801 29 1 22.732 1 15H-1C-1 23.8366 6.16344 31 15 31V29ZM29 15C29 22.732 22.732 29 15 29V31C23.8366 31 31 23.8366 31 15H29ZM15 1C22.732 1 29 7.26801 29 15H31C31 6.16344 23.8366 -1 15 -1V1ZM15 -1C6.16344 -1 -1 6.16344 -1 15H1C1 7.26801 7.26801 1 15 1V-1Z"
      fill={color}
    />
  </svg>
);

Error.defaultProps = {
  className: '',
  color: '#f15b5b',
  width: '30',
  height: '30',
  viewBox: '-1 -1 32 32',
};

Error.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
