/* eslint-disable no-console */
import React, { Component } from 'react';
import { getDisplayName, objToQS } from 'common/util';
import localForage from 'localforage';
import isEmpty from 'lodash/isEmpty';
import { activeControllers } from 'common/collections';
import get from 'lodash/get'

/**
 * HOC to delay component rendering while getting data from server
 *
 * @param {React.ComponentType} AuthComponent
 * @returns {React.ComponentType}
 */
const withApi = AuthComponent =>
  class Authorized extends Component {
    static displayName = `WithApi(${getDisplayName(AuthComponent)})`;

    static async fetch(match, location, { dispatch, cache, apiVersion }) {
      activeControllers.abortAll();

      let { search = '' } = location;
      let state = get(location, 'state', {}) || {}


      const {api = {}, ...rest} = state
      const { method = 'get', success = false } = api

      if(method === false){
        return;
      }

      const val = await localForage.getItem('location.state');

      if (!val?.hidden) {
        state = { ...rest, ...((val && val.getParams) || val) };
      }

      if (!isEmpty(state) && !state.hidden && method === 'get') {
        search += search.length ? `&${objToQS(state)}` : `?${objToQS(state)}`;
      }

      const locationPathName = location.pathname || '';

      return await dispatch.api[method.toLowerCase()]({
        url: locationPathName.substring(1),
        query: search,
        log: true,
        data: method !== 'get' ? state : null,
        cache,
        apiVersion,
        successCallback: success
      });
    }

    shouldComponentUpdate(nextProps) {
      const current = `${this.props.location.pathname}${this.props.location.search}`;
      const next = `${nextProps.location.pathname}${nextProps.location.search}`;

      return current !== next;
    }

    componentWillUnmount() {
      if (!AuthComponent.keepLocationState) {
        localForage.removeItem('location.state').catch(err => console.log(err));
      }
    }

    render() {
      return <AuthComponent {...this.props} />;
    }
  };

export default withApi;
