import { getUser } from 'pages/Orders/selectors';

const FinanceFiltersInitState = {
  balance: {},
  payments: {
    dateFrom: new Date(),
    dateTo: new Date(),
  },
  purchase: {
    dateFrom: new Date(),
    dateTo: new Date(),
  },
  turnover: {
    dateFrom: new Date(),
    dateTo: new Date(),
  },
  userFilters: {},
};

const financeFilters = {
  name: 'financeFilters',
  state: FinanceFiltersInitState,
  reducers: {
    persist(state, { userId, type, ...rest }) {
      const current = { ...state[type], ...rest };

      return {
        ...state,
        [type]: current,
        userFilters: {
          ...state.userFilters,
          [userId]: {
            [type]: current,
          },
        },
      };
    },
  },
  effects: dispatch => ({
    async save({ type, ...rest }, state) {
      const user = getUser(state);
      const filters = { ...state.financeFilters };

      this.persist({ userId: user.id, type, ...rest });

      await dispatch.api.post({
        url: 'view/settings/save',
        data: {
          finances: {
            [type]: {
              ...filters.userFilters[type],
              ...rest,
            },
          },
        },
      });
    },
  }),
  selectors(slice, createSelector, hasProps) {
    return {
      getFilters: hasProps(({ user }, type) =>
        createSelector(
          user.getInfo,
          slice,
          ({ id }, filters) => filters.userFilters[id]?.[type] || filters[type],
        ),
      ),
    };
  },
};

export default financeFilters;
