import moment from 'moment';

export default (date, dates) => {
  if (dates.includes(date)) {
    return date;
  }

  let found = 0;
  const date1 = moment(date, 'DD.MM.YYYY');

  for (const curDate of dates) {
    const date2 = moment(curDate, 'DD.MM.YYYY');

    found = date2.diff(date1, 'days');

    if (found > 0) {
      return curDate;
    }
  }

  return dates[dates.length - 1];
};
