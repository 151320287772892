import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Download = ({
  className,
  color = '#236192',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M8.3783 10.9752L11.583 7.77044C11.7959 7.55758 11.7959 7.22646 11.583 7.0136C11.3702 6.80074 11.0391 6.80074 10.8262 7.0136L8.53203 9.30776L8.53203 1.52654C8.53203 1.2309 8.29552 0.994385 7.99988 0.994385C7.70424 0.994385 7.46773 1.2309 7.46773 1.52654L7.46773 9.30776L5.17357 7.0136C4.96071 6.80074 4.62959 6.80074 4.41673 7.0136C4.3103 7.12003 4.263 7.25011 4.263 7.39202C4.263 7.53393 4.3103 7.66401 4.41673 7.77044L7.62146 10.9752C7.83432 11.188 8.17726 11.188 8.3783 10.9752Z"
        fill={color}
      />
      <path
        d="M0.5 6.50006C0.5 6.50006 0.5 10.5001 0.5 12.0001C0.5 13.5001 1 14.5001 3 14.5001C5.5 14.5001 12 14.5001 13 14.5001C15 14.5001 15.5 13.5001 15.5 12.0001C15.5 11.2001 15.5 8.33339 15.5 6.50006"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
};

Download.defaultProps = {
  className: '',
  color: '#236192',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Download.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
