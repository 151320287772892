import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const DashboardItem = props => {
  const { title, src, position, description } = props;

  const { default: url } = require(`img/${src}`);

  const classDashboard = position
    ? `dashboard__item dashboard__item--${position}`
    : 'dashboard__item';
  const classImage = position
    ? `dashboard__image dashboard__image--${position}`
    : 'dashboard__image';

  return (
    <div className={classDashboard}>
      {title && <h2 className="dashboard__header">{title}</h2>}
      <img src={url} className={classImage} alt={title} />
      {description && <div className="dashboard__description">{ReactHtmlParser(description)}</div>}
    </div>
  );
};

export default DashboardItem;
