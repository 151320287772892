import { getOrder } from 'pages/Orders/selectors';
import { SERVER_BAD_ID, SERVER_ID } from 'pages/Orders/constances/groups';
import pick from 'lodash/pick';
import { download } from 'common/util';

export default ({ api }) =>
  async ({ id, instance }, state) => {
    const table = instance.current.refs['react-table'];
    const { resolvedData } = table.getResolvedState();

    const order = getOrder(state);
    const isRegional = parseInt(order.isRegional);

    const pickedData = isRegional
      ? [
          'id',
          'vendorPart',
          'name',
          'desiredQty',
          'unitPriceLCY',
          'desiredTotal',
          'priceClient',
          'desiredClientTotal',
          'availableMSK1Raw',
          'inventoryMSK1Raw',
          'availableOfficeRG',
          'warehouseOfficeRGRaw',
          'availableExpected',
        ]
      : [
          'id',
          'vendorPart',
          'name',
          'desiredQty',
          'unitPriceLCY',
          'desiredTotal',
          'priceClient',
          'desiredClientTotal',
          'availableMSK1Raw',
          'inventoryMSK1',
          'availableExpected',
        ];

    const data = resolvedData.reduce((items, item) => {
      if (item['_original'].code1 !== SERVER_ID && item['_original'].code2 !== SERVER_BAD_ID) {
        return items;
      }

      const picked = pick(item['_original'], pickedData);

      picked.unitPriceLCY = picked.unitPriceLCY.replace(' ', '');
      picked.desiredTotal = picked.desiredTotal.replace(' ', '');
      picked.priceClient = picked.priceClient.replace(' ', '');
      picked.desiredClientTotal = picked.desiredClientTotal.replace(' ', '');

      return [...items, picked];
    }, []);

    const fileName = `Выгрузка спецификаций серверного оборудования по заказу ${id}`;

    const { blob, filename } = await api.post({
      url: `file/generate/spec/${order.orderNo || 'NEW'}`,
      data: {
        name: fileName,
        data,
      },
    });

    download(blob, filename);
  };
