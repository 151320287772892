import get from 'lodash/get';
import alert from 'components/native/alert/function';
import { downloadFile } from 'utils/new/download-file';
import { formatDocStatus, updateDownload } from '../utils';

const initState = {
  data: [],
  fetching: false,
  docStatus: '',
};

export default {
  name: 'newDownloadList',
  state: initState,
  reducers: {
    fetchDownloadListRequest: state => {
      state.fetching = true;
      return state;
    },
    fetchDownloadListSuccess: (state, payload) => {
      state.data = get(payload, ['data', 'data'], []);
      state.fetching = false;
      return state;
    },
    setDownloadList: (state, payload) => {
      state.data = get(payload, ['data', 'data'], []);
      return state;
    },
    updateDownloadList: (state, payload) => {
      state.data = updateDownload(state.data, payload);
      return state;
    },
    pullDownloadListMessage: (state, { id, done, status }) => {
      state.data = state.data.map(item =>
        item.orderNo === id && done ? { ...item, status } : item,
      );
      state.docStatus = status;
      return state;
    },
    clearStatus: state => {
      state.docStatus = '';
      return state;
    },
    resetState: () => initState,
  },
  effects: ({ api }) => ({
    async fetchDownloadList() {
      try {
        this.fetchDownloadListRequest();

        const result = await api.get({
          url: '/new-download-list',
        });

        this.fetchDownloadListSuccess(result);

        return result;
      } catch (err) {
        return null;
      }
    },
    async fetchDownloadFile({id, name}) {
      let config = {
        url: `new-download-list/file/${id}`,
      }

      if(!id && name){
        config = {
          url: `new-download-list/file/get`,
          query: `?path=${encodeURI(name)}`
        }
      }

      try {
        downloadFile(await api.get(config));
      } catch (err) {
        console.error(err)
        return null;
      }
    },
    receive(message, { newOrderActions: { jobType } }) {
      if (jobType === 'doc') {
        const doc = get(message, ['data', 'fix', 'id'], null);
        const done = get(message, ['data', 'fix', 'done'], false);
        const error = get(message, ['data', 'fix', 'error'], null);

        const payload = {
          id: doc.id,
          done,
          status: '',
        };

        if (done && !error) {
          payload.status = 'Ok';
        }

        if (!done && !error) {
          payload.status = 'Active';
        }

        if (done && error) {
          payload.status = 'Error';
        }

        this.pullDownloadListMessage(payload);
      }
    },
    expire() {
      alert('Ваш запрос обрабатывается. Обновите страницу через некоторое время.');
    },
  }),
  selectors: (slice, createSelector) => ({
    getDownloadList() {
      return slice(state => get(state, 'data', []));
    },
    getFetching() {
      return slice(state => get(state, 'fetching', false));
    },
    getStatus() {
      return createSelector(
        slice,
        rootState => get(rootState, ['newOrderActions', 'jobType'], ''),
        (state, jobType) => {
          if (jobType !== 'doc') {
            return '';
          }

          const docStatus = get(state, 'docStatus', '');

          return formatDocStatus(docStatus);
        },
      );
    },
  }),
};
