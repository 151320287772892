import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const View = ({
  className = '',
  color = '#236192',
  name = 'view',
  width = '16',
  height = '15',
  viewBox = '0 0 16 15',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="16" height="3" rx="1.5" fill={color} />
    <rect y="6" width="16" height="3" rx="1.5" fill={color} />
    <rect y="12" width="16" height="3" rx="1.5" fill={color} />
  </svg>
);

View.defaultProps = {
  className: '',
  color: '#236192',
  width: '16',
  height: '16',
  viewBox: '0 0 16 15',
};

View.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
