import React from 'react';
import { FormSection } from 'redux-form';
import PropTypes from 'prop-types';
import DocumentField from '../FormFields/DocumentField';

const DocumentsSection = ({ list }) => (
  <FormSection name="uploadDocuments">
    {list.map(fieldProps => {
      const required = Number(fieldProps.required);

      return <DocumentField {...fieldProps} required={required} key={fieldProps.name} />;
    })}
  </FormSection>
);

DocumentsSection.propTypes = {
  list: PropTypes.array.isRequired,
};

export default DocumentsSection;
