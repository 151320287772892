// @ts-nocheck
import React from 'react';
import { NavLink } from 'react-router-dom';
import { undef } from 'common/util';
import get from 'lodash/get';
import OldRouteLink from 'components/old-route-link';
import { formattingCamelCase } from 'utils/formatting-camel-case';
import Mixpanel from 'services/mixpanel';

const handleClick = isDesignV2 => e => {
  if (isDesignV2) {
    const parentMenu = e.target.dataset.parent;
    const childMenu = formattingCamelCase(e.target.lastChild.textContent);

    Mixpanel?.track(`Меню.${parentMenu}.${childMenu}`);
  }
};

const isActiveMain = route => (match, location) => {
  if (!match || !route.routes) return false;

  return route.routes.some(route =>
    new RegExp(route.path.replace(/:.*$/, '')).test(location.pathname),
  );
};

const hasAccessedChildren = (menu, path, key) =>
  (!key && Object.keys(menu).filter(key => key.includes(path)).length) || key;

const createNewLink = (isNew, name) =>
  isNew ? (
    <div>
      {name} <span className="link--new">(new)</span>
    </div>
  ) : (
    name
  );

const isShow = route => undef(route.show) || route.show !== false;

const renderLinks = (routes, perms = [], menu, key = 0, parentName = '', isDesignV2 = false) => {
  return (
    <ul>
      {routes
        .filter(route => isShow(route) && route.name && ((!key && !route.parent) || !!key))
        .map((route, index) => {
          const menuItem = menu[route.path];
          const permission = perms.find(perm => route.perm in perm);
          const access = get(permission, [route.perm], 0);

          const isMain =
            ((!key || index !== key) &&
              route?.routes?.some(route => isShow(route) && menu[route.path])) ||
            false;

          if (
            (!isMain && undef(menuItem)) ||
            !(
              route.path &&
              hasAccessedChildren(menu, route.path, key) &&
              (undef(route.perm) || Number(access) === 1)
            )
          ) {
            return null;
          }

          const oldUrl = route.old && menuItem?.oldUrl;

          const to =
            (route.state && {
              pathname: route.path,
              state: route.state,
            }) ||
            route.path;

          const link = createNewLink(route.new, route.name);

          return (
            <li key={index}>
              {isMain ? (
                <NavLink to={to} isActive={isActiveMain(route)} onClick={e => e.preventDefault()}>
                  {link}
                </NavLink>
              ) : oldUrl ? (
                <OldRouteLink to={oldUrl} name={route.name} onClick={handleClick(isDesignV2)} data-parent={parentName}>
                  {link}
                </OldRouteLink>
              ) : (
                <NavLink to={to} onClick={handleClick(isDesignV2)} data-parent={parentName}>
                  {link}
                </NavLink>
              )}
              {route.routes && renderLinks(route.routes, perms, menu, key + 1, link, isDesignV2)}
            </li>
          );
        })}
    </ul>
  );
};

export default renderLinks;
