import { useState } from 'react';
import debounce from 'lodash/debounce';

/**
 * Hook to delay hover callback
 * in meens to show some bounded hidden element
 *
 * @param {DelayedHoveredStateProps} props
 * @returns {DelayedHoveredStateReturns}
 */
function useDelayedHoveredState(props) {
  const { delay, trackPosition, defaultPosition } = props;

  const [isHovered, setIsHovered] = useState(false);
  const [top, setTop] = useState((defaultPosition && defaultPosition.top) || -9999);
  const [left, setLeft] = useState((defaultPosition && defaultPosition.left) || -9999);

  const setIsHoveredDebounced = debounce(setIsHovered, delay);

  const handleMouseEnter = e => {
    if (trackPosition) {
      const coords = e.target.getBoundingClientRect();
      const pageTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      setTop(coords.bottom + pageTop);
      setLeft(coords.left + coords.width / 2);
    }

    setIsHoveredDebounced(true);
  };
  const handleMouseLeave = () => {
    setIsHoveredDebounced(false);
  };
  return {
    isHovered,
    setIsHovered,
    top,
    left,
    handleMouseEnter,
    handleMouseLeave,
  };
}

export default useDelayedHoveredState;
