import React from 'react';
import userHoverStatus from '../hooks/useHoverStatus';
import isFunction from 'lodash/isFunction';

function WithHover(props) {
  const hoverStatus = userHoverStatus();
  const { onMouseEnter, onMouseLeave, component: Component, children, ...restProps } = props;

  const handleMouseEnter = () => {
    isFunction(onMouseEnter) && onMouseEnter();
    hoverStatus.handleMouseEnter();
  };

  const handleMouseLeave = () => {
    isFunction(onMouseLeave) && onMouseLeave();
    hoverStatus.handleMouseLeave();
  };

  return (
    <Component {...restProps} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children(hoverStatus.isHovered)}
    </Component>
  );
}

export default WithHover;
