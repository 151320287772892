// @ts-nocheck
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

const getStatusConstructor = status => {
  switch (String(status).toLowerCase()) {
    case 'заявка':
    case 'открыта':
      return <span className="status--red">Заявка отправлена</span>;
    case 'взят в работу':
    case 'в работе':
      return <span className="status--blue">Взята в работу</span>;
    case 'восстановлена':
      return <span className="status--blue">Восстановлена</span>;
    case 'закрыта':
    case 'выполнены':
      return <span className="status--green">Выполнена</span>;
    case 'закрыт без выполнения':
      return <span>Закрыта без выполнения</span>;
    default:
      return <span>{String(status)}</span>;
  }
};

export default getStatusConstructor;
