import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const EyeCrossed = ({
  className,
  color = '#808080',
  name,
  width = '26',
  height = '19',
  viewBox = '0 0 26 19',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M13 2.5C6.1 2.5 0.5 8.8 0.5 10.1C0.5 11.4 6.1 16.5 13 16.5C19.9 16.5 25.5 11.3 25.5 10.1C25.5 8.9 19.9 2.5 13 2.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path d="M13.5 9H12.5V10H13.5V9Z" fill={color} />
        <path d="M20.5 3V2L19.5 2V3H20.5Z" fill={color} />
        <path d="M19.5 4V3H18.5V4H19.5Z" fill={color} />
        <path d="M22.5 1V0L21.5 0V1L22.5 1Z" fill={color} />
        <path d="M21.5 2V1L20.5 1V2L21.5 2Z" fill={color} />
        <path d="M5.5 18V17H4.5V18H5.5Z" fill={color} />
        <path d="M4.5 19V18H3.5V19H4.5Z" fill={color} />
        <path d="M18.5 5V4H17.5V5H18.5Z" fill={color} />
        <path d="M17.5 6V5H16.5V6H17.5Z" fill={color} />
        <path d="M16.5 7V6H15.5V7H16.5Z" fill={color} />
        <path d="M15.5 8V7H14.5V8H15.5Z" fill={color} />
        <path d="M14.5 9V8H13.5V9H14.5Z" fill={color} />
        <path d="M13.5 10V9H12.5V10H13.5Z" fill={color} />
        <path d="M12.5 11V10H11.5V11H12.5Z" fill={color} />
        <path d="M11.5 12V11H10.5V12H11.5Z" fill={color} />
        <path d="M10.5 13V12H9.5V13H10.5Z" fill={color} />
        <path d="M9.5 14V13H8.5V14H9.5Z" fill={color} />
        <path d="M8.5 15V14H7.5V15H8.5Z" fill={color} />
        <path d="M7.5 16V15H6.5V16H7.5Z" fill={color} />
        <path d="M6.5 17V16H5.5V17H6.5Z" fill={color} />
        <path
          d="M13 13.5C15.2091 13.5 17 11.7091 17 9.5C17 7.29086 15.2091 5.5 13 5.5C10.7909 5.5 9 7.29086 9 9.5C9 11.7091 10.7909 13.5 13 13.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

EyeCrossed.defaultProps = {
  className: '',
  color: '#808080',
  width: '26',
  height: '19',
  viewBox: '0 0 26 19',
};

EyeCrossed.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
