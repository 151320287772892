import confirm from 'components/native/confirm/function';

const SPECIFIC_OF_ITEM = 1;
const IS_NEW_DESIGN = 1;

const getLink = (isNewDesign, id) =>
  `/?action=${
    isNewDesign ? 'Y4ECDD97' : 'Y16E945A'
  }&action1=Y7D4B6FD&brand=&group=ML45&op=item&item_id=${id}`;

export const redirectSpecificOfItem = (specificOfItem, design, id, resetValue) => {
  const isNewDesign = Number(design) === IS_NEW_DESIGN;

  if (Number(specificOfItem) === SPECIFIC_OF_ITEM) {
    resetValue(0);
    const link = getLink(isNewDesign, id);
    confirm('Электронные ключи доступны для заказа в разделе Заказ ПО', {
      confirmLabel: 'Перейти',
    }).then(() => window.open(link, '_blank'));
  }
};
