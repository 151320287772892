import get from 'lodash/get';

const getIntegrateData = ({ data }) => {
  const { id, success, message, order } = data;

  const joinedOrders = get(data, 'joinedOrders', []).filter(order => order !== id);

  return {
    success,
    id,
    message,
    joinedOrders,
    order,
  };
};

export default getIntegrateData;
