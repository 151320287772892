import { COMMON_GUEST_THEME } from './common-guest';

export const CACTUS_THEME = {
  ...COMMON_GUEST_THEME,
  'shadow-primary': 'rgba(0, 0, 0, .2)',
  'color-light-blue': '#edf1fa',
  'color-cactus-main': '#004ca6',
  'color-brand-red': '#004ca6',
  'color-brand-red-hover': '#0d4572',
  'color-confirm-red': '#004ca6',
};
