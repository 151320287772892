import { CARD_PAGE_PATH } from 'common/routes/constants';
import { getIsRegional, getUser, isLastApiCallItems } from 'pages/Orders/selectors';
import alert from 'components/native/alert/function';
import notifierDefault from 'services/notifier';
import isEmpty from 'lodash/isEmpty';
import { preparedData } from 'pages/Orders/components/order-edit/helpers/prepareData';

export default ({ items, list, order, queue }) =>
  async ({ data, id, pathname, notifier = notifierDefault, isPilot }, state) => {
    const isCard = pathname.includes(CARD_PAGE_PATH);

    const {
      file,
      order: newOrder,
      success,
      withOrder,
      command,
      type,
      message,
      rows,
      uid,
      targetOrder,
    } = data;

    const resolve = () =>
      notifier.resolve(
        {
          success,
          message:
            !success || !newOrder?.orderNo
              ? message
              : withOrder
              ? `Заказ ${newOrder?.reserveOrOrder === 'РЕЗЕРВ ИЗ ТРАНЗИТА' ? 'из транзита' : ''} ${
                  newOrder?.orderNo
                } создан`
              : isCard
              ? `Товар ${id} добавлен в заказ ${newOrder?.orderNo}`
              : 'Изменения сохранены',
        },
        withOrder ? 'NEW' : newOrder?.orderNo || targetOrder || data.id,
      );

    if (command) {
      return order.receive({ data, notifier, isPilot });
    }

    if (success === false) {
      return resolve();
    }

    const orderId = id || 'NEW';
    const isInList = isLastApiCallItems(state);

    if (rows) {
      if (isCard) {
        return;
      }

      const payload = {
        id: orderId,
        rows,
        isInList,
      };

      if (data?.data?.migrate) {
        list.syncQueueMigrateRows(payload);
      } else {
        queue.setRows(payload);
        list.syncQueueEditRows(payload);
      }

      return;
    }

    if (!isEmpty(newOrder) && success) {
      order.setHeader(newOrder);
    }

    if (isCard) {
      return resolve();
    }

    if (file) {
      if (success) {
        items.setFiles({ file, type });
      } else {
        alert(`Ошибка формирования файла <b>${file}</b>!`);
        items.endExport(type);
      }

      return;
    }

    if (data.delete) {
      if (!isInList && success) {
        list.deleteRow({ searchKey: 'itemNo', needle: data.id });
        preparedData.clear();
      }

      notifier.resolve(
        {
          success,
          message: message || 'Изменения сохранены',
        },
        data.id,
      );

      return;
    }

    resolve();
    preparedData.clear();

    if (uid !== getUser(state).id) {
      return;
    }

    const isRegionalSettings = getIsRegional(state);

    if (orderId === 'NEW' && withOrder && isRegionalSettings !== Number(order.isRegional)) {
      await items.refresh({ orderNo: newOrder.orderNo, pathname });
    }
  };
