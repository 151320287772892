import React from 'react';
import PropTypes from 'prop-types';

const RenderTimeInput = ({ value, onHandleChange }) => {
  const handleSelectText = event => event.target.select();

  const handlePaste = event => {
    event.preventDefault();
    return false;
  };

  return (
    <div className="time-input">
      <input
        className="form-control"
        type="text"
        value={value}
        onChange={onHandleChange}
        onFocus={handleSelectText}
        onPaste={handlePaste}
        autoComplete="off"
      />
    </div>
  );
};

RenderTimeInput.propTypes = {
  value: PropTypes.string,
  onHandleChange: PropTypes.func.isRequired,
};

RenderTimeInput.defaultProps = {
  value: '',
};

export default RenderTimeInput;
