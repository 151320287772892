import { getUnixTimeStamp } from './get-unix-time';

const sortByDates = (prevDateDMY, nextDateDMY) => {
  const prevDate = getUnixTimeStamp(prevDateDMY);
  const nextDate = getUnixTimeStamp(nextDateDMY);

  if (prevDate < nextDate) return -1;
  if (prevDate > nextDate) return 1;
  return 0;
};

export default sortByDates;
