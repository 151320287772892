import React, { useMemo, useCallback } from 'react';
import TableList from 'components/table/list';
import Content from 'components/layout/content';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AppSocket from 'components/AppSocket';
import { makeColumns } from './helper/columns';
import { ACTIONS_TABLE_NAME } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import withApi from 'hoc/withApi';

const defaultSorted = [
  {
    id: 'period',
    desc: true,
  },
];

const ActionsList = () => {
  const pages = useSelector(({ actions }) => actions.pages);
  const {
    actions: { receive, accept },
    list,
  } = useDispatch();

  const { pathname } = useLocation();

  const isArch = /archive/.test(pathname);

  const linkName = isArch ? 'Активные акции' : 'Архивные акции';
  const link = isArch ? '/actions' : '/actions/archive';

  const tdProps = useCallback(
    () => ({
      style: {
        alignSelf: 'center',
      },
    }),
    [],
  );

  const columns = useMemo(() => {
    const backName = isArch ? 'Список архивных акции' : 'Список акций';
    const backLink = isArch ? '/actions/archive' : '/actions';

    return makeColumns({
      isArch,
      backName,
      backLink,
      accept,
    });
  }, [isArch, accept]);

  const onSortedChange = useCallback(() => {
    list.unblock();
  }, []);

  return (
    <Content title={`${isArch ? 'Архивные' : 'Активные'} акции`}>
      <AppSocket channel="queue-stat" onReceive={receive}>
        <div className="act-actions">
          <Link to={link} className="section-nav">
            <i className="icon icon-event_list" /> {linkName}
          </Link>
        </div>
        <div className="table-container actions-list">
          <TableList
            name={ACTIONS_TABLE_NAME}
            columns={columns}
            manual
            pages={pages}
            route={pathname}
            getTdProps={tdProps}
            defaultSorted={defaultSorted}
            showPagination={pages > 1}
            onSortedChange={onSortedChange}
          />
        </div>
      </AppSocket>
    </Content>
  );
};

export default withApi(ActionsList);
