import React from 'react';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import proxyColumns from './common/columns/proxyColumns';
import withApi from 'hoc/withApi';

const defaultSorted = [
  {
    id: 'name',
    desc: false,
  },
];

const Proxy = () => (
  <Content title="Доверенности">
    <div className="proxies-list">
      <TableList columns={proxyColumns} defaultSorted={defaultSorted} />
    </div>
  </Content>
);

export default withApi(Proxy);
