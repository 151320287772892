import pick from 'lodash/pick';
import { def, isShallowEqual } from 'common/util';
import ObjCollector from 'services/objCollector';

export const preparedData = new ObjCollector({
  qtys: {},
  prices: {},
  priceClients: {},
  deliveryDates: {},
  act: {},
});

export let indexRows = {};

export const prepareData = (row, state, preOrder) => {
  const {
    original: { id, index, lineNo },
  } = row;

  const rowPicked = pick(row.original, ['desiredQty', 'desiredPriceRUB', 'desiredPriceClient']);

  rowPicked['desiredPrice'] = rowPicked['desiredPriceRUB'];
  delete rowPicked['desiredPriceRUB'];

  const stateId = index || id;
  const currentState = { ...state[stateId] };

  if (isShallowEqual(currentState, rowPicked)) {
    if (preparedData.has('qtys', id)) {
      preparedData.delete('qtys', id);
      preparedData.delete('prices', id);
      preparedData.delete('priceClients', id);
      preparedData.delete('act', id);
    }

    return;
  }

  const qty = currentState['desiredQty'];

  if (!parseInt(qty) && !lineNo) {
    if (preparedData.has('qtys', id)) {
      preparedData.delete('qtys', id);
    }

    return;
  }

  if (lineNo && !parseInt(qty)) {
    preparedData.set('act', id, 'delete');
  }

  preparedData.set('qtys', id, currentState['desiredQty']);
  preparedData.set('prices', id, currentState['desiredPrice'] || rowPicked['desiredPrice']);
  preparedData.set(
    'priceClients',
    id,
    currentState['desiredPriceClient'] || rowPicked['desiredPriceClient'],
  );

  if (def(row.original.deliveryDate)) {
    preparedData.set('deliveryDates', id, row.original.deliveryDate);
    preparedData.set('prices', id, row.original.price);

    indexRows = {
      indexes: {
        ...indexRows.indexes,
        [id]: stateId,
      },
    };
  }

  preparedData.set('isPreorder', preOrder);
};
