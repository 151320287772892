import React from 'react';
import TableList from 'components/table/list';

const defaultSorted = [
  {
    id: 'date',
    desc: true,
  },
];

export default ({ width = '100%', columns }) => (
  <div className="service-table-container" style={{ width }}>
    <TableList
      columns={columns}
      defaultPageSize={5000}
      pageSize={5000}
      loadingText={null}
      defaultSorted={defaultSorted}
    />
  </div>
);
