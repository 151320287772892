import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const RubleOutlined = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '-1 -1 20 20',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M5 8.77966H6.17113V3.23305C6.589 3.13983 7.03436 3.07768 7.50722 3.04661C7.99107 3.01554 8.46942 3 8.94227 3C9.44811 3 9.94296 3.04661 10.4268 3.13983C10.9216 3.23305 11.356 3.40395 11.7299 3.65254C12.1148 3.90113 12.4227 4.23776 12.6536 4.66243C12.8845 5.0871 13 5.63089 13 6.29379C13 6.94633 12.879 7.49011 12.6371 7.92514C12.4062 8.36017 12.0928 8.71233 11.6969 8.98164C11.312 9.25094 10.8667 9.44256 10.3608 9.5565C9.86598 9.67043 9.36014 9.73258 8.8433 9.74294L7.54021 9.75848V10.8616H10.2784V11.8249H7.54021V14H6.17113V11.8249H5V10.8616H6.17113V9.74294H5V8.77966ZM8.97526 4.08757C8.68935 4.08757 8.41443 4.09793 8.15052 4.11864C7.89759 4.129 7.69416 4.14972 7.54021 4.18079V8.68644H8.79381C9.12371 8.68644 9.45361 8.65537 9.7835 8.59322C10.1134 8.53107 10.4103 8.41196 10.6742 8.23588C10.9381 8.05979 11.1526 7.82156 11.3175 7.52119C11.4825 7.21045 11.5595 6.8065 11.5485 6.30932C11.5595 5.88465 11.4935 5.53249 11.3505 5.25282C11.2186 4.96281 11.0316 4.73493 10.7897 4.56921C10.5588 4.39313 10.2838 4.26883 9.96495 4.19633C9.65704 4.12382 9.32715 4.08757 8.97526 4.08757Z"
      fill={color}
    />
    <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" stroke={color} />
  </svg>
);

RubleOutlined.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '-1 -1 20 20',
};

RubleOutlined.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
