export default values => {
  const errors = {};
  if (!values.secondName) {
    errors.secondName = 'Заполните поле';
  } else if (/[^а-яА-Я\-ёЁ]/.test(values.secondName) || values.secondName.length > 20) {
    errors.secondName = 'Фамилия должна состоять из русских букв, длиной не более 20 символов';
  }
  if (!values.name) {
    errors.name = 'Заполните поле';
  } else if (/[^а-яА-Я\-ёЁ]/.test(values.name) || values.name.length > 20) {
    errors.name = 'Имя должно состоять из русских букв, длиной не более 20 символов';
  }
  if (
    values.thirdName &&
    (/[^а-яА-Я\-ёЁ]/.test(values.thirdName) || values.thirdName.length > 20)
  ) {
    errors.thirdName = 'Отчество должно состоять из русских букв, длиной не более 20 символов';
  }
  if (!values.position) {
    errors.position = 'Заполните поле';
  } else if (/[^а-яА-ЯёЁa-zA-Z/\s\-]/.test(values.position) || values.position.length > 80) {
    errors.position = 'Должность должна состоять из букв, длиной не более 80 символов';
  }
  if (!values.regionId) {
    errors.regionId = 'Заполните поле';
  } else if (/[^\d]/.test(values.regionId)) {
    errors.regionId = 'Выберите регион';
  }
  if (!values.cityId) {
    errors.cityId = 'Заполните поле';
  } else if (/[^\d]/.test(values.cityId)) {
    errors.cityId = 'Выберите город';
  }

  if (!values.login) {
    errors.login = 'Заполните поле';
  } else if (values.login.length > 15) {
    errors.login = 'Значение поля не больее 15 символов!';
  }

  if (!values.email) {
    errors.email = 'Заполните поле';
  } else if (!/.+@.+\..+/i.test(values.email)) {
    errors.email = 'Неверно заполнено поле';
  }

  if (!values.mobilePhone) {
    errors.mobilePhone = 'Заполните поле';
  } else if (!/\+(7|37|38)\(\d{3}\)\d{3}-\d{2}-\d{2}/.test(values.mobilePhone)) {
    errors.mobilePhone = 'Неверно заполнено поле';
  }

  if (values.workPhone && !/\+7\(\d{3}\)\d{3}-\d{2}-\d{2}(#\d{1,5})?/.test(values.workPhone)) {
    errors.workPhone = 'Неверно заполнено поле';
  }

  if (values.icq && (/[^\d]/.test(values.icq) || values.icq.length > 9)) {
    errors.icq = 'Номер ICQ должнен состоять из цифр (не более 9)';
  }

  if (!values.newPass) {
    errors.newPass = 'Заполните поле';
  } else if (values.newPass.length < 10 || !/^(?:\d+[a-z]|[a-z]+\d)[a-z\d]*$/i.test(values.newPass)) {
    errors.newPass = 'Пароль должен быть не менее 10 символов и содержать латинские буквы и цифры';
  }

  if (values.newPass && values.confirmPass && values.newPass !== values.confirmPass) {
    errors.confirmPass = 'Пароль для подтверждения отличается от нового пароля';
  }

  return errors;
};
