import { createAction } from 'redux-actions';

const SET_ACCOUNTED_STATE = 'SET_ACCOUNTED_STATE';
const CLEAR_ACCOUNTED_STATE = 'CLEAR_ACCOUNTED_STATE';

export const setAccountedState = createAction(SET_ACCOUNTED_STATE);
export const clearAccountedState = createAction(CLEAR_ACCOUNTED_STATE);

const date = new Date();
const year = date.getFullYear();
const quarter = Math.ceil((date.getMonth() + 1) / 3);

export const accountedState = {
  no: '',
  ozprNo: '',
  search: '',
  requested: false,
  period: `${year}-${quarter * 3 - 2}-01`,
};

export const accountedReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_ACCOUNTED_STATE:
      return { ...state, ...payload };
    case CLEAR_ACCOUNTED_STATE:
      return { ...state, ...accountedState };
    default:
      return state;
  }
};
