import { useCallback, useState } from 'react';

/**
 * Hook to add collapse/expand ability to ref if its Y-overflowed
 *
 * @param {any[]} [deps = []] - dependencies on which depends collapsed state of ref
 * @returns {[
 *   boolean,
 *   React.Dispatch<React.SetStateAction<boolean>>,
 *   React.RefCallback<HTMLElement>
 * ]}
 */
const useCollapsed = (deps = []) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const ref = useCallback(
    node => {
      if (node !== null) {
        const height = node.offsetHeight;
        const innerHeight = [...node.childNodes].reduce(
          (height, child) => height + child.offsetHeight,
          0,
        );

        setIsCollapsed(innerHeight > height);
      }
    },
    [...deps],
  );

  return [isCollapsed, setIsCollapsed, ref];
};

export default useCollapsed;
