/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import cn from 'classnames';

import { IconButton } from '../icon-button';
import { Icon } from '../icon';
import styles from './popup.module.scss';

ReactModal.setAppElement(document.body);

/**
 * Renders a <Popup /> component
 * @component
 * @example
 *
 * return (
 *   <Popup />
 * )
 */
export const Popup = ({
  className,
  classNameOverlay,
  children,
  body,
  hasCloser,
  header,
  overlayClose,
  style,
  visible,
  onClose,
}) => (
  <ReactModal
    portalClassName={cn(styles['popup'])}
    className={cn(styles['popup__content'], className)}
    bodyOpenClassName={cn(styles['popup__body'])}
    overlayClassName={cn(styles['popup__overlay'], classNameOverlay)}
    isOpen={visible}
    style={style}
    onRequestClose={overlayClose ? onClose : null}
  >
    {hasCloser && (
      <IconButton className={styles['popup__close-button']} onClick={onClose}>
        <Icon name="clear" color="brand-red" />
      </IconButton>
    )}
    {header && header}
    {body && body}
    {children && children}
  </ReactModal>
);

Popup.defaultProps = {
  className: '',
  children: null,
  body: null,
  hasCloser: true,
  header: null,
  overlayClose: true,
  style: {},
  visible: false,
  onClose: () => {},
};

Popup.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * children
   */
  children: PropTypes.node,
  /**
   * Popup main content
   */
  body: PropTypes.node,
  /**
   * Able to close Popup
   */
  hasCloser: PropTypes.bool,
  /**
   * Popup title
   */
  header: PropTypes.node,
  /**
   * Able to close Popup on overlay click
   */
  overlayClose: PropTypes.bool,
  /**
   * Inline styles => overlay and content
   */
  style: PropTypes.object,
  /**
   * Popup visibility
   */
  visible: PropTypes.bool,
  /**
   * Click event handler to close popup
   */
  onClose: PropTypes.func,
};
