// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { THEME_COLORS } from 'common/constants/styles';

import styles from './spinner.module.scss';

/**
 * Renders a <Spinner /> component
 * @component
 * @example
 *
 * return (
 *   <Spinner />
 * )
 */
export const Spinner = ({ className = '', color = 'brand-blue', size = 'lg' }) => {
  const strokeColor = THEME_COLORS[color];
  const svgClasses = cn(styles['spinner'], className, {
    [styles['spinner_size_xxs']]: size === 'xxs',
    [styles['spinner_size_xs']]: size === 'xs',
    [styles['spinner_size_sm']]: size === 'sm',
    [styles['spinner_size_md']]: size === 'md',
    [styles['spinner_size_lg']]: size === 'lg',
    [styles['spinner_size_xl']]: size === 'xl',
    [styles['spinner_size_xxl']]: size === 'xxl',
  });

  return (
    <svg className={svgClasses} data-testid="spinner" viewBox="0 0 50 50">
      <circle
        className={styles['spinner__path']}
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke={strokeColor}
        strokeWidth="4"
      />
    </svg>

    // <svg className={classes} data-testid="spinner" viewBox="0 0 100 100">
    //   <defs>
    //     <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="spinner-gradient1">
    //       <stop stopColor={stopColor} offset="0%" stopOpacity="100%" />
    //       <stop stopColor={stopColor} offset="100%" stopOpacity="50%" />
    //     </linearGradient>
    //     <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="spinner-gradient2">
    //       <stop stopColor={stopColor} offset="0%" stopOpacity="50%" />
    //       <stop stopColor={stopColor} offset="95%" stopOpacity="0%" />
    //     </linearGradient>
    //   </defs>
    //   <g fill="none" fillRule="evenodd">
    //     <circle cx="50" cy="4" r="4" fill={stopColor} />
    //     <path
    //       d="M 50 96 a 1 1, 0, 0, 1, 0 -92"
    //       fill="none"
    //       stroke="url(#spinner-gradient1)"
    //       strokeWidth="8"
    //     />
    //     <path
    //       d="M 50 96 a 1 1, 0, 0, 0, 0 -92"
    //       fill="none"
    //       stroke="url(#spinner-gradient2)"
    //       strokeWidth="8"
    //     />
    //     <animateTransform
    //       attributeName="transform"
    //       type="rotate"
    //       from="0 50 50"
    //       to="360 50 50"
    //       dur="0.9s"
    //       repeatCount="indefinite"
    //     />
    //   </g>
    // </svg>
  );
};

Spinner.defaultProps = {
  className: '',
  color: 'brand-blue',
  size: 'lg',
};

Spinner.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * 'white' | 'black' | 'brand-red' | 'brand-red-hover' | 'brand-blue' | 'brand-blue-hover' | 'accent-blue' | 'light-blue'
   * | 'accent-yellow' | 'accent-yellow-hover' | 'accent-green' | 'accent-green-hover'| 'gray' | 'dark-gray' | 'light-gray' | 'stroke-gray'
   */
  color: PropTypes.string,
  /**
   * 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
   */
  size: PropTypes.string,
};
