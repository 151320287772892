import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import ReactHtmlParser from 'react-html-parser';
import { APP_CONTAINER_SELECTOR, isCactus } from 'common/config';
import { hasAccess } from 'common/util';
import classnames from 'classnames';
const AgreementContent = props => {
  const { agreement, loading, reject, accept } = props;
  return (
    <div>
      <h4>{ReactHtmlParser(agreement.Title)}</h4>
      {ReactHtmlParser(agreement.Text)}
      <div className="row justify-content-center modal-actions">
        <button className="btn btn-outline-danger btn--rect" onClick={reject} disabled={loading}>
          Отложить
        </button>
        <button className="btn btn-danger btn--rect" onClick={accept} disabled={loading}>
          Принять
        </button>
      </div>
    </div>
  );
};

const MessageContent = props => {
  const { message, accept } = props;

  if (!message) {
    return null;
  }

  return (
    <div>
      <i className="icon icon-close12 brand-list-closer" onClick={accept} />
      <h4>{ReactHtmlParser(message['Title'])}</h4>
      <div className="message-content">{ReactHtmlParser(message['Text'])}</div>
    </div>
  );
};

const MessageModal = props => {
  const { message, agreement, loading, user, accept, reject } = props;

  const Content = agreement ? AgreementContent : MessageContent;
  const modalMessage = `Modal__message--${agreement ? 'agreement' : 'news'}`;
  const classModal = classnames('Modal', 'Modal__message', modalMessage, {
    'Modal--cactus': isCactus,
  });

  const [isOpen, setIsOpen] = useState(
    (agreement && hasAccess(user, 'FIN')) || message?.ID > 0 || false,
  );

  const handleAccept = useCallback(
    (id, type) => () => {
      setIsOpen(false);
      return accept({ id, type });
    },
    [],
  );

  const id = agreement?.ID || message?.ID;
  const type = agreement ? 'fin' : '';

  const handleReject = useCallback(() => reject(), []);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      contentLabel="Modal"
      className={classModal}
      overlayClassName="Overlay Overlay__message"
      appElement={document.querySelector(APP_CONTAINER_SELECTOR)}
    >
      <Content
        agreement={agreement}
        message={message}
        reject={handleReject}
        accept={handleAccept(id, type)}
        loading={loading}
      />
    </ReactModal>
  );
};

export default MessageModal;
