import React from 'react';
import { Link } from 'react-router-dom';

const NumberCell = row => {
  if (!row) return null;

  const { no, children = false } = row.original;

  if (!no) {
    return null;
  }

  const id = no.replace('/', '-');

  return (
    <Link
      className={'link-detail-special-price'}
      to={{
        pathname: `/vad/special-prices/detail/${id}`,
        state: {
          hasChildren: !!children,
        },
      }}
    >
      {/-/.test(id) && <i className="icon icon-child-arrow-icon" />}
      {id}
    </Link>
  );
};

export default NumberCell;
