import { FILTER_BY_TILDA_VALUES } from '../../constants';
import curryCreateFilters from './curryCreateFilters';

export default serverFilterGroups =>
  serverFilterGroups.map(serverFilterGroup => ({
    name: serverFilterGroup.caption.toUpperCase(),
    key: serverFilterGroup.id,
    row: 1,
    type: FILTER_BY_TILDA_VALUES,
    filters: curryCreateFilters(serverFilterGroup.values),
  }));
