import React from 'react';
import get from 'lodash/get';
import List from './common/list';
import withApi from 'hoc/withApi';
import sortDates from 'helpers/sortDates';

const columns = [
  {
    Header: 'Дата учета',
    id: 'date',
    accessor: d => d.dateSort,
    Cell: row => get(row, ['original', 'date'], null),
    sortMethod: sortDates,
    width: 90,
  },
  {
    Header: 'Код',
    id: 'itemNo',
    accessor: 'itemNo',
    width: 80,
  },
  {
    Header: 'Наименование',
    headerStyle: { textAlign: 'left' },
    id: 'name',
    accessor: 'name',
    style: { textAlign: 'left' },
    minWidth: 330,
  },
  {
    Header: 'Серийный номер',
    id: 'serialNo',
    accessor: 'serialNo',
    width: 130,
  },
  {
    Header: 'Место выдачи',
    id: 'place',
    accessor: 'place',
    width: 120,
  },
  {
    Header: 'Кол.',
    id: 'qty',
    accessor: 'qty',
    width: 50,
  },
  {
    Header: 'Статус',
    id: 'status',
    accessor: 'status',
    width: 110,
  },
  {
    Header: () => <span title="Номер заказа продажи">№ Заказа</span>,
    id: 'zprNo',
    accessor: 'zprNo',
    width: 90,
  },
  {
    Header: () => <span title="Номер товарной накладной">№ ТН</span>,
    id: 'packListNo',
    accessor: 'packListNo',
    width: 100,
  },
  {
    Header: 'Дата отгрузки',
    id: 'shipmentDate',
    accessor: d => d.shipmentDateSort,
    Cell: row => get(row, ['original', 'shipmentDate'], null),
    width: 90,
  },
  {
    Header: () => <span title="Цена торговли">Цена</span>,
    id: 'priceTrade',
    accessor: 'priceTrade',
    width: 100,
  },
];

const Debt = () => <List columns={columns} />;
Debt.keepLocationState = true

export default withApi(Debt);
