import alert from 'components/native/alert/function';
import confirm from 'components/native/confirm/function';
import { QUEUE_LINKS } from 'common/config';
import { download } from 'common/util';
import { SubmissionError } from 'redux-form';
import ReactHtmlParser from 'react-html-parser';

export default {
  name: 'linkings',
  state: {
    rows: [],
  },
  reducers: {
    load: (state, priceRows) => ({ ...state, rows: priceRows }),
  },
  effects: ({ api }) => ({
    async priceLoad({ selected, start, cnt, history }) {
      const isFull =
        Object.keys(selected).length === cnt &&
        !Object.values(selected).some(elem => elem.value === '');

      if (!isFull) {
        alert('Сопоставьте все колонки!');
        return false;
      }
      if (start === '') {
        alert('Укажите стартовую строку');
        return false;
      }

      const { success, message } = await api.post({
        url: '/settings/bindings/save-price',
        data: { ...selected, startRow: start },
      });

      if (success) {
        try {
          await confirm(message, {
            confirmLabel: 'Просмотр очереди',
            abortLabel: 'Привязка товаров',
          });

          history.push({
            pathname: '/queue',
            state: { queueType: QUEUE_LINKS },
          });
        } catch (e) {
          history.push({ pathname: '/settings/bindings' });
        }
      } else {
        alert(message);
      }
    },
    async getExampleFile() {
      const { blob, filename } = await api.get({
        url: '/file/get/linked-file',
      });

      download(blob, filename);
    },
    async submitPrice(data) {
      const formData = new FormData();

      formData.append('name', data.linked.name);
      formData.append('price', data.linked);

      const {
        success,
        message,
        priceRows = [],
      } = await api.post({
        url: '/settings/bindings/load-price',
        data: formData,
      });

      if (!success) {
        throw new SubmissionError({
          _error: ReactHtmlParser(message),
        });
      } else {
        this.load(priceRows);
      }
    },
    async submitLinks({ data, history }) {
      const formData = new FormData();

      formData.append('name', data.linked.name);
      formData.append('linked', data.linked);

      const { success, message } = await api.post({
        url: '/settings/bindings/load',
        data: formData,
      });

      if (success) {
        try {
          await confirm(message, {
            confirmLabel: 'Просмотр очереди',
            abortLabel: 'Привязка товаров',
          });

          history.push({
            pathname: '/queue',
            state: { queueType: QUEUE_LINKS },
          });
        } catch (e) {
          history.push({ pathname: '/settings/bindings' });
        }
      } else {
        throw new SubmissionError({
          _error: ReactHtmlParser(message),
        });
      }
    },
  }),
};
