import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-v6';
import selectTableHOC from 'react-table-v6/lib/hoc/selectTable';
import newsletterColumns from '../../common/columns/newsletter-columns';
import alert from 'components/native/alert/function';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import { getUser } from 'pages/Orders/selectors';
import { useHistory } from 'react-router-dom';

const SelectTable = selectTableHOC(ReactTable);

const ModalContentNewsLetter = ({ closeModal }) => {
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [data, setData] = useState([]);

  const history = useHistory();

  const {
    shipments: { getSubscriptions, subscribe },
  } = useDispatch();

  const fetching = useSelector(getEffectLoading('shipments', 'getSubscriptions'));
  const subscribing = useSelector(getEffectLoading('shipments', 'subscribe'));

  const { EMail } = useSelector(getUser);

  const keyField = 'code';

  useEffect(() => {
    getSubscriptions().then(({ success, subscriptions = [], message }) => {
      if (success) {
        const active = subscriptions.reduce((acc, { code, subscribed }) => {
          if (Number(subscribed)) {
            return [...acc, `select-${code}`];
          }

          return acc;
        }, []);

        setSelected(active);
        setData(subscriptions);
      } else {
        alert(message).then(() => history.push('/settings/personal'));
      }
    });
  }, []);

  const toggleSelection = useCallback(
    key => {
      const keyIndex = selected.indexOf(key);
      let current = [...selected];

      if (keyIndex >= 0) {
        current = [...current.slice(0, keyIndex), ...current.slice(keyIndex + 1)];
      } else {
        current.push(key);
      }

      setSelected(current);
    },
    [selected],
  );

  const isSelected = key => selected.includes(`select-${key}`);

  const toggleAll = () => {
    let current = [];

    if (!selectAll) {
      data.forEach(item => {
        current.push(`select-${item[keyField]}`);
      });
    }

    setSelectAll(!selectAll);
    setSelected(current);
  };

  const sendSubscribersNewsletters = async () => {
    if (!selected.length) {
      return alert('Данных не передано!');
    }

    const success = await subscribe(selected);

    if (success) {
      closeModal();
    }
  };

  return (
    <div className="newsletter__modal">
      <div className="newsletter__modal__header">
        <h2 className="newsletter__modal__title">Подписка на рассылку</h2>
        <div className="newsletter__modal__description">
          Уведомления будут приходит на ваш емейл {EMail}, указанный в настройках.
        </div>
      </div>
      <div className="newsletter__modal__content">
        <SelectTable
          keyField={keyField}
          data={data}
          columns={newsletterColumns}
          minRows={1}
          isSelected={isSelected}
          toggleSelection={toggleSelection}
          toggleAll={toggleAll}
          selectAll={selectAll}
          showPagination={false}
          defaultPageSize={5000}
          pageSize={5000}
          loading={fetching}
          noDataText="ничего не найдено"
        />
      </div>
      <div className="newsletter__modal__footer">
        <button
          className="btn btn-danger btn--rect"
          onClick={sendSubscribersNewsletters}
          disabled={subscribing}
        >
          Сохранить
        </button>
      </div>
    </div>
  );
};

ModalContentNewsLetter.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalContentNewsLetter;
