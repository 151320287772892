import React from 'react';
import cn from 'classnames';
import { Field, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowRender } from 'common/ui/arrow-render';
import { Button } from 'common/ui/button';
import { renderText } from 'components/fields';
import {
  renderCity,
  renderRegion,
  renderMasked,
  renderCheckbox,
  renderPassword,
  renderPersonalEmail,
} from 'pages/Settings/components/fields';
import { mask } from 'pages/Settings/helpers/util';
import { maxLength50 } from 'utils/validators';

import styles from './personal-form.module.scss';

export const PersonalForm = ({ submitting, handleSubmit, isNew }) => {
  const { personal } = useDispatch();
  const info = useSelector(getFormValues('personalForm'));
  const { approvalReceived, email, login } = info;
  const isDisabled = email && isNew;

  const sendConfirm = email => {
    personal.confirmEmail({ email, login });
  };

  return (
    <form className={styles['form']} onSubmit={handleSubmit}>
      <div className={styles['field']}>
        <Field name="secondName" type="text" label="Фамилия" required component={renderText} />
      </div>
      <div className={styles['field']}>
        <Field name="name" type="text" label="Имя" required component={renderText} />
      </div>
      <div className={styles['field']}>
        <Field name="thirdName" type="text" label="Отчество" component={renderText} />
      </div>
      <div className={styles['field']}>
        <Field
          name="email"
          type="text"
          label="E-mail"
          disabled={isDisabled}
          required
          component={renderPersonalEmail}
          validate={[maxLength50]}
          confirm={sendConfirm}
        />
      </div>
      <div className={styles['field']}>
        <Field
          name="regionId"
          label="Регион"
          required
          component={renderRegion}
          info={info}
          isNew={isNew}
          placeholder=""
          arrowRenderer={ArrowRender}
        />
      </div>
      <div className={styles['field']}>
        <Field
          name="cityId"
          label="Город"
          required
          info={info}
          isNew={isNew}
          arrowRenderer={ArrowRender}
          placeholder=""
          component={renderCity}
        />
      </div>
      <div className={styles['field']}>
        <Field
          name="mobilePhone"
          label="Мобильный"
          required
          mask={mask}
          placeholder="+7(___)___-__-__"
          component={renderMasked}
        />
      </div>
      <div className={styles['field']}>
        <Field name="position" type="text" label="Должность" required component={renderText} />
      </div>
      {isNew && (
        <>
          <div className={styles['field']}>
            <Field name="newPass" label="Придумайте пароль" required component={renderPassword} />
          </div>
          <div className={styles['field']}>
            <Field
              name="confirmPass"
              label="Подтвердите пароль"
              required
              component={renderPassword}
            />
          </div>
        </>
      )}
      <div className={cn(styles['field'], styles['agreement'])}>
        <Field
          name="approvalReceived"
          label="Я согласен на обработку персональных данных"
          component={renderCheckbox}
        />
      </div>
      <Button
        theme="secondary"
        type="submit"
        className={styles['submit']}
        disabled={submitting || !approvalReceived}
      >
        {isNew ? 'Зарегистироваться' : 'Сохранить'}
      </Button>
    </form>
  );
};
