import React, { useRef, useState, useEffect } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import formattingByMask from '../helpers/formattingByMask';
import validateDayPickerInput from '../helpers/validateDayPickerInput';
import { FORMAT } from './constants/date-format';
import validateDate from '../helpers/validateDate';
import alert from './native/alert/function';

const MASK = '##.##.####';
const formattingValue = formattingByMask(MASK);
const validateValue = validateDayPickerInput();

const CustomDayPickerInput = ({ value, onDateInputChange, dayPickerProps, inputProps }) => {
  const [date, setDate] = useState();
  const refDayPicker = useRef();

  useEffect(() => {
    if (date && !validateDate(date)) {
      alert('Введен неправильный формат даты');
    }

    setDate(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, value]);

  const showCalendar = () => refDayPicker.current.getInput().focus();

  const handleChange = e => {
    e.persist();
    const { value } = e.target;
    e.target.value = validateValue(value);
  };

  const handleBlur = e => {
    const { value } = e.target;
    const formattedValue = formattingValue(value);
    setDate(formattedValue);
  };

  return (
    <div className="datepicker-input">
      <DayPickerInput
        ref={refDayPicker}
        value={date}
        format={FORMAT}
        placeholder={FORMAT}
        dayPickerProps={{
          locale: 'ru',
          localeUtils: MomentLocaleUtils,
          ...dayPickerProps,
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        inputProps={{
          className: 'form-control form-control-sm datepicker-input--placholder__small',
          onChange: handleChange,
          onBlur: handleBlur,
          ...inputProps,
        }}
        onDayChange={onDateInputChange}
      />
      <i onClick={showCalendar} className="icon icon-calendar" />
    </div>
  );
};

export default CustomDayPickerInput;
