import React, {useCallback, useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import columns from './helpers/columns';
import Search from './components/search';
import { hrefReglamentClaims } from 'common/util';
import { isCactus } from 'common/config';
import withApi from 'hoc/withApi';
import { useDispatch } from 'react-redux';
import cn from 'classnames'

const tableSort = [
  {
    id: 'claimDate',
    desc: true,
  },
];

const Claims = () => {
  const [filtered, setFiltered] = useState([]);

  const { list } = useDispatch();

  const handleSortChange = useCallback(list.unblock, []);

  return (
    <Content title="Список претензий">
      <div
        className={cn('actions-container', 'actions-container--order', 'align-items-center')}
      >
        <div className="row event-actions" style={{ padding: 0 }}>
          {!isCactus && (
            <div style={{ padding: '0 10px' }}>
              <Link to="/logistic/claims/new" className="section-nav">
                <i className="icon icon-create-icon" /> Создать претензию
              </Link>
            </div>
          )}
          <div style={{ padding: '0 10px' }}>
            <Search filter={setFiltered} />
          </div>
          <div
            className={cn('col-2', 'justify-content-end', 'align-items-center', 'invite-block')}
            style={{
              paddingLeft: '10px',
            }}
          >
            <a className="section-nav" href={hrefReglamentClaims}>
              Регламент подачи претензий
            </a>
          </div>
        </div>
      </div>
      <div className="logistic-list">
        <TableList
          manual
          route='/logistic/claims'
          defaultPageSize={5000}
          pageSize={5000}
          filtered={filtered}
          columns={columns}
          defaultSorted={tableSort}
          onSortedChange={handleSortChange}
        />
      </div>
    </Content>
  );
};

export default withApi(Claims);
