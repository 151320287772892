export const LENDING_FORM = 'LENDING_FORM';
export const creditDocList = [
  Object.freeze({
    code: 'ИП',
    name: 'ИП',
  }),
  Object.freeze({
    code: 'ЮЛ',
    name: 'ЮЛ',
  }),
];
export const requiredValidate = value => (value ? undefined : 'Required');
