import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import alert from 'components/native/alert/function';
import { Button } from 'common/ui/button';
import { renderText } from 'components/fields';
import { validate } from '../../utils/validate';
import { maxLength50, maxLength15 } from "utils/validators";

import styles from './invite-form.module.scss';

const Form = ({ submitting, handleSubmit, reset, onClose }) => {
  const handleReset = () => {
    reset();
    onClose();
  };

  return (
    <form className={styles['form']} onSubmit={handleSubmit}>
      <div className={styles['field']}>
        <Field name="login" type="text" label="Логин" required component={renderText} validate={[maxLength15]} />
      </div>
      <div className={styles['field']}>
        <Field name="email" type="text" label="E-mail" required component={renderText} validate={[maxLength50]} />
      </div>
      <div className={styles['buttons']}>
        <Button theme="secondary" type="submit" className={styles['submit']} disabled={submitting}>
          Отправить
        </Button>
        <Button
          type="button"
          className={styles['cancel']}
          disabled={submitting}
          onClick={handleReset}
        >
          Отмена
        </Button>
      </div>
    </form>
  );
};

export const InviteForm = reduxForm({
  form: 'inviteForm',
  validate,
  onSubmit: (values, { employees }) => employees.invite(values),
  onSubmitFail: (errors, dispatch, submitError) => {
    if (submitError instanceof SubmissionError) {
      alert(submitError.errors._error);
    } else {
      throw submitError;
    }
  },
  onSubmitSuccess: (_result, _dispatch, { onClose }) => {
    onClose();
    alert('Приглашение на регистрацию отправлено');
  },
})(Form);
