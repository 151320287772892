import { proxyArgsToMethods } from 'common/util';
import * as effects from './effects';
import * as reducers from './reducers';

export default {
  name: 'demo',
  state: {
    detail: {},
  },
  reducers,
  effects(dispatch) {
    const type = this.name;

    return {
      ...proxyArgsToMethods(effects, [dispatch, type]),
      async getItems(text) {
        await dispatch.api.get({
          url: `demofund/category/list/${text}`,
        });
      },
      async createRequest(data) {
        return await dispatch.api.post({
          url: 'vad/demofond/create',
          data,
        });
      },
      async getAgents(value) {
        try {
          const { data } = await dispatch.api.get({
            url: 'search/contact',
            query: `?text=${encodeURIComponent(value)}`,
          });

          return data;
        } catch (e) {
          return {};
        }
      },
      async getInn(value) {
        try {
          const { data } = await dispatch.api.get({
            url: 'search/contact-inn',
            query: `?text=${encodeURIComponent(value)}`,
          });
          const { hits } = data;

          return hits?.[0] || {};
        } catch (e) {
          return {};
        }
      },
    };
  },
  selectors: slice => ({
    getOrder() {
      return slice(({ detail }) => {
        const { header = {}, data: products = [], comments = [] } = detail;

        return { ...header, products, comments };
      });
    },
  }),
};
