/* eslint-disable no-console */
import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import { RenderHideInput, renderMasked, renderText, Checkbox } from 'components/fields';
import { mask } from 'pages/Settings/helpers/util';
import classNames from 'classnames';
import DemofondTable from './DemofondTable';
import FieldsTest from './FieldsTest';
import { useDispatch } from 'react-redux';
import { demoCollector } from 'pages/VAD/constants';
import alert from 'components/native/alert/function';

const OrderForm = props => {
  const { isDemoOrder, closeModal, title, handleSubmit, reset, submitting } = props;

  const handleClose = useCallback(() => {
    reset();
    closeModal();
  }, []);

  const {
    demo: { createRequest },
  } = useDispatch();

  const onSubmit = useCallback(async data => {
    if (demoCollector.isEmpty()) {
      return false;
    }

    const items = Object.values(demoCollector.get()).reduce(
      (items, { id, qty }) => ({ ...items, [id]: Number(qty) }),
      {},
    );

    try {
      const { success, message } = await createRequest({
        ...data,
        customer: data.customerName,
        qty: items,
      });

      alert(message);

      if (success) {
        closeModal();
      }
    } catch (e) {
      console.error(e);
      closeModal();
    }
  }, []);

  return (
    <div className="new-order__form">
      <h3>{title}</h3>
      <hr className="red_line" />
      <form onSubmit={handleSubmit(onSubmit)}>
        {isDemoOrder && <FieldsTest />}
        <div className="row order-field">
          <Field
            name="contact"
            required
            type="text"
            label="Контактные данные (ФИО)"
            colSm={4}
            maxLength={160}
            component={renderText}
          />
          <Field
            name="phone"
            required
            label="Мобильный телефон"
            type="text"
            colSm={4}
            maxLength={12}
            component={renderMasked}
            mask={mask}
          />
          <Field
            name="email"
            required
            label="E-mail"
            type="text"
            colSm={4}
            maxLength={125}
            component={renderText}
          />
          <Field name="type" defaultValue={isDemoOrder ? 1 : 2} component={RenderHideInput} />
        </div>
        {!isDemoOrder && (
          <div className="row">
            <div className="verification-block col-sm-6">
              <Field
                name="verification"
                label="Запросить проверку состояния оборудования"
                required
                component={Checkbox}
              />
            </div>
          </div>
        )}
        <DemofondTable isDemoOrder={isDemoOrder} />
        <div className="demofund-order__form__buttons">
          <button
            type="submit"
            disabled={submitting}
            className={classNames('btn', 'btn-danger', {
              disabled: submitting,
            })}
          >
            Оформить заказ
          </button>
          <button type="button" className="btn btn-outline-danger" onClick={handleClose}>
            Отменить
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrderForm;
