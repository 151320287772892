import React, { Fragment } from 'react';
import { getCurrencySymbol } from 'common/util';
import sortStringNumbers from 'helpers/sortStringNumbers';
import priceFormat from 'helpers/priceFormat';
import onlineReserveColumn from 'pages/Orders/components/orders-item/helpers/onlineReserveColumn';
import { FILTER_TILDA_PROPERTIES } from 'pages/Orders/components/common/order-list-filter/constants';
import { getOrderCurrency } from 'pages/Orders/helpers';
import isEmpty from 'lodash/isEmpty';
import {
  DesiredPriceCell,
  DesiredPriceClientCell,
  DesiredQtyCell,
  GroupCell,
  IdCell,
  ImageCell,
  MinPackagedCell,
  NameCell,
  PackagedCell,
  SubGroupCell,
  ExpectedNextCell,
} from 'pages/Orders/components/common/columns/items/cells';

const itemColumns = (order, isPreorder, isRegional, imageWrapper) => {
  const titleOrderCurrency = getOrderCurrency('title', order.currency);
  const isRegionalShipment = isEmpty(order) ? isRegional : Number(order.isRegional) !== 1;
  const inOrder= Object.hasOwn(order, "orderNo");

  const regSuffix = (isRegionalShipment && 'Рег') || 'Мск';
  const regOnWhich = (isRegionalShipment && 'региональном') || 'московском';
  const regFromWhich = (isRegionalShipment && 'регионального') || 'московского';

  return [
    // USED FOR FILTERS
    {
      Header: '',
      width: 0,
      accessor: FILTER_TILDA_PROPERTIES,
      sortable: false,
      headerStyle: { padding: 0 },
    },
    {
      Header: '',
      width: 0,
      accessor: 'other',
      sortable: false,
      headerStyle: { padding: 0 },
    },
    {
      Header: '',
      width: 0,
      accessor: 'code2',
      sortable: false,
      headerStyle: { padding: 0 },
    },
    {
      Header: '',
      width: 0,
      accessor: 'code3',
      sortable: false,
      headerStyle: { padding: 0 },
    },
    //USED FOR HIDDEN BRAND COLUMN SETTINGS
    {
      Header: '',
      width: 0,
      accessor: 'brand1',
      sortable: false,
      headerStyle: { padding: 0 },
      headerClassName: 'hidden',
    },
    //
    {
      Header: 'Код',
      title: 'Код',
      id: 'id',
      isPreorder: true,
      accessor: 'id',
      width: 100,
      sortMethod: sortStringNumbers,
      Cell: IdCell,
    },
    {
      show: !isPreorder,
      Header: 'Доп. Код',
      title: 'Доп. Код',
      id: 'vendorId',
      accessor: 'vendorId',
      width: 150,
      sortMethod: sortStringNumbers,
    },
    {
      Header: 'Изображение',
      title: 'Изображение',
      id: 'image',
      accessor: 'imageUrl',
      sortable: false,
      style: { overflow: 'visible', padding: '0px 10px' },
      width: 100,
      isPreorder: true,
      Cell: ImageCell(imageWrapper),
    },
    {
      Header: 'Наименование',
      title: 'Наименование',
      id: 'name',
      accessor: 'name',
      width: 400,
      headerStyle: { textAlign: 'left' },
      style: { textAlign: 'left' },
      isPreorder: true,
      Cell: NameCell(order, isPreorder),
    },
    {
      show: !isPreorder,
      Header: 'Бренд',
      title: 'Бренд',
      id: 'brand',
      accessor: 'brand',
    },
    {
      show: !isPreorder,
      Header: 'Part №',
      title: 'Part №',
      id: 'vendorPart',
      accessor: 'vendorPart',
      width: 150,
    },
    {
      show: !isPreorder,
      Header: () => <span>Скл.</span>,
      title: 'Количество на складе',
      id: 'inventoryMSK1',
      accessor: 'inventoryMSK1',
      width: 55,
      sortMethod: sortStringNumbers,
    },
    {
      show: !isPreorder,
      Header: 'Дост.',
      title: 'Доступное количество',
      id: 'availableClient',
      accessor: 'availableClient',
      width: 55,
      sortMethod: sortStringNumbers,
      Cell: row => (row && <strong>{row.original.availableClient}</strong>) || null,
    },
    {
      show: !isPreorder,
      Header: `Скл. ${regSuffix}.`,
      title: `Количество на ${regOnWhich}<br /> складе`,
      id: 'warehouseOfficeRG',
      accessor: 'warehouseOfficeRG',
      width: 80,
      sortMethod: sortStringNumbers,
    },
    {
      show: !isPreorder,
      Header: `Дост. ${regSuffix}.`,
      title: `Доступное количество<br /> на ${regOnWhich} складе<br /> и в транзите`,
      id: 'availableMSK1',
      accessor: 'availableMSK1',
      width: 90,
      sortMethod: sortStringNumbers,
      Cell: row => (row && <strong>{row.original.availableMSK1}</strong>) || null,
    },
    {
      Header: 'ЖКол',
      title: 'Желаемое количество',
      id: 'desiredQty',
      accessor: 'desiredQty',
      isPreorder: true,
      sortMethod: sortStringNumbers,
      width: 90,
      Cell: (props) => <DesiredQtyCell {...props} inOrder={inOrder} />,
    },
    {
      show: !isPreorder,
      Header: 'ОР',
      title:
        'Онлайн Резерв(В случае<br />платного резерва со стоимостью<br /> отмены резерва, РУБ/шт*ч)',
      id: 'onlineReserve',
      accessor: 'cancelReserveCostHr',
      width: 70,
      style: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
      Cell: onlineReserveColumn,
      sortMethod: sortStringNumbers,
    },
    {
      show: !isPreorder,
      Header: 'ВР',
      title: 'Время нахождения<br /> в резерве, ч',
      id: 'inReserveHours',
      accessor: 'inReserveHours',
      width: 60,
      sortMethod: sortStringNumbers,
      Cell: row => (row.value && parseFloat(row.value).toFixed(4)) || null,
    },
    {
      show: !isPreorder,
      Header: 'Кол',
      title: 'Количество в заказе',
      id: 'qty',
      accessor: 'qty',
      width: 60,
      sortMethod: sortStringNumbers,
      Cell: row => <strong>{row.original.qty}</strong>,
    },
    {
      show: !isPreorder,
      Header: 'ЗКол',
      title: 'Кол-во в своих Заказах',
      id: 'selfReserve',
      accessor: 'selfReserve',
      width: 60,
      sortMethod: sortStringNumbers,
    },
    {
      Header: () => (
        <span>Цена, {getCurrencySymbol(getOrderCurrency('order', order.currency))}</span>
      ),
      title: 'Цена в заказе',
      id: 'price',
      accessor: 'price',
      sortMethod: sortStringNumbers,
      isPreorder: true,
      Cell: row => (
        <Fragment>
          {row.value} {isPreorder && getCurrencySymbol(row.original.currencyCode)}
        </Fragment>
      ),
    },
    {
      show: !isPreorder,
      Header: () => <span>ЖЦена, &#8381;</span>,
      title: 'Желаемая цена(РУБ)',
      id: 'desiredPrice',
      accessor: d => d.desiredPriceRUB,
      sortMethod: sortStringNumbers,
      Cell: (props) => <DesiredPriceCell {...props} inOrder={inOrder} />,
    },
    /* {
      show: false,//!isPreorder,
      Header: () => <span>ЖЦена, {getCurrencySymbol(titleOrderCurrency)}</span>,
      title: `Желаемая цена (${titleOrderCurrency})`,
      id: 'desiredPriceClient',
      accessor: 'desiredPriceClient',
      sortMethod: sortStringNumbers,
      Cell: DesiredPriceClientCell,
    },*/
    {
      show: !isPreorder,
      Header: () => <span>РЦена, &#8381;</span>,
      title: 'Рекомендуемая Цена(РУБ)',
      id: 'priceLCY',
      accessor: 'priceLCY',
      sortMethod: sortStringNumbers,
      Cell: row => priceFormat(row.value),
    },
    /* {
      show: false, //!isPreorder,
      Header: () => <span>РЦена, {getCurrencySymbol(titleOrderCurrency)}</span>,
      title: `Рекомендуемая Цена(${titleOrderCurrency})`,
      id: 'priceClient',
      accessor: 'priceClient',
      sortMethod: sortStringNumbers,
    },*/
    {
      show: !isPreorder,
      Header: () => <span>РЦена{regSuffix}, &#8381;</span>,
      title: `Рекомендуемая Цена<br /> с ${regFromWhich} склада (РУБ)`,
      id: 'priceLcyMSK',
      accessor: 'priceLcyMSK',
      sortMethod: sortStringNumbers,
      Cell: row => priceFormat(row.value),
    },
    /*{

      show: !isPreorder,
      Header: () => (
        <span>
          РЦена{regSuffix}, {getCurrencySymbol(titleOrderCurrency)}
        </span>
      ),
      title: `Рекомендуемая Цена<br /> с ${regFromWhich} склада (${titleOrderCurrency})`,
      id: 'priceLcyRG',
      accessor: 'priceLcyRG',
      sortMethod: sortStringNumbers,
      Cell: row => priceFormat(row.value),
    },*/
    {
      show: !isPreorder,
      Header: 'Код валюты',
      title: 'Код валюты',
      id: 'currencyCode',
      accessor: 'currencyCode',
    },
    {
      show: !isPreorder,
      Header: 'МКол',
      title: 'Минимальное количество',
      id: 'minPackaged',
      accessor: 'minPackaged',
      width: 60,
      Cell: MinPackagedCell,
      sortMethod: sortStringNumbers,
    },
    {
      show: !isPreorder,
      Header: 'Упак.',
      title: 'Единиц товара в упаковке',
      id: 'packaged',
      accessor: 'packaged',
      width: 130,
      minWidth: 130,
      Cell: PackagedCell,
      sortMethod: sortStringNumbers,
    },
    {
      show: !isPreorder,
      Header: 'Вес',
      title: 'Вес',
      id: 'weight',
      accessor: 'weight',
      width: 60,
      sortMethod: sortStringNumbers,
    },
    {
      show: !isPreorder,
      Header: 'Объем',
      title: 'Объем',
      id: 'volume',
      accessor: 'volume',
      width: 70,
      Cell: row => (row && parseFloat(row.value).toFixed(2)) || null,
      sortMethod: sortStringNumbers,
    },
    {
      show: !isPreorder,
      Header: 'Гар.',
      title: 'Гарантия',
      id: 'warranty',
      accessor: 'warranty',
      width: 60,
      sortMethod: sortStringNumbers,
    },
    {
      show: !isPreorder,
      Header: 'Ожид. Приход',
      title: 'Приход на МСК в течение<br /> 5 рабочих дней',
      id: 'availableExpected',
      accessor: 'availableExpected',
      width: 120,
      sortMethod: sortStringNumbers,
    },
    {
      show: !isPreorder,
      Header: 'След. Приход',
      title: 'Приход на МСК позже<br /> 5 рабочих дней',
      id: 'availableExpectedNext',
      accessor: 'availableExpectedNext',
      width: 115,
      sortMethod: sortStringNumbers,
      Cell: ExpectedNextCell,
    },
    {
      show: !isPreorder,
      Header: 'Группа',
      title: 'Группа',
      id: 'group2',
      accessor: 'group2',
      Cell: (props) => <GroupCell {...props} inOrder={inOrder} />,
    },
    {
      show: !isPreorder,
      Header: 'Подгруппа',
      title: 'Подгруппа',
      id: 'group3',
      accessor: 'group3',
      Cell: (props) => <SubGroupCell {...props} inOrder={inOrder} />,
    },
    {
      show: !isPreorder,
      Header: () => <span>РРЦ, &#8381;</span>,
      title: 'РР Цена',
      id: 'RRP',
      accessor: 'RRP',
      sortMethod: sortStringNumbers,
      Cell: row => priceFormat(row.value),
    },
    {
      show: !isPreorder,
      Header: 'Дата РРЦ',
      title: 'Дата РР Цены',
      id: 'RRPDate',
      accessor: 'RRPDate',
    },
    {
      show: isPreorder,
      Header: 'Дата поставки',
      title: 'Дата поставки',
      id: 'deliveryDate',
      accessor: 'deliveryDate',
      sortMethod: sortStringNumbers,
      isPreorder: true,
    },
    {
      show: isPreorder,
      Header: 'Доступен к предзаказу от',
      title: 'Доступен к предзаказу от',
      id: 'actualyDateFrom',
      accessor: 'actualyDateFrom',
      sortMethod: sortStringNumbers,
      isPreorder: true,
    },
    {
      show: isPreorder,
      Header: 'Доступен к предзаказу до',
      title: 'Доступен к предзаказу до',
      id: 'actualyDateTo',
      accessor: 'actualyDateTo',
      sortMethod: sortStringNumbers,
      isPreorder: true,
    },
  ];
};

export default itemColumns;
