const preloadedState = window.__PRELOADED_STATE__;

export default {
  name: 'secure',
  state: preloadedState?.secure,
  reducers: {
    'user/login': state => ({ ...state, isCredPresence: true }),
    csrf: (state, payload) => ({ ...state, csrf: payload }),
    'api/success': (state, payload) => ({ ...state, csrf: payload.csrf_token || state.csrf }),
    'api/fail': (state, payload) => ({ ...state, csrf: payload.csrf_token || state.csrf }),
    'user/logout': state => ({ ...state, isCredPresence: false }),
  },
  effects: dispatch => ({
    async renewCSRF() {
      /**
       * Need an unsafe method to renew on backend
       */
      const { csrf } = await dispatch.api.post({ url: 'renew-csrf' });
      this.csrf(csrf);
    },
  }),
};
