import SHIPPING_AGENT from './types-shiping-agent';

export const optionsForTypeShippingAgent = [
  { value: SHIPPING_AGENT.client, label: 'Клиент' },
  { value: SHIPPING_AGENT.tk, label: 'ТК' },
];

export const optionsMethodGetDocument = [
  { value: '0', label: 'На руки' },
  { value: '1', label: 'В коробке' },
];

export const optionsTypeUnload = [
  { value: '0', label: 'Склад клиента' },
  { value: '1', label: 'Перегруз' },
];
