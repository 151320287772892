import { isEqual, isFunction } from 'lodash';
import {arrayToObj, undef, objToQS} from 'common/util'
import { syncQueueMigrateRows, syncQueueEditRows } from 'pages/Orders/helpers';
import { alert } from 'common/lib';
import history from "common/history"
import {getData, getDataByPath, setDataByPath} from "models/list/helpers"
import { DEFAULT_PAGE_SIZE } from 'common/config';
import { getAvailableCollection, getGoodsSortByAbc } from 'helpers/sortDataByAvailability';

/** @type ListState */
const initState = {
  fetching: false,
  data: {},
  manual: false,
  indexes: {},
  total: 0,
};

/**
 * Flush list state
 *
 * @param {ListState} state
 */
const clear = state => ({
  ...state,
  ...initState,
});

/**
 * Load payload into state reducer
 *
 * @param {ListState} state
 * @param {{data: ListState, indexes: object}} payload
 */
const load = (state, payload) => {
  const {
    dataKey = history.location.pathname.substring(1),
    data = {},
    indexes
  } = payload || {}

  if(Array.isArray(data.data)){
    state.data[dataKey] = data.data
  }

  state.indexes = indexes || state.indexes
  state.total = data.total || 0
  state.fetching = undef(data.data)

  return state;
}

const list = {
  state: initState,
  reducers: {
    'api/start': state => ({...state, fetching: true}),
    'api/success': load,
    load,
    /** @param {ListState} state */
    block: state => ({ ...state, manual: false }),
    /** @param {ListState} state */
    unblock: state => ({ ...state, manual: true }),
    /**
     * @param {ListState} state
     * @param {ChangeRowProps & ListState} payload
     */
    changeRow: (state, { searchKey, needle, ...rest }) => {
      const data = getDataByPath(state)
      const index = data.findIndex(row => row[searchKey] === needle);

      data[index] = { ...data[index], ...rest };

      setDataByPath(state, data)

      return state;
    },
    /**
     * @param {ListState} state
     * @param {ChangeRowProps} item
     */
    addRow: (state, item) => {
      const data = [...getDataByPath(state), item]

      setDataByPath(state, data)

      return state;
    },
    /**
     * @param {ListState} state
     * @param {ChangeRowProps} payload
     */
    deleteRow: (state, { searchKey, needle, dataKey }) => {
      const newData = getDataByPath(state).filter(row => {
        if (Array.isArray(needle)) {
          return needle.includes(row[searchKey]);
        }

        return row[searchKey] !== needle;
      });

      setDataByPath(state, newData)

      return state;
    },
    /**
     * @callback Handler
     * @param {ListState} state
     * @param {ChangeRowProps} rest
     *
     * @typedef {{handler: Handler}} HandlerProps
     * @param {ListState} state
     * @param {HandlerProps & ChangeRowProps} payload
     */
    change: (state, { handler, ...rest }) => (isFunction(handler) && handler(state, rest)) || state,
    syncQueueMigrateRows,
    syncQueueEditRows,
    /** @param {ListState} state
     *  @param {string} dataKey
     */
    clear: (state,  dataKey) => {
      delete state.data[dataKey];

      return state;
    },
    replace: load,
  },
  effects: dispatch => ({
    async fetchData({ state, instance }, { list }) {
      if (!list.manual) {
        return;
      }

      const { page, filtered, sorted, pageSize } = state;
      const { pageSize: size, filtered: prevFiltered = [], name } = instance.props;

      const curPage = pageSize !== size || !isEqual(filtered, prevFiltered) ? 1 : page + 1;
      const query = { p: curPage };
      let data = {};

      if (pageSize) {
        query.pageSize = pageSize;
      }

      if (filtered.length) {
        filtered.map(filter => {
          switch (filter.id) {
            case 'city':
              query.region = filter.value;
              break;
            case 'brand':
              query.brand = filter.value;
              break;
            case 'start':
              query.date = filter.value;
              break;
            case 'type':
              query.type = filter.value;
              break;
            default:
              data = { ...data, [filter.id]: filter.value };
              break;
          }
        });
      }

      if (sorted.length) {
        const sort = arrayToObj(sorted, (acc, { id, desc }) => ({ ...acc, [id]: Number(desc) }));
        data = { ...data, sort };
      }

      const route = state.route || '';

      const { success = true, message } = await dispatch.api.get({
        url: route.substring(1),
        query: `?${objToQS(query)}${data ? `&${objToQS(data)}` : ''}`,
        // cache: `${CACHE_NAME}${name ? `-${name}` : ''}`,
      });

      if (!success && message) {
        alert(message);
      }

      this.block();
    },
  }),
  selectors(slice, createSelector, hasProps) {
    return {
      getPages: hasProps(({ listSettings }, tableName) =>
        createSelector(slice, listSettings.getPageSize(tableName), ({ total }, pageSize) =>
          Math.ceil(total / pageSize),
        ),
      ),
      getData: hasProps(
        (models, url) => slice(
          ({ data }) => {
            const tableData = data[url] || data[url.substring(1)]

            return Array.isArray(tableData) ? tableData : []
          }
        )),
      getTotal() {
        return slice(list => list.total)
      },
      isLoading() {
        return slice(list => list.fetching)
      },
      isManual() {
        return slice(list => list.manual)
      },
      getSortedData: hasProps(
        (models, url) =>
          createSelector(
            models.list.getData(url),
            (data) => {
              if (!data) {
                return [];
              }

              if (!data.every(obj => Object.hasOwn(obj, 'availableClient'))) {
                return data;
              }

              const availableCollection = getAvailableCollection(
                data,
                {
                  available: 'availableClient',
                  availableRegional: 'availableMSK1',
                }
              );

              return getGoodsSortByAbc(availableCollection);
            }
          )
      ),
    };
  },
};

export default list;
