import React, { useCallback } from 'react';
import BannerItem from './BannerItem';
import classNames from 'classnames';
import Mixpanel from 'services/mixpanel';
import useRouteConfig from 'hooks/use-route-config';

const Banners = props => {
  const { banners, slide } = props;
  const { name: routeName } = useRouteConfig();

  const track = useCallback(
    id => () => {
      const name = (routeName === 'Маркетинг' && 'СтрГлавнаяСтраница') || `Стр${routeName}`;

      Mixpanel?.track(`${name}.Баннер`, {
        page: name,
        id: id,
      });
    },
    [routeName],
  );
  /**
   * TODO: v2.0 - что за параметр? И как обрабатывать?
   */
  return banners.map(({ id, link, img }, index) => {
    const className = classNames('banner__slide', {
      active: index === slide,
    });

    return (
      <div key={id} className={className}>
        <BannerItem href={link} src={img} track={track(id)} />
      </div>
    );
  });
};

export default Banners;
