import { API_URL } from 'common/config/app';

const currencyApi = {
  'new-currency/settings/get/?([a-zA-Z0-9]+)?$': {
    endpoint: `${API_URL}/settings/currency`,
  },
};

const settingsApi = {
  'new-view/settings/get/?([a-zA-Z0-9]+)?$': {
    endpoint: `${API_URL}/settings`,
  },
};

export const userApi = {
  ...currencyApi,
  ...settingsApi,
};
