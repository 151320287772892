import React, { useMemo } from 'react';
import Dot from './Dot';

const Dots = props => {
  const { length, onSelectSlide, slide } = props;
  const dots = useMemo(() => new Array(length).fill(0), [length]);

  return (
    <div className="banner__dots">
      {dots.map((dot, index) => (
        <Dot key={index} active={index === slide} onSelectSlide={() => onSelectSlide(index)} />
      ))}
    </div>
  );
};

export default Dots;
