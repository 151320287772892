import React from 'react';
import switchStatusColor from 'helpers/switchStatusColor';
import sortStringNumbers from 'helpers/sortStringNumbers';
import TooltipCell from 'components/tooltip-cell';
import passData from './passData';
import { AmountCell, ScheduleCell, TimeCell } from './cells';
import sortStrings from 'helpers/sortStrings';

export default isPickup => [
  {
    Header: 'Информация по заказу',
    headerClassName: 'shipments-list__header',
    columns: [
      {
        Header: 'Планируемая дата отгрузки',
        accessor: 'planingShipmentDate',
      },
      {
        Header: 'Филиал',
        accessor: 'companyUnion',
      },
      {
        Header: '№ заказа',
        accessor: 'orderNo',
      },
      {
        Header: 'Статус обработки',
        accessor: 'processingStatus',
        Cell: row =>
          (row && <div className={switchStatusColor(row.value)}>{row.value}</div>) || null,
      },
      {
        Header: 'Сумма заказа',
        headerStyle: { justifyContent: 'flex-end' },
        accessor: 'amount',
        className: 'rt-td--rigth',
        Cell: AmountCell,
        sortMethod: sortStringNumbers,
      },
      {
        Header: 'Запись на самовывоз',
        show: isPickup,
        Cell: ScheduleCell,
      },
      {
        Header: 'Адрес Доставки',
        accessor: 'shippingAddress',
        show: !isPickup,
        Cell: row =>
          (row && (
            <TooltipCell index={`${row.original.orderNo}0`} tableNo={0}>
              {row.value}
            </TooltipCell>
          )) ||
          null,
      },
      {
        Header: 'Плановое время доставки (от - до)',
        show: !isPickup,
        accessor: 'timePlanBegin',
        Cell: TimeCell,
        sortMethod: sortStrings,
      },
      {
        accessor: 'functionality',
        Header: '',
        width: 0, // Баг - github.com/tannerlinsley/react-table/issues/1111
        show: true,
        className: 'separator_before',
        headerClassName: 'separator_before',
        Cell: () => null,
      },
    ],
  },
  {
    Header: 'Данные для пропуска',
    headerClassName: 'shipments-list__header',
    columns: passData,
  },
];

