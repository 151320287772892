import _ from 'lodash';
import {
  DOCUMENT_IN_PROGRESS,
  DOCUMENT_READY,
  DOCUMENT_WITH_ERROR,
  OPERATION_ACTIVE,
  OPERATION_COMPLETED,
  OPERATION_PREPARATION,
  OPERATION_IN_PROGRESS,
} from '../constants';
import findDetectorStatus from './findDetectorStatus';

const isDocumentInProgress = doc =>
  _.includes([OPERATION_ACTIVE, OPERATION_PREPARATION, OPERATION_IN_PROGRESS], doc.procResult);

const isDocumentReady = doc => _.includes([OPERATION_COMPLETED], doc.procResult);

const isDocumentWithError = () => true;

const detectors = [
  {
    status: DOCUMENT_IN_PROGRESS,
    detect: isDocumentInProgress,
  },
  {
    status: DOCUMENT_READY,
    detect: isDocumentReady,
  },
  {
    status: DOCUMENT_WITH_ERROR,
    detect: isDocumentWithError,
  },
];

export default findDetectorStatus(detectors);
