import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Checkmark = ({
  className,
  color = '#008000',
  name,
  width = '11',
  height = '11',
  viewBox = '0 0 11 11',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M3 5L5 7L8 4" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <rect x="0.5" y="0.5" width="10" height="10" rx="5" stroke={color} />
    </svg>
  );
};

Checkmark.defaultProps = {
  className: '',
  color: '#008000',
  width: '11',
  height: '11',
  viewBox: '0 0 11 11',
};

Checkmark.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
