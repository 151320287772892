import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import { renderPassword } from 'pages/Settings/components/fields';
import { Link } from 'react-router-dom';
import {showErrorMessage} from "pages/Login/components/login-form"


const PasswordForm = props => {
  const { error, submitting, handleSubmit, formData } = props;
  const { firstField, secondField, btnName } = formData;

  const errors = Array.isArray(error)
    ? error.join('<br />')
    : error?.code
        ? showErrorMessage(error)
        : error

  return (
    <form onSubmit={handleSubmit}>
      <div className="personal-container">
        <div className="row">
          <Field
            type="password"
            name={firstField.name}
            label={firstField.label}
            required
            component={renderPassword}
          />
          <Field
            type="password"
            name={secondField.name}
            label={secondField.label}
            required
            component={renderPassword}
          />
        </div>
        <div className="row">
          <div>
            <small>
              <span className="required">*</span> поля обязательные для заполнения
            </small>
          </div>
          <div>
            <small>
              Пароль должен быть не менее 10 символов и содержать латинские буквы и цифры
            </small>
          </div>
          <div className="form-actions">
            <Fragment>
              <Link to="/settings/personal" className="btn btn-danger btn--rect">
                Назад
              </Link>
              <button
                type="submit"
                disabled={submitting}
                className={'btn btn--rect btn-danger'}
              >
                {btnName}
              </button>
            </Fragment>
          </div>
        </div>
      </div>
      {errors && (
        <div className="recoverErrors">
          {errors}
        </div>
      )}
    </form>
  );
};

export default PasswordForm;
