// @ts-nocheck
import { availabilityFilter } from '../constants/availability';
import { staticFilters } from '../constants/static-filters';

export const getFiltersFromGoods = goods => {
  const res = {
    brands: [],
    characteristics: {},
  };

  goods.forEach(({ filterKeys, brand }) => {
    if (!filterKeys || !brand) {
      return;
    }

    for (let i = 0, l = filterKeys.length; i < l; i++) {
      res.characteristics[filterKeys[i]] = 1;
    }

    res.brands.push(brand);
  });

  return res;
};

export function buildFilters(filters) {
  const result = {};

  return actualFilters => {
    Object.values(filters).forEach(filter => {
      if (actualFilters.characteristics[filter.key]) {
        if (!result[filter.id]) {
          result[filter.id] = { ...filter, values: [] };
        }

        const filterValue = {
          id: filter.key,
          propertyId: filter.id,
          measure: filter.measure,
          value: filter.value,
        };

        if (!Number(filter.value) || filter.value === 'нет') {
          result[filter.id].values.unshift(filterValue);
        } else {
          result[filter.id].values.push(filterValue);
        }
      }
    });

    return {
      characteristics: [availabilityFilter, ...staticFilters, ...Object.values(result)],
      brands: actualFilters.brands.sort(),
    };
  };
}
