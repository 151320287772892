import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Async } from 'react-select';

const FilterType = ({ setFilter, style }) => {
  const [type, setType] = useState('');
  const {
    wiki: { getAttachmentsTypes },
  } = useDispatch();

  const handleChange = newValue => {
    setType(newValue);
    setFilter('type', newValue);
  };

  const loadOptions = (input, callback) => {
    getAttachmentsTypes()
      .then(options => callback(null, { options }))
      .catch(err => callback(err, null));
  };

  return (
    <Async
      name="filterType"
      id="filter-type"
      placeholder="Выберите тип"
      noResultsText="Выберите тип"
      loadingPlaceholder="Загружаем тип"
      loadOptions={loadOptions}
      searchable={false}
      onChange={handleChange}
      value={type}
      style={style}
    />
  );
};

export default FilterType;
