import PropTypes from 'prop-types';
import StepsVisual from './index.container';

StepsVisual.propTypes = {
  stepsNumber: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

StepsVisual.defaultProps = {
  stepsNumber: 0,
};

export default StepsVisual;
