import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { OrderEditContext } from 'pages/Orders/components/order-edit/context';
import { setSearchText, setState } from 'pages/Orders/action/state';
import { undef } from 'common/util';
import { isNull } from 'lodash';
import Mixpanel from 'services/mixpanel';
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';
import useContextDispatch from 'hooks/context/useContextDispatch';

const useSelectGroup = () => {
  const { pathname } = useLocation();
  const {
    select: { order },
  } = useStore();
  const { orderNo } = useSelector(order.getHeader);
  const {
    catalog: { getItems },
    demo: { getItems: getDemoItems },
    claims: { getItems: getClaimsItems },
  } = useDispatch();
  const { id } = useParams();
  const stateDispatch = useContextDispatch(OrderEditContext);
  const isPreorder = useIsPreorder();

  const isDemo = /demof[uo]nd/.test(pathname);
  const isClaims = /claims/.test(pathname);

  return useCallback(
    (group, row = {}, level, curOrder = {}) =>
      async e => {
        e?.preventDefault();
        e?.stopPropagation();

        if (isDemo) {
          return await getDemoItems(group);
        }

        if (isClaims) {
          return await getClaimsItems(group);
        }

        const { code1, code2, code3, group1, group2, group3 } = row.original;

        stateDispatch(setSearchText(''));

        const name = (undef(level) && row) || (level === 1 && group2) || `${group2}_${group3}`;

        const orderNo = (isNull(curOrder) && 'NEW') || curOrder?.orderNo || id || orderNo;

        const groups = {
          [code1]: group1,
          [code2]: group2,
        };

        if (level === 0) {
          groups[code3] = group3;
        }

        Mixpanel?.trackCatalog({ ...row.original, level });

        const data = await getItems({ group, groups, row, orderNo, isPreorder });

        stateDispatch(
          setState({
            group: name,
            page: 0,
            searchText: '',
          }),
        );

        return data;
      },
    [orderNo, id, isDemo],
  );
};

export default useSelectGroup;
