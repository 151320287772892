import { createConfirmation } from 'react-confirm';
import Dialog from '../confirm/confirm';

const alert = createConfirmation(Dialog);

export default (confirmation, options = {}) => {
  options = { ...options, isAlert: true };

  return alert({ confirmation, options });
};
