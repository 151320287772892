import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import { RenderCustomerName, RenderINN } from '../../fields';
import CustomDayPickerInput from 'pages/Logistic/components/fields/new-shipment-daypicker';
import { renderText, RenderSelect } from 'components/fields';
import { DEMOFOND_ORDER_FORM, optionsReturnPeriod } from '../../../constants';

const FieldsTest = () => {
  return (
    <Fragment>
      <div className="row">
        <Field
          label="Конечный заказчик"
          name="customerName"
          colSm={8}
          required
          props={{ form: DEMOFOND_ORDER_FORM }}
          component={RenderCustomerName}
        />
        <Field
          name="inn"
          label="ИНН заказчика"
          required
          props={{ form: DEMOFOND_ORDER_FORM }}
          component={RenderINN}
        />
      </div>
      <div className="row">
        <div className="datepicker-block col-sm-3">
          <Field
            name="receiptDate"
            label="Желаемая дата получения"
            currentError={'Дата получения не может быть раньше текущей'}
            required
            component={CustomDayPickerInput}
          />
        </div>
        <div className="datepicker-block col-sm-3">
          <Field
            className="select-date"
            name="returnPeriod"
            label="Срок тестирования"
            placeholder="Выберите..."
            required
            options={optionsReturnPeriod}
            component={RenderSelect}
          />
        </div>
        <Field
          name="purpose"
          label="Цель тестирования"
          colSm={6}
          maxLength={200}
          required
          component={renderText}
        />
      </div>
    </Fragment>
  );
};

export default FieldsTest;
