import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Notice = ({
  className,
  color = '#236192',
  name,
  width = '15',
  height = '17',
  viewBox = '0 0 15 17',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M10 14C10 14.0377 10 14.0755 10 14.1132C10 15.1698 8.9 16 7.5 16C6.1 16 5 15.1698 5 14.1132C5 14.0755 5 14.0377 5 14"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M9 3V2.5C9 1.65 8.35 1 7.5 1C6.65 1 6 1.65 6 2.5V3"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M3.32143 6.96C3.32143 4.76 5.17857 3 7.5 3C9.82143 3 11.6786 4.76 11.6786 6.96V7.752C11.6786 8.94 12.05 10.128 12.7929 11.096L14 12.68C14 12.68 12.4679 14 7.5 14C2.53214 14 1 12.68 1 12.68L2.20714 11.096C2.95 10.128 3.32143 8.94 3.32143 7.752V6.96Z"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

Notice.defaultProps = {
  className: '',
  color: '#236192',
  width: '15',
  height: '17',
  viewBox: '0 0 15 17',
};

Notice.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
