import React from 'react';
import { Link } from 'react-router-dom';
import withApi from 'hoc/withApi';

import { Queue } from 'pages/Queue/components/queue';
import ClaimQueueColumns from '../../common/columns/claim-queue-columns';

const ClaimQueue = () => {
  return (
    <Queue channel="queue-claims" columns={ClaimQueueColumns}>
      <Link to="/logistic/claims/new" className="section-nav">
        <i className="icon icon-create-icon" /> Создать претензию
      </Link>
    </Queue>
  );
};

export default withApi(ClaimQueue);
