import React from 'react';
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';
import useCanEdit from 'pages/new/orders/hooks/use-can-edit';
import { useDesignSelect } from 'hooks/new';

const optionsForDocType = {
  order: [{ value: 1, label: 'Резерв (ЗПр)' }],
  preorder: [],
};

const TypeField = ({ input: { value, onChange, name }, label, className, arrowRenderer, docType }) => {
  // const isPreorder = useIsPreorder();
  const canEdit = useCanEdit();

  const Select = useDesignSelect();

  // const options = isPreorder ? optionsForDocType.preorder : optionsForDocType.order;
  const options = optionsForDocType.order;

  const isDisabled = options.length <= 1 || (!canEdit || docType);

  return (
    <div>
      <label>{label}</label>
      <Select
        className={className}
        name={name}
        value={value || 1}
        options={options}
        onChange={({ value }) => {
          onChange(value);
        }}
        searchable={false}
        clearable={false}
        arrowRenderer={arrowRenderer}
        disabled={isDisabled}
      />
    </div>
  );
};

export default TypeField;
