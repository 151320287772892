/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import { FORMAT } from 'components/constants/date-format';
import validateDate from '../../../../helpers/validateDate';
import alert from '../../../../components/native/alert/function';
import formattingByMask from '../../../../helpers/formattingByMask';
import validateDayPickerInput from '../../../../helpers/validateDayPickerInput';
import moment from 'moment';

const MASK = '##.##.####';
const formattingValue = formattingByMask(MASK);
const validateValue = validateDayPickerInput();

const dayPickerProps = {
  locale: 'ru',
  localeUtils: MomentLocaleUtils,
  disabledDays: { before: new Date() },
};

const CustomDayPickerInput = ({
  value,
  onDateInputChange,
  currentError,
  label,
  disabled,
  required,
}) => {
  const now = value || '';
  const [date, setDate] = useState(value);
  const refDayPicker = useRef();

  useEffect(() => {
    if (!value) {
      setDate(now);
      onDateInputChange(new Date());
    }
    if (date && !validateDate(date)) {
      alert('Введен неправильный формат даты');
      setDate(now);
      onDateInputChange(new Date());
      return;
    }
    if (moment().startOf('day').isAfter(moment(date, FORMAT)) && !disabled) {
      alert(currentError || 'Дата приезда не может быть раньше текущей');
      setDate(now);
      onDateInputChange(new Date());
    }
  }, [date, disabled]);

  useEffect(() => {
    setDate(value);
  }, [value]);

  const showCalendar = () => refDayPicker.current.getInput().focus();

  const handleChange = e => {
    e.persist();
    const { value } = e.target;
    e.target.value = validateValue(value);
  };

  const handleBlur = e => {
    const { value } = e.target;
    const formattedValue = formattingValue(value);
    setDate(formattedValue);
  };

  return (
    <React.Fragment>
      <label className={required ? 'required' : ''}>{label}</label>
      <div className="datepicker-input">
        <DayPickerInput
          ref={refDayPicker}
          value={date}
          format={FORMAT}
          placeholder={FORMAT}
          dayPickerProps={dayPickerProps}
          formatDate={formatDate}
          parseDate={parseDate}
          inputProps={{
            disabled: disabled,
            className: 'form-control form-control-sm datepicker-input--placholder__small',
            onChange: handleChange,
            onBlur: handleBlur,
          }}
          onDayChange={onDateInputChange}
        />
        <i onClick={showCalendar} className="icon icon-calendar" />
      </div>
    </React.Fragment>
  );
};

const DayPickerWrapper = props => {
  const {
    input: { value, onChange },
    label,
    disabled,
    required,
    currentError,
  } = props;

  const handleDayChange = useCallback(selectedDay => {
    const date = formatDate(selectedDay, FORMAT, 'ru');
    onChange(date);
  }, []);

  return (
    <CustomDayPickerInput
      value={value}
      onDateInputChange={handleDayChange}
      label={label}
      disabled={disabled}
      currentError={currentError}
      required={required}
    />
  );
};

export default DayPickerWrapper;
