import {useCallback, useState, useEffect, useRef} from 'react'

/**
 * Hook for listen ref boundaries changes while client resizing
 *
 * @param {any[]} [deps = []] - Additional dependencies on which ref boundaries depends
 * @returns {[ClientRect, React.RefCallback<HTMLElement>]}
 */
const useClientRect = (deps = []) => {
  const [rect, setRect] = useState({});
  const ref = useRef()
  const [clientHeight, setClientHeight] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);

  const setClientRect = useCallback(() => {
    const height =
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    const width =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    setClientHeight(height);
    setClientWidth(width);
  }, []);

  useEffect(() => {
    setClientRect();

    window.addEventListener('resize', setClientRect);

    return () => {
      window.removeEventListener('resize', setClientRect);
    };
  }, [setClientRect]);

  const callbackRef = useCallback(
    node => {
      if (node !== null) {
        if(!ref.current) {
          ref.current = node
        }

        setRect(node.getBoundingClientRect());
      }
    },
    [clientWidth, clientHeight, ...deps],
  );

  // @ts-ignore
  return [rect, callbackRef, ref];
};

export default useClientRect;
