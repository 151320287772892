const switchStatusColor = status => {
  switch (status) {
    case 'Разгружен в ТК':
    case 'Отгружен':
      return 'status--green';

    case 'Выдается':
    case 'Прибыл':
      return 'status--orange';

    case 'План':
      return 'status--orange';

    default:
      return 'status--blue';
  }
};

export default switchStatusColor;
