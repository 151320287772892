import React from 'react';

const Required = ({ text }) => (
  <p className="claims-new__text__footnote">
    <span className="claims-new__text__required">*</span>
    {text}
  </p>
);

export default Required;
