import { getUser } from 'pages/Orders/selectors';
import saveSettings from 'models/effects/saveSettings';
import { proxyArgsToMethods } from 'common/util';
import isEmpty from 'lodash/isEmpty';
import * as selectors from './selectors';

const itemsColumnsState = {
  values: {
    characteristics: false,
    other: false,
    brand1: false,
    vendorId: false,
    id: true,
    image: true,
    orderDate: true,
    brand: true,
    vendorPart: true,
    inventoryMSK1: true,
    availableClient: true,
    warehouseOfficeRG: true,
    availableMSK1: true,
    name: true,
    onlineReserve: true,
    inReserveHours: true,
    qty: true,
    desiredQty: true,
    selfReserve: true,
    price: true,
    desiredPrice: true,
    desiredPriceClient: true,
    desiredPriceRUB: true,
    priceLcyMSK: true,
    priceLcyRG: true,
    priceLCY: true,
    priceClient: true,
    priceOrderRUB: true,
    currencyCode: true,
    minPackaged: false,
    packaged: false,
    salesLimitType: true,
    weight: false,
    volume: false,
    warranty: false,
    availableExpected: true,
    availableExpectedNext: true,
    TCComment: true,
    group1: true,
    group2: false,
    group3: false,
    RRP: false,
    RRPDate: false,
    deliveryDate: true,
    actualyDateFrom: true,
    actualyDateTo: true,
    'Код Валюты': true,
    statuses: true,
    barcodeEAN: false,
  },
  userVisibility: {},
  order: {
    id: 1,
    vendorId: 2,
    image: 3,
    name: 4,
    brand: 5,
    vendorPart: 6,
    inventoryMSK1: 7,
    availableClient: 8,
    warehouseOfficeRG: 9,
    availableMSK1: 10,
    orderDate: 11,
    onlineReserve: 12,
    inReserveHours: 13,
    qty: 14,
    desiredQty: 15,
    selfReserve: 16,
    price: 17,
    desiredPrice: 18,
    desiredPriceClient: 19,
    priceLCY: 20,
    priceClient: 21,
    priceLcyMSK: 22,
    priceLcyRG: 23,
    currencyCode: 24,
    minPackaged: 25,
    salesLimitType: 26,
    weight: 27,
    volume: 28,
    warranty: 29,
    availableExpected: 30,
    availableExpectedNext: 31,
    TCComment: 32,
    group1: 33,
    group2: 34,
    group3: 35,
    RRP: 36,
    RRPDate: 37,
    deliveryDate: 38,
    actualyDateFrom: 39,
    actualyDateTo: 40,
    'Код Валюты': 41,
    statuses: 42,
    barcodeEAN: 43,
    packaged: 44,
  },
  userOrder: {},
  simplified: false,
  toggled: false,
  userWidths: {},
};

const loadColumns = (state, payload) => {
  const {
    values,
    order = state.order,
    simplified = false,
  } = !isEmpty(payload.items) ? payload.items : {};

  const uid = payload.uid || payload.user?.id;

  return {
    ...state,
    values: values || state.values,
    simplified,
    userVisibility: {
      ...state.userVisibility,
      [uid]: {
        values: values || state.values,
        simplified,
      },
    },
    userOrder: {
      ...state.userOrder,
      [uid]: order,
    },
  };
};

const itemsColumns = {
  name: 'itemsColumns',
  state: itemsColumnsState,
  reducers: {
    toggle: (state, payload) => {
      const { simplified = false, userId, ...rest } = payload;

      const values = state.userVisibility[userId]?.values || state.values;

      return {
        ...state,
        simplified,
        userVisibility: {
          ...state.userVisibility,
          [userId]: {
            simplified,
            values: { ...values, ...(rest || {}) },
          },
        },
      };
    },
    setWidths: (state, { userId, ...rest }) => {
      return {
        ...state,
        userWidths: {
          ...state.userWidths,
          [userId]: {
            ...(state.userWidths[userId] || {}),
            ...rest,
          },
        },
      };
    },
    order: (state, { userId, ...rest }) => {
      const newOrder = {
        ...(state.userOrder[userId] || state.order),
        ...rest,
      };

      return {
        ...state,
        order: newOrder,
        userOrder: {
          ...state.userOrder,
          [userId]: newOrder,
        },
      };
    },
    'user/loadSettings': loadColumns,
  },
  effects: dispatch => ({
    async visibilityPersist(payload, state) {
      const { simplified = false, ...rest } = payload;
      const user = getUser(state);

      this.toggle({ userId: user.id, ...payload });

      const items = { simplified };

      if (!isEmpty(rest)) {
        items.values = {
          ...state.itemsColumns.values,
          ...rest,
        };
      }

      await saveSettings({ items })(dispatch);
    },
    async setWidthsPersist(payload, state) {
      const user = getUser(state);
      this.setWidths({ userId: user.id, ...payload });
    },
    async orderPersist(payload, state) {
      const user = getUser(state);
      this.order({ userId: user.id, ...payload });

      await saveSettings({
        items: {
          order: {
            ...state.itemsColumns.order,
            ...payload,
          },
        },
      })(dispatch);
    },
    async save(payload, { itemColumns }) {
      const { simplified = false, ...rest } = payload;

      await saveSettings({
        items: {
          values: {
            ...itemColumns.values,
            ...rest,
          },
          simplified,
        },
      })(dispatch);
    },
  }),
  selectors(...args) {
    return proxyArgsToMethods(selectors, args);
  },
};

export default itemsColumns;
