import { FILTER_BY_TEXT_INPUT, DEFAULT_TEXT_INPUT_VALUE } from '../../constants';

const ENTER_KEY = 13;

const curryAddNameFilterGroup = filterGroups => {
  const handleKeyDown = event => {
    if (event.keyCode === ENTER_KEY) {
      event.preventDefault();
    }
  };

  const nameFilterGroup = {
    name: 'Наименование',
    key: 'name',
    type: FILTER_BY_TEXT_INPUT,
    filters: [
      {
        key: 'name',
        name: 'name',
        placeholder: 'Пример: Hitach*80M*7200',
        value: DEFAULT_TEXT_INPUT_VALUE,
        defaultValue: DEFAULT_TEXT_INPUT_VALUE,
        onKeyDown: handleKeyDown,
      },
    ],
  };

  return [nameFilterGroup, ...filterGroups];
};

export default curryAddNameFilterGroup;
