import { getOrder } from 'pages/Orders/selectors';
import { preparedData } from 'pages/Orders/components/order-edit/helpers/prepareData';
import alert from 'components/native/alert/function';
import isEmpty from 'lodash/isEmpty';
import { getTableListData } from 'common/selectors';
import defaultNotifier from 'services/notifier';
import { selector } from 'client/store';
import history from "common/history"

export default dispatch =>
  async (
    { isCard, orderNo, tableName = history.location.pathname, notifier = defaultNotifier },
    state,
  ) => {
    const order = getOrder(state);
    const id = orderNo || order.orderNo;
    const { list } = selector

    if (!isCard) {
      const items = list.getData(tableName)(state);

      const ids = items.map(({ id }) => id);
      /**
       *
       * send only present items to add,
       * cause data may contain previous items set
       *
       */

      for (const key in preparedData.get('qtys')) {
        if (ids.indexOf(key) < 0) {
          preparedData.delete('qtys', key);
        }
      }
    }

    if (isEmpty(preparedData.get('qtys'))) {
      return false;
    }

    if (!id) {
      await alert('Создайте заказ для добавления в него товара!');
      return dispatch.order.toggleCreateForm();
    }

    notifier.show(
      id,
      isCard ? `Товар добавляется в заказ ${order.orderNo}` : 'Изменения применяются',
    );

    await dispatch.api.post({
      url: `/orders/edit/rows/${id}`,
      data: preparedData.get(),
    });
  };
