import React, { forwardRef } from 'react'
import styles from './button.module.scss'
import cn from 'classnames'

function Group({children, className}, ref) {
  return (
    <div
      className={cn(styles['btn-group'], className)}
      ref={ref}
    >
      {children}
    </div>
  )
}

export default forwardRef(Group)
