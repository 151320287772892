import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './textarea.module.scss';

/**
 * Renders a <Textarea /> component
 * @component
 * @example
 *
 * return (
 *   <Textarea />
 * )
 */
export const Textarea = forwardRef(
  (
    {
      className,
      blurOnChange,
      disabled,
      hasError,
      name,
      rows,
      tabIndex,
      onBlur,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const classes = cn(styles['textarea'], className, {
      [styles['textarea--error']]: hasError,
    });

    const handleChange = event => {
      onChange(event?.target.value);

      if (blurOnChange) {
        onBlur(event?.target.value);
      }
    };

    const handleBlur = event => {
      onBlur(event.target.value);
    };

    return (
      <textarea
        {...rest}
        className={classes}
        data-testid="textarea"
        disabled={disabled}
        name={name}
        ref={ref}
        rows={rows}
        tabIndex={disabled ? -1 : tabIndex}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    );
  },
);

Textarea.displayName = 'Textarea';

Textarea.defaultProps = {
  className: '',
  blurOnChange: false,
  disabled: false,
  id: null,
  hasError: false,
  maxLength: Infinity,
  name: '',
  placeholder: '',
  readOnly: false,
  required: false,
  rows: 3,
  tabIndex: 0,
  value: '',
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onPaste: () => {},
};

Textarea.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * blur on change
   */
  blurOnChange: PropTypes.bool,
  /**
   * Visually and functionally disable the textarea.
   */
  disabled: PropTypes.bool,
  /**
   * Adds a HTML `id` attribute to the textarea. This is used for linking the HTML with a label
   */
  id: PropTypes.string,
  /**
   * Makes the textarea border color red.
   */
  hasError: PropTypes.bool,
  /**
   * The maximum number of characters that a user can enter. `onChange` will not fire if a user
   * enters a character that exceeds `maxLength`.
   */
  maxLength: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  /**
   * Adds `name` HTML attribute to element, indicating the property name associated with the textarea.
   */
  name: PropTypes.string,
  /**
   * Text that appears within the textarea when there is no `value`.
   */
  placeholder: PropTypes.string,
  /**
   * Adds `readonly` HTML attribute, allowing users to select (but not modify) the input.
   */
  readOnly: PropTypes.bool,
  /**
   * Adds the `required` HTML attribute to the textarea.
   */
  required: PropTypes.bool,
  /**
   * Textarea row height.
   */
  rows: PropTypes.number,
  /**
   * Accessible order
   */
  tabIndex: PropTypes.number,
  /**
   * The current value of the textarea.
   */
  value: PropTypes.string,
  /**
   * Fires when the textarea loses focus.
   */
  onBlur: PropTypes.func,
  /**
   * The function that is called when the textarea value changes.
   *
   * It receives two arguments: `onChange(value)`.
   *
   * The consumer of this component should use that data to update the `value` prop passed in to
   * this component.
   */
  onChange: PropTypes.func,
  /**
   * Fires when the textarea receives focus.
   */
  onFocus: PropTypes.func,
  /**
   * Fires when the textarea receives text that was copied and pasted.
   */
  onPaste: PropTypes.func,
};
