import React, { useState } from 'react';
import { isDisabled as hasDocument } from 'pages/Finances/util/helper';
import Select from 'react-select';
import { DOC_TYPES_BASE, QUEUE_ACCOUNTED } from 'common/config';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import alert from 'components/native/alert/function';

const AccountedExport = ({ type, orderNo }) => {
  const [documentEmail, setDocumentEmail] = useState('');
  const [documentType, setDocumentType] = useState(1);
  const [documentFormat, setDocumentFormat] = useState('1');

  const history = useHistory();

  const {
    accounted: { exportDocument },
  } = useDispatch();

  const loading = useSelector(getEffectLoading('accounted', 'exportDocument'));
  const options = useSelector(state => state.accounted.export);

  if (Number(type) !== 2) {
    return null;
  }

  const handleExport = async () => {
    const { success, operation, message } = await exportDocument({
      type: documentType,
      format: documentFormat,
      email: documentEmail,
    });

    if (success) {
      history.push({
        pathname: `/queue/${orderNo}`,
        search: `?operation_no=${operation}&queueType=QUEUE_ACCOUNTED`,
        state: {
          queueType: QUEUE_ACCOUNTED,
          operation: operation,
        },
      });
    } else {
      alert(message);
    }
  };

  const optionsForDocumentFormat = hasDocument(documentType)
    ? [{ value: 2, label: 'PDF' }]
    : [
        { value: 1, label: 'XLS' },
        { value: 2, label: 'PDF' },
      ];

  return (
    <div
      className={classNames(
        'request-document',
        'request-document--accounted',
        'col-sm',
        'row',
        'flex-nowrap',
        'align-items-center',
        'justify-content-end',
      )}
    >
      <label>Запрос документа</label>
      <Select
        name="document-type"
        title="Тип документа"
        style={{ width: 180 }}
        value={documentType}
        searchable={false}
        clearable={false}
        options={options}
        onChange={newValue => {
          setDocumentType(newValue.value);
          setDocumentFormat(DOC_TYPES_BASE.includes(newValue.value) ? '2' : '1');
        }}
      />
      <Select
        name="document-format"
        title="Формат файла"
        style={{ width: 80 }}
        value={documentFormat}
        searchable={false}
        clearable={false}
        options={optionsForDocumentFormat}
        onChange={newValue => setDocumentFormat(newValue.value)}
      />
      <input
        className="email-checkbox"
        id="get-by-mail"
        checked={documentEmail}
        onChange={e => setDocumentEmail(e.target.checked ? 'on' : '')}
        type="checkbox"
        name="email"
      />
      <label className="email-checkbox" htmlFor="get-by-mail">
        Получить&nbsp;по&nbsp;почте
      </label>
      <button className="btn btn-danger btn--rect" onClick={handleExport} disabled={loading}>
        Продолжить
      </button>
    </div>
  );
};

export default AccountedExport;
