import React, { useMemo } from 'react';
import Select from 'react-select';
import { SET_FILTER_CONTACT_VALUE } from 'pages/Orders/action';
import useContextSelector from 'hooks/context/useContextSelector';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { OrderFiltersContext } from 'pages/Orders/constances';
import useListData from 'components/table/hooks/useListData';

const OrderContactFilter = () => {
  const orders = useListData();
  const dispatch = useContextDispatch(OrderFiltersContext);
  const filterContactValue = useContextSelector(
    OrderFiltersContext,
    state => state.filterContactValue,
  );

  const createFilterContactOptions = useMemo(() => {
    const contacts = orders.map(order => order.contact);
    let filteredContacts = [{ label: 'Любой', value: '' }];

    new Set(contacts).forEach(contact =>
      filteredContacts.push({
        label: contact,
        value: contact,
      }),
    );

    return filteredContacts;
  }, [orders.length]); // eslint-disable-line

  return (
    <div className="flex-nowrap align-items-center order-filters">
      <div className="form-control__wrap">
        <label htmlFor="filter-period">Контакт</label>
        <Select
          name="filterContact"
          id="filter-contact"
          searchable={false}
          clearable={false}
          value={filterContactValue}
          options={createFilterContactOptions}
          onChange={filterContactOption => {
            dispatch({
              type: SET_FILTER_CONTACT_VALUE,
              payload: filterContactOption.value,
            });
          }}
        />
      </div>
    </div>
  );
};

export default OrderContactFilter;
