import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCorrespondence, isLinkedRemoving } from 'pages/Settings/selectors';
import isEmpty from 'lodash/isEmpty';

export default row => {
  if (!row) return null;

  const choosed = useSelector(state => state.bindings.choosed);
  const correspondence = useSelector(getCorrespondence);
  const fetchingRemove = useSelector(state => isLinkedRemoving(state)(row.index));
  const showCurrent = useSelector(state => state.bindings.showCurrent);

  const {
    bindings: { removeLink },
  } = useDispatch();

  if (isEmpty(choosed)) return null;

  const handleRemoveLink = async () => {
    const no = choosed.no;
    await removeLink({ no, row });
  };

  return !!correspondence.length && !showCurrent ? (
    <input type="checkbox" name="correspondence[]" value={row.original.no} />
  ) : fetchingRemove ? (
    <i className="loader loader--small" />
  ) : (
    <i className="icon icon-delete enabled" title="Удалить" onClick={handleRemoveLink} />
  );
};
