export default values => {
  const errors = {};
  if (!values.substFio) {
    errors.substFio = 'Заполните поле';
  }
  if (!values.substEmail) {
    errors.substEmail = 'Заполните поле';
  }
  if (!/^\w+([.-]?\w+)*@\w+([.]?\w+)*(\.\w{2,3})+$/.test(values.substEmail)) {
    errors.substEmail = 'Неверно введен e-mail';
  }
  return errors;
};
