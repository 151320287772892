import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getSimpleView } from '../../selectors';
import ym from 'react-yandex-metrika';
import { isDevEnv } from 'common/config';

const SimpleViewSwitcher = props => {
  const { dispatch, simpleView } = props;

  const toggleSimplifiedView = e => {
    const checked = e.target.checked;

    !isDevEnv && ym('reachGoal', (checked && 'SIMPLE_VIEW') || 'STANDARD_VIEW');

    return dispatch.itemsColumns.visibilityPersist({ simplified: checked });
  };

  return (
    <span className={classNames('ws-switcher-container', 'ws-switcher-container__simple')}>
      <input
        type="checkbox"
        id="ws-switcher"
        checked={simpleView}
        onChange={toggleSimplifiedView}
      />
      <label htmlFor="ws-switcher">&nbsp;</label>
      &nbsp;Упрощенный вид
    </span>
  );
};

export default connect(state => ({
  simpleView: getSimpleView(state),
}))(SimpleViewSwitcher);
