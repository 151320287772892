import { useEffect, useRef } from 'react';

const useUpdateEffect = (callback, deps = []) => {
  const isFirst = useRef(true);

  useEffect(() => {
    if (!isFirst.current) {
      callback();
    }

    isFirst.current = false;
  }, deps);
};

export default useUpdateEffect;
