import { getOrder, getGoodsToFind, getOrders } from 'pages/Orders/selectors';
import { CACHE_NAME } from 'common/config';

export { default as addToOrder } from './addToOrder';

export const getAvailability =
  ({ api }) =>
  async (id, state) => {
    const { orderNo = 'NEW' } = getOrder(state);

    const { data } = await api.get({
      url: `products/availability/${id}`,
      query: `?order=${orderNo}`,
    });

    return data?.[0] || {};
  };

export const getImages =
  ({ api }) =>
  async id => {
    const { data } = await api.get({
      url: `products/images/${id}`,
      cache: CACHE_NAME,
    });

    return data.data;
  };

export const getProperties =
  ({ api }) =>
  async id => {
    const { data } = await api.get({
      url: `products/properties/${id}`,
      cache: CACHE_NAME,
    });

    return data.data;
  };

export const getRelated =
  ({ api }) =>
  async (id, state) => {
    const { orderNo = 'NEW' } = getOrder(state);

    const { data } = await api.get({
      url: `products/related/${id}`,
      query: `?order=${orderNo}`,
    });

    return data;
  };

export const getAnalogs =
  ({ api }) =>
  async (id, state) => {
    const { orderNo = 'NEW' } = getOrder(state);

    const { data } = await api.get({
      url: `products/analog/${id}`,
      query: `?order=${orderNo}`,
    });

    return data;
  };

export const getItemsForProduct =
  ({ api }) =>
  async (id, state) => {
    const itemList = getGoodsToFind(state);
    let orders = getOrders(state);

    const query = orders.map(({ orderNo }) => `orders[]=${orderNo}`).join('&');

    const { data } = await api.post({
      url: `items/relate`,
      query: `?${query}`,
      data: { itemList: [...itemList, { RelationType: 'main', LinkedItemNo: id }] },
    });

    return data;
  };

