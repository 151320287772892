import React, { useCallback } from 'react';
import { SET_SEARCH_ORDER_VALUE } from 'pages/Orders/action';
import { useDispatch } from 'react-redux';
import useContextSelector from 'hooks/context/useContextSelector';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { OrderFiltersContext } from 'pages/Orders/constances';

const OrderCodeOrPartFilter = () => {
  const {
    order: { searchByItem },
  } = useDispatch();

  const searchOrderValue = useContextSelector(OrderFiltersContext, state => state.searchOrderValue);
  const dispatch = useContextDispatch(OrderFiltersContext);

  const onSearchOrderClearClick = useCallback(
    async event => {
      event.preventDefault();
      if (!searchOrderValue?.length) return null;

      dispatch({
        type: SET_SEARCH_ORDER_VALUE,
        payload: '',
      });
      await searchByItem('');
    },
    [searchOrderValue],
  ); // eslint-disable-line

  const onSearchOrderFindClick = useCallback(async () => {
    if (!searchOrderValue) return null;
    await searchByItem(searchOrderValue);
  }, [searchOrderValue]); // eslint-disable-line

  return (
    <div className="order-search">
      <div className="form-control__wrap">
        <label htmlFor="">Поиск товара в заказах</label>
        <input
          type="text"
          name="searchOrder"
          placeholder="Код или Part#"
          className="form-control form-control-sm search-order"
          value={searchOrderValue}
          onChange={({ target: { value } }) =>
            dispatch({
              type: SET_SEARCH_ORDER_VALUE,
              payload: value,
            })
          }
        />
      </div>
      <button className="btn btn-outline-danger btn--rect" onClick={onSearchOrderFindClick}>
        Найти
      </button>
      <a href="" className="clear-filters" onClick={onSearchOrderClearClick}>
        <i className="icon icon-remove-filters" title="Очистить фильтр" />
      </a>
    </div>
  );
};

export default OrderCodeOrPartFilter;
