import React from 'react';
import { reserveIcon } from '../../../helpers';

const onlineReserveColumn = row => {
  if (!row) {
    return null;
  }

  const cancelReserveCostHr = parseFloat(row.value);
  return (
    <>
      {reserveIcon(row.original.onlineReserve)}&nbsp;
      {cancelReserveCostHr > 0 ? cancelReserveCostHr : null}
    </>
  );
};

export default onlineReserveColumn;
