import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const UsdOutlined = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '-1 -1 20 20',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M8.4532 13.7289C7.91133 13.7193 7.42857 13.6711 7.00493 13.5844C6.59113 13.5074 6.25616 13.4015 6 13.2667L6.39901 12.2122C6.59606 12.3278 6.86207 12.4337 7.19704 12.53C7.54187 12.6263 7.96059 12.6793 8.4532 12.6889V8.78889C8.15764 8.64444 7.86207 8.49037 7.5665 8.32667C7.28079 8.16296 7.02463 7.97037 6.79803 7.74889C6.57143 7.51778 6.38916 7.24815 6.25123 6.94C6.1133 6.63185 6.04433 6.26593 6.04433 5.84222C6.04433 5.14889 6.24138 4.58074 6.63547 4.13778C7.03941 3.69481 7.64532 3.42037 8.4532 3.31444V2H9.45813V3.27111C9.96059 3.29037 10.3695 3.33852 10.6847 3.41556C11.0099 3.48296 11.3005 3.57444 11.5567 3.69L11.202 4.70111C11.0049 4.60481 10.7685 4.52296 10.4926 4.45555C10.2167 4.37852 9.87192 4.33037 9.45813 4.31111V7.87889C9.76355 8.03296 10.069 8.20148 10.3744 8.38444C10.6798 8.55778 10.9507 8.76 11.1872 8.99111C11.4335 9.22222 11.6305 9.49185 11.7783 9.8C11.9261 10.1081 12 10.4693 12 10.8833C12 11.6344 11.7833 12.2556 11.3498 12.7467C10.9261 13.2281 10.2956 13.5363 9.45813 13.6711V15H8.4532V13.7289ZM10.7734 10.9844C10.7734 10.7533 10.7291 10.5463 10.6404 10.3633C10.5616 10.1804 10.4532 10.0167 10.3153 9.87222C10.1773 9.72778 10.0148 9.59778 9.82759 9.48222C9.64039 9.36667 9.43842 9.25592 9.22167 9.15V12.66C9.67488 12.5926 10.0443 12.4241 10.33 12.1544C10.6256 11.8752 10.7734 11.4852 10.7734 10.9844ZM7.27094 5.77C7.27094 6.18407 7.40394 6.52111 7.66995 6.78111C7.94581 7.04111 8.28571 7.27704 8.68966 7.48889V4.32555C8.16749 4.38333 7.79803 4.55185 7.58128 4.83111C7.37438 5.11037 7.27094 5.42333 7.27094 5.77Z"
      fill={color}
    />
    <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" stroke={color} />
  </svg>
);

UsdOutlined.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '-1 -1 20 20',
};

UsdOutlined.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
