import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Wait = ({
  className,
  color = '#ffa900',
  name,
  width = '12',
  height = '18',
  viewBox = '0 0 12 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect x="0.5" y="0.5" width="11" height="2" rx="1" stroke={color} />
      <rect x="0.5" y="15.5" width="11" height="2" rx="1" stroke={color} />
      <path
        d="M10.5 3V2.5H10H2H1.5V3C1.5 4.69096 2.35749 5.81037 3.12037 6.7004C3.20268 6.79643 3.28276 6.88856 3.3603 6.97777C3.65337 7.31497 3.91026 7.61054 4.11522 7.91798C4.36542 8.29328 4.5 8.6342 4.5 9.00001C4.5 9.36582 4.36542 9.70674 4.11523 10.082C3.91027 10.3895 3.65337 10.685 3.3603 11.0222C3.28276 11.1114 3.20269 11.2036 3.12037 11.2996C2.35749 12.1896 1.5 13.309 1.5 15V15.5H2H10H10.5V15C10.5 13.2955 9.62725 12.2965 8.8674 11.4734C8.84575 11.4499 8.82424 11.4266 8.80288 11.4035C8.05594 10.5951 7.5 9.99346 7.5 9.00001C7.5 8.6342 7.63458 8.29328 7.88478 7.91798C8.08973 7.61054 8.34663 7.31497 8.6397 6.97777C8.71724 6.88856 8.79731 6.79643 8.87963 6.7004C9.64251 5.81037 10.5 4.69096 10.5 3Z"
        stroke={color}
      />
    </svg>
  );
};

Wait.defaultProps = {
  className: '',
  color: '#ffa900',
  width: '12',
  height: '18',
  viewBox: '0 0 12 18',
};

Wait.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
