/* eslint-disable no-console */
import { compose } from 'redux';
import { connect } from 'react-redux';
import withMemo from 'hoc/withMemo';
import { getOrderSettings, getUser, getShipmentMethods } from '../selectors';
import OrderHeader from '../components/order-header';

const mapStateToProps = state => ({
  ...getOrderSettings(state),
  user: getUser(state),
  headerFormScenario: 'edit',
  isHeaderModalOpened: state.order.formOpened,
  shipmentMethodRows: getShipmentMethods(state),
});

export default compose(connect(mapStateToProps), withMemo())(OrderHeader);
