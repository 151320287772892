import React from 'react';

export const CustomInput = ({ input, name, disabled, placeholder }) => (
  <input
    {...input}
    name={name}
    value={input.value}
    type="text"
    className="form-control"
    placeholder={placeholder}
    disabled={!!disabled}
  />
);
