import React from 'react'
import {useSelector} from "react-redux"
import {getEffectLoading} from "common/selectors"
import classNames from "classnames"
import {isCactus} from "common/config"

const LoadingBar = () => {
  const isFetching = useSelector(getEffectLoading('api', 'fetchRoute'))

  const loadingInnerClass = classNames('inner', {
    'inner--active': isFetching,
    'inner--cactus': isCactus
  });

  return (
    <div className="loading-bar-container">
      <div className={'loading-bar-container'}>
        <div className={loadingInnerClass} />
      </div>
    </div>
  )
}

export default LoadingBar

