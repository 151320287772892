// @ts-nocheck
import isEmpty from 'lodash/isEmpty';
import { proxyArgsToMethods } from 'common/util';
import alert from 'components/native/alert/function';
import { getUser, isItemsVisibilitySaved, isOrdersVisibilitySaved } from 'pages/Orders/selectors';
import createContactsSections from 'pages/Help/helpers/createContactsSections';

import * as effects from './effects';
import { formatExchangeRate } from '../utils';
import {getEffectLoading} from "common/selectors"

const loadState = (state, { user, balance, confirmed }) => ({
  ...state,
  info: user,
  balance,
  confirmed,
});

export default {
  name: 'newUser',
  state: {
    info: {},
    balance: {},
    currency: {
      info: '',
      rate: '',
    },
    contacts: {},
    orderSettings: null,
    confirmed: null,
  },
  reducers: {
    login: loadState,
    refresh: loadState,
    setOrderSettings: (state, payload) => ({ ...state, orderSettings: payload.orderSettings }),
    setCurrency: (state, payload) => ({ ...state, currency: payload.currency || {} }),
    setConfirmation: (state, payload) => ({ ...state, confirmed: payload }),
    loadSettings: (state, payload) => ({ ...state, ...payload }),
    loadContacts: (state, payload) => ({ ...state, contacts: payload }),
  },

  effects(dispatch) {
    const type = this.name;
    const { api } = dispatch;

    return {
      ...proxyArgsToMethods(effects, [dispatch, type]),
      async checkPresence(_, state) {
        const user = getUser(state);

        if (isEmpty(user)) {
          const payload = await api.get({ url: '/user' });

          this.refresh(payload);
        }
      },
      async checkCurrency(_, state) {
        if (!isEmpty(state.user.currency)) {
          return;
        }

        const { id } = getUser(state);
        const payload = await api.get({
          url: `currency/settings/get${id ? `/${id}` : ''}`,
        });

        this.setCurrency(payload);
      },
      async checkOrderSettings(_, state) {
        if (!isEmpty(state.user.orderSettings)) {
          return;
        }

        const { id } = getUser(state);
        const payload = await api.get({
          url: `orders/settings/get${id ? `/${id}` : ''}`,
        });

        this.setOrderSettings(payload);
      },
      async checkViewSettings(id, state) {
        if(getEffectLoading('newUser', 'checkViewSettings')(state)){
          return;
        }

        const uid = id || getUser(state).id;

        const isEmptySettings =
          !isItemsVisibilitySaved(state)(uid) || !isOrdersVisibilitySaved(state)(uid);

        if (!isEmptySettings) {
          return;
        }

        const payload = await api.get({
          url: `view/settings/get${uid ? `/${uid}` : ''}`,
        });

        this.loadSettings(payload);
      },
      async getManagerCategories() {
        return await api.get({
          url: 'help/contacts/categories',
        });
      },
      async getUserContacts() {
        try {
          const contacts = await api.get({
            url: 'help/contacts',
          });

          this.loadContacts(contacts);
        } catch (err) {}
      },
      async getCategoryProducts(cat) {
        try {
          const { success, data, message } = await api.get({
            url: `help/contacts/managers${cat && `/${cat}`}`,
          });

          if (success) {
            return data.data;
          }

          alert(message);
        } catch (err) {}
      },
    };
  },
  selectors: (slice, createSelector) => ({
    getCurrency() {
      return slice(user => formatExchangeRate(user?.currency || {}));
    },
    getCurrencyCode() {
      return createSelector(this.getCurrency, ({ Code }) => Code || 'USD');
    },
    getOrderSettings() {
      return slice(user => user.orderSettings);
    },
    getInfo() {
      return slice(user => user.info);
    },
    getContacts() {
      return slice(user => user.contacts);
    },
    getDecoratedContacts() {
      return createSelector(this.getContacts, contacts => createContactsSections(contacts));
    },
    getIsAllowPrinting() {
      return createSelector(this.getInfo, user => Number(user['Ban Printing Documents']) !== 1);
    },
  }),
};
