import React, { Fragment, useMemo } from 'react';
import InfoItem from 'components/info-item';
import colorStatusShipmentitem from '../helpers/color-status-shipment-item';

const ShipmentInfo = props => {
  const { info, orderNo } = props;
  const statusColor = useMemo(() => {
    return colorStatusShipmentitem(info['sign']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.sign]);

  const newInfo = {
    ...info,
    processingStatus: null,
    statusTime: null,
  };
  const isShow = Object.values(newInfo).some(elem => !!elem);

  return (
    <Fragment>
      {isShow && (
        <div className="actions-container">
          <div className="info-item__container">
            <InfoItem label="Kод" text={info['no']} />
            <InfoItem label="Тип" text={info['type']} />
            <InfoItem label="Дата" text={info['date']} />
            <InfoItem label="Инициатор" text={info['initiatorsDetails']} />
            <InfoItem label="Метод отгрузки" text={info['shipmentMethodCode']} />
            <InfoItem label="Статус" text={info['sign']} className={statusColor} />
            <InfoItem label="Дата отгрузки" text={info['shipmentsDate']} />
            <InfoItem label="Время отгрузки" text={info['shipmentsTime']} />
            <InfoItem label="Вес" text={info['weight']} />
            <InfoItem label="Объем" text={parseFloat(info['volume']).toFixed(3)} />
            <InfoItem label="Сумма" text={info['sum']} />
          </div>
        </div>
      )}
      <div className="actions-container">
        <div className="info-item__container">
          <InfoItem label="№ заказа" text={orderNo} />
          <InfoItem label="Статус Обработки" text={info['processingStatus']} />
          <InfoItem label="Статус Время" text={info['statusTime']} />
        </div>
      </div>
    </Fragment>
  );
};

export default ShipmentInfo;
