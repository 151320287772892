import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasAccess } from 'common/util';
import { getUser } from 'pages/Orders/selectors';

const EnabledSignatureButton = props => {
  const { row, shipDate, currentDate, isListView } = props;
  const user = useSelector(getUser);
  const {
    order: { handleSign },
  } = useDispatch();

  const hasAutoSignAccess = hasAccess(user, 'ZPR_SIGN');
  const title = hasAutoSignAccess ? 'Подписать на отгрузку' : 'Поставить подпись клиента';
  const hasPrice = parseInt(row.price) || parseInt(row.priceRUB);
  const hasDocType = Number(row.docType) !== 1;

  if (
    row.reserveOrOrder !== 'РЕЗЕРВ' ||
    shipDate < currentDate ||
    (hasAutoSignAccess && (!hasPrice || hasDocType))
  ) {
    return <i className="icon icon-sign-dis" title={title} />;
  }

  return (
    <i
      className="icon icon-sign enabled"
      title={title}
      onClick={() => handleSign({ id: row.orderNo, isList: isListView, auto: hasAutoSignAccess })}
    />
  );
};

export default EnabledSignatureButton;
