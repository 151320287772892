export const DOWNLOAD_FILES_EXISTS = 'DOWNLOAD_FILES_EXISTS';
export const DOWNLOAD_FILES_NOT_EXISTS = 'DOWNLOAD_FILES_NOT_EXISTS';
export const DOCUMENT_WITH_ERROR = 'DOCUMENT_WITH_ERROR';
export const DOCUMENT_IN_PROGRESS = 'DOCUMENT_IN_PROGRESS';
export const DOCUMENT_READY = 'DOCUMENT_READY';
export const OPERATION_ACTIVE = '0';
export const OPERATION_COMPLETED = '1';
export const OPERATION_ERROR = '2';
export const OPERATION_CANCELLED = '3';
export const OPERATION_PREPARATION = '4';
export const OPERATION_IN_PROGRESS = '5';
