import sortStringNumbers from 'helpers/sortStringNumbers';

const shipmentItemsColumn = volume => [
  {
    Header: 'Номер',
    accessor: 'id',
    width: 130,
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Наименование',
    accessor: 'name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
  },
  {
    Header: () => `Объем = ${volume}`,
    accessor: row => parseFloat(row.volume).toFixed(3),
    id: 'volume',
    width: 215,
  },
  {
    Header: 'Кол.',
    accessor: 'qty',
    width: 80,
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Цена',
    accessor: 'price',
    width: 140,
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Сумма',
    accessor: 'amount',
    width: 160,
    sortMethod: sortStringNumbers,
  },
];

export default shipmentItemsColumn;
