import alert from 'components/native/alert/function';
import { formattingDays, getActiveFormat } from '../helpers/util';

export default {
  name: 'setup',
  state: {
    permissions: [],
    priceName: '',
    priceDays: [],
    priceFormat: 1,
    login: '',
    sendPrice: 1,
  },
  reducers: {
    load: (state, { info, permissions, priceName }) => ({
      ...state,
      priceName,
      permissions,
      priceDays: info.priceDays,
      priceFormat: Number(info.priceFormat),
      login: info.login,
      sendPrice: Number(info.sendPrice),
    }),
  },
  effects: dispatch => ({
    async onSubmitPerms(data, { setup }) {
      const { message } = await dispatch.api.post({
        url: `/settings/permissions/save`,
        query: `?login=${setup.login}`,
        data,
      });
      alert(message);
    },
    async onSubmitDispatch(data, { setup }) {
      const { message } = await dispatch.api.post({
        url: `/settings/dispatch/save`,
        query: `?login=${setup.login}`,
        data,
      });
      alert(message);
    },
  }),
  selectors: slice => ({
    getLogin() {
      return slice(({ login }) => login);
    },
    getPriceDays() {
      return slice(({ priceDays }) => priceDays);
    },
    getPriceFormat() {
      return slice(({ priceFormat }) => priceFormat);
    },
    getSendPrice() {
      return slice(({ sendPrice }) => sendPrice);
    },
    getPermissions() {
      return slice(({ permissions }) => permissions);
    },
    getInitValuesPermissionsForm() {
      return slice(({ permissions }) =>
        permissions.reduce(
          (obj, elem) => ({
            ...obj,
            [elem['Но_']]: elem['Доступ'],
          }),
          {},
        ),
      );
    },
    getDays() {
      return slice(({ priceDays }) => formattingDays(priceDays));
    },
    getActiveFormat() {
      return slice(({ priceFormat, sendPrice }) => getActiveFormat(priceFormat, sendPrice));
    },
  }),
};
