/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Icon } from 'common/ui/icon';

export const createOrderContent = (blankedOrderNo, reserve) => {
  let contentOrder = [];

  if (String(blankedOrderNo).trim()) {
    contentOrder = [...contentOrder, `ОЗПр ${blankedOrderNo}`];
  }
  if (Number(reserve) === 3) {
    contentOrder = [...contentOrder, <Icon name="flash" />];
  }

  if (Number(reserve) === 2) {
    contentOrder = [...contentOrder, <Icon name="flash" color="brand-red" />];
  }

  return contentOrder;
};
