import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-v6';
import isEmpty  from 'lodash/isEmpty';
import classNames from 'classnames';
import orderFromTransitColumns from '../common/columns/orderFromTransitColumns';
import alert from 'components/native/alert/function';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import { transitItems } from 'pages/Orders/constances';

const loadingText = () => <i className="loader loader--ripple" />;

const OrderFromTransit = ({ closeModal }) => {
  const {
    order: { getTransitItems, createFromTransit },
  } = useDispatch();
  const fetching = useSelector(getEffectLoading('order', 'getTransitItems'));
  const [items, setItems] = useState([]);

  const buttonRef = useRef();

  const itemsStr = useMemo(() => JSON.stringify(items.map(item => item.id)), [items]);
  const inputRefs = useMemo(() => Array.from(items, () => createRef()), [itemsStr]);
  const checkboxRefs = useMemo(() => Array.from(items, () => createRef()), [itemsStr]);
  const columns = useMemo(
    () => orderFromTransitColumns(inputRefs, checkboxRefs, buttonRef),
    [itemsStr],
  );

  useEffect(() => {
    getTransitItems().then(setItems);

    return () => transitItems.clear();
  }, []); // eslint-disable-line

  const createOrderFromTransit = async () => {
    if (isEmpty(transitItems.get())) {
      alert('Выберите товар для добавления в заказ');

      return;
    }

    await createFromTransit({
      docType: 4,
      tender: 5,
      qtys: transitItems.get(),
    });

    transitItems.clear();

    closeModal();
  };

  return (
    <div className="reserve-from-transit">
      <h2 className="reserve-from-transit__title">Доступно для заказа из транзита</h2>
      <div className="reserve-from-transit__divider" />
      <ReactTable
        sortable={false}
        data={items}
        columns={columns}
        showPagination={false}
        minRows={1}
        defaultPageSize={5000}
        loading={fetching}
        noDataText={'Нет доступных заказов'}
        loadingText={loadingText}
      />
      <button
        ref={buttonRef}
        className={classNames('btn', 'btn-outline-danger')}
        disabled={fetching}
        onClick={createOrderFromTransit}
      >
        Создать заказ
      </button>
    </div>
  );
};

OrderFromTransit.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default OrderFromTransit;
