import {useCallback, useState} from "react"
import {useSelector} from "react-redux"
import {getTableListData} from "common/selectors"
import isFunction from "lodash/isFunction"
import useListData from "components/table/hooks/useListData"

const useSelectTable = (params = {}) => {
  const {keyField = 'id', toggle, toggleAll: toggleAllCallback, dataKey = ''} = params
  const data = useListData(dataKey)

  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const clearAll = useCallback(() => {
    setSelected([])
    setSelectAll(false)
  }, [])

  const toggleSelection = useCallback(
    (currentKey, shift, row) => {
      const key = currentKey.replace('select-', '');

      const keyIndex = selected.indexOf(key);
      const rowIndex = data.findIndex(item => item[keyField] === key)

      let current = [...selected];

      if (keyIndex >= 0) {
        current = [...current.slice(0, keyIndex), ...current.slice(keyIndex + 1)];
        setSelectAll(false)
      } else {
        current.push(key);
      }

      if(isFunction(toggle)){
        toggle({original: row, index: rowIndex}, keyIndex < 0, clearAll)
      }

      setSelected(current);
    },
    [selected, toggle],
  );

  const isSelected = key => selected.includes(key);

  const toggleAll = () => {
    let current = [];

    if (!selectAll) {
      data.forEach(item => {
        current.push(item[keyField]);
      });
    }

    if(isFunction(toggleAllCallback)){
      toggleAllCallback(!selectAll, clearAll)
    }

    setSelectAll(!selectAll);
    setSelected(current);
  };

  return {selectAll, selected, toggleSelection, isSelected, toggleAll, clearAll}
}

export default useSelectTable
