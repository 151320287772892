import ReactHtmlParser from 'react-html-parser';
import { transform, unflatPermissions } from 'pages/Settings/helpers/util';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import { reduxForm } from 'redux-form';
import React, { useMemo } from 'react';

const Form = ({ permissions, handleSubmit }) => {
  const {
    setup: { onSubmitPerms },
  } = useDispatch();
  const fetchingSetup = useSelector(getEffectLoading('setup', 'onSubmitPerms'));

  const permissionsFields = useMemo(() => unflatPermissions(permissions, 0).join(''), []);

  return (
    <form onSubmit={handleSubmit(onSubmitPerms)}>
      <div className="row" style={{ padding: '0 15px' }}>
        <div className="col-sm-6">
          <h5>Права доступа</h5>
        </div>
        <div className="col-sm-6 justify-content-end">
          <button type="submit" className="btn btn-danger btn-sm" disabled={fetchingSetup}>
            Сохранить
          </button>
        </div>
      </div>
      <ul className="personal-permissions">
        {ReactHtmlParser(permissionsFields, { transform: transform })}
      </ul>
    </form>
  );
};

const PermissionForm = () => {
  const permissions = useSelector(state => state.setup.permissions);

  const initialValues = useMemo(
    () =>
      permissions.reduce(
        (obj, elem) => ({
          ...obj,
          [elem['Но_']]: elem['Доступ'],
        }),
        {},
      ),
    [],
  );

  const PermissionsForm = reduxForm({
    form: 'permissions',
    initialValues: initialValues,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Form);

  return <PermissionsForm permissions={permissions} />;
};

export default PermissionForm;
