import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../icon.module.scss';

export const Add = ({
  className,
  color = '#f15b5b',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="18" height="18" rx="9" fill={color} />
    <path
      d="M9.5 4C9.5 3.72386 9.27614 3.5 9 3.5C8.72386 3.5 8.5 3.72386 8.5 4H9.5ZM8.5 14C8.5 14.2761 8.72386 14.5 9 14.5C9.27614 14.5 9.5 14.2761 9.5 14H8.5ZM8.5 4V14H9.5V4H8.5Z"
      fill="white"
    />
    <path
      d="M14 9.5C14.2761 9.5 14.5 9.27614 14.5 9C14.5 8.72386 14.2761 8.5 14 8.5L14 9.5ZM4 8.5C3.72386 8.5 3.5 8.72386 3.5 9C3.5 9.27614 3.72386 9.5 4 9.5L4 8.5ZM14 8.5L4 8.5L4 9.5L14 9.5L14 8.5Z"
      fill="white"
    />
  </svg>
);

Add.defaultProps = {
  className: '',
  color: '#f15b5b',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Add.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
