import {
  DEFAULT_OPTION_ORDER_STATUS,
  DEFAULT_OPTIONS_ORDER_CONTACTS,
  DEFAULT_OPTION_ORDER_SORT,
} from '../constants';

export const initFilters = {
  status: DEFAULT_OPTION_ORDER_STATUS,
  contacts: DEFAULT_OPTIONS_ORDER_CONTACTS,
  sort: DEFAULT_OPTION_ORDER_SORT,
};

export const setInitialFilters = locationState => {
  if (locationState) {
    const { orderParams } = locationState;

    return {
      ...initFilters,
      status: orderParams
        ? { ...DEFAULT_OPTION_ORDER_STATUS, label: orderParams.status, value: orderParams.status }
        : DEFAULT_OPTION_ORDER_STATUS,
    };
  }

  return initFilters;
};
