import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { QUEUE_LINKS, QUEUE_PAGE } from 'common/config';
import LoadLinkedForm from 'pages/Settings/components/load-links/components/load-linked-form';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getLinkingsRows } from 'pages/Settings/selectors';

const LoadFileLinks = ({ isPrice }) => {
  const history = useHistory();
  const {
    linkings: { getExampleFile, submitPrice, submitLinks },
  } = useDispatch();
  const data = useSelector(getLinkingsRows);

  const onSubmit = useCallback(
    async data => {
      if (isPrice) {
        await submitPrice(data);
      } else {
        await submitLinks({ data, history });
      }
    },
    [isPrice],
  );

  if (!!data.length) {
    return null;
  }

  return (
    <div>
      <div className="bindings-actions">
        <div>
          <Link to="/settings/bindings" className="section-nav">
            <i className="icon icon-bindings" /> Привязки товаров
          </Link>
        </div>
        <div>
          <Link
            to={`/settings/bindings/load-${isPrice ? 'links' : 'price'}`}
            className="section-nav"
          >
            <i className="icon icon-from-file" />
            {`Загрузить ${isPrice ? 'привязки из файла' : 'прайс'}`}
          </Link>
        </div>
        <div>
          <Link
            to={{ pathname: QUEUE_PAGE, state: { queueType: QUEUE_LINKS } }}
            className="section-nav"
          >
            <i className="icon icon-queue" /> Просмотр очереди
          </Link>
        </div>
      </div>
      {isPrice ? (
        <div className="linking-example">
          <p>
            Для того, чтобы привязать ваши товары к товарам Merlion, загрузите прайс (*.xls, *.xlsx)
            с колонками <br />
            <b>Код товара</b>, <b>Наименование товара</b>, <b>Бренд</b>(необязательно), <b>Part#</b>
            (необязательно) и <b>EAN</b>(необязательно).
          </p>
          <p>
            Если в прайсе будет заполнена колонка <b>EAN</b>, то{' '}
            <b>
              * система автоматически попытается привязать ваш товар к товару Merlion по коду EAN. *
            </b>
          </p>
        </div>
      ) : (
        <a
          href="#get-linking-file"
          onClick={getExampleFile}
          className="section-nav linking-example"
        >
          <i className="icon icon-form-xls" /> Скачать пример файла
        </a>
      )}
      <div className="load-links-container">
        <LoadLinkedForm onSubmit={onSubmit} />
      </div>
    </div>
  );
};

LoadFileLinks.propTypes = {
  isPrice: PropTypes.bool.isRequired,
};

export default LoadFileLinks;
