import { useState, useCallback } from 'react';

export const useRect = trackEvent => {
  const [rect, setRect] = useState({});

  const ref = useCallback(
    node => {
      if (node !== null) {
        const { offsetLeft } = node;
        const { offsetTop } = node;

        if (trackEvent) {
          node.addEventListener(trackEvent, ({ target }) => {
            const { left, top, height, bottom } = target.getBoundingClientRect();
            setRect({
              left,
              top,
              height,
              bottom,
              offsetLeft,
              offsetTop,
            });
          });
        }

        const { left, top, height, bottom } = node.getBoundingClientRect();
        setRect(
          node.getBoundingClientRect({
            left,
            top,
            height,
            bottom,
            offsetTop,
            offsetLeft,
          }),
        );
      }
    },
    [trackEvent],
  );

  return [rect, ref];
};
