import React, { Fragment } from 'react';
import parse from 'react-html-parser';
import * as animationData from './data/under_const.json';
//import Lottie from 'react-lottie';
import styles from './styles/maintenance.module.scss';
import { isCactus } from 'common/config';

import cactusLogo from '../../img/cactus-logo.png';
import mainLogo from '../../img/logo_top.gif';

const logo = isCactus ? cactusLogo : mainLogo;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};

export default ({ children }) => (
  <Fragment>
    <div className={styles.logoContainer}>
      <img src={logo} alt="" />
    </div>
    {/*<div className={styles.pleer}>
      <Lottie options={defaultOptions} />
    </div>*/}
    <h1 className={styles.message}>{parse(children)}</h1>
  </Fragment>
);
