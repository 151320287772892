import React from 'react';
import getDocumentType from '../helpers/getDocumentType';
import { DOCUMENT_IN_PROGRESS, DOCUMENT_WITH_ERROR } from '../constants';
import DocumentWithError from './document-with-error';
import DocumentReady from './document-ready';

const Document = ({ doc }) => {
  const status = getDocumentType(doc);

  switch (status) {
    case DOCUMENT_IN_PROGRESS:
      return <div>в обработке</div>;
    case DOCUMENT_WITH_ERROR:
      return <DocumentWithError doc={doc} />;
    default:
      return <DocumentReady doc={doc} />;
  }
};

export default Document;
