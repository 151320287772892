import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import validate from 'common/validates/validate';
import LoginForm from '../components/login-form';

const mapState = ({ loading: { effects } }) => ({
  isFetching: effects.auth.login || effects.api.fetchRoute,
  passFetching: effects.auth.recover,
});

const mapDispatch = ({ auth: { login, recover } }) => ({
  login,
  recover,
});

export default compose(
  connect(mapState, mapDispatch),
  reduxForm({
    form: 'login-form',
    validate,
  }),
)(LoginForm);
