import React from 'react';
import Content from 'components/layout/content';
import { hasAccess } from 'common/util';
import EmployeesBack from 'pages/Settings/components/employees/components/backref';
import { isCactus } from 'common/config';
import { useSelector } from 'react-redux';
import { getUser } from 'pages/Orders/selectors';
import PermissionForm from './components/permissions-form';
import DispatchForm from './components/dispatch-form';
import withApi from 'hoc/withApi';

const Setup = () => {
  const user = useSelector(getUser);
  const currentLogin = useSelector(state => state.setup.login);

  return (
    <Content title={`Настройки ${currentLogin} (${user.client})`}>
      <div className="personal-container personal-container__settings">
        <div className="row row__dispatch">
          <DispatchForm />
        </div>
        {!isCactus && (
          <div className="row">
            <div className="col-sm-6">
              <h5>Рассылка новостей</h5>
            </div>
            <div className="col-sm-6 justify-content-end">
              <a
                href="https://merlion.com/subscribe"
                rel="noopener noreferrer"
                className="section-nav"
                target="_blank"
              >
                <i className="icon icon-news-icon" /> перейти к настройке
              </a>
            </div>
          </div>
        )}
        {hasAccess(user, 'ADM') && (
          <div className="row">
            <PermissionForm />
          </div>
        )}
      </div>
      {user.login !== currentLogin && <EmployeesBack />}
    </Content>
  );
};

export default withApi(Setup);
