import { convertDateString } from 'utils/new/date';

export function canIntegrateOrder(order, ordersToIntegrate) {
  const currentDate = new Date(new Date().setHours(0, 0, 0, 0));

  const shipmentDate = convertDateString(order?.shipmentDate || '');

  const newOrdersToIntegrate = ordersToIntegrate || {};

  const orders = newOrdersToIntegrate[order.orderNo];

  if (currentDate <= shipmentDate) {
    return orders ? orders.length > 0 : false;
  }

  return false;
}
