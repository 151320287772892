export const formatDocStatus = status => {
  switch (status?.toLowerCase()) {
    case 'ok':
      return 'checkmark';
    case 'error':
      return 'attention';

    case 'active':
    case 'ok-warning':
    case 'canceled':
      return 'wait';

    default:
      return '';
  }
};

export const formatStatusToTitle = status => {
  switch (status) {
    case 'wait': 
      return 'Готовятся';
    case 'checkmark':
      return 'Скачать';
    default:
      return 'Документы';
  }
};
