import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const CellPhone = ({
  className,
  color = '#236192',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M4.58491 12.453C8.0469 16.2297 12.0703 16.9442 13.4738 16.9442C14.503 15.4131 15.0644 14.1882 15.5323 13.0654C14.7837 12.0447 12.6317 10.9219 11.696 10.6156L10.2925 12.0447C9.73111 11.8405 8.42117 11.4322 6.92409 9.79905C5.42702 8.16587 5.14631 6.83892 4.95918 6.22648L6.26912 4.79745C5.89485 3.26635 4.86561 1.63318 4.02351 0.612442C2.99427 1.02074 1.87146 1.73525 0.467952 2.85806C0.374385 4.28708 1.12292 8.77831 4.58491 12.453Z"
          stroke={color}
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="17.4545" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

CellPhone.defaultProps = {
  className: '',
  color: '#236192',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

CellPhone.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
