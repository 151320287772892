import React, { useState } from 'react';
import { certsCollector } from 'pages/Certificates/constants';
import Select from 'react-select';

const validityOptions = [
  { value: 1, label: 'Действительные' },
  { value: 2, label: 'Новые' },
  { value: 3, label: 'Заканчивающиеся в течение недели' },
  { value: 4, label: 'Заканчивающиеся в течение месяца' },
];

const FilterValidity = () => {
  const [value, setValue] = useState();

  const onChange = newValue => {
    const value = newValue?.value;

    setValue(value);

    if (!value) {
      return certsCollector.delete('validity');
    }

    certsCollector.set('validity', value);
  };

  return (
    <Select
      name="validityFilter"
      placeholder="Срок действия"
      onChange={onChange}
      value={value}
      searchable={false}
      options={validityOptions}
    />
  );
};

export default FilterValidity;
