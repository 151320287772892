const colorResultClaimQueue = result => {
  switch (result) {
    case 'Ok':
      return 'status--green';
    case 'Warning':
      return 'status--blue';
    case 'Error':
      return 'status--red';
    case 'Canceled':
      return 'status--red';
    default:
      return 'status--black';
  }
};

export default colorResultClaimQueue;
