export default {
  name: 'wiki',
  state: {
    article: {},
    category: {},
    children: [],
    parents: [],
    articles: [],
  },
  reducers: {
    load: (state, payload) => ({ ...state, ...payload }),
  },
  effects: ({ api }) => ({
    async getAttachmentsTypes() {
      try {
        const options = await api.get({
          url: 'vad/attachments/types',
        });

        return Object.values(options);
      } catch (e) {
        return [];
      }
    },
    async getAttachmentsVendors() {
      try {
        const options = await api.get({
          url: 'vad/attachments/vendors',
        });

        return Object.values(options);
      } catch (e) {
        return [];
      }
    },
    async getAttachmentsGroups() {
      try {
        const options = await api.get({
          url: 'vad/attachments/groups',
        });

        return Object.values(options);
      } catch (e) {
        return [];
      }
    },
  }),
};
