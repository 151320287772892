const optionsItemAccess = [
  {
    value: 0,
    label: 'Весь активный товар',
  },
  {
    value: 1,
    label: 'Доступно на складе  +  транзит',
  },
  {
    value: 2,
    label: 'Доступно к отгрузке',
  },
];

export default optionsItemAccess;
