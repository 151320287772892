import React from 'react';

const OrderLegend = () => (
  <div className="row order-legend">
    <div>
      <span className="square square--red" /> ЖКол = 0
    </div>
    <div>
      <span className="square square--blue" /> ЖКол &gt; Кол
    </div>
    <div>
      <span className="square square--purple" /> {'ЖЦена < Цена'}
    </div>
  </div>
);

export default OrderLegend;
