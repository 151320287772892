export const HEADER_MAP = [
  [
    'limit',
    {
      className: '',
      description: 'Кредитный лимит',
    },
  ],
  [
    'spent',
    {
      className: '',
      description: 'Использовано',
    },
  ],
  [
    'available',
    {
      className: '',
      description: 'Доступно',
    },
  ],
  [
    'delayedSum',
    {
      className: 'chart-info__row--delayed',
      description: 'Просроченных платежей',
    },
  ],
  [
    'maxDayOfDelay',
    {
      className: 'chart-info__row--delayed',
      description: 'Дней просрочки',
    },
  ],
];

export const FILE_GENERATED = 'FILE_GENERATED_STATUS';
export const FILE_ERROR = 'ERROR_GENERATION_STATUS';
