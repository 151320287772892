// @ts-nocheck
import { isEmpty, pick } from 'lodash';
import alert from 'components/native/alert/function';
import { getRegisteredFields } from 'pages/Orders/selectors';
import { preparedData } from 'pages/Orders/components/order-edit/helpers/prepareData';
import dmyToYmdDateFormat from 'pages/Orders/helpers/dmyToYmdDateFormat';
import dateFormat, { undef } from 'common/util';
import notifyOld from 'services/notifier';
import notifyNew from 'services/new/notifier';
import { selector } from 'client/store';
import { GoodsCollector } from 'pages/new/orders/pages/orders-new/utils';
import Mixpanel from 'services/mixpanel';

export default ({ api }) =>
  async ({ formData, isPreorder, isShpd = false, isOrderEdit }, state) => {
    const notifier = isShpd ? notifyNew : notifyOld;

    const { order } = selector;
    const { counterAgentRows = [] } = order.getSettings(state);
    const { orderNo } = order.getHeader(state);

    const orderId = undef(isOrderEdit) || isOrderEdit === true ? orderNo : null;

    const goods = isShpd ? GoodsCollector.get()[orderId] || {} : preparedData.get();

    if (isEmpty(counterAgentRows)) {
      alert('Нет доступных контрагентов, обратитесь к вашему менеджеру!');
      return false;
    }
    const registered = getRegisteredFields('ORDER_EDIT_FORM')(state);
    let availableFormData = pick(formData, registered);
    availableFormData.shipmentAgentInfo = formData.shipmentAgentInfo;

    if (!availableFormData.shipmentMethod) {
      alert(
        `Нет доступных методов отгрузки ${
          (isPreorder && 'с заказного склада') || ''
        }, обратитесь к вашему менеджеру`,
      );

      return false;
    }

    if (!availableFormData.shipmentDate) {
      alert(`Выберите желаемую дату отгрузки!`);

      return false;
    }

    if (!isEmpty(goods['qtys'])) {
      availableFormData = {
        ...availableFormData,
        ...goods,
      };
    }

    const orderDateStr = dmyToYmdDateFormat(availableFormData.shipmentDate);
    const currentDateStr = dateFormat(new Date(), 'yyyymmdd');

    if (orderDateStr < currentDateStr) {
      alert('Дата отгрузки меньше сегодня<br />Сохранить изменения невозможно');
      return false;
    }

    const url = orderId ? `/orders/edit/header/${orderId}` : '/orders/create/header';

    const { success, message } = await api.post({
      url,
      data: availableFormData,
    });

    const { qtys, countItems } = goods.qtys ? Object.values(goods.qtys).reduce(
      (acc, value) => ({
        qtys: acc.qtys + value,
        countItems: acc.countItems + 1,
      }),
      { qtys: 0, countItems: 0 },
    ) : { qtys: 0, countItems: 0 };

    Mixpanel?.track('Заказ.СохранитьЗаказ', {
      заказ: orderId,
      источник: 'СтрЗаказа',
      строки: countItems,
      количество: qtys,
    });

    if (!success) {
      alert(message);
      return false;
    }

    notifier.show(orderId || 'NEW', orderId ? `Изменение заказа ${orderId}` : 'Заказ создаётся');

    return success;
  };
