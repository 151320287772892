import { getUser } from 'pages/Orders/selectors';

const accountedColumnVisibleState = {
  values: {
    orderNo: true,
    ozprNo: true,
    postingDate: true,
    type: true,
    contractor: true,
    sum: true,
    exceededSum: true,
    docNo: true,
    initiator: true,
    closed: true,
    paymentDate: true,
    currency: true,
    deliveryCity: false,
    description: false,
    exceededDays: true,
    note: true,
    updNo: true,
  },
  userVisibility: {},
};

const accountedColumns = {
  name: 'accountedColumns',
  state: accountedColumnVisibleState,
  reducers: {
    toggle: (state, { userId, ...rest }) => {
      const values = { ...state.values, ...rest };

      return {
        ...state,
        values,
        userVisibility: {
          ...state.userVisibility,
          [userId]: values,
        },
      };
    },
  },
  effects: dispatch => ({
    async visibilityPersist(payload, state) {
      const user = getUser(state);

      this.toggle({ userId: user.id, ...payload });

      await dispatch.api.post({
        url: 'view/settings/save',
        data: {
          accounted: {
            values: {
              ...state.accountedColumns.values,
              ...payload,
            },
          },
        },
      });
    },
  }),
};

export default accountedColumns;
