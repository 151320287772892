/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useEffect, useMemo, useLayoutEffect} from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import classNames from 'classnames';
import ItemsTableList from '../../containers/items-table';
import OrderListFilter from '../common/order-list-filter';
import Content from 'components/layout/content';
import FilterTags from '../common/filter-tags';
import itemColumns from 'pages/Orders/components/common/columns/items/columns';
import OrderHeader from '../../containers/order-header';
import OrderFinder from '../../components/common/order-finder';
import OrderQueueList from '../common/order-queue';
import { OrderContext } from '../common/items-search/constants';
import { ImageWrapper } from '../common/columns/components/preview-image';
import Breadcrumbs from '../../components/common/breadcrumbs';
import { empty } from 'components/empty';
import { isEqual } from 'lodash';
import {
  getScrolledElements,
  initScrolls,
  syncScrollWidth,
  initWheel,
} from '../../helpers/eventListeners';
import SimpleViewSwitcher from '../common/simple-view-switcher';
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';
import { OrderEditContext } from 'pages/Orders/components/order-edit/context';
import { useSelector, useStore, useDispatch } from 'react-redux';
import { getTableListData } from 'common/selectors';
import {getIsRegional, getIsRegionalShipment, isFetchingItems} from 'pages/Orders/selectors'
import useContextSelector from 'hooks/context/useContextSelector';
import useToggleHandler from 'hooks/use-toggle-handler';
import {OrderViewContext} from "pages/Orders/constances"
import useContextDispatch from "hooks/context/useContextDispatch"
import {flushState, setState} from "pages/Orders/action/state"
import useDeepEffect from "hooks/use-deep-effect"
import useListData from "components/table/hooks/useListData"

const contentStyle = {
  marginTop: 0,
};

const OrderEdit = React.memo(() => {
  const {
    select: { items, order: orderSelector },
  } = useStore();

  const {
    items: { resetEnv, handleSearch }
  } = useDispatch();

  const found = useSelector(items.getTotalFound);
  const data = useListData();
  const order = useSelector(orderSelector.getHeader);
  const isRegional = useSelector(getIsRegionalShipment);
  const isItemsFetching = useSelector(isFetchingItems)
  const { state } = useLocation()
  const { text, fromOrigin } = state || {}

  const { id = 'NEW' } = useParams();
  const history = useHistory();
  const [isFiltersOpened, setIsFiltersOpened, handleFilterHeadClick] = useToggleHandler(false);

  const tableInstance = useRef();
  const imageWrapper = useRef();

  const isPreorder = useIsPreorder();

  const { initiator, docType } = order;
  const searchText = useContextSelector(OrderEditContext, state => state.searchText);
  const dispatch = useContextDispatch(OrderEditContext)

  if (isFiltersOpened && isPreorder) {
    setIsFiltersOpened(false);
  }

  useEffect(() => {
    const scrolledElements = getScrolledElements();
    const { scroll } = scrolledElements;

    if (!scroll) {
      return;
    }

    syncScrollWidth(scrolledElements);

    scroll.addEventListener('scroll', () => {
      initScrolls(scrolledElements);
    });
    window.addEventListener('scroll', () => {
      initScrolls(scrolledElements);
    });
    window.addEventListener('wheel', () => {
      initWheel(scrolledElements);
    });
    window.addEventListener('resize', () => {
      syncScrollWidth(scrolledElements);
    });

    return () => {
      window.removeEventListener('scroll', initScrolls, true);
      window.removeEventListener('resize', syncScrollWidth, true);
      scroll.removeEventListener('scroll', initScrolls, true);
      window.removeEventListener('wheel', initWheel, true);
    };
  }, []);

  useEffect(() => {
    if(fromOrigin === 'search' && text){
      handleSearch({ text })
      dispatch(setState({ searchText: text }));
      history.replace('/orders/new', { fromOrigin: '', text: '' });
    }
  }, [fromOrigin, text])

  useEffect(() => {
    syncScrollWidth(getScrolledElements());
  }, [isFiltersOpened]);

  useLayoutEffect(() => () => {
    dispatch(flushState())
    resetEnv()
  }, [])

  const wrapperStyle = useMemo(
    () => ({
      display: isFiltersOpened && !isPreorder ? 'flex' : 'none',
    }),
    [isFiltersOpened, isPreorder],
  );

  const columns = useMemo(
    () => itemColumns(order, isPreorder, isRegional, imageWrapper),
    [isPreorder, isRegional, order.isRegional],
  );

  return (
    <Content
      noFooter={true}
      noHeader={true}
      style={contentStyle}
      title={(id !== 'NEW' && `Заказ №${id}`) || 'Создание заказа'}
    >
      <div className="order-edit">
        <ImageWrapper ref={imageWrapper} />
        <OrderContext.Provider value={{ tableInstance }}>
          <OrderViewContext.Provider value={{instance: tableInstance}}>
            <OrderHeader />
            <OrderFinder />
          </OrderViewContext.Provider>
        </OrderContext.Provider>
        <div
          className={classNames('row', 'justify-content-between', 'order-additional-info')}
          style={{ padding: '6px 16px' }}
        >
          <div className="breadcrumbs__wrapper align-items-center">
            {!isItemsFetching && (searchText && (
              <div className="search-result-container">
                Результат поиска по&nbsp;
                <span>"{searchText}"</span>: показано {data.length} товаров из {found}
              </div>
            ) || <Breadcrumbs />)}
          </div>
          <FilterTags />
          <div
            className={classNames(
              'row',
              'table-menu__wrapper',
              'align-items-center',
              'justify-content-end',
              { 'hidden-container': isPreorder },
            )}
          >
            <SimpleViewSwitcher />
            <OrderQueueList />
          </div>
        </div>
        <div className="order-filter-and-list">
          <div
            className={
              'order-filter-opener__container' + (initiator && docType !== 4 ? ' with-order' : '')
            }
          >
            <a
              className="order-filter-opener"
              id="order-filter-opener"
              style={!isFiltersOpened && !isPreorder ? { display: 'flex' } : { display: 'none' }}
              onClick={handleFilterHeadClick}
            >
              <i
                className={classNames(
                  'order-filter-opener__icon',
                  'icon',
                  'icon-order-filter_head_right-arrow',
                  'enabled',
                )}
              />
            </a>
          </div>
          <div style={wrapperStyle}>
            <OrderListFilter isSearch={searchText} onFilterHeadClick={handleFilterHeadClick} />
          </div>
          <div
            id="order-items-list"
            className={classNames('order-list', 'order-list__items', {
              'order-list--with-filter': !isPreorder && isFiltersOpened,
            })}
          >
            <div
              className={
                Number(docType) === 1
                  ? 'items-table-list-header width-order'
                  : 'items-table-list-header'
              }
              id="items-table-list-header"
            >
              <ItemsTableList // Just an outside header for stick
                columns={columns}
                noBody={true}
                model="itemsColumns"
                togglable={!isPreorder}
                isNotPreorder={!isPreorder}
                showPagination={false}
              />
            </div>
            <ItemsTableList
              ref={tableInstance}
              columns={columns}
              TheadComponent={empty}
              model="itemsColumns"
              togglable={!isPreorder}
              isNotPreorder={!isPreorder}
            />
          </div>
        </div>
      </div>
    </Content>
  );
}, isEqual);

OrderEdit.displayName = 'OrderEdit';
OrderEdit.whyDidYouRender = false;

export default OrderEdit;
