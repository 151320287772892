// @ts-nocheck
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { CURRENCIES } from 'common/constants/currency';
import { SHIPPING_METHODS } from 'common/constants/order';
import { highlightText } from 'utils/new/strings';
import { formatNumber, toPrimalNumber } from 'utils/new/numbers/format-number';
import { convertDateString } from 'utils/new/date';
import { Chip } from 'common/ui/chip';
import { FORM_INITIAL_VALUES } from '../data';

function orderShipmentFactory(order, shipmentMethodRows) {
  const shipmentMethod = shipmentMethodRows.find(
    shipmentMethodRow => shipmentMethodRow.Code === order.shipmentMethod,
  );

  if (shipmentMethod) {
    return shipmentMethod;
  }

  shipmentMethodRows.find(shipmentMethodRow => shipmentMethodRow['IsDefault'].includes('1'));

  if (!shipmentMethod) {
    return shipmentMethodRows[0];
  }

  return shipmentMethod;
}

function contractorFactory(contractorRows) {
  const contractor = contractorRows.find(contractorRow =>
    contractorRow['Dogovor by Default'].includes('1'),
  );

  if (!contractor) {
    return contractorRows[0];
  }

  return contractor;
}

function shipmentAgentFactory(shipmentAgent, shipmentAgentRows) {
  const agent = shipmentAgentRows.find(shipmentAgentRow =>
    shipmentAgentRow.Name.includes(shipmentAgent),
  );
  const { Name, Address } = agent;
  let combo;

  if (Name && Address) {
    combo = `${Name} | ${Address}`;
  }

  if (Name && !Address) {
    combo = Name;
  }

  if (Address && !Name) {
    combo = Address;
  }

  return {
    ...agent,
    label: combo,
    value: combo,
  };
}

export function endpointFactory(order, shipmentEndpointRows) {
  const endpoint = shipmentEndpointRows.find(
    shipmentEndpointsRow =>
      shipmentEndpointsRow.address.includes(order.finalAddress) &&
      shipmentEndpointsRow.contact.includes(order.finalContact),
  );

  if (endpoint) {
    const { contact, address } = endpoint || {};
    let combo;

    if (contact && address) {
      combo = `${address} | ${contact}`;
    }

    if (contact && !address) {
      combo = contact;
    }

    if (address && !contact) {
      combo = address;
    }

    return {
      ...endpoint,
      label: combo,
      value: combo,
    };
  }

  return shipmentEndpointRows[0];
}

export function representativeFactory(order, representatives) {
  if (order.representative) {
    return {
      label: order.representative.trim(),
      value: order.representative.trim(),
    };
  }

  if (!order.representative && representatives && representatives.length > 0) {
    return representatives[0];
  }

  return null;
}

export function createOrderFormValues(order, orderSettings) {
  if (order && orderSettings) {
    const isReserve = order.reserveOrOrder.includes('РЕЗЕРВ');
    let orderType = {
      condition: 'РЕЗЕРВ',
      label: 'Резерв - ЗПр',
      value: 'Резерв - ЗПр',
    };

    orderType = isReserve
      ? orderType
      : {
          condition: 'ЗАКАЗ',
          label: 'Заказ - ОЗПр',
          value: 'Заказ - ОЗПр',
        };

    return {
      ...FORM_INITIAL_VALUES,
      orderType,
      contractor: contractorFactory(orderSettings.contractorRows),
      representative: representativeFactory(order, orderSettings.representatives),
      packingType: {
        label: order.packingType,
        value: order.packingType,
      },
      // finalAddress | finalContact
      endpoint: endpointFactory(order, orderSettings.shipmentEndpointRows),
      shipmentAgent: shipmentAgentFactory(order.shipmentAgent, orderSettings.shipmentAgentRows),

      shipmentDate: isReserve
        ? {
            label: order.shipmentDate,
            value: order.shipmentDate,
          }
        : convertDateString(order.shipmentDate),
      shipmentMethod: orderShipmentFactory(order, orderSettings.shipmentMethodRows),
      // shipmentTime: shipmentTimeFactoryorder.shipmentTime,
      endCustomer: order.endCustomer,
      postingDescription: order.postingDescription,
      goods: [],
    };
  }

  return {
    ...FORM_INITIAL_VALUES,
  };
}

export function composeGoodIcons(good = {}) {
  const statuses = [];

  if (isEmpty(good)) {
    return statuses;
  }

  if (String(good.isNew) === '1') {
    statuses.push({
      icon: 'new',
      className: 'new',
      label: 'Новый товар',
    });
  }

  if (good.existsActions || good.ExistsActions) {
    statuses.push({
      icon: 'sale',
      className: 'sale',
      label: `Участвует в акции - ${good.actionDesc}`,
    });
  }

  if (String(good.EOL) === '1') {
    statuses.push({
      icon: 'stop',
      className: 'stop',
      label: 'Больше не производится',
    });
  }

  if (String(good.name).includes('плохая упаковка')) {
    statuses.push({
      icon: 'damage',
      className: 'damage',
      label: 'Плохая упаковка',
    });
  }

  return statuses;
}

export function composeGoodReserveIcons(good = {}) {
  const statuses = [];

  if (isEmpty(good)) {
    return statuses;
  }

  const onlineReserve = String(good.onlineReserve);

  if (onlineReserve.includes('3') || onlineReserve.includes('1')) {
    statuses.push({
      icon: 'flash',
      className: 'flash-yellow',
      label: 'Дефицитный товар',
    });
  }
  if (onlineReserve.includes('6') || onlineReserve.includes('4')) {
    statuses.push({
      icon: 'flash',
      className: 'flash-red',
      label: 'Просроченный резерв дефицитного товара',
    });
  }

  if (onlineReserve.includes('2')) {
    statuses.push({
      icon: 'rub',
      className: 'rub-green',
      label: 'Товар, за отмену резерва которого будет начислен штраф ',
    });
  }

  if (onlineReserve.includes('5')) {
    statuses.push({
      icon: 'rub',
      className: 'rub-red',
      label: 'Просроченный резерв со штрафом',
    });
  }

  return statuses;
}

const formatGoodAttribute = (attribute, text) => highlightText(attribute, text);

export function formatGoodsByCurrency(goods, currency) {
  const goodCurrency = CURRENCIES[currency] || {};
  return goods.map(good => ({
    ...good,
    currency: goodCurrency,
    priceRUB: formatNumber(toPrimalNumber(good.priceRUB), { fractions: 2 }),
    priceUSD: formatNumber(toPrimalNumber(good.priceUSD), { fractions: 2 }),
  }));
}

export function formatGoodsBySearch(goods, searchValue) {
  return goods.map(good => ({
    ...good,
    code: searchValue ? formatGoodAttribute(good.code, searchValue) : good.code,
    name: searchValue ? formatGoodAttribute(good.name, searchValue) : good.name,
  }));
}

export function makeContractorRows(encodedValue) {
  if (encodedValue) {
    const decodedValue = JSON.parse(decodeURI(encodedValue));

    return decodedValue.map(value => ({
      ...value,
      label: value.Description,
      value: value.Description,
    }));
  }

  return [];
}

export function makePackingRows(encodedValue) {
  if (encodedValue) {
    const decodedValue = JSON.parse(decodeURI(encodedValue));

    return decodedValue.map(value => ({
      label: value,
      value,
    }));
  }
  return [
    {
      label: 'Нет упаковки',
      value: 'Нет упаковки',
    },
  ];
}

export function makeShipmentAgentRows(encodedValue) {
  if (encodedValue) {
    const decodedValue = JSON.parse(decodeURI(encodedValue));

    return decodedValue.map(value => {
      let combo;

      if (value.Name && value.Address) {
        combo = `${value.Name} | ${value.Address}`;
      }

      if (value.Name && !value.Address) {
        combo = value.Name;
      }

      if (value.Address && !value.Name) {
        combo = value.Address;
      }

      return {
        ...value,
        label: combo,
        value: combo,
      };
    });
  }
  return [
    {
      label: 'Нет агента по доставке',
      value: 'Нет агента по доставке',
    },
  ];
}

export function makeShipmentDatesRows(shipmentDates) {
  if (shipmentDates) {
    return shipmentDates.map(shipmentDate => ({
      label: shipmentDate,
      value: shipmentDate,
    }));
  }
  return [
    {
      label: 'Нет даты огрузки',
      value: 'Нет даты огрузки',
    },
  ];
}

export function makeRepresentativeRows(representatives) {
  if (representatives) {
    return representatives.map(representative => ({
      label: representative,
      value: representative,
    }));
  }
  return [
    {
      label: 'Нет представителя',
      value: 'Нет представителя',
    },
  ];
}

export function makeShipmentMethodRows(encodedValue) {
  if (encodedValue) {
    const decodedValue = JSON.parse(decodeURI(encodedValue));

    return decodedValue.map(value => {
      const label =
        value.Func === '0' ? (
          <Chip
            icon={SHIPPING_METHODS['доставка'].icon}
            iconColor={SHIPPING_METHODS['доставка'].iconColor}
            text={value.Description}
          />
        ) : (
          <Chip
            icon={SHIPPING_METHODS['самовывоз'].icon}
            iconColor={SHIPPING_METHODS['самовывоз'].iconColor}
            text={value.Description}
          />
        );
      return {
        ...value,
        label,
        value: value.Func,
      };
    });
  }

  return [
    {
      label: 'Нет методов огрузки',
      value: 'Нет методов огрузки',
    },
  ];
}

export function makeShipmentEndpointRows(encodedValue) {
  if (encodedValue) {
    const decodedValue = JSON.parse(decodeURI(encodedValue));

    return decodedValue.map(value => {
      let combo;

      if (value.contact && value.address) {
        combo = `${value.address} | ${value.contact}`;
      }

      if (value.contact && !value.address) {
        combo = value.contact;
      }

      if (value.address && !value.contact) {
        combo = value.address;
      }

      return {
        ...value,
        label: combo,
        value: combo,
      };
    });
  }

  return [
    {
      label: 'Нет конечного адреса и контакта',
      value: 'Нет конечного адреса и контакта',
    },
  ];
}

function preparePrice(order) {
  const prices = {};
  const goods = order.goods || order.initialGoods;

  prices.prices = {};
  prices.priceClients = {};
  goods.forEach(({ id, priceRUB, nextPriceRUB, priceUSD, nextPriceUSD }) => {
    prices.prices[id] = nextPriceRUB ? toPrimalNumber(nextPriceRUB) : toPrimalNumber(priceRUB);
    prices.priceClients[id] = nextPriceUSD
      ? toPrimalNumber(nextPriceUSD)
      : toPrimalNumber(priceUSD);
  });

  return prices;
}

function prepareQty(order) {
  const qtys = {};
  const goods = order.goods || order.initialGoods;
  goods.forEach(({ id, count, nextCount }) => {
    qtys[id] = nextCount || count;
  });
  return qtys;
}

export function prepareOrderForRequest(order) {
  return {
    act: {},
    docType: order.docType,
    contractorCode: order.contractor.Code,
    currency: order.currency?.name,
    endCustomer: order.endCustomer,
    endpoint: order.endpoint.value,
    importItems: [],
    isPreorder: false,
    packingType: order.packingType.value,
    postingDescription: order.postingDescription,
    representative: order.representative.value,
    qtys: prepareQty(order),
    shipmentAgentInfo: `${order.shipmentAgent.Code}#${order.shipmentAgent['Agent Type']}`,
    shipmentDate: order.orderType.condition.includes('РЕЗЕРВ')
      ? order.shipmentDate.value
      : order.shipmentDate.toLocaleDateString(),
    shipmentMethod: order.shipmentMethod.Code,
    shipmentAgentDeliveryInterval: order.shipmentAgentDeliveryInterval,
    shipmentTime: order.shipmentTime,
    ...preparePrice(order),
  };
}
