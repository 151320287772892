import sortStringNumbers from 'helpers/sortStringNumbers';
import priceFormat from 'helpers/priceFormat';
import sortDates from 'helpers/sortDates';
import { DocumentCell } from './cells';

export default allowPrinting => [
  {
    Header: 'Учтенный Счет Но.',
    accessor: 'orderNo',
    id: 'orderNo',
    width: 140,
  },
  {
    Header: 'Сумма',
    accessor: 'orderAmount',
    id: 'orderAmount',
    width: 140,
    sortMethod: sortStringNumbers,
    Cell: row => priceFormat(row.value),
  },
  {
    Header: 'ИНН',
    accessor: 'INN',
    id: 'INN',
    width: 140,
  },
  {
    Header: 'Юр.лицо',
    accessor: 'company',
    id: 'company',
    width: 140,
  },
  {
    Header: 'Ож. дата возвр.',
    accessor: d => new Date(d.dateReturn).toLocaleDateString(),
    id: 'dateReturn',
    sortMethod: sortDates,
    width: 140,
  },
  {
    Header: 'Дата документа',
    accessor: d => new Date(d.documentDate).toLocaleDateString(),
    id: 'documentDate',
    sortMethod: sortDates,
    width: 140,
  },
  {
    Header: 'Номера документов',
    accessor: 'documentNumbers',
    id: 'documentNumbers',
    width: 220,
  },
  {
    Header: 'Статус',
    accessor: 'documentStatus',
    id: 'documentStatus',
    width: 140,
  },
  {
    Header: 'Комментарий',
    accessor: 'documentComment',
    id: 'documentComment',
  },
  {
    Header: 'Запрос документа',
    id: 'documentDownload',
    show: allowPrinting,
    width: 160,
    className: 'btn-wrapper-blocked',
    Cell: DocumentCell,
  },
];
