import React, { memo } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import Preloader from 'components/preloader';
import isEqual from 'lodash/isEqual';
import useCollapsed from 'hooks/use-collapsed';

const Properties = ({ properties }) => {
  const [isCollapsed, setIsCollapsed, mayCollapse, ref] = useCollapsed([properties]);

  const isPropertiesFetching = useSelector(getEffectLoading('product', 'getProperties'));

  if (isPropertiesFetching) {
    return <Preloader />;
  }

  if (!Array.isArray(properties)) {
    return null;
  }

  return (
    <div className="properties">
      <div
        className={classNames('properties__toggler', {
          'properties__toggler--collapsed': isCollapsed,
        })}
        ref={ref}
      >
        {properties.map(propertySection => (
          <div key={propertySection.sectionId} className="properties__section">
            <h2 className={classNames('properties__head', 'font-size__xlarge')}>
              {propertySection.sectionName}
            </h2>
            {propertySection.data.map(property => (
              <div key={property.id} className="properties__description">
                <p className={classNames('properties__description__key', 'font-size__regular')}>
                  {property.name}
                </p>
                <p className={classNames('properties__description__value', 'font-size__regular')}>
                  {property.value}
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
      {mayCollapse && (
        <div
          className={classNames('properties__footer', {
            'properties__footer--collapsed': isCollapsed,
          })}
        >
          <div
            className={classNames('properties', {
              properties__gradient: isCollapsed,
            })}
          />
          <a
            onClick={() => setIsCollapsed(!isCollapsed)}
            href="#toggleCollapse"
            className={classNames('font-size__regular', {
              'font-color__grey': !isCollapsed,
              properties__expand: isCollapsed,
              properties__collapse: !isCollapsed,
            })}
          >
            {(isCollapsed && 'Все характеристики') || 'Спрятать'}
          </a>
        </div>
      )}
    </div>
  );
};

export default memo(Properties, isEqual);
