/* eslint-disable no-console */
import React, {useCallback} from 'react'
import { createContext } from 'use-context-selector';
import useContextSelector from 'hooks/context/useContextSelector';
import Select from 'react-select';
import { getMoved } from '../../helpers';
import OrderLegend from '../common/order-legend';
import Content from 'components/layout/content';
import alert from 'components/native/alert/function';
import { compose } from 'redux';
import { removeProp } from 'common/util';
import HeaderInfo from '../common/header-info';
import initColumns from './columns/initColumns';
import { setExpanded, setState } from '../../action/state';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import OrderToMove from 'pages/Orders/components/order-move-form/components/order-to-move';
import { getTableListData } from 'common/selectors';
import { getOrderShipmentMethods, getOrderView, getUser } from 'pages/Orders/selectors';
import withApi from 'hoc/withApi';
import withContextReducer from 'hoc/withContextReducer';
import orderEditReducer, { initialMoveState } from 'pages/Orders/reducer/orderEditReducer';
import useContextDispatch from 'hooks/context/useContextDispatch';
import SelectTableList from "components/select-table-list"
import useListData from "components/table/hooks/useListData"

const OrderMoveContext = createContext({});

const ORDER_HEADER_KEYS = [
  'orderNo',
  'currentDate',
  'shipmentMethod',
  'shipmentDate',
  'type',
  'contact',
  'status',
  'weight',
  'volume',
  'priceRUB',
  'postingDescription',
  'endCustomer',
];

const OrderMoveForm = () => {
  const {
    order: { getSimilar },
  } = useDispatch();

  const data = useListData();
  const user = useSelector(getUser);
  const order = useSelector(getOrderView);
  const orderSettings = useSelector(getOrderShipmentMethods);

  const stateDispatch = useContextDispatch(OrderMoveContext);

  const orderToMove = useContextSelector(OrderMoveContext, state => state.orderToMove);
  const moved = useContextSelector(OrderMoveContext, state => state.moved);
  const expanded = useContextSelector(OrderMoveContext, state => state.expanded);
  const itemsToMove = useContextSelector(OrderMoveContext, state => state.itemsToMove);

  const moveRow = useCallback(
    (row, changed = false, clear) => {

      if (!orderToMove) {
        return alert('Выберите заказ для переноса!').then(clear)
      }

      const newRow = { ...row.original };
      const {qty, desiredQty} = newRow

      newRow.qty = desiredQty > qty ? qty : desiredQty;

      if (!changed && row.index in moved) {
        stateDispatch(
          setState({
            moved: removeProp(moved, row.index),
            itemsToMove: removeProp(itemsToMove, row.index),
          }),
        );
      } else {
        stateDispatch(
          setState({
            moved: { ...moved, [row.index]: newRow },
            itemsToMove: {
              ...itemsToMove,
              [row.index]: [row.original.id, desiredQty],
            },
          }),
        );
      }
    },
    [orderToMove, moved],
  );

  const moveAllRows = (checked, clear) => {
    if (!orderToMove) {
      return alert('Выберите заказ для переноса!').then(clear)
    }

    const moved = checked
      ? { ...moved, ...getMoved(data, false) }
      : {};

    stateDispatch(
      setState({
        moved,
        itemsToMove: checked
          ? {
              ...itemsToMove,
              ...getMoved(Object.values(moved), true),
            }
          : {},
      }),
    );
  };

  const handleChooseOrder = ({ value }) => {
    stateDispatch(setState({ orderToMove: value }));
  };

  const getAvailableOrders = (input, callback) => {
    getSimilar(order).then(({ orders }) => callback(null, { options: orders }));
  };

  const expandTable = () => stateDispatch(setExpanded(!expanded));

  const getTrGroupProps = useCallback(
    (state, info) => {
      if (info && info.index > 3 && !expanded) {
        return { className: 'hidden' };
      } else {
        return { className: '' };
      }
    },
    [expanded],
  );

  const getTdProps = useCallback(() => ({ moveRow }))

  const columns = useCallback(
    (forMove = false) =>
      initColumns({
        forMove,
      }),
    [],
  );

  return (
    <Content title="Перенести товар в другой заказ" style={{ padding: 0 }}>
      <HeaderInfo
        user={user}
        order={order}
        orderSettings={orderSettings}
        visible={ORDER_HEADER_KEYS}
      />
      <div
        className={classNames(
          'align-items-center',
          'actions-container',
          'actions-container--order',
          'actions-container__move',
        )}
      >
        <div className="row event-actions">
          <label>В какой заказ перенести товар</label>
          <Select.Async
            name="orders"
            placeholder="Выберите заказ"
            value={orderToMove}
            loadOptions={getAvailableOrders}
            onChange={handleChooseOrder}
            noResultsText="Выберите заказ"
            clearable={false}
          />
        </div>
      </div>
      <div className="order-list">
        <SelectTableList
          keyField='id'
          columns={columns(true)}
          defaultPageSize={5000}
          pageSize={5000}
          getTdProps={getTdProps}
          getTrGroupProps={getTrGroupProps}
          toggleAll={moveAllRows}
          toggle={moveRow}
        />

        <div
          className={classNames('expander', {
            'expander--hidden': data.length < 5,
          })}
        >
          <i
            className={`icon icon-${expanded ? 'up' : 'down'}`}
            onClick={expandTable}
            title={expanded ? 'Скрыть до 4-х строк в таблице' : 'Показать все строки таблицы'}
          />
        </div>
      </div>
      <div className="legend-container justify-content-end">
        <OrderLegend />
      </div>
      <OrderToMove
        toOrder={orderToMove}
        user={user}
        columns={columns}
        orderSettings={orderSettings}
        items={moved}
        headerKeys={ORDER_HEADER_KEYS}
      />
    </Content>
  );
};

export default compose(
  withApi,
  withContextReducer(orderEditReducer, initialMoveState, OrderMoveContext),
)(OrderMoveForm);
