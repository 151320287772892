import React from 'react';
import { SHIPPING_METHODS } from 'common/constants/order';
import { Chip } from 'common/ui/chip';

export const FORM_INITIAL_VALUES = {
  orderType: {},
  shipmentDate: '',
  shipmentMethod: '',
  contractor: '',
  representative: '',
  packingType: '',
  shipmentAgent: '',
  shipmentTime: null,
  // finalAddress | finalContact
  endpoint: '',
  endCustomer: '',
  postingDescription: '',
};

export const SHIPPING_METHODS_OPTIONS = {
  доставка: {
    label: (
      <Chip
        icon={SHIPPING_METHODS['доставка'].icon}
        iconColor={SHIPPING_METHODS['доставка'].iconColor}
        text={SHIPPING_METHODS['доставка'].name}
      />
    ),
    value: SHIPPING_METHODS['доставка'].name,
  },
  самовывоз: {
    label: (
      <Chip
        icon={SHIPPING_METHODS['самовывоз'].icon}
        iconColor={SHIPPING_METHODS['самовывоз'].iconColor}
        text={SHIPPING_METHODS['самовывоз'].name}
      />
    ),
    value: SHIPPING_METHODS['доставка'].name,
  },
};

export const ORDER_TYPE_OPTIONS = [
  {
    condition: 'ЗАКАЗ',
    label: 'Заказ - ОЗПр',
    value: 'Заказ - ОЗПр',
  },
  {
    condition: 'РЕЗЕРВ',
    label: 'Резерв - ЗПр',
    value: 'Резерв - ЗПр',
  },
];
