import { useState, useCallback, useMemo } from 'react';
import xor from 'lodash/xor';
import includes from 'lodash/includes';

/**
 * Hook to toggle tabs
 *
 * @returns {TabsHookReturns}
 */
export default (activeTab = 0) => {
  const [tabs, setTabs] = useState([activeTab]);

  const toggleTab = useCallback(
    tab => void setTabs(prevTabs => xor(prevTabs, [tab]))
    , []);

  const isTabExists = useCallback(tab => includes(tabs, tab), [tabs]);

  return useMemo(
    () => ({
      setTabs,
      toggleTab,
      isTabExists,
    }),
    [isTabExists, toggleTab],
  );
};
