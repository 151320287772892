// @ts-nocheck
import get from 'lodash/get';
import { formattingIquoteOrder } from '../helpers/formattin-iquoute-order';
import { handleIquoteLink } from '../helpers/handle-iquoute-link';

export default {
  name: 'iquote',
  state: {
    message: '',
    configuration: '',
  },
  reducers: {
    load: (state, payload) => {
      const success = get(payload, 'success', false);
      if (!success) {
        return {
          ...state,
          message: get(payload, 'message', ''),
        };
      }

      return {
        ...state,
        configuration: get(payload, ['data', 'configuration'], 'ничего не найдено'),
      };
    },
  },
  effects({ api }) {
    return {
      async authServer() {
        try {
          let newTab = window.open()
          newTab.document.write('Loading HPE configurators...');

          const res = await api.get({ url: 'hp-iquote-servers' });
          handleIquoteLink(res, newTab);
          return res;
        } catch (e) {
          console.error(e);
          return e;
        }
      },
      async authDesktop() {
        try {
          let newTab = window.open()
          newTab.document.write('Loading HP configurators...');

          const res = await api.get({ url: 'hp-iquote-desktops' });
          handleIquoteLink(res, newTab);
          return res;
        } catch (e) {
          console.error(e);
          return e;
        }
      },
      async createIquoteOrder(data) {
        try {
          const foramattedData = formattingIquoteOrder(data);
          const res = await api.post({
            url: 'orders/create/iquote',
            data: foramattedData,
          });
          return res;
        } catch (e) {
          console.error(e);
          return e;
        }
      },
    };
  },
};
