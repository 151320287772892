import { includes } from 'lodash';
import {
  PRODUCT_GROUP_SERVER_KEY,
  PRODUCT_SUB_GROUP_SERVER_KEY,
  PRODUCT_GROUP_TABLE_KEY,
  PRODUCT_SUB_GROUP_TABLE_KEY,
  BRAND_KEY,
} from '../constants';

const FILTER_GROUP_KEYS = [
  PRODUCT_GROUP_SERVER_KEY,
  PRODUCT_GROUP_TABLE_KEY,
  PRODUCT_SUB_GROUP_SERVER_KEY,
  PRODUCT_SUB_GROUP_TABLE_KEY,
  BRAND_KEY,
];

const isProductGroupWithSoloFilter = filterGroup =>
  !(filterGroup.filters.length <= 1 && includes(FILTER_GROUP_KEYS, filterGroup.key));

const cleanFilterGroups = filterGroups => filterGroups.filter(isProductGroupWithSoloFilter);

export default cleanFilterGroups;
