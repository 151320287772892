import React, { useState, useCallback, useImperativeHandle, forwardRef, useEffect } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import Catalog from '../../../components/catalog';
import { smoothScrollToTop } from 'common/util';
import { useSelector, useDispatch } from 'react-redux';
import { isFetchingItems } from 'pages/Orders/selectors';

const CatalogOpener = forwardRef(({ catalogText, isOpen = true }, ref) => {
  const {
    catalog: { setActiveCategory },
  } = useDispatch();

  const [isCatalogOpened, setIsCatalogOpened] = useState(isOpen);
  const fetchingItems = useSelector(isFetchingItems);

  const { pathname } = useLocation();
  const isDemofond = /demof[uo]nd/.test(pathname);

  useEffect(() => {
    fetchingItems && setIsCatalogOpened(false);
  }, [fetchingItems]);

  useEffect(() => {
    setIsCatalogOpened(isOpen);
  }, [isOpen]);

  const handleOpen = useCallback(
    isOpen => evt => {
      evt.preventDefault();
      smoothScrollToTop(2000);
      setIsCatalogOpened(isOpen);

      if (isDemofond) {
        setActiveCategory({});
      }
    },
    [isDemofond],
  );

  useImperativeHandle(ref, () => ({
    toggle: (value, callback) => {
      setIsCatalogOpened(value);
      callback?.();
    },
  }));

  return (
    <div className="catalog-opener-container">
      <a
        href="#"
        title="Показать группы товаров"
        className={classNames(
          'section-nav',
          'section-nav__no-underline',
          'section-nav--dashed',
          'section-nav--upper',
          {
            ['section-nav__drop-arrow']: !isCatalogOpened,
            ['section-nav__pick-arrow']: isCatalogOpened,
          },
        )}
        onClick={handleOpen(!isCatalogOpened)}
      >
        {catalogText}
      </a>
      {isCatalogOpened && <Catalog handleOpen={handleOpen} isDemofond={isDemofond} />}
    </div>
  );
});

export default CatalogOpener;
