const createTargetsColumns = () => [
  {
    Header: 'Ваша цель',
    accessor: 'description',
    sortable: false,
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
  },
  {
    Header: 'Бонус за выполнение',
    accessor: 'result',
    sortable: false,
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
  },
];

export default createTargetsColumns;
