import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Balance = ({
  className,
  color = '#236192',
  name,
  width = '12',
  height = '12',
  viewBox = '0 0 12 12',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M2.11111 11.5L9.88889 11.5C10.1967 11.5 10.3231 11.4323 10.3762 11.3821C10.4235 11.3375 10.5 11.2241 10.5 10.9091L10.5 4.20711L6.79289 0.5L2.11111 0.500001C1.80334 0.500001 1.67691 0.56772 1.62376 0.617886C1.57649 0.6625 1.5 0.775896 1.5 1.09091L1.5 10.9091C1.5 11.2241 1.57649 11.3375 1.62376 11.3821C1.67691 11.4323 1.80334 11.5 2.11111 11.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 0 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

Balance.defaultProps = {
  className: '',
  color: '#236192',
  width: '12',
  height: '12',
  viewBox: '0 0 12 12',
};

Balance.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
