import { FILTER_BY_PROPERTIES } from '../../constants';
import curryCreateFacetsFilters from '../serverData/curryCreateFacetsFilters';

const PropertiesMap = {
  code3: 'Подгруппа',
  code2: 'Группа',
  brand: 'Бренд',
};

export default facets => filterGroups => {
  let facetsGroups = [];

  for (const key in facets) {
    if (!PropertiesMap[key]) {
      continue;
    }

    const facetsGroup = {
      name: PropertiesMap[key].toUpperCase(),
      key,
      type: FILTER_BY_PROPERTIES,
      filters: curryCreateFacetsFilters(facets[key]),
    };

    facetsGroups = [...facetsGroups, facetsGroup];
  }

  return [...facetsGroups, ...filterGroups];
};
