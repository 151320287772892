// @ts-nocheck
import { LocalStorage } from 'services/new/local-storage';
import { capitalize } from 'utils/new/strings';
import { CATEGORIES } from './data';

export function getCategoryNameFromData(name) {
  if (typeof name !== 'string') {
    return '';
  }

  const capitalizedName = capitalize(name.toLowerCase());

  return CATEGORIES[capitalizedName]?.name || capitalizedName;
}

export function getParentCatByParentId(parentId, categories = []) {
  return categories.find(category => category.id === parentId);
}

export function getFlattenFavCats(favoriteCats) {
  let arr = [];

  if (favoriteCats) {
    Object.keys(favoriteCats).forEach(favoriteCat => {
      if (Array.isArray(favoriteCats[favoriteCat])) {
        arr = [...arr, favoriteCat, ...favoriteCats[favoriteCat]];
      } else {
        arr.push(favoriteCat);
      }
    });
  }

  return arr;
}

export function getFlattenSubFavCats(favoriteCats) {
  if (favoriteCats) {
    let arr = [];
    Object.keys(favoriteCats).forEach(favoriteCat => {
      if (favoriteCats[favoriteCat] && favoriteCats[favoriteCat].length > 0) {
        arr = [...arr, ...favoriteCats[favoriteCat]];
      }
    });
    return arr;
  }

  return null;
}

export function isFavorite(categoryId) {
  const favoriteCategoriesIds = LocalStorage.getItem('favoriteCategoriesIds') || {};
  const favoriteCats = getFlattenFavCats(favoriteCategoriesIds);

  return Boolean(favoriteCats.find(id => id === categoryId));
}

export function makeCrumbs(items = []) {
  const item = items[0];

  if (!item) {
    return [];
  }

  const { Code1, Code2, Code3, group1, group2, group3 } = item;
  const crumbs = [];

  if (Code1 && group1) {
    crumbs.push({
      id: Code1,
      name: getCategoryNameFromData(group1),
    });
  }

  if (Code2 && group2) {
    crumbs.push({
      id: Code2,
      name: group2,
    });
  }

  if (Code3 && group3) {
    crumbs.push({
      id: Code3,
      name: group3,
    });
  }

  return crumbs;
}

export function makeFavoriteCategoriesFromStorage(category) {
  const favCatsFromStorage = LocalStorage.getItem('favoriteCategories');
  const favoriteCategories = Array.isArray(favCatsFromStorage) ? favCatsFromStorage : [];
  const index = favoriteCategories.findIndex(favCat => favCat.id === category.id);
  if (index > -1) {
    favoriteCategories.splice(index, 1);
  } else {
    favoriteCategories.push(category);
  }

  return favoriteCategories;
}

export function makeGridRowsCount(data) {
  const minGridRowsCount = 10;
  let gridRowsCount = 0;
  if (data) {
    if (data.length === 30) {
      gridRowsCount = minGridRowsCount;
    }
    if (data.length > 30) {
      gridRowsCount = (data.length % minGridRowsCount) + minGridRowsCount;
    }

    if (data.length < 30) {
      gridRowsCount = minGridRowsCount;
    }
  }

  return gridRowsCount;
}

export const getNewFavCats = (payload, favoriteCategories) => {
  const { categoryId, parentId } = payload;

  if (parentId) {
    let parentCategory = favoriteCategories[parentId] || [];

    if (parentCategory.length) {
      if (parentCategory.includes(categoryId)) {
        parentCategory.splice(parentCategory.indexOf(categoryId), 1);
      } else {
        parentCategory.push(categoryId);
      }
    } else {
      parentCategory = [categoryId];
    }

    return { ...favoriteCategories, [parentId]: parentCategory };
  }

  const { [categoryId]: favCat, ...categories } = favoriteCategories;

  return favCat
    ? categories
    : {
        ...favoriteCategories,
        [categoryId]: [],
      };
};

export const makeActiveCategory = (categories = {}) => ({
  firstCategory: { id: categories['Code1'], name: categories['Group1'] },
  secondCategory: { id: categories['Code2'], name: categories['Group2'] },
  thirdCategory: { id: categories['Code3'], name: categories['Group3'] },
});
