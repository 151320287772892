import { shipmentCollector } from 'pages/Logistic/constants';
import notifier from 'services/notifier';
import alert from 'components/native/alert/function';

export default ({ api }) =>
  async ({ data, orders, scheduleNo }) => {
    const order = orders.find(({ value }) => data.orderNo.find(no => no === value));

    const requestData = {
      date: data.date,
      orderNo: data.orderNo,
      logisticObject: order?.logisticObject,
      carModel: data.carModel,
      stateNumber: data.stateNumber,
      representativeFIO: data.representativeFIO,
      notRussianStateNumber: data.notRussianStateNumber ? data.notRussianStateNumber : '',
      shipmentDuringTheDay: data.shipmentDuringTheDay ? '1' : '0',
      timeIntervalCode: data.timeIntervalCode,
    };

    shipmentCollector.set(requestData);

    const url = scheduleNo ? `shipment-schedule/update/${scheduleNo}` : 'shipment-schedule/create';

    try {
      const { id } = await api.post({ url, data: requestData });
      const no = scheduleNo ?? id;
      const notifierMessage = `Запись на отгрузку ${no} ${
        scheduleNo ? 'обновляется' : 'добавляется'
      }`;
      notifier.show(no, notifierMessage);
    } catch (err) {
      alert(err.message);
    }
  };
