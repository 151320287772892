import React, { memo } from 'react';
import {compose} from "redux"
import { reduxForm } from 'redux-form';
import useTabs from 'hooks/use-tabs';
import classNames from 'classnames';
import {
  FILTER_BY_OTHER,
  FILTER_BY_PROPERTIES,
  FILTER_BY_TEXT_INPUT,
  FILTER_BY_TILDA_VALUES,
  ORDER_LIST_FILTER_FORM,
} from '../constants';
import { RenderCheckboxFilter, RenderListFilters, RenderTextInputFilters } from '../fields';
import useDeepEffect from "hooks/use-deep-effect"

const FilterForm = ({ serverFilterGroups, clientFilterGroups }) => {
  const { setTabs, toggleTab, isTabExists } = useTabs();
  let maxHeight = 650;

  useDeepEffect(() => {
    setTabs([]);

    let els = document.querySelectorAll('.order-filter__group__head');

    for (let i = 0; i < els.length; i++) {
      maxHeight += els[i].offsetHeight;
    }
  }, [serverFilterGroups]);

  return (
    <form style={{ maxHeight: maxHeight + 'px' }}>
      {clientFilterGroups.map(filterGroup => (
          <React.Fragment key={filterGroup.key}>
            <div className="order-filter__group">
              {filterGroup.name && (
                <div
                  onClick={() => {
                    toggleTab(filterGroup.key);
                  }}
                  className="order-filter__group__head"
                >
                  <i
                    className={classNames('order-filter__group__head__icon', 'icon', {
                      'icon-order-filter_group_head_plus': !isTabExists(filterGroup.key),
                      'icon-order-filter_group_head_minus': isTabExists(filterGroup.key),
                    })}
                  />
                  <p className="order-filter__group__head__text">{filterGroup.name}</p>
                </div>
              )}
              {([FILTER_BY_TILDA_VALUES, FILTER_BY_PROPERTIES].includes(filterGroup.type) && (
              <RenderCheckboxFilter
                filterGroup={filterGroup}
                show={!isTabExists(filterGroup.key)}
              />
            )) ||
              (filterGroup.type === FILTER_BY_TEXT_INPUT && (
                <RenderTextInputFilters
                  filterGroup={filterGroup}
                  show={!isTabExists(filterGroup.key)}
                />
              )) ||
              (filterGroup.type === FILTER_BY_OTHER && (
                <RenderListFilters filterGroup={filterGroup} />
              ))}
            </div>
            <hr className="order-filter__separator" />
          </React.Fragment>
      ))}
    </form>
  );
};

export default compose(
  reduxForm({
    form: ORDER_LIST_FILTER_FORM,
    destroyOnUnmount: false,
  }),
  memo
)(FilterForm);
