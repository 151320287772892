/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';

const Iframe = props => {
  const frame = useRef();
  const { onLoad, ...rest } = props;

  useEffect(() => {
    frame.current.addEventListener('load', onLoad);

    return () => frame.current.removeEventListener('load', onLoad);
  }, []);

  return <iframe ref={frame} {...rest} />;
};

export default Iframe;
