import React from 'react';
import { Link } from 'react-router-dom';
import colorStatusDemofond from '../../../../helper/colorStatusDemofond';
import sortDates from 'helpers/sortDates';

const ListColumns = [
  {
    Header: 'Дата обращения',
    accessor: 'date',
    sortMethod: sortDates,
  },
  {
    Header: 'Номер заказа',
    accessor: 'projectNo',
    Cell: row => {
      if (!row) return null;
      return <Link to={`demofond-detail/${row.value}`}>{row.value}</Link>;
    },
  },
  {
    Header: 'Конечный заказчик',
    accessor: 'customerName',
    Cell: row => {
      if (!row) return null;
      return row.value || '-';
    },
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: row => {
      if (!row) return null;
      return <span className={`status ${colorStatusDemofond(row.value)}`}>{row.value}</span>;
    },
  },
  {
    Header: 'Дата выдачи',
    accessor: 'receiptDate',
    sortMethod: sortDates,
    Cell: row => {
      if (!row) return null;
      return row.value || '-';
    },
  },
  {
    Header: 'Дата возврата',
    accessor: 'returnDate',
    sortMethod: sortDates,
    Cell: row => {
      if (!row) return null;
      return row.value || '-';
    },
  },
];

export default ListColumns;
