import React from 'react';
import { useSelector, useStore } from 'react-redux';
import headerCreators from './headerCreators';
import createHeaders from './helpers/createHeaders';

const HeaderInfo = props => {
  const {
    select: {
      order: { getSettings },
    },
  } = useStore();

  const settings = useSelector(getSettings);

  const { visible, className } = props;

  const rootClassName =
    className ||
    ['actions-container', 'actions-container--order', 'actions-container__header'].join(' ');

  const headers = createHeaders(headerCreators, { ...props, settings }, visible);

  return (
    <div className={rootClassName}>
      <div className="row event-actions">
        {headers.map(header => (
          <div key={header.key}>
            <span>{header.label}</span> {header.value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeaderInfo;
