import React, { useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getCurrencySymbol } from 'common/util';
import ReactModal from 'react-modal';
import { LoadingSubmitButton } from 'components/loading-sumbit-button';
import { get } from 'lodash';
import useModalPositionToElem from 'hooks/use-modal-position-to-elem';
import useClientRect from 'hooks/use-client-rect';
import classNames from 'classnames';
import { isCactus } from 'common/config';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'pages/Orders/selectors';
import { getEffectLoading } from 'common/selectors';
import LoadItemsForm from './components/load-items-form';

const classModal = classNames('Modal', 'Modal__items-import-form', { 'Modal--cactus': isCactus });
const textMerlion = isCactus ? '' : ' Merlion';

const ItemsImport = React.memo(({ style }) => {
  const { currency } = useSelector(getUser);
  const {
    items: { importFromFile, importFromText },
  } = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const textRef = useRef('');

  const [rectElem, refElem] = useClientRect();
  const [rectModal, refModal] = useClientRect();
  const modalContentStyle = useModalPositionToElem(rectElem, rectModal, 'left');

  const loading = useSelector(getEffectLoading('items', 'importFromText'));

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleImportFromText = async () => {
    await importFromText({
      id,
      value: textRef.current?.value,
    });

    setIsOpen(false);
  };

  const handleImportFromFile = useCallback(async handleData => {
    try {
      await importFromFile({ handleData });
      setIsOpen(false);
    } catch (e) {
      throw e;
    }
  }, []);

  return (
    <div style={style} className="align-items-center">
      <i
        ref={refElem}
        className="icon icon-loadxls enabled"
        onClick={handleOpen}
        title="Вставить из Excel файла"
      />
      <ReactModal
        contentRef={refModal}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Modal"
        className={classModal}
        overlayClassName="Overlay Overlay__columns"
        ariaHideApp={false}
        style={{
          content: {
            ...modalContentStyle,
          },
        }}
      >
        <div className="order-import-area">
          <div className="order-import-header text-left underlined underlined--red">
            Вставить из Excel
            <i
              className="icon icon-close12 items-import-closer enabled"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="forms">
            <div className="row">
              <div className="col-sm text-center">
                <b>Код/Part#</b>
              </div>
              <div className="col-sm text-center">
                <b>ЖКол</b>
              </div>
              <div className="col-sm text-center">
                <b>ЖЦена ({getCurrencySymbol(get({ Code: 'USD' }, 'Code', ''))})</b>
              </div>
            </div>
            <textarea
              name="items"
              ref={textRef}
              className="form-control form-control--grey items-import-field"
            />
            <div className="row">
              <div className="col-7 import-price-caption text-left">
                Если ЖЦена не указана, то сохраняется <br />
                рекомендуемая цена для данного клиента
              </div>
              <div className="col-5 text-right">
                <LoadingSubmitButton
                  label="Добавить товар"
                  position="left"
                  fetching={loading}
                  onClick={handleImportFromText}
                  btnClass="btn btn-outline-danger btn--rect"
                />
              </div>
            </div>
          </div>
          <div className="forms forms--dashed">
            <div className="text-left" style={{ marginBottom: 5 }}>
              <b>
                Импорт из Excel-файла<sup>*</sup>
              </b>
            </div>
            <LoadItemsForm onSubmit={handleImportFromFile} />
            <p className="import-file-caption text-left">
              {/* eslint-disable react-hooks/exhaustive-deps */}
              <sup>*</sup> -{' '}
              {`Excel-файл для создания заказа можно сформировать
              в эксельном прайс-листе${textMerlion}. Для этого в колонке прайс-листа
              "Заказ" необходимо поставить желаемые количества нужных товаров
              и нажать кнопку "Сформировать файл для создания заказа в
              интернет-модуле". Если вы не подписаны на рассылку прайс-листов,
              то вы можете обратиться к вашему менеджеру, или скачать
              актуальный прайс-лист из раздела "Загрузка" -> "Прайс-лист".`}
              {/* eslint-enable react-hooks/exhaustive-deps */}
            </p>
          </div>
        </div>
      </ReactModal>
    </div>
  );
});

export default ItemsImport;
