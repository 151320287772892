import { useSelector, useStore } from 'react-redux';
import React from 'react';
import DefaultField from 'pages/Orders/components/order-edit/header-form/components/defaultField';
import { useDesignSelect } from 'hooks/new';

const PackingTypeField = ({
  input: { value, onChange, name },
  label,
  className,
  arrowRenderer,
}) => {
  const {
    select: { order },
  } = useStore();
  const { packingRows = [] } = useSelector(order.getSettings);
  const options = packingRows.map(packing => ({ value: packing, label: packing }));

  const Select = useDesignSelect();

const handleChange = ({ value }) => {
  onChange(value);
};

  if (!options.length) {
    return <DefaultField title={label} fieldName={name} placeholder="Нет доступной упаковки" />;
  }

  return (
    <div>
      <label>{label}</label>
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        options={options}
        searchable={false}
        clearable={false}
        className={className}
        arrowRenderer={arrowRenderer}
      />
    </div>
  );
};

export default PackingTypeField;
