import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';
import Mixpanel from 'services/mixpanel';

import styles from './logout.module.scss';

export const Logout = ({ className }) => {
  const {
    auth: { logout },
  } = useDispatch();

  const handleClick = () => {
    Mixpanel?.track('Выход');
    logout();
  };

  return (
    <div className={cn(styles['logout'], className)} data-testid="logout">
      <IconButton className={styles['logout__button']} onClick={handleClick}>
        <Icon name="exit" />
        Выход
      </IconButton>
    </div>
  );
};

Logout.defaultProps = {
  className: '',
};

Logout.propTypes = {
  className: PropTypes.string,
};
