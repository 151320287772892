import React from 'react';
import BrowserWarning from 'containers/browser-warning';
import classnames from 'classnames';
import { isCactus } from 'common/config';
import cactusLogo from 'img/cactus-logo.png';
import mainLogo from 'img/logo_top.gif';

const logo = isCactus ? cactusLogo : mainLogo;

const classLayout = classnames('layout', { 'theme-cactus': isCactus });

const GuestLayout = props => {
  return (
    <div className={classLayout}>
      <div>
        <img src={logo} alt="" className="logo" />
        <div className="mainMenu mainMenu--clear" />
      </div>
      {props.children}
      <BrowserWarning />
    </div>
  );
};

export default GuestLayout;
