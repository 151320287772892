import React from 'react';
import TooltipCell from 'components/tooltip-cell';
import MarkLinked from '../components/linked-mark';
import LinkedActions from '../components/linked-actions';

export const columns = [
  {
    Header: 'Id',
    accessor: 'no',
    width: 90,
    sortable: false,
  },
  {
    Header: 'Бренд',
    accessor: 'brand',
    width: 90,
    sortable: false,
  },
  {
    Header: 'Part#',
    accessor: 'partNo',
    width: 90,
    sortable: false,
    Cell: row => {
      if (!row || !row.value) {
        return null;
      }

      return (
        <TooltipCell index={row.original.no + '0'} tableNo={0}>
          {row.value}
        </TooltipCell>
      );
    },
  },
  {
    Header: 'Наименование',
    accessor: 'name',
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
    sortable: false,
  },
  {
    Header: 'EAN',
    accessor: 'ean',
    width: 110,
    sortable: false,
  },
  {
    Header: '',
    accessor: 'linked',
    Cell: MarkLinked,
    width: 50,
    sortable: false,
  },
];

export const linkedColumns = hasCorr => {
  return [
    {
      Header: '',
      accessor: '',
      headerStyle: { width: 25 },
      id: 'remove',
      Cell: LinkedActions,
      sortable: false,
      width: 25,
    },
    {
      Header: '',
      headerStyle: { width: 80 },
      accessor: 'no',
      width: 80,
      sortable: false,
    },
    {
      Header: '',
      headerStyle: { width: 80 },
      accessor: 'brand',
      width: 80,
      sortable: false,
    },
    {
      Header: '',
      headerStyle: { width: 100 },
      accessor: 'partNo',
      width: 100,
      sortable: false,
      Cell: row => {
        if (!row || !row.value) {
          return null;
        }

        return (
          <TooltipCell index={row.original.no + '1'} tableNo={1}>
            {row.value}
          </TooltipCell>
        );
      },
    },
    {
      Header: '',
      headerStyle: { width: 90, textAlign: 'left' },
      style: { textAlign: 'left', fontSize: hasCorr ? 11 : 14 },
      accessor: 'name',
      sortable: false,
    },
    {
      Header: '',
      headerStyle: { width: 110 },
      accessor: 'ean',
      width: 110,
      sortable: false,
    },
  ];
};
