import React from 'react';
import Header from './header';
import Footer from './footer';
import ArrowUp from './arrow-up';
import BrowserWarning from 'containers/browser-warning';
import Banner from './banner/components';
import classnames from 'classnames';
import { isCactus } from 'common/config';
import { routes } from 'common/routes';

const classLayout = classnames('layout', { 'theme-cactus': isCactus });

const ForbiddenLayout = props => {
  const { children } = props;

  return (
    <div className={classLayout}>
      <Header routes={routes} />
      {children}
      <ArrowUp />
      <BrowserWarning />
      <Footer />
    </div>
  );
};

export default ForbiddenLayout;
