import React, { memo } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

const InfoHead = ({ productHead }) => {
  return (
    <React.Fragment>
      <div className="info__head-small">
        <p
          className={classNames('info__head-small__left', 'font-color__red', 'font-size__regular')}
        >
          {productHead.brand}
        </p>
        <p
          className={classNames('info__head-small__right', 'font-color__black', 'font-size__small')}
        >
          Id {productHead.id}
        </p>
      </div>
      <p className={classNames('info__head', 'font-size__xlarge', 'font-color__black')}>
        {productHead.shortName}
      </p>
      <p className={classNames('info__description', 'font-color__grey', 'font-size__small')}>
        {productHead.extraName}
      </p>
    </React.Fragment>
  );
};

export default memo(InfoHead, isEqual);
