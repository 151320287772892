// @ts-nocheck
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import alert from 'components/native/alert/function';
import validate from '../../../../common/validates/validateFeedBack';
import { FormFeedback, targetOptions } from './components/FormFeedback';
import { formattingData } from '../../helpers/formatting-data';

const mapDispatch = ({ feedback: { sendFeedback } }) => ({
  sendFeedback,
});

export default compose(
  connect(null, mapDispatch),
  reduxForm({
    form: 'FEEDBACK_FORM',
    initialValues: {
      target: targetOptions[0].value,
    },
    validate,
    onSubmit(values, dispatch, props) {
      const { sendFeedback, reset } = props;
      const formData = formattingData(values);
      return sendFeedback(formData).then(({ message }) => {
        alert(message);
        reset();
        return message;
      });
    },
  }),
)(FormFeedback);
