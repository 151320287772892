import React, { useState, useEffect } from 'react';
import Bowser from 'bowser';
import { validBrowsers } from '../../constants/valid-browsers';

const BrowserWarning = props => {
  const { changeNotifySettings, isDeprecationBrowser } = props;

  const [isValidBrowser, setIsValidBrowser] = useState(true);

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValidB = browser.satisfies(validBrowsers);
    setIsValidBrowser(isValidB);
  }, []);

  if (isValidBrowser || !isDeprecationBrowser) {
    return '';
  }

  return (
    <div className="browser-alert-container alert alert-warning" role="alert">
      <p>
        <div>
          <h4 className="alert-heading">Вы используете старую версию браузера</h4>
          Для корректной работы рекомендуем использовать последнюю версию Google Chrome, Mozilla
          FireFox или Yandex Browser
        </div>
        <button className="btn btn-light" onClick={() => changeNotifySettings(false)}>
          Понятно
        </button>
      </p>
    </div>
  );
};

export default BrowserWarning;
