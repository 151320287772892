import { compose } from 'redux';
import { connect } from 'react-redux';
import withToggle from 'hoc/withToggle';
import TableList from 'components/table/list';
import { getOrdersVisibility, isOrdersVisibilitySaved } from '../selectors';
import withVirtualList from 'hoc/withVirtualList';
import withMemo from 'hoc/withMemo';

const mapStateToProps = state => ({
  visibility: getOrdersVisibility(state),
  saved: isOrdersVisibilitySaved(state),
});

const OrdersTableList = compose(
  connect(mapStateToProps, null, null, { forwardRef: true }),
  withMemo(['loading', 'visibility', 'filtered', 'data', 'joined']),
  withToggle(),
  withVirtualList,
)(TableList);

export default OrdersTableList;
