/**
 *
 * @param   {string|Date} dateString
 * @returns {Date}
 */
export function convertDateString(dateString = '') {
  if (dateString instanceof Date) {
    return dateString;
  }

  return new Date(dateString.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$3-$2-$1'));
}
