// @ts-nocheck
import notifier from 'services/new/notifier';

export default ({ newOrdersNew, api }) =>
  async payload => {
    const { id, order: orderNo } = payload;
    notifier.show(orderNo, `Товар удаляется из заказа ${orderNo}`);

    try {
      newOrdersNew.setLoadingOrders({ orderNo, isFetching: true, isItems: true });

      await api.post({
        url: `new-orders-new/delete/row/${orderNo}`,
        data: {
          itemId: id,
          lineNo: payload[`lineNo_${orderNo}`],
        },
        successPayload: {
          id,
        },
      });
    } catch (err) {
      console.error(err);
      notifier.resolve({ message: 'Неудачная попытка удаления товара из заказа!' });
    } finally {
      newOrdersNew.setLoadingOrders({ orderNo, isFetching: false, isItems: true });
    }
  };
