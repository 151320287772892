/* eslint-disable quote-props */
import { formatDate } from 'react-day-picker/moment';

export const CLAIMS_FORM = 'CLAIMS_FORM';
export const PAGE_SIZE = 20;
export const NO_DATA_TEXT = 'Данных нет';
export const DATA_LOADING = 'Данные загружаются...';
export const ALLOWED_EXTENSIONS = ['gif', 'jpg', 'doc', 'xls', 'zip', 'rar', 'pdf'];
export const REAL_COUNT_FIELD_KEY = 'realCount';
export const CLAIMS_TYPE_FIELD_KEY = 'claimsType';
export const FILE_FIELD_KEY = 'file';
export const REAL_COUNT_MIN_ONE_CLAIM_TYPES = [0, 1, 2];
export const FORM_INITIAL_VALUES = {
  cargoReceipt: '',
  uzNo: '',
  receivedBags: '',
  receivedBoxes: '',
  receivedPlaces: '',
  sealNumbers: '',
  recDate: formatDate(new Date(), 'DD.MM.Y', 'ru'),
  products: [],
};

export const claimTypes = {
  Пересорт: 1,
  Недогруз: 2,
  Перегруз: 3,
  БНТ_Некомплект: 6,
  БНТ_Битый: 8,
  ПУ_Доставка: 9,
};

export const claimTypesNeedFiles = {
  Перегруз: 3,
  БНТ_Некомплект: 6,
  БНТ_Битый: 8,
  ПУ_Доставка: 9,
};
