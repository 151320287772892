import { createSelector } from 'reselect';
import { memoize } from 'lodash';
import { getEffectLoading } from 'common/selectors';

export const getSettings = state => state.personal;

export const getSettingsInfo = createSelector(getSettings, ({ info }) =>
  memoize(isNew => (isNew ? { cityId: 0, regionId: 0 } : info)),
);

export const isEmailConfirmed = createSelector(
  getSettings,
  ({ info: { emailConfirmed } }) => emailConfirmed,
);

export const getCities = createSelector(getSettings, ({ cities }) => cities);

export const getCorrespondence = state => state.bindings.correspondence || [];
export const getSelectedCorrespondence = createSelector(getCorrespondence, correspondence =>
  memoize(selected => correspondence.filter(corr => selected.includes(corr.no))),
);

export const isLinkedRemoving = createSelector(
  state => state.bindings.fetchingRemove,
  removing => memoize(index => removing[index]),
);

export const isItemLinked = createSelector(
  state => state.bindings.linked,
  state => state.bindings.choosed.no,
  state => state.bindings.current,
  (linked, choosed, current) =>
    memoize(
      (no, islinked) =>
        linked[no]?.length || (islinked && (!linked[no] || (no === choosed && current.length))),
    ),
);

export const getLinkingsRows = createSelector(
  state => state.linkings.rows,
  rows => (Array.isArray(rows) && rows) || [],
);

export const getFetchingBindingItems = createSelector(
  getEffectLoading('bindings', 'searchByML'),
  getEffectLoading('bindings', 'saveCorrespondence'),
  getEffectLoading('bindings', 'findItems'),
  (...args) => args.some(loading => loading),
);
