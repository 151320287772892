import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
  useRef,
} from 'react';
import { isFinite } from 'lodash';
import classNames from 'classnames';
import alert from 'components/native/alert/function';
import { transitItems } from 'pages/Orders/constances';

const QtyFieldTransit = ({ row, fromMsk }, ref) => {
  const { id, availableMSK1, desiredQty, qty, desiredPrice } = row.original;

  const value = fromMsk ? desiredQty - qty : row.value;

  const [isDisabled, setIsDisabled] = useState(true);
  const [currentValue, setCurrentValue] = useState(value);
  const [notified, setNotified] = useState();

  const prevDisabled = useRef(isDisabled);

  useEffect(() => {
    if (isDisabled) {
      setCurrentValue(value);
      transitItems.delete(id);
    } else {
      const available = parseInt(availableMSK1);

      if (fromMsk) {
        if (parseInt(value) > available) {
          if (!notified && prevDisabled.current !== isDisabled) {
            alert('Превышение доступного количества товара на складе МСК');
          }

          setCurrentValue(available);
          transitItems.set(id, { id, desiredQty: available, price: desiredPrice });
        } else {
          transitItems.set(id, { id, desiredQty: value, price: desiredPrice });
        }
      } else {
        transitItems.set(id, value);
      }
    }

    prevDisabled.current = isDisabled;
  }, [isDisabled, value, id]);

  useImperativeHandle(
    ref,
    () => ({
      toggle(checked, notified = false) {
        setIsDisabled(!checked);
        fromMsk && setNotified(checked && notified);
      },
    }),
    [currentValue, isDisabled, fromMsk],
  );

  const handleChange = e => {
    const { value: val } = e.target;

    if (fromMsk) {
      if (parseInt(val) > parseInt(availableMSK1)) {
        alert('Превышение доступного количества товара на складе МСК');

        setCurrentValue(parseInt(availableMSK1));

        return false;
      }

      if (parseInt(val) <= 0) {
        alert('Жкол переносимого товара должно быть больше нуля.');
        setCurrentValue(value);

        return false;
      }
    }

    setCurrentValue(val);
  };

  const handleBlur = useCallback(
    e => {
      const { value: newValue } = e.target;

      if (!isFinite(+newValue)) {
        return setCurrentValue(value);
      }

      transitItems.set(id, !fromMsk ? newValue : { id, desiredQty: newValue, price: desiredPrice });
    },
    [id, fromMsk, desiredPrice],
  );

  const classNamesForTransitInput = classNames('reserve-from-transit__number', {
    'reserve-from-transit__number--active': !isDisabled,
  });

  return (
    <input
      className={classNamesForTransitInput}
      type="number"
      min="0"
      disabled={isDisabled}
      value={currentValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default forwardRef(QtyFieldTransit);
