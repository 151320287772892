import ObjCollector from 'services/objCollector';
import { createContext } from 'use-context-selector';
import { createContext as context} from 'react'

export const ITEMS_TABLE_NAME = 'items';
export const transitItems = new ObjCollector();

export const OrderFiltersContext = createContext({});
export const OrderViewContext = context({});

export const ID_ORDER_HEADER_FORM = 'order-header-form';
