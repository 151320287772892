import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Search = ({
  className,
  color = '#000',
  name,
  width = '16',
  height = '16',
  viewBox = '0 0 16 16',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M11.2204 11.4213L10.8994 11.0977L10.5475 11.3876C9.48595 12.2623 8.12674 12.7872 6.6436 12.7872C3.25058 12.7872 0.5 10.0366 0.5 6.6436C0.5 3.25058 3.25058 0.5 6.6436 0.5C10.0366 0.5 12.7872 3.25058 12.7872 6.6436C12.7872 8.14191 12.2515 9.51379 11.3606 10.5801L11.064 10.935L11.3949 11.2583L15.2888 15.0629L15.0602 15.2915L11.2204 11.4213Z"
        stroke={color}
      />
    </svg>
  );
};

Search.defaultProps = {
  className: '',
  color: '#000',
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
};

Search.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
