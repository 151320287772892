import { API_URL } from 'common/config/app';

export const orderListApi = {
  'new-orders/list': {
    endpoint: `${API_URL}/v1/orders/index/list`,
  },
  'new-orders/shipment-subscription': {
    endpoint: `${API_URL}/v1/logistic/shipments-subscription`,
  },
  'new-orders/shipment-subscription/update': {
    endpoint: `${API_URL}/v1/logistic/shipments-subscription/update`,
  },
  'new-orders/contacts': {
    endpoint: `${API_URL}/v1/orders/index/contacts`,
    success: 'newOrders/fetchContactsSuccess',
  },
};
