import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FieldArray, reduxForm } from 'redux-form';
import Content from 'components/layout/content';
import InputsClaims from './components/InputsClaims';
import FormActions from './components/FormActions';
import InputsProducts from './components/InputsProducts';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import { CLAIMS_FORM, FORM_INITIAL_VALUES } from 'pages/Logistic/components/claims-new/constants';
import { QUEUE_CLAIM } from 'common/config';
import validate from 'common/validates/validateClaim';
import isEmpty from "lodash/isEmpty"
import history from "common/history"

function ClaimsNew(props) {
  const { handleSubmit, submitting } = props;

  const {
    claims: { clearProducts },
  } = useDispatch();

  const isLoadingProducts = useSelector(getEffectLoading('claims', 'selectOrder'));
  const products = useSelector(state => state.claims.products);

  useEffect(() => clearProducts, []);

  return (
    <Content title="Создание претензии">
      <form onSubmit={handleSubmit} className="claims-new__form">
        <InputsClaims />
        <FormActions
          products={products}
          isLoading={isLoadingProducts}
          submitting={submitting}
          handleSubmit={handleSubmit}
        />
        <FieldArray
          name="products"
          products={products}
          isLoading={isLoadingProducts}
          component={InputsProducts}
        />
        <div className="claims-new__btn_container">
          <button
            type="submit"
            className={classNames('btn', 'btn--rect', 'btn-outline-danger')}
            disabled={isLoadingProducts || submitting || isEmpty(products)}
            title={isEmpty(products) ? 'Нет товаров!' : ''}
          >
            Создать претензию
          </button>
          <Link
            to="/logistic/claims"
            className={classNames('btn', 'btn--rect', 'btn-outline-danger')}
          >
            Назад к претензиям
          </Link>
        </div>
      </form>
    </Content>
  );
}

export default reduxForm({
  form: CLAIMS_FORM,
  onSubmit: async (formValues, { claims: { create } }) => {
    const docId = await create(formValues);

    if (docId) {
      history.push({
        pathname: `/logistic/claims/${docId}`,
        state: { queueType: QUEUE_CLAIM },
      });
    }
  },
  touchOnChange: true,
  validate,
  initialValues: FORM_INITIAL_VALUES,
})(ClaimsNew);
