import React from 'react';
import Registration from "pages/Marketing/components/events/components/Registration"

export default [
  {
    Header: 'Период',
    id: 'start',
    accessor: 'start',
    Cell: row => (row && `${row.original.start} - ${row.original.end}`) || null,
  },
  {
    Header: 'Старт',
    id: 'startPeriod',
    accessor: 'start',
    show: false,
  },
  {
    Header: 'Финиш',
    id: 'endPeriod',
    accessor: 'end',
    show: false,
  },
  {
    Header: 'Событие',
    accessor: 'name',
    minWidth: 300,
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
  },
  {
    Header: 'Вендор',
    accessor: 'vendor',
  },
  {
    Header: 'Город',
    id: 'city',
    accessor: 'city',
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: row =>
      (row && (
        <span style={{ color: row.value === 'Закрыта' ? 'red' : 'green' }}>{row.value}</span>
      )) ||
      null,
  },
  {
    Header: 'Регистрация',
    id: 'registration',
    Cell: Registration,
    sortable: false,
  },
];
