// @ts-nocheck
import React, { useMemo } from 'react';
import { useStore, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import Content from 'components/layout/content';
import withApi from 'hoc/withApi';
import TableList from 'components/table/list';
import ShipmentInfo from './shipment-info';
import shipmentItemsColumn from '../../common/columns/shipment-item-columns';

const ShipmentItem = () => {
  const {
    select: {
      shipments: { getHeader, getVolume },
    },
  } = useStore();

  const { id } = useParams();

  const header = useSelector(getHeader);
  const volume = useSelector(getVolume);
  const columns = useMemo(() => shipmentItemsColumn(volume), [volume]);

  return (
    <Content title={`Просмотр отгрузок по заказу ${id}`}>
      <div className="actions-container">
        <div className="event-actions">
          <Link to="/logistic/shipments" className="section-nav" style={{ padding: '0 10px' }}>
            <i className="icon icon-back-icon" /> Список отгрузок
          </Link>
          <Link
            to={`/orders/view/${id}`}
            style={{
              display: 'flex',
              alignSelf: 'center',
            }}
          >
            <i className="icon icon-view" title="Просмотр заказа" />
          </Link>
        </div>
      </div>
      <ShipmentInfo info={header} orderNo={id} />
      <div className="table-container">
        <TableList
          columns={columns}
          pageSize={5000}
          defaultPageSize={5000}
          defaultSorted={[
            {
              id: 'id',
              desc: true,
            },
          ]}
        />
      </div>
    </Content>
  );
};

export default withApi(ShipmentItem);
