import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Score = ({
  className,
  color = '#236192',
  name,
  width = '12',
  height = '12',
  viewBox = '0 0 12 12',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M2.11111 0.5H9.88889C10.1967 0.5 10.3231 0.567719 10.3762 0.617885C10.4235 0.662499 10.5 0.775895 10.5 1.09091V7.79289L6.79289 11.5H2.11111C1.80334 11.5 1.67691 11.4323 1.62376 11.3821C1.57649 11.3375 1.5 11.2241 1.5 10.9091V1.09091C1.5 0.775895 1.57649 0.662499 1.62376 0.617885C1.67691 0.567719 1.80334 0.5 2.11111 0.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <rect x="3" y="3" width="6" height="1" fill={color} />
      <rect x="3" y="5" width="6" height="1" fill={color} />
    </svg>
  );
};

Score.defaultProps = {
  className: '',
  color: '#236192',
  width: '12',
  height: '12',
  viewBox: '0 0 12 12',
};

Score.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
