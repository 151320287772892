import React, { memo } from 'react';
import OrderCodeOrPartFilter from './components/OrderCodeOrPartFilter';
import OrderContactFilter from './components/OrderContactFilter';
import OrderNoOrZprFilter from './components/OrderNoOrZprFilter';

const OrderHeaderFilter = () => {
  return (
    <React.Fragment>
      <OrderNoOrZprFilter />
      <OrderCodeOrPartFilter />
      <OrderContactFilter />
    </React.Fragment>
  );
};

export default memo(OrderHeaderFilter);
