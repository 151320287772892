import { useRef } from 'react';

export const useLatest = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef(value);
  // Store current value in ref
  ref.current = value;
  // Return latest value
  return ref.current;
};
