import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Mixpanel from 'services/mixpanel';

const Accept = props => {
  const { action, accept, index = 0 } = props;

  const fetching = useSelector(state => get(state, ['actions', 'fetching', index], false));
  const now = Math.floor(Date.now() / 1000);

  return (
    <div className="btn-container">
      {now > action.sortEnd ? (
        <div className="finished">Акция завершена</div>
      ) : action.registered ? (
        <div className="registered">
          <i className="icon icon-registered" />
          Вы зарегистрированы
        </div>
      ) : fetching === true ? (
        <div className="loading">Запрос отправлен</div>
      ) : fetching === 'done' ? (
        <div className="registered">
          <i className="icon icon-registered" />
          Вы зарегистрированы
        </div>
      ) : fetching === 'fail' ? (
        <div>Произошла ошибка</div>
      ) : (
        <button
          className="btn btn-outline-danger btn--rect"
          onClick={() => {
            Mixpanel?.track('СтрГлавнаяСтраница.АкцияУчастие', {
              page: 'СтрГлавнаяСтраница',
              id: action.no,
            });
            return accept({ original: action, index });
          }}
        >
          Принять участие
        </button>
      )}
    </div>
  );
};

Accept.propTypes = {
  action: PropTypes.object.isRequired,
  accept: PropTypes.func.isRequired,
  index: PropTypes.number,
};

export default Accept;
