import alert from 'components/native/alert/function';

export default data =>
  async ({ api }) => {
    try {
      const { success, message } = await api.post({
        url: 'view/settings/save',
        data,
      });

      if (!success) {
        alert(message);
      }
    } catch (err) {
      console.error(err);
    }
  };
