import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Delivery = ({
  className,
  color = '#808080',
  name,
  width = '30',
  height = '14',
  viewBox = '0 0 30 14',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M19 13.5C19.8284 13.5 20.5 12.8284 20.5 12C20.5 11.1716 19.8284 10.5 19 10.5C18.1716 10.5 17.5 11.1716 17.5 12C17.5 12.8284 18.1716 13.5 19 13.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 13.5C9.82843 13.5 10.5 12.8284 10.5 12C10.5 11.1716 9.82843 10.5 9 10.5C8.17157 10.5 7.5 11.1716 7.5 12C7.5 12.8284 8.17157 13.5 9 13.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 13.5C4.82843 13.5 5.5 12.8284 5.5 12C5.5 11.1716 4.82843 10.5 4 10.5C3.17157 10.5 2.5 11.1716 2.5 12C2.5 12.8284 3.17157 13.5 4 13.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 13.5C26.8284 13.5 27.5 12.8284 27.5 12C27.5 11.1716 26.8284 10.5 26 10.5C25.1716 10.5 24.5 11.1716 24.5 12C24.5 12.8284 25.1716 13.5 26 13.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 12H29V6.21054L26.3077 2.00002H22"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <mask
        id="path-6-outside-1"
        maskUnits="userSpaceOnUse"
        x="0"
        y="1.52588e-05"
        width="23"
        height="13"
        fill="black"
      >
        <rect fill="white" y="1.52588e-05" width="23" height="13" />
        <path d="M2.5 12H1V1.00002H22V12" />
      </mask>
      <path
        d="M1 12H0C0 12.5523 0.447715 13 1 13L1 12ZM1 1.00002V1.52588e-05C0.447715 1.52588e-05 0 0.44773 0 1.00002L1 1.00002ZM22 1.00002H23C23 0.44773 22.5523 1.52588e-05 22 1.52588e-05V1.00002ZM2.5 11H1V13H2.5V11ZM2 12V1.00002H0V12H2ZM1 2.00002H22V1.52588e-05H1V2.00002ZM21 1.00002V12H23V1.00002H21Z"
        fill={color}
        mask="url(#path-6-outside-1)"
      />
      <path d="M25 12L20 12" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M18 12L10 12" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M8 12H5" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path
        d="M1 10H29"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 4.00002H25V7.00002H29"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Delivery.defaultProps = {
  className: '',
  color: '#808080',
  width: '30',
  height: '14',
  viewBox: '0 0 30 14',
};

Delivery.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
