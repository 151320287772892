import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Squares = ({
  className,
  color = '#236192',
  name,
  width = '9',
  height = '9',
  viewBox = '0 0 9 9',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M3.07692 3.5H0.923077C0.676142 3.5 0.5 3.32386 0.5 3.07692V0.923077C0.5 0.676142 0.676142 0.5 0.923077 0.5H3.07692C3.32386 0.5 3.5 0.676142 3.5 0.923077V3.07692C3.5 3.32386 3.32386 3.5 3.07692 3.5Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M3.07692 8.5H0.923077C0.676142 8.5 0.5 8.32386 0.5 8.07692V5.92308C0.5 5.67614 0.676142 5.5 0.923077 5.5H3.07692C3.32386 5.5 3.5 5.67614 3.5 5.92308V8.07692C3.5 8.32386 3.32386 8.5 3.07692 8.5Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M8.07692 3.5H5.92308C5.67614 3.5 5.5 3.32386 5.5 3.07692V0.923077C5.5 0.676142 5.67614 0.5 5.92308 0.5H8.07692C8.32386 0.5 8.5 0.676142 8.5 0.923077V3.07692C8.5 3.32386 8.32386 3.5 8.07692 3.5Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M8.07692 8.5H5.92308C5.67614 8.5 5.5 8.32386 5.5 8.07692V5.92308C5.5 5.67614 5.67614 5.5 5.92308 5.5H8.07692C8.32386 5.5 8.5 5.67614 8.5 5.92308V8.07692C8.5 8.32386 8.32386 8.5 8.07692 8.5Z"
        stroke={color}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

Squares.defaultProps = {
  className: '',
  color: '#236192',
  width: '9',
  height: '9',
  viewBox: '0 0 9 9',
};

Squares.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
