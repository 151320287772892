import React, { Fragment, memo, useMemo } from 'react';
import Label from '../components/Label';
import ReactTooltip from 'react-tooltip';
import { flow, isEmpty, isFunction, isObject, trim } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getLendingFormValue } from 'pages/Finances/selectors';
import renderUploadFile from 'components/fields/renderUploadFile';
import { Field } from 'redux-form';
import { LENDING_FORM } from '../constants';
import classNames from 'classnames';
import { fileRequired, fileSize, fileType } from 'common/validates/validateFileLedingPage';

const renderFromValue =
  value =>
  (isTrueView = null, isFalseView = null) => {
    if (isObject(value)) {
      if (isFunction(isTrueView)) {
        return isTrueView({ value, isTrueView, isFalseView });
      }

      return isTrueView;
    }

    if (isFunction(isFalseView)) {
      return isFalseView({ value, isTrueView, isFalseView });
    }

    return isFalseView;
  };

const DocumentField = props => {
  const { required, description, code, name } = props;

  const descriptionIsEmpty = flow(trim, isEmpty)(description);

  const validate = useMemo(() => {
    if (required) {
      return [fileType, fileSize, fileRequired];
    }

    return [fileType, fileSize];
  }, [required]);

  const {
    finances: { deleteUploadedDocument },
  } = useDispatch();
  const file = useSelector(getLendingFormValue(`uploadDocuments.${code}`));
  const render = renderFromValue(file);

  return (
    <div className="form-field-download">
      <div className="form-field-download__content">
        <Label title={name} required={required}>
          <Fragment>
            <i
              className={classNames('icon', 'icon-info-gray', {
                disabled: descriptionIsEmpty,
              })}
              data-tip={description}
              data-for={code}
            />
            <ReactTooltip
              id={code}
              place="bottom"
              disable={descriptionIsEmpty}
              className="form-field-download__tooltip"
            />
          </Fragment>
        </Label>
        <div
          className={classNames('form-field-download__select-file', {
            'form-field-download__select-file--selected': !isEmpty(file),
          })}
        >
          <div className="form-field-download__controller">
            {render(<i className="icon icon-registered" />)}
            <Field
              label={'Выберите файл'}
              code={code}
              name={`creditDoc_${code}`}
              key={props.name}
              model="finances"
              url="/finances/credit/upload-doc"
              form={LENDING_FORM}
              deleteFile={deleteUploadedDocument}
              progressClassName="progress--transparent"
              component={renderUploadFile}
              validate={validate}
            />
          </div>
        </div>
      </div>
      <div className="divider" />
    </div>
  );
};

export default memo(DocumentField);
