// @ts-nocheck
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './icon-button.module.scss';

/**
 * Renders a <IconButton /> component
 * @component
 * @example
 *
 * return (
 *   <IconButton />
 * )
 */
export const IconButton = forwardRef(({ children, className = '', disabled, ...rest }, ref) => {
  const classes = cn(styles['icon-button'], className, {
    [styles['icon-button--disabled']]: disabled,
  });

  const iconButtonProps = { ...rest, className: classes };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button
      disabled={disabled}
      type="button"
      data-testid="icon-button"
      ref={ref}
      {...iconButtonProps}
    >
      {React.Children.map(children, child => {
        if (child?.type) {
          return child;
        }
        return <span>{child}</span>;
      })}
    </button>
  );
});

IconButton.defaultProps = {
  className: '',
  disabled: false,
  onClick: Function.prototype,
};

IconButton.propTypes = {
  /**
   * children
   */
  children: PropTypes.node.isRequired,
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * Able to click
   */
  disabled: PropTypes.bool,
  /**
   * Click event handler
   */
  onClick: PropTypes.func,
};
