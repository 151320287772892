import { def, undef } from '../util';
import { LINKED_MAX_FILE_SIZE } from '../config';

// при попытке загрузки файлов с расширением com и bat mime типы не орпеделяются, делаю провреку по имени файла

const fileTypeForbidden = ['com', 'bat', 'exe', 'cmd'];

export const fileType = file => {
  const fileType = file?.name?.split('.')?.pop()?.toLowerCase() || '' ;
  if (def(file) && fileTypeForbidden.includes(fileType)) {
    return 'Недопустимый формат файла';
  }
};

export const fileRequired = file => {
  if (undef(file)) {
    return 'Загрузите документ';
  }
};

export const fileSize = file => {
  if (def(file) && file.size > LINKED_MAX_FILE_SIZE) {
    return 'Размер принятого файла превысил максимально допустимый размер';
  }
};
