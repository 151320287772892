import getNextClosestDate from 'pages/Orders/helpers/getNextClosestDate';
import moment from 'moment';

export const createInitialDate = (currentDate, dates, isPreorder, isReserve) => {
  if (!isPreorder) {
    if (!isReserve) {
      return currentDate;
    }

    return getNextClosestDate(currentDate, dates);
  }

  return moment().format('DD.MM.YYYY');
};
