import { API_URL } from '../config';

export const LOGIN_PAGE_ROUTE = '/login';
export const CARD_PAGE_PATH = '/products/view';
export const AUTH_ROUTE = `${API_URL}/login`;
export const RECOVER_ROUTE = `${API_URL}/v1/settings/recover/send`;
export const RECOVER_CHANGE_ROUTE = `${API_URL}/v1/settings/recover`;
export const LOGOUT_ROUTE = `${API_URL}/logout`;
export const OUTDATED_PAGE_ROUTE = '/outdated';
export const MAINTENANCE_ROUTE = '/maintenance';
export const DB_CONNECT_CHECK_ROUTE = '/dbauth/:id'
export const GROUP_ITEMS_ROUTE = '/items/:id'
export const ORDER_ITEMS_ROUTE = '/items'
export const PRODUCT_ROUTE = '/items/view/:id'
export const PRODUCT_ITEMS_ROUTE = '/items/relate'
export const PRODUCT_CARD_PAGE_PATH = '/products/card';
