import React, { Fragment } from 'react';
import Document from 'pages/Finances/components/blocked/components/document';
import { useDispatch } from 'react-redux';
import Loader from 'components/loader';

export const DocumentCell = row => {
  const {
    finances: { generateBlocked },
  } = useDispatch();
  if (!row) return null;

  const { docs, generating } = row.original;

  if (!docs) return null;

  const fileExists = docs.every(doc => doc.opNo);

  if (generating && !fileExists) {
    return <Loader className="loader__container--no-margin" />;
  }

  if (fileExists) {
    return (
      <Fragment>
        {generating ? (
          <Loader className="loader__container--no-margin" />
        ) : (
          <a href="#" onClick={async () => await generateBlocked(row.original)}>
            <i
              className="btn-download-blocked__reload icon icon-reload_small"
              title="Пересоздать"
            />
          </a>
        )}
        <div>
          {docs.map(doc => (
            <Document doc={doc} />
          ))}
        </div>
      </Fragment>
    );
  }

  return (
    <a
      href="#"
      className="btn-download-blocked__start"
      onClick={async () => await generateBlocked(row.original)}
    >
      Запросить
    </a>
  );
};
