// @ts-nocheck
export default {
  name: 'feedback',
  state: {},
  effects(dispatch) {
    return {
      async sendFeedback(data) {
        try {
          const res = await dispatch.api.post({
            url: 'feedback',
            data,
          });
          return res;
        } catch (e) {
          return e;
        }
      },
    };
  },
};
