import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

const OsvGenerateButton = ({ period, small, error }) => {
  const {
    osv: { generate },
  } = useDispatch();

  if (small) {
    return (
      <i
        className="icon icon-reload_small"
        onClick={async () => await generate(period)}
        title="Пересоздать"
      />
    );
  }

  return (
    <Fragment>
      <button className="btn btn-danger btn--rect" onClick={async () => await generate(period)}>
        Создать
      </button>
      {error && <div className={`error-text error-text`}>{error}</div>}
    </Fragment>
  );
};

OsvGenerateButton.propTypes = {
  period: PropTypes.string.isRequired,
  small: PropTypes.bool,
  error: PropTypes.string,
};

export default OsvGenerateButton;
