import escapeRegExp from 'lodash/escapeRegExp';
import ReactHtmlParser from 'react-html-parser';

/**
 *
 * @param   {string} string  String to be dashed
 * @returns {string}         Dashed string
 */
function camelCaseToDash(string = '') {
  return str
    .replace(/[^a-zA-Z0-9]+/g, '-')
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/([0-9])([^0-9])/g, '$1-$2')
    .replace(/([^0-9])([0-9])/g, '$1-$2')
    .replace(/-+/g, '-')
    .toLowerCase();
}

/**
 *
 * @param   {string} string  String to be capitalized
 * @returns {string}         Capitalized and camelcased string
 */
function capitalizeAndCamelCase(string = '') {
  return string
    .replace(/\b(\w)/g, (match, capture) => capture.toUpperCase())
    .replace(/(\s+|-)/g, '');
}

/**
 *
 * @param   {string} string  String to be capitalized
 * @returns {string}         Capitalized  string
 */
function capitalize(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
* @param   {string} string  String to be title-cased
* @example
* const text = "foo bar loo zoo moo";
* toTileCase(text); "Foo Bar Loo Zoo Moo"
@example
* @returns {string} Title-cased  string
*/
function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
}

const regExpDefault = new RegExp('\\.', 'g');

const formatStringByMask =
  (mask, searchRegExp = regExpDefault) =>
  value => {
    const onlyNumbers = value.replace(searchRegExp, '');
    let i = 0;
    let lastReplacedIndex = -1;
    const fieldMask = mask.replace(/#/g, (_, j) => {
      if (i >= onlyNumbers.length) {
        return '#';
      }
      lastReplacedIndex = j;
      // eslint-disable-next-line no-plusplus
      return onlyNumbers[i++];
    });
    return fieldMask.substring(0, lastReplacedIndex + 1);
  };

function highlightText(name, text) {
  const pattern = new RegExp(`(${escapeRegExp(text)})`, 'gi');

  return ReactHtmlParser(name.replace(pattern, regText => `<b>${regText}</b>`));
}

export {
  camelCaseToDash,
  capitalize,
  capitalizeAndCamelCase,
  formatStringByMask,
  highlightText,
  toTitleCase,
};
