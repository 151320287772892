// @ts-nocheck
export const formattingIquoteOrder = data => {
  const formatted = {
    endCustomer: data.customerName.na_no,
    endCustomerName: data.customerName.fullname,
    inn: data.inn,
    contact: data.contact,
    email: data.email,
    phone: data.phone,
    configuration: data.configuration,
  };

  return formatted;
};
