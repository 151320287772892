// @ts-nocheck
import React, { useEffect, memo, useState, useMemo, useRef } from 'react';
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';
import { useDispatch, useSelector, useStore } from 'react-redux';
import AppSocket from 'components/AppSocket';
import { useDesign } from 'hooks/new';
import { ID_ORDER_HEADER_FORM } from 'pages/Orders/constances';

const OrderHeaderForm = props => {
  const {
    reset,
    handleSubmit,
    onCancel = Function.prototype,
    onReceive = Function.prototype,
    onBroadcast = Function.prototype,
    onConnect = Function.prototype,
    onNotify = Function.prototype,
  } = props;
  const { children, ...rest } = props;

  const ref = useRef();

  const isNotPreorder = !useIsPreorder();

  const {
    select: { order },
  } = useStore();
  const {
    order: { handleOrder },
    items,
  } = useDispatch();

  const isPickup = useSelector(order.isPickup);
  const { orderNo } = useSelector(order.getHeader);

  const [pickup, setPickup] = useState(isPickup);

  useEffect(() => {
    if (!orderNo) {
      reset();
    }

    return reset;
  }, [orderNo]); // eslint-disable-line

  const channels = useMemo(
    () => ['queue', `queue-${(isNotPreorder && 'order') || 'preorder'}`],
    [isNotPreorder],
  );

  return (
    <AppSocket
      channel={channels}
      onInit={onConnect}
      onReceive={onReceive}
      onCommonReceive={onBroadcast}
      onReceiveNotify={onNotify}
      onExpire={items.onExpire}
    >
      <form
        id={ID_ORDER_HEADER_FORM}
        ref={ref}
        onSubmit={handleSubmit}
        data-testid="form"
      >
        {children({ ...rest, pickup, setPickup })}
      </form>
    </AppSocket>
  );
};

export default memo(OrderHeaderForm);
