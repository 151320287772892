import alert from 'components/native/alert/function';
import { clearSubmitErrors, SubmissionError } from 'redux-form';
import { batch } from 'react-redux';
import { getSettingsInfo } from 'pages/Settings/selectors';
import { getUser } from 'pages/Orders/selectors';
import ReactHtmlParser from 'react-html-parser';
import history from 'common/history';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'personal',
  state: {
    info: {},
    cities: null,
    confirm: { success: false, message: '' },
    fetching: {},
    invitedUser: {},
  },
  reducers: {
    load: (state, payload) => ({ ...state, ...payload }),
    loadInfo: (state, payload) => ({ ...state, info: { ...state.info, ...payload } }),
    loadCities: (state, payload) => ({ ...state, cities: payload }),
    confirm: (state, payload) => ({
      ...state,
      confirm: { ...state.confirm, ...payload },
    }),
    setFetching: (state, { index, flag }) => {
      state.fetching[index] = flag;

      return state;
    },
    'invitedUser/load': (state, payload) => ({
      ...state,
      invitedUser: { ...payload },
    }),
  },
  effects: dispatch => ({
    async confirmEmail({ email, login, confirmation = null }) {
      const { success, message } = await dispatch.api.get({
        url: 'settings/send-confirm',
        query: `?email=${email}&login=${login}`,
        cancellation: false,
      });

      if (success) {
        alert(confirmation || message);

        this.loadInfo({ emailConfirmed: 'wait' });
      }
    },
    async update({ data, location }, state) {
      const isNew = location.pathname.includes('add');
      const { email } = getSettingsInfo(state)(isNew);
      const isConfirmation = location.state?.needConfirm;
      const needConfirm = data.email !== email;

      dispatch(clearSubmitErrors('personalForm'));

      const { success, message } = await dispatch.api.post({
        url: `/settings/${isNew ? 'add' : 'save'}`,
        query: isNew ? '' : `?needConfirm=${needConfirm || 0}`,
        data,
      });

      if (success) {
        dispatch.user.setApproval('true');
      }

      if (needConfirm) {
        dispatch.personal.loadInfo({ emailConfirmed: 'wait' });
      }

      if (!success) {
        throw new SubmissionError({ _error: message });
      }

      await alert(message, {
        confirmLabel: isNew ? 'К списку сотрудников' : 'Ок',
      });

      if (isNew) {
        history.push('/settings/employees');
      } else if (isConfirmation) {
        batch(() => {
          dispatch.personal.confirm({ success: true });
          dispatch.user.setConfirmation({ success: true });
        });

        history.push('/');
      }
    },
    async register({ data, token }, { personal: { invitedUser } }) {
      const { success, message } = await dispatch.api.post({
        url: '/register',
        query: `?token=${token}`,
        data,
      });

      if (!success) {
        throw new SubmissionError({ _error: message });
      }

      dispatch.auth.login({
        clientNo: invitedUser.client,
        clientLogin: invitedUser.login,
        password: data.newPass,
      });

      return message;
    },
    async searchCity(region) {
      const { items } = await dispatch.api.get({
        url: '/settings/search-city',
        query: `?region=${region}`,
      });
      this.loadCities(items);
    },
    async searchRegion(value) {
      const { items } = await dispatch.api.get({
        url: '/settings/search-region',
        query: `?q=${encodeURIComponent(value)}`,
      });

      return items;
    },
    async recoverPass(payload, state) {
      const user = getUser(state);
      const {
        personal: { invitedUser },
      } = state;

      const { success, message } = await dispatch.api.post({
        url: 'settings/password-recover',
        data: payload,
      });

      if (success) {
        const { new: isNew, newPass } = payload;

        if (user?.id) {
          await dispatch.auth.logout(user.id);
        }

        history.push('/');

        await dispatch.auth.login({
          clientNo: invitedUser.client,
          clientLogin: invitedUser.login,
          password: newPass,
        });

        await alert(message);

        return isNew;
      }
    },
    async changePass(data, state) {
      const user = getUser(state);

      const { success, message } = await dispatch.api.post({
        url: '/settings/password-change',
        query: `?id=${user?.login || ''}`,
        data,
      });

      if (success) {
        await alert('Пароль успешно изменён!');
        await dispatch.auth.logout();
        history.push('/');
      } else {
        throw new SubmissionError({
          _error: ReactHtmlParser(message),
        });
      }
    },
    async switchDesign(version) {
      try {
        return await dispatch.api.get({
          url: `/switch-design/${version}`,
        });
      } catch (e) {
        console.error(e);
      }
    },
  }),
  selectors(slice, createSelector, hasProps) {
    return {
      getSettingsInfo: hasProps((_, isNew) =>
        slice(({ info }) => (isNew ? { cityId: 0, regionId: 0 } : info)),
      ),
      getInvitedUser: () => slice(({ invitedUser }) => invitedUser),
    };
  },
};
