import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const SortUp = ({
  className,
  color = '#d4d4d4',
  name,
  width = '12',
  height = '6',
  viewBox = '0 0 12 6',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M5.34921 0.557821C5.7237 0.23683 6.2763 0.23683 6.65079 0.557821L10.9475 4.24075C11.6524 4.84495 11.2252 6 10.2967 6L1.70326 6C0.774849 6 0.347566 4.84495 1.05246 4.24075L5.34921 0.557821Z"
        fill={color}
      />
    </svg>
  );
};

SortUp.defaultProps = {
  className: '',
  color: '#d4d4d4',
  width: '12',
  height: '6',
  viewBox: '0 0 12 6',
};

SortUp.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
