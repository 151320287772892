const createHeaders = (headerCreators, headerParams, allowedKeys) => {
  if (!Array.isArray(allowedKeys)) return [];
  return allowedKeys.map(allowedKey => {
    const headerCreator = headerCreators.find(headerCreator => headerCreator.key === allowedKey);
    if (!headerCreator) {
      throw new Error(`Unknown header creator with key: ${allowedKey}`);
    }
    return {
      key: headerCreator.key,
      ...headerCreator.create(headerParams),
    };
  });
};

export default createHeaders;
