import deleteOrder from './deleteOrder';

export default (state, { items = [], oldNo }) => {
  const newGoods = state.goods.reduce((acc, row) => {
    for (let i = 0, l = items.length; i < l; i++) {
      if (items[i].id === row.id) {
        const item = items[i];
        items.splice(i, 1);

        return [...acc, { ...row, ...item }];
      }
    }

    return [...acc, row];
  }, []);

  const newState = { ...state, goods: [...newGoods, ...items] };

  if (oldNo) {
    return deleteOrder(newState, { orderNo: oldNo, onlyGoods: true });
  }

  return newState;
};
