import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Ruble = ({
  className,
  color = '#f15b5b',
  name,
  width = '18',
  height = '18',
  viewBox = '-1 -1 20 20',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M6 9.208H6.994V4.21C7.34867 4.126 7.72667 4.07 8.128 4.042C8.53867 4.014 8.94467 4 9.346 4C9.77533 4 10.1953 4.042 10.606 4.126C11.026 4.21 11.3947 4.364 11.712 4.588C12.0387 4.812 12.3 5.11533 12.496 5.498C12.692 5.88067 12.79 6.37067 12.79 6.968C12.79 7.556 12.6873 8.046 12.482 8.438C12.286 8.83 12.02 9.14733 11.684 9.39C11.3573 9.63267 10.9793 9.80533 10.55 9.908C10.13 10.0107 9.70067 10.0667 9.262 10.076L8.156 10.09V11.084H10.48V11.952H8.156V13.912H6.994V11.952H6V11.084H6.994V10.076H6V9.208ZM9.374 4.98C9.13133 4.98 8.898 4.98933 8.674 5.008C8.45933 5.01733 8.28667 5.036 8.156 5.064V9.124H9.22C9.5 9.124 9.78 9.096 10.06 9.04C10.34 8.984 10.592 8.87667 10.816 8.718C11.04 8.55933 11.222 8.34467 11.362 8.074C11.502 7.794 11.5673 7.43 11.558 6.982C11.5673 6.59933 11.5113 6.282 11.39 6.03C11.278 5.76867 11.1193 5.56333 10.914 5.414C10.718 5.25533 10.4847 5.14333 10.214 5.078C9.95267 5.01267 9.67267 4.98 9.374 4.98Z"
      fill={color}
    />
    <path
      d="M9 0.999999C13.4183 1 17 4.58172 17 9L19 9C19 3.47715 14.5228 -1 9 -1L9 0.999999ZM1 9C1 4.58172 4.58172 0.999999 9 0.999999L9 -1C3.47715 -1 -0.999999 3.47715 -0.999999 9L1 9ZM9 17C4.58172 17 1 13.4183 1 9L-0.999999 9C-1 14.5228 3.47715 19 9 19L9 17ZM9 19C14.5228 19 19 14.5228 19 9L17 9C17 13.4183 13.4183 17 9 17L9 19Z"
      fill={color}
    />
  </svg>
);

Ruble.defaultProps = {
  className: '',
  color: '#f15b5b',
  width: '18',
  height: '18',
  viewBox: '-1 -1 20 20',
};

Ruble.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
