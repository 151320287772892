import alert from 'components/native/alert/function';
import notifier from 'services/notifier';
import { QUEUE_MOVE } from 'common/config';
import history from "common/history"

export default dispatch =>
  async ({ from, to, items, fromMsk = false }) => {
    if (!Object.keys(items).length) {
      alert('Выберите позиции для переноса!');
      return false;
    }

    try {
      const formattedItems = Object.values(items).reduce(
        (formatted, item) => [...formatted, [item.id, item.desiredQty, item.price]],
        [],
      );

      if (fromMsk) {
        notifier.show(from, `Создается дозаказ со склада МСК для заказа ${from}`);
      }

      const { success, message } = await dispatch.api.post({
        url: `orders/move/${from}`,
        data: { to, items: formattedItems, fromMsk },
      });

      if (success) {
        !fromMsk &&
          history.push({
            pathname: `/queue/${from}`,
            state: {
              queueType: QUEUE_MOVE,
              docId: from,
            },
          });
      } else {
        alert(message);
        notifier.dismiss();
      }
    } catch (e) {
      notifier.dismiss();
    }
  };
