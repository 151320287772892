import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Shipment = ({
  className,
  color = '#236192',
  name,
  width = '12',
  height = '12',
  viewBox = '0 0 12 12',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M4.07692 4.5H1.92308C1.67614 4.5 1.5 4.32386 1.5 4.07692V1.92308C1.5 1.67614 1.67614 1.5 1.92308 1.5H4.07692C4.32386 1.5 4.5 1.67614 4.5 1.92308V4.07692C4.5 4.32386 4.32386 4.5 4.07692 4.5Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M4.07692 10.5H1.92308C1.67614 10.5 1.5 10.3239 1.5 10.0769V7.92308C1.5 7.67614 1.67614 7.5 1.92308 7.5H4.07692C4.32386 7.5 4.5 7.67614 4.5 7.92308V10.0769C4.5 10.3239 4.32386 10.5 4.07692 10.5Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M10.0769 4.5H7.92308C7.67614 4.5 7.5 4.32386 7.5 4.07692V1.92308C7.5 1.67614 7.67614 1.5 7.92308 1.5H10.0769C10.3239 1.5 10.5 1.67614 10.5 1.92308V4.07692C10.5 4.32386 10.3239 4.5 10.0769 4.5Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M10.0769 10.5H7.92308C7.67614 10.5 7.5 10.3239 7.5 10.0769V7.92308C7.5 7.67614 7.67614 7.5 7.92308 7.5H10.0769C10.3239 7.5 10.5 7.67614 10.5 7.92308V10.0769C10.5 10.3239 10.3239 10.5 10.0769 10.5Z"
        stroke={color}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

Shipment.defaultProps = {
  className: '',
  color: '#236192',
  width: '12',
  height: '12',
  viewBox: '0 0 12 12',
};

Shipment.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
