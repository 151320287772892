import React from 'react';
import OrderButtons from '../order-buttons';
import { checkReserve, ContactCell, getStatusCell } from '../../../helpers';
import sortStringNumbers from 'helpers/sortStringNumbers';
import filterContacts from 'pages/Orders/helpers/filter-contacts';
import { SignCell } from './items/cells';

const createOrderColumns = handleSignChange => [
  {
    Header: '',
    id: 'actions',
    width: 210,
    sortable: false,
    Cell: row =>
      (row && (
        <OrderButtons
          key={row.index}
          row={row.original}
          index={row.index}
          disabled={row.disabled}
        />
      )) ||
      null,
  },
  {
    Header: 'Подписать',
    width: 100,
    id: 'sign',
    Cell: row => <SignCell row={row} handleSignChange={handleSignChange} />,
    sortable: false,
  },
  {
    Header: 'Номер',
    width: 95,
    id: 'orderNo',
    style: { textAlign: 'right' },
    accessor: 'orderNo',
    Cell: checkReserve,
  },
  {
    Header: 'ОЗПр №',
    id: 'ozprNo',
    width: 85,
    accessor: 'blankedOrderNo',
  },
  {
    Header: 'ТН',
    width: 50,
    accessor: 'THH',
    id: 'THH',
  },
  {
    Header: 'Дата заказа',
    id: 'orderDate',
    minWidth: 100,
    accessor: d => d.orderDateSort,
    Cell: row => (row && row.original.orderDate) || null,
  },
  {
    Header: 'Источник',
    width: 80,
    accessor: 'type',
    id: 'type',
  },
  {
    Header: 'Тип',
    width: 95,
    accessor: 'reserveOrOrder',
    id: 'reserveOrOrder',
  },
  {
    Header: 'Контакт',
    accessor: 'contact',
    Cell: ContactCell,
    id: 'contact',
    filterMethod: filterContacts,
  },
  {
    Header: 'Метод отгрузки',
    accessor: 'shipment',
    width: 170,
    id: 'shipment',
  },
  {
    Header: 'Дата отгрузки',
    id: 'shipmentDate',
    accessor: d => d.shipDateSort,
    Cell: row => (row && row.original && row.original.shipmentDate) || null,
  },
  {
    Header: 'Вес',
    accessor: 'weight',
    id: 'weight',
    width: 50,
    style: { textAlign: 'right' },
    Cell: row => parseFloat(row?.value).toFixed(2),
  },
  {
    Header: 'Объем',
    accessor: 'volume',
    id: 'volume',
    width: 70,
    style: { textAlign: 'right' },
    Cell: row => (row && parseFloat(row.value).toFixed(2)) || null,
  },
  {
    Header: () => <span>Сумма, &#8381;</span>,
    title: 'Сумма, RUB',
    accessor: 'priceRUB',
    id: 'priceRUB',
    width: 90,
    style: { textAlign: 'right' },
    Cell: row => (row && row.value && <strong>{row.value.slice(0, -1)}</strong>) || null,
    sortMethod: sortStringNumbers,
  },
  /* {
    Header: 'Сумма',
    accessor: 'price',
    id: 'price',
    width: 90,
    style: { textAlign: 'right' },
    Cell: row => <strong>{row?.value}</strong>,
    sortMethod: sortStringNumbers,
  },*/
  {
    Header: 'Статус',
    width: 100,
    accessor: 'status',
    id: 'status',
    Cell: getStatusCell,
  },
  {
    Header: 'Контрагент',
    width: 170,
    accessor: 'contractorName',
    id: 'contractorName',
  },
  {
    Header: 'Примечание',
    accessor: 'postingDescription',
    id: 'postingDescription',
  },
  {
    Header: 'Конечный заказчик',
    accessor: 'endCustomer',
    width: 170,
    id: 'endCustomer',
  },
];

export default createOrderColumns;
