import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { renderFile } from 'components/fields';
import validate from 'common/validates/validateItemsFile';
import { LoadingSubmitButton } from 'components/loading-sumbit-button';

const LoadItemsForm = props => {
  const { handleSubmit, error, submitting, onSubmit } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <div className="items-load-form row">
        <div className="col-6">
          <Field
            name="items"
            placeholder="Выберите файл"
            label="Обзор"
            className="items-file-field"
            component={renderFile}
          />
        </div>
        <div className="col-6 text-right">
          <LoadingSubmitButton
            label="Добавить из файла"
            position="left"
            fetching={submitting}
            btnClass="btn btn-outline-danger btn--rect btn-sm"
          />
        </div>
      </div>
      {error && (
        <div className="text-center">
          <strong className="text-danger text-danger--small">{error}</strong>
        </div>
      )}
    </form>
  );
};

LoadItemsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'loadItemsForm',
  validate,
})(LoadItemsForm);
