import React from 'react';
import {FORBIDDEN, isCactus} from 'common/config'
import BrowserWarning from 'containers/browser-warning';
import classnames from 'classnames';
import Header from './header';
import Footer from './footer';
import ArrowUp from './arrow-up';
import Banner from './banner/components';
import {useSelector} from "react-redux"
import ForbiddenPage from "components/forbidden"

const classLayout = classnames('layout', { 'theme-cactus': isCactus });

const DefaultLayout = props => {
  const appFetchingError = useSelector(state => state.api.appFetchingError) || {};

  const { children } = props;
  const {status, message} = appFetchingError

  if(status === FORBIDDEN){
    return <ForbiddenPage {...props} message={message} />;
  }

  return (
    <div className={classLayout}>
      <Banner />
      <Header />
      {children}
      <ArrowUp />
      <BrowserWarning />
      <Footer />
    </div>
  );
};

export default DefaultLayout;
