import { batch } from 'react-redux';
import alert from 'components/native/alert/function';
import notifier from 'services/notifier';
import getIntegrateData from 'pages/Orders/helpers/getIntegrateData';
import { QUEUE_ORDER } from 'common/config';
import { isEmpty, get } from 'lodash';
import { objToQS } from 'common/util';
import {
  getContractorCode,
  getOrder as selectGetOrder,
  getOrderSettings,
} from 'pages/Orders/selectors';
import {
  change,
  clearFields,
  getFormInitialValues,
  initialize,
  unregisterField,
  reset,
} from 'redux-form';
import sortStrings from 'helpers/sortStrings';
import { setJoined } from 'pages/Orders/action/state';
import { getDataByPath, setDataByPath } from 'models/list/helpers';

const integrate = (state, { joinedOrders, dataKey }) => {
  const joined = [...joinedOrders];
  let data = getDataByPath(state);

  data.forEach(row => {
    if (!joined.includes(row.orderNo)) {
      row.disabled = row.joining = false;
    }
  });

  setDataByPath(state, data);

  return state;
};

export { default as receive } from './onReceive';
export { default as handleExport } from './export';
export { default as handleDelete } from './delete';
export { default as handleSign } from './sign';
export { default as handleGroupSign } from './group-sign';
export { default as handleJoin } from './join';
export { default as getSpecification } from './specification';
export { default as handleSplit } from './split';
export { default as handleMove } from './move';
export { default as handleOrder } from './orderHandler';

export const search =
  ({ api }) =>
  async (input, state) => {
    const { orderNo = 'NEW' } = selectGetOrder(state);

    try {
      const { orders = [] } = await api.get({
        url: `/orders/edit/search-order/${orderNo}`,
        query: `?q=${input}`,
      });

      orders.sort((a, b) => sortStrings(a.id, b.id) * -1);

      return orders.filter(order => order.id !== orderNo);
    } catch (e) {
      return [];
    }
  };

export function expire() {
  return async function () {
    await alert('Ваш запрос обрабатывается. Обновите страницу через некоторое время.');
    notifier.dismiss();
  };
}

export const commonReceive =
  ({ list }) =>
  ({ message, dispatch }) => {
    const data = message?.data?.data?.data?.[0];
    const isSign = data?.command?.toLowerCase() === 'sign';
    const isIntegrate = get(message, ['data', 'integrate'], false);

    if (message?.data?.fix && message?.data?.fix?.error) {
      notifier.resolve(
        {
          success: false,
          message: message?.data?.fix?.error,
        },
        message?.data?.fix?.id,
      );

      return;
    }

    if (isIntegrate) {
      const data = getIntegrateData(message);
      const { id, joinedOrders, order, ...rest } = data;

      batch(() => {
        dispatch(setJoined({ id: 0, index: 0 }));

        joinedOrders.forEach(orderId => list.deleteRow({ searchKey: 'orderNo', needle: orderId }));
        list.changeRow({ searchKey: 'orderNo', needle: id, ...order });
        list.change({ joinedOrders, handler: integrate });
        notifier.resolve(rest, id);
      });

      return;
    }

    if (isSign && data?.status !== 'Active' && data?.status !== 'В очереди') {
      notifier.resolve(
        {
          success: true,
          message: 'Заказ подписан',
        },
        message?.data?.fix?.id,
      );
    }
  };

export const searchByItem =
  ({ api }) =>
  async item => {
    await api.post({
      url: 'orders/search',
      data: { goodNo: item },
    });
  };

export const createRemains =
  ({ api }) =>
  async order => {
    notifier.show(order, `Создается заказ на остатки для ${order}`);

    try {
      await api.post({
        url: `orders/remains/${order}`,
        data: { queueType: QUEUE_ORDER },
      });
    } catch (e) {
      console.log(e);
    }
  };

export const getSimilar =
  ({ api }) =>
  async order => {
    if (!isEmpty(order)) {
      return await api.post({
        url: `orders/similar`,
        data: { order },
      });
    }

    return null;
  };

export const getOrderToMove =
  ({ api }) =>
  async id => {
    if (id === 'NEW') {
      return { orderNo: 'NEW', goods: [] };
    }

    const { success, message, order, goods } = await api.get({
      url: `orders/get/${id}`,
    });

    if (!success) {
      alert(message);
      return;
    }

    return { ...order, goods };
  };

export const getShipmentDates =
  ({ api }) =>
  async shipmentMethod => {
    const { data = [] } = await api.get({
      url: 'order-shipment-dates',
      query: `?shipmentMethod=${shipmentMethod}`,
    });

    return data;
  };

export const getRepresentatives =
  dispatch =>
  async ({ shipmentData, contractorCode, contractId }, state) => {
    const {
      orderShipmentDates,
      shipmentDate: orderShipmentDate,
      contractId: orderContractId,
      representative,
    } = selectGetOrder(state);

    const orderContractorCode = getContractorCode(state);

    const {
      shipmentDate: defaultShipmentDate,
      counterAgentRows,
      workDateRows,
    } = getOrderSettings(state);

    const dates = (orderShipmentDates?.length && orderShipmentDates) || workDateRows;

    const options = {
      counterAgent: contractorCode || orderContractorCode || get(counterAgentRows, [0, 'Code'], ''),
      contractId: contractId || orderContractId || get(counterAgentRows, [0, 'idDogovor'], ''),
      shipmentDate: shipmentData || orderShipmentDate || defaultShipmentDate || dates[0],
    };

    const { representatives = [] } = await dispatch.api.get({
      url: '/orders/get/representatives',
      query: `?${objToQS(options)}`,
    });

    const firstRepresentative = representatives[0]?.value || representative;
    dispatch.order.changeFieldValue({ field: 'representative', value: firstRepresentative });

    return representatives;
  };

export const getShipmentTime =
  ({ api }) =>
  async ({ day, agentCode }, state) => {
    const { shipmentAgentCode, orderNo = 'NEW', shipmentDate } = selectGetOrder(state);
    const code = agentCode || shipmentAgentCode;
    const { shipmentDate: settingsShipmentDate } = getOrderSettings(state);

    if (!code) {
      return 0;
    }

    const options = {
      agentCode: code,
      date: day || settingsShipmentDate || shipmentDate,
      orderNo,
    };

    const { data } = await api.get({
      url: '/orders/get/shipment-agent-delivery-intervals',
      query: `?${objToQS(options)}`,
    });

    return get(data, ['intervals', 0], {});
  };

export const getTransitItems =
  ({ api }) =>
  async (_, state) => {
    const { orderNo } = selectGetOrder(state);
    try {
      const {
        data: { transits = [] },
      } = await api.get({
        url: `orders/edit/transit-filter/${orderNo}`,
      });

      return transits;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

export const createFromTransit =
  ({ api }) =>
  async data => {
    notifier.show('NEW', 'Создается заказ из транзита');

    await api.post({
      url: 'orders/create/headerFromTransit',
      data,
    });
  };

export const changeFieldValue =
  dispatch =>
  ({ field, value }) => {
    dispatch(change('ORDER_EDIT_FORM', field, value));
  };

export const clearField = dispatch => field =>
  dispatch(clearFields('ORDER_EDIT_FORM', false, false, field));

export const unRegisterField = dispatch => field =>
  dispatch(unregisterField('ORDER_EDIT_FORM', field));

export const initForm = dispatch => (newValues, state) => {
  const currentValues = getFormInitialValues('ORDER_EDIT_FORM')(state);
  dispatch(initialize('ORDER_EDIT_FORM', { ...currentValues, ...newValues }, true));
};

export const resetForm = dispatch => () => {
  dispatch(reset('ORDER_EDIT_FORM'));
};

export const getOrder =
  ({ api }) =>
  async orderNo => {
    try {
      return await api.get({
        url: `orders/edit/${orderNo}`,
      });
    } catch (e) {
      return e;
    }
  };
