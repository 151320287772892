/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { ImageIcon } from 'components/new/image-icon';

export const formattingDate = date =>
  date instanceof Date ? date.toLocaleDateString('ru-RU') : date;

export const createOrderContent = (blankedOrderNo, orderDate, statuses) => {
  let contentOrder = [];

  if (orderDate) {
    contentOrder = [...contentOrder, <div>От {formattingDate(orderDate)}</div>];
  }

  if (blankedOrderNo) {
    contentOrder = [...contentOrder, <div>Заказ (ОЗПр) {blankedOrderNo}</div>];
  }

  contentOrder = [...contentOrder, <ImageIcon items={statuses} />];

  return contentOrder;
};
