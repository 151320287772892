// eslint-disable react-hooks/exhaustive-deps
import React, { useState, useCallback, useRef, useEffect } from 'react';
import Select, { Async } from 'react-select';
import MaskedTextInput from 'react-text-mask';
import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';
import { menuStyle } from '../constants/menu-style-select';
import useUpdateEffect from "hooks/use-update-effect"

export const renderText = ({
  input,
  label,
  type,
  name,
  required,
  disabled,
  placeholder,
  readonly,
  meta: { touched, error },
  colSm,
  maxLength,
}) => (
  <div className={colSm ? `col-sm-${colSm}` : 'col-sm-6'}>
    <label className={required ? 'required' : ''}>{label}</label>
    <div>
      <input
        {...input}
        maxLength={maxLength}
        name={name}
        value={input.value}
        type={type}
        className="form-control"
        placeholder={placeholder}
        disabled={!!disabled}
        readOnly={!!readonly}
      />
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </div>
  </div>
);

export const DebouncedInput = ({
  input: { onChange, value },
  name,
  placeholder,
  className,
  handle,
}) => {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.value = value;
  }, [value]);

  const debounceChange = debounce(value => {
    if (typeof handle === 'function') {
      handle(value);
    }
    onChange(value);
  }, 500);

  return (
    <input
      ref={inputRef}
      className={className}
      type="text"
      placeholder={placeholder}
      name={name}
      onChange={({ target: { value } }) => debounceChange(value)}
    />
  );
};

export const adaptFileEventToValue =  delegate => ({target:{ files }}) => delegate(files[0]);

export const renderFile = ({
  input: { value, onBlur, onChange: nativeChange, ...inputProps },
  placeholder,
  name,
  label,
  onChange,
  meta: { touched, error },
  ...props
}) => {
  const handleChange = e => {
    if (isFunction(onChange)) {
      onChange(e);
    }

    nativeChange(e);
  };
  return (
    <div className={props.className || ''}>
      <div>
        <input
          type="text"
          className="form-control form-control-sm form-control--grey"
          placeholder={placeholder}
          defaultValue={value.name || ''}
        />
        {(touched || value) && error && (
          <span className="text-danger text-danger--small">{error}</span>
        )}
      </div>
      <label className="btn btn-outline-danger btn-sm btn--rect" htmlFor="linked-file">
        {label}
      </label>
      <input
        type="file"
        id="linked-file"
        name={name}
        onChange={adaptFileEventToValue(handleChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        {...inputProps}
        {...props}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export const EditableSelect = props => {
  const {
    inputValue,
    async,
    value,
    onInputChange,
    onBlurSetInputValue,
    onBlurResetsInput,
    ...rest
  } = props;

  const [currentInputValue, setCurrentInputValue] = useState(inputValue);
  const [currentValue, setCurrentValue] = useState(value);
  const [isSelected, setIsSelected] = useState(false);

  useUpdateEffect(() => {
    setCurrentInputValue(inputValue);
  }, [inputValue]);

  useUpdateEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const Component = async ? Select.Async : Select;
  const selectRef = useRef(null);

  const handleInputChange = useCallback(inputValue => {
    setCurrentInputValue(inputValue);
    onInputChange && onInputChange(inputValue);
    if (!onBlurSetInputValue || !inputValue) setIsSelected(false);

    return inputValue;
  }, []); // eslint-disable-line

  const handleOpen = useCallback(() => {
    currentInputValue &&
      selectRef.current.select?.handleInputChange({
        target: { value: currentInputValue },
      });
  }, [currentInputValue]);

  const handleChange = useCallback(
    value => {
      setCurrentValue(value);
      setCurrentInputValue((value && value[rest.inputKey || rest.labelKey]) || null);
      rest.onChange(value);
      setIsSelected(true);
    },
    [rest.onChange], // eslint-disable-line
  );

  const inputRenderer = useCallback(
    props => (
      <input
        type="text"
        {...props}
        value={currentInputValue}
        className="form-control form-control-sm form-control--no-border"
        onFocus={e => {
          const { value } = e.target;

          e.target.setSelectionRange(0, value.length);
          props.onFocus(e);
        }}
      />
    ),
    [currentInputValue],
  );
  /* eslint-disable  */
  const handleBlur = useCallback(() => {
    if (onBlurSetInputValue) {
      if (currentValue && currentInputValue) {
        setCurrentValue({ ...currentValue, value: currentInputValue });
        rest.onChange({ value: currentInputValue });
      } else if (currentInputValue) {
        setCurrentValue({ value: currentInputValue });
        rest.onChange({ value: currentInputValue });
      } else {
        setCurrentValue(null);
        rest.onChange(null);
      }
    } else if (!isSelected && onBlurResetsInput) {
      selectRef.current.select?.handleInputChange({
        target: { value: '' },
      });

      setCurrentValue(null);
    }
  }, [
    isSelected,
    onBlurResetsInput,
    onBlurSetInputValue,
    currentInputValue,
    currentValue,
    rest.onChange,
  ]);
  /* eslint-enable  */
  return (
    <Component
      {...rest}
      ref={selectRef}
      value={currentValue}
      onChange={handleChange}
      valueRenderer={() => null}
      onInputChange={handleInputChange}
      backspaceRemoves={false}
      deleteRemoves={false}
      inputRenderer={inputRenderer}
      onOpen={handleOpen}
      onBlur={handleBlur}
      menuContainerStyle={menuStyle}
      onBlurResetsInput={false}
    />
  );
};

export const RenderSelect = ({
  input: { value, onChange, name },
  label,
  options,
  async,
  loadOptions,
  valueKey = 'value',
  labelKey,
  direction,
  className,
  placeholder,
  handle,
  required,
  isLoading,
  noResultsText,
  meta: { touched, error },
  defaultValue,
  searchable = false,
  clearable = false,
  disabled = false,
}) => {
  const props = {
    autosize: true,
    clearable,
    name,
    className: `placement-${direction || 'bottom'}`,
    placeholder,
    searchable,
    value,
    noResultsText,
    disabled,
    valueKey,
    labelKey,
    onChange: newValue => {
      if (typeof handle === 'function') {
        handle(newValue);
      }

      onChange(newValue?.[valueKey] || defaultValue);
    },
  };

  return (
    <div className={className || 'col-sm-6'}>
      {label && <label className={required ? 'required' : ''}>{label}</label>}
      {async ? (
        <Async loadOptions={loadOptions} searchPromptText={placeholder} {...props} />
      ) : (
        <Select options={options} isLoading={isLoading} {...props} />
      )}
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </div>
  );
};

export const renderMasked = ({
  input: { onChange, value },
  label,
  name,
  required,
  disabled,
  mask,
  meta: { touched, error },
  placeholder,
  colSm,
}) => (
  <div className={colSm ? `col-sm-${colSm}` : 'col-sm-6'}>
    <label className={required ? 'required' : ''}>{label}</label>
    <MaskedTextInput
      name={name}
      className="form-control"
      disabled={!!disabled}
      mask={mask}
      onChange={event => onChange(event.target.value.replace(/#(?=_)/, '').replace(/_/g, ''))}
      value={value}
      placeholder={placeholder}
    />
    {touched && error && <span className="text-danger text-danger--small">{error}</span>}
  </div>
);

export const Checkbox = props => {
  const {
    required,
    name,
    value,
    disabled,
    label,
    input: { onChange },
  } = props;
  return (
    <>
      <label className={required ? 'required' : ''}>
        <input
          name={name}
          value={value}
          type="checkbox"
          disabled={!!disabled}
          onChange={onChange}
        />
        &nbsp;{label}
      </label>
    </>
  );
};

export const RenderHideInput = ({ input, defaultValue }) => {
  useEffect(() => {
    input.onChange(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return <input type="hidden" />;
};

export const renderTextarea = ({
  input,
  label,
  name,
  required,
  disabled,
  placeholder,
  readonly,
  meta: { touched, error },
  colSm,
  maxLength,
}) => (
  <div className={colSm ? `col-sm-${colSm}` : 'col-sm-6'}>
    <label className={required ? 'required' : ''}>{label}</label>
    <div>
      <textarea
        {...input}
        name={name}
        value={input.value}
        className="form-control"
        placeholder={placeholder}
        disabled={!!disabled}
        readOnly={!!readonly}
      />
      {touched && error && <span className="text-danger text-danger--small">{error}</span>}
    </div>
  </div>
);
