import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Lock = ({
  className,
  color = '#236192',
  name,
  width = '16',
  height = '16',
  viewBox = '0 0 16 16',
  title = 'Разблокировать',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    title={title}
  >
    <title>{title}</title>
    <path
      d="M12.5 16H3.5C2.67334 16 2 15.3274 2 14.5V7.5C2 6.67261 2.67334 6 3.5 6H12.5C13.3267 6 14 6.67261 14 7.5V14.5C14 15.3274 13.3267 16 12.5 16ZM3.5 7C3.22461 7 3 7.224 3 7.5V14.5C3 14.776 3.22461 15 3.5 15H12.5C12.7754 15 13 14.776 13 14.5V7.5C13 7.224 12.7754 7 12.5 7H3.5Z"
      fill={color}
    />
    <path
      d="M11.5 7C11.224 7 11 6.776 11 6.5V4C11 2.34595 9.65405 1 8 1C6.34595 1 5 2.34595 5 4V6.5C5 6.776 4.776 7 4.5 7C4.224 7 4 6.776 4 6.5V4C4 1.79395 5.79395 0 8 0C10.2061 0 12 1.79395 12 4V6.5C12 6.776 11.776 7 11.5 7Z"
      fill={color}
    />
  </svg>
);

Lock.defaultProps = {
  className: '',
  color: '#236192',
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  title: 'Разблокировать',
};

Lock.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  title: PropTypes.string,
};
