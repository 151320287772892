import React from 'react';
import { fileType } from 'pages/VAD/constants/regexpes';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Attach = ({ fileName, lineId }) => {
  const { id } = useParams();
  const {
    tz: { getAttachment },
  } = useDispatch();

  const [name] = fileName.split(fileType);
  const [type] = fileName.match(fileType);

  return (
    <button className="attach__btn" onClick={async () => await getAttachment({ id, line: lineId })}>
      <i className="icon icon-download attach__icon" />
      <span className="attach__filename">{name}</span>
      <span className="attach__filetype">{type}</span>
    </button>
  );
};

export default Attach;
