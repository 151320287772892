import React, { useState, useEffect } from 'react';
import alert from 'components/native/alert/function';
import Content from 'components/layout/content';
import SpecialPricesFormStepOne from '../../../containers/special-prices-form-step-1';
import SpecialPricesFormStepTwo from './special-prices-form-step-2';
import SpecialPricesFormStepThree from './special-prices-form-step-3';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ObjCollector from 'services/objCollector';
import { SPECIAL_PRICES_FORM } from 'pages/VAD/constants';
import { reset } from 'redux-form';

const spCollector = new ObjCollector();

const SpecialPricesForm = () => {
  const [step, setStep] = useState(1);
  const history = useHistory();

  const dispatch = useDispatch();
  const {
    specialPrice: { createRequest },
  } = dispatch;

  useEffect(
    () => () => {
      dispatch(reset(SPECIAL_PRICES_FORM));
      spCollector.clear();
    },
    [],
  );

  const sendForm = async values => {
    await createRequest(values);
    await alert('Ваш запрос отправлен, он появится в списке запросов через несколько минут.');

    history.push({ pathname: '/vad/special-prices/list' });
  };

  const formOneOnSubmit = values => {
    spCollector.set(values);
    setStep(step => step + 1);
  };

  const formTwoOnSubmit = values => {
    spCollector.add(values);
    setStep(step => step + 1);
  };

  const formThreeOnSubmit = async values =>
    await sendForm({
      ...spCollector.get(),
      ...values,
    });

  const previousStep = () => setStep(step => step - 1);

  return (
    <Content title="Запрос спец цен">
      {step === 1 && <SpecialPricesFormStepOne onSubmit={formOneOnSubmit} />}
      {step === 2 && (
        <SpecialPricesFormStepTwo onSubmit={formTwoOnSubmit} previousStep={previousStep} />
      )}
      {step === 3 && (
        <SpecialPricesFormStepThree onSubmit={formThreeOnSubmit} previousPage={previousStep} />
      )}
    </Content>
  );
};

export default SpecialPricesForm;
