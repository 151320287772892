import { get } from 'lodash';

const createOrderContractorCode = ({ counterAgentRows = [], user, order }) => {
  const orderAgent = counterAgentRows.find(agent => order && agent.IdDogovor === order.contractId);
  const postByAgreement = get(user, 'Post by Agreement', 0);

  return (
    (+postByAgreement === 1 && orderAgent && orderAgent['Code']) ||
    (+postByAgreement === 0 && order && order.contractorCode)
  );
};

export default createOrderContractorCode;
