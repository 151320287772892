import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder, getShipmentAgentOptions } from 'pages/Orders/selectors';
import DefaultField from 'pages/Orders/components/order-edit/header-form/components/defaultField';
import { useDesignSelect } from 'hooks/new';

const ShipmentAgentField = ({
  input: { value, onChange, name },
  label,
  className,
  arrowRenderer,
  show = true,
}) => {
  const { shipmentAgentCode, shipmentAgentType, orderNo } = useSelector(getOrder);
  const shipmentAgentOptions = useSelector(getShipmentAgentOptions);
  const {
    order: { changeFieldValue },
  } = useDispatch();

  const Select = useDesignSelect();

  const defaultOptions =
    (orderNo && [
      {
        value: `${shipmentAgentCode}#${shipmentAgentType}`,
        label: 'Без изменения',
      },
    ]) ||
    [];
  const options = [...defaultOptions, ...shipmentAgentOptions];

  const customProps = {
    name,
    value,
    options,
    placeholder: 'Не выбран',
    onChange: ({ value }) => {
      onChange(value);
      changeFieldValue('postCode', '');
    },
    className,
    arrowRenderer,
  };

  if (!show || !options.length) {
    return <DefaultField title={label} fieldName={name} placeholder="Нет агента по доставке" />;
  }

  return (
    <div>
      <label>{label}</label>
      <Select clearable={false} searchable={false} {...customProps} />
    </div>
  );
};

export default ShipmentAgentField;
