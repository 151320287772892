import { connect } from 'react-redux';
import BrandList from '../components/common/order-finder/components/brand-list';
import { getItemsAvailFilter, getItemsBrandsFilter } from '../selectors';

const mapState = state => ({
  brands: state.brands,
  allBrands: getItemsBrandsFilter(state),
  avail: getItemsAvailFilter(state),
});

export default connect(mapState)(BrandList);
