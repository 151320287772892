import React from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { CLAIMS_FORM } from '../../constants';

export const DocumentCell = row => {
  const dispatch = useDispatch();

  if (!row) return null;
  const { value } = row;

  const handleClick = async e => {
    e.preventDefault();
    dispatch(change(CLAIMS_FORM, 'products', []));
    dispatch(change(CLAIMS_FORM, 'uzNo', value));

    await dispatch.claims.selectOrder(value);
  };

  return (
    <a
      href="#"
      onClick={handleClick}
      className="enabled"
      style={{ color: 'grey', textDecoration: 'underline' }}
    >
      {row.value}
    </a>
  );
};
