import { useCallback, useState } from 'react';
import throttle from 'lodash/throttle';
import { useEvent } from '../use-event';

export function useSticky(offsetTop = 0, delay = 250) {
  const [state, setState] = useState(false);

  const handleScroll = useCallback(
    throttle(() => {
      const offset = window.scrollY;
      if (offset > offsetTop) {
        setState(true);
      } else {
        setState(false);
      }
    }, delay),
    [],
  );

  useEvent('scroll', handleScroll);

  return state;
}
