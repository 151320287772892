import { cloneDeep, isFunction } from 'lodash';

export default ({ tableInstance: table, group, searchText, id }) => {
  const columns = table.current.getResolvedState().allVisibleColumns;

  const data = table.current.getResolvedState().sortedData;

  const fileName =
    (searchText && `Поиск_по_${searchText}`) || (group && `${group}_все`) || `Заказ_${id}`;

    columns.shift();

  const headers = columns.reduce((headers, { title, width, id, headerContent }) => {
    if (width === 0) {
      return headers;
    }
    if (title) {
      return { ...headers, [id]: title.replace(/<.*\/>/g, '') };
    }

    if (isFunction(headerContent) === 'function') {
      return { ...headers, [id]: headerContent() };
    }

    return { ...headers, [id]: headerContent };
  }, {});

  const headerIds = Object.keys(headers);
  const sendData = cloneDeep(data);

  sendData.forEach(row => {
    for (const key in row) {
      if (!headerIds.includes(key)) {
        row[key] = undefined;
      }
    }
  });

  return {
    fileName,
    data: [...[Object.values(headers)], ...sendData],
  };
};
