import React, { useMemo } from 'react';
import TableList from 'components/table/list';
import getColumns from '../columns';

function ShipmentTab({ filter }) {
  const filtered = useMemo(
    () => [
      {
        id: 'functionality',
        value: filter,
      },
    ],
    [filter],
  );

  const columns = useMemo(() => getColumns(filter === 'Самовывоз'), [filter]);

  return (
    <div className="shipments-list">
      <div className="shipments-list__table">
        <TableList
          minRows={1}
          defaultPageSize={5000}
          columns={columns}
          rowHeight={30}
          filtered={filtered}
        />
      </div>
    </div>
  );
}

export default ShipmentTab;
