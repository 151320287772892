import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { positions } from './data';

import styles from './logo.module.scss';

/**
 * Renders a <Logo /> component
 * @component
 * @example
 *
 * return (
 *   <Logo />
 * )
 */
export const Logo = ({ className, position = 'top' }) => (
  <img
    className={cn(styles['logo'], className)}
    data-testid="logo"
    src={positions[position]}
    alt="merlion-logo"
  />
);

Logo.defaultProps = {
  className: '',
  position: 'top',
};

Logo.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * top | bottom
   */
  position: PropTypes.string,
};
