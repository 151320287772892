import { useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';

export function useInfiniteScroll({
  callback = Function.prototype,
  delay = 300,
  initialState = false,
  hasNextPage = false,
  offset = 0,
}) {
  const [isFetching, setIsFetching] = useState(initialState);

  const pageRef = useRef(hasNextPage);

  useEffect(() => {
    pageRef.current = hasNextPage;
  }, [hasNextPage]);

  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = useCallback(
    throttle(e => {
      const { scrollHeight, scrollTop } = e.target.documentElement;
      const { innerHeight } = window;
      const isBottom = scrollHeight - (scrollTop + innerHeight) < offset;
      if (pageRef.current) {
        if (isBottom && !isFetching) {
          setIsBottom(true);
        }
      }
    }, delay),
    [isFetching],
  );

  const handleFetch = useCallback(async () => {
    try {
      await callback();
      setIsBottom(false);
    } catch (err) {
      console.error('err', err);
    }
  }, [callback]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (isBottom && !isFetching) {
      setIsFetching(true);
      handleFetch();
    }
  }, [isBottom, isFetching, callback, handleFetch]);

  return [isFetching, setIsFetching];
}
