const OPENED_DATE = '01.01.1753';

const createClosedOperationState = row => {
  const isOperationOpened = row.original.open === '1' && row.original.closedAtDate === OPENED_DATE;

  const isOperationClosed = row.original.open === '0' && row.original.closedAtDate !== OPENED_DATE;

  if (isOperationClosed) {
    return {
      label: 'да',
      color: 'green',
      isClosed: false,
    };
  }

  if (isOperationOpened) {
    return {
      label: 'нет',
      color: 'red',
      isClosed: false,
    };
  }

  return {
    label: '–',
    color: 'grey',
    isClosed: null,
  };
};

export default createClosedOperationState;
