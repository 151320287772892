// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFIO } from 'features/user';
import { Icon } from 'common/ui/icon';
import Mixpanel from 'services/mixpanel';
import { Logout } from './components/logout';

import styles from './user-info.module.scss';

export const UserInfo = () => {
  const fio = useSelector(getFIO);

  const handleClick = track => () => {
    Mixpanel?.track(track);
  };

  return (
    <div className={styles['user-info']}>
      <Link
        to="/help/contacts"
        className={styles['user-info__manager']}
        onClick={handleClick('ВашМенеджер')}
      >
        <Icon name="cell-phone" />
        <span>Ваш менеджер</span>
      </Link>
      <Link
        to="/settings/personal"
        className={styles['user-info__avatar']}
        onClick={handleClick('ЛичныеДанные')}
      >
        <Icon name="user" />
        <span className={styles['user-info__name']}>{fio}</span>
      </Link>

      <Logout />
    </div>
  );
};
