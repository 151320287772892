import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import {
  DEFAULT_PHOTO_BIG,
  DEFAULT_PHOTO_MID,
  DEFAULT_PHOTO_SMALL,
  DEFAULT_PHOTO_BIG_2,
} from 'common/config';
import { def } from 'common/util';
import { useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import Preloader from 'components/preloader';
import { useDesign } from 'hooks/new';
import { Carousel } from './carousel';
import { Loader } from 'common/ui/loader';

const Photos = React.memo(({ images }) => {
  const isPhotosFetching = useSelector(getEffectLoading('product', 'getImages'));
  const [_, isPilot] = useDesign();

  const [selectedPhoto, setSelectedPhoto] = useState(images[0]);
  const photos = images.slice(0, 4);

  useEffect(() => {
    setSelectedPhoto(images[0]);
  }, [images[0]]);

  if (isPhotosFetching) {
    return <Loader />;
  }

  const selectedPhotoSmall = selectedPhoto?.s || DEFAULT_PHOTO_SMALL;

  const selectedPhotos =
    (def(selectedPhoto) && [selectedPhoto.b, selectedPhoto.m, selectedPhoto.s]) || [];
  const presenceSelectedPhotos = selectedPhotos.filter(selectedPhoto => selectedPhoto);
  const selectedPhotoMedium =
    presenceSelectedPhotos[0] || (isPilot ? DEFAULT_PHOTO_BIG_2 : DEFAULT_PHOTO_BIG);

  return (
    <div className={cn('photos', { 'photos--pilot': isPilot })}>
      <div className={cn('photos__previews', { 'photos__previews--pilot': isPilot })}>
        {isPilot && <Carousel images={images} onSelectImage={setSelectedPhoto} />}
        {!isPilot &&
          photos.map(photo => {
            const photoSmall = photo.s || DEFAULT_PHOTO_SMALL;
            return (
              (photoSmall.url && (
                <a
                  key={photoSmall.name}
                  href={`#${photoSmall.name}`}
                  onClick={() => setSelectedPhoto(photo)}
                  className={cn('photos__preview', {
                    'photos__preview--selected': photoSmall.name === selectedPhotoSmall.name,
                  })}
                >
                  <img
                    className="photos__preview__image"
                    src={photoSmall.url}
                    alt={photoSmall.name}
                  />
                </a>
              )) ||
              false
            );
          })}
      </div>
      <div className={cn('photos__image', { 'photos__image--pilot': isPilot })}>
        <img src={selectedPhotoMedium.url} alt={selectedPhotoMedium.name} />
      </div>
    </div>
  );
});

export default Photos;
