/* eslint-disable no-irregular-whitespace */
import React from 'react';
import Content from 'components/layout/content';
import LoadPrice from 'pages/Settings/components/load-links/components/load-price';
import LoadFileLinks from 'pages/Settings/components/load-links/components/load-file-links';
import { useLocation } from 'react-router-dom';

const LoadLinks = () => {
  const { pathname } = useLocation();
  const isPrice = pathname.includes('price');

  return (
    <Content title={`Загрузить ${isPrice ? 'прайс' : 'привязки из файла'}`}>
      <LoadPrice isPrice={isPrice} />
      <LoadFileLinks isPrice={isPrice} />
    </Content>
  );
};

export default LoadLinks;
