/* eslint-disable react/no-array-index-key */
// @ts-nocheck
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import chunk from 'lodash/chunk';
import get from 'lodash/get';
import useCarousel from 'pages/Products/hooks/useCarousel';
import { DEFAULT_PHOTO_MID } from 'common/config';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';

import Mixpanel from 'services/mixpanel';
import styles from './carousel.module.scss';

export const Carousel = ({ images, onSelectImage }) => {
  const slides = useMemo(() => (Array.isArray(images) ? chunk(images, 3) : []), [images.length]);
  const [slideShift, isFirstSlide, isLastSlide, handleNextSlideClick, handlePrevSlideClick] =
    useCarousel(
      {
        containerClassName: styles['carousel__slide-container'],
      },
      [slides.length],
    );

  const firstImage = get(slides, [0, 0, 's', 'name'], '');
  const [selectedImage, setSelectedImage] = useState(firstImage);

  const handleSelectedImage = image => () => {
    onSelectImage(image);
    setSelectedImage(image.s.name);
    Mixpanel?.track('СтрТовара.Картинка');
  };

  const handlePrev = () => {
    Mixpanel?.track('СтрТовара.КартинкаВлево');
    handlePrevSlideClick();
  };

  const handleNext = () => {
    Mixpanel?.track('СтрТовара.КартинкаВправо');
    handleNextSlideClick();
  };

  if (images?.length <= 1) {
    return null;
  }

  const showControl = slides.length > 1;

  return (
    <div className={styles['carousel']}>
      {showControl && (
        <IconButton
          disabled={isFirstSlide()}
          onClick={handlePrev}
          className={cn(styles['carousel__button-control'])}
        >
          <Icon className={styles['carousel__icon-prev']} name="arrow-down" color="gray" />
        </IconButton>
      )}
      <div className={styles['carousel__window']}>
        <div style={{ left: `-${slideShift}px` }} className={styles['carousel__door']}>
          {slides.map((slide, index) => (
            <div key={index} className={styles['carousel__slide-container']}>
              {slide.map(image => {
                const { name, url } = image.s || DEFAULT_PHOTO_MID;
                return (
                  <button
                    key={name}
                    type="button"
                    className={cn(styles['carousel__slide'], {
                      [styles['carousel__slide--selected']]: selectedImage === name,
                    })}
                    onClick={handleSelectedImage(image)}
                  >
                    <img src={url} alt={name} className={styles['carousel__img']} />
                  </button>
                );
              })}
            </div>
          ))}
        </div>
      </div>
      {showControl && (
        <IconButton
          disabled={isLastSlide()}
          onClick={handleNext}
          className={cn(styles['carousel__button-control'])}
        >
          <Icon className={styles['carousel__icon-next']} name="arrow-down" color="gray" />
        </IconButton>
      )}
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      s: PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
  ).isRequired,
  onSelectImage: PropTypes.func.isRequired,
};
