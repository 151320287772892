import React, { useEffect, useMemo, createRef, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactTable from 'react-table-v6';
import createColumns from '../common/columns/orderFromWarehouseColumns';
import alert from 'components/native/alert/function';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import { getOrder } from 'pages/Orders/selectors';
import classNames from 'classnames';
import confirm from 'components/native/confirm/function';
import { transitItems } from 'pages/Orders/constances';

const OrderFromWarehouse = ({ closeModal }) => {
  const [items, setItems] = useState([]);

  const { id } = useParams();
  const {
    items: { getFromWarehouse },
    order: { handleMove: move },
  } = useDispatch();

  const isLoading = useSelector(getEffectLoading('items', 'getFromWarehouse'));
  const from = useSelector(getOrder).orderNo;

  const refsInput = useMemo(() => Array.from(items, () => createRef()), [items.length]);
  const refsCheckbox = useMemo(() => Array.from(items, () => createRef()), [items.length]);

  const buttonRef = useRef();

  const columns = useMemo(
    () =>
      createColumns({
        refsInput,
        refsCheckbox,
        buttonRef,
        cnt: items.length,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items.length],
  );

  useEffect(() => {
    buttonRef.current.disabled = true;

    getFromWarehouse(id).then(({ data, success, message }) => {
      buttonRef.current.disabled = false;

      if (!success) {
        alert(message).then(closeModal);

        return;
      }

      setItems(data);
    });

    return () => transitItems.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCreateOrder = async () => {
    const items = transitItems.get();

    if (!isEmpty(items)) {
      try {
        await confirm('Проверьте правильность внесённых изменений и нажмите ОК');
        closeModal();
        await move({
          from,
          to: 'NEW',
          items: items,
          fromMsk: true,
        });
      } catch (e) {}
    } else {
      alert('Выберите товар');
    }
  };

  return (
    <div className="reserve-from-transit" id="warehouse">
      <h2 className="reserve-from-transit__title">Товары для дозаказа со склада МСК</h2>
      <i className="icon icon-close12" title="Закрыть" onClick={closeModal} />
      <div className="reserve-from-transit__divider" />
      <ReactTable
        sortable={false}
        data={items}
        columns={columns}
        showPagination={false}
        minRows={1}
        defaultPageSize={5000}
        loading={isLoading}
        noDataText="Данных нет"
        loadingText={<i className="loader loader--ripple" />}
      />
      <button
        ref={buttonRef}
        className={classNames('btn', 'btn-outline-danger')}
        onClick={handleCreateOrder}
      >
        Создать заказ
      </button>
    </div>
  );
};

export default OrderFromWarehouse;
