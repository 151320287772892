const filterContacts = ({ id, value }, { contact }) => {
  if(!contact){
    return false
  }

  if(value && contact.toLowerCase() === value.toLowerCase()){
    return true
  }

  return !value
}

export default filterContacts
