import { useContext } from 'react';
import { RouterConfigContext } from 'common/router-config-context';
import { useLocation } from 'react-router-dom';

/**
 * Hook to get current route config according to routes
 * See {@link routes}
 *
 * @returns {object}
 */
const useRouteConfig = (path = '') => {
  const { pathname } = useLocation();
  const routeConfig = useContext(RouterConfigContext);

  return routeConfig[(path || pathname).replace(/([A-Z0-9])\w+$/, ':id')] || {};
};

export default useRouteConfig;
