/* eslint-disable react/jsx-filename-extension */
// @ts-nocheck
import React from 'react';
import { toast } from 'react-toastify';
import HtmlParse from 'react-html-parser';
import { Toast } from './toast';

toast.configure({
  hideProgressBar: true,
});

const notifier = {
  show: (id, message = 'Идет обработка...', success = null) => {
    toast(<Toast message={message} success={success} id={id} />, {
      toastId: id,
      updateId: id,
      autoClose: false,
      closeButton: false,
    });
  },

  resolve: (
    {
      success = null,
      message = 'Готово',
      autoClose = 0,
      closeOnClick = true,
      onClick = Function.prototype,
    },
    id,
  ) => {
    toast.update(id, {
      render: <Toast message={HtmlParse(message)} success={success} id={id} />,
      type: 'default',
      autoClose: Number(autoClose) || 4000,
      closeButton: true,
      closeOnClick,
      className: 'animated',
      onClick,
    });
  },
  dismiss: toast.dismiss,
  notify: toast.info,
};

export default notifier;
