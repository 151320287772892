const DIGITS_CHAR_REGEX = /\D/g;

const normalizeNumberField =
  (props = {}) =>
  (value, previousValue) => {
    const { min = -Infinity, max = Infinity } = props;
    if (!value) return value;
    const onlyDigitsValue = value.replace(DIGITS_CHAR_REGEX, '');
    if (value < min || value > max) return previousValue;
    return onlyDigitsValue;
  };

export default normalizeNumberField;
