import PropTypes from 'prop-types';
import { memo } from 'react';
import Steps from './index.container';

Steps.propTypes = {
  stepsNumber: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  stepDescription: PropTypes.string.isRequired,
};

export default memo(Steps);
