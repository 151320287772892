import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Repairs = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M2.5 3C2.77614 3 3 2.77614 3 2.5C3 2.22386 2.77614 2 2.5 2C2.22386 2 2 2.22386 2 2.5C2 2.77614 2.22386 3 2.5 3Z"
        fill={color}
      />
      <path
        d="M12.5 7C12.7761 7 13 6.77614 13 6.5C13 6.22386 12.7761 6 12.5 6C12.2239 6 12 6.22386 12 6.5C12 6.77614 12.2239 7 12.5 7Z"
        fill={color}
      />
      <path
        d="M10.5 7C10.7761 7 11 6.77614 11 6.5C11 6.22386 10.7761 6 10.5 6C10.2239 6 10 6.22386 10 6.5C10 6.77614 10.2239 7 10.5 7Z"
        fill={color}
      />
      <path
        d="M2.5 7C2.77614 7 3 6.77614 3 6.5C3 6.22386 2.77614 6 2.5 6C2.22386 6 2 6.22386 2 6.5C2 6.77614 2.22386 7 2.5 7Z"
        fill={color}
      />
      <path
        d="M12.5 11C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10C12.2239 10 12 10.2239 12 10.5C12 10.7761 12.2239 11 12.5 11Z"
        fill={color}
      />
      <path
        d="M10.5 11C10.7761 11 11 10.7761 11 10.5C11 10.2239 10.7761 10 10.5 10C10.2239 10 10 10.2239 10 10.5C10 10.7761 10.2239 11 10.5 11Z"
        fill={color}
      />
      <path
        d="M10.5 15C10.7761 15 11 14.7761 11 14.5C11 14.2239 10.7761 14 10.5 14C10.2239 14 10 14.2239 10 14.5C10 14.7761 10.2239 15 10.5 15Z"
        fill={color}
      />
      <path
        d="M2.5 11C2.77614 11 3 10.7761 3 10.5C3 10.2239 2.77614 10 2.5 10C2.22386 10 2 10.2239 2 10.5C2 10.7761 2.22386 11 2.5 11Z"
        fill={color}
      />
      <path
        d="M12.5 15C12.7761 15 13 14.7761 13 14.5C13 14.2239 12.7761 14 12.5 14C12.2239 14 12 14.2239 12 14.5C12 14.7761 12.2239 15 12.5 15Z"
        fill={color}
      />
      <path
        d="M11.5 9C11.7761 9 12 8.77614 12 8.5C12 8.22386 11.7761 8 11.5 8C11.2239 8 11 8.22386 11 8.5C11 8.77614 11.2239 9 11.5 9Z"
        fill={color}
      />
      <path
        d="M9.5 9C9.77614 9 10 8.77614 10 8.5C10 8.22386 9.77614 8 9.5 8C9.22386 8 9 8.22386 9 8.5C9 8.77614 9.22386 9 9.5 9Z"
        fill={color}
      />
      <path
        d="M11.5 13C11.7761 13 12 12.7761 12 12.5C12 12.2239 11.7761 12 11.5 12C11.2239 12 11 12.2239 11 12.5C11 12.7761 11.2239 13 11.5 13Z"
        fill={color}
      />
      <path
        d="M9.5 13C9.77614 13 10 12.7761 10 12.5C10 12.2239 9.77614 12 9.5 12C9.22386 12 9 12.2239 9 12.5C9 12.7761 9.22386 13 9.5 13Z"
        fill={color}
      />
      <path
        d="M3.5 5C3.77614 5 4 4.77614 4 4.5C4 4.22386 3.77614 4 3.5 4C3.22386 4 3 4.22386 3 4.5C3 4.77614 3.22386 5 3.5 5Z"
        fill={color}
      />
      <path
        d="M13.5 9C13.7761 9 14 8.77614 14 8.5C14 8.22386 13.7761 8 13.5 8C13.2239 8 13 8.22386 13 8.5C13 8.77614 13.2239 9 13.5 9Z"
        fill={color}
      />
      <path
        d="M3.5 9C3.77614 9 4 8.77614 4 8.5C4 8.22386 3.77614 8 3.5 8C3.22386 8 3 8.22386 3 8.5C3 8.77614 3.22386 9 3.5 9Z"
        fill={color}
      />
      <path
        d="M13.5 13C13.7761 13 14 12.7761 14 12.5C14 12.2239 13.7761 12 13.5 12C13.2239 12 13 12.2239 13 12.5C13 12.7761 13.2239 13 13.5 13Z"
        fill={color}
      />
      <path
        d="M5.5 5C5.77614 5 6 4.77614 6 4.5C6 4.22386 5.77614 4 5.5 4C5.22386 4 5 4.22386 5 4.5C5 4.77614 5.22386 5 5.5 5Z"
        fill={color}
      />
      <path
        d="M15.5 9C15.7761 9 16 8.77614 16 8.5C16 8.22386 15.7761 8 15.5 8C15.2239 8 15 8.22386 15 8.5C15 8.77614 15.2239 9 15.5 9Z"
        fill={color}
      />
      <path
        d="M5.5 9C5.77614 9 6 8.77614 6 8.5C6 8.22386 5.77614 8 5.5 8C5.22386 8 5 8.22386 5 8.5C5 8.77614 5.22386 9 5.5 9Z"
        fill={color}
      />
      <path
        d="M15.5 13C15.7761 13 16 12.7761 16 12.5C16 12.2239 15.7761 12 15.5 12C15.2239 12 15 12.2239 15 12.5C15 12.7761 15.2239 13 15.5 13Z"
        fill={color}
      />
      <path
        d="M4.5 3C4.77614 3 5 2.77614 5 2.5C5 2.22386 4.77614 2 4.5 2C4.22386 2 4 2.22386 4 2.5C4 2.77614 4.22386 3 4.5 3Z"
        fill={color}
      />
      <path
        d="M14.5 7C14.7761 7 15 6.77614 15 6.5C15 6.22386 14.7761 6 14.5 6C14.2239 6 14 6.22386 14 6.5C14 6.77614 14.2239 7 14.5 7Z"
        fill={color}
      />
      <path
        d="M4.5 7C4.77614 7 5 6.77614 5 6.5C5 6.22386 4.77614 6 4.5 6C4.22386 6 4 6.22386 4 6.5C4 6.77614 4.22386 7 4.5 7Z"
        fill={color}
      />
      <path
        d="M14.5 11C14.7761 11 15 10.7761 15 10.5C15 10.2239 14.7761 10 14.5 10C14.2239 10 14 10.2239 14 10.5C14 10.7761 14.2239 11 14.5 11Z"
        fill={color}
      />
      <path
        d="M4.5 11C4.77614 11 5 10.7761 5 10.5C5 10.2239 4.77614 10 4.5 10C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11Z"
        fill={color}
      />
      <path
        d="M14.5 15C14.7761 15 15 14.7761 15 14.5C15 14.2239 14.7761 14 14.5 14C14.2239 14 14 14.2239 14 14.5C14 14.7761 14.2239 15 14.5 15Z"
        fill={color}
      />
      <path
        d="M6.5 3C6.77614 3 7 2.77614 7 2.5C7 2.22386 6.77614 2 6.5 2C6.22386 2 6 2.22386 6 2.5C6 2.77614 6.22386 3 6.5 3Z"
        fill={color}
      />
      <path
        d="M6.5 7C6.77614 7 7 6.77614 7 6.5C7 6.22386 6.77614 6 6.5 6C6.22386 6 6 6.22386 6 6.5C6 6.77614 6.22386 7 6.5 7Z"
        fill={color}
      />
      <path
        d="M6.5 11C6.77614 11 7 10.7761 7 10.5C7 10.2239 6.77614 10 6.5 10C6.22386 10 6 10.2239 6 10.5C6 10.7761 6.22386 11 6.5 11Z"
        fill={color}
      />
      <path d="M8 13V4" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
      <path
        d="M11 4.75H17V16H9C8.43333 16 8 15.5543 8 15V12H1V1L10 1C10.5667 1 11 1.38315 11 1.9375C11 2.49185 10.5667 2.875 10 2.875H9C8.43333 2.875 8 3.25815 8 3.8125C8 4.36685 8.43333 4.75 9 4.75H11Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path d="M11 5V2" stroke={color} strokeMiterlimit="10" strokeLinejoin="round" />
    </svg>
  );
};

Repairs.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Repairs.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
