import React from 'react';
import { useSelector, useStore } from 'react-redux';
import TableList from 'components/table/list';
import { columns } from 'common/constants/date-balance';

const defaultSorted = [
  {
    id: 'name',
    desc: false,
  },
];

const TableInfo = () => {
  const {
    select: {
      balance: {
        getFilteredCounterparty
      },
    },
  } = useStore();

  const filteredCounterparty = useSelector(getFilteredCounterparty);

  return (
    <TableList
      defaultPageSize={5000}
      pageSize={5000}
      columns={columns}
      defaultSorted={defaultSorted}
      data={filteredCounterparty}
    />
  );
};

export default TableInfo;
