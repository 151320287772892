import React from 'react';
import { Link } from 'react-router-dom';

const showInvoices = invoices => {
  const invoicesStr = [];
  if (!Array.isArray(invoices)) return invoicesStr;
  invoices.forEach((invoice, i) => {
    if (i > 0) {
      invoicesStr.push(', ');
    }
    invoicesStr.push(<Link to={`/invoice/${invoice.no}`}>{invoice.no}</Link>);
  });

  return invoicesStr;
};

export default showInvoices;
