// @ts-nocheck
const formattingAmount = value => {
  if (!value) return '-';

  const val = Number(value.replace(' ', ''));
  if (!val) return '-';

  return Number.isInteger(val) ? val : val.toFixed(2);
};

export default formattingAmount;
