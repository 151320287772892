const brands = {
  name: 'brands',
  state: [],
  reducers: {
    'order/load': (state, payload) => payload?.data?.brands || [],
    'orders/load': (state, payload) => payload?.data?.brands || [],
  },
  effects: dispatch => ({
    async choose(brands) {
      await dispatch.itemsFilter.save({ brands });
    },
    async clear() {
      await dispatch.itemsFilter.save({ brands: [] });
    },
  }),
};

export default brands;
