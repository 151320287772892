import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import notifier from 'services/new/notifier';
import alert from 'components/native/alert/function';
import history from 'common/history';
import { initFilters } from 'pages/new/orders/utils/set-initial-filters';

import { chooseQueueMessage, groupOrdersByShipment } from './utils';

const initState = {
  fetching: false,
  joined: [],
  orders: [],
  ordersByShipment: [],
};

export default {
  name: 'newOrderIntegration',
  state: initState,
  reducers: {
    clearJoinedOrders: state => {
      state.joined = [];
      return state;
    },
    setJoinedOrders: (state, payload) => {
      let joined = [...state.joined];
      const orderNo = payload || '';
      if (joined.includes(orderNo)) {
        joined = joined.filter(joinedId => joinedId !== orderNo);
      } else {
        joined = [...joined, orderNo];
      }

      state.joined = joined;
      return state;
    },
    setOrdersToIntegrate: (state, payload) => {
      state.orders = payload || [];
      return state;
    },
    setOrdersToIntegrateByShipment: (state, payload) => {
      const orderNo = payload || '';
      state.ordersByShipment = groupOrdersByShipment(orderNo, state.orders);
      return state;
    },
  },
  effects: ({ api, newOrderActions, newOrders }) => ({
    async integrateOrder({ orderNo, joined }) {
      const text =
        joined.length > 1
          ? `Заказы ${joined.join(', ')} объединяются в ${orderNo}`
          : `Заказ ${joined.join(', ')}  объединяется в ${orderNo}`;
      notifier.show(orderNo, text);
      try {
        const result = await api.post({
          url: `new-orders/integrate/${orderNo}`,
          data: { joined },
        });
        newOrderActions.setJobId(result.jobId);
        newOrderActions.setJobType('order');
        return result;
      } catch (err) {
        this.clearJoinedOrders();
        notifier.resolve(
          {
            success: false,
            message: `Ошибка объединения в заказ ${orderNo}!`,
          },
          orderNo,
        );
        return null;
      }
    },
    async fetchOrdersToIntegrate(_, { newOrders: { orders, filters, search } }) {
      let data = orders;

      if (orders.length === 0 || !isEqual(filters, initFilters) || !isEmpty(search)) {
        data = await newOrders.fetchOrders().then(data => get(data, ['data', 'data'], []));
      }

      this.setOrdersToIntegrate(data);
    },
    receive(message) {
      const { fix } = message.data;

      const socketMessage = chooseQueueMessage(message);

      if (fix.done) {
        newOrderActions.setJobId('');
        newOrderActions.setJobType('');
        this.clearJoinedOrders();

        notifier.resolve(
          {
            success: socketMessage.success,
            message: socketMessage.text,
          },
          socketMessage.id,
        );

        if (socketMessage.success) {
          setTimeout(() => {
            history.push('/orders');
          }, 1000);
        }
      }
    },
    expire(message) {
      alert('Ваш запрос обрабатывается. Обновите страницу через некоторое время.');
      // eslint-disable-next-line no-console
      console.log('onExpire', message);
    },
  }),
  selectors: (slice, _, hasProps) => ({
    getOrdersToIntegrate() {
      return slice(state => get(state, 'orders', null));
    },
    getJoinedOrders() {
      return slice(state => get(state, 'joined', []));
    },
    getOrderIntegrationFetching() {
      return slice(state => get(state, 'fetching', []));
    },
    getOrderForJoin: hasProps((_, orderNo) =>
      slice(state => {
        const mainOrder = state.orders.find(order => order.orderNo === orderNo);
        return state.orders.filter(
          order => order.joinId === mainOrder?.joinId && order.orderNo !== mainOrder?.orderNo,
        );
      }),
    ),
  }),
};
