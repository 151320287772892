import { useCallback, useState } from 'react';

export const usePopup = () => {
  const [state, setState] = useState(false);

  const handleOpen = useCallback(() => {
    setState(true);
  }, []);

  const handleClose = useCallback(() => {
    setState(false);
  }, []);

  return [handleOpen, handleClose, state];
};
