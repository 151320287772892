import { DEMOFOND_TYPE_TEST } from 'common/config';
import { CHANGE_DEMOFOND_MODE } from './action';

export const initState = {
  demofondMode: DEMOFOND_TYPE_TEST,
};

export const vadReducer = (state = initState, action) => {
  switch (action.type) {
    case CHANGE_DEMOFOND_MODE:
      return { ...state, demofondMode: action.payload };
    default:
      return state;
  }
};
