import React from 'react';
import { Scroller } from 'common/ui/scroller';

import styles from './personal-data-text.module.scss';

export const PersonalDataText = () => (
  <Scroller className={styles['scroller']}>
    <div className={styles['list']}>
      <ol>
        <li>
          <strong>Общие положения</strong>
          <ol>
            <li>
              Политика в отношении обработки персональных данных (далее — Политика) направлена на
              защиту прав и свобод физических лиц, персональные данные которых обрабатывает частная
              компания с ограниченной ответственностью "МЕРЛИОН Груп Лимитед" (MERLION Group Limited
              (далее — Оператор).
            </li>
            <li>
              Политика разработана в соответствии с п. 2 ч. 1 ст. 18.1 Федерального закона от 27
              июля 2006 г. № 152-ФЗ «О персональных данных» (далее — ФЗ «О персональных данных»), а
              также с учетом требований Регламента (ЕС) 2016/679 Европейского Парламента и Совета о
              защите физических лиц в отношении обработки персональных данных и о свободном
              перемещении таких данных и отмене Директивы 95/46 / EC (GDPR).
            </li>
            <li>
              Политика содержит сведения, подлежащие раскрытию в соответствии с ч. 1 ст. 14 ФЗ «О
              персональных данных», и является общедоступным документом.
            </li>
          </ol>
        </li>
        <li>
          <strong>Сведения об операторе</strong>

          <ol>
            <li>
              Оператор ведет свою деятельность по адресу: Российская Федерация, Московская обл., г.
              Красногорск, бульвар. Строителей, д. 4, стр. 1 (филиал).
            </li>
          </ol>
        </li>
        <li>
          <strong>Сведения об обработке персональных данных</strong>

          <ol>
            <li>
              Оператор обрабатывает персональные данные на законной и справедливой основе для
              выполнения возложенных законодательством функций, полномочий и обязанностей,
              осуществления прав и законных интересов Оператора, работников Оператора и третьих лиц.
            </li>
            <li>
              Оператор получает персональные данные непосредственно у субъектов персональных данных.
            </li>
            <li>
              Оператор обрабатывает персональные данные автоматизированным и неавтоматизированным
              способами, с использованием средств вычислительной техники и без использования таких
              средств.
            </li>
            <li>
              Действия по обработке персональных данных включают сбор, запись, систематизацию,
              накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
              передачу (распространение, предоставление, доступ), обезличивание, блокирование,
              удаление и уничтожение.
            </li>
            <li>
              Оператор хранит Персональную информацию столько времени, сколько это необходимо для
              достижения цели, для которой она была собрана, или для соблюдения требований
              применимого законодательства и иных нормативных актов.
            </li>
          </ol>
        </li>
        <li>
          <strong>Обработка персональных данных клиентов</strong>

          <ol>
            <li>
              Оператор обрабатывает персональные данные клиентов с их согласия, предоставляемого
              клиентами и/или их законными представителями путем совершения конклюдентных действий
              на настоящем интернет-сайте, в том числе, но не ограничиваясь, оформлением заказа,
              регистрацией в личном кабинете, подпиской на рассылку, в соответствии с настоящей
              Политикой.
            </li>
            <li>
              Оператор обрабатывает персональные данные клиентов не дольше, чем того требуют цели
              обработки персональных данных, если иное не предусмотрено требованиями применимого
              законодательства.
            </li>
            <li>
              Оператор обрабатывает следующие персональные данные клиентов:
              <p>
                —&nbsp;Фамилия, имя, отчество;
                <br />
                —&nbsp;Номер контактного телефона;
                <br />
                —&nbsp;Адрес электронной почты.
              </p>
            </li>
            <li>
              Оператор защищает персональную информацию Пользователя в соответствии с требованиями,
              предъявляемыми к защите такого рода информации, и несет ответственность за
              использование безопасных методов защиты такой информации.
            </li>
            <li>
              Для защиты персональной информации Пользователя, обеспечения ее надлежащего
              использования и предотвращения несанкционированного и/или случайного доступа к ней,
              Оператор применяет необходимые и достаточные технические и административные меры.
              Предоставляемая Пользователем персональная информация хранится на серверах с
              ограниченным доступом, расположенных в охраняемых помещениях.
            </li>
            <li>
              Оператор вправе передать персональную информацию пользователя (в том числе
              организациям, осуществляющим запись, систематизацию, накопление, уточнение, хранение,
              извлечение, непосредственно осуществляющим направление Пользователю специальных
              предложений, информации о новых товарах и рекламных акциях, обработку моих запросов и
              обращений, а также осуществляющим уничтожение персональной информации) третьим лицам в
              следующих случаях:
              <p>
                —&nbsp;в рамках использования пользователем определенного Сервиса либо для оказания
                услуги пользователю, выполнения обязательств по договору;
                <br />
                —&nbsp;передача предусмотрена российским или иным применимым законодательством в
                рамках установленной законодательством процедуры.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <strong>Сведения об обеспечении безопасности персональных данных</strong>

          <ol>
            <li>
              Оператор назначает ответственного за организацию обработки персональных данных для
              выполнения обязанностей, предусмотренных применимым законодательством.
            </li>
            <li>
              Оператор применяет комплекс правовых, организационных и технических мер по обеспечению
              безопасности персональных данных для обеспечения конфиденциальности персональных
              данных и их защиты от неправомерных действий:
              <p>
                —&nbsp;обеспечивает неограниченный доступ к Политике, копия которой размещена по
                адресу нахождения Оператора, а также может быть размещена на сайте Оператора (при
                его наличии);
                <br />
                —&nbsp;во исполнение Политики утверждает и приводит в действие документ «Положение
                об обработке персональных данных» (далее — Положение) и иные локальные акты;
                <br />
                —&nbsp;производит ознакомление работников с положениями законодательства о
                персональных данных, а также с Политикой и Положением;
                <br />
                —&nbsp;осуществляет допуск работников к персональным данным, обрабатываемым в
                информационной системе Оператора, а также к их материальным носителям только для
                выполнения трудовых обязанностей;
                <br />
                —&nbsp;устанавливает правила доступа к персональным данным, обрабатываемым в
                информационной системе Оператора, а также обеспечивает регистрацию и учёт всех
                действий с ними;
                <br />
                —&nbsp;производит оценку вреда, который может быть причинен субъектам персональных
                данных в случае нарушения применимого законодательства;
                <br />
                —&nbsp;производит определение угроз безопасности персональных данных при их
                обработке в информационной системе Оператора;
                <br />
                —&nbsp;применяет организационные и технические меры и использует средства защиты
                информации, необходимые для достижения установленного уровня защищенности
                персональных данных;
                <br />
                —&nbsp;осуществляет обнаружение фактов несанкционированного доступа к персональным
                данным и принимает меры по реагированию, включая восстановление персональных данных,
                модифицированных или уничтоженных вследствие несанкционированного доступа к ним;
                <br />
                —&nbsp;производит оценку эффективности принимаемых мер по обеспечению безопасности
                персональных данных до ввода в эксплуатацию информационной системы Оператора;
                <br />
                —&nbsp;осуществляет внутренний контроль соответствия обработки персональных данных
                требованиям применимого законодательства к защите персональных данных, Политике,
                Положению и иным локальным актам, включающий контроль за принимаемыми мерами по
                обеспечению безопасности персональных данных и их уровня защищенности при обработке
                в информационной системе Оператора.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <strong>Права субъектов персональных данных</strong>
          <ol>
            <li>
              Субъект персональных данных имеет право:
              <p>
                —&nbsp;на получение персональных данных, относящихся к данному субъекту, и
                информации, касающейся их обработки;
                <br />
                —&nbsp;на исправление без необоснованной задержки неточных персональных данных , а
                также, принимая во внимания цели сбора и обработки, на дополнение неполных
                персональных данных, в том числе посредством предоставления дополнительного
                заявления;
                <br />
                —&nbsp;на уточнение, ограничение, блокирование или уничтожение («право быть
                забытым») его персональных данных в случае, если они являются неполными,
                устаревшими, неточными, незаконно полученными или не являются необходимыми для
                заявленной цели обработки;
                <br />
                —&nbsp;на отзыв данного им согласия на обработку персональных данных;
                <br />
                —&nbsp;на защиту своих прав и законных интересов, в том числе на возмещение убытков
                и компенсацию морального вреда в судебном порядке;
                <br />
                —&nbsp;на обжалование действий или бездействия Оператора в уполномоченный орган по
                защите прав субъектов персональных данных или в судебном порядке.
                <br />
                —&nbsp;на запрос электронной копии своих персональных данных в структурированном,
                широко используемом, машиночитаемом формате.
              </p>
            </li>
            <li>
              Для реализации своих прав и законных интересов субъекты персональных данных имеют
              право обратиться к Оператору либо направить запрос лично или с помощью представителя.
            </li>
          </ol>
        </li>
        <li>
          <strong>Цели сбора и обработки персональной информации пользователей:</strong>
          <ol>
            <li>
              Оператор собирает, обрабатывает и хранит только ту персональную информацию, которая
              необходима.
            </li>
            <li>
              Оператор обрабатывает персональные данные клиентов в целях приема предложений о
              партнерстве и дальнейших переговоров с целью:
              <ol>
                <li>идентификации стороны в рамках договоров с Оператором;</li>
                <li>приема предложений о партнерстве и дальнейших переговоров;</li>
                <li>предоставления Пользователю клиентской поддержки и сервиса;</li>
                <li>информирования Пользователя о проводимых Оператором мероприятиях и акциях;</li>
              </ol>
            </li>
            <li>
              Оператор не проверяет достоверность предоставленной Пользователем персональной
              информации и не осуществляет контроль ее актуальности. Всю ответственность, а также
              возможные последствия за предоставление недостоверной или не актуальной персональной
              информации несёт Пользователь.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </Scroller>
);
