import React from 'react';

export const CustomTextArea = ({ input, name, disabled, placeholder }) => (
  <textarea
    {...input}
    className="form-control"
    name={name}
    placeholder={placeholder}
    disabled={!!disabled}
  >
    {input.value}
  </textarea>
);
