import { memo } from 'react';
import {circularReplacer, getDisplayName} from 'common/util'

/**
 * Hook to memoize component up on array of checked props, or for all props
 *
 * @template {React.ComponentType} C
 * @param {[]} [checkedProps=[]] - array of props to memoize component on
 * @returns {function(C): React.MemoExoticComponent<C>}
 */
export default (checkedProps = []) =>
  Component => {
    function arEqual(prev, next) {
      if (Array.isArray(checkedProps) && checkedProps.length) {
        let isEqual = true;

        for (let i = 0; i < checkedProps.length; i++) {
          const checkedProp = checkedProps[i];

          if (JSON.stringify(prev[checkedProp]) !== JSON.stringify(next[checkedProp])) {
            isEqual = false;
            break;
          }
        }

        return isEqual;
      }

      return JSON.stringify(prev, circularReplacer()) === JSON.stringify(next, circularReplacer());
    }

    Component.displayName = `withMemo(${getDisplayName(Component)})`
    return memo(Component, arEqual);
  };
