import { createAction } from 'redux-actions';
import { SET_JOINED } from 'pages/Orders/action/index';

export const FLUSH_STATE = 'FLUSH_STATE';
export const SET_STATE = 'SET_STATE';
export const SET_EXPANDED = 'SET_EXPANDED';

export const flushState = createAction(FLUSH_STATE);
export const setState = createAction(SET_STATE);

export const setPage = page => dispatch => dispatch(setState({ page }));

export const setGroups = groups => dispatch => dispatch(setState({ groups }));

export const setSorted = sorted => dispatch => dispatch(setState({ sorted }));

export const setSearchText = text => dispatch => dispatch(setState({ searchText: text }));

export const setImport = isImport => dispatch => dispatch(setState({ import: isImport }));

export const setExpanded = expanded => dispatch => dispatch(setState({ expanded }));

export const setJoined = createAction(SET_JOINED);
