import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TZColumns } from 'pages/VAD/components/common/columns/tzColumns';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import FilterList from 'containers/filter-list';
import { useSelector } from 'react-redux';
import { getTableListData } from 'common/selectors';
import withApi from 'hoc/withApi';
import useListData from "components/table/hooks/useListData"

const TABLE_NAME = 'TZ';
const defaultSorted = [
  {
    id: 'createDate',
    desc: true,
  },
  {
    id: 'no',
    desc: true,
  },
];

const TZList = () => {
  const [filtered, setFiltered] = useState([]);
  const handleUpdateFiltered = filtered => setFiltered(filtered);

  const list = useListData();

  return (
    <Content title="Запросы ТЗ">
      <div
        className={
          'actions-container actions-container--order align-items-center special-prices__list-header'
        }
      >
        <div>
          <Link
            to="/vad/tz/form"
            className={['section-nav', 'section-nav__no-underline', 'section-nav--upper'].join(' ')}
          >
            <i className="icon-create">+</i> Создать запрос ТЗ
          </Link>
          <FilterList
            label="Конечный заказчик"
            filterBy="customerName"
            filtered={filtered}
            onUpdateFiltered={handleUpdateFiltered}
          />
        </div>
        <Link to="/category/994" className="add-vendor-data">
          <i className={'icon icon-help'} />
          Помощь
        </Link>
      </div>
      <div className="special-prices__list">
        <TableList
          name={TABLE_NAME}
          columns={TZColumns}
          defaultSorted={defaultSorted}
          filtered={filtered}
          showPagination={list.length > 5}
        />
      </div>
    </Content>
  );
};

export default withApi(TZList);
