import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Attention = ({
  className,
  color = '#f15b5b',
  name,
  width = '11',
  height = '11',
  viewBox = '0 0 11 11',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle cx="5.5" cy="5.5" r="5" stroke={color} />
      <path fill={color} d="M5 2H6V7H5z" />
      <path fill={color} d="M5 8H6V9H5z" />
    </svg>
  );
};

Attention.defaultProps = {
  className: '',
  color: '#f15b5b',
  width: '11',
  height: '11',
  viewBox: '0 0 11 11',
};

Attention.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
