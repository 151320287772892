import React from 'react';
import { useSelector, useStore } from 'react-redux';
import Content from 'components/layout/content';
import { Link } from 'react-router-dom';
import employeeColumns from 'pages/Settings/components/employees/helpers/columns';
import { isCactus } from 'common/config';
import { getUser } from 'pages/Orders/selectors';
import withApi from 'hoc/withApi';
import { Invite } from 'pages/new/settings/pages/employees/components/invite';
import TableList from "components/table/list"

const tdProps = (state, info) =>
  (info && {
    className: info.original.isBlocked ? 'rt-td--grey' : '',
  }) ||
  {};

const trGroupProps = user => (state, info) =>
  (info && {
    id: `row-${info.original.login}`,
    className: info.original.login === user.login ? 'rt-tr-group--current' : '',
  }) ||
  {};

const Employees = () => {
  const {
    select: {
      employees: { getEmployees },
    },
  } = useStore();

  const user = useSelector(getUser);

  return (
    <Content title={`Сотрудники(${user.client})`}>
      <div className="employees-container">
        <div className="row actions-container">
          <div className="col-sm">
            <Invite />
          </div>
          {!isCactus && (
            <div className="col-sm justify-content-end">
              Если Вам что-либо не понятно, посмотрите
              <Link to="/help/content" className="link link--underlined">
                Помощь
              </Link>
            </div>
          )}
        </div>
        <div className="row table-container">
          <TableList
            columns={employeeColumns}
            pageSize={5000}
            noDataText="Данных нет"
            minRows={1}
            getTdProps={tdProps}
            className="-striped"
            getTrGroupProps={trGroupProps(user)}
            showPagination={false}
          />
        </div>
      </div>
    </Content>
  );
};

export default withApi(Employees);
