export const getHead = slice => () => slice(product => product.head || {});
export const getMainItem = (slice, createSelector) => ({ newOrdersNew }) =>
    createSelector(
      newOrdersNew.getGoods,
      goods => goods.find(good => good.relationType === 'main') || {}
    )

export const getGroups =
  (slice, createSelector) =>
  ({ product }) =>
    createSelector(product.getHead, product => {
      let productGroups =
        (product.code1 && {
          [product.code1]: product.group1,
          [product.code2]: product.group2,
        }) ||
        null;

      if (productGroups && product.code3) {
        productGroups = {
          ...productGroups,
          [product.code3]: product.group3,
        };
      }

      return productGroups || {};
    });

export const getAvailability = slice => () => slice(products => products.availability || []);

export const getRelated = slice => () => slice(products => products.related || []);
