// @ts-nocheck
import { getOrder, getUser } from 'pages/Orders/selectors';
import { isPreOrder } from 'pages/Orders/helpers';
import { CARD_PAGE_PATH } from 'common/routes/constants';
import notifierDefault from 'services/notifier';
import history from "common/history"

export default dispatch =>
  async (
    {
      data,
      pathname = '',
      id,
      orderModel = ['order'],
      notifier = notifierDefault,
      isOrderEdit
    },
    state,
  ) => {
    const user = getUser(state);
    const isCard = pathname.includes(CARD_PAGE_PATH);

    const order = getOrder(state);
    const preOrder = isPreOrder(pathname, order);
    const currentOrder = (
      preOrder && parseInt(order.tender) !== 4
      || isOrderEdit === false
    ) ? {}
      : order;

    const currentId = isCard ? currentOrder.orderNo : id;
    const isNewOrders = orderModel.includes('newOrdersNew');

    if (data?.fix && data?.fix?.error) {
      dispatch.order.resetForm();
      notifier.resolve(
        {
          success: false,
          message: data?.fix?.error,
        },
        data?.fix?.id,
      );

      return;
    }

    const { migrate, uid } = data;

    if (!data.data || !(currentId === data.id || uid === user.id)) {
      return;
    }

    const orderId = !currentOrder?.orderNo ? 'NEW' : data.id || id;

    if (migrate) {
      const message = data.data.data || [];
      const status = data['Processing Result'];
      const isError = status === 'Error';
      notifier.resolve(
        {
          success: !isError,
          onClick: () => history.push(`/orders/edit/${message['text1']}`),
          message:
            (isError && message['Processing Result Comment']) ||
            `Создан дозаказ <b>${message['text1']}</b> со слада МСК для заказа <b>${id}</b>`,
          autoClose: 6000,
        },
        orderId,
      );

      return;
    }

    const message = data.data.data[0];

    const { status, error, result, comment, docNo, ...header } = message;

    if (!migrate) {
      dispatch.queue.setHeader({ data: message, id: orderId || docNo });
    }

    if (result === 'Error' || status === 'Error') {
      dispatch.order.resetForm()
      notifier.resolve(
        {
          success: false,
          message: error || comment,
        },
        orderId || docNo,
      );
    } else if (status === 'Готово') {
      await Promise.all(orderModel.map(model =>
        Promise.resolve(dispatch[model].setHeader({ ...header, orderNo: data.id }))
      ))

      notifier.resolve(
        {
          success: true,
          message: `Заказ ${data.id} сохранён`,
        },
        orderId || docNo,
      );
    }

    if (isCard) {
      return;
    }

    if (
      isNewOrders ||
      currentOrder?.orderNo &&
      currentOrder.shipmentDate === message.shipmentDate &&
      currentOrder.shipmentMethod === message.shipmentMethod
    ) {
      return;
    }

    if (status === 'Готово' && uid === user.id && orderModel.includes('order')) {
      await dispatch.items.refresh({ orderNo: message.orderNo, pathname });
    }

    return message;
  };
