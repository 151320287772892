import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import ReactModal from 'react-modal';
import ReactHtmlParser from 'react-html-parser';
import cn from 'classnames';
import get from 'lodash/get';
import { isCactus } from 'common/config';

const classModal = cn('Modal', 'Modal--confirm', { 'Modal--cactus': isCactus });

const Dialog = ({ show, proceed, dismiss, cancel, confirmation, options }) => {
  const isAlert = options.isAlert || false;
  const noFixedLayout = options.noFixedLayout || false;
  const bodyOpenClassName = get(options, 'bodyOpenClassName', 'ReactModal__Body--open');

  const buttons = isAlert ? (
    <div className={cn("row justify-content-center", 'confirm__actions')}>
      <button className={cn('confirm__btn', 'confirm__btn--outlined')} onClick={proceed}>
        {options.confirmLabel || 'Ок'}
      </button>
    </div>
  ) : (
    <div className={cn("row justify-content-center", 'confirm__actions')}>
      <button className={cn('confirm__btn', 'confirm__btn--outlined')} onClick={cancel}>
        {options.abortLabel || 'Отмена'}
      </button>
      <button className='confirm__btn' onClick={proceed}>
        {options.confirmLabel || 'Ок'}
      </button>
    </div>
  );

  return (
    <ReactModal
      ariaHideApp={false}
      onRequestClose={dismiss}
      isOpen={show}
      contentLabel="Confirm"
      className={classModal}
      overlayClassName="Overlay Overlay--confirm"
      shouldCloseOnOverlayClick={false}
      bodyOpenClassName={`${bodyOpenClassName} ${
        noFixedLayout && 'ReactModal__Body--open--related'
      }`}
    >
      <div className="confirm-body">
        {ReactHtmlParser(
          Array.isArray(confirmation) ? confirmation.join('\n') : confirmation,
          options || {},
        )}
      </div>
      {buttons}
    </ReactModal>
  );
};

Dialog.propTypes = {
  show: PropTypes.bool,
  proceed: PropTypes.func,
  cancel: PropTypes.func,
  dismiss: PropTypes.func,
  confirmation: PropTypes.string,
  options: PropTypes.object,
};

export default confirmable(Dialog);
