import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './loader.module.scss';

/**
 * Renders a <Loader /> component
 * @component
 * @example
 *
 * return (
 *   <Loader />
 * )
 */
export const Loader = ({ className, size = 'xs', theme }) => {
  const dotClasses = cn(styles['loader__dot'], {
    [styles['loader__dot_primary']]: theme === 'primary',
    [styles['loader__dot_secondary']]: theme === 'secondary',
    [styles['loader__dot_ternary']]: theme === 'ternary',
  });
  const dotSizeClasses = cn(styles['loader-dot'], {
    [styles['loader__dot_size_xxs']]: size === 'xxs',
    [styles['loader__dot_size_xs']]: size === 'xs',
    [styles['loader__dot_size_sm']]: size === 'sm',
    [styles['loader__dot_size_md']]: size === 'md',
    [styles['loader__dot_size_lg']]: size === 'lg',
    [styles['loader__dot_size_xl']]: size === 'xl',
    [styles['loader__dot_size_xxl']]: size === 'xxl',
  });

  return (
    <ul className={cn(styles['loader'], className)} data-testid="loader" role="status">
      <li className={cn(dotClasses, dotSizeClasses)} />
      <li className={cn(dotClasses, dotSizeClasses)} />
      <li className={cn(dotClasses, dotSizeClasses)} />
    </ul>
  );
};

Loader.defaultProps = {
  className: '',
  size: 'xs',
  theme: 'primary',
};

Loader.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
   */
  size: PropTypes.string,
  /**
   *  'primary' | 'secondary' | 'ternary'
   */
  theme: PropTypes.string,
};
