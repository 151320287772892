// @ts-nocheck
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { formatGroups } from './utils';

export default {
  name: 'newSearchHints',
  state: {
    data: {},
    facets: [],
    fetching: false,
  },
  reducers: {
    clearHints: state => {
      state.data = {};
      return state;
    },
    fetchSearchHintsRequest: state => {
      state.fetching = true;
      return state;
    },
    fetchSearchHintsSuccess: (state, payload) => {
      state.data = get(payload, 'data', {});
      state.fetching = false;
      return state;
    },
    fetchSearchRequest: state => {
      state.fetching = true;
      return state;
    },
    fetchSearchSuccess: state => {
      state.fetching = false;
      return state;
    },
  },
  effects(dispatch) {
    const { api, ...models } = dispatch;
    const { newCatalog } = models;

    return {
      async fetchSearchHints(text) {
        try {
          if (text.length >= 3) {
            this.fetchSearchHintsRequest();

            const result = await api.get({
              url: '/new-search-suggestions',
              query: `?text=${text.toLowerCase()}`,
            });

            this.fetchSearchHintsSuccess(result);

            return result;
          }
          return null;
        } catch (err) {
          return null;
        }
      },
      async fetchSearchMain({ text, onlyItems }) {
        try {
          this.fetchSearchRequest();
          const encoded = encodeURI(text);
          const hasOnlyItems = onlyItems ? '&onlyItems=1' : '';
          const result = await api.get({
            url: '/new-search-main',
            query: `?text=${encoded}${hasOnlyItems}`,
          });

          this.fetchSearchSuccess(result);

          return result;
        } catch (err) {
          return null;
        }
      },
      async fetchCatalogGroup(searchCategory) {
        try {
          this.fetchSearchRequest();
          const result = await newCatalog.fetchCatalogGroup(searchCategory.categoryId);

          this.fetchSearchSuccess();

          return result;
        } catch (err) {
          return null;
        }
      },
    };
  },
  selectors: slice => ({
    getSearchHintsFacets() {
      return slice(state => get(state, 'facets', []));
    },
    getFetching() {
      return slice(state => get(state, 'fetching', false));
    },
    getSearchHints() {
      return slice(state => {
        const data = get(state, 'data', {});
        const { brands = [], goods = [], groups = [] } = data;
        const hasItems = !(isEmpty(brands) && isEmpty(goods) && isEmpty(groups));
        const list = [
          {
            id: 'brands',
            title: 'Бренды',
            list: brands.slice(0, 10),
          },
          {
            id: 'goods',
            title: 'Товары',
            list: goods.slice(0, 10),
          },
          {
            id: 'groups',
            title: 'Категории',
            list: groups.slice(0, 10).map(formatGroups),
          },
        ];

        return {
          hasItems,
          list,
        };
      });
    },
  }),
};
