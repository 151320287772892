import get from 'lodash/get';

export const changeRows = (rows = [], payload = {}) => {
  if (get(payload, ['fix', 'done'], false)) {
    const hasCommand = get(payload, 'command', '');
    if (hasCommand) return rows;

    if (payload?.delete) {
      const itemId = get(payload, ['fix', 'payload', 'id'], 0);
      return rows.filter(sourceRow => sourceRow.id !== itemId);
    }

    return get(payload, ['fix', 'payload', 'goods'], rows);
  }

  return rows;
};
