/* eslint-disable no-nested-ternary */
import isNil from 'lodash/isNil';
import get from 'lodash/get';

export const chooseQueueMessage = socketMessage => {
  const command = get(socketMessage, ['data', 'fix', 'payload', 'command'], '').toLowerCase();
  const fix = get(socketMessage, ['data', 'fix'], null);
  const orderNo = get(fix, 'id', 0);

  const message = {
    id: orderNo,
    success: false,
    text: '',
  };

  const isSign = command === 'sign';
  const isUnsign = command === 'unsign';
  const isDelete = command === 'del';

  if (!fix?.done && fix?.error === null) {
    message.success = false;
  }

  if (fix?.done && fix?.error === null) {
    message.success = true;
    message.text = isDelete
      ? `Заказ ${orderNo} удален`
      : isSign
      ? `Заказ ${orderNo} подписан`
      : isUnsign
      ? `Заказ ${orderNo} снят с подписи`
      : 'Изменения сохранены';
  }

  if (!isNil(fix?.error)) {
    message.success = false;
    message.text = fix.error;
  }

  return message;
};
