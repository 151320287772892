import createValue from '../helpers/createValue';
import strToFixedStr from '../helpers/strToFixedStr';

export default {
  key: 'weight',
  create: ({ order }) => ({
    label: 'Вес:',
    value: createValue(strToFixedStr((order && order.weight) || 0.0, 2)),
  }),
};
