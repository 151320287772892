import alert from 'components/native/alert/function';

export const validationSearchValue = value => {
  if (!value) {
    return null;
  }

  let no = value.toUpperCase();

  if (/А-ЯЁ/.test(no)) {
    alert(
      'Поле не должно содержать буквы кириллицы!<br />Недопустимые символы будут удалены автоматически',
    );

    no = no.replace(/[А-ЯЁ]+/g, '');

    if (!no) return null;
  }

  return no;
};
