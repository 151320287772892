import React, { useEffect } from 'react';
import { DEFAULT_PAGE_SIZE } from 'common/config';
import columns from '../columns/orders';
import { PAGE_SIZE, NO_DATA_TEXT } from '../constants';
import TableList from 'components/table/list';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';

const SelectOrderTable = () => {
  const {
    claims: { getPostedOrders },
  } = useDispatch();
  const loading = useSelector(getEffectLoading('claims', 'getPostedOrders'));

  useEffect(() => { getPostedOrders() }, []);

  return (
    <TableList
      name="orders-posted"
      noDataText={NO_DATA_TEXT}
      columns={columns}
      loading={loading}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      minRows={1}
    />
  );
};

export default SelectOrderTable;
