import { createAction } from 'redux-actions';

export const SET_ITEM_DESIRED_QTY = 'SET_ITEM_DESIRED_QTY';
export const SET_ITEM_DESIRED_PRICE = 'SET_ITEM_DESIRED_PRICE';
export const SET_ITEM_DESIRED_CLIENT_PRICE = 'SET_ITEM_DESIRED_CLIENT_PRICE';
export const CLEAR_ITEM_STATE = 'CLEAR_ITEM_STATE';
export const CLEAR_ALL_ITEMS_STATE = 'CLEAR_ALL_ITEMS_STATE';

export const setItemDesiredQty = createAction(SET_ITEM_DESIRED_QTY);

export const SET_FILTER_NO_VALUE = 'SET_FILTER_NO_VALUE';
export const SET_FILTER_OZPR_VALUE = 'SET_FILTER_OZPR_VALUE';
export const SET_SEARCH_ORDER_VALUE = 'SET_SEARCH_ORDER_VALUE';
export const SET_FILTER_CONTACT_VALUE = 'SET_FILTER_CONTACT_VALUE';
export const SET_JOINED = 'SET_JOINED';

export const ADD_SELECTED_INDEX_ROWS = 'ADD_SELECTED_INDEX_ROWS';
export const DELETE_SELECTED_INDEX_ROWS = 'REMOVE_SELECTED_INDEX_ROWS';
export const addSelectedIndexRows = createAction(ADD_SELECTED_INDEX_ROWS);
export const deleteSelectedIndexRows = createAction(DELETE_SELECTED_INDEX_ROWS);
