import React from 'react';
import Content from '../layout/content';
import { useSelector } from 'react-redux';
import { sanitizeContent } from './helper';

const Article = ({ handleArticle, noTitle }) => {
  const article = useSelector(state => state.wiki.article);

  if (!article) {
    return (
      <div className="wiki-article">
        <div className="wiki-description">
          <p>Нет информации по складу!</p>
        </div>
      </div>
    );
  }

  let description = {};

  if (handleArticle && Object.keys(handleArticle).length) {
    description = handleArticle;
  } else if (Object.keys(article).length) {
    description = article;
  }

  const text1 = description['Text1'];
  const text2 = description['Text2'];

  const content = sanitizeContent(text1 + (text2 || ''));

  return noTitle ? (
    <div className="wiki-article">
      <div className="wiki-description">{content}</div>
    </div>
  ) : (
    <Content title={description.Name} style={{ padding: 0 }}>
      <div className="wiki-article">
        <div className="wiki-description">{content}</div>
      </div>
    </Content>
  );
};

export default Article;
