export const DEFAULT_OPTION_ORDER_SORT = {
  id: 1,
  value: 'orderDate',
  label: 'Дате создания по убыванию',
  desc: true,
};

export const OPTIONS_ORDER_SORT = [
  {
    id: 2,
    value: 'orderDate',
    label: 'Дате создания по возрастанию',
    desc: false,
  },
  {
    id: 3,
    value: 'price',
    label: 'Сумме заказа по убыванию',
    desc: true,
  },
  {
    id: 4,
    value: 'price',
    label: 'Сумме заказа по возрастанию',
    desc: false,
  },
  {
    id: 5,
    value: 'shipment',
    label: 'Метод отгрузки (Я -> А)',
    desc: true,
  },
  {
    id: 6,
    value: 'shipment',
    label: 'Метод отгрузки (А -> Я)',
    desc: false,
  },
];
