// @ts-nocheck
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { SIGN, UNSIGN } from 'features/order-actions/constants';

const editStatus = (orderNo, status, apiStatus) => order =>
  order.orderNo === orderNo ? { ...order, ...apiStatus[status] } : order;

export const changeOrders = (orders = [], payload = {}) => {
  const fix = get(payload, ['data', 'fix'], null);
  const orderNo = get(payload, ['data', 'id'], 0);
  const { order: socketOrder } = fix.payload;

  const command = get(
    payload,
    ['data', 'fix', 'payload', 'data', '0', 'command'],
    '',
  )?.toLowerCase();

  let status = '';

  if (!fix.done && fix.error === null) {
    status = 'fetching';
  }

  if (fix.done && fix.error === null) {
    status = 'done';
  }
  if (fix.done && fix.error !== null) {
    status = 'error';
  }

  switch (command) {
    case 'sign':
      return orders.map(editStatus(orderNo, status, SIGN));

    case 'unsign':
      return orders.map(editStatus(orderNo, status, UNSIGN));

    default: {
      if (!isEmpty(socketOrder)) {
        return orders.reduce((acc, order) => {
          if (order.orderNo === socketOrder.orderNo) {
            return [...acc, { ...order, ...socketOrder, status: socketOrder.statusV2 }];
          }

          return [...acc, order];
        }, []);
      }

      return orders;
    }
  }
};
