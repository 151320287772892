import confirm from 'components/native/confirm/function';
import { QUEUE_ORDER } from 'common/config';
import notifier from 'services/notifier';
import alert from 'components/native/alert/function';

export default dispatch =>
  async ({ orderNo, reserve, isList = false }) => {
    const confirmation =
      reserve > 0
        ? `В заказе есть ${
            +reserve === 3 ? 'дефицитные товары' : 'товары с платной отменой резерва'
          }, продолжить?`
        : `Пожалуйста, подтвердите удаление заказа ${orderNo}`;

    try {
      await confirm(confirmation);
      const { success, message } = await dispatch.api.post({
        url: `orders/delete/${orderNo}`,
        data: {
          queueType: QUEUE_ORDER,
          command: 'del',
        },
        cancellation: false,
      });

      if (success) {
        notifier.show(orderNo, `Заказ ${orderNo} удаляется`);
        isList &&
          dispatch.list.changeRow({
            searchKey: 'orderNo',
            needle: orderNo,
            disabled: true,
            statusText: 'на удалении',
          });
      } else {
        alert(message);
      }
    } catch (e) {}
  };
