import get from 'lodash/get';
import { SerialNoCell } from 'pages/Warranty/components/serial/cells';

export const columns = [
  {
    Header: 'Серийный номер',
    id: 'serialNo',
    accessor: 'serial',
    sortable: false,
    width: 150,
    Cell: SerialNoCell,
  },
  {
    Header: 'Код товара',
    id: 'itemNo',
    accessor: 'id',
    width: 100,
  },
  {
    Header: 'Наименование',
    id: 'name',
    accessor: 'name',
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
    sortable: false,
    width: 400,
  },
  {
    Header: 'Окончание гарантии',
    id: 'expireDate',
    accessor: d => d.expireDateSort,
    Cell: row => get(row, ['original', 'expireDate'], null),
  },
];
