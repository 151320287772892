// @ts-nocheck
import get from 'lodash/get';
import { CACHE_NAME } from 'common/config';

export default {
  name: 'newBanners',
  state: {
    items: [],
    fetching: false,
  },
  reducers: {
    fetchBannersRequest: state => {
      state.fetching = true;
      return state;
    },
    fetchBannersSuccess: (state, payload) => {
      state.items = get(payload, ['data', 'top_v2.0'], []);
      state.fetching = false;
      return state;
    },
  },
  effects: ({ api }) => ({
    async fetchBanners() {
      try {
        this.fetchBannersRequest();
        const result = await api.get({
          url: '/new-banners',
          cache: CACHE_NAME,
        });

        this.fetchBannersSuccess(result);

        return result;
      } catch (err) {
        return null;
      }
    },
  }),
  selectors: slice => ({
    getBanners() {
      return slice(state => state);
    },
  }),
};
