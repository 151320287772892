import React, { Children, cloneElement } from 'react';
import { ReactTableDefaults } from 'react-table-v6';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

const ChoosedTHead = props => {
  const choosed = useSelector(state => state.bindings.choosed);

  const {
    bindings: { findItems },
  } = useDispatch();

  if (isEmpty(choosed)) {
    return null;
  }

  const children = Children.toArray(props.children.props.children);

  const handleFindItems = async () => {
    await findItems(choosed);
  };

  return (
    <ReactTableDefaults.TheadComponent {...props}>
      <ReactTableDefaults.TrComponent>
        {children.map(child => {
          const style = { ...child.props.style, height: 40, lineHeight: 2.3 };
          const children = choosed[child.key.substr(4)];

          return cloneElement(child, { children, style });
        })}
        <ReactTableDefaults.ThComponent
          style={{ width: 40, flex: '40 0 auto', maxWidth: 40, height: 40, lineHeight: 3.1 }}
        >
          <i
            className="icon icon-find-link enabled"
            onClick={handleFindItems}
            title="Найти соответствия"
          />
        </ReactTableDefaults.ThComponent>
      </ReactTableDefaults.TrComponent>
    </ReactTableDefaults.TheadComponent>
  );
};

export default ChoosedTHead;
