import React, { Fragment, useState } from 'react';
import ReactTable from 'react-table-v6';
import ReactModal from 'react-modal';
import queueHeaderColumns from './columns/queueHeaderColumns';
import queueItemColumns from './columns/queueItemColumns';
import useModalPositionToElem from '../../../../hooks/use-modal-position-to-elem';
import useClientRect from '../../../../hooks/use-client-rect';
import classNames from 'classnames';
import { isCactus } from 'common/config';
import { useParams } from 'react-router-dom';
import { useSelector, useStore } from 'react-redux';

const classModal = classNames('Modal', 'Modal__queue-columns', { 'Modal--cactus': isCactus });

const OrderQueueList = React.memo(() => {
  const { id } = useParams();
  const [show, setShow] = useState('header');
  const [isOpen, setIsOpen] = useState(false);

  const {
    select: { queue },
  } = useStore();

  const header = useSelector(queue.getHeader(id));
  const rows = useSelector(queue.getRows(id));

  const [rectElem, refElem] = useClientRect();
  const [rectModal, refModal] = useClientRect();
  const modalContentStyle = useModalPositionToElem(rectElem, rectModal, 'left');

  const tabsToggle = e => {
    e.preventDefault();
    setShow(e.target.id);
  };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const dataForTable = show === 'header' ? header : rows;
  const data = Array.isArray(dataForTable) ? dataForTable : [];

  return (
    <Fragment>
      <i
        ref={refElem}
        className="queue-switcher icon icon-queue-view"
        title="Просмотр очереди "
        onClick={openModal}
      />
      <ReactModal
        contentRef={refModal}
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className={classModal}
        overlayClassName="Overlay Overlay__columns"
        style={{
          width: '70%',
          content: {
            ...modalContentStyle,
          },
        }}
      >
        <div>
          <div className="order-edit-header text-left underlined underlined--red">
            Просмотр очереди{' '}
            <i
              className="icon icon-close12"
              onClick={closeModal}
              style={{ float: 'right', cursor: 'pointer' }}
            />
          </div>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${show === 'header' ? 'active' : ''}`}
                id="header"
                href=""
                onClick={tabsToggle}
              >
                Заголовок документа
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${show === 'rows' ? 'active' : ''}`}
                id="rows"
                href=""
                onClick={tabsToggle}
              >
                Строки документа
              </a>
            </li>
          </ul>
          <div className="queue-rows">
            <ReactTable
              columns={show === 'header' ? queueHeaderColumns : queueItemColumns}
              data={data}
              defailtPageSize={5000}
              minRows={1}
              noDataText="Данных нет"
              showPagination={false}
            />
          </div>
        </div>
      </ReactModal>
    </Fragment>
  );
});

export default OrderQueueList;
