import React from 'react';
import { Field } from 'redux-form';
import { Button } from 'common/ui/button';
import { renderText, renderTextarea } from 'components/fields';
import { renderMasked } from 'pages/Settings/components/fields';
import { mask } from 'pages/Settings/helpers/util';

import styles from './unlocking-form.module.scss';

export const Form = ({ submitting, handleSubmit, reset, onClose }) => {
  const handleReset = () => {
    reset();
    onClose();
  };

  return (
    <form className={styles['form']} onSubmit={handleSubmit}>
      <div className={styles['field']}>
        <Field name="secondName" type="text" label="ФИО" required component={renderText} />
      </div>
      <div className={styles['field']}>
        <Field name="email" type="text" label="E-mail" required component={renderText} />
      </div>
      <div className={styles['field']}>
        <Field
          name="mobilePhone"
          type="text"
          label="Телефон"
          placeholder="+7(___)___-__-__"
          required
          mask={mask}
          component={renderMasked}
        />
      </div>
      <div className={styles['field']}>
        <Field name="description" label="Описание" required component={renderTextarea} />
      </div>
      <div className={styles['buttons']}>
        <Button theme="secondary" type="submit" className={styles['submit']} disabled={submitting}>
          Отправить
        </Button>
        <Button
          type="button"
          className={styles['cancel']}
          disabled={submitting}
          onClick={handleReset}
        >
          Отмена
        </Button>
      </div>
    </form>
  );
};
