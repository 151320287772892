import React from 'react';
import { Link } from 'react-router-dom';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import agentsColumns from '../../common/columns/agents-columns';
import withApi from 'hoc/withApi';

const ShippingAgentList = () => (
  <Content title="Заявки на агентов по доставке">
    <div className="shiping-agent__list">
      <div className="actions-container actions-container--shiping-agent">
        <Link to="/logistic/agents/new" className="shiping-agent__link">
          <i className="icon-create">+</i>
          Создать заявку
        </Link>
      </div>
      <div className="shiping-agents-list">
        <TableList columns={agentsColumns} pageSize={5000} defaultPageSize={5000} />
      </div>
    </div>
  </Content>
);

export default withApi(ShippingAgentList);
