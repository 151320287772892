import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { UserMenu } from './components/user-menu';
import { Navigation } from './components/navigation';

import styles from './header.module.scss';

export const Header = ({ className }) => (
  <header className={cn(styles['header'], className)} data-testid="header">
    <UserMenu />
    <Navigation />
  </header>
);

Header.defaultProps = {
  className: '',
};

Header.propTypes = {
  className: PropTypes.string,
};
