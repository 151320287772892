import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const Children = ({ childs, isArticles, artIndex, ...props }) => {
  if (!childs?.length) {
    return null;
  }

  return (
    <div className="wiki-actions justify-content-center">
      <div className="row submenu_container">
        {childs.map((child, index) =>
          isArticles ? (
            <a
              href="#"
              className={`link link--underlined ${artIndex === index ? 'link--bold' : ''}`}
              onClick={e => {
                e.preventDefault();
                props.onClick(index);
              }}
            >
              {child.Name}
            </a>
          ) : (
            <NavLink
              to={child['Cat_ID'] ? `/article/${child.ID}` : `/category/${child.ID}`}
              isActive={() => false}
              className="link link--underlined"
            >
              {child.Name}
            </NavLink>
          ),
        )}
      </div>
    </div>
  );
};

Children.propTypes = {
  childs: PropTypes.arrayOf(PropTypes.object).isRequired,
  isArticles: PropTypes.bool,
};

export default Children;
