import { createContext } from 'use-context-selector';
import withContextReducer from 'hoc/withContextReducer';
import withToggle from 'hoc/withToggle';
import withDrag from 'hoc/withDrag';
import { compose } from 'redux';
import React from 'react';
import { connect } from 'react-redux';
import {
  getItemsColumnsOrder,
  getItemsVisibility,
  isItemsVisibilitySaved,
} from 'pages/Orders/selectors';
import { CLEAR_ALL_ITEMS_STATE } from '../action';
import itemPriceReducer, { itemPriceInitialState } from '../reducer/itemPricesReducer';
import ItemsTable from '../components/items-table-list';

export const ItemsTableContext = createContext();
const mapStateToProps = (state, { isNotPreorder }) => ({
  visibility: getItemsVisibility(state)(isNotPreorder),
  saved: isItemsVisibilitySaved(state),
  columnsOrder: getItemsColumnsOrder(state),
});

export default compose(
  connect(mapStateToProps, null, null, { forwardRef: true }),
  withContextReducer(
    itemPriceReducer,
    itemPriceInitialState,
    ItemsTableContext,
    CLEAR_ALL_ITEMS_STATE,
  ),
  withToggle({ accessor: 'status', id: 'status' }),
  withDrag,
)(ItemsTable);
