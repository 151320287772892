export default ({ api }) =>
  async () => {
    try {
      const { data = [] } = await api.get({
        url: 'shipment-schedule/orders-get',
      });

      return data.map(order => ({
        label: order.no,
        value: order.no,
        date: order.planningShipmentDate,
        shipmentMethodCode: order.shipmentMethodCode,
        logisticObject: order.logisticObject,
        initiator: order.initiator,
        description: order.description,
        sum: parseFloat(order.sum).toFixed(2).toLocaleString(),
      }));
    } catch (err) {
      return [];
    }
  };
