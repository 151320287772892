import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import ReactModal from 'react-modal';
import get from 'lodash/get';
import ReactHtmlParser from 'react-html-parser';
import classnames from 'classnames';
import { isCactus } from 'common/constants/infrasturucture';

const classModal = classnames('Modal', 'Modal--confirm', { 'Modal--cactus': isCactus });

const Dialog = ({ show, proceed, dismiss, cancel, confirmation, options }) => {
  const isAlert = options.isAlert || false;
  const noFixedLayout = options.noFixedLayout || false;
  const bodyOpenClassName = get(options, 'bodyOpenClassName', 'ReactModal__Body--open');

  const buttons = isAlert ? (
    <div className="row justify-content-center modal-actions">
      <button className="btn btn-outline-danger btn--rect" type="button" onClick={() => proceed()}>
        {options.confirmLabel || 'Ок'}
      </button>
    </div>
  ) : (
    <div className="row justify-content-center modal-actions">
      <button className="btn btn-outline-danger btn--rect" type="button" onClick={() => cancel()}>
        {options.abortLabel || 'Отмена'}
      </button>
      <button className="btn btn-danger btn--rect" type="button" onClick={() => proceed()}>
        {options.confirmLabel || 'Ок'}
      </button>
    </div>
  );

  return (
    <ReactModal
      ariaHideApp={false}
      onRequestClose={dismiss}
      isOpen={show}
      contentLabel="Confirm"
      className={classModal}
      overlayClassName="Overlay Overlay--confirm"
      shouldCloseOnOverlayClick={false}
      bodyOpenClassName={`${bodyOpenClassName} ${
        noFixedLayout && 'ReactModal__Body--open--related'
      }`}
    >
      <div className="confirm-body">
        {ReactHtmlParser(
          Array.isArray(confirmation) ? confirmation.join('\n') : confirmation,
          options || {},
        )}
      </div>
      {buttons}
    </ReactModal>
  );
};

Dialog.propTypes = {
  show: PropTypes.bool,
  proceed: PropTypes.func,
  cancel: PropTypes.func,
  dismiss: PropTypes.func,
  confirmation: PropTypes.string,
  options: PropTypes.object,
};

export default confirmable(Dialog);
