import React, {Fragment, useCallback, useRef, memo, useMemo} from 'react'
import { Link } from 'react-router-dom';
import { undef } from 'common/util';
import Content from 'components/layout/content';
import { LoadingSubmitButton } from 'components/loading-sumbit-button';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getTableListData } from 'common/selectors';
import withApi from 'hoc/withApi';
import useListData from "components/table/hooks/useListData"

const GetButton = memo(({ no, type, lol }) => {
  const {
    select: { priceList },
  } = useStore();
  const sending = useSelector(priceList.getSending(no));
  const ref = useRef();

  const {
    priceList: { sendFile, getFile },
  } = useDispatch();

  const handleFile = useCallback(async () => {
    if (ref?.current?.checked) {
      return await sendFile({ index: no, type, lol });
    }

    return await getFile({ type, lol });
  }, [no, type, lol]);

  return (
    <Fragment>
      <td width="100">
        <label className="email-checkbox">
          <input className="email-checkbox" ref={ref} type="checkbox" name="email" />
          на e-mail
        </label>
      </td>
      <td className="align-right">
        <LoadingSubmitButton
          label="Получить"
          fetching={sending}
          position="left"
          btnClass="btn btn-outline-danger btn--rect"
          onClick={handleFile}
        />
      </td>
    </Fragment>
  );
});

const PriceLists = () => {
  const priceLists = useListData();

  const prices = useMemo(() => priceLists.map((priceList = {}, index) => {
    const prices = Object.keys(priceList || {}).map((type, key) => {
      if (!priceList[type]) {
        return false
      }

      const { name, size, dtime: time, lol } = priceList[type];
      const no = `${index}${key}`;

      return (
        <tr key={index}>
          <td width="10"/>
          <td width="24">
            <span className="icon icon-zip"/>
          </td>
          <td className="align-left">
            <b>{name}</b>
          </td>
          {undef(size) ? (
            <td colSpan="5" className="not-found">
              файл не найден
            </td>
          ) : (
            <Fragment>
              <td width="110">Формат {type.toUpperCase()}</td>
              <td width="130">{time}</td>
              <td width="70">{size}</td>
              <GetButton no={no} type={type} lol={lol}/>
            </Fragment>
          )}
        </tr>
      );
    }).filter(Boolean)

    return !!prices.length && prices || false
  }).filter(Boolean), [priceLists.length])

  return (
    <Content title="Загрузка прайс-листа">
      <div className="pricelists">
        <table className="pricelists-table">
          <tbody>
            {!!prices.length && prices || (
              <tr>
                <td>Прайс листы не найдены!</td>
              </tr>
            )}
          </tbody>
        </table>
        <p>
          Вы можете получать прайс на e-mail, указанный в ваших{' '}
          <Link to={'/settings/personal'}>настройках</Link> поставив галку рядом с кнопкой
          &#171;Получить&#187;.
          <br />
          При появлении сообщения о загрузке файла, выберите &#171;Сохранить&#187; и укажите место
          для сохранения файла на вашем компьютере.
        </p>
      </div>
    </Content>
  );
};

export default withApi(PriceLists);
