/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Content from 'components/layout/content';
import ItemColumns from '../common/columns/demofond/itemColumns';
import OrderActions from './components/orderActions';
import ItemsSearch from 'pages/Orders/components/common/items-search';
import CatalogOpener from 'pages/Orders/components/catalog/components/CatalogOpener';
import { demoCollector, DEMOFOND_TABLE_NAME, DFTYPE } from '../../constants';
import DemofondSwitcher from './components/demofondSwitcher';
import withContextReducer from 'hoc/withContextReducer';
import { vadReducer } from '../../reducer';
import { DEFAULT_PAGE_SIZE } from 'common/config';
import ReactTable from 'react-table-v6';
import TablePagination from 'components/table/pagination';
import { demofondModeContext } from 'pages/VAD/context';
import useToggleHandler from 'hooks/use-toggle-handler';
import useContextSelector from 'hooks/context/useContextSelector';
import { useDispatch, useSelector } from 'react-redux';
import { isFetchingItems } from 'pages/Orders/selectors';
import { getTableListData } from 'common/selectors';
import { initState } from '../../reducer';
import useListData from "components/table/hooks/useListData"

const defaultSorted = [
  {
    id: 'id',
    desc: true,
  },
];
const loadingText = <i className="loader loader--ripple" />;

const Demofond = () => {
  const [isFiltersOpened] = useToggleHandler(false);
  const [selectedRow, setSelectedRow] = useState({});

  const fetchingItems = useSelector(isFetchingItems);
  const {
    list: { clear },
    catalog: { loadCategories, clear: clearCatalog },
  } = useDispatch();
  const data = useListData();

  useMemo(
    () => ({
      display: isFiltersOpened ? 'flex' : 'none',
    }),
    [isFiltersOpened],
  );

  const catRef = useRef();
  const demofondMode = useContextSelector(demofondModeContext, state => state.demofondMode);
  const prevMode = useRef(demofondMode);

  const handleToggleCategories = () => {
    if (prevMode.current !== demofondMode) {
      loadCategories({ isDemofond: true });
    }

    prevMode.current = demofondMode;
  };

  useEffect(() => {
    clearCatalog();
  }, []);

  useEffect(() => {
    clear();
    setSelectedRow({});
    catRef?.current.toggle?.(true, handleToggleCategories);
    demoCollector.clear();
  }, [demofondMode]);

  const getTdProps = useCallback((state, row) => {
    if (!row) return {};
    const { id } = row.original;

    return {
      activate: flag =>
        setSelectedRow(state => {
          const { [id]: foo, ...rest } = state;

          return flag ? { ...state, [id]: flag } : rest;
        }),
    };
  }, []);

  const getTrProps = useCallback(
    (state, info) => {
      if (!info || !selectedRow[info.original.id]) return {};

      return { className: 'access' };
    },
    [selectedRow],
  );

  const columns = useMemo(() => ItemColumns(demofondMode), [demofondMode]);

  return (
    <Content title="Демофонд">
      <Link to="/vad/demofond-list" className="section-nav demofond__section-nav">
        <i className="icon icon-back-icon" /> Список заказов
      </Link>
      <div className="actions-container demofond__container">
        <div className="demofond__buttons">
          <CatalogOpener ref={catRef} catalogText={'Каталог товаров'} />
          <ItemsSearch className="form-control--round" />
          <DemofondSwitcher />
        </div>
        <OrderActions />
      </div>
      <div className="order-filter-and-list demofond__order-filter-and-list">
        {/* TODO: вернуть как будут готовы фильтры для демофонда */}
        {/* <div
          className={
            'order-filter-opener__container' +
            (order && order.initiator && order.docType !== 4
              ? ' with-order'
              : '')
          }
        >
          <a
            href="#order-filter-opener"
            className="order-filter-opener"
            id="order-filter-opener"
            styles={!isFiltersOpened ? { display: 'flex' } : { display: 'none' }}
            onClick={() => {
              setIsFiltersOpened(true)
            }}
          >
            <i
              className={classNames(
                'order-filter-opener__icon',
                'icon',
                'icon-order-filter_head_right-arrow'
              )}
            />
          </a>
        </div> */}
        {/* <div style={wrapperStyle}>
          <OrderListFilter
            onFilterHeadClick={handleFilterHeadClick}
          />
        </div>*/}
        <div
          className={classNames('table-container', 'demofond__table', {
            'order-list--with-filter': isFiltersOpened,
          })}
        >
          <ReactTable
            name={DEMOFOND_TABLE_NAME}
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            getTdProps={getTdProps}
            getTrProps={getTrProps}
            loading={fetchingItems}
            PaginationComponent={TablePagination}
            showPagination={data.length > DEFAULT_PAGE_SIZE}
            minRows={1}
            nextText=">"
            previousText="<"
            endText=">>"
            startText="<<"
            rowsText="строк"
            noDataText="Нет данных"
            loadingText={loadingText}
          />
        </div>
      </div>
    </Content>
  );
};

export default withContextReducer(vadReducer, initState, demofondModeContext)(Demofond);
