import { dateToRU } from 'common/util';
import { createTime } from 'pages/VAD/helper/util';

export const loadDetail = (state, { data }) => ({ ...state, detail: data });

export const addComment = (state, { comment, login }) => {
  const comments = [...(state.detail.comments || [])];
  const date = new Date();

  comments.push({
    author: login,
    comment,
    createDate: dateToRU(date),
    createTime: createTime(date),
  });

  return { ...state, detail: { ...state.detail, comments } };
};
