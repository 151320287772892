import { filesValidators } from './validateFeedBack';

const validateTZ = values => {
  const requiredField = 'Заполните поле';
  const errors = {};

  if (!values.customerName) {
    errors.customerName = requiredField;
  }

  if (!values.inn) {
    errors.inn = requiredField;
  }

  if (!values.otherFiles) {
    errors.otherFiles = 'Загрузите файл';
  }

  if (values.otherFiles) {
    errors.otherFiles = filesValidators(values.otherFiles);
  }

  return errors;
};

export default validateTZ;
