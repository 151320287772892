import { getOrder } from 'pages/Orders/selectors';
import { useSelector } from 'react-redux';
import useLocalForage from 'hooks/use-local-forage';
import { useEffect, useState } from 'react';
import useIsPreorder from 'pages/Orders/hooks/useIsPreorder';
import useDeepEffect from "hooks/use-deep-effect"

const useCurrentOrder = () => {
  const order = useSelector(getOrder);
  const [storedOrder] = useLocalForage('location.state', null);
  const isNotPreorder = !useIsPreorder();
  const [currentOrder, setCurrentOrder] = useState(order);

  useDeepEffect(() => {
    const isPreOrder = Number(order.tender) === 4;
    const isPreOrderForage = Number(storedOrder?.tender) === 4;

    setCurrentOrder(
      (((isPreOrder && !isNotPreorder) || (isNotPreorder && !isPreOrder)) && order) ||
        (((isPreOrderForage && !isNotPreorder) || (isNotPreorder && !isPreOrderForage)) &&
          storedOrder) ||
        {},
    );
  }, [isNotPreorder, order, storedOrder]);

  const {hidden, ...rest} = currentOrder

  return rest;
};

export default useCurrentOrder;
