import React from 'react';
import { useDesign, usePopup } from 'hooks/new';
import { Button } from 'common/ui/button';
import { Heading } from 'common/ui/heading';
import { Popup } from 'common/ui/popup';
import { InviteForm } from './components/invite-form';

import styles from './invite.module.scss';

export const Invite = () => {
  const [isPilot] = useDesign();
  const [openPopup, closePopup, isPopupOpen] = usePopup();

  return (
    <>
      <Button
        className={styles['button']}
        theme={isPilot ? 'primary' : 'default'}
        onClick={openPopup}
      >
        {isPilot ? (
          'Добавить сотрудника'
        ) : (
          <>
            <i className="icon icon-create-icon" /> Добавить сотрудника
          </>
        )}
      </Button>
      <Popup
        className={styles['invite-popup']}
        body={<InviteForm onClose={closePopup} />}
        header={
          <Heading className={styles['heading']} size="md">
            Отправить приглашение сотруднику
          </Heading>
        }
        visible={isPopupOpen}
        onClose={closePopup}
      />
    </>
  );
};
