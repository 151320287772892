export const CURRENCIES = {
  '': {
    name: 'RUB',
    code: '₽',
    locale: 'ru-RU',
  },
  РУБ: {
    name: 'RUB',
    code: '₽',
    locale: 'ru-RU',
  },
  RUR: {
    name: 'RUB',
    code: '₽',
  },
  RUB: {
    name: 'RUB',
    code: '₽',
    locale: 'ru-RU',
  },
  EUR: {
    name: 'EUR',
    code: '€',
    locale: 'en-EN',
  },
  USD: {
    name: 'USD',
    code: '$',
    locale: 'en-EN',
  },
};
