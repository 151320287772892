import EmployeeActions from '../components/actions';

export default [
  {
    Header: '',
    id: 'actions',
    accessor: '',
    Cell: EmployeeActions,
    width: 120,
  },
  {
    Header: 'Логин',
    accessor: 'login',
  },
  {
    Header: 'Фамилия',
    accessor: 'secondName',
  },
  {
    Header: 'Имя',
    accessor: 'name',
  },
  {
    Header: 'Отчество',
    accessor: 'thirdName',
  },
];
