import React, { useCallback, useState } from 'react';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import columns from '../common/columns/attachments';
import FilterGroup from './fields/FilterGroup';
import FilterType from './fields/FilterType';
import FilterVendor from './fields/FilterVendor';
import { useSelector, useStore } from 'react-redux';
import withApi from 'hoc/withApi';
import { useLocation } from 'react-router-dom';

const optionsStyle = {
  width: 180,
};

const getPaginationProps = () => ({ isManual: true });

const Attachments = () => {
  const [filter, setFilter] = useState([]);
  const { select } = useStore();
  const pages = useSelector(select.list.getPages('attachments'));
  const { pathname } = useLocation();

  const handleSetFilter = useCallback(
    (id, value) => {
      const newFilter = value
        ? [...filter, { id, value: value.value }]
        : filter.filter(elem => elem.id !== id);

      setFilter(newFilter);
    },
    [filter],
  );

  return (
    <Content title="Библиотека знаний">
      <div className="actions-container actions-container--order align-items-center">
        <div className="row events-actions attachments-actions">
          <FilterGroup setFilter={handleSetFilter} style={optionsStyle} />
          <FilterType setFilter={handleSetFilter} style={optionsStyle} />
          <FilterVendor setFilter={handleSetFilter} style={optionsStyle} />
        </div>
      </div>
      <div className="order-list order-list__library-knowledge">
        <TableList
          columns={columns}
          filtered={filter}
          name="attachments"
          manual
          route={pathname}
          pages={pages}
          defaultPageSize={20}
          getPaginationProps={getPaginationProps}
          showPaginationTop={pages > 1}
          showPaginationBottom={pages > 1}
        />
      </div>
    </Content>
  );
};

export default withApi(Attachments);
