import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Back = ({
  className,
  color = '#236192',
  name,
  width = '14',
  height = '10',
  viewBox = '0 0 14 10',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M0.646447 4.64645C0.451184 4.84171 0.451184 5.15829 0.646447 5.35355L3.82843 8.53553C4.02369 8.7308 4.34027 8.7308 4.53553 8.53553C4.7308 8.34027 4.7308 8.02369 4.53553 7.82843L1.70711 5L4.53553 2.17157C4.7308 1.97631 4.7308 1.65973 4.53553 1.46447C4.34027 1.2692 4.02369 1.2692 3.82843 1.46447L0.646447 4.64645ZM13 5.5C13.2761 5.5 13.5 5.27614 13.5 5C13.5 4.72386 13.2761 4.5 13 4.5V5.5ZM1 5.5H13V4.5H1V5.5Z"
        fill={color}
      />
    </svg>
  );
};

Back.defaultProps = {
  className: '',
  color: '#236192',
  width: '14',
  height: '10',
  viewBox: '0 0 14 10',
};

Back.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
