import React, { useMemo } from 'react';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import AppSocket from 'components/AppSocket';
import createColumns from './components/columns';
import { useDispatch, useSelector, useStore } from 'react-redux';
import withApi from 'hoc/withApi';

const defaultSorted = [
  {
    id: 'dateReturn',
    desc: true,
  },
];

const Blocked = () => {
  const {
    select: { user },
  } = useStore();
  const allowPrinting = useSelector(user.getIsAllowPrinting);

  const {
    finances: { blockedReceive },
  } = useDispatch();

  const columns = useMemo(() => createColumns(allowPrinting), [allowPrinting]);

  return (
    <AppSocket channel="queue-blocked-docs" onReceive={blockedReceive}>
      <Content title="Список блокирующих документов">
        <div className="finances-list">
          <TableList
            defaultPageSize={5000}
            pageSize={5000}
            columns={columns}
            defaultSorted={defaultSorted}
          />
        </div>
      </Content>
    </AppSocket>
  );
};

export default withApi(Blocked);
