import React from 'react';
import { SET_FILTER_NO_VALUE, SET_FILTER_OZPR_VALUE } from 'pages/Orders/action';
import useContextSelector from 'hooks/context/useContextSelector';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { OrderFiltersContext } from 'pages/Orders/constances';

const OrderNoOrZprFilter = () => {
  const filterNoValue = useContextSelector(OrderFiltersContext, state => state.filterNoValue);
  const filterOzprValue = useContextSelector(OrderFiltersContext, state => state.filterOzprValue);
  const dispatch = useContextDispatch(OrderFiltersContext);

  return (
    <div className="order-filters">
      <div className="form-control__wrap">
        <label htmlFor="filter-no">Фильтры</label>
        <input
          type="text"
          name="filterNo"
          placeholder="номер заказа"
          className="form-control form-control-sm"
          value={filterNoValue}
          onChange={({ target: { value } }) => {
            dispatch({
              type: SET_FILTER_NO_VALUE,
              payload: value,
            });
          }}
        />
      </div>
      <label htmlFor="filter-ozpr" style={{ marginBottom: '5px' }}>
        или
      </label>
      <input
        type="text"
        name="filterOzpr"
        placeholder="номер ОЗПр"
        className="form-control form-control-sm"
        value={filterOzprValue}
        onChange={({ target: { value } }) => {
          dispatch({
            type: SET_FILTER_OZPR_VALUE,
            payload: value,
          });
        }}
      />
      <a
        href=""
        className="clear-filters"
        onClick={event => {
          event.preventDefault();
          dispatch({
            type: SET_FILTER_NO_VALUE,
            payload: '',
          });
          dispatch({
            type: SET_FILTER_OZPR_VALUE,
            payload: '',
          });
        }}
      >
        <i className="icon icon-remove-filters" title="Очистить фильтр" />
      </a>
    </div>
  );
};

export default OrderNoOrZprFilter;
