import React, { useImperativeHandle, forwardRef, useState } from 'react';
import { getItemsFromTransitChecked } from 'pages/Orders/helpers';

const CheckboxFieldTransit = ({ inputRef, buttonRef }, ref) => {
  const [checked, setChecked] = useState(false);

  const handleChange = e => {
    const { checked } = e.target;
    inputRef.current.toggle(checked);
    setChecked(checked);
    if (getItemsFromTransitChecked().length) {
      return (buttonRef.current.disabled = false);
    }

    buttonRef.current.disabled = true;
  };

  useImperativeHandle(
    ref,
    () => ({
      handleChange,
    }),
    [],
  );

  return (
    <input
      checked={checked}
      name="transit-checkbox"
      className="reserve-from-transit__checkbox"
      type="checkbox"
      onChange={handleChange}
    />
  );
};

export default forwardRef(CheckboxFieldTransit);
