import PropTypes from 'prop-types';
import FormStepContainer from './index.container';

FormStepContainer.propTypes = {
  title: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  useDivider: PropTypes.bool,
  styles: PropTypes.object,
};

FormStepContainer.defaultProps = {
  useDivider: false,
};

export default FormStepContainer;
