export const validate = values => {
  const errors = {};

  if (!values.login) {
    errors.login = 'Заполните поле';
  }

  else if (!/^[0-9a-zA-Z]{1,}\.{0,1}[0-9a-zA-Z]{1,}$/gi.test(values.login)) {
    errors.login = 'Неверно заполнено поле';
  }
  else if (values.login.replace(/[^a-zA-Z]+/gi, '').length < 3) {
    errors.login = 'Логин должен содержать минимум 3 буквы';
  }

  else if (values.login.length < 3) {
    errors.login = 'Логин должен быть не менее 3 символов';
  }

  if (!values.email) {
    errors.email = 'Заполните поле';
  } else if (!/^[^@]+@[^@]+\.[а-яё\w]+$/i.test(values.email)) {
    errors.email = 'Неверно заполнено поле';
  }
  
  return errors;
};
