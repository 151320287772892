/* eslint-disable no-console */
import { connect } from 'react-redux';
import { getUser } from 'pages/Orders/selectors';
import MessageModal from '../components/message-modal';

const mapState = state => ({
  agreement: state.message.agreement,
  message: state.message.message,
  loading: state.loading.effects.message.agree,
  user: getUser(state),
});

const mapDispatch = ({ message: { accept, flushAgreement } }) => ({
  accept,
  reject: flushAgreement,
});

export default connect(mapState, mapDispatch)(MessageModal);
