const validateDayPickerInput = () => {
  let lastValidedValue = '';
  return value => {
    if (
      /^[\d.]*$/.test(value) &&
      ((value.includes('.') && value.length <= 10) || value.length <= 8)
    ) {
      lastValidedValue = value;
    }
    return lastValidedValue;
  };
};

export default validateDayPickerInput;
