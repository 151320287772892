import React from 'react';
import sortStringNumbers from 'helpers/sortStringNumbers';
import nameColumn from '../helpers/nameColumn';

const createColumnsPreorder = [
  {
    Header: '',
    id: 'no',
    width: 25,
    sortable: false,
    Cell: row => row.viewIndex + 1,
  },
  {
    Header: 'Код',
    width: 60,
    id: 'id',
    accessor: 'id',
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Наименование',
    accessor: 'name',
    id: 'name',
    style: { textAlign: 'left' },
    Cell: nameColumn,
  },
  {
    Header: 'ЖКол',
    accessor: 'desiredQty',
    id: 'desiredQty',
    width: 75,
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Цена, ₽',
    accessor: 'price',
    id: 'price',
    style: { textAlign: 'center' },
    Cell: row => <strong>{row.value}</strong>,
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Дата поставки ',
    accessor: 'deliveryDate',
    id: 'deliveryDate',
  },
  {
    Header: 'Статус',
    accessor: 'preorderStatus',
    id: 'preorderStatus',
    sortMethod: sortStringNumbers,
  },
];

export default createColumnsPreorder;
