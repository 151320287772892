import React, { useState } from 'react';
import { Field, FormSection } from 'redux-form';
import { filesValidators, fileType } from 'common/validates/validateFeedBack';
import FieldSelect from './FieldSelect';
import FieldTextarea from './FieldTextarea';
import InputFile from './InputFile';
import FieldText from './FieldText';

export const targetOptions = [
  { value: 'Сообщить о проблеме', label: 'Сообщить о проблеме' },
  { value: 'Задать вопрос', label: 'Задать вопрос' },
];

export function FormFeedback(props) {
  const { handleSubmit, submitting } = props;

  const prefixNameFile = 'attachFile';
  const [files, setFiles] = useState([prefixNameFile]);
  const [fileIndex, setFileIndex] = useState(2);
  const [resetForm, setRestForm] = useState(false);

  const addFile = () => {
    setFileIndex(fileIndex + 1);
    setFiles([...files, prefixNameFile + fileIndex]);
  };

  const deleteFile = fileIndex => {
    const _files = [...files];
    _files.splice(fileIndex, 1);
    setFiles(_files);
  };

  if (props.submitSucceeded && !resetForm) {
    setFileIndex(fileIndex + 1);
    setFiles([prefixNameFile + fileIndex]);
    setRestForm(true);
  }
  if (!props.submitSucceeded && resetForm) {
    setRestForm(false);
  }

  return (
    <div className="feedback-container">
      <form onSubmit={handleSubmit}>
        <Field name="target" component={FieldSelect} options={targetOptions} />
        <Field name="theme" component={FieldText} type="text" />
        <Field name="description" component={FieldTextarea} />
        <FormSection name="files">
          {files.map((filesName, fileIndex) => (
            <Field
              key={filesName}
              name={filesName}
              component={InputFile}
              warn={[filesValidators, fileType]}
              props={{
                deleteFile,
                addFile,
                fileIndex,
                files,
              }}
            />
          ))}
        </FormSection>
        <div className="submit-container">
          <button type="submit" disabled={submitting}>
            ОТПРАВИТЬ
          </button>
        </div>
      </form>
    </div>
  );
}
