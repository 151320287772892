import { connect } from 'react-redux';
import SpecialPricesFormStepOne from '../components/special-prices/components/special-prices-form-step-1';

const mapStateToProps = state => ({
  initialValues: {
    clientId: state.user.info.client,
    clientName: state.user.info.Name,
    f080: new Date(),
    f120: new Date(),
    vendorCount: 1,
  },
});

export default connect(mapStateToProps)(SpecialPricesFormStepOne);
