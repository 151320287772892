import React, { memo, useRef, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import optionsItemAccess from '../../constances/options-item-access';
import getOptionByAvail from '../../helpers/get-option-by-avail';
import { OrderEditContext } from 'pages/Orders/components/order-edit/context';
import {useDispatch, useSelector, useStore} from 'react-redux'
import { getItemsAvailFilter, getItemsBrandsFilter } from 'pages/Orders/selectors';
import { getEffectLoading, getTableListData } from 'common/selectors';
import useContextSelector from 'hooks/context/useContextSelector';
import useUpdateEffect from "hooks/use-update-effect"
import { Cookies } from "services/cookies"
import useListData from "components/table/hooks/useListData"

const SelectItemAccess = () => {
  const {select: { list }} = useStore()
  const avail = useSelector(getItemsAvailFilter);
  const allBrands = useSelector(getItemsBrandsFilter);
  const items = useListData();
  const fetching = useSelector(getEffectLoading('catalog', 'getItems'));
  const isListLoading = useSelector(list.isLoading)

  const { state } = useLocation();
  const {
    items: { setAvail },
  } = useDispatch();
  const onlyAvailRef = useRef();

  const handleChange = useCallback(async ({ value }) => {
    Cookies.set('avail', value)

    await setAvail({
      avail: value,
      groupNo: state?.groupNo,
      tree: state?.tree,
    });
  }, []);

  useUpdateEffect(() => {
    if (avail !== 0 && !items.length && !allBrands.length && !isListLoading) {
      ReactTooltip.show(onlyAvailRef.current);
    }
  }, [avail, items.length, allBrands.length, isListLoading]);

  useEffect(() => {
    Cookies.set('avail', avail)
  }, [avail])

  return (
    <div
      ref={onlyAvailRef}
      data-tip="Товар не найден, попробуйте выбрать опцию Весь активный товар"
      data-for="only-avail"
      data-event="hided"
    >
      <Select
        className="select-item-access"
        value={getOptionByAvail(avail)}
        options={optionsItemAccess}
        onChange={handleChange}
        searchable={false}
        clearable={false}
        disabled={fetching}
      />
      <ReactTooltip
        id="only-avail"
        place="bottom"
        className="brand__tooltip"
        globalEventOff="click"
        type="light"
      />
    </div>
  );
};

export default memo(SelectItemAccess);
