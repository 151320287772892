import React from 'react';
import { Link, useParams } from 'react-router-dom';
import withApi from 'hoc/withApi';
import Content from 'components/layout/content';
import TableList from 'components/table/list';
import columns from './columns';

const OrdersIntegrate = () => {
  const { id } = useParams();

  return (
    <Content title={`Объединенный заказ №${id}`}>
      <div className="actions-container actions-container--order align-items-center">
        <div className="row event-actions">
          <div>
            <Link to="/orders" className="section-nav">
              <i className="icon icon-back-icon" /> К списку заказов
            </Link>
          </div>
        </div>
      </div>
      <div className="order-list">
        <TableList columns={columns} />
      </div>
    </Content>
  );
};

export default withApi(OrdersIntegrate);
