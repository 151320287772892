const RESERVES = {
  1: {
    icon: 'flash',
    className: 'flash-yellow',
    label: 'В заказе есть дефицитный товар',
  },
  2: {
    icon: 'rub',
    className: 'rub-green',
    label: 'В заказе есть товар, за отмену резерва которого будет начислен штраф',
  },
  3: {
    icon: 'flash',
    className: 'flash-yellow',
    label: 'В заказе есть дефицитный товар',
  },
  4: {
    icon: 'flash',
    className: 'flash-red',
    label: 'Просрочен резерв дефицитного товара',
  },
  5: {
    icon: 'rub',
    className: 'rub-red',
    label: 'Просрочена бронь товара с платной отменой резерва',
  },
  6: {
    icon: 'flash',
    className: 'flash-red',
    label: 'Просрочен резерв дефицитного товара',
  },
};
export function composeOrderStatuses(order = { reserve: '0' }) {
  const statuses = [];
  if (order.reserve === '0') return statuses;

  statuses.push(RESERVES[order.reserve]);

  return statuses.filter(Boolean);
}
