import React from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { RESULT_COLORS } from 'pages/Logistic/components/claims/constants';

export const AttachmentCell = row => {
  const {
    claims: { getAttachment },
  } = useDispatch();

  if (!row) return null;

  const attachments = get(row, 'value', []);

  return attachments.map((url, i) => (
    <button key={i} className="num-icon" onClick={async () => await getAttachment(url)}>
      {++i}
    </button>
  ));
};

export const ResultCell = row => {
  if (!row) return null;

  return <font color={RESULT_COLORS[row.original.resultType]}>{row.value}</font>;
};
