import { def, undef } from 'utils/new/types';

export function downloadFile({ blob, filename }) {
  if (def(window.navigator.msSaveBlob)) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const url = window.URL || window.webkitURL;
    const downloadUrl = url.createObjectURL(blob);

    if (filename) {
      const a = document.createElement('a');

      if (undef(a.download)) {
        window.location = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    } else {
      window.location = downloadUrl;
    }

    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
    }, 100);
  }
}
