import { compose } from 'redux';
import withApi from 'hoc/withApi';
import Events from 'pages/Marketing/components/events';
import withContextReducer from 'hoc/withContextReducer';
import eventsStateReducer, { initialEventsState } from 'pages/Marketing/duck/reducers';
import { EventsContext } from 'pages/Marketing/helpers/context';

export default compose(
  withApi,
  withContextReducer(eventsStateReducer, initialEventsState, EventsContext),
)(Events);
