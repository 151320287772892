export default {
  name: 'notifier',
  state:{
    successes: {},
    isActive: {}
  },
  reducers: {
    notify (state, id){
      state.isActive[id] = true
      delete state.successes[id]

      return state
    },
    resolve(state, {id, success}){
      state.successes[id] = success
      delete state.isActive[id]

      return state
    },
    dismiss(state, id){
      delete state.isActive[id]
      delete state.successes[id]

      return state
    }
  },
  selectors: (slice, createSelector, hasProps) => ({
    isNotifying: hasProps((model, id) =>
      slice(state => !!state.isActive[id])
    ),
    isSuccess: hasProps((model, id) =>
      slice(state => state.successes[id])
    )
  })
}
