import React from 'react';
import OrdersJoin from '../../orders-join';

const OrdersJoinButton = props => {
  const { row, index, isListView, shipDate, currentDate, isOrderPage, ...rest } = props;

  if (!isOrderPage && isListView && shipDate >= currentDate && +row.reserve !== 4) {
    return <OrdersJoin info={row} key={row.orderNo} index={index} {...rest} />;
  }
  return null;
};

export default OrdersJoinButton;
