import React, { Fragment, useEffect } from 'react';
import { Field } from 'redux-form';
import { RenderSelect } from 'components/fields';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAgentFormValue,
  optionsRentSelector,
  optionsShippingAgentCode,
} from 'pages/Logistic/selectors';
import SHIPPING_AGENT from 'pages/Logistic/constants/types-shiping-agent';
import { getEffectLoading } from 'common/selectors';

const AgentTKFields = () => {
  const type = useSelector(getAgentFormValue('type'));
  const branch = useSelector(getAgentFormValue('branch'));
  const rent = useSelector(getAgentFormValue('rent'));
  const agents = useSelector(state => state.agents);

  const {
    agents: { get },
  } = useDispatch();

  const optionsRent = useSelector(optionsRentSelector);
  const optionsShippingAgents = useSelector(optionsShippingAgentCode);

  const loading = useSelector(getEffectLoading('agents', 'get'));

  useEffect(() => {
    type === SHIPPING_AGENT.tk && branch && !agents.length && get();
  }, [type, agents, branch]);

  if (!(type && branch && type === SHIPPING_AGENT.tk)) {
    return null;
  }

  return (
    <Fragment>
      <div className="row">
        <Field
          name="rent"
          label="Скорость"
          options={optionsRent}
          placeholder="Выберите скорость"
          required
          disabled={loading}
          component={RenderSelect}
        />
      </div>
      {rent && (
        <div className="row">
          <Field
            name="shippingAgent"
            label="Код агента"
            options={optionsShippingAgents}
            placeholder="Выберите код агента"
            required
            disabled={loading}
            component={RenderSelect}
          />
        </div>
      )}
    </Fragment>
  );
};

export default AgentTKFields;
