import { useLocation } from 'react-router-dom';
import { QSToObj } from 'common/util';
import isEmpty from 'lodash/isEmpty';
import useLocalForage from 'hooks/use-local-forage';

/**
 * Hook to get value from location whenever there is
 * @template {any} T
 * @param {String} key - key to search value in location
 * @param {T} initial - default location value
 * @returns {T}
 */
const useLocationValue = (key, initial) => {
  const { state, search } = useLocation();
  const [localState] = useLocalForage('location.state', { [key]: initial });

  if (state?.[key]) {
    return state[key];
  }

  /**
   * @type {KeyStringObject}
   */
  const params = QSToObj(search);

  if (!isEmpty(params)) {
    return params[key];
  }

  return localState?.[key];
};

export default useLocationValue;
