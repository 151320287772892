import React, { useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Mixpanel from 'services/mixpanel';
import { Cookies } from 'services/cookies';
import { getEffectLoading } from 'common/selectors';
import { useNewLayout } from 'hooks/use-new-layout';
import { Switcher } from 'common/ui/switcher';

import styles from './switcher-version.module.scss';

export const SwitcherVersion = () => {
  const {
    personal: { switchDesign },
  } = useDispatch();

  const isNewLayout = useNewLayout();

  const loading = useSelector(getEffectLoading('personal', 'switchDesign'));

  const [checked, setChecked] = useState(false);

  const handleSwitchDesign = async newValue => {
    setChecked(newValue);

    try {
      const { success } = await switchDesign('v2');

      if (success) {
        Mixpanel.track('Версия', {
          version: newValue ? 'новая версия' : 'старая версия',
        });

        Cookies.set('ui_version', 'new');

        window.location.reload();
      } else {
        setChecked(!newValue);
      }
    } catch (err) {
      setChecked(!newValue);
      console.error(err);
    }
  };

  return (
    <div className={cn(styles['wrapper'], { [styles['wrapper-v2']]: isNewLayout })}>
      <span className={styles['label']}>Включить новую версию</span>
      <Switcher
        className={styles['switcher']}
        checked={checked}
        onChange={handleSwitchDesign}
        disabled={loading}
      />
    </div>
  );
};

