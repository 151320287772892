import { API_URL } from 'common/config/app';

export const homeApi = {
  'new-actions/?([a-zA-Z0-9]+)?$': {
    endpoint: `${API_URL}/v1/actions`,
  },
  'new-actions/accept/([a-zA-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/actions/index/accept`,
  },
  'new-actions/pull/([a-zA-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/actions/index/pull`,
  },
  'new-banners': {
    endpoint: `${API_URL}/v1/banners`,
  },
  'new-events/?([a-z]+)?$': {
    endpoint: `${API_URL}/v1/events`,
  },
  'new-news$': {
    endpoint: `${API_URL}/v1/news`,
  },
};
