import NewsList from 'pages/Marketing/components/news-list';
import Events from 'pages/Marketing/containers/events';
import ActionsList from 'pages/Marketing/components/actions-list';
import ActionsItem from 'pages/Marketing/components/actions-item';
import OrderEdit from 'pages/Orders/containers/order-edit';
import OrdersList from 'pages/Orders/components/orders-list';
import OrdersItem from 'pages/Orders/components/orders-item';
import ProductView from 'pages/Products/components/product-view';
import NoMatch from 'components/no-match';
import NewsItem from 'pages/Marketing/components/news-item';
import Queue from 'pages/Queue/components/queue';
import OrdersIntegrated from 'pages/Orders/components/orders-integrated';
import OrderMoveForm from 'pages/Orders/components/order-move-form';
import OrderSplitForm from 'pages/Orders/components/order-split-form';
import PriceLists from 'pages/Download/components/pricelists-list';
import Service from 'pages/Warranty/components/service';
import SerialCheck from 'pages/Warranty/components/serial';
import Contacts from 'pages/Help/components/contacts';
import Article from 'components/wiki/article';
import CertificatesList from 'pages/Certificates/components/certificates-list';
import LogisticShipments from 'pages/Logistic/components/shipments';
import StockInfo from 'pages/Logistic/components/stock-info';
import Claims from 'pages/Logistic/components/claims';
import ClaimsNew from 'pages/Logistic/components/claims-new';
import ClaimQueue from 'pages/Logistic/components/claims/components/claim-queue';
import Personal from 'pages/Settings/components/personal';
import Employees from 'pages/Settings/components/employees';
import ChangePassword from 'pages/Settings/components/password/change';
import RecoverPassword from 'pages/Settings/components/password/recover';
import Confirm from 'pages/Settings/components/confirm';
import OSV from 'pages/Finances/components/osv';
import Balance from 'pages/Finances/components/balance';
import Turnover from 'pages/Finances/components/turnover';
import Proxy from 'pages/Finances/components/proxy';
import Payments from 'pages/Finances/components/payments';
import Calendar from 'pages/Finances/components/calendar';
import Agreement from 'pages/Finances/components/agreement';
import Expired from 'pages/Finances/components/expired';
import Blocked from 'pages/Finances/components/blocked';
import Setup from 'pages/Settings/components/setup';
import Bindings from 'pages/Settings/components/bindings';
import LoadLinks from 'pages/Settings/components/load-links';
import Accounted from 'pages/Finances/components/accounted';
import AccountedView from 'pages/Finances/components/accounted/components/view';
import Attachments from 'pages/VAD/components/attachments';
import SpecialPricesForm from 'pages/VAD/components/special-prices/components/special-prices-form';
import SpecialPricesList from 'pages/VAD/components/special-prices/components/special-prices-list';
import LendingForm from 'pages/Finances/components/LendingForm/index';
import ShippingAgentForm from 'pages/Logistic/components/agents/components/form';
import ShippingAgentList from 'pages/Logistic/components/agents/components/list';
import SpecialPriceDetail from 'pages/VAD/components/special-prices/components/detail';
import FormTZ from 'pages/VAD/components/tz/form';
import TZList from 'pages/VAD/components/tz/list';
import TZDetail from 'pages/VAD/components/tz/detail';
import Purchase from 'pages/Finances/components/purchase';
import Demofond from 'pages/VAD/components/demofond';
import DemofondItem from 'pages/VAD/components/demofond/components/demofond-item';
import DemofondList from 'pages/VAD/components/demofond/components/DemofondList';
import NewsMain from 'pages/Marketing/components/NewsMain';
import { default as ServiceBalance } from 'pages/Warranty/components/service/components/balance';

import Debt from 'pages/Warranty/components/service/components/debt';
import Receive from 'pages/Warranty/components/service/components/receive';
import Delivery from 'pages/Warranty/components/service/components/delivery';
import Document from 'pages/Warranty/components/service/components/document';
import Documents from 'pages/Warranty/components/service/components/documents';
import withApi from 'hoc/withApi';
import Feedback from 'pages/Help/components/FormFeedback';
import Category from 'components/wiki/category';
import ShipmentItem from 'pages/Logistic/components/shipments/components/shipment-item';
import MultiBrandsList from 'pages/Iquote';
import Configurators from 'pages/Iquote/components/configurators';
import CreateIquote from 'pages/Iquote/components/create-iquote';
import { SignUp } from 'pages/new/settings/pages/sign-up';
import { CACHE_NAME, isCactus, isProdEnv } from '../config';

/**
 * Кривое разруливание корневого пути (с выносом скрытых подразделов). Т.к. дублирует /news + главное меню так же строится по этому конфигу
 * show: bool - показать/скрыть ссылку в гнлавном меню
 * perm: string - код прав на раздел. Дублирует бекэнд карту прав в классе app\models\User
 */

export const routes = [
  {
    path: '/',
    exact: true,
    component: NewsMain,
    name: 'Маркетинг',
    showBanner: true,
    routes: [
      {
        path: '/events',
        name: 'События',
        perm: 'MRK',
        component: Events,
        showBanner: true,
      },
      {
        path: '/news',
        name: 'Список новостей',
        component: NewsList,
        showBanner: true,
      },
      {
        path: '/actions',
        name: 'Акции',
        perm: 'ACT',
      },
    ],
  },
  {
    path: '/events',
    name: 'События',
    perm: 'MRK',
    show: false,
    showBanner: true,
    routes: [
      {
        path: '/events',
        component: Events,
        exact: true,
        show: false,
      },
      {
        path: '/events/archive',
        component: Events,
        show: false,
      },
      {
        path: '/events/invite',
        component: Events,
        show: false,
      },
    ],
  },
  {
    path: '/news',
    name: 'Список новостей',
    show: false,
    showBanner: true,
    routes: [
      {
        path: '/news',
        exact: true,
        name: 'Новости',
        component: NewsList,
        show: false,
      },
      {
        path: '/news/:id',
        name: 'Новость',
        component: NewsItem,
        show: false,
      },
    ],
  },
  {
    path: '/actions',
    name: 'Акции',
    show: false,
    perm: 'ACT',
    showBanner: true,
    routes: [
      {
        path: '/actions',
        exact: true,
        name: 'Акции',
        component: ActionsList,
        show: false,
      },
      {
        path: '/actions/archive',
        exact: true,
        name: 'Архивные акции',
        component: ActionsList,
        show: false,
      },
      {
        path: '/actions/:id',
        name: 'Акция',
        component: ActionsItem,
        show: false,
      },
    ],
  },
  {
    path: '/products/view/:id',
    name: 'Карточка товара',
    component: ProductView,
    show: false,
    showBalance: true,
  },
  {
    path: '/orders',
    name: 'Заказы',
    showBalance: true,
    perm: 'ZPR',
    routes: [
      {
        path: '/orders',
        name: 'Список заказов',
        exact: true,
        component: OrdersList, // Temporary replace OrderList!
        perm: 'ZPR',
        // showBanner: true,
      },
      {
        path: '/orders/new',
        name: 'Подбор товаров',
        component: OrderEdit,
        perm: 'ZPR',
        props: {
          noFooter: true,
          cache: CACHE_NAME,
        },
        state: {
          hidden: true,
          fromOrigin: 'menu',
        },
      },
      /**
       * #TODO: брать признаки и пути из таблицы!
       */
      {
        path: '/orders/edit/:id',
        name: 'Редактирование заказа',
        component: OrderEdit,
        show: false,
        props: {
          noFooter: true,
        },
      },
      {
        path: '/orders/integrate/:id',
        name: 'Объединенный заказ',
        component: OrdersIntegrated,
        show: false,
        perm: 'ZPR',
      },
      {
        path: '/orders/move/:id',
        name: 'Перемещение заказа',
        component: OrderMoveForm,
        show: false,
        perm: 'ZPR',
      },
      {
        path: '/orders/view/:id',
        name: 'Заказ',
        component: OrdersItem,
        show: false,
        perm: 'ZPR',
      },
      {
        path: '/orders/split/:id',
        name: 'Разбить заказ на дату',
        component: OrderSplitForm,
        show: false,
        perm: 'ZPR',
      },
      {
        path: '/orders/multibrands',
        component: MultiBrandsList,
        name: 'Список конфигураторов',
        show: false,
        perm: 'ZPR',
      },
      {
        path: '/orders/config',
        component: Configurators,
        name: 'Конфигураторы',
        perm: 'ZPR',
        props: { opinions: true },
      },
      {
        path: '/orders/iquote/:id',
        component: CreateIquote,
        name: 'Создать заказ из HPE iQuote',
        show: false,
      },
      {
        path: '/orders/iru',
        name: 'Конфигуратор IRU',
        component: false,
        perm: 'ZPR',
        old: true,
      },
      {
        path: '/preorders/new',
        name: 'Создать предзаказ',
        component: OrderEdit,
        perm: 'ZPR',
        state: {
          hidden: true,
        },
        props: {
          isPreOrder: true,
          noFooter: true,
        },
        new: true,
      },
    ],
  },
  {
    path: '/preorders',
    name: 'Предзаказы',
    showBalance: true,
    perm: 'ZPR',
    show: false,
    routes: [
      {
        path: '/preorders/new',
        name: 'Создать предзаказ',
        component: OrderEdit,
        perm: 'ZPR',
        state: {
          hidden: true,
        },
        props: {
          isPreOrder: true,
          noFooter: true,
        },
      },
      {
        path: '/preorders/edit/:id',
        name: 'Редактирование предзаказа',
        component: OrderEdit,
        show: false,
        props: {
          isPreOrder: true,
          noFooter: true,
        },
      },
      {
        path: '/preorders/view/:id',
        name: 'Предзаказ',
        component: OrdersItem,
        show: false,
        perm: 'ZPR',
        props: {
          isPreOrder: true,
        },
      },
    ],
  },
  {
    path: '/soft',
    name: 'Заказ ПО',
    perm: 'SOFT',
    routes: [
      {
        path: '/soft/keys',
        name: 'Электронные ключи',
        component: false,
        perm: 'SOFT',
        old: true,
      },
    ],
  },
  {
    path: '/logistic',
    name: 'Логистика',
    perm: 'LOG',
    routes: [
      {
        path: '/logistic/shipments/view/:id',
        component: ShipmentItem,
        perm: 'LOG',
        show: false,
        name: 'Просмотр отгрузки',
      },
      {
        path: '/logistic/shipments',
        component: LogisticShipments,
        perm: 'LOG',
        name: 'Отгрузки',
      },
      {
        path: '/logistic/claims/new',
        component: ClaimsNew,
        perm: 'LOG',
        show: false,
        name: 'Создание претензии',
      },
      {
        path: '/logistic/claims/:id',
        component: ClaimQueue,
        perm: 'LOG',
        show: false,
        name: 'Очередь создания претензий',
      },
      {
        path: '/logistic/claims',
        component: Claims,
        perm: 'LOG',
        name: 'Претензии',
      },
      {
        path: '/logistic/clause',
        component: false,
        perm: 'LOG',
        name: 'Нетоварные претензии',
        show: !isCactus,
        old: true,
      },
      {
        path: '/logistic/delivery',
        component: StockInfo,
        perm: 'LOG',
        name: 'Доставка',
      },
      {
        path: '/logistic/pickup',
        component: StockInfo,
        perm: 'LOG',
        name: 'Самовывоз',
      },
      {
        path: `/logistic/package`,
        component: withApi(Article),
        perm: 'LOG',
        name: 'Маркировка и упаковка',
      },
      {
        path: '/logistic/agents',
        component: ShippingAgentList,
        exact: true,
        name: 'Заявки на агентов по доставке',
      },
      {
        path: '/logistic/agents/new',
        component: ShippingAgentForm,
        name: 'Создать агента по доставке',
        show: false,
      },
    ],
  },
  {
    path: '/pricelists',
    name: 'Загрузка',
    perm: 'DNLD',
    routes: [
      {
        path: '/pricelists/search',
        name: 'Прайс-лист',
        component: PriceLists,
        perm: 'DNLD_PR',
      },
    ],
  },
  {
    path: '/warranty',
    name: 'Гарантия',
    perm: 'WARRANTY',
    routes: [
      {
        path: '/warranty/serial',
        name: 'Проверка серийных номеров',
        component: SerialCheck,
        perm: 'WARRANTY',
      },
      {
        path: '/warranty/rules',
        name: 'Правила гарантийного обслуживания',
        component: withApi(Article),
        perm: 'WARRANTY',
      },
      {
        path: '/warranty/service',
        name: 'Товар на обслуживании',
        component: Service,
        perm: 'WARRANTY',
        routes: [
          {
            path: '/warranty/service/debt',
            name: 'Товарный долг',
            exact: true,
            component: Debt,
            show: false,
            perm: 'WARRANTY',
          },
          {
            path: '/warranty/service/receive',
            name: 'Акты приема',
            exact: true,
            component: Receive,
            show: false,
            perm: 'WARRANTY',
          },
          {
            path: '/warranty/service/delivery',
            name: 'Акты выдачи',
            exact: true,
            component: Delivery,
            show: false,
            perm: 'WARRANTY',
          },
          {
            path: '/warranty/service/offset-balance',
            name: 'Зачет по браку',
            exact: true,
            component: ServiceBalance,
            show: false,
            perm: 'WARRANTY',
          },
          {
            path: '/warranty/service/view/:id',
            name: 'Просмотр документа',
            exact: true,
            component: Document,
            show: false,
            showInSub: false,
            perm: 'WARRANTY',
          },
          {
            path: '/warranty/service/:id',
            name: 'Просмотр документа',
            component: Documents,
            show: false,
            showInSub: false,
            perm: 'WARRANTY',
          },
        ],
      },
    ],
  },
  {
    path: '/certificates',
    name: 'Сертификаты',
    routes: [
      {
        path: '/certificates/list',
        name: 'Поиск сертификата',
        component: CertificatesList,
      },
    ],
  },
  {
    path: '/finances',
    name: 'Финансы',
    perm: 'FIN',
    showBalance: true,
    routes: [
      {
        path: '/finances/osv',
        name: 'О/С ведомость',
        component: OSV,
        perm: 'FIN_OSV',
      },
      {
        path: '/finances/balance',
        name: 'Баланс на дату',
        component: Balance,
        perm: 'FIN_BND',
      },
      {
        path: '/finances/payments',
        name: 'Графики платежей',
        component: Payments,
        perm: 'FIN_BND',
        show: !isCactus,
      },
      {
        path: '/finances/calendar',
        exact: true,
        name: 'Платежный календарь',
        component: Calendar,
        perm: 'FIN_BND',
        props: { opinions: true },
      },
      {
        path: '/finances/purchases',
        name: 'Отчет о закупках',
        component: Purchase,
        perm: 'FIN_BND',
        show: false,
      },
      {
        path: '/finances/accounted',
        name: 'Учтенные документы',
        component: Accounted,
        perm: 'FIN_UZ',
        exact: true,
      },
      {
        path: '/finances/agreement',
        name: 'Соглашение',
        component: Agreement,
        perm: 'FIN',
      },
      {
        path: '/finances/turnover',
        name: 'Оборот',
        component: Turnover,
        perm: 'FIN_REV',
      },
      {
        path: '/finances/expired',
        name: 'Документы с просроченным сроком возврата',
        component: Expired,
        perm: 'FIN',
      },
      {
        path: '/finances/proxy',
        name: 'Доверенности',
        component: Proxy,
        perm: 'FIN',
      },
      {
        path: '/finances/accounted/:id',
        name: 'Просмотр учтенного документа',
        component: AccountedView,
        perm: 'FIN_UZ',
        show: false,
      },
      {
        path: '/finances/blocked',
        name: 'Список блокирующих документов',
        component: Blocked,
        perm: 'FIN',
      },
      {
        path: '/finances/lending-form',
        name: 'Запрос кредитования',
        component: LendingForm,
        perm: 'FIN_KRED',
        new: true,
      },
    ],
  },
  {
    path: '/vad',
    name: 'Инструменты',
    perm: 'VAD',
    routes: [
      {
        path: '/category/55',
        component: Category,
        name: 'Направления',
        perm: 'VAD',
      },
      {
        path: '/vad/attachments',
        component: Attachments,
        name: 'Библиотека знаний',
        perm: 'WIKI',
      },
      {
        path: '/category/1',
        component: Category,
        name: 'Технический центр',
        perm: 'VAD',
      },
      {
        path: '/orders/config',
        component: Configurators,
        name: 'Конфигураторы',
        perm: 'ZPR',
        props: { opinions: true },
      },
      {
        path: '/vad/special-prices/form',
        component: SpecialPricesForm,
        name: 'Создать запрос на Спеццены',
        show: false,
      },
      {
        path: '/vad/special-prices/list',
        component: SpecialPricesList,
        name: 'Запросы Спеццен',
        perm: 'VAD_REQ',
      },
      {
        path: '/vad/special-prices/detail/:id',
        component: SpecialPriceDetail,
        name: 'Спец. цена подробный просмотр',
        show: false,
      },
      {
        path: '/vad/tz/form',
        component: FormTZ,
        name: 'Запрос ТЗ',
        perm: 'VAD',
        show: false,
      },
      {
        path: '/vad/tz/list',
        component: TZList,
        name: 'Запросы ТЗ',
        perm: 'VAD_REQ',
      },
      {
        path: '/vad/tz/detail/:id',
        component: TZDetail,
        name: 'Описание ТЗ',
        perm: 'VAD',
        show: false,
      },
      {
        path: '/vad/demofund',
        component: Demofond,
        name: 'Демофонд',
        perm: 'VAD',
      },
      {
        path: '/vad/demofond-detail/:id',
        component: DemofondItem,
        name: 'Демофонд заявка',
        show: false,
        perm: 'VAD',
      },
      {
        path: '/vad/demofond-list',
        component: DemofondList,
        name: 'Список заказов демофонд',
        show: false,
        perm: 'VAD',
      },
    ],
  },
  {
    path: '/category/:id',
    component: Category,
    name: 'Категория',
    show: false,
    perm: 'VAD',
  },
  {
    path: '/article/:id',
    component: withApi(Article),
    name: 'Статья',
    show: false,
    perm: 'VAD',
  },
  {
    path: '/settings',
    name: 'Настройки',
    routes: [
      {
        path: '/settings/personal',
        component: Personal,
        name: 'Личные данные',
      },
      {
        path: '/settings/setup',
        component: Setup,
        name: 'Настройки',
        show: false,
      },
      {
        path: '/settings/employees',
        component: Employees,
        name: 'Сотрудники',
        perm: 'ADM',
      },
      {
        path: '/settings/confirm',
        component: Confirm,
        name: 'Подтверждение e-mail',
        show: false,
        props: { auth: true },
      },
      {
        path: '/settings/employees/edit',
        component: Personal,
        name: 'Редактирование сотрудника',
        perm: 'ADM',
        show: false,
      },
      {
        path: '/settings/employees/add',
        component: Personal,
        name: 'Новый сотрудник',
        perm: 'ADM',
        show: false,
      },
      {
        path: '/settings/change-password',
        component: ChangePassword,
        name: 'Сменить пароль',
        show: false,
      },
      {
        path: '/settings/bindings',
        component: Bindings,
        name: 'Привязка товаров',
        exact: true,
      },
      {
        path: '/settings/bindings/load-links',
        component: LoadLinks,
        name: 'Загрузить привязки из файла',
        show: false,
      },
      {
        path: '/settings/bindings/load-price',
        component: LoadLinks,
        name: 'Загрузить прайс',
        show: false,
      },
    ],
  },
  {
    path: '/password/recover',
    component: RecoverPassword,
    name: 'Восстановление пароля',
    show: false,
    props: { auth: true },
  },
  {
    path: '/help',
    name: 'Помощь',
    routes: [
      {
        path: '/help/contacts',
        component: Contacts,
        name: 'Контакты',
      },
      {
        path: '/help/content',
        component: withApi(Category),
        name: 'Содержание',
      },
      {
        path: '/help/feedback',
        component: Feedback,
        name: 'Обратная связь',
      },
    ],
  },
  {
    path: '/sign-up',
    component: SignUp,
    name: 'Регистрация',
    show: false,
    props: {
      auth: true,
      isNewLayout: true,
    },
  },
  {
    path: '/queue',
    component: Queue,
    name: 'Очередь',
    exact: true,
    show: false,
  },
  {
    path: '/queue/:id',
    component: Queue,
    name: 'Очередь',
    show: false,
  },
  {
    component: NoMatch,
  },
];
