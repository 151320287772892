import { getSelectedBindings } from 'pages/Settings/helpers/util';
import alert from 'components/native/alert/function';
import { getSelectedCorrespondence } from 'pages/Settings/selectors';
import { batch } from 'react-redux';
import { download } from 'common/util';

export default {
  name: 'bindings',
  state: {
    goods: [],
    correspondence: [],
    filtered: [],
    type: 'all',
    choosed: {},
    linked: {},
    current: [],
    selected: {},
    showCurrent: false,
    fetchingRemove: {},
    pages: 0,
  },
  reducers: {
    load: (state, payload) => ({ ...state, ...payload }),
    addLinked: (state, { no, goods, append = false }) => {
      if (append) {
        const linked = state.linked[no] || [];
        state.linked[no] = [...linked, ...goods];
      } else {
        state.linked[no] = goods;
      }
      state.current = [...state.linked[no]]
      state.showCurrent = true

      return state;
    },
    setCurrent: (state, payload) => ({
      ...state,
      current: payload,
      showCurrent: true,
    }),
    filter: (state, { type, value }) => ({
      ...state,
      filtered: [...state.filtered.filter(elem => elem.id === type), { id: type, value }],
    }),
    choose: (state, payload) => ({
      ...state,
      choosed: payload,
    }),
    fetchRemove: (state, { index, flag }) => {
      state.fetchingRemove[index] = flag;

      return state;
    },
  },
  effects: dispatch => ({
    async saveCorrespondence(no, state) {
      const selected = getSelectedBindings();

      if (!selected.length) {
        alert('Не выбрана ни одна позиция!', { noFixedLayout: true });
        return false;
      }

      const goods = getSelectedCorrespondence(state)(selected);

      const { success, message } = await dispatch.api.post({
        url: '/settings/bindings/save',
        query: `?id=${no}`,
        data: selected,
      });

      if (success) {
        this.addLinked({ no, goods, append: true });
      }

      alert(message);
    },
    async chooseRow({ original }, { bindings }) {
      const { no } = original;

      this.choose({ ...original, remove: '' });

      if (!bindings.linked[no]) {
        const { goods } = await dispatch.api.get({
          url: '/settings/linked',
          query: `?no=${no}`,
        });

        batch(() => {
          dispatch.bindings.addLinked({ no, goods });
          dispatch.bindings.setCurrent(goods);
        });
      } else {
        this.setCurrent(bindings.linked[no]);
      }
    },
    async findItems(choosed) {
      const { correspondence = [] } =  await dispatch.api.post({
        url: '/settings/bindings/find',
        data: choosed,
      });

      this.load({ correspondence, showCurrent: false });
    },
    async removeLink({ no, row }, { bindings: { current } }) {
      const { original, index } = row;

      this.fetchRemove({ index, flag: true });
      const { success, message } = await dispatch.api.get({
        url: '/settings/linked/remove',
        query: `?id=${original.no}&cust_id=${no}`,
      });
      this.fetchRemove({ index, flag: false });
      alert(message, {
        noFixedLayout: true
      });

      if (success) {
        const curr = current.filter(link => link.no !== original.no);

        batch(() => {
          dispatch.bindings.load({ current: curr });
          dispatch.bindings.addLinked({ no, goods: curr });
        });
      }
    },
    clearML(_, { bindings: { filtered } }) {
      this.load({
        correspondence: [],
        filtered: filtered.length ? [] : [{ id: 'type', value: 'all' }],
        type: 'all',
      });
    },
    clearSearch(_, { bindings: { filtered } }) {
      dispatch.list.unblock();
      this.load({
        filtered: filtered.filter(elem => elem.id !== 'q'),
      });
    },
    switchType(e) {
      const type = e.target.value;
      dispatch.list.unblock();

      batch(() => {
        dispatch.bindings.filter({ type: 'type', value: type });
        dispatch.bindings.load({ type });
      });
    },
    search(val) {
      dispatch.list.unblock();
      this.filter({ type: 'q', value: val });
    },
    async searchByML(val) {
      if (!val) {
        return;
      }

      if (!/^[A-Z0-9\-_]+$/.test(val)) {
        alert('Введите заглавные латинские буквы, цифры, дефис или символ подчеркивания!');
        return;
      }

      await dispatch.api.get({
        url: `/settings/bindings/find-ml/${val}`,
      });

      this.load({
        choosed: {},
        showCurrent: false,
      });
    },
    async exportLinked() {
      const { blob, filename } = await dispatch.api.get({
        url: '/file/get/linked',
      });

      download(blob, filename);
    },
  }),
};
