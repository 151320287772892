// @ts-nocheck
import dateFnsIsAfter from 'date-fns/isAfter';
import dateFnsIsToday from 'date-fns/isToday';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import { convertDateString } from 'utils/new/date';
import { capitalize } from 'utils/new/strings';
import {
  formatCurrency,
  formatNumber,
  removeCurrencySymbol,
  toPrimalNumber,
} from 'utils/new/numbers/format-number';
import { changeOrders } from 'pages/new/orders/utils';
import { proxyArgsToMethods } from 'common/util';
import history from 'common/history';
import { composeGoodIcons } from 'pages/new/orders/pages/order/utils';
import * as effects from './effects';
import mergeGoods from './reducers/mergeGoods';
import deleteOrder from './reducers/deleteOrder';
import syncQueueEditRows from './reducers/syncQueueEditRows';

const initState = {
  goods: [{ default: true }],
  goodsFetching: false,
  goodsToFind: [],
  characteristics: [],
  orders: [],
  ordersCnt: 0,
  ordersFetching: {},
  ordersAdding: {},
  inList: true,
};

export default {
  name: 'newOrdersNew',
  state: initState,
  reducers: {
    setInList(state, payload) {
      return { ...state, inList: payload };
    },
    clearState: () => initState,
    clearGoods: state => {
      state.goods = [{ default: true }];

      return state;
    },
    changeRows: (state, { searchKey, needle, ...rest }) => {
      const searchNeedle = Array.isArray(needle) ? needle : [needle];

      for (const val of searchNeedle) {
        const index = state.goods.findIndex(row => row[searchKey] === val);
        state.goods[index] = { ...state.goods[index], ...rest };
      }

      return state;
    },
    deleteRows: (state, { searchKey, needle }) => {
      state.goods = state.goods.filter(row => {
        if (Array.isArray(needle)) {
          return needle.includes(row[searchKey]);
        }

        return row[searchKey] !== needle;
      });

      return state;
    },
    setGoods: (state, payload) => ({
      ...state,
      goods: payload.data.data || payload.data,
    }),
    mergeGoods,
    setGoodsFromOrdersSuccess: (state, payload) => {
      state.goods = payload;

      return state;
    },
    syncQueueEditRows,
    setOrders: (state, payload) => ({ ...state, orders: payload }),
    setOrder: (state, { data = {} }) => {
      const newState = {
        ...state,
        ...data,
        goods: data.data || data.goods || [],
      };

      if (data.order) {
        newState.orders = [...newState.orders, data.order];
      }

      return newState;
    },
    'orders/load': (state, { data = {} }) => ({ ...state, ...data }),
    'order/load': (state, { data = {} }) => ({
      ...state,
      goods: data.data || data.goods || [],
      orders: data.orders || [],
      ordersCnt: Number(data.ordersCnt || 0),
    }),
    deleteOrder,
    addOrder: (state, order) => {
      state.orders.push(order);

      return state;
    },
    replaceOrder: (state, { oldNo, newOrder }) => {
      const oldIndex = state.orders.findIndex(({ orderNo }) => orderNo === oldNo);
      state.orders[oldIndex] = newOrder;

      return state;
    },
    setLoadingOrders(state, { orderNo = 'NEW', isFetching = false, isItems = false }) {
      if (isItems) {
        state.ordersAdding[orderNo] = isFetching;
      } else {
        state.ordersFetching[orderNo] = isFetching;
      }

      return state;
    },
    updateOrders: (state, payload) => ({
      ...state,
      orders: changeOrders(state.orders, payload),
    }),
    setGoodsInOrdersCrumbs: (state, payload) => {
      state.goodsInOrdersCrumbs = payload;
      return state;
    },
  },
  effects(dispatch) {
    return {
      ...proxyArgsToMethods(effects, dispatch),
      async setHeader(order) {
        const state = history.location?.state || {};
        const { data: searchIds = [] } = state;

        await this.selectOrder({
          existedOrder: order,
          searchIds,
        });
      },
      expire() {
        alert('Ваш запрос обрабатывается. Обновите страницу через некоторое время.');
      },
    };
  },
  selectors: (slice, createSelector, hasProps) => ({
    getGoods() {
      return slice(state => {
        const goods = get(state, 'goods', [{ default: true }]);
        return goods.map(good => ({
          ...good,
          image: good.imageUrl,
          statuses: composeGoodIcons(good),
        }));
      });
    },
    getGoodsToFind() {
      return slice(state => get(state, 'goodsToFind', []));
    },
    getGoodsToFindIds({ newOrdersNew }) {
      return createSelector(newOrdersNew.getGoodsToFind, goods =>
        goods.map(item => item.LinkedItemNo),
      );
    },
    hasGoods({ newOrdersNew }) {
      return createSelector(newOrdersNew.getGoods, goods => goods.length > 0 && !goods[0].default);
    },
    getGoodsFetching() {
      return slice(state => get(state, 'goodsFetching', false));
    },
    getCharacteristics() {
      return slice(state => get(state, 'characteristics', []));
    },
    getOrders() {
      return slice(state => {
        const orders = get(state, 'orders', []);
        return map(orders, order => ({
          ...order,
          apiStatus: order.apiStatus || '',
          apiStatusText: order.apiStatusText || '',
          blankedOrderNo: typeof order.blankedOrderNo === 'string'
            ? order.blankedOrderNo.replace(/[\n\r\s\t]+/g, '')
            : '',
          dueDate:
            dateFnsIsToday(convertDateString(order.dueDate)) ||
            dateFnsIsAfter(convertDateString(order.dueDate), new Date())
              ? order.dueDate
              : '',

          price: formatCurrency(toPrimalNumber(removeCurrencySymbol(order.price)), {
            currency: 'USD',
          }),
          priceRUB: formatCurrency(toPrimalNumber(removeCurrencySymbol(order.priceRUB)), {
            currency: 'RUB',
          }),
          status: order?.status?.replace(/\-/gi, '') ? order.status : order.statusV2,
          volume: formatNumber(order.volume),
          weight: formatNumber(order.weight),
        }));
      });
    },
    getOrdersNos() {
      return slice(state => map(get(state, 'orders', []), order => order.orderNo));
    },
    isInList() {
      return slice(state => state.inList);
    },
    getOrder: hasProps((model, orderNo) =>
      slice(state => {
        const order = state.orders.find(order => order.orderNo === orderNo);

        if (order) {
          return {
            ...order,
            status: order.status === '-' || !order.status ? order.statusV2 : order.status,
          };
        }

        return null;
      }),
    ),
    getHasCharacteristics() {
      return slice(state => !isEmpty(get(state, 'characteristics', [])));
    },
    getDefaultShipmentMethod() {
      return createSelector(
        slice,
        rootState => get(rootState, ['user', 'orderSettings'], {}),
        (_, settings) => {
          const decodedValue = settings?.shipmentMethodRows
            ? JSON.parse(decodeURI(settings.shipmentMethodRows))
            : [];

          return decodedValue.find(method => +method['IsDefault']) || decodedValue[0] || {};
        },
      );
    },
    getDefaultShipmentDate() {
      return createSelector(
        slice,
        rootState => get(rootState, ['user', 'orderSettings'], {}),
        (_, settings) => settings?.shipmentDate,
      );
    },
    getFetchingOrders: hasProps((models, orderNo) => slice(state => state.ordersFetching[orderNo])),
    getAddingOrders: hasProps((models, orderNo) => slice(state => state.ordersAdding[orderNo])),
    getGoodsCategories() {
      return slice(state => {
        const goodsCategories = map(state.goods, good => ({
          id: good.id,
          value: good?.Code2 || good?.code2,
          label: capitalize((good?.Group2 || good?.group2)?.toLowerCase()),
          Code1: good?.Code1 || good?.code1,
          Code2: good?.Code2 || good?.code2,
          Code3: good?.Code3 || good?.code3,
          Group1: capitalize((good?.Group1 || good?.group1)?.toLowerCase()),
          Group2: capitalize((good?.Group2 || good?.group2)?.toLowerCase()),
          Group3: capitalize((good?.Group3 || good?.group3)?.toLowerCase()),
        }));

        const categories = [
          {
            id: 0,
            value: '',
            label: 'Товары в заказах из всех категорий',
          },
          ...goodsCategories,
        ];
        return uniqBy(categories, 'value');
      });
    },
  }),
};
