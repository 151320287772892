import isEmpty from 'lodash/isEmpty';
import { FILTER_BY_PROPERTIES } from '../constants';

export default formValues => filterGroups =>
  filterGroups.reduce((filters, group) => {
    const values = formValues[`group-${group.key}`];

    if (isEmpty(values)) {
      return filters;
    }

    if (group.type === FILTER_BY_PROPERTIES) {
      const filterValues = Object.keys(values).filter(val => values[val]);

      return filterValues.length ? { ...filters, [group.key]: filterValues } : filters;
    }

    return { ...filters, ...values };
  }, {});
