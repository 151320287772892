/* eslint-disable no-undef */
import { isDevEnv } from 'common/config';
import alert from '../native/alert/function';
import isEmpty from "lodash/isEmpty"
import cache from "services/cache"
import history from "common/history"
import { APPROVAL_MESSAGE } from 'pages/Settings/utils/handle-prompt';

export const checkNewVersion = version => {
  const isNewVersion = !isDevEnv && version && version !== __webpack_hash__;

  return isNewVersion
    ? alert('Вышла новая версия сайта. Необходимо обновиться для корректной работы.', {
        confirmLabel: 'Обновить',
      }).then(() => cache.clear())
        .then(() => window.location.reload())
    : null;
};

export const checkConfirmation = (props, prevConfirmed) => {
  const {
    user,
    confirmEmail,
    isAuth,
    location,
    confirmed: currentConfirmed,
  } = props;

  if (!isAuth || isEmpty(user)) {
    return null;
  }

  const { state, pathname } = location;
  const isPathSettings = pathname.includes('/settings/confirm');

  if (isPathSettings) {
    return null;
  }

  const { EMail, login } = user

  const confirmation = email => `На Ваш адрес <b>${email}</b>
было отправлено письмо<br />
Необходимо перейти по ссылке из письма
для подтверждения адреса электронной почты
`;

  const confirmationNoEmail =
    'Для Вашего логина не привязан e-mail. Пожалуйста, введите Ваш e-mail на странице подтверждения личных данных.';

  const confirmationEmail = currentConfirmed?.email || EMail;

  const needSendConfirmation = currentConfirmed?.exist === false;
  const needNotifyConfirmation =
    (currentConfirmed?.exist && (!prevConfirmed || prevConfirmed.exist !== false)) ||
    !confirmationEmail;

  const emailConfirmation = email => (email ? confirmation(email) : confirmationNoEmail);

  return currentConfirmed === false
    ? state?.needConfirm || history.push('/settings/personal', { needConfirm: true })
    : (needNotifyConfirmation &&
        alert(emailConfirmation(confirmationEmail)).then(() =>
          confirmationEmail ? false : history.push('/settings/personal'),
        )) ||
        (needSendConfirmation &&
          confirmEmail({
            email: confirmationEmail,
            login,
            confirmation: confirmation(confirmationEmail),
          }));
};

export const checkApproval = ({ isAuth, received }) => {
  if (!isAuth) {
    return null;
  }

  if (!received) {
    history.push('/settings/personal');

    alert(APPROVAL_MESSAGE);    
  }
};
