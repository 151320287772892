import React, { useRef } from 'react';
import ReactDom from 'react-dom';
import useUpdateElementPosition from 'hooks/use-update-element-position';

function ModalWrapper({ children, rootRef, ...props }) {
  return (
    <div ref={rootRef} {...props}>
      {children}
    </div>
  );
}

function Modal(props) {
  const { isOpen, position, children, className } = props;

  if (!isOpen) return null;

  const movingRef = useRef(); // eslint-disable-line
  useUpdateElementPosition({
    // eslint-disable-line
    movingRef,
    relativeRef: position.relativeRef,
    compensationTop: position.compensationTop,
    compensationLeft: position.compensationLeft,
  });

  return ReactDom.createPortal(
    <ModalWrapper rootRef={movingRef} className={className}>
      {children}
    </ModalWrapper>,
    document.body,
  );
}

export default Modal;
