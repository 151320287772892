import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, reset, change, submit } from 'redux-form';
import PropTypes from 'prop-types';
import { isShallowEqual } from 'common/util';
import { SIMPLIFIED_COLUMNS } from 'pages/Orders/components/order-edit/helpers/simplifiedColumns';

const renderCheckbox = ({ input, label }) => {
  return (
    <div>
      <label>
        <input {...input} type="checkbox" checked={input.value} />
        {label}
      </label>
    </div>
  );
}

class ColumnSwitcher extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !isShallowEqual(this.state, nextState) || !isShallowEqual(this.props, nextProps);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(reset('columnsForm'));
  }

  saveChangesColumns = values => {
    const { dispatch, model, save, initial, saved } = this.props;
    const isChanged = !isShallowEqual(values, initial) || !saved;

    isChanged && dispatch[model]?.toggle(values);
    save(values, isChanged);
  };

  toggleAllFields = isShow => {
    const { initial, dispatch } = this.props;

    Object.keys(initial).forEach(columnName => {
      dispatch(change('columnsForm', columnName, isShow));
    });
  };

  render() {
    const { columns, initial, dispatch } = this.props;
    const values = initial.values || initial

    const Form = properties => {
      const { handleSubmit, submitting } = properties;

      return (
        <form onSubmit={handleSubmit(this.saveChangesColumns)}>
          <div>
            <div className="row order-columns-actions">
              <div className="row">
                <a href="#" onClick={() => this.toggleAllFields(true)}>
                  Показать все
                </a>
                <a href="#" onClick={() => this.toggleAllFields(false)}>
                  Скрыть все
                </a>
              </div>
              <div className="text-right">
                <button
                  type="submit"
                  className="btn btn-outline-danger btn--rect"
                  disabled={submitting}
                >
                  Сохранить вид таблицы
                </button>
                <i
                  className="icon icon-close12"
                  title="Закрыть"
                  onClick={() => {
                    dispatch(submit('columnsForm'));
                  }}
                />
              </div>
            </div>
            <div className="row order-columns">
              {columns.map(column => {
                if (values.hasOwnProperty(column.id))
                  return (
                    <Field
                      key={column.id}
                      name={column.id}
                      label={typeof column.Header === 'function' ? column.Header() : column.Header}
                      component={renderCheckbox}
                      normalize={value => !!value}
                    />
                  );
              })}
            </div>
          </div>
        </form>
      );
    };

    const ColumnVisForm = reduxForm({
      form: 'columnsForm',
      initialValues: initial,
      onSubmit: this.saveChangesColumns,
      destroyOnUnmount: false,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(Form);

    return <ColumnVisForm />;
  }
}

ColumnSwitcher.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  save: PropTypes.func.isRequired,
  model: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { toggleType } = ownProps;
  const toggleAll = (columns, vis) => () => {
    let newColumns = {};

    Object.keys(columns).forEach(key => {
      newColumns = { ...newColumns, [key]: vis };
    });

    return dispatch({ type: toggleType, payload: { ...newColumns, simplified: false } });
  };

  const toggleColumn = (evt, newValue) =>
    dispatch({ type: toggleType, payload: { [evt.target.name]: newValue } });

  const toggleSimplified = () =>
    dispatch({ type: toggleType, payload: { ...SIMPLIFIED_COLUMNS, simplified: true } });

  return {
    toggleAll,
    toggleColumn,
    toggleSimplified,
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(ColumnSwitcher);
