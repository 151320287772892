import { isEmpty } from 'lodash';
import { claimTypes, claimTypesNeedFiles } from 'pages/Logistic/components/claims-new/constants';
import { filesValidators } from './validateFeedBack';

const requiredField = 'Заполните поле';
const claimTypesNeedFilesValues = Object.values(claimTypesNeedFiles);

const validateClaim = values => {
  const errors = {};

  if (!values.uzNo) {
    errors.uzNo = requiredField;
  }

  if (!values.recDate) {
    errors.recDate = requiredField;
  }

  if (values.receivedBags > 0 && !values.sealNumbers) {
    errors.sealNumbers = requiredField;
  }

  if (!isEmpty(values.products)) {
    const resultProducts = [];

    values.products.forEach((product, index) => {
      const resultProduct = {};
      const { itemId, factQty, claimType, file } = product;

      if (!itemId) {
        resultProduct.itemId = requiredField;
        resultProducts[index] = resultProduct;
      }

      if (factQty && !claimType) {
        resultProduct.claimType = requiredField;
        resultProducts[index] = resultProduct;
      }

      if (claimType && !factQty) {
        resultProduct.factQty = requiredField;
        resultProducts[index] = resultProduct;
      }

      if (
        claimType &&
        !(
          claimType.value === claimTypes['Пересорт'] || claimType.value === claimTypes['Недогруз']
        ) &&
        +factQty === 0
      ) {
        resultProduct.factQty = 'Количество должно быть больше нуля';
        resultProducts[index] = resultProduct;
      }
      if (claimType && claimTypesNeedFilesValues.includes(claimType.value) && !file) {
        resultProduct.claimType = 'Приложите файл';
        resultProducts[index] = resultProduct;
      }

      if (file) {
        resultProduct.file = filesValidators(file);
        resultProducts[index] = resultProduct;
      }

      const hasProduct = values.products.some(product => product.factQty);
      if (!hasProduct) {
        resultProduct.factQty = 'Выберите товар для претензии';
        resultProducts[index] = resultProduct;
      }

      return resultProducts;
    });

    if (resultProducts.length) {
      errors.products = resultProducts;
    }
  }

  return errors;
};

export default validateClaim;
