import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Async } from 'react-select';

const FilterGroup = ({ setFilter, style }) => {
  const [group, setGroup] = useState('');

  const {
    wiki: { getAttachmentsGroups },
  } = useDispatch();

  const handleChange = newValue => {
    setGroup(newValue);
    setFilter('group', newValue);
  };

  const loadOptions = (input, callback) => {
    getAttachmentsGroups()
      .then(options => {
        console.log(options);
        callback(null, { options });
      })
      .catch(err => callback(err, null));
  };

  return (
    <Async
      name="filterGroup"
      id="filter-group"
      placeholder="Выберите направление"
      noResultsText="Выберите направление"
      loadingPlaceholder="Загружаем направление"
      loadOptions={loadOptions}
      searchable={false}
      onChange={handleChange}
      value={group}
      style={style}
    />
  );
};

export default FilterGroup;
