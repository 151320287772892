import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Clear = ({
  className,
  color = '#000',
  name,
  width = '14',
  height = '14',
  viewBox = '0 0 14 14',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M1 1L13 13" stroke={color} strokeLinecap="round" />
    <path d="M13 1L1 13" stroke={color} strokeLinecap="round" />
  </svg>
);

Clear.defaultProps = {
  className: '',
  color: '#000',
  width: '14',
  height: '14',
  viewBox: '0 0 14 14',
};

Clear.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
