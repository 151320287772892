import React, { memo } from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { useSelector, useStore } from 'react-redux';
import validate from 'common/validates/validateNewPassword';
import Content from 'components/layout/content';
import { QSToObj } from 'common/util';
import PasswordForm from 'pages/Settings/components/password/components/form';
import history from 'common/history';
import withApi from 'hoc/withApi';

const FORM_DATA = {
  firstField: {
    name: 'newPass',
    label: 'Пароль',
  },
  secondField: {
    name: 'confirmPass',
    label: 'Подтвердите пароль',
  },
  btnName: 'Изменить',
};

const RecoverPassword = memo(props => {
  const {
    select: { personal },
  } = useStore();

  const { login, client } = useSelector(personal.getInvitedUser);

  return (
    <Content title={`Смена пароля ${login} (${client})`}>
      <PasswordForm formData={FORM_DATA} {...props} />
    </Content>
  );
});

export default compose(
  withApi,
  reduxForm({
    form: 'recoverPasswordForm',
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
    onSubmit: async (data, { personal: { recoverPass } }, { location: { search } }) => {
      const queryObj = QSToObj(search);
      const isNew = await recoverPass({ ...data, ...queryObj });
      history.push(isNew ? '/settings/personal' : '/', {
        needConfirm: !!isNew,
      });
    },
  }),
)(RecoverPassword);
