// @ts-nocheck
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { checkDate, disabledSameIds, undef } from 'common/util';
import useContextSelector from 'hooks/context/useContextSelector';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { ItemsTableContext } from '../../../../containers/items-table';
import { addSelectedIndexRows, deleteSelectedIndexRows } from '../../../../action';
import { regExoNumberWithDot } from '../../../../constances/regexpes';

const EditableField = props => {
  const {
    row: {
      value,
      column,
      original: { id, status, lineNo, index },
      row,
    },
    blur,
    actionType,
  } = props;

  const state = useContextSelector(ItemsTableContext, state => state);
  const dispatch = useContextDispatch(ItemsTableContext);
  const { selectedIndexRows } = state;

  const stateId = index || id;

  const prevValue = useRef(value);

  const disabled = useMemo(
    () =>
      (index && selectedIndexRows && disabledSameIds(selectedIndexRows, id, index)) ||
      checkDate(row.actualyDateFrom, row.actualyDateTo),
    [selectedIndexRows, index, id],
  ); // eslint-disable-line

  const [curValue, setCurValue] = useState(value);

  /**
   * fill items state on row mount/change
   */
  useEffect(() => {
    const val =
      (status === 'Err' && prevValue.current) ||
      (undef(status) &&
        (prevValue.current !== value || (state[stateId] && state[stateId][column.id] != value)) &&
        value) ||
      (status === 'Ok' && value) ||
      (state[stateId] && state[stateId][column.id]) ||
      value;

    dispatch({
      type: actionType,
      payload: {
        id: stateId,
        value: val,
      },
    });

    setCurValue(val);

    prevValue.current = val;
  }, [value, status]); // eslint-disable-line

  useEffect(() => {
    state[stateId] && setCurValue(state[stateId][column.id]);
  }, [state[stateId]]); // eslint-disable-line

  return (
    <input
      data-line={lineNo}
      disabled={disabled}
      type="text"
      value={curValue}
      className={['form-control', 'form-control-sm', 'text-right', props.className || ''].join(' ')}
      autoComplete="off"
      onFocus={e => {
        e.persist();
        const { value } = e.target;

        if (!+value) {
          e.target.value = '';
        }
      }}
      onBlur={e => {
        const { value } = e.target;

        if (!value.length) {
          setCurValue((state[stateId] && state[stateId][column.id]) || value);
        }

        const val = parseInt(value);

        if (index) {
          if (val) {
            dispatch(addSelectedIndexRows({ [id]: index }));
          } else {
            selectedIndexRows && selectedIndexRows[id] && dispatch(deleteSelectedIndexRows(id));
          }
        }

        blur(state, dispatch)(e);
      }}
      onChange={e => {
        const { value } = e.target;

        if (regExoNumberWithDot.test(value)) {
          setCurValue(value);
          value.length &&
            dispatch({
              type: actionType,
              payload: {
                id: stateId,
                value: value.replace(/,/, '.'),
              },
            });
        }
      }}
    />
  );
};

export default EditableField;
