import { useDispatch, useSelector } from 'react-redux';
import { getFormValue, getOrder } from 'pages/Orders/selectors';
import React, { useEffect, useState } from 'react';
import { getEffectLoading } from 'common/selectors';

import isEmpty from 'lodash/isEmpty';
import { useDesignSelect } from 'hooks/new';

import DefaultField from '../components/defaultField';

const RepresentativeField = ({ input: { onChange, name }, label, className, arrowRenderer }) => {
  const {
    order: { getRepresentatives, initForm },
  } = useDispatch();

  const { representative } = useSelector(getOrder);
  const loading = useSelector(getEffectLoading('order', 'getRepresentatives'));

  const shipmentData = useSelector(getFormValue('shipmentDate'));
  const contractor = useSelector(getFormValue('contractorCode')) || '';

  const [contractorCode, contractId] = contractor?.split('|') || [];

  const [value, setValue] = useState(representative);
  const [options, setOptions] = useState([]);

  const Select = useDesignSelect();

  useEffect(() => {
    shipmentData &&
      getRepresentatives({ shipmentData, contractorCode, contractId }).then(res => {
        const firstRepresentative = res[0] || representative;

        setOptions(res.map(representative => ({ value: representative, label: representative })));
        setValue(firstRepresentative);
        initForm({ [name]: firstRepresentative });
      });
  }, [shipmentData, contractorCode, contractId, representative]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    (!loading && isEmpty(options) && (
      <DefaultField title={label} fieldName={name} placeholder="Нет доступных представителей" />
    )) || (
      <div>
        <label>{label}</label>
        <Select
          name={name}
          value={value}
          options={options}
          placeholder="Не выбран"
          isLoading={loading}
          onChange={({ value }) => {
            setValue(value);
            onChange(value);
          }}
          searchable={false}
          clearable={false}
          className={className}
          arrowRenderer={arrowRenderer}
        />
      </div>
    )
  );
};

export default RepresentativeField;
