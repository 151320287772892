import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { getEffectLoading } from 'common/selectors';
import notifier from 'services/new/notifier';
import alert from 'components/native/alert/function';
import {
  formatCurrency,
  removeCurrencySymbol,
  toPrimalNumber,
  changePeriodToComma,
} from 'utils/new/numbers/format-number';
import { chooseQueueMessage } from 'features/order-actions/utils';
import { convertDateString } from 'utils/new/date';
import { DEFAULT_OPTIONS_ORDER_CONTACTS } from '../constants';
import { changeOrders, composeOrderStatuses, formatDataOrderList } from '../utils';
import { initFilters } from '../utils/set-initial-filters';

const defaultOrdersOptions = {
  filters: initFilters,
  pageSize: 10000,
  page: 1,
  search: '',
  mode: 1,
};

export default {
  name: 'newOrders',
  state: {
    filters: initFilters,
    search: '',
    orders: [],
    pageCount: 0,
    possibleFilters: null,
    ordersShipment: [],
    orderShipmentSubscription: [],
    ordersApiStatus: {},
    contacts: [],
  },
  reducers: {
    fetchOrdersSuccess: (state, action) => {
      const page = get(action, ['data', 'p'], 1);
      const orders = get(action, ['data', 'data'], []);

      state.orders = uniqBy(page === 1 ? orders : state.orders.concat(orders), 'orderNo');

      state.pageCount = get(action, ['data', 'pages'], 0);
      state.possibleFilters = get(action, ['data', 'possibleFilters'], null);

      return state;
    },
    fetchOrdersShipmentSuccess: (state, action) => {
      state.ordersShipment = get(action, 'payload', []);

      return state;
    },
    fetchOrderShipmentSuccess: (state, action) => {
      state.orderShipmentSubscription = get(action, ['data', 'subscriptions'], []);

      return state;
    },
    setOrders: (state, payload) => ({ ...state, orders: payload }),
    updateOrders: (state, payload) => {
      state.orders = changeOrders(state.orders, payload);
      return state;
    },
    fetchContactsSuccess: (state, payload) => {
      state.contacts = uniq(get(payload, 'data', []));
      return state;
    },
    setFilters: (state, payload) => ({ ...state, filters: payload }),
    setSearch: (state, payload) => ({ ...state, search: payload }),
  },
  effects(dispatch) {
    const { api, ...models } = dispatch;
    const { newOrderActions, items } = models;

    return {
      async fetchOrders(options = {}) {
        try {
          const actualOptions = { ...defaultOrdersOptions, ...options };

          const { filters, mode, pageSize, page, search } = actualOptions;
          const data = formatDataOrderList(filters);

          const result = await api.post({
            url: 'new-orders/list',
            data: { ...data, mode, pageSize, p: page, search },
          });
          this.fetchOrdersSuccess(result);

          return result;
        } catch (err) {
          return [];
        }
      },
      async fetchShipmentSubscription() {
        try {
          const result = await api.get({
            url: 'new-orders/shipment-subscription',
          });
          this.fetchOrderShipmentSuccess(result);
        } catch (err) {
          return null;
        }
      },
      async sendShipmentSubscription(payload) {
        return await api.post({
          url: 'new-orders/shipment-subscription/update',
          data: {
            agents: payload,
          },
        });
      },
      async receive(message, { newOrders: { orders }, newOrderActions: { jobType } }) {
        const { fix } = message.data;

        const socketMessage = chooseQueueMessage(message);

        if (fix?.channel === 'queue') {
          if (fix.done) {
            this.updateOrders(message);
            newOrderActions.setJobId('');

            if (fix?.payload?.command === 'del') {
              this.setOrders(orders.filter(order => order.orderNo !== fix.id));
            }

            notifier.resolve(
              {
                success: socketMessage.success,
                message: socketMessage.text,
              },
              socketMessage.id,
            );
          }
        }

        if (fix?.channel === 'queue-order') {
          if (fix.done) {
            newOrderActions.setJobId('');
            notifier.resolve(
              {
                success: socketMessage.success,
                message: socketMessage.text,
              },
              socketMessage.id,
            );

            if (jobType === 'product-from-excel') {
              this.updateOrders(message);
              newOrderActions.setJobId('');
              newOrderActions.setJobType('');
            }

            if (jobType === 'product-to-excel' && !fix.error && fix?.payload?.file) {
              await items.getFile(fix.payload.file);
              newOrderActions.setJobId('');
              newOrderActions.setJobType('');
            }
          }
        }
      },
      expire() {
        alert('Ваш запрос обрабатывается. Обновите страницу через некоторое время.');
      },
      async fetchContacts() {
        try {
          return await api.get({
            url: 'new-orders/contacts',
          });
        } catch (err) {
          return Promise.reject(err);
        }
      },
    };
  },
  selectors: slice => ({
    getFilters() {
      return slice(state => get(state, 'filters', {}));
    },
    getOrders() {
      return slice(state => {
        const orders = get(state, 'orders', []);
        return orders.map(order => ({
          ...order,
          orderNoLink: order.orderNo,
          items: order.items
            ? order.items.map(orderItem => ({
                ...orderItem,
                amount: formatCurrency(toPrimalNumber(orderItem.amount)),
              }))
            : order.items,
          orderDate: convertDateString(order.orderDate),
          price: toPrimalNumber(removeCurrencySymbol(order.price)),
          priceRUB: changePeriodToComma(removeCurrencySymbol(order.priceRUB)),
          shipmentDate: convertDateString(order.shipmentDate),
          // shippingAddress: order.shippingAddress || 'Адрес не указан',
          // shipmentMethod: order.shipmentMethod || 'Метод отгрузки не указан',
          statuses: composeOrderStatuses(order),
        }));
      });
    },
    getAreOrdersFetching() {
      return getEffectLoading('newOrders', 'fetchOrders');
    },
    getContacts() {
      return slice(state => {
        const contacts = get(state, 'contacts', []);
        const formattedContacts = contacts.map(contact => ({
          id: contact,
          value: contact,
          label: contact,
        }));

        return [DEFAULT_OPTIONS_ORDER_CONTACTS, ...formattedContacts];
      });
    },
    getPageCount() {
      return slice(state => get(state, 'pageCount', 0));
    },
    getHasOrders() {
      return slice(state => !isEmpty(get(state, 'orders', false)));
    },
    getOrdersShipment() {
      return slice(state => get(state, 'ordersShipment', []));
    },
    getOrderShipmentSubscription() {
      return slice(state => get(state, 'orderShipmentSubscription', []));
    },
  }),
};
