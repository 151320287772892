import React from 'react';
import DisabledOrderShipmentsButton from './DisabledOrderShipmentsButton';
import DisabledMigrateIcon from './DisabledMigrateIcon';
import DisabledExcelIcon from './DisabledExcelIcon';
import DisabledSignatureButton from './DisabledSignatureButton';
import { isShallowEqual } from 'common/util';
import OrdersJoinButton from './OrdersJoinButton';

const DisabledButtons = React.memo(props => {
  return (
    <React.Fragment>
      <DisabledOrderShipmentsButton {...props} />
      <i className="icon icon-edit_new-dis" />
      <DisabledMigrateIcon {...props} />
      <i className="icon icon-delorder-dis" />
      <DisabledExcelIcon {...props} />
      <DisabledSignatureButton {...props} />
      <OrdersJoinButton {...props} />
    </React.Fragment>
  );
}, isShallowEqual);

export default DisabledButtons;
