import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import validate from 'common/validates/validateLinkedFile';
import { renderFile } from 'components/fields';
import { LoadingSubmitButton } from 'components/loading-sumbit-button';

const LoadLinkedForm = props => {
  const { handleSubmit, error, submitting, onSubmit } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <div className="link-loaded-form">
        <Field name="linked" placeholder="Выберите файл" label="Обзор" component={renderFile} />
        <LoadingSubmitButton
          label="Загрузить"
          fetching={submitting}
          btnClass="btn btn-danger btn--rect btn-sm"
        />
      </div>
      {error && (
        <div className="text-center">
          <strong className="text-danger text-danger--small">{error}</strong>
        </div>
      )}
    </form>
  );
};

LoadLinkedForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default reduxForm({
  form: 'loadLinkedForm',
  validate,
})(LoadLinkedForm);
