const allNonDotAndDigitsRegex = /[^0-9.-]+/g;

// '$1,100.00' → 1100.00
const priceStringToNumber = priceString => {
  const onlyDigitsAndDotString = priceString.replace(allNonDotAndDigitsRegex, '');
  const parsedNumber = parseFloat(onlyDigitsAndDotString);
  return Number.isNaN(parsedNumber) ? 0 : parsedNumber;
};

const sortStringNumbers = (prevPrice, nextPrice) => {
  const prevPriceStr = `${prevPrice}`;
  const nextPriceStr = `${nextPrice}`;
  const prevPriceNum = priceStringToNumber(prevPriceStr);
  const nextPriceNum = priceStringToNumber(nextPriceStr);

  return prevPriceNum - nextPriceNum;
};

export default sortStringNumbers;
