// @ts-nocheck
import { MORE, LESS, INCLUDES, NOT_INCLUDES } from '../constants/operators';

export const setChecked = (valueParameter, valueOperator, searchText) => {
  switch (valueOperator) {
    case MORE:
      return Number(parseFloat(searchText)) < Number(valueParameter);
    case LESS:
      return Number(parseFloat(searchText)) > Number(valueParameter);
    case INCLUDES:
      return valueParameter.toLowerCase().includes(searchText.toString().toLowerCase());
    case NOT_INCLUDES:
      return !valueParameter.toLowerCase().includes(searchText.toString().toLowerCase());
    default:
      return false;
  }
};
