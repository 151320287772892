import React from 'react';
import { useParams } from 'react-router-dom';
import { hasAccess } from 'common/util';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'pages/Orders/selectors';
import { getEffectLoading } from 'common/selectors';

const OrderAutoSign = ({ order }) => {
  const user = useSelector(getUser);
  const fetching = useSelector(getEffectLoading('order', 'handleSign'));
  const { id } = useParams();
  const {
    order: { handleSign },
  } = useDispatch();

  if (Number(order.docType) !== 1) {
    return null;
  }

  const hasPrice = parseInt(order.price) || parseInt(order.priceRUB);

  return hasAccess(user, 'ZPR_SIGN') ? (
    <button
      className="btn btn-outline-danger btn--rect"
      disabled={!hasPrice || fetching}
      onClick={async () => await handleSign({ id, auto: true })}
    >
      Подписать на отгрузку
    </button>
  ) : null;
};

export default OrderAutoSign;
