import React from 'react';
import { compose } from 'redux';

const createMailto = params => {
  const { to, copy } = params;
  if (!to) return '';
  const mailto = `mailto:${to}`;
  if (!copy) return mailto;
  return `${mailto}?cc=${copy}`;
};

export const filterEmptyContactSections = contactSections =>
  contactSections.filter(contactSection =>
    contactSection.children.find(
      contact => typeof contact.description === 'string' && contact.description.trim().length > 0,
    ),
  );

export const decorateContacts = contacts => [
  // При нажатии на E-Mail в блоке «Ваш менеджер»:
  // Адресаты: e-mail менеджера;
  // В копии: e-mail замещающего менеджера.
  {
    id: 'manager',
    children: [
      {
        id: 'manager',
        label: 'Ваш менеджер',
        description: contacts.name,
      },
      {
        id: 'phone',
        label: 'Телефон',
        description: contacts.phone,
      },
      {
        id: 'email',
        label: 'E-mail',
        description: (
          <a
            href={createMailto({
              to: contacts.email,
              copy: contacts.RMMail,
            })}
          >
            {contacts.email}
          </a>
        ),
      },
    ],
  },

  // При нажатии на E-Mail в блоке «Руководитель»:
  // Адресаты: e-mail руководителя;
  // В копии: e-mail менеджера.
  {
    id: 'chief',
    children: [
      {
        id: 'chief',
        label: 'Руководитель',
        description: contacts.chiefName,
      },
      {
        id: 'phone',
        label: 'Телефон',
        description: contacts.chiefPhone,
      },
      {
        id: 'email',
        label: 'E-mail',
        description: (
          <a
            href={createMailto({
              to: contacts.chiefEmail,
              copy: contacts.email,
            })}
          >
            {contacts.chiefEmail}
          </a>
        ),
      },
    ],
  },

  // При нажатии на E-Mail в блоке «Замещающий менеджер»:
  // Адресаты: e-mail замещающего менеджера;
  // В копии: e-mail менеджера.
  {
    id: 'RM',
    children: [
      {
        id: 'chief',
        label: 'Замещающий менеджер',
        description: contacts.RMName,
      },
      {
        id: 'phone',
        label: 'Телефон',
        description: contacts.RMPhone,
      },
      {
        id: 'email',
        label: 'E-mail',
        description: (
          <a
            href={createMailto({
              to: contacts.RMMail,
              copy: contacts.email,
            })}
          >
            {contacts.RMMail}
          </a>
        ),
      },
    ],
  },

  // При нажатии на E-Mail в блоке «Финансовый менеджер»:
  // Адресаты: e-mail Фин.менеджера;
  // В копии: e-mail менеджера.
  {
    id: 'FM',
    children: [
      {
        id: 'chief',
        label: 'Финансовый менджер',
        description: contacts.FMName,
      },
      {
        id: 'phone',
        label: 'Телефон',
        description: contacts.FMPhone,
      },
      {
        id: 'email',
        label: 'E-mail',
        description: (
          <a
            href={createMailto({
              to: contacts.FMMail,
              copy: contacts.email,
            })}
          >
            {contacts.FMMail}
          </a>
        ),
      },
    ],
  },
];

export default compose(filterEmptyContactSections, decorateContacts);
