export const CLEAR = {
  apiStatus: '',
  apiStatusText: '',
};

export const SIGN = {
  fetching: {
    apiStatus: 'fetching',
    apiStatusText: 'Заказ подписывается',
  },
  done: {
    apiStatus: 'done',
    apiStatusText: 'Заказ подписан',
    status: 'На подтверждении',
    statusV2: 'На подтверждении',
  },
  error: {
    apiStatus: 'error',
    apiStatusText: 'Не получилось. Попробуйте еще раз',
  },
  finish: {
    apiStatus: '',
    apiStatusText: '',
  },
};

export const UNSIGN = {
  fetching: {
    apiStatus: 'fetching',
    apiStatusText: 'Подпись удаляется',
  },
  done: {
    apiStatus: 'done',
    apiStatusText: 'Ваша подпись снята',
    status: 'Резерв',
    statusV2: 'Резерв',
  },
  error: {
    apiStatus: 'error',
    apiStatusText: 'Не получилось. Попробуйте еще раз',
  },
  finish: {
    apiStatus: '',
    apiStatusText: '',
  },
};

export const REMOVED = {
  fetching: {
    apiStatus: 'fetching',
    apiStatusText: 'Заказ удаляется',
  },
  done: {
    apiStatus: 'done',
    apiStatusText: 'Заказ удален',
    status: 'Резерв',
    statusV2: 'Резерв',
  },
  error: {
    apiStatus: 'error',
    apiStatusText: 'Не получилось. Попробуйте еще раз',
  },
  finish: {
    apiStatus: '',
    apiStatusText: '',
  },
};
