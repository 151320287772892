import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Tools = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M11.5616 10.4944C10.2984 10.6517 10.3686 13.0113 11.7721 13.0113C11.8423 13.0113 11.9826 13.0113 12.0528 13.0113"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2809 9L12.3335 13.7978C11.2809 13.9551 10.8599 14.5056 10.8599 15.2921V16L16.5441 15.9213C16.5441 14.3483 17.1757 13.2472 16.4037 13.2472L15.1406 13.4045L14.0879 9"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 5H3.84375C3.375 5 3 5.25 3 5.5625V6.4375C3 6.75 3.375 7 3.84375 7H6"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4H6V8H8"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0827 3.00105C13.2096 3.00105 11.395 3.00105 9.52191 3.09335L8 3.64709V8.44625L9.58044 9C11.4536 9 13.2681 9 15.1413 8.90771C17.6583 8.90771 17.5997 2.90876 15.0827 3.00105Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8596 5.66635C14.3684 5.66635 13.807 5.66635 13.3158 5.66635C12.8947 5.66635 12.8947 5 13.3158 5C13.807 5 14.3684 5 14.8596 5C15.2807 5 15.2807 5.66635 14.8596 5.66635Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6H3.03509"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Tools.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Tools.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
