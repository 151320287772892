import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Damage = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M8.99993 7.88003L16.7142 5.08003L8.99993 2.28003L1.28564 5.08003L8.99993 7.88003Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7142 5.08003V12.92L8.99993 15.72L1.28564 12.92V5.08003"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 7.88003V15.72"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 10.0007L11.5528 9.77713C11.4753 9.93212 11.4836 10.1162 11.5747 10.2636C11.6658 10.411 11.8267 10.5007 12 10.5007V10.0007ZM15 10.0007L15.4472 10.2243C15.5247 10.0693 15.5164 9.88528 15.4253 9.73787C15.3342 9.59046 15.1733 9.50073 15 9.50073V10.0007ZM12.5528 13.7771C12.4293 14.0241 12.5294 14.3245 12.7764 14.4479C13.0234 14.5714 13.3237 14.4713 13.4472 14.2243L12.5528 13.7771ZM13.5528 5.77713L11.5528 9.77713L12.4472 10.2243L14.4472 6.22434L13.5528 5.77713ZM12 10.5007H15V9.50073H12V10.5007ZM14.5528 9.77713L12.5528 13.7771L13.4472 14.2243L15.4472 10.2243L14.5528 9.77713Z"
        fill={color}
      />
    </svg>
  );
};

Damage.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Damage.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
