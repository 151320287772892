import React from 'react';
import { Link } from 'react-router-dom';
import Mixpanel from 'services/mixpanel';

const EventItem = props => {
  const {
    activity: { link, name, newsId, start, end },
  } = props;
  const date = `${start}-${end}`;
  return (
    <li style={{ display: 'block' }}>
      <div className="action__date">{date}</div>
      {link ? (
        <a href={link} rel="noopener noreferrer" className="news-link" target="_blank">
          {name}
        </a>
      ) : (
        <Link
          to={`/news/${newsId}`}
          className="news-link"
          onClick={() => {
            Mixpanel?.track('СтрГлавнаяСтраница.Мероприятие', {
              page: 'СтрГлавнаяСтраница',
              id: newsId,
            });
          }}
        >
          {name}
        </Link>
      )}
    </li>
  );
};

export default React.memo(EventItem);
