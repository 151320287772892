// @ts-nocheck
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import { SearchInput } from 'common/ui/search-input';
import { activeControllers } from 'common/collections';
import { useDesign } from 'hooks/new';
import ReactTooltip from 'react-tooltip';
import Mixpanel from 'services/mixpanel';
import { SearchContext } from './context';
import { Hint } from './components/hint';
import styles from './search-hints.module.scss';
import useUpdateEffect from 'hooks/use-update-effect';
import { Cookies } from 'services/cookies';

export const SearchHints = () => {
  const {
    select: {
      newSearchHints: { getFetching, getSearchHints },
      newCatalog: { getHasCrumbs, getActiveCategory },
    },
  } = useStore();
  const {
    newSearchHints: { clearHints, fetchSearchHints, fetchSearchMain },
    newOrdersNew: { fetchGoods },
    newCatalog: { fetchCatalogGroup, setActiveCatalogCategories, setCrumbs },
    itemsFilter: { save },
  } = useDispatch();

  const [value, setValue] = useState('');
  const [showHints, setShowHints] = useState(false);

  const { pathname, state = {} } = useLocation();
  const history = useHistory();

  const hints = useSelector(getSearchHints);
  const fetching = useSelector(getFetching);
  const hasCrumbs = useSelector(getHasCrumbs);
  const activeCategory = useSelector(getActiveCategory);

  const searchInputRef = useRef();

  const classes = cn(styles['search-hints'], {
    [styles['search-hints--opened']]: showHints && hints && hints.hasItems,
  });

  const [isPilot] = useDesign('/orders/new');

  useEffect(() => {
    clearHints();
  }, [clearHints]);

  useEffect(() => {
    setShowHints(value.length >= 3);
  }, [value]);

  useEffect(() => {
    setValue(state?.text || '');
  }, [state?.text, pathname]);

  useUpdateEffect(() => {
    activeCategory && setValue('');
  }, [activeCategory]);

  const debounceSearch = useCallback(
    debounce(searchVal => {
      fetchSearchHints(searchVal);
    }, 1000),
    [],
  );

  const handleClear = () => {
    setValue('');
    clearHints();
  };

  const handleFetchCatalogGroup = searchCategory => async () => {
    setShowHints(false);
    searchCategory.hidden = true;

    if (!pathname.includes('/orders/new')) {
      history.push({
        pathname: '/orders/new',
        state: {
          fromOrigin: 'search',
          searchCategory,
          groupNo: searchCategory.categoryId,
          api: {
            success: !isPilot && 'items/load',
          },
        },
      });
    } else {
      await fetchCatalogGroup(searchCategory.categoryId);
    }
  };

  const handleFetchMain = async (val = value) => {
    if (val) {
      await save({ avail: 0 });
      Cookies.set('avail', 0);

      debounceSearch.cancel();
      activeControllers.abort('/new-search-suggestions');
      setShowHints(false);

      if (isPilot) {
        const {
          data: { data },
        } = await fetchSearchMain({ text: val });

        if (!data.length) {
          return ReactTooltip.show(searchInputRef.current);
        }

        if (!pathname.includes('/orders/new')) {
          history.push({
            pathname: '/orders/new',
            state: { fromOrigin: 'search', text: val, searchIds: data, hidden: true },
          });
        } else {
          await fetchGoods({ searchIds: data });
          history.replace('/orders/new', { searchIds: data });
          if (hasCrumbs) {
            setCrumbs([]);
            setActiveCatalogCategories({
              firstCategory: null,
              secondCategory: null,
              thirdCategory: null,
            });
          }
        }
      } else if (!pathname.includes('/orders/new')) {
        history.push({
          pathname: '/orders/new',
          state: { fromOrigin: 'search', text: val, hidden: true, api: { method: false } },
        });
      }
    }
  };

  const handleKeyPress = async e => {
    if (value && e.key === 'Enter') {
      Mixpanel?.track('Поиск.Запрос', {
        запрос: value,
      });

      await handleFetchMain();
    }
  };

  const handleChange = e => {
    debounceSearch(e.target.value);
    setValue(e.target.value);
  };

  const handleFocus = () => setShowHints(value.length >= 3);
  const handleBlur = () => {
    if (!value) {
      clearHints();
    }
    setTimeout(() => {
      setShowHints(false);
    }, 250);
  };

  const handleSearch = async searchValue => {
    Mixpanel?.track('Поиск.Запрос', {
      запрос: searchValue,
    });

    await handleFetchMain(searchValue);
  };

  return (
    <div className={classes}>
      <SearchInput
        className={styles['search-hints__input']}
        loading={fetching}
        placeholder="Введите название или код товара"
        value={value}
        onSearch={handleSearch}
        onClear={handleClear}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyPress={handleKeyPress}
        id="search-hints-input"
        ref={searchInputRef}
      />
      <SearchContext.Provider
        value={{
          text: value,
          onFetchCatalogGroup: handleFetchCatalogGroup,
          onFetchMain: handleFetchMain,
        }}
      >
        {showHints && hints && hints.hasItems && <Hint hints={hints} />}
      </SearchContext.Provider>
    </div>
  );
};
