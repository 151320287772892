import React from 'react';
import { useSelector, useStore } from 'react-redux';

// http://recharts.org/en-US/examples/BarChartWithMultiXAxis
const MonthTick = props => {
  const {
    select: { chart },
  } = useStore();
  const {
    x,
    y,
    payload: { value, offset },
    index,
  } = props;

  const dates = useSelector(chart.getMonthDates(value));
  const { text } = useSelector(chart.getMonthTick(value));

  const indexInMonth = dates.findIndex(val => val.index === value);
  const isLastInMonth = indexInMonth === dates.length - 1;
  const isFirstInAll = index === 0;
  const isSingle = dates.length === 1;

  const Path = ({ isFirst = false }) => {
    const pathX = Math.floor(isFirst ? x - offset : x + offset);
    return <path d={`M${pathX},${y - 23}v${-15}`} className="chart-block__months-path" />;
  };

  const Text = ({ x: X = x }) => (
    <text className="chart-block__months-text" x={X} y={y + 15}>
      {text}
    </text>
  );

  // 1 element in month
  if (isSingle) {
    if (isFirstInAll) {
      return (
        <React.Fragment>
          <Path isFirst={true} />
          <Text />
          <Path />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Text />
        <Path />
      </React.Fragment>
    );
  }

  // even elements in month
  if (dates.length % 2 === 0) {
    const leftMiddleEl = dates.length / 2 - 1;

    if (isFirstInAll && indexInMonth === leftMiddleEl) {
      return (
        <React.Fragment>
          <Text x={x + offset} />
          <Path isFirst={true} />
        </React.Fragment>
      );
    }

    if (indexInMonth === leftMiddleEl) {
      return <Text x={x + offset} />;
    }
  }

  // odd

  if (isFirstInAll) {
    return <Path isFirst={true} />;
  }

  const middleEl = (dates.length - 1) / 2;

  if (indexInMonth === middleEl) {
    return <Text />;
  }

  if (isLastInMonth) {
    return <Path />;
  }

  return null;
};

export default MonthTick;
