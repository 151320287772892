import React from 'react';

const deliveryColumns = [
  {
    Header: 'Дата приема',
    id: 'postingDate',
    accessor: 'postingDate',
    minWidth: 90,
    maxWidth: 110,
  },
  {
    Header: 'Код',
    id: 'no',
    accessor: 'no',
    minWidth: 60,
    maxWidth: 70,
  },
  {
    Header: 'Наименование',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    id: 'name',
    accessor: 'name',
    minWidth: 278,
    maxWidth: 600,
  },
  {
    Header: 'Серийный номер',
    id: 'serialNo',
    accessor: 'serialNo',
    sortable: false,
    minWidth: 120,
  },
  {
    Header: 'Описание',
    id: 'description',
    accessor: 'description',
    sortable: false,
  },
  {
    Header: 'Вид обсл./ М. выдачи',
    id: 'SMode',
    accessor: d => `${d.SMode} / ${d.deliveryPlace}`,
    minWidth: 155,
  },
  {
    Header: 'Кол.',
    id: 'qty',
    accessor: 'qty',
    sortable: false,
    width: 50,
  },
  {
    Header: 'Статус',
    id: 'status',
    accessor: 'status',
    minWidth: 145,
  },
  {
    Header: () => <span title="Номер заказа продажи">№ Заказа</span>,
    id: 'zprNo',
    accessor: 'zprNo',
    sortable: false,
    minWidth: 80,
  },
  {
    Header: () => <span title="Номер товарной накладной">№ ТН</span>,
    id: 'packListNo',
    accessor: 'packListNo',
    sortable: false,
    minWidth: 80,
  },
  {
    Header: () => <span title="Цена торговли">Цена</span>,
    id: 'priceTrade',
    accessor: 'priceTrade',
    sortable: false,
    minWidth: 75,
    maxWidth: 100,
  },
];

export default deliveryColumns;
