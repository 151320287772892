import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { trackingCardPage } from '../../../../helpers';
import { escapeRegExp } from 'lodash';
import { useDispatch } from 'react-redux';

const Item = props => {
  const { isDemofond, item, orderNo, text } = props;

  const pattern = new RegExp('(' + escapeRegExp(text) + ')', 'gi');
  const {
    items: { handleSearch },
  } = useDispatch();

  if (isDemofond) {
    return (
      <a
        key={item.id}
        className="link link__suggest"
        onClick={async () => await handleSearch({ text: item.id, isDemo: true })}
      >
        {ReactHtmlParser(item.name.replace(pattern, `<b>${text}</b>`))}
      </a>
    );
  }

  return (
    <Link
      key={item.id}
      to={{
        pathname: `/products/view/${item.id}`,
        state: {
          search: text,
          orderNo: orderNo,
        },
        search: `?search=${text}&orderNo=${orderNo}`,
      }}
      target="_blank"
      className="link link__suggest"
      onClick={trackingCardPage(item.id, item.name, 'ПоискТоваров')}
    >
      {ReactHtmlParser(item.name.replace(pattern, `<b>${text}</b>`))}
    </Link>
  );
};

export default Item;
