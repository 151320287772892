import React, { memo } from 'react';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { getLCYCurrencySymbol, isShallowEqual } from 'common/util';
import useCarousel from '../../../hooks/useCarousel';
import { trackingCardPage } from 'pages/Orders/helpers';
import { useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import Preloader from 'components/preloader';
import isEqual from 'lodash/isEqual';
import { DEFAULT_PHOTO_SMALL } from 'common/config';

const Analogs = React.memo(
  props => {
    const { analogSlides, order } = props;

    const [slideShift, isFirstSlide, isLastSlide, handleNextSlideClick, handlePrevSlideClick] =
      useCarousel(
        {
          ...props,
          containerClassName: 'analogs__slide',
          type: 'Vertical',
        },
        [analogSlides.length],
      );
    const isAnalogsFetching = useSelector(getEffectLoading('product', 'getAnalogs'));
    const { id } = useParams();

    if (isAnalogsFetching) {
      return <Preloader />;
    }

    if (!analogSlides.length) {
      return null;
    }

    return (
      <div className="analogs">
        <p className={classNames('analogs__head', 'font-size__xlarge')}>Аналоги товара</p>
        <div className="analogs__products">
          <div
            className={classNames('analogs__arrow', 'analogs__arrow--top', {
              'analogs__arrow--disabled': isFirstSlide(),
            })}
          >
            <a href="#prevSlide" onClick={handlePrevSlideClick}>
              <i className="icon icon-top-grey-icon" />
            </a>
          </div>
          <div className="analogs__slider__window">
            <div style={{ top: `-${slideShift}px` }} className="analogs__slider__door">
              {analogSlides.map((analogSlide, index) => (
                <div key={index} className="analogs__slide">
                  {analogSlide.map(product => {
                    const productUniqueId = `${product.id}_${product.groupId}`;
                    const photoSmall = product.imageUrl || DEFAULT_PHOTO_SMALL.url;

                    return (
                      <div key={productUniqueId} className="analogs__product">
                        <Link
                          to={{
                            pathname: `/products/view/${product.id}`,
                            state: {
                              order: (order && order.orderNo) || 'NEW',
                              analogParentId: id,
                            },
                          }}
                          className="analogs__product__photo"
                          onClick={trackingCardPage(product.id, product.name, 'АналогТовары')}
                        >
                          <img
                            src={photoSmall}
                            className="analogs__product__image"
                            alt={product.name}
                          />
                        </Link>
                        <div className="analogs__product__info">
                          <Link
                            to={{
                              pathname: `/products/view/${product.id}`,
                              state: {
                                orderNo: (order && order.orderNo) || 'NEW',
                                analogParentId: id,
                              },
                            }}
                            className={classNames(
                              'analogs__product__info__title',
                              'font-size__small',
                              'font-color__black--hover',
                            )}
                            onClick={trackingCardPage(product.id, product.name, 'АналогТовары')}
                          >
                            {product.name}
                          </Link>
                          <p
                            className={classNames(
                              'analogs__product__info__id',
                              'font-size__small',
                              'font-color__grey',
                            )}
                          >
                            id {product.id}
                          </p>
                          <p
                            className={classNames(
                              'analogs__product__info__price',
                              'font-size__medium',
                            )}
                          >
                            <strong>
                              {product.priceLCY}
                              {getLCYCurrencySymbol('РУБ')}
                            </strong>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
          <div
            className={classNames('analogs__arrow', 'analogs__arrow--bottom', {
              'analogs__arrow--disabled': isLastSlide(),
            })}
          >
            <a href="#prevSlide" onClick={handleNextSlideClick}>
              <i className="icon icon-bottom-grey-icon" />
            </a>
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isShallowEqual(prev, next),
);

export default memo(Analogs, isEqual);
