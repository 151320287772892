import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Exit = ({
  className,
  color = '#f15b5b',
  name,
  width = '16',
  height = '16',
  viewBox = '0 0 16 16',
}) => {
  return (
    <svg
      className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M15.846 7.6275L12.6413 4.42277C12.4284 4.20991 12.0973 4.20991 11.8845 4.42277C11.6716 4.63563 11.6716 4.96674 11.8845 5.1796L14.1786 7.47377H6.39739C6.10175 7.47377 5.86523 7.71028 5.86523 8.00592C5.86523 8.30156 6.10175 8.53807 6.39739 8.53807H14.1786L11.8845 10.8322C11.6716 11.0451 11.6716 11.3762 11.8845 11.5891C11.9909 11.6955 12.121 11.7428 12.2629 11.7428C12.4048 11.7428 12.5349 11.6955 12.6413 11.5891L15.846 8.38434C16.0589 8.17147 16.0589 7.82853 15.846 7.6275Z"
          fill={color}
        />
        <path
          d="M9.07021 14.9357H2.67258C1.78566 14.9357 1.07613 14.2143 1.07613 13.3392V2.67258C1.0643 1.78566 1.78566 1.0643 2.67258 1.0643H9.07021C9.36585 1.0643 9.60236 0.82779 9.60236 0.532151C9.60236 0.236511 9.36585 0 9.07021 0H2.67258C1.19438 0 0 1.19438 0 2.67258V13.3392C0 14.8056 1.19438 16.0118 2.67258 16.0118H9.07021C9.36585 16.0118 9.60236 15.7753 9.60236 15.4797C9.60236 15.1722 9.36585 14.9357 9.07021 14.9357Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Exit.defaultProps = {
  className: '',
  color: '#f15b5b',
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
};

Exit.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
