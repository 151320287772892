import React, { useState } from 'react';
import { certsCollector } from 'pages/Certificates/constants';
import { Async } from 'react-select';
import { useDispatch } from 'react-redux';

const FilterType = () => {
  const [value, setValue] = useState();
  const {
    certificates: { getTypes },
  } = useDispatch();

  const onChange = newValue => {
    const value = newValue?.value;

    setValue(value);

    if (!value) {
      return certsCollector.delete('type');
    }

    certsCollector.set('type', value);
  };

  return (
    <Async
      name="typeFilter"
      placeholder="Тип сертификата"
      onChange={onChange}
      value={value}
      loadOptions={getTypes}
    />
  );
};

export default FilterType;
