import React, { useEffect, useState } from 'react';
import useSlide from '../hooks/useSlide';
import Banners from './Banners';
import Dots from './Dots';
import { useDispatch, useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import { useDesign } from 'hooks/new';

const Slider = ({ place }) => {
  const [banners, setBanners] = useState([]);
  const length = banners?.length;
  const [slide, handleSelectSlide] = useSlide(length);
  const {
    news: { getBanners },
  } = useDispatch();
  const loading = useSelector(getEffectLoading('news', 'getBanners'));
  const [isPilot] = useDesign();

  useEffect(() => {
    getBanners().then(banners => {
      const banner = banners[`${place}${(isPilot && '_v2.0') || ''}`] || [];
      setBanners(banner);
    });
  }, [place, isPilot]);

  if (loading) {
    return <i className="products__preloader__image loader" />;
  }

  return (
    <div>
      <div className="banner__slider">
        <Banners slide={slide} banners={banners} />
        {length > 1 && <Dots length={length} onSelectSlide={handleSelectSlide} slide={slide} />}
      </div>
    </div>
  );
};

export default Slider;
