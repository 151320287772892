import React from 'react';
import { Link } from 'react-router-dom';
import { separateNumberFormatter } from 'common/util';

const createProductColumns = (productTypeVisibility, isCard) => [
  {
    Header: 'Номер товара',
    accessor: 'Номер Товара',
    Cell: row =>
      (row && (
        <Link
          className="orders__table__row__link"
          to={{
            pathname: `/products/${isCard ? 'card' : 'view'}/${row.value}`,
          }}
          target="_blank"
        >
          {row.value}
        </Link>
      )) ||
      null,
  },
  {
    Header: 'Наименование',
    accessor: 'Полное Наименование',
    minWidth: 610,
    sortable: false,
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
    Cell: row =>
      (row && (
        <Link
          className="orders__table__row__link"
          to={{
            pathname: `/products/${isCard ? 'card' : 'view'}/${row.original['Номер Товара']}`,
          }}
          target="_blank"
        >
          {row.value}
        </Link>
      )) ||
      null,
  },
  {
    Header: 'Бонус, руб',
    accessor: 'Bonus Amt',
    show: productTypeVisibility.bonus,
    sortMethod: (a, b) => (Math.round(a) < Math.round(b) ? -1 : 1),
    Cell: row =>
      (row && (
        <Link
          className="orders__table__row__link"
          to={{
            pathname: `/products/view/${row.original['Номер Товара']}`,
          }}
          target="_blank"
        >
          {separateNumberFormatter(row.value)}
        </Link>
      )) ||
      null,
  },
  {
    Header: 'Баллы',
    accessor: 'Point Qty',
    minWidth: 140,
    show: productTypeVisibility.point,
    sortMethod: (a, b) => (Math.round(a) < Math.round(b) ? -1 : 1),
    Cell: row =>
      (row && (
        <Link
          className="orders__table__row__link"
          to={{
            pathname: `/products/view/${row.original['Номер Товара']}`,
          }}
          target="_blank"
        >
          {separateNumberFormatter(row.value)}
        </Link>
      )) ||
      null,
  },
];

export default createProductColumns;
