import { useSelector, useStore } from 'react-redux';
import DefaultField from 'pages/Orders/components/order-edit/header-form/components/defaultField';
import React from 'react';
import { useDesignSelect } from 'hooks/new';
import useCanEdit from 'pages/new/orders/hooks/use-can-edit';

const ContractorCodeField = ({
  input: { value, onChange, name },
  label,
  className,
  arrowRenderer,
}) => {
  const {
    select: { order, user },
  } = useStore();
  const { counterAgentRows = [] } = useSelector(order.getSettings);
  const { currency: orderCurrency } = useSelector(order.getHeader);
  const settingsCurrency = useSelector(user.getCurrencyCode);
  const canEdit = useCanEdit();

  const Select = useDesignSelect();

  const options = counterAgentRows.map(agent => {
    const currency = agent['Currency Code'] || orderCurrency || settingsCurrency;

    return {
      value: `${agent.Code}|${agent.IdDogovor}|${currency}`,
      label: agent.Description,
    };
  });

  if (!options.length) {
    return <DefaultField title={label} fieldName={name} placeholder="Нет доступных контрагентов" />;
  }

  return (
    <div>
      <label>{label}</label>
      <Select
        value={value}
        name={name}
        placeholder="Не выбран"
        onChange={({ value }) => {
          onChange(value);
        }}
        options={options}
        clearable={false}
        searchable={false}
        className={className}
        arrowRenderer={arrowRenderer}
        disabled={!canEdit}
      />
    </div>
  );
};

export default ContractorCodeField;
