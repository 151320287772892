import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './container.module.scss';

export const Container = ({ children, className, id, style }) => (
  <div
    className={cn(styles['container'], className)}
    data-testid="container"
    {...(id && { id } || false)}
    style={style}
  >
    {children}
  </div>
);

Container.defaultProps = {
  children: null,
  className: '',
};

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
