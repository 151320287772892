import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../icon.module.scss';

export const Default = ({
  className,
  color = '#808080',
  name,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect x="0.5" y="6.5" width="17" height="11" rx="0.5" stroke={color} />
    <path
      d="M4.11916 1.72265C4.21189 1.58355 4.36801 1.5 4.53518 1.5H13.4648C13.632 1.5 13.7881 1.58355 13.8808 1.72265L17.0657 6.5H0.934258L4.11916 1.72265Z"
      stroke={color}
    />
    <rect x="5.5" y="9.5" width="7" height="2" rx="0.5" stroke={color} />
    <path d="M5 2V6" stroke={color} />
    <path d="M13 2V6" stroke={color} />
  </svg>
);

Default.defaultProps = {
  className: '',
  color: '#808080',
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
};

Default.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
