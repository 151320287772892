import React from 'react';
import Helmet from 'react-helmet';
import classNames from 'classnames';

const Content = props => (
  <div className={props.className}>
    <Helmet>
      <title>{props.title}</title>
    </Helmet>
    {!props.noHeader && <h1 className={props.titleClassName || 'title'}>{props.title}</h1>}
    <div
      className={classNames('content', {
        'content--without-footer': props.noFooter,
      })}
      style={props.style}
    >
      {props.children}
    </div>
  </div>
);

export default Content;
