import React from 'react';
import Mixpanel from 'services/mixpanel';
import useRouteConfig from 'hooks/use-route-config';

const OldRouteLink = props => {
  const { children, to, onClick, ...rest } = props;

  const { name: routeName } = useRouteConfig();

  const eventName =
    routeName && `Стр${routeName.replace(/(^|\s)\S/g, l => l.toUpperCase()).replace(/\s/g, '')}`;

  return (
    <a
      href={to}
      onClick={(e) => {
        onClick(e);
        if (eventName) {
          Mixpanel?.track(eventName, {
            page: routeName,
          });
        }
      }}
      {...rest}
    >
      {children}
    </a>
  );
};

export default OldRouteLink;
