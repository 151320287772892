import React, { useState, useEffect, useRef } from 'react';

const FormContainer = props => {
  const { error, touched, filesLength } = props;

  const [isTouched, setIsTouched] = useState(touched);

  const prevFilesLength = useRef(filesLength);

  useEffect(() => {
    if (filesLength === 1) {
      prevFilesLength.current = filesLength;
    }

    if (prevFilesLength.current && filesLength !== prevFilesLength.current) {
      setIsTouched(false);
      prevFilesLength.current = filesLength;
    } else {
      setIsTouched(touched);
    }
  }, [filesLength, touched]);

  return (
    <div className={'row row-feedback'}>
      <div className={'col label-container'}>
        <label>{props.label}</label>
      </div>
      <div className={'col'}>
        {props.children}
        {isTouched && error && <span className={'text-danger text-danger--small'}>{error}</span>}
      </div>
    </div>
  );
};
export default FormContainer;
