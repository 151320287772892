export const ACTIONS = [
  'integrate-order',
  'signature',
  'unsignature',
  'edit',
  'balance-sheet',
  'invoice',
  'transer-item',
  'commercial-offer',
  'transit',
  'transfer',
  'excel',
  'shipment',
  'removed',
  'product-from-excel',
];
