import React from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
import { QUEUE_CLAIM } from 'common/config';
import ClaimInfo from 'pages/Logistic/components/claims/components/claim-info';

const QueueHeader = () => {
  const header = useSelector(state => state.queue.order);

  const { state = {} } = useLocation();
  const { queueType } = state;

  if (isEmpty(header)) {
    return null;
  }

  let resultColor = '#000000';

  switch (header['Processing Result']) {
    case 'Warning':
      resultColor = '#0000cc';
      break;
    case 'Active':
    case 'Canceled':
    case 'Ok':
      resultColor = '#008800';
      break;
    case 'Error':
      resultColor = '#ee2a24';
      break;
    default:
      break;
  }

  if (queueType === QUEUE_CLAIM) {
    return (
      <div className="row queue-header">
        <ClaimInfo info={header} />
      </div>
    );
  }

  return (
    <div className="row queue-header">
      <div className="col-sm">
        <span>Дата {header['Date']}</span>
        <span>Комманда {header['Command Name']}</span>
      </div>
      <div className="col-sm">
        <span>Метод отгрузки {header['Shipment']}</span>
        <span>Дата отгрузки {header['Shipment Date']}</span>
      </div>
      <div className="col-sm">
        <span>Время отгрузки {header['Shipment Time']}</span>
        <span>Агент по доставке {header['Shipment Agent']}</span>
      </div>
      <div className="col-sm">
        <span>
          Результат{' '}
          <span style={{ color: resultColor }}>
            {header['Processing Result Comment'] || header['Processing Result']}
          </span>
        </span>
        <span />
      </div>
    </div>
  );
};

export default QueueHeader;
