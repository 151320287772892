import React, { useCallback } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Logo } from 'common/ui/logo';
import withApi from 'hoc/withApi';
import { QSToObj } from 'common/util';
import { SIGN_UP_THEME } from 'common/themes';
import { useTheme } from 'hooks/use-theme';
import { isCactus } from 'common/config';
import cactusLogo from 'img/cactus-logo.png';
import { Form } from './components/form';
import { PersonalDataAgreement } from './components/personal-data-agreement';

import styles from './sign-up.module.scss';

export const SignUp = withApi(() => {
  useTheme(SIGN_UP_THEME);
  const {
    select: { personal },
  } = useStore();
  const {
    personal: { register },
  } = useDispatch();

  const { search } = useLocation();
  const { token } = QSToObj(search);
  const { email } = useSelector(personal.getInvitedUser);

  const handleSubmit = useCallback(data => register({ data, token }), [token]);

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['logo']}>
          {isCactus ? <img src={cactusLogo} alt="" className="logo" /> : <Logo />}
        </div>
        <h2 className={styles['header']}>Регистрация</h2>
        <Form initialValues={{ regionId: 0, cityId: 0, email }} isNew onSubmit={handleSubmit} />
      </div>
      <PersonalDataAgreement />
    </>
  );
});
