import { API_URL } from 'common/config/app';

export const ordersNewApi = {
  'new-orders-new/goods/?([%A-Z0-9]*)$': {
    endpoint: `/items`,
  },
  'new-orders-new/delete/row/([A-Z0-9]+)$': {
    endpoint: `${API_URL}/v1/orders/edit/delete-row`,
  },
  'new-orders-new/filters/([%A-Z0-9]*)$': {
    endpoint: `${API_URL}/v2/orders/create/characteristics`,
  },
};
