import React, { useEffect } from 'react';
import columns from '../columns/products';
import TableList from 'components/table/list';
import selectTableHOC from 'react-table-v6/lib/hoc/selectTable';
import { useDispatch, useSelector } from 'react-redux';
import { getTableListData } from 'common/selectors';
import pick from 'lodash/pick';
import transform from 'lodash/transform'
import useSelectTable from "hooks/use-select-table"
import useListData from "components/table/hooks/useListData"

const SelectTable = selectTableHOC(TableList);

function SelectProduct({ isLoading, onRequestClose }) {
  const {selectAll, toggleSelection, isSelected, toggleAll, selected, clearAll} = useSelectTable()

  const data = useListData();
  const {
    claims: { addProducts },
  } = useDispatch();

  useEffect(() => {
    if (isLoading) {
      clearAll()
    }
  }, [isLoading]);

  const handleAddProduct = () => {
    const products = transform(data, (acc, product) => {
      if (selected.includes(product.id)) {
        const selectedProduct = pick(product, [
          'name',
          'qty',
          'price',
          'sum',
          'volume',
          'underload',
          'orderedQty',
        ]);

        selectedProduct.no = product.id;
        acc.push(selectedProduct);
      }
    }, []);

    addProducts(products);
    onRequestClose();
  };

  return (
    <div>
      <SelectTable
        keyField="id"
        name={'items'}
        defaultPageSize={5000}
        pageSize={5000}
        isSelected={isSelected}
        toggleSelection={toggleSelection}
        toggleAll={toggleAll}
        selectAll={selectAll}
        loading={isLoading}
        columns={columns}
      />
      <section className="add-product-btn-container">
        {!isLoading && (
          <button
            className="btn btn-danger btn--rect"
            disabled={selected.length === 0}
            onClick={handleAddProduct}
          >
            Добавить товар
          </button>
        )}
      </section>
    </div>
  );
}

export default SelectProduct;
