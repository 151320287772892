// @ts-nocheck
import React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { usePopup } from 'hooks/new';
import AppSocket from 'components/AppSocket';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';
import { Popup } from 'common/ui/popup';
import { Heading } from 'common/ui/heading';
import Mixpanel from 'services/mixpanel';
import { DownloadListBody } from './components/download-list-body';
import { formatStatusToTitle } from './utils/format-status';

import styles from './download-list.module.scss';

export const DownloadList = () => {
  const {
    select: {
      newDownloadList: { getStatus },
    },
  } = useStore();
  const {
    newDownloadList: { receive, expire, resetState },
    newOrderActions: { setJobType },
  } = useDispatch();

  const status = useSelector(getStatus);
  const [openPopup, closePopup, isPopupOpen] = usePopup();

  const hanldeOpenPopup = () => {
    Mixpanel?.track('Меню.Документы');
    openPopup();
  };

  const handleClosePopup = () => {
    closePopup();
    resetState();
    setJobType();
  };

  return (
    <>
      <AppSocket channel="queue" onReceive={receive} onExpire={expire}>
        <IconButton onClick={hanldeOpenPopup} className={styles['download-list__main-button']}>
          <span>{formatStatusToTitle(status)}</span>
          {
            status !== 'checkmark' ? 
              <Icon name="download" color='white'/> : 
              <Icon name="approve" color='white' />
          }
        </IconButton>
      </AppSocket>
      <Popup
        className={styles['download-list__popup']}
        body={<DownloadListBody />}
        header={<Heading size="lg">Список загрузок</Heading>}
        visible={isPopupOpen}
        onClose={handleClosePopup}
      />
    </>
  );
};
