// @ts-nocheck
import get from 'lodash/get';
import { proxyArgsToMethods } from 'common/util';
import alert from 'components/native/alert/function';
import { getTableListData } from 'common/selectors';
import * as effects from './effects';
import history from "common/history"

export default {
  name: 'shipments',
  state: {
    header: {},
  },
  reducers: {
    loadData(state, payload) {
      state.header = get(payload, ['data', 'details'], {});
      return state;
    },
  },
  effects: dispatch => ({
    async getSchedule(id) {
      if (id) {
        try {
          const { data } = await dispatch.api.get({
            url: `shipment-schedule/view/${id}`,
          });

          return data;
        } catch (e) {
          alert(e.message);
          return null;
        }
      }

      return null;
    },
    ...proxyArgsToMethods(effects, dispatch),
  }),
  selectors: (slice, createSelector) => ({
    getHeader() {
      return slice(state => state.header);
    },
    getVolume({ list }) {
      return createSelector(slice, list.getData(history.location.pathname), (state, list) => {
        const volume = get(state, ['header', 'volume'], 0);
        const volumeFromList = list.reduce((acc, cur) => acc + parseFloat(cur.volume), 0);

        return volume ? parseFloat(volume).toFixed(3) : volumeFromList.toFixed(3);
      });
    },
  }),
};
