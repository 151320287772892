/* eslint-disable no-unused-vars */
import { def } from 'common/util';
import {
  CLEAR_ALL_ITEMS_STATE,
  CLEAR_ITEM_STATE,
  SET_ITEM_DESIRED_CLIENT_PRICE,
  SET_ITEM_DESIRED_PRICE,
  SET_ITEM_DESIRED_QTY,
  ADD_SELECTED_INDEX_ROWS,
  DELETE_SELECTED_INDEX_ROWS,
} from '../action';

export const itemPriceInitialState = {
  selectedIndexRows: null,
};

export default (state, action) => {
  switch (action.type) {
    case CLEAR_ITEM_STATE: {
      const { [action.payload.id]: foo, ...rest } = state;

      return rest;
    }
    case CLEAR_ALL_ITEMS_STATE:
      return {};
    case SET_ITEM_DESIRED_QTY: {
      const itemRow = def(state[action.payload.id])
        ? { ...state[action.payload.id], desiredQty: action.payload.value }
        : { desiredQty: action.payload.value };

      return { ...state, [action.payload.id]: itemRow };
    }
    case SET_ITEM_DESIRED_PRICE:
    case SET_ITEM_DESIRED_CLIENT_PRICE: {
      const { rate } = action.payload;
      const prices =
        action.type === SET_ITEM_DESIRED_PRICE
          ? {
              desiredPrice: action.payload.value,
              desiredPriceClient:
                (rate && (parseFloat(action.payload.value) / parseFloat(rate)).toFixed(2)) ||
                (state[action.payload.id] && state[action.payload.id]['desiredPriceClient']) ||
                0,
            }
          : {
              desiredPrice:
                (rate && (parseFloat(action.payload.value) * parseFloat(rate)).toFixed(2)) ||
                (state[action.payload.id] && state[action.payload.id]['desiredPrice']) ||
                0,
              desiredPriceClient: action.payload.value,
            };

      const row = def(state[action.payload.id])
        ? { ...state[action.payload.id], ...prices }
        : prices;

      return { ...state, [action.payload.id]: row };
    }
    case ADD_SELECTED_INDEX_ROWS: {
      return {
        ...state,
        selectedIndexRows: {
          ...state.selectedIndexRows,
          ...action.payload,
        },
      };
    }
    case DELETE_SELECTED_INDEX_ROWS: {
      const { [action.payload]: foo, ...rest } = state.selectedIndexRows;

      return {
        ...state,
        selectedIndexRows: rest,
      };
    }
    default:
      return state;
  }
};
