export const DEFAULT_OPTION_ORDER_STATUS = { id: 1, value: '', label: 'С любым статусом' };

export const OPTIONS_ORDER_STATUS = [
  {
    id: 2,
    value: 'ОЗПр',
    label: 'Заказ (ОЗПр)',
  },
  {
    id: 3,
    value: 'Резерв',
    label: 'Резерв (ЗПр)',
  },
  {
    id: 4,
    value: 'На подтверждении',
    label: 'На подтверждении',
  },
  {
    id: 5,
    value: 'Комплектуется',
    label: 'Комплектуется',
  },
  {
    id: 6,
    value: 'Ожидает оплаты',
    label: 'Ожидает оплаты',
  },
  {
    id: 7,
    value: 'Готов к выдаче',
    label: 'Готов к выдаче',
  },
  {
    id: 8,
    value: 'Выдаётся',
    label: 'Выдаётся',
  },
  {
    id: 9,
    value: 'Передан в доставку',
    label: 'Передан в доставку',
  },
  {
    id: 10,
    value: 'Выдан',
    label: 'Выдан',
  },
  {
    id: 11,
    value: 'Доставлен',
    label: 'Доставлен',
  },
];
