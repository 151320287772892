import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../icon.module.scss';

export const Done = ({
  className,
  color = '#008000',
  name,
  width = '30',
  height = '30',
  viewBox = '-1 -1 32 32',
}) => (
  <svg
    className={cn(styles['svg-icon'], `svg-icon-${name}`, className)}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M24.3536 10.3536C24.5488 10.1583 24.5488 9.84171 24.3536 9.64645C24.1583 9.45118 23.8417 9.45118 23.6464 9.64645L24.3536 10.3536ZM14 20L13.6464 20.3536C13.8417 20.5488 14.1583 20.5488 14.3536 20.3536L14 20ZM7.35355 12.6464C7.15829 12.4512 6.84171 12.4512 6.64645 12.6464C6.45119 12.8417 6.45119 13.1583 6.64645 13.3536L7.35355 12.6464ZM23.6464 9.64645L13.6464 19.6464L14.3536 20.3536L24.3536 10.3536L23.6464 9.64645ZM14.3536 19.6464L7.35355 12.6464L6.64645 13.3536L13.6464 20.3536L14.3536 19.6464Z"
      fill={color}
    />
    <path
      d="M15 29C7.26801 29 1 22.732 1 15H-1C-1 23.8366 6.16344 31 15 31V29ZM29 15C29 22.732 22.732 29 15 29V31C23.8366 31 31 23.8366 31 15H29ZM15 1C22.732 1 29 7.26801 29 15H31C31 6.16344 23.8366 -1 15 -1V1ZM15 -1C6.16344 -1 -1 6.16344 -1 15H1C1 7.26801 7.26801 1 15 1V-1Z"
      fill={color}
    />
  </svg>
);

Done.defaultProps = {
  className: '',
  color: '#008000',
  width: '30',
  height: '30',
  viewBox: '-1 -1 32 32',
};

Done.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * color of svg primitives
   */
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
