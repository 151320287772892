import { isItemLinked } from 'pages/Settings/selectors';
import { useSelector } from 'react-redux';
import React from 'react';
import classNames from 'classnames';

export default row => {
  if (!row) return null;

  const isLinked = useSelector(state =>
    isItemLinked(state)(row.original.no, Number(row.original.linked)),
  );
  const choosedNo = useSelector(state => state.bindings.choosed.no);

  if (!isLinked) return null;

  return (
    <i
      className={classNames('icon icon-linked', {
        'icon icon-linked-red': choosedNo === row.original.no,
      })}
      title="Есть привязки"
    />
  );
};
